import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { TextField, FilledTextFieldProps } from '@mui/material'
import { ContainerInput } from './styles';
import { theme } from '../../styles/theme';

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiFilledInput-root": {
      background: "#fff"
    }
  }
}));

const useStylesBlack = makeStyles(() => ({
  root: {
    "& .MuiFilledInput-root": {
      background: theme.colorsBase.gray7
    }
  }
}));

interface InputProps extends FilledTextFieldProps {
  notEditable?: boolean;
  backBlack?: boolean;
}


const Input: React.FC<InputProps> = (props: InputProps) => {
  const classes = useStyles();
  const classesBlack = useStylesBlack();

  return (
    <>
      {props.notEditable ? (
        <ContainerInput>
          <h3>{props.label}</h3>
          <h5>{String(props.value).length > 0 ? String(props.value) : '-'}</h5>
        </ContainerInput>
      ) : (
        <TextField
          className={props.backBlack ? classesBlack.root : classes.root}
          autoComplete='off'
          {...props}
        />
      )}
    </>
  )
}

export default Input
