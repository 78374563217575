import * as yup from 'yup';
import { verifyClassEmpty } from '../../utils/ClassEmpty';
import { genericValidation, } from '../../utils/ValidationGenerics';
import { RegistrationAdditionalInformation, RegistrationAdditionalInformationError, RegistrationIdentification, RegistrationIdentificationError, RegistrationQuiz, RegistrationQuizError } from './types';


export const registerQuizValidation = async (object: RegistrationQuiz): Promise<true | RegistrationQuiz> => {
  const schemaRegister = yup.object().shape({
    question: yup.string().required()
  });

  const mountError = await genericValidation<RegistrationQuiz, RegistrationQuizError>(schemaRegister, object, new RegistrationQuiz());

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};

export const registerQuizAlternativeValidation = async (object: RegistrationQuiz): Promise<true | RegistrationQuiz> => {
  const schemaRegister = yup.object().shape({
    question: yup.string().required()
  });

  const mountError = await genericValidation<RegistrationQuiz, RegistrationQuizError>(schemaRegister, object, new RegistrationQuiz());

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};

export const registerChallengeValidation = async (object: RegistrationIdentification): Promise<true | RegistrationIdentification> => {
  const schemaRegister = yup.object().shape({
    name: yup.string().required(),
    language: yup.string().required()
  });

  const mountError = await genericValidation<RegistrationIdentification, RegistrationIdentificationError>(schemaRegister, object, new RegistrationIdentification());

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};

export const additionalInformationValidation = async (object: RegistrationAdditionalInformation): Promise<true | RegistrationAdditionalInformation> => {
  const schemaRegister = yup.object().shape({
    duration: yup.string().required(),
  });

  const mountError = await genericValidation<RegistrationAdditionalInformation, RegistrationAdditionalInformationError>(schemaRegister, object, new RegistrationAdditionalInformation());

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};

export const mediaTypeValidation = async (object: RegistrationIdentification): Promise<true | RegistrationIdentification> => {
  const schemaRegister = yup.object().shape({
    mediaType: yup.string().required()
  });

  const mountError = await genericValidation<RegistrationIdentification, RegistrationIdentificationError>(schemaRegister, object, new RegistrationIdentification());

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};
