export const STR = {
  REQUEST_EXCEPTION: {
    /** Não encontrou a API, ou sem internet */
    NETWORK_ERROR: 'Erro ao estabelecer conexão com o servidor. Tente novamente mais tarde.',

    /** URL não encontrada */
    NOT_FOUND_ERROR: 'Não encontrado. Tente novamente mais tarde.',

    /** Erro interno da API */
    API_INTERNAL_ERROR: 'Parece que ocorreu um erro interno no servidor. Tente novamente mais tarde.',

    /** Erro ao converter resposta da API */
    APP_JSON_PROCESS_ERROR: 'Erro ao processar resposta do servidor. Tente novamente mais tarde.',

    /** Não autorizado */
    UNAUTHORIZED: 'Acesso negado. Tente novamente mais tarde.'
  }
};
