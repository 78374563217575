import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 400px;
  border: solid 1px transparent;
  border-radius: 8px;
  position: relative;
  padding: 4px 8px 4px 4px;
  margin: 0 0px 24px 0px;

  .i {
    display: none;
    color: ${(props) => props.theme.colors.seventh};
    font-size: 20px;
    position: absolute;
    top: 12px;
    right: 12px;
  }

  @media (max-width: 680px) {
    width: ${`calc(100vw - 16px)`};
    margin: 0px;
  }

  &:hover {
    border: solid 1px ${(props) => props.theme.colorsBase.gray3};

    .i {
      display: flex;
    }
  }

  
  .boxContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 16px;

        .title {
          margin-bottom: 4px;
          line-height: 16px;
          letter-spacing: 0.4px;
          text-align: left;
          font-size: ${responsiveFont(16)};
          color: ${(props) => props.theme.colors.secundary};
          font-weight: 400;
          font-family: 'Barlow', Regular;

          width: 250px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @media (max-width: 680px) {
            max-width: ${`calc(100vw - 110px)`};
          }
        }

        .subTitle {
          margin-bottom: 4px;
          line-height: 16px;
          letter-spacing: 0.4px;
          text-align: left;
          font-size: ${responsiveFont(12)};
          color: ${(props) => props.theme.colors.black};
          font-weight: 400;
          font-family: 'Barlow', Regular;

          width: 250px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          
          @media (max-width: 680px) {
            max-width: ${`calc(100vw - 110px)`};
            font-size: ${responsiveFont(10)};
          }
        }

        .description {
          line-height: 16px;
          letter-spacing: 0.4px;
          text-align: left;
          font-size: ${responsiveFont(10)};
          color: ${(props) => props.theme.colors.black};
          font-weight: 400;
          font-family: 'Barlow', Regular;
          
          overflow: hidden; 
          text-overflow: ellipsis; 
          display: -webkit-box;
          -webkit-line-clamp: 2; 
          -webkit-box-orient: vertical; 

          @media (max-width: 680px) {
            max-width: ${`calc(100vw - 110px)`};
            font-size: ${responsiveFont(8)};
          }
        }
  }

  .noImage {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    height: 100px;
    object-fit: cover; 
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);


    @media (max-width: 680px) {
      min-width: 60px;
      height: 60px;
    }

    .icon {
      color: ${(props) => props.theme.colorsBase.gray6};
      width: 24px;
      height: 24px;
      align-self: center;
      position: absolute;
    }
  }
`

export const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover; 
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 680px) {
    width: 60px;
    height: 60px;
  }
`