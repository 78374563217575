import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


export interface StyledTabProps {
  label: string;
}

export const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #DEE2E6',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

export const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'uppercase',
    paddingLeft: '50px',
    paddingRight: '50px',
    paddingTop: '33px',
    [theme.breakpoints.down(800)]: {
      paddingTop: '17px',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
    paddingBottom: '17px',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightLight,
    marginRight: theme.spacing(1),
    color: '#707070',
    fontFamily: "Barlow",
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#5080FF',
      fontWeight: theme.typography.fontWeightLight,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);