import React, { useState, ChangeEvent } from 'react';

import { Edit, Delete, AddPhotoAlternate, } from '@mui/icons-material'
import { Tooltip, } from '@mui/material'
import { emptyItem, ITableUsers } from './types'
import { useTranslation } from 'react-i18next'
import ModalCustom from '../ModalCustom'
import ToastNotification from '../ToastNotification'
import { DataMaskFormtData, validateIfNull } from '../../utils/Mask'
import Input from '../../components/Input';
import { Registration, RegistrationError } from './types';
import { registerValidation } from './validations';
import * as S from './styles'
import { IGifts } from '../../services/Gifts/types';
import { deleteGifts, putGifts } from '../../services/Gifts';
import offImage from '../../assets/images/imgErro.png'

const TableGifts: React.FC<ITableUsers> = (props: ITableUsers) => {
  const { t } = useTranslation();
  const { listUsers, updateList } = props
  const [itemSelected, setItemSelected] = useState<IGifts>(emptyItem)
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openEditCategoriesModal, setOpenEditCategoriesModal] = useState(false)
  const [registration, setRegistration] = useState<Registration>(new Registration());
  const [registrationError, setRegistrationError] = useState<RegistrationError>(new RegistrationError());
  const [imageSet, setImageSet] = useState<File>();
  const [image, setImage] = useState<string>(offImage);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedImage = e.target.files?.[0];
    if (selectedImage) {
      setImageSet(selectedImage)
      setImage(URL.createObjectURL(selectedImage));
    }
  };

  const registerSponsor = async () => {
    setLoading(true)
    try {
      let countError = 0;

      const validation = await registerValidation(registration);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError(validation as RegistrationError);
        setLoading(false);
        return;
      }

      const data = new FormData();
      data.append('Brinde.Nome', registration.nome);
      data.append('Brinde.QtdPontos', registration.pontos);
      if (imageSet) data.append('Brinde.Foto', imageSet as File);

      const response = await putGifts(data, Number(itemSelected.Id));
      if (response.Success) {
        setImage(offImage)
        setImageSet(undefined)
        setRegistration({
          nome: '',
          pontos: ''
        })
        updateList();
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Brinde editado com sucesso!')
        });
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao editar o brinde, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao editar o brinde, tente novamente.')
      });
    }
    setLoading(false)
  };

  const handleDeleteUser = async () => {
    setLoading(true);
    try {
      if (itemSelected?.Id) {
        const response = await deleteGifts(Number(itemSelected.Id));
        if (response.Success) {
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Brinde excluído com sucesso!')
          });
          updateList();
          setOpenDeleteUserModal(false)
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível excluir o brinde, tente novamente mais tarde.'),
            errorMessage: response.Message,
            errors: response.Errors
          });
        }
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível excluir o brinde, tente novamente mais tarde.')
      });
    }
    setLoading(false);
  };

  const renderTable = () => {
    return (
      <S.Table>
        <table>
          <tr className="header">
            <td style={{ paddingLeft: 16 }}>{t('Imagem')}</td>
            <td>{t('Nome')}</td>
            <td>{t('Qtd. Pontos')}</td>
            <td>{t('Data Cadastro')}</td>
            <td>{t('Ações')}</td>
          </tr>
          {listUsers.map((item) => (
            <tr key={item?.Id}>
              <td style={{ paddingLeft: 16 }}><S.Image src={item?.Foto} /></td>
              <td>{validateIfNull(item?.Nome)}</td>
              <td>{item?.QtdPontos}</td>
              <td>{DataMaskFormtData(item?.DtCadastro)}</td>
              <td>
                <S.Icons>
                  <button onClick={() => {
                    setItemSelected(item)
                    setOpenEditCategoriesModal(true)
                    setRegistration({
                      nome: item?.Nome,
                      pontos: item?.QtdPontos
                    })
                    setImage(item?.Foto ? item?.Foto : offImage)
                  }
                  }>
                    <Tooltip title={t('Editar')}>
                      <Edit className="i" />
                    </Tooltip>
                  </button>

                  <button onClick={() => {
                    setItemSelected(item)
                    setOpenDeleteUserModal(true)
                  }
                  }>
                    <Tooltip title={t('Excluir')}>
                      <Delete style={{ marginLeft: '24px' }} className="i" />
                    </Tooltip>
                  </button>
                </S.Icons>
              </td>
            </tr>
          ))}
        </table>

        <ModalCustom
          open={openDeleteUserModal}
          onClose={() => setOpenDeleteUserModal(false)}
          onClick={() => {
            handleDeleteUser()
          }}
          loading={loading}
          title={t('Excluir Brinde')}
          description={t('Tem certeza de que deseja excluir o brinde? Após a confirmação, não há como voltar atrás.')}
          confirmationButtonText={t('CONFIRMAR')}
          textDeclineButton={t('CANCELAR')}
        />

        <ModalCustom
          open={openEditCategoriesModal}
          onClose={() => setOpenEditCategoriesModal(false)}
          onClick={() => registerSponsor()}
          childrenContent={
            <>
              <S.BackgroundImageProfile htmlFor="selecao-arquivo">
                <img src={image} />

                <div className='iconBackground'>
                  <AddPhotoAlternate className="iconCam" />
                </div>

                <S.Input id="selecao-arquivo" onChange={handleChange} type="file" accept="image/*" />
              </S.BackgroundImageProfile>

              <Input
                label={t('Nome')}
                type="name"
                value={registration.nome}
                onChange={(e) => setRegistration({ ...registration, nome: e.target.value })}
                size="small"
                fullWidth
                error={registrationError.nome ? true : false}
                helperText={registrationError.nome}
                onClick={() => setRegistrationError({ ...registrationError, nome: '' })}
                variant="filled"
                style={{ width: '50%', marginBottom: '16px' }}
              />

              <Input
                label={t('Quantidade de Pontos')}
                type="number"
                value={registration.pontos}
                onChange={(e) => setRegistration({ ...registration, pontos: e.target.value })}
                size="small"
                fullWidth
                error={registrationError.pontos ? true : false}
                helperText={registrationError.pontos}
                onClick={() => setRegistrationError({ ...registrationError, pontos: '' })}
                variant="filled"
                style={{ width: '50%', marginBottom: '16px' }}
              />
            </>
          }
          loading={loading}
          title={t('Editar Brinde')}
          confirmationButtonText={t('SALVAR')}
          textDeclineButton={t('CANCELAR')}
        />
      </S.Table>
    )
  }

  return renderTable()
}

export default TableGifts
