import React, { useCallback, useEffect, useState } from 'react';
import { WithTranslationProps, useTranslation, withTranslation } from 'react-i18next';
import SearchBar from '../../../../../components/SearchBar';
import ChallengeItemDesktop from '../../../../../components/ChallengeItemDesktop';
import ModalCustom from '../../../../../components/ModalCustom';
import ViewError from '../../../../../components/ViewError';
import { getChallengesCurations } from '../../../../../services/ChallengesManagement/InCurations';
import { IChalengeInCurationsQueryResponse } from '../../../../../services/ChallengesManagement/InCurations/types';
import { useIsSm } from '../../../../../hooks/useIsSm';
import ChallengeCurationDetail from './ChallengeCurationDetail';
import ViewLoading from '../../../../../components/ViewLoading';
import Loading from '../../../../../components/Loading';
import ToastNotification from '../../../../../components/ToastNotification';
import * as S from '../all/styles'

const AdmChallengeCuration: React.FC<WithTranslationProps> = () => {
  const [searchValue, setSearchValue] = useState("")
  const [selectedItem, setSelectedItem] = useState<IChalengeInCurationsQueryResponse | null>(null)
  const { t } = useTranslation();
  const isSm = useIsSm()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [challengeList, setChallengeList] = useState<IChalengeInCurationsQueryResponse[]>([])
  const [loading, setLoading] = useState(false)
  let totalPages = 0;
  let currentPage = 0;

  const handleClose = useCallback(() => {
    setSelectedItem(null)
    retrieveChallenge(1, true, searchValue);
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    retrieveChallenge(1, true);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > (300 * currentPage)) {
        if (currentPage < (totalPages || 0)) {
          retrieveChallenge(currentPage + 1, false, searchValue)
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Função de limpeza para remover o listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const retrieveChallenge = async (page: number = 1, clear: boolean = false, title?: string) => {
    if (page === 1) {
      setLoading(true)
    }
    try {
      if (clear) {
        setChallengeList([]);
      }
      const bodyData = {
        PageNumber: 1,
        PageSize: 64 * page,
        Nome: title
      };

      const response = await getChallengesCurations(bodyData);
      if (response.Success) {
        currentPage = page;
        if (page === 1 && response.TotalPages) {
          totalPages = response.TotalPages;
        }
        setChallengeList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os desafios, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os desafios, tente novamente.')
      });
    }
    setLoading(false)
  };

  const onEnterSearch = (event: { key: string }) => {
    if (event.key === 'Enter') {
      retrieveChallenge(1, true, searchValue);
    }
  };

  return (
    <S.Container>
      <SearchBar
        filterButton={false}
        value={searchValue}
        placeholder='Busque o desafio desejado'
        setValue={setSearchValue}
        onEnterSearch={(e) => onEnterSearch(e)}
      />

      <ModalCustom
        open={!!selectedItem}
        onClick={() => { }}
        onClose={handleClose}
        buttonsEnabled={false}
        title="Detalhes do Desafio"
        style={{ width: "auto" }}
        childrenContent={selectedItem ?
          <ChallengeCurationDetail item={selectedItem} onClose={handleClose} />
          : null
        }
      />

      {loading ? (
        <ViewLoading />
      ) : (
        <>
          {challengeList.length > 0 ? (
            <>
              <S.ContainerChallenge>
                {challengeList.map((item) => (
                  <>
                    {!isSm ?
                      <ChallengeItemDesktop
                        onClick={() => setSelectedItem(item)}
                        item={{
                          CoverImageUrl: item.CoverImageUrl,
                          Language: '1',
                          AuthorName: item.AuthorName,
                          Score: 0,
                          Title: item.Title,
                          Id: item.Id.toString(),
                          NomeCurador: item.CuratorName,
                          DateTime: item.DateTime
                        }}
                        showAuthorName
                        showId
                        hidenDifficultyInfo
                        hidenBottonInfo
                        showCreateDate
                        showModeratorName
                        especialProgram={item?.SpecialProgramContent}
                      />
                      :
                      <ChallengeItemDesktop
                        style={{ width: windowWidth < 400 ? 'none' : '90%', margin: '0' }}
                        onClick={() => setSelectedItem(item)}
                        item={{
                          CoverImageUrl: item.CoverImageUrl,
                          Language: '1',
                          AuthorName: item.AuthorName,
                          Score: 0,
                          Title: item.Title,
                          Id: item.Id.toString(),
                          NomeCurador: item.CuratorName,
                          DateTime: item.DateTime
                        }}
                        showAuthorName
                        showId
                        hidenDifficultyInfo
                        hidenBottonInfo
                        showCreateDate
                        showModeratorName
                        especialProgram={item?.SpecialProgramContent}
                      />
                    }
                  </>
                ))}
              </S.ContainerChallenge>
              {loading && <Loading />}
            </>
          ) : (
            <ViewError message={t("Não há desafios a serem curados, tente novamente mais tarde")} />
          )}
        </>
      )}
    </S.Container>
  )
}

export default withTranslation()(AdmChallengeCuration);