import { Box, styled } from '@mui/material';

export const ItemContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  backgroundColor: "#F9F9F9",
  borderBottom: "1px solid #DBDADA"
}));
