import styled from 'styled-components'
import { theme } from '../../styles/theme'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`

export const Box = styled.div`
    width: 300px;
    height: 250px;
    margin: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border: solid 0.5px ${(props) => props.theme.colors.black2};
    border-radius: 8px;

    h5 {
      margin-left: 12px;
      line-height: 24px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(20)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 500;
      font-family: 'Barlow', Medium;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(18)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(24)};
      }
    }

    h3 {
      margin-left: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(10)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

    &:hover {
      opacity: 0.8;
    }
`

export const BoxChallengePending = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 280px;
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 8px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  position: relative;
  margin: 0px 8px 24px 8px;
  border: solid 1px ${(props) => (props.active ? theme.colors.seventh : 'transparent')}; 
  @media (min-width: 1600px) {
    width: 390px;
  }

  .topLeft {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 12px;
    top: 12px;
    background-color: ${(props) => props.theme.colorsBase.white};
    border: solid 1px ${(props) => props.theme.colors.secundary};
    border-radius: 4px;
    height: 24px;
    padding: 0 4px;
  }

  .topRight {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 12px;
    top: 12px;
    background-color: ${(props) => props.theme.colorsBase.white};
    border: solid 1px ${(props) => props.theme.colors.seventh};
    border-radius: 4px;
    height: 24px;
    padding: 0 4px;

    .textMin {
      text-align: center;
      font-size: ${responsiveFont(10)};
      color: ${(props) => props.theme.colors.seventh};
      font-weight: 500;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(12)};
      }
    }
  }

  .topRight2 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 12px;
  top: 12px;
  background-color: ${(props) => props.theme.colorsBase.white};
  border: solid 1px ${(props) => props.theme.colors.seventh};
  border-radius: 4px;
  height: 24px;

  .textMin2 {
    text-align: center;
    font-size: ${responsiveFont(10)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 500;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(12)};
    }
  }
};

  .boxViewed {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 8px;
    top: 8px;
  }

  .iconViewed {
    color: ${(props) => props.theme.colorsBase.white};
    font-size: 16px;
  }

  .boxPremium {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 8px;
    top: 155px;

    @media (min-width: 1600px) {
      top: 225px;
    }

    @media (max-width: 680px) {
      top: 200px;
    }
  }

  .iconPremium {
    color: ${(props) => props.theme.colorsBase.white};
    font-size: 24px;
  }

  .containerContent {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;

    .leftItens {
      display: flex;
      flex-direction: row;
    }
  }

  .containerChip {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding-left: 8px;
  }

  .containerBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-top: 8px;
    padding: 0px 12px 12px 12px;
  }
  
  .boxContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 12px;

    .boxTitle {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 4px;
      
      .iconTitle {
        color: ${theme.colors.friday};
        font-size: 20px;

        &:hover {
          color: ${theme.colors.seventh};
        }
      }

      .iconTitleError {
        color: ${theme.colors.friday};
        font-size: 20px;

        &:hover {
          color: ${theme.colorsBase.red2};
        }
      }

      .titleB {
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        line-height: 16px;
        letter-spacing: 0.1px;
        text-align: center;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(16)};
        }
      }
    }

    .containerProgressBar {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-self: center;
      width: 60%;
      margin-top: 4px;
      margin-left: 4px;

      .textProgress {
        margin-left: 6px;
        line-height: 10px;
        letter-spacing: 0.8px;
        text-align: left;
        font-size: ${responsiveFont(10)};
        color: ${(props) => props.theme.colorsBase.black5};
        font-weight: 400;
        font-family: 'Barlow', Medium;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(10)};
        }
      }
    }

      .title {
        width: 100%;
        margin: 0 -12px;
        align-self: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        margin-top: 8px;
        line-height: 16px;
        letter-spacing: 0.1px;
        text-align: start;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(16)};
        }
      }

      .boxUser {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 4px;
        width: 100%;

        .i {
          color: #201E1E;
          font-size: 16px;
      
          &:hover {
            color: ${theme.colors.black};
          }
        }
  
        .nameUser {
          max-width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
  
          margin-left: 8px;
          line-height: 16px;
          letter-spacing: 0.4px;
          text-align: left;
          font-size: ${responsiveFont(12)};
          color: ${(props) => props.theme.colorsBase.gray13};
          font-weight: 400;
          font-family: 'Barlow', Regular;
          
          @media (max-width: 680px) {
            font-size: ${responsiveFont(10)};
          }
          
          @media (min-width: 1600px) {
            font-size: ${responsiveFont(14)};
          }
        }

        b {
          text-align: left;
          font-size: ${responsiveFont(12)};
          color: ${(props) => props.theme.colorsBase.gray13};
          font-weight: 700;
          font-family: 'Barlow', Regular;
          
          @media (max-width: 680px) {
            font-size: ${responsiveFont(10)};
          }
          
          @media (min-width: 1600px) {
            font-size: ${responsiveFont(14)};
          }
        }
      }
    
  }

  &:hover {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
 }
`

export const SelectedLanguage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .flag {
    width: 24px;
    object-fit: cover;
  }
`

export const ChipMonochrome = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 12px;
  height: 30px;
  background-color: transparent;
  border-radius: 16px;
  border: solid 1px ${(props) => props.theme.colors.eleven};
  margin-left: 8px;

  .i {
    color: ${theme.colors.seventh};
    font-size: 16px;
    margin-right: 8px;
  }

  .titleChip {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }
`

export const ContainerChallenge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 8px 0px 8px;
    width: 100%;
  }
  
  @media (min-width: 1600px) {
    gap: 100px;
  }
`

export const Chip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2px;

  .i {
    color: ${theme.colors.seventh};
    font-size: 16px;
    margin-right: 8px;
  }

  .titleChip {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.seventh};

    .i {
      color: ${theme.colorsBase.white};
    }

    .titleChip {
      color: ${(props) => props.theme.colorsBase.white};
    }
  }
`

export const BoxCategory = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 12px;
  height: 30px;
  background-color: transparent;
  border-radius: 16px;
  border: solid 1px ${(props) => props.theme.colors.eleven};

  .i {
    color: ${theme.colors.black};
    font-size: 16px;
    margin-right: 8px;
  }

  .titleChip {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }
`

export const IconPlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 150px;
  max-height: 150px;
  border-radius: 4px 4px 0px 0px;
  position: absolute;
  top: 0;

  .i {
    color: ${theme.colors.primary};
    font-size: 32px;
  }
`

export const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  aspect-ratio: 13/9;

  .iconPlay {
    color: ${theme.colors.primary};
    font-size: 32px;
    position: absolute;
    visibility: hidden;
    &:hover {
      scale: 1.3
    }
  }

  &:hover {
    .iconPlay {
      visibility: visible;
    }
  }
`

export const Image = styled.img<{ active?: boolean }>`
  width: 100%;
  border-radius: 8px  8px  0px  0px;
  object-fit: cover; 
  filter: ${(props) => (props.active ? ' grayscale(100%)' : ' grayscale(0%)')}; 
`

export const ImageVideo = styled.img<{ active?: boolean }>`
  width: 100%;
  border-radius: 8px  8px  0px  0px;
  object-fit: cover; 
  filter: ${(props) => (props.active ? ' grayscale(100%)' : ' grayscale(0%)')}; 
  display: flex;


`

export const ContainerVideo = styled.video`
  width: 100%;
  border-radius: 8px  8px  0px  0px;
  background-color: black;
  position: relative;
`

export const BoxOptionsVideo = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colorsBase.black5};                     
  padding: 8px;
  border-radius: 50%;
  position: absolute;
  right: 8px;
  top: 8px;
  border: none;
  z-index: 999;

  .iOptionVideo {
    width: 24px;
    object-fit: cover;
    color: ${(props) => props.theme.colorsBase.white};       
  }
`

export const BoxContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  h5 {
    font-weight: 400;
    letter-spacing: 0.71px;
    margin-left: 4px;
    color: ${(props) => props.theme.colorsBase.gray13};
    font-size: ${responsiveFont(12)};
    font-family: 'Barlow', Regular;
  }                       

  .i {
    width: 16px;
    object-fit: cover;
  }

  @media (max-width: 680px) {
    .i {
      width: 12px;
    }

    h5 {
      font-size: ${responsiveFont(10)};
    } 
  }
`

export const ButtonViewMore = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 12px;
  // height: 30px;

  .title {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.friday};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  &:hover {
      opacity: 0.8;
  }
`

export const ContainerTotal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .messagePending {
    width: 100%;
    margin-bottom: 8px;
    line-height: 14px;
    letter-spacing: 0.1px;
    text-align: center;
    font-size: ${responsiveFont(10)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(9)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(12)};
    }
  }
`

export const BoxPoint = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .titlePoint {
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  &:hover {
    opacity: 0.8;
  }
`

export const ChallengeUnavailable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.friday};
  padding: 4px;

  position: absolute;
  right: 35%;
  top: 155px;

  .titleChallengeUnavailable {
    text-align: left;
    font-size: ${responsiveFont(10)};
    color: ${(props) => props.theme.colors.friday};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(12)};
    }
  }

  &:hover {
    opacity: 0.8;
  }
`

export const ContainerNotContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px 0px;
  width: 100%;

  .topLine {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 4px;
    background-color: ${(props) => props.theme.colors.black4};
    width: 30%;
    margin-bottom: 8px;
  }
  
  .bottomLine {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 4px;
    background-color: ${(props) => props.theme.colors.black4};
    width: 50%;
  }
`
export const StatusTag = styled.div<{ approved?: boolean; rejected?: boolean; underReview?: boolean }>`
  display: flex;
  align-items: center;
  margin: auto;
  padding: 0 4px 0 4px;
  gap: 5px;
  border-radius: 4px;
  border: 1px solid ${({ approved, rejected, underReview }) => {
    if (approved) return 'black';
    if (rejected) return '#E81010';
    if (underReview) return '#0C4FE5';
    return 'transparent';
  }};
  color: ${({ approved, rejected, underReview }) => {
    if (approved) return 'black';
    if (rejected) return '#E81010';
    if (underReview) return '#0C4FE5';
    return 'transparent';
  }};
  background-color: white;
  font-size: ${responsiveFont(12)};

  .iconStatus {
    margin-right: 4px;
  }
`;

export const ContainerSpecial1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px  32px 24px 32px;

  @media (max-width: 680px) {
    flex-direction: column;
    padding: 16px 16px 16px 16px;
  }
`

export const ContainerSpecialDiv1 = styled.div<{ noShadow?: boolean }>` 
  box-shadow: 1px 4px 2px 2px ${(props) => (props.noShadow ? 'none' : 'rgba(0, 0, 0, 0.2)')};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 550px;

  .topBox {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media (max-width: 680px) {
    flex-direction: column;
    height: 100%;
    min-height: 100%;

    .topBox {
      flex-direction: column;
    }
  }
`

export const ContainerSpecialDiv2 = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 24px;
  flex-direction: column;

  img{
    width: 100%;
    border-radius: 8px;
    object-fit: cover; 
  }

  ul{
    list-style: none;
    margin-top: 1rem;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colorsBase.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
  }

  ul li{
    display: flex;
    margin-bottom: 8px;
    gap: 0.5rem;
  }

  ul li span{
    color: ${theme.colors.seventh};
    font-size: ${responsiveFont(14)};
  }
`

export const ContainerSpecialDiv3 = styled.div`
width: 100%;
margin-top: 24px;
text-align: left;
font-size: ${responsiveFont(14)};
color: ${(props) => props.theme.colorsBase.black};
font-weight: 400;
font-family: 'Barlow', Regular;
padding: 0 16px 0 16px;

@media (max-width: 680px) {
  margin-top: -16px;
}
`

export const ContainerSpecialDiv4 = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 24px;
  margin-top: auto;

  @media (max-width: 680px) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`

export const ContainerSpeciaLParticipants = styled.div`
  display: flex;
  width: 100%;
  height: 550px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 1px 4px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 0 0 0 24px;

  @media (max-width: 680px) {
    margin: 16px 0 0 0;
    height: 200px;
  }
`

export const ContainerParticipants = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px 8px 16px 8px;

  @media (max-width: 680px) {
    padding: 8px 8px 8px 8px;
  }
`

export const ContainerContents = styled.div`
  justify-content: center;
  align-items: center;
  margin: 24px 2rem;
  gap: 20px;
  height: 50vh;
  display: flex;
  box-shadow: 1px 4px 2px 2px rgba(0, 0, 0, 0.2); 
  border-radius: 0.5rem;

`

export const ContainerContentsCard = styled.div`
  height:100%;
  display: grid;
  grid-template-columns:1fr 2fr 2fr 2fr 1fr;
  padding: 1rem;
  font-size:20px;
  gap: 20px;

`

export const ContentsCard = styled.div`
  box-shadow: 1px 4px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 1.5rem 1.5rem 0.5rem 0.5rem;

  img{
    width: 100%;
    height: 30vh;
    border-radius: 1.2em 1.2em 0 0;
  }

  div{
    /* margin-left:2rem */
  }

`
export const CardContent = styled.div`
  margin-left: 2rem;

  div{
    margin-right: 2rem;
    display: flex;
    justify-content: space-between;
  }
`

export const ArrowContents = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
`

export const ButtonContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom:20px;
`
