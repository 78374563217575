import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const ContainerData = styled.div`
  display: flex;
  height: 100%
  align-items: flex-start;
  padding: 0px 24px 24px 24px;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 680px) {
    padding: 0px;
  }
`

export const ImageContent = styled.img`
  width: 100%;
  border: solid 1px ${(props) => props.theme.colorsBase.gray};
  object-fit: cover; 
`

export const VideoContent = styled.video`
  width: 100%;
  border: solid 1px ${(props) => props.theme.colorsBase.gray};
  object-fit: cover; 
`

export const BoxTitle = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 24px;
  
    .boxText {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin: 12px 0;
  
        h2 {
          text-align: left;
          font-size: ${responsiveFont(12)};
          color: ${(props) => props.theme.colors.fourth};
          font-weight: 400;
          font-family: 'Barlow', Regular;
          
          @media (min-width: 1600px) {
            font-size: ${responsiveFont(14)};
          }
        }
      
      h1 {
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colorsBase.black3};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }
    }
    
`