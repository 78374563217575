import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles'
import { ICurators } from '../../pages/MyHealers/types';
import { Language, PhotoCamera } from '@mui/icons-material'
import { validateIfNull } from '../../utils/UtilsGlobal';
import CardAchievements from '../../components/CardAchievements';
import facebook from '../../assets/images/icon_facebook.png'
import instagram from '../../assets/images/icon_instagram.png'
import twitter from '../../assets/images/icon_twitter.png'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth'

interface IProps {
  curator?: ICurators
}

const CuratorsDetails: React.FC<IProps> = ({ curator }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { setLink } = useAuth()

  const openLink = (id: string) => {
    navigate(`/link_externo`)
    setLink(id)
  };

  return (
    <S.ContainerContent>
      <S.ContainerInfo>
        <S.BoxCurator>
          {curator?.ProfilePhotoUrl ? (
            <S.Image src={curator?.ProfilePhotoUrl} />
          ) : (
            <div className='noImage'>
              <PhotoCamera className="icon" />
            </div>
          )}

          <div className='boxContent'>
            <h1 className='title'>{curator?.Name}</h1>
            <h1 className='subTitle'>{curator?.RegulatoryBoad}</h1>
          </div>
        </S.BoxCurator>

        <S.Description>{t('Sobre')}: {validateIfNull(curator?.AboutProfessional)}</S.Description>

        {curator?.SiteUrl &&
          <S.ButtonLink>
            <Language className="icon" />
            <button className='link' onClick={() => openLink(curator?.SiteUrl)}>{curator?.SiteUrl}</button>
          </S.ButtonLink>
        }
      </S.ContainerInfo>

      <S.ContainerLink>
        <div className='boxLink'>
          {curator?.FacebookUrl &&
            <S.ButtonLink>
              <img src={facebook} className="icon" alt="" />
              <button className='link' onClick={() => openLink(curator?.FacebookUrl)}>{curator?.FacebookUrl}</button>
            </S.ButtonLink>
          }
          {curator?.InstagramUrl &&
            <S.ButtonLink>
              <img src={instagram} className="icon" alt="" />
              <button className='link' onClick={() => openLink(curator?.InstagramUrl)}>{curator?.InstagramUrl}</button>
            </S.ButtonLink>
          }
          {curator?.XUrl &&
            <S.ButtonLink>
              <img src={twitter} className="icon" alt="" />
              <button className='link' onClick={() => openLink(curator?.XUrl)}>{curator?.XUrl}</button>
            </S.ButtonLink>
          }
        </div>

        <div className='boxCard'>
          <CardAchievements title={t('Total de Revisões')} description={t('Total de desafios revisados pelo curador:')} value={String(curator?.CurationCount)} />
        </div>
      </S.ContainerLink>
    </S.ContainerContent>
  )
}

export default CuratorsDetails;
