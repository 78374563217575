import { IProduct } from "../../../services/Product/types";


export const emptyItem: IProduct = {
  Id: "",
  Name: "",
  Enabled: true,
  Count: 0,
  Description: "",
  Points: 0,
  ImageUrl: "",
  Type: 0,
  LimitPerUser: 0,
}

export interface ITableUsers {
  listUsers: IProduct[]
  updateList: () => void
}

export interface IProductCreationAndEditingProps {
  registration: Registration
  registrationError: RegistrationError
  setRegistration: (body: Registration) => void
  setRegistrationError: (body: RegistrationError) => void
  exchangeOfPoints?: boolean
  urlImage?: string
  setUrlImage: (e: string) => void
  edit?: boolean
  id?: string
  returnWarning?: boolean
  type?: number
}

export class Registration {
  name = '';
  details = '';
  availableQuantity = '';
  exchangeScore = '';
  limitPerUser = '';

  sponsored = '';
  expirationDate = '';
  withdrawalDeadline = '';

  minimumScore = '';
  maximumScore = '';
  alternativePunctuation = '';
}

export class RegistrationError {
  name = '';
  details = '';
  availableQuantity = '';
  exchangeScore = '';
  limitPerUser = '';

  sponsored = '';
  expirationDate = '';
  withdrawalDeadline = '';

  minimumScore = '';
  maximumScore = '';
  alternativePunctuation = '';
}