import { useState, } from 'react'
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import { AddAPhoto, Save, } from '@mui/icons-material'
import { useTranslation } from 'react-i18next';
import { theme } from '../../styles/theme';
import * as S from './styles'
import ButtonMaterial from '../Button';
import { useIsSm } from '../../hooks/useIsSm';

interface IProps {
  onClickAdd?: () => void
  onClickSave: (e: File) => void
  imageSet: File
  aspectRatioSquare?: boolean
}

const ImageAdjustment: React.FC<IProps> = ({ onClickAdd, imageSet, onClickSave, aspectRatioSquare = false }) => {
  const { t } = useTranslation();
  const isSm = useIsSm()
  const [cropper, setCropper] = useState<Cropper>();

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      cropper.getCroppedCanvas().toBlob((blob) => {
        if (blob) {
          const file = new File([blob], 'profile-pic.png')
          onClickSave(file)
        }
      })
    }
  };

  return (
    <S.ContainerTotal>
      <div className='boxImage' >
        <Cropper
          style={{ height: 350, width: "100%" }}
          zoomTo={0.5}
          preview=".img-preview"
          src={URL.createObjectURL(imageSet)}
          aspectRatio={aspectRatioSquare ? 1 : 13 / 9}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={true}
        />
      </div>

      <div className='bottomButtons'>
        <div className='imageOptions'>
          <button className='buttonOptions' onClick={onClickAdd} >
            <AddAPhoto className='iOptions' />
            <h1 className='textOptions'>{t('NOVA FOTO')}</h1>
          </button>
        </div>

        <ButtonMaterial
          style={{ border: `solid 1px ${theme.colors.seventh}`, width: isSm ? '100%' : '50%' }}
          variant="contained"
          color='info'
          label={t("SALVAR")}
          icon={<Save style={{ fontSize: '24px', marginRight: '4px', color: theme.colorsBase.white }} />}
          onClick={getCropData}
        />
      </div>
    </S.ContainerTotal>
  )
}

export default ImageAdjustment;
