import React from 'react'
import { useAuth } from '../../contexts/Auth';
import Sidebar from '../Sidebar';

import * as S from './styles'

const ContainerApp: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth()
  
  return (
    <div style={{ minHeight: '100vh', maxWidth: '100vw' }}>
      <S.Content>
        {isAuthenticated && <Sidebar />}
        <S.Body signed={isAuthenticated}>{children}</S.Body>
      </S.Content>
    </div>
  )
}

export default ContainerApp
