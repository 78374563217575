import React from 'react';
import { Box, Card, CardContent, CardProps, Stack, Typography } from '@mui/material';

import ChallengeInpactGraph from '../../../components/Graph/ChallengeInpactGraph';
import { UseQueryResult } from '@tanstack/react-query';
import { IBaseResponse } from '~/services/RequestService/types';
import { IChallengeDashboardQueryResponse } from '~/services/Dashboard/ChallengesDashboard/types';

interface IProps extends CardProps<typeof Box> {
  endDate: number | Date
  query: UseQueryResult<IBaseResponse<IChallengeDashboardQueryResponse>, unknown>
}

const ChallengeImpact: React.FC<IProps> = ({ endDate, query, ...props }) => {

  return (
    <Card component={Box} {...props}>
      <CardContent>
        <Stack p={2} spacing={2} height="248px">
          <Stack
            alignItems='baseline'
            spacing={1}
            sx={(theme) => ({
              flexDirection: "row",
              [theme.breakpoints.down(800)]: {
                flexDirection: "column"
              }
            })}
          >
            <Typography fontSize="20px" fontWeight="400" color='#201E1E'>Impacto dos Desafios</Typography>
            <Typography fontSize="14px" fontWeight="400" color='#201E1E'>(Nos últimos 15 dias)</Typography>
          </Stack>

          <Box flex={1} >
            <ChallengeInpactGraph endDate={endDate} data={query.data?.Data.Performance} />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default ChallengeImpact;
