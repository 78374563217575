import React, { useEffect } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import ModalCustom from '../../../../components/ModalCustom';

interface ApproveChallenge {
  showCuratorNameInChallenge: boolean
}

interface IProps {
  open: boolean;
  onClose: () => void;
  onSubmit: SubmitHandler<ApproveChallenge>;
  loading?: boolean;
}

const ApproveChallengeModal: React.FC<IProps> = ({ open, loading, onClose, onSubmit }) => {
  const { t } = useTranslation()

  const { control, handleSubmit, reset } = useForm<ApproveChallenge>({
    defaultValues: { showCuratorNameInChallenge: false }
  });

  useEffect(() => reset(), [open])

  return (
    <ModalCustom
      open={open}
      onClick={handleSubmit((data) => { onSubmit(data); onClose(); })}
      onClose={onClose}
      buttonsEnabled={true}
      title={t("Aprovar Desafio")}
      childrenContent={(
        <Stack width="100%" spacing={2}>
          <Controller
            control={control}
            name='showCuratorNameInChallenge'
            render={({ field: { value, ...field } }) => (
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormControlLabel
                  control={<Checkbox checked={value} {...field} />}
                  label={t("Deseja que seu nome seja exibido publicamente como o moderador responsável por aprovar esse desafio?")}
                />
                <FormHelperText>Caso não deseje que seu nome seja exposto, basta não marcar a opção acima</FormHelperText>
              </FormControl>
            )}
          />
        </Stack>
      )}
      loading={loading}
      confirmationButtonText={t("ENVIAR")}
      textDeclineButton={t("CANCELAR")}
    />
  )
}

export default ApproveChallengeModal;
