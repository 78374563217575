import React, { useEffect, useState, } from 'react';
import * as S from './styles'
import { useAuth } from '../../contexts/Auth';
import { ChevronLeft, ChevronRight, Done } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ToastNotification from '../../components/ToastNotification';
import ViewLoading from '../../components/ViewLoading';
import { IChallengeExecutionQuestions } from '../../services/Challenges/types';
import { postAnswerQuestion, postFinishChallenge, postStartExecution, } from '../../services/Challenges';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { theme } from '../../styles/theme';
import ButtonMaterial from '../../components/Button';
import ReactAudioPlayer from 'react-audio-player';
import BoxAssessment from '../../components/BoxAssessment';
import ChallengeHeader from '../../components/ChallengeHeader';
import { useIsSm } from '../../hooks/useIsSm';
import ModalCustomBottom from '../../components/ModalCustomBottom';
import ModalCustom from '../../components/ModalCustom'

const AnswerQuestions: React.FC = () => {
  const { challenge } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { token, token2 } = useParams();
  const [questionContent, setQuestionContent] = useState<IChallengeExecutionQuestions[]>([])
  const [loading, setLoading] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [questionAnswer, setQuestionAnswer] = useState('')
  const isSm = useIsSm()
  const [questionsAnswered, setQuestionsAnswered] = useState<string[]>([])
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    startExecution();
  }, []);

  const startExecution = async (update?: boolean) => {
    if (!update) { setLoading(true) }
    try {
      const response = await postStartExecution(token ?? '');
      if (response.Success) {
        setQuestionContent(response.Data.Questions ?? [])

        const tempQuestionsAnswered: string[] = []
        response.Data?.Questions?.filter((itemX) => itemX?.Answered === true).map((item) => {
          tempQuestionsAnswered.push(item.Id)
        })
        setQuestionsAnswered(tempQuestionsAnswered)

        if (!update) {
          setCurrentQuestion(response.Data.NumberOfQuestionsAnswered ?? 0)
        } else if ((currentQuestion + 1) < questionContent.length) {
          setCurrentQuestion(currentQuestion + 1)
          setQuestionAnswer('')
        } else {
          setQuestionAnswer('')
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.'),
      });
    }
    setLoading(false)
  };

  const postAnswer = async () => {
    setLoading(true)
    try {
      if (questionAnswer === '') {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Selecione uma alternativa!')
        });
        setLoading(false);
        return;
      }

      const bodyData = {
        ChallengeExecutionId: token ?? "",
        ChallengeQuestionId: questionContent[currentQuestion]?.ChallengeQuestionId,
        ChallengeQuestionExecutionId: questionContent[currentQuestion]?.Id,
        Answers: [{ ChallengeAswerId: questionAnswer }]
      };
      const response = await postAnswerQuestion(bodyData);
      if (response.Success) {
        startExecution(true);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao responder a pergunta, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao responder a pergunta, tente novamente.')
      });
    }
    setLoading(false)
  };

  const handleFinishChallenge = async () => {
    setLoading(true)
    try {
      const response = await postFinishChallenge(token ?? '');
      if (response.Success) {
        navigate(`/desafio/concluido/${token}/${token2 === 'PE' ? 'PE' : ''}`)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao finalizar o desafio, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao finalizar o desafio, tente novamente.')
      });
    }
    setLoading(false)
  };

  const backQuestion = () => {
    setQuestionAnswer('')
    setCurrentQuestion(currentQuestion - 1)
  };

  const advanceQuestion = () => {
    setQuestionAnswer('')
    if (questionContent.length === (currentQuestion + 1)) {
      if (questionsAnswered.length === (currentQuestion + 1)) {
        handleFinishChallenge()
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Responda todas as questões antes de finalizar!')
        });
      }
    } else {
      setCurrentQuestion(currentQuestion + 1)
    }
  };

  return (
    <S.Container>
      <ChallengeHeader
        title={`${t('Pergunta')} ${currentQuestion + 1} ${t('de')} ${questionContent?.length}`}
        buttonNavigate={() => navigate('/home')}
        mediaAvaliacoes={challenge?.AverageRating}
        qtdAvaliacoes={String(challenge?.RatingReviewCount)}
        id={challenge?.Id}
      />

      <S.ContainerContent>
        {loading ? (
          <ViewLoading />
        ) : (
          <>
            {questionContent?.length >= 0 &&
              <S.AnswerQuestions>
                <div className='divContentText'>
                  <div className='divNumber'>
                    <h3>{currentQuestion + 1}</h3>
                  </div>

                  <h5>{t(questionContent[currentQuestion]?.Question ?? '')}</h5>
                </div>

                {questionContent[currentQuestion]?.AudioUrl && (
                  <ReactAudioPlayer
                    src={questionContent[currentQuestion]?.AudioUrl}
                    controls
                    style={{ marginTop: !isSm ? '16px' : '8px', width: !isSm ? '50%' : '100%' }}
                  />
                )}

                <div className='divContent'>
                  {questionContent[currentQuestion]?.Alternatives.map((item, index) => (
                    <div key={item.Id} className='boxAlternative'>
                      <h5>{index + 1}.</h5>

                      <button className='alternative' onClick={() => setQuestionAnswer(item.Id)}>
                        <FormControlLabel className="option" checked={questionAnswer ? (questionAnswer === item.Id) : (questionContent[currentQuestion]?.Alternatives.filter((itemX) => itemX?.SelectedAnswer === true)[0]?.Id === item.Id)} control={<Radio />} label={item.Answer} />
                      </button>
                    </div>
                  ))}
                </div>

                <ButtonMaterial
                  style={{ backgroundColor: (questionContent[currentQuestion]?.Answered && !questionAnswer) ? theme.colorsBase.gray3 : theme.colors.seventh, color: (questionContent[currentQuestion]?.Answered && !questionAnswer) ? theme.colorsBase.green2 : theme.colorsBase.white, marginTop: '32px', marginLeft: !isSm ? '48px' : '0px', width: !isSm ? 'auto' : '100%' }}
                  variant="contained"
                  color='info'
                  label={(questionContent[currentQuestion]?.Answered && !questionAnswer) ? t("RESPOSTA SALVA") : t("SALVAR RESPOSTA")}
                  icon={(questionContent[currentQuestion]?.Answered && !questionAnswer) ? <Done style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.green2 }} /> : <Done style={{ fontSize: '24px', marginRight: '8px' }} />}
                  onClick={postAnswer}
                  disable={(questionContent[currentQuestion]?.Answered && !questionAnswer)}
                />

                <S.ContainerButtons>
                  <div className='divButtonTop'>
                    {isSm &&
                      <ButtonMaterial
                        style={{ marginRight: 'auto', width: '40%', border: `solid 1px ${currentQuestion !== 0 ? theme.colors.seventh : theme.colorsBase.gray3}`, color: currentQuestion !== 0 ? theme.colors.seventh : theme.colorsBase.gray3 }}
                        variant="outlined"
                        color='info'
                        label={t("ANTERIOR")}
                        icon={<ChevronLeft style={{ fontSize: '24px', marginLeft: '8px', color: currentQuestion !== 0 ? theme.colors.seventh : theme.colorsBase.gray3 }} />}
                        onClick={backQuestion}
                        disable={currentQuestion === 0}
                      />
                    }

                    <ButtonMaterial
                      style={{ backgroundColor: questionContent.length === (currentQuestion + 1) ? theme.colorsBase.green2 : 'transparent', width: !isSm ? 'auto' : '40%', border: `solid 1px ${questionContent.length === (currentQuestion + 1) ? 'transparent' : theme.colors.seventh}`, color: questionContent.length === (currentQuestion + 1) ? theme.colorsBase.white : theme.colors.seventh }}
                      variant="outlined"
                      color='info'
                      label={questionContent.length === (currentQuestion + 1) ? t("FINALIZAR") : t("PRÓXIMA")}
                      iconRight={<ChevronRight style={{ fontSize: '24px', marginLeft: '8px', color: questionContent.length === (currentQuestion + 1) ? theme.colorsBase.white : theme.colors.seventh }} />}
                      onClick={advanceQuestion}
                    />
                  </div>

                  <ButtonMaterial
                    style={{ width: !isSm ? 'auto' : '100%', backgroundColor: questionsAnswered.length === questionContent.length ? theme.colorsBase.gray3 : theme.colors.secundary }}
                    variant="contained"
                    color='info'
                    label={t("CONTINUAR DEPOIS")}
                    onClick={() => setOpenModal(true)}
                    disable={questionsAnswered.length === questionContent.length}
                  />

                  {!isSm &&
                    <ButtonMaterial
                      style={{ marginRight: '32px', width: 'auto', border: `solid 1px ${currentQuestion !== 0 ? theme.colors.seventh : theme.colorsBase.gray3}`, color: currentQuestion !== 0 ? theme.colors.seventh : theme.colorsBase.gray3 }}
                      variant="outlined"
                      color='info'
                      label={t("PERGUNTA ATERIOR")}
                      icon={<ChevronLeft style={{ fontSize: '24px', marginLeft: '8px', color: currentQuestion !== 0 ? theme.colors.seventh : theme.colorsBase.gray3 }} />}
                      onClick={backQuestion}
                      disable={currentQuestion === 0}
                    />
                  }
                </S.ContainerButtons>
              </S.AnswerQuestions>
            }
          </>
        )}
      </S.ContainerContent>

      {!isSm ? (
        <ModalCustom
          open={openModal}
          onClose={() => setOpenModal(false)}
          onClick={() => navigate('/home')}
          title={t('Continuar Depois')}
          confirmationButtonText={t('CONFIRMAR')}
          textDeclineButton={t('CANCELAR')}
          description={t('Deseja continuar o quiz mais tarde? Suas respostas salvas serão mantidas.')}
          styleButtons={{ justifyContent: 'center' }}
        />
      ) : (
        <ModalCustomBottom
          open={openModal}
          onClose={() => setOpenModal(false)}
          onClick={() => navigate('/home')}
          title={t('Continuar Depois')}
          showIcon={false}
          description={t('Deseja continuar o quiz mais tarde? Suas respostas salvas serão mantidas.')}
          buttonsEnabled={true}
          confirmationButtonText={t('CONFIRMAR')}
          textDeclineButton={t('CANCELAR')}
          styleButtons={{ justifyContent: 'center' }}
          buttonClose
        />
      )}

      <BoxAssessment id={challenge?.Id} content={token2 === 'PE'} />
    </S.Container>
  )
}

export default AnswerQuestions
