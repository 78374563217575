import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import ToastNotification from "../components/ToastNotification";
import { IQuizForm } from "../components/forms/QuizForm";
import { postQuestion, postResponse } from "../services/Challenges";

export const useCreateQuizMutation = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const mutation = useMutation(async (data: IQuizForm & { challengeId: number }) => {
    const formData = new FormData();
    formData.append('DesafioConteudoId', data.challengeId.toString());
    formData.append('Pergunta.TipoPergunta', data.questionType);
    formData.append('Pergunta.Pergunta', data.question);
    if (data.audio) formData.append('Pergunta.Audio', data.audio);

    const response = await postQuestion(formData);
    if (!response.Success) throw new Error();

    const responses = [data.response1, data.response2, data.response3, data.response4, data.response5];

    const arrayResponse = data.questionType === '1'
      ? responses.filter((response) => !!response).map((response, index) => ({
        "Resposta": String(response),
        "RespostaCorreta": data.trueAnswer === String(index + 1) ? 'true' : 'false'
      }))
      : [
        { "Resposta": "verdadeiro", "RespostaCorreta": data.status ? 'true' : 'false' },
        { "Resposta": "falso", "RespostaCorreta": !data.status ? 'true' : 'false' }
      ]

    const responseR = await postResponse(arrayResponse, response.Data);
    if (!responseR.Success) throw new Error(responseR.Message, { cause: responseR.Errors });

  }, {
    onSettled: () => Promise.all([
      queryClient.invalidateQueries(["useChallengesInModeration"], { exact: false }),
      queryClient.invalidateQueries(["useChallengesInTriage"], { exact: false })
    ]),
    onError: () => ToastNotification({
      id: 'error',
      type: 'error',
      message: t('Houve um erro ao cadastrar o quiz, tente novamente.'),
    })
  })

  return mutation
}
