import { Button, ButtonProps } from '@mui/material';
import React, { useCallback, useState } from 'react';

interface IProps extends Omit<ButtonProps, "children"> {
  initialState?: boolean;
  buttonChildren?: ButtonProps['children']
  children?: React.FC<{ open: boolean, onClose: () => void }>
}

const ModalButton = ({ initialState = false, buttonChildren, children, onClick, ...props }: IProps) => {
  const [open, setOpen] = useState(initialState)

  const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>((e) => {
    setOpen(true)
    onClick?.(e)
  }, [onClick])

  return (
    <>
      {children?.({ open, onClose: () => setOpen(false) }) ?? null}
      <Button {...props} onClick={handleClick} children={buttonChildren} />
    </>
  );
}

export default ModalButton;
