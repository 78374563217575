import React from 'react';

import BackPageHeader from '../../components/Template/BackPageHeader';
import PageContainer from '../../components/Layout/PageContainer';
import PaperContainer from '../../components/Layout/PaperContainer';
import { AntTab, AntTabs } from '../../components/Tabs';
import InspectionPendents from './components/InspectionPendents';

interface IProps {
  tab: number;
  setNewTab: (newTab: number) => void;
  variant?: "fullWidth" | "standard" | "scrollable";
}

const InspectionView: React.FC<IProps> = ({ setNewTab, tab, variant }) => (
  <PageContainer>
    <BackPageHeader title='Moderação' />
    <PaperContainer>
      <AntTabs value={tab} onChange={(_, newValue) => setNewTab(newValue)} variant={variant}>
        <AntTab label="CURADORIA" />
        {/* <AntTab label="moderados" /> */}
      </AntTabs>
      {tab === 0 ? <InspectionPendents /> : null}
    </PaperContainer>
  </PageContainer>
)

export default InspectionView;
