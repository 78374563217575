export type RuleNames = 'length' | 'specialChar' | 'number' | 'capital' | 'lowerCase' | 'match' | 'equalNumber'

export class Login {
  email = '';
  password = '';
}

export class LoginError {
  email = '';
  password = '';
}

export class Registration {
  name = '';
  email = '';
  password = '';
  confirmPassword = '';
  dateOfBirth = '';
  cpf = '';
}

export class RegistrationError {
  name = '';
  email = '';
  password = '';
  confirmPassword = '';
  dateOfBirth = '';
  cpf = '';
}