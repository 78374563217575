import { sendRequest } from '../RequestService';
import { ICEP, IPais, IState } from './types';

const defaultRoot = 'localities';

export const getCEPAddressUser = async (cep: string) => {
  const url = `${defaultRoot}/addresses/${cep}`
  return await sendRequest<ICEP, boolean>(url, 'GET')
}

export const getStateAddressUser = async () => {
  const url = `${defaultRoot}/states`
  return await sendRequest<IState[], boolean>(url, 'GET')
}

export const getCityAddressUser = async (uf: string) => {
  const url = `${defaultRoot}/cities?Uf=${uf}`
  return await sendRequest<IPais[], boolean>(url, 'GET')
}
