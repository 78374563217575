export type RuleNames = 'length' | 'specialChar' | 'number' | 'capital' | 'lowerCase' | 'match' | 'equalNumber'

export class Login {
  email = '';
  password = '';
  confirmPassword = '';
  codigo = '';
  hash = '';
}

export class LoginError {
  email = '';
  password = '';
  confirmPassword = '';
  codigo = '';
  hash = '';
}