import React, { useEffect, useState } from 'react';
import ToastNotification from '../../../components/ToastNotification';
import ViewLoading from '../../../components/ViewLoading';
import ViewError from '../../../components/ViewError';
import TableProduct from '../TableProduct';
import ModalCustom from '../../../components/ModalCustom';
import { Select, MenuItem, Pagination } from '@mui/material'
import { useIsSm } from '../../../hooks/useIsSm';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import SearchBar from '../../../components/SearchBar';
import { Container, Pages } from '../../../pages/TermsOfUse/ScreenTermsOfUse/styles';
import { Inputs, Item } from '../../../pages/MyContacts/styles';
import ButtonMaterial from '../../../components/Button';
import { theme } from '../../../styles/theme';
import { getAutoCompleteProducts, getProducts, postProduct, } from '../../../services/Product';
import { IProduct } from '../../../services/Product/types';
import ModalCustomBottom from '../../../components/ModalCustomBottom';
import { Add, Shop } from '@mui/icons-material';
import { ButtonScrollToTop } from '../../../pages/ViewChallenge/styles';
import { ContainerTable } from '../../../pages/UserManagement/Users/styles';
import * as S from './styles'
import { ButtonQuestion } from '../../../components/ModalRegistrationAndChallengeEditing/styles';
import ProductCreationAndEditing from '../ProductCreationAndEditing';
import { Registration, RegistrationError } from '../TableProduct/types';
import { ProductDateValidation, registerValidation, registerValidationChallenge } from './validations';
import { dateMaskInverter, onlyDigits } from '../../../utils/Mask';
import { addDays, format } from 'date-fns';

const Product: React.FC = () => {
  const { t } = useTranslation();
  const [productList, setProductList] = useState<IProduct[]>([])
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [totalPages, setTotalPages] = useState<number | undefined>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [valueProduct, setValueProduct] = useState('');
  const [openFilter, setOpenFilter] = useState(false)
  const [type, setType] = useState('0');
  const [status, setStatus] = useState('0');
  const isSm = useIsSm()
  const [openRegisterModal, setOpenRegisterModal] = useState(false)
  const [loadingRegister, setLoadingRegister] = useState(false)
  const [openRegisterCancelModal, setOpenRegisterCancelModal] = useState(false)
  const [page, setPage] = useState(1)
  const [productType, setProductType] = useState('1');
  const [registration, setRegistration] = useState<Registration>(new Registration());
  const [registrationError, setRegistrationError] = useState<RegistrationError>(new RegistrationError());
  const [urlImage, setUrlImage] = useState('');
  const [searchById, setSearchById] = useState(false);
  const [autoCompleteListProduct, setAutoCompleteListProduct] = useState<string[]>([])
  const [dataError, setDataError] = useState(false);
  const [openSavedModal, setOpenSavedModal] = useState(false)

  useEffect(() => {
    retrieveProducts(1, true);
    setLoadingRegister(false)
  }, []);

  const validationStatus = () => {
   if (status !== '0') {
if (status === '2'){
  return 'false';
}else{
  return 'true';
}
   }else{
    return '';
   }
  };

  const retrieveProducts = async (page: number = 1, clear: boolean = false, search?: string) => {
    setLoadingProduct(true)
    try {
      if (clear) {
        setProductList([]);
      }
      const bodyData = {
        PageNumber: page,
        PageSize: 8,
        Tipo: type !== '0' ? type : '',
        Nome: searchById ? '' : (search ?? valueProduct),
        Id: searchById ? valueProduct : '',
        Active: validationStatus(),
      };
      const response = await getProducts(bodyData);
      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }
        setProductList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os produtos, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os produtos, tente novamente.')
      });
    }
    setLoadingProduct(false)
  };

  const handleRegisterProduct = async () => {
    setLoadingRegister(true);
    try {
      let countError = 0;

      const validation = Number(productType) === 1 ? await registerValidation(registration) : await registerValidationChallenge(registration);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError(validation as RegistrationError);
        if (!urlImage) {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Insira a imagem do Produto')
          });
        }
        setLoadingRegister(false);
        return;
      }
      if (!urlImage) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Insira a imagem do Produto')
        });
        setLoadingRegister(false);
        return;
      }

      const calcularData = (somaDias: number) => {
        const dataAtual = new Date();
        const novaData = addDays(dataAtual, somaDias);
        return novaData;
      };

      const dateValidation = ProductDateValidation(onlyDigits(registration.expirationDate), String( format(calcularData(Number(registration.withdrawalDeadline)), 'ddMMyyyy')));
      if (!dateValidation.status) {
        setDataError(true)
        setLoadingRegister(false);
        return;
      } else {
        setDataError(false)
      }


      let response;
      if (Number(productType) === 1) {
        const bodyData = {
          Name: registration.name,
          Count: Number(registration.availableQuantity),
          Description: registration.details,
          Points: Number(registration.exchangeScore),
          ImageUrl: urlImage,
          Type: Number(productType),
          LimitPerUser: Number(registration.limitPerUser),

          ExpirationDate: dateMaskInverter(registration.expirationDate),
          WithdrawalDeadline:  Number(registration.withdrawalDeadline),
          SponsoredBy: registration.sponsored
        };
        response = await postProduct(bodyData);
      } else {
        const bodyData = {
          Name: registration.name,
          Count: Number(registration.availableQuantity),
          Description: registration.details,
          ImageUrl: urlImage,
          Type: Number(productType),

          ExpirationDate: dateMaskInverter(registration.expirationDate),
          WithdrawalDeadline: Number(registration.withdrawalDeadline),
          SponsoredBy: registration.sponsored,
          MinPoints: Number(registration.minimumScore),
          MaxPoints: Number(registration.maximumScore),
          AlternativePoints: Number(registration.alternativePunctuation),
        };
        response = await postProduct(bodyData);
      }

      if (response.Success) {
        retrieveProducts(1, true);
        setOpenSavedModal(true)

        clearRegistrationData();
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao cadastrar o produto, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao cadastrar o produto, tente novamente.')
      });
    }
    setLoadingRegister(false);
  };

  const clearRegistrationData = () => {
    setOpenRegisterCancelModal(false)
    setOpenRegisterModal(false)
    setUrlImage('')
    setProductType('1')
    setPage(1)
    setRegistration(new Registration())
    setRegistrationError(new RegistrationError())
    setDataError(false)
  };

  const validationPageStyle = () => {
    if (page === 1) {
      return '30%'
    } else {
      return '50%'
    }
  };

  const returnRegister = () => {
    return <S.ContainerRegister>
      {page === 1 && returnRegisterPage1()}
      {page === 2 && <ProductCreationAndEditing
        registration={registration}
        registrationError={registrationError}
        setRegistration={(e) => setRegistration(e)}
        setRegistrationError={(e) => setRegistrationError(e)}
        setUrlImage={(e) => setUrlImage(e)}
        urlImage={urlImage}
        type={Number(productType)}
        returnWarning={dataError}
      />}

      <div className='boxButtonsRegister'>
        <S.BoxButtonsNav active={!isSm && page === 2}>
          <ButtonQuestion active={page === 1}>
            <h5 className='textbutton'>1</h5>
          </ButtonQuestion>

          <ButtonQuestion active={page === 2}>
            <h5 className='textbutton'>2</h5>
          </ButtonQuestion>
        </S.BoxButtonsNav>

        <div className='boxButtonsBottom'>
          <ButtonMaterial
            style={{ backgroundColor: theme.colors.secundary, display: page === 1 ? 'none' : 'flex', width: isSm ? '40%' : 'auto' }}
            variant="contained"
            label={t('ANTERIOR')}
            onClick={() => setPage(1)}
            disabled={loadingRegister}
          />

          <div className='boxButtonsRight'>
            <ButtonMaterial
              style={{ borderColor: theme.colors.secundary, color: theme.colors.secundary, marginLeft: isSm ? '8px' : '0px', width: isSm ? validationPageStyle() : 'auto' }}
              variant="outlined"
              label={t('CANCELAR')}
              onClick={() => setOpenRegisterCancelModal(true)}
              disabled={loadingRegister}
            />

            <ButtonMaterial
              style={{ backgroundColor: theme.colors.seventh, marginLeft: isSm ? '8px' : '24px', width: isSm ? validationPageStyle() : 'auto' }}
              variant="contained"
              label={page === 2 ? t('SALVAR') : t('PRÓXIMO')}
              onClick={() => page === 1 ? setPage(2) : handleRegisterProduct()}
              loading={loadingRegister}
              disabled={loadingRegister}
            />
          </div>
        </div>
      </div>
    </S.ContainerRegister>
  };

  const returnRegisterPage1 = () => {
    return <S.ContainerRegisterPage1>
      {!isSm && <h3 className='textitle'>{t('Escolha qual tipo de produto você deseja cadastrar.')}</h3>}

      <FormControl style={{ display: 'flex', width: isSm ? '100%' : '60%' }}>
        <InputLabel id="demo-simple-select-helper-label">{t('Tipo de Produto')}</InputLabel>
        <Select
          MenuProps={{
            MenuListProps: {
              sx: { maxHeight: '180px', overflowY: 'auto' }
            }
          }}
          label={t('Tipo de Produto')}
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          variant="standard"
          style={{ display: 'flex', width: '100%' }}
          value={productType}
          onChange={(e) => setProductType(e.target.value)}
        >
          <MenuItem value='2'>{t('Produto atribuido à desafio')}</MenuItem>
          <MenuItem value='1'>{t('Produto adquirido por troca de pontos')}</MenuItem>
        </Select>
      </FormControl>
    </S.ContainerRegisterPage1>
  };

  const getAutoCompleteProduct = async () => {
    try {
      setAutoCompleteListProduct([])
      const response = await getAutoCompleteProducts(valueProduct);
      if (response.Success) {
        if (response.Data[0] !== valueProduct) {
          setAutoCompleteListProduct(response.Data)
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
  };

  const onEnterSearch = (event: { key: string }) => {
    if (event.key === 'Enter') {
      retrieveProducts(1, true);
      setAutoCompleteListProduct([])
    }
  };

  useEffect(() => {
    if (valueProduct.length > 0) {
      if (Number(valueProduct.substring(0, 1))) {
        setSearchById(true)
      } else {
        setSearchById(false)
        if (valueProduct.length > 2) {
          getAutoCompleteProduct()
        } else {
          setAutoCompleteListProduct([])
        }
      }
    } else {
      retrieveProducts(1, true, '');
    }
  }, [valueProduct]);

  return (
    <Container>
      <ContainerTable>
        <div className='containerButton'>
          <SearchBar
            value={valueProduct}
            setValue={(e) => setValueProduct(e)}
            onEnterSearch={onEnterSearch}
            setFilterModal={() => setOpenFilter(true)}
            placeholder={t('Busque o produto desejado')}
            filterButton
            style={{ padding: 0, width: isSm ? '100%' : '80%', marginRight: 'auto' }}
            buttonSearch
            onClearSearch={() => {
              setValueProduct('')
              retrieveProducts(1, true, '');
            }}
            autoCompleteList={autoCompleteListProduct}
          />

          {!isSm &&
            <ButtonMaterial
              style={{ borderColor: theme.colors.secundary, top: '-60px' }}
              variant="outlined"
              label={t('CADASTRAR PRODUTO')}
              onClick={() => setOpenRegisterModal(true)}
            />
          }
        </div>

        {loadingProduct ? (
          <ViewLoading />
        ) : (
          <>
            {productList.length > 0 ? (
              <>
                <TableProduct listUsers={productList} updateList={() => retrieveProducts(1, true)} />
                <Pages>
                  <Pagination
                    showFirstButton
                    showLastButton
                    count={totalPages}
                    page={currentPage}
                    color="primary"
                    onChange={(e, p) => {
                      e.preventDefault()
                      e.stopPropagation()
                      retrieveProducts(p)
                    }}
                  />
                </Pages>
              </>
            ) : (
              <ViewError message={searchById ? t('Não há produtos cadastrados com o ID informado') : t('Nenhum produto encontrado')} />
            )}
          </>
        )}
      </ContainerTable>


      {isSm ?
        <ModalCustomBottom
          open={openRegisterModal}
          onClose={() => setOpenRegisterCancelModal(true)}
          onClick={() => { }}
          loading={loadingRegister}
          title={t('Cadastro de Produto')}
          showIcon
          buttonClose
          buttonsEnabled={false}
          styleButtons={{ justifyContent: 'center' }}
          childrenContent={returnRegister()}
          icon={<Shop style={{ fontSize: '24px', color: theme.colorsBase.gray3, backgroundColor: theme.colorsBase.white }} />}
        />
        :
        <ModalCustom
          open={openRegisterModal}
          onClose={() => setOpenRegisterCancelModal(true)}
          onClick={() => { }}
          childrenContent={returnRegister()}
          styleLine={{ backgroundColor: 'transparent', marginBottom: '24px' }}
          loading={loadingRegister}
          title={t('Cadastro de Produto')}
          largeTitle
          buttonsEnabled={false}
          iconHeader={<Shop style={{ fontSize: '24px', color: theme.colorsBase.gray3, backgroundColor: theme.colorsBase.white }} />}
        />
      }

      {isSm ?
        <ModalCustomBottom
          open={openRegisterCancelModal}
          onClose={() => setOpenRegisterCancelModal(false)}
          onClick={() => clearRegistrationData()}
          loading={loadingRegister}
          title={t('Cancelar Cadastro')}
          confirmationButtonText={t('CANCELAR')}
          textDeclineButton={t('VOLTAR')}
          showIcon={false}
          buttonClose
          buttonsEnabled
          styleButtons={{ justifyContent: 'center' }}
          redButtonRight
          description={t('Você deseja cancelar o cadastro?  Nenhuma informação será salva.')}
        />
        :
        <ModalCustom
          open={openRegisterCancelModal}
          onClose={() => setOpenRegisterCancelModal(false)}
          onClick={() => clearRegistrationData()}
          loading={loadingRegister}
          title={t('Cancelar Cadastro')}
          styleButtons={{ justifyContent: 'center' }}
          confirmationButtonText={t('CANCELAR')}
          textDeclineButton={t('VOLTAR')}
          redButtonRight
          shortModal
          description={t('Você deseja cancelar o cadastro?  Nenhuma informação será salva.')}
        />
      }

      <ModalCustom
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        onClick={() => {
          retrieveProducts(1, true)
          setOpenFilter(false)
        }}
        childrenContent={
          <>
            <Item>
              <Inputs>
                <div className="input">
                  <FormControl sx={{ display: 'flex', m: 1, width: '100%' }}>
                    <InputLabel id="demo-simple-select-helper-label">{t('Status')}</InputLabel>
                    <Select
                      MenuProps={{
                        MenuListProps: {
                          sx: { maxHeight: '200px', maxWidth: '100%', overflowY: 'auto' }
                        }
                      }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      variant="standard"
                      style={{ display: 'flex', width: '100%' }}
                      value={status}
                      onChange={(e) => {
                        setStatus(String(e.target.value))
                      }}
                    >
                      <MenuItem value={'0'}>{t('Todos')}</MenuItem>
                      <MenuItem value={'1'}>{t('Ativo')}</MenuItem>
                      <MenuItem value={'2'}>{t('Inativo')}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Inputs>
            </Item>

            <Item style={{ marginTop: '-24px' }}>
              <Inputs>
                <div className="input">
                  <FormControl sx={{ display: 'flex', m: 1, width: '100%' }}>
                    <InputLabel id="demo-simple-select-helper-label">{t('Tipo')}</InputLabel>
                    <Select
                      MenuProps={{
                        MenuListProps: {
                          sx: { maxHeight: '200px', maxWidth: '100%', overflowY: 'auto' }
                        }
                      }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      variant="standard"
                      style={{ display: 'flex', width: '100%' }}
                      value={type}
                      onChange={(e) => {
                        setType(String(e.target.value))
                      }}
                    >
                      <MenuItem value={'0'}>{t('Todos')}</MenuItem>
                      <MenuItem value={'2'}>{t('Atribuído à Desafio')}</MenuItem>
                      <MenuItem value={'1'}>{t('Troca de Pontos')}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Inputs>
            </Item>
          </>
        }
        loading={loadingProduct}
        title={t('Filtros')}
        confirmationButtonText={t('Pesquisar')}
        textDeclineButton={t('CANCELAR')}
      />

      {isSm &&
        <ButtonScrollToTop onClick={() => setOpenRegisterModal(true)}>
          <Add className="icon" />
        </ButtonScrollToTop>
      }

      {isSm ?
        <ModalCustomBottom
          open={openSavedModal}
          onClose={() => setOpenSavedModal(false)}
          onClick={() => setOpenSavedModal(false)}
          title={t('Cadastro de Produto')}
          textDeclineButton={t('VOLTAR')}
          showIcon={false}
          buttonClose
          buttonsEnabled
          buttonRight={false}
          styleButtons={{ justifyContent: 'center' }}
          description={t('Produto cadastrado com sucesso.')}
        />
        :
        <ModalCustom
          open={openSavedModal}
          onClose={() => setOpenSavedModal(false)}
          onClick={() => setOpenSavedModal(false)}
          title={t('Cadastro de Produto')}
          styleButtons={{ justifyContent: 'center' }}
          textDeclineButton={t('VOLTAR')}
          buttonRight={false}
          shortModal
          description={t('Produto cadastrado com sucesso.')}
        />
      }
    </Container >
  )
}

export default Product
