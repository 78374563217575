import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import ModalButton from '../../../../../components/ModalButton';
import { useInvalidateChalengesInScreening } from '../../../../../services/ChallengesManagement/InScreenings';
import { useChalengeManagementDisapprovalMutation } from '../../../../../services/ChallengesManagement/Disapprovals';
import { useChalengeManagementBlockMutation } from '../../../../../services/ChallengesManagement/Blocked';
import { useInvalidateChalengeDetails } from '../../../../../services/ChallengesManagement/Details';
import { useChalengeManagementApprovalMutation } from '../../../../../services/ChallengesManagement/Approvals';
import { IChalengeDetailsQueryResponse } from '../../../../../services/ChallengesManagement/Details/types';
import { IChalengeQuizQueryResponse } from '../../../../../services/ChallengesDefinition/Quizzes/types'
import { IChalengeManagementApprovalCommand } from '../../../../../services/ChallengesManagement/Approvals/types';
import { useInvalidateChalengeQuiz } from '../../../../../services/ChallengesDefinition/Quizzes';

import TriageItemModal from '../../TriageItemModal';
import Timeline from '../../Timeline';

import DisapproveChallengeModal from './DisapproveChallengeModal';
import BlockChallengeModal from './BlockChallengeModal';

interface IProps {
  Id: number,
  challengeDetail: IChalengeDetailsQueryResponse,
  challengeQuizes: IChalengeQuizQueryResponse[]
  onClose: () => void
}

const schema = yup.object().shape({
  id: yup.number().required(),
  command: yup.object().shape({
    AgeRating: yup.number().required(),
    CategoryId: yup.number().required(),
    DifficultyLevel: yup.number().required(),
    EstimatedTime: yup.number().min(1, "Insira um valor maior que 1").required(),
  }),
});

const ChallengeTriageDetail: React.FC<IProps> = ({ Id, challengeDetail, challengeQuizes, onClose: closeModal }) => {
  const invalidateChalengesInScreening = useInvalidateChalengesInScreening()
  const invalidateChalengeDetails = useInvalidateChalengeDetails()
  const invalidateChalengeQuiz = useInvalidateChalengeQuiz()

  const chalengeManagementDisapprovalMutation = useChalengeManagementDisapprovalMutation();
  const chalengeManagementBlockMutation = useChalengeManagementBlockMutation();
  const chalengeManagementApprovalMutation = useChalengeManagementApprovalMutation();

  const { control, handleSubmit, watch } = useForm<IChalengeManagementApprovalCommand>({
    defaultValues: {
      id: Id,
      command: {
        Tags: challengeDetail.Tags,
        AgeRating: undefined,
        CategoryId: undefined,
        DifficultyLevel: undefined,
        EstimatedTime: challengeDetail.EstimatedTime,
        Questions: challengeQuizes.map(({ Alternatives, ...quiz }) => ({
          QuizAlternatives: Alternatives,
          ...quiz
        }))
      }
    },
    resolver: yupResolver(schema) as unknown as Resolver<IChalengeManagementApprovalCommand>
  });

  const questions = watch("command.Questions");

  const hasQuiz = questions.length > 0;

  return (
    <Box
      display="flex"
      width="70vw"
      maxWidth="900px"
      gap={2}
      sx={({ breakpoints }) => ({
        [breakpoints.down("md")]: {
          flexDirection: "column"
        }
      })}
    >
      <Box flex={0.6}>
        <TriageItemModal
          challengeDetail={challengeDetail}
          challengeQuizes={questions.map(({ Question, QuizAlternatives, ...quiz }, index) => ({
            ...quiz,
            Question:
              Question ?? "",
            Id: index,
            Alternatives: QuizAlternatives.map((alternative, alternativeIndex) => ({
              ...alternative,
              Answer: alternative.Answer ?? "",
              Id: alternativeIndex
            }))
          }))}
          hasQuiz={hasQuiz}
          control={control}
          editable
        />
      </Box>

      <Stack flex={0.4} width="100%" spacing={1}>
        <Timeline timeLine={challengeDetail.TimeLine} sx={{ flex: 1 }} />
        <Stack direction="row" spacing={2} justifyContent="end">
          <Button
            variant='outlined'
            size='small'
            color='success'
            disabled={!hasQuiz}
            onClick={handleSubmit(
              (data) => chalengeManagementApprovalMutation.mutateAsync(
                data,
                {
                  onSuccess: () => closeModal(),
                  onSettled: () => {
                    invalidateChalengeQuiz();
                    invalidateChalengesInScreening();
                    invalidateChalengeDetails();
                  }
                }
              ),
              console.error
            )}
          >
            APROVAR
          </Button>
          <ModalButton variant='outlined' size='small' color='primary' buttonChildren="REJEITAR">
            {({ open, onClose }) => (
              <DisapproveChallengeModal
                open={open}
                onClose={onClose}
                onSubmit={({ reason }) =>
                  chalengeManagementDisapprovalMutation.mutateAsync(
                    { id: Id, reason },
                    {
                      onSuccess: () => { onClose(); closeModal(); },
                      onSettled: () => {
                        invalidateChalengesInScreening();
                        invalidateChalengeDetails();
                      }
                    }
                  )}
                loading={false}
              />
            )}
          </ModalButton>
          <ModalButton variant='outlined' size='small' color='error' buttonChildren="BLOQUEAR">
            {({ open, onClose }) => (
              <BlockChallengeModal
                open={open}
                onClose={onClose}
                onSubmit={({ reason }) =>
                  chalengeManagementBlockMutation.mutateAsync(
                    { id: Id, reason },
                    {
                      onSuccess: () => { onClose(); closeModal(); },
                      onSettled: () => {
                        invalidateChalengesInScreening();
                        invalidateChalengeDetails();
                      }
                    }
                  )}
                loading={false}
              />
            )}
          </ModalButton>
        </Stack>
      </Stack>
    </Box>
  );
}
export default ChallengeTriageDetail;
