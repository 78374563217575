import { Box, styled } from "@mui/material";

export const Grid = styled(Box)(({ theme }) => ({
  paddingLeft: "48px",
  paddingRight: "48px",
  gap: "48px",
  display: "grid",
  marginBottom: '64px',
  gridTemplateAreas: `
    "geral      geral"
    "importancia    importancia"

    "neste_canal neste_canal"
    "publicacao      publicacao"
    "visibilidade  planos"

    "primeiro primeiro"
    "registro     notificacao"

    "boasvindas boasvindas"
    "plano_boasvindas explore"
    "como        futuro"
    "feedback feedback"
  `,
  [theme.breakpoints.down(800)]: {
    marginTop: '32px',
    gridTemplateAreas: `
      "geral"
      "importancia"

      "neste_canal"
      "empresa"
      "publicacao"
      "visibilidade"
      "planos"

      "primeiro"
      "registro"
      "notificacao"

      "boasvindas"
      "plano_boasvindas"
      "explore"
      "como"
      "futuro"
      "feedback"
    `,
  }
}))
