import React, { useEffect, useState } from 'react';
import * as S from './styles'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ToastNotification from '../../components/ToastNotification';
import ViewError from '../../components/ViewError';
import { INotification } from '../../services/Notifications/types';
import { deleteNotification, getNotifications } from '../../services/Notifications';
import HeaderAchievements from '../../components/HeaderAchievements';
import CardNotification from '../../components/CardNotification';
import { Pagination } from '@mui/material';

const NotificationHistory: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [notificationList, setNotificationList] = useState<INotification[]>([])
  const [totalPages, setTotalPages] = useState<number | undefined>(0)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    retrieveNotifications(1);
    resetCountDown();
  }, []);

  const [time, setTime] = useState(0);
  /* eslint-disable @typescript-eslint/no-explicit-any */ 
  let countdownTimeOut: any;

  useEffect(() => {
    if (time > 0) {
      countdownTimeOut = setTimeout(() => {
        setTime((state) => state - 1);
      }, 1000);
    } else if (time <= 0) {
      retrieveNotifications(currentPage);
      resetCountDown();
    }
  }, [time]);

  const resetCountDown = () => {
    clearTimeout(countdownTimeOut);
    setTime(100);
  };

  const retrieveNotifications = async (page: number = 1) => {
    try {
      const bodyDataUser = {
        PageNumber: page,
        PageSize: 8,
        Visualizadas: 'true',
      };
      const response = await getNotifications(bodyDataUser);
      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }
        setNotificationList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar as notificações, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar as notificações, tente novamente.')
      });
    }
  };

  const notificationDelete = async (id: string) => {
    try {
      const response = await deleteNotification(id);
      if (response.Success) {
        retrieveNotifications(1);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao deletar a notificação, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao deletar a notificação, tente novamente.')
      });
    }
  };

  return (
    <S.Container>
      <HeaderAchievements
        title={t('Histórico de Notificações')}
        onClick={() => navigate(-1)}
      />

      <S.ContainerContent>
        <S.ContainerTable>
          {notificationList.length > 0 ? (
            <>
              {notificationList.map((item) => (
                <CardNotification key={item.Id} historic item={item} onClickClose={() => notificationDelete(item.Id)} />
              ))}
            </>
          ) : (
            <ViewError message={t('Nenhuma notificação encontrada!')} />
          )}

          <S.ContainerBottom>
            <Pagination
              showFirstButton
              showLastButton
              count={totalPages}
              page={currentPage}
              color="primary"
              onChange={(e, p) => {
                e.preventDefault()
                e.stopPropagation()
                retrieveNotifications(p)
              }}
            />
          </S.ContainerBottom>
        </S.ContainerTable>
      </S.ContainerContent>
    </S.Container>
  )
}

export default NotificationHistory
