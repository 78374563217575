import { useMutation } from "@tanstack/react-query";

import { sendRequest } from "../../../services/RequestService";

import { IChalengeManagementChangeCuratorCommand, IChalengeManagementChangeCuratorCommandResponse } from "./types";

const defaultRoot = "challenges-management/change-curators"

export const useChalengeManagementChangeCuratorMutation = () => useMutation({
  mutationFn: async (command: IChalengeManagementChangeCuratorCommand) => {
    const url = `${defaultRoot}/${command.id}`

    return await sendRequest<IChalengeManagementChangeCuratorCommandResponse>(url, 'POST', command.reason)
  }
})
