import React from 'react';

import PageContainer from '../../components/Layout/PageContainer';
import PaperContainer from '../../components/Layout/PaperContainer';
import { AntTab, AntTabs } from '../../components/Tabs';
import { theme } from '../../styles/theme';
import HeaderAchievements from '../../components/HeaderAchievements';
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../hooks/useIsSm';
import { Diamond } from '@mui/icons-material';
import Product from './Product';

interface IProps {
  tab: number;
  setNewTab: (newTab: number) => void;
  variant?: "fullWidth" | "standard" | "scrollable";
}

const ProductManagement: React.FC<IProps> = ({ setNewTab, tab, variant }) => {
  const { t } = useTranslation();
  const isSm = useIsSm()

  return (
    <PageContainer>
      <HeaderAchievements
        title={t('Gerenciamento de Produtos')}
        marginBottom={isSm ? 24 : 0}
        icon={<Diamond style={{ color: theme.colorsBase.gray3 }} />}
      />
      <PaperContainer>
        <AntTabs value={tab} onChange={(_, newValue) => setNewTab(newValue)} variant={variant}>
          <AntTab label="PRODUTOS" sx={{ width: isSm ? '50vw' : 'none', margin: isSm ? '0px auto' : '0px' }} />
        </AntTabs>

        <Product />
      </PaperContainer>
    </PageContainer>
  )
}

export default ProductManagement
