import * as yup from 'yup';

import { verifyClassEmpty } from '../../utils/ClassEmpty';
import { genericValidation } from '../../utils/ValidationGenerics';

import { Registration, RegistrationError } from './types';

export const registerValidation = async (object: Registration): Promise<true | RegistrationError> => {
  const schemaRegister = yup.object().shape({
    ValuePtBr: yup.string().required(),
    ValueEn: yup.string().required(),
    ValueEs: yup.string().required(),
    ValueFr: yup.string().required()
  });

  const mountError = await genericValidation<Registration, RegistrationError>(schemaRegister, object, new RegistrationError());

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};
