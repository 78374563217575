import React, { ChangeEvent, useRef } from 'react';
import * as S from './styles'
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Input';
import ButtonMaterial from '../../../components/Button';
import { Add, Delete, ForwardToInbox, Info, UploadFile } from '@mui/icons-material';
import { theme } from '../../../styles/theme';
import { RegistrationSpecialProgram, RegistrationSpecialProgramError } from './types';
import ToastNotification from '../../../components/ToastNotification';
import { postProgramInviteExcel } from '../../../services/SpecialProgram';
import { useIsSm } from '../../../hooks/useIsSm';

interface IProps {
  email: string[]
  handleDeleteEmail: (e: number) => void
  handleAddEmail: () => void
  registration: RegistrationSpecialProgram
  setRegistration: (e: RegistrationSpecialProgram) => void
  registrationError: RegistrationSpecialProgramError
  setRegistrationError: (e: RegistrationSpecialProgramError) => void
  setListEmails: (e: string[]) => void
  noDelete?: boolean
}

const SendingInvitations: React.FC<IProps> = ({ email, handleDeleteEmail, handleAddEmail, registration, setRegistration, registrationError, setRegistrationError, setListEmails, noDelete }) => {
  const { t } = useTranslation();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const isSm = useIsSm();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      searchEmailsFile(selectedFile)
    }
  };

  const searchEmailsFile = async (e: File) => {
    try {
      const data = new FormData();
      if (e) data.append('Spreadsheet', e);

      const response = await postProgramInviteExcel(data);
      if (response.Success) {
        setListEmails(response.Data.Emails)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao enviar o arquivo, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao enviar o arquivo, tente novamente.')
      });
    }
  };

  return (
    <S.ContainerSendingInvitations>
      <h3 className='title'>{t('Envio de Convites')}</h3>
      <h5 className='subTitle'>{t('Escolha quem você deseja convidar para a realização do seu desafio.')}</h5>

      <div className='containerData'>
        <Input
          label={t('E-mail')}
          placeholder="Por favor, insira um e-mail no formato correto 'usuario@dominio.com.'"
          type="convite"
          value={registration.email}
          onChange={(e) => setRegistration({ ...registration, email: e.target.value })}
          size="small"
          fullWidth
          error={registrationError.email.length > 0}
          helperText={registrationError.email}
          onClick={() => setRegistrationError({ ...registrationError, email: '' })}
          variant="filled"
          style={{ width: '100%', marginTop: isSm ? '8px' : '24px' }}
        />

        <ButtonMaterial
          style={{ borderColor: theme.colorsBase.green3, color: theme.colorsBase.green3, marginTop: '16px' }}
          variant="outlined"
          label={t('CONVIDAR')}
          icon={<ForwardToInbox style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.green3 }} />}
          onClick={handleAddEmail}
        />

        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: '16px' }}>
          <p className='textCenter'>{t('Ou importe uma planilha Excel com os e-mails dos convidados:')}</p>
        </div>

        <S.BoxExcel htmlFor="selecao-arquivo">
          <div className='iconB'>
            <UploadFile style={{ fontSize: '24px', marginRight: '8px', color: "#6A3AE7" }} />
            <h5 className='textAdd'>{t('IMPORTAR PLANILHA')}</h5>
          </div>

          <S.InputExcel id="selecao-arquivo" onChange={handleChange} type="file" accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ref={inputFileRef} />
        </S.BoxExcel>


        <S.ContainerListEmails>
          <h1 className='title'>{isSm ? t('Participantes') : t('Convidados')}</h1>

          <div className='containerList'>
            <div className='containerListColumn'>
              {email?.map((item, i) => (
                <div className='itemList' key={item}>
                  <h1 className='textItemList'>{item}</h1>

                  {!noDelete && <button className='deleteItemList' onClick={() => handleDeleteEmail(i)}>
                    <Delete style={{ fontSize: '24px', color: theme.colorsBase.red2 }} />
                  </button>}
                </div>
              ))}

              {email?.length < 1 &&
                <div className='boxNoResults'>
                  <Add className="iconNoResults" />
                  <h3>{t('Você ainda não convidou ninguém.')}</h3>
                  <h3>{t('Convide participantes inserindo o e-mail.')}</h3>
                </div>
              }
            </div>
          </div>

          <div className='boxMessage'>
            <Info className="iconMessage" />
            <h5>{t('Atenção! os convites só serão disparados se liberado pelo autor do programa após a aprovação na curadoria.')}</h5>
          </div>
        </S.ContainerListEmails>
      </div>
    </S.ContainerSendingInvitations>
  )
}

export default SendingInvitations