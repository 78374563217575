import React, { useState } from 'react';
import { Cancel, CheckCircle, Flag, Info, } from '@mui/icons-material'
import { Tooltip, } from '@mui/material'
import { emptyItem, IReport, ITableUsers } from './types'
import { useTranslation } from 'react-i18next'
import { DataMaskFormtData } from '../../../../../../utils/Mask'
import * as S from './styles'
import { useIsSm } from '../../../../../../hooks/useIsSm';
import ModalCustomBottom from '../../../../../../components/ModalCustomBottom';
import { theme } from '../../../../../../styles/theme';

const TableReport: React.FC<ITableUsers> = (props: ITableUsers) => {
  const { t } = useTranslation();
  const isSm = useIsSm()
  const { listReport } = props
  const [itemSelected, setItemSelected] = useState<IReport>(emptyItem)
  const [openOptionsModal, setOpenOptionsModal] = useState(false)


  const renderTextFunction = (type: number) => ({
    0: t("Não Iniciado"),
    1: t("Iniciado"),
    2: t("Aprovado"),
    3: t("Reprovado"),
  }[type])

  const renderIconFunction = (type: number) => ({
    0: <Flag style={{ color: theme.colorsBase.yellow }} />,
    1: <Flag style={{ color: theme.colorsBase.yellow }} />,
    2: <CheckCircle style={{ color: theme.colorsBase.green2 }} />,
    3: <Cancel style={{ color: theme.colors.graphicRed }} />,
  }[type])

  const renderColor = (type: number) => ({
    0: theme.colorsBase.yellow,
    1: theme.colorsBase.yellow,
    2: theme.colorsBase.green2,
    3: theme.colors.graphicRed,
  }[type])

  return (
    <S.ContainerTableReport>
      <table>
        <tr className="header">
          <td style={{ paddingLeft: 16 }}>{t('Nome')}</td>
          {!isSm && <td>{t('E-mail')}</td>}
          {!isSm && <td>{t('Data de Conclusão')}</td>}
          {!isSm && <td>{t('Nota')}</td>}
          {!isSm && <td>{t('Tentativas')}</td>}
          <td>{t('Status')}</td>
          {isSm && <td>{t('Detalhes')}</td>}
        </tr>
        {listReport.map((item) => (
          <tr key={item?.Name}>
            <td style={{ paddingLeft: 16 }}>{item?.Name}</td>
            {!isSm && <td>{item?.Email}</td>}
            {!isSm && <td>{item?.CompletedAt ? DataMaskFormtData(item?.CompletedAt) : '-'}</td>}
            {!isSm && <td>{item?.PercentageOfCorrectAswers}%</td>}
            {!isSm && <td>{item?.Attempts}</td>}
            <td style={{ color: renderColor(item?.ResumeSpecialProgramStatus) }}>{isSm ? renderIconFunction(item?.ResumeSpecialProgramStatus) : renderTextFunction(item?.ResumeSpecialProgramStatus)}</td>
            {isSm && <td style={{ width: '20px' }}>
              <S.Icons>
                <button onClick={() => {
                  setItemSelected(item)
                  setOpenOptionsModal(true)
                }}>
                  <Tooltip title={t('Detalhes')}>
                    <Info className="i" />
                  </Tooltip>
                </button>
              </S.Icons>
            </td>}
          </tr>
        ))}
      </table>

      <ModalCustomBottom
        open={openOptionsModal}
        onClose={() => setOpenOptionsModal(false)}
        title={t('Detalhes do Participante')}
        showIcon={false}
        buttonClose
        childrenContent={
          <S.ContainerDetailsUser>
            <div className='boxInfo'>
              <h1>{t('Nome')}</h1>
              <h2>{itemSelected.Name}</h2>
            </div>

            <div className='boxInfo'>
              <h1>{t('E-mail')}</h1>
              <h2>{itemSelected.Email}</h2>
            </div>

            <div className='boxInfo'>
              <h1>{t('Data de Conclusão')}</h1>
              <h2>{itemSelected?.CompletedAt ? DataMaskFormtData(itemSelected?.CompletedAt) : '-'}</h2>
            </div>

            <div className='boxInfo'>
              <h1>{t('Nota')}</h1>
              <h2>{itemSelected.PercentageOfCorrectAswers}%</h2>
            </div>

            <div className='boxInfo'>
              <h1>{t('Status')}</h1>
              <h2 style={{ color: renderColor(itemSelected?.ResumeSpecialProgramStatus) }}>{renderTextFunction(itemSelected.ResumeSpecialProgramStatus)}</h2>
            </div>
          </S.ContainerDetailsUser>
        }
      />
    </S.ContainerTableReport>
  )

}

export default TableReport
