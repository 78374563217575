import React, { useState } from 'react';

import { useIsSm } from '../../../hooks/useIsSm';

import AdmChallengeView from './view';

const AdmChallenge: React.FC = () => {
  const [tab, setTab] = useState(0)
  const isSm = useIsSm()

  return (
    <AdmChallengeView
      tab={tab}
      setNewTab={setTab}
      variant={isSm ? 'scrollable' : 'standard'}
    />
  );
}

export default AdmChallenge;
