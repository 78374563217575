import { sendRequest } from '../RequestService';
import { IPostAssessment } from './types';

const defaultRoot = 'challenges-executions/challenge-ratings';


export const postAssessment = async (body: IPostAssessment, id?: string) => {
  const url = `${defaultRoot}/${id}`
  return await sendRequest<boolean, IPostAssessment>(url, 'PATCH', body)
}
