import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin-top: 56px;
  background-color: ${(props) => props.theme.colorsBase.background};
`

export const ContainerContent = styled.div`
padding: 0 40px;
width: 100%;

.buttonFilter {
  display: flex;
  align-self: flex-end;
  width: 100%;
  padding: 0px 0px 24px 0px;
}

@media (max-width: 680px) {
  padding: 0;
  height: auto;

  .buttonFilter {
    display: flex;
    align-self: flex-start;
    padding: 8px 16px 8px 16px;
    flex-direction: column;
    width: 100%;
  }
}
`

export const ContainerChallenge = styled.div`
  display: grid;
  justify-content: start;
  align-items: start;
  margin: 0px 0px 24px 0px;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 80%;
  min-width: 850px;

  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 8px 0px 8px;
    width: 100%;
    min-width: 100%;
  }
  
  @media (min-width: 1600px) {
    gap: 40px;
    grid-template-columns: repeat(4, 1fr);
  }
  `

export const Item = styled.div`
  display: flex;
  flex-direction: column;

  width: 60%;

  padding: 8px 0;

  p {
    font-weight: 500;
  }

  @media (max-width: 680px) {
    width: 100%;
    margin-top: -12px;
  }
`

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 0px 12px;

  > div {
    display: flex;
    align-self: flex-start;

    padding: 8px 0;

    input {
      width: 22px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .input {
    width: 100%;

    input {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }

    select {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }
  }
`

export const BoxText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;

  @media (max-width: 680px) {
    margin-top: 8px;
    margin-bottom: 24px;
  }

    .text {
      text-align: center;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }

    b {
      text-align: center;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 700;
      font-family: 'Barlow', Regular;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
  
`

export const ContainerProfile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items:flex-start;
  width: 100%;
  margin-bottom: 8px;

  .box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  @media (max-width: 680px) {
    flex-direction: column;
    margin-bottom: 24px;
  }
`

export const TextCount = styled.div`
display: flex;
align-items: left;
justify-content: flex-start;
width: 100%;
height: 0.5px;
margin-top: 16px;
margin-left: 16px;

h5 {
  display: flex;
  align-self: flex-start;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
  font-size: ${responsiveFont(12)};
  color: ${(props) => props.theme.colors.black};
  font-weight: 400;
  font-family: 'Barlow', Medium;

  @media (max-width: 680px) {
    font-size: ${responsiveFont(10)};
  }

  @media (min-width: 1600px) {
    font-size: ${responsiveFont(14)};
  }
}
`

export const TextProfile = styled.h1`
    display: flex;
    align-self: flex-start;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
  
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
`