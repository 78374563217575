import Modal from '@mui/material/Modal'
import { IModalCancelCustomProps } from './types'
import * as S from './styles'
import { Close, Rule } from '@mui/icons-material'
import { theme } from '../../styles/theme'
import ButtonMaterial from '../Button'
import { useTranslation } from 'react-i18next';
import HeaderMobile from '../HeaderMobile'
import { useIsSm } from '../../hooks/useIsSm';

export default function ModalRegistrationAndChallengeEditing(props: Readonly<IModalCancelCustomProps>) {
  const { open, onClose, onCloseBottom, onClick, title, page, confirmationButtonText, textDeclineButton, loading = false, childrenContent, buttonsEnabled = true, pageTotal, pageActive = true } = props
  const { t } = useTranslation();
  const isSm = useIsSm()

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <>
        {isSm && <HeaderMobile />}
        <S.ContainerModal>
          <S.Line>
            {isSm && <Rule className="i" />}
            <h5>{title}</h5>
          </S.Line>

          <button className='buttonClose' onClick={onClose}>
            <Close className="icon" />
          </button>

          <S.ContainerChildren>
            {childrenContent}
          </S.ContainerChildren>

          {pageActive &&
            <S.ContainerQuestionCount>
              <S.ButtonQuestion active={page === 1}>
                <h5 className='textbutton'>1</h5>
              </S.ButtonQuestion>

              <S.ButtonQuestion active={page === 2}>
                <h5 className='textbutton'>2</h5>
              </S.ButtonQuestion>

              <S.ButtonQuestion active={page === 3}>
                <h5 className='textbutton'>3</h5>
              </S.ButtonQuestion>

              {pageTotal >= 4 &&
                <S.ButtonQuestion active={page === 4}>
                  <h5 className='textbutton'>4</h5>
                </S.ButtonQuestion>
              }

              {pageTotal >= 5 &&
                <S.ButtonQuestion active={page === 5}>
                  <h5 className='textbutton'>5</h5>
                </S.ButtonQuestion>
              }
            </S.ContainerQuestionCount>
          }

          {buttonsEnabled && (
            <div className='button'>
              <div className='buttonTop'>
                {pageActive && (<>
                  {page === 1 ? (
                    <>
                      {isSm &&
                        <ButtonMaterial
                          style={{ borderColor: theme.colorsBase.gray3, color: theme.colorsBase.gray3, width: isSm ? '100%' : 'auto' }}
                          variant="outlined"
                          label={t('ANTERIOR')}
                          disabled={true}
                        />
                      }
                    </>
                  ) : (
                    <ButtonMaterial
                      style={{ backgroundColor: theme.colors.secundary, width: isSm ? '100%' : 'auto' }}
                      variant="contained"
                      label={t('ANTERIOR')}
                      onClick={() => onCloseBottom()}
                    />
                  )}
                </>)}

                {isSm &&
                  <ButtonMaterial
                    style={{ backgroundColor: theme.colors.seventh, marginLeft: pageActive ? '24px' : '0px', width: isSm ? '100%' : 'auto' }}
                    variant="contained"
                    label={confirmationButtonText}
                    onClick={onClick}
                    loading={loading}
                    disabled={loading}
                  />
                }
              </div>

              <div>
                <ButtonMaterial
                  style={{ borderColor: theme.colors.secundary, width: isSm ? '100%' : 'auto', marginTop: isSm ? '24px' : '0px' }}
                  variant="outlined"
                  label={textDeclineButton}
                  onClick={onClose}
                  disabled={loading}
                />

                {!isSm &&
                  <ButtonMaterial
                    style={{ backgroundColor: theme.colors.seventh, marginLeft: '24px' }}
                    variant="contained"
                    label={confirmationButtonText}
                    onClick={onClick}
                    loading={loading}
                    disabled={loading}
                  />
                }
              </div>
            </div>
          )}
        </S.ContainerModal>
      </>
    </Modal>
  )
}
