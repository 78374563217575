import { useQuery } from "@tanstack/react-query";
import { sendRequest } from "../../services/RequestService";
import { IDifficultyLevelQueryResponse } from "./types";

const defaultRoot = "difficulty-levels"

export const useDifficultyLevels = () => useQuery({
  queryKey: ["useDifficultyLevels"],
  queryFn: async () => await sendRequest<IDifficultyLevelQueryResponse[]>(defaultRoot, 'GET')
})
