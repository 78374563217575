import React from 'react';
import { Box, CircularProgress } from '@mui/material';


interface IProps {
  value?: number;
  color?: string;
  backgroundColor?: string;
}

const CircularPercent: React.FC< React.PropsWithChildren<IProps>> = ({ value, color, backgroundColor, children }) => (
  <Box
    sx={{
      position: "relative",
      display: "inline-flex",
      borderRadius: '50%',
      width: 'fit-content',
      backgroundColor
    }}
  >
    <CircularProgress
      variant="determinate"
      size={100}
      thickness={3.5}
      value={value}
      sx={{ borderRadius: '50%', color }}
    />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </Box>
  </Box>
)

export default CircularPercent;