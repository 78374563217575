import { Box, styled } from "@mui/material"

const PageContainer = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    paddingTop: '56px',
    minHeight: '100vh',
}))

export default PageContainer
