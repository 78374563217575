import { Typography, styled } from "@mui/material"

export const Title = styled(Typography)(() => ({
  color: "#201E1E",
  fontFamily: "Barlow",
  fontSize: "12px",
  fontWeight: 400
}))

export const Content = styled(Typography)(() => ({
  color: "rgba(0, 0, 0, 0.87)",
  fontFamily: "Barlow",
  fontSize: "16px",
  fontWeight: 400
}))
