import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin-top: 56px;
`

export const ContainerContent = styled.div`
  padding: 0 40px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  
  @media (max-width: 680px) {
    padding: 0;
  }
`

export const ContainerPoints = styled.div`
  align-items: center;
  justify-content: center;
  align-self: center; 
  padding: 24px 16px;
  border-radius: 16px 16px 0px 0px;
  
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  
  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    padding: 24px 40px;
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(5, 1fr);
    gap: 40px;
  }
`
