import React from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import PageContainer from '../../components/Layout/PageContainer';
import BackPageHeader from '../../components/Template/BackPageHeader';
import QueryContainer from '../../components/QueryContainer';
import InspectionOverviewCard from './components/InspectionOverviewCard';
import AwatingTriageCard from './components/AwatingTriageCard';
import InspectionHistoryCard from './components/InspectionHistoryCard';
import { IBaseResponse } from '../../services/RequestService/types';
import { ICurationsDashboardQueryResponse } from '../../services/Dashboard/CurationsDashboard/types';

import { Container } from './styles';

interface IProps {
  query: UseQueryResult<IBaseResponse<ICurationsDashboardQueryResponse>>
}

const InspectionDashboardView: React.FC<IProps> = ({ query }) => (
  <PageContainer>
    <BackPageHeader title='Dashboard Curadoria' />
    <Container>
      <QueryContainer query={query}>
        {({ data }) => (
          <>
            <InspectionOverviewCard gridArea="totalChallenge" height="280px" curationCounter={data.Data.CurationCounters} />
            <AwatingTriageCard gridArea="challengeImpact" height="280px" challenges={data.Data.WaitingCuration} />
            <InspectionHistoryCard gridArea="history" history={data.Data.History} />
          </>
        )}
      </QueryContainer>
    </Container>
  </PageContainer>
)

export default InspectionDashboardView;
