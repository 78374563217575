import styled from "styled-components";

import { responsiveFont } from "../../../utils/UtilsGlobal";

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  position: relative;

  .curva {
    display: flex;
    position: absolute;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 680px) {
    height: 170px;
    margin-top: 70px;

    .curva {
      height: 170px;
    }
  }

  @media (min-width: 681px) {
    height: 170px;

    .curva {
      height: 170px;
    }
  }

  @media (min-width: 1250px) {
    height: 170px;

    .curva {
      height: 170px;
    }
  }

  @media (min-width: 1600px) {
    height: 200px;

    .curva {
      height: 200px;
    }
  }
`

export const BackButton = styled.button`
  display: flex; 
  position: absolute;
  top: 24px;
  left: 24px;
  align-items: center;
  flex-direction: row;
  z-index: 13;

  border: none;
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }
  
  .icon {
    color: ${(props) => props.theme.colorsBase.white};
    width: 20px;
    height: 20px;
    background-color: ${(props) => props.theme.colors.seventh};
    border-radius: 50%;
  }

  h1 {
    margin-left: 8px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: center;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;


    @media (min-width: 1600px) {
      line-height: 24px;
      font-size: ${responsiveFont(16)};
    }
  }
`

export const TextHeader = styled.image`
  display: flex; 
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: absolute;
  top: 24px;

  .icon {
    color: ${(props) => props.theme.colors.secundary};
    width: 40px;
    height: 40px;
  }
`

export const TitleHeader = styled.h2`
    z-index: 10;
    position: absolute;
    top: 48px;
    text-align: center;
    font-size: ${responsiveFont(24)};
    color: ${(props) => props.theme.colors.third};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(16)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(26)};
    }
`