import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'
import { theme } from '../../styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:  space-between;
  align-items: center;
  width: 100%;
  height: calc(100vh - 56px);
  margin-top: 56px;
  background-color: ${(props) => props.theme.colorsBase.white};
`

export const ContainerBackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: ${(props) => props.theme.colors.primary};
  width: 100%;
  border-radius: 16px 0 0 0;

  .i {
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;

    &:hover {
      opacity: 0.8;
    }
  }

    .iconBackButton {
      color: ${(props) => props.theme.colors.seventh};
      width: 24px;
      height: 24px;
      background-color: ${(props) => props.theme.colorsBase.white};
      border-radius: 50%;

      &:hover {
        opacity: 0.8;
      }
    }

    h5 {
      margin-left: 16px;
      line-height: 32px;
      letter-spacing: 0.18px;
      text-align: left;
      font-size: ${responsiveFont(24)};
      color: ${(props) => props.theme.colorsBase.white};
      font-weight: 500;
      font-family: 'Barlow', Medium;
  
      @media (max-width: 680px) {
        font-size: ${responsiveFont(20)};
      }
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(28)};
      }
    }
  

  @media (max-width: 680px) {
    padding: 16px;
  }
`

export const ContainerData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  width: 50%;
  padding: 8px 16px 32px 16px;
  margin-top: 24px;

  @media (max-width: 680px) {
    width: 100%;
    margin-top: 32px;
    padding: 8px 16px 24px 16px;
  }
`

export const ImageConcluded = styled.img`
  width: 50%;
  object-fit: cover; 

  @media (max-width: 680px) {
    width: 90%;
  }
`

export const ContainerChallenge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  background-color: blue;
  width: 50%;
  height: 100%;
  padding: 16px 24px;
  margin-top: 24px;

`

export const BoxChallengePending = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
  margin-top: 32px;

  .boxImage {
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .boxContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 12px;

    .containerTitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      .title {
        max-width: 480px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colorsBase.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }
    
    }

    .boxUser {
      margin-top: 4px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .i {
        color: ${theme.colors.seventh};
        font-size: 16px;
      }

      .nameUser {
        max-width: 480px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        margin-left: 4px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colorsBase.gray12};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }

    .containerTop {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }
  }

  @media (max-width: 680px) {
   
  }
`

export const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  z-index: 1;
  object-fit: cover; 
`

export const BoxPointsEarned = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 8px 0px;
  position: relative;

    .boxContent {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 2px;

      .i {
        color: #201E1E;
        font-size: 24px;
    
        &:hover {
          color: ${theme.colors.black};
        }
      }

      .titleContent {
        margin-left: 4px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colorsBase.gray12};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
          margin-left: 0px;
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }

      .boxContentLeft {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 4px;
      }

      .boxContentLeftText {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 4px;

        .boxContentLeftLink {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          background: none;
          border: none;
          margin-top: 4px;
        }
      }

      .containerProgressBar {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-left: 8px;
  
        .textProgress {
          margin-left: 6px;
          line-height: 10px;
          letter-spacing: 0.8px;
          text-align: left;
          font-size: ${responsiveFont(12)};
          color: ${(props) => props.theme.colors.black};
          font-weight: 500;
          font-family: 'Barlow', Medium;
          
          @media (max-width: 680px) {
            font-size: ${responsiveFont(10)};
          }
          
          @media (min-width: 1600px) {
            font-size: ${responsiveFont(14)};
          }
        }
      }
    }
  }

  @media (max-width: 680px) {
    padding: 8px 0px;

    .boxContent {
      .i {
        display: none;
      }
    }
  }
`

export const ContainerSummary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
  margin-top: 8px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.friday};

    .boxButton {
      padding: 8px 16px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background: none;
      border: none;
      
      &:hover {
        opacity: 0.8;
      }
      
      .i {
        color: ${(props) => props.theme.colors.fourth};
        font-size: 24px;
      }

      .titleContent {
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colorsBase.gray12};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }

    .containerQuestion {
      padding: 12px 16px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .boxQuestion {
      margin: 4px 0px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      
      .i {
        color: ${(props) => props.theme.colors.secundary};
        font-size: 24px;

        @media (max-width: 680px) {
          font-size: 20px;
        }
      }

      .boxTextQuestion {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

      .textQuestion {
        line-height: 16px;
        letter-spacing: 0.4px;
        text-align: left;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(16)};
        }
      }

      .textAlternative {
        line-height: 16px;
        letter-spacing: 0.4px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colorsBase.black3};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(10)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }

    }
  }
`

export const MessageRedoChallenge = styled.h1`
  margin-top: 8px;
  width: 100%;
  text-align: left;
  font-size: ${responsiveFont(12)};
  color: ${(props) => props.theme.colorsBase.gray12};
  font-weight: 400;
  font-family: 'Barlow', Regular;
  margin-left: 8px;

  @media (max-width: 680px) {
    text-align: center;
    margin-left: 0px;
  }

  @media (min-width: 1600px) {
    font-size: ${responsiveFont(14)};
  }
`