import { useQuery } from "@tanstack/react-query";

import { getChallengesInModeration } from "../../services/Moderation";
import { ErrCodes } from "../../services/types";
import { IGetChallengesModeration } from "~/services/Moderation/types";

export const useChallengesInModeration = (params: IGetChallengesModeration) =>
  useQuery(
    ["useChallengesInModeration", params],
    async () => {
      const response = await getChallengesInModeration(params);
      if (!response.Success) throw new Error(response.ErrorDetails)

      return response
    },
    { meta: { errCode: ErrCodes.MODERATION_FETCH_FAILED } }
  )
