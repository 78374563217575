import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { WithTranslationProps, withTranslation } from 'react-i18next';

import QueryContainer from '../../../../components/QueryContainer';
import QueryPagination from '../../../../components/Table/QueryPagination';
import ViewError from '../../../../components/ViewError';
import SearchBar from '../../../../components/SearchBar';
import ChallengeItemDesktop from '../../../../components/ChallengeItemDesktop';
import ModalCustom from '../../../../components/ModalCustom';
import { EChallengeStatus } from '../../../../services/ChallengesManagement/All/types';
import { useChallengeCurationAll } from '../../../../services/ChallengesCuration/queries/All';
import { IChallengeCurationAllQueryResponse } from '../../../../services/ChallengesCuration/queries/All/types';

import ChallengeInspectionDetail from './ChallengeInspectionDetail';

const InspectionPendents: React.FC<WithTranslationProps> = ({ i18n }) => {
  const [searchValue, setSearchValue] = useState("")
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)

  const query = useChallengeCurationAll({ Title: search, Page: page, Status: EChallengeStatus.InCuration })
  const [selectedItem, setSelectedItem] = useState<IChallengeCurationAllQueryResponse | null>(null)

  const handleClose = useCallback(() => setSelectedItem(null), [])

  return (
    <>
      <Typography
        px="24px"
        pt={1}
        dangerouslySetInnerHTML={{ __html: i18n?.t("Aqui você encontra os desafios atribuídos a você, como <b>Curador</b>, disponíveis para serem <b>moderados</b>.").toString() ?? "" }}
      />

      <SearchBar
        filterButton
        value={searchValue}
        placeholder='Busque o desafio desejado'
        setValue={setSearchValue}
        setFilterModal={() => setSearch(searchValue)}
      />

      <ModalCustom
        open={!!selectedItem}
        onClick={() => { }}
        onClose={handleClose}
        buttonsEnabled={false}
        title="Detalhes do Desafio"
        style={{ width: "auto" }}
        childrenContent={selectedItem ?
          <ChallengeInspectionDetail item={selectedItem} onClose={handleClose} />
          : null
        }
      />

      <Box>
        <Box display="flex" flexWrap="wrap" gap={4} px="16px" pt="16px">
          <QueryContainer query={query}>
            {({ data }) => data.Data.length ? (
              <>
                {data.Data.map((item) => (
                  <ChallengeItemDesktop
                    onClick={() => setSelectedItem(item)}
                    item={{
                      CoverImageUrl: item.CoverImageUrl,
                      Language: '1',
                      AuthorName: item.AuthorName,
                      Score: 0,
                      Title: item.Title,
                      Id: item.Id.toString(),
                      DateTime: item.DateTime
                    }}
                    showAuthorName
                    showId
                    hidenDifficultyInfo
                    hidenBottonInfo
                    showCreateDate

                  />
                ))}
              </>
            ) : <ViewError>{i18n?.t("Não há desafios a serem moderados, tente novamente mais tarde")}</ViewError>}
          </QueryContainer>
        </Box>
        <QueryPagination query={query} setPage={setPage} />
      </Box>
    </>
  );
}

export default withTranslation()(InspectionPendents);
