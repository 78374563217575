import React from 'react';

import PageContainer from '../../components/Layout/PageContainer';
import PaperContainer from '../../components/Layout/PaperContainer';
import { AntTab, AntTabs } from '../../components/Tabs';
import { theme } from '../../styles/theme';
import HeaderAchievements from '../../components/HeaderAchievements';
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../hooks/useIsSm';
import { Shop } from '@mui/icons-material';
import ProductViewAvailable from './ProductViewAvailable';



interface IProps {
  tab: number;
  setNewTab: (newTab: number) => void;
  variant?: "fullWidth" | "standard" | "scrollable";
}

const Product: React.FC<IProps> = ({ setNewTab, tab, variant }) => {
  const { t } = useTranslation();
  const isSm = useIsSm()

  return (
    <PageContainer>
      <HeaderAchievements
        title={t('Produtos')}
        icon={<Shop style={{ color: theme.colors.seventh }} />}
        description={isSm ? t('Aqui você encontra os produtos disponíveis, que você pode resgatar utilizando seus pontos!') ?? '' : ''}
      />
      <PaperContainer>
        <AntTabs value={tab} onChange={(_, newValue) => setNewTab(newValue)} variant={variant}>
          <AntTab label="DISPONÍVEIS" sx={{ width: isSm ? '50vw' : 'none', margin: isSm ? '0px auto' : '0px' }} />
          <AntTab label="TROCADOS" sx={{ width: isSm ? '50vw' : 'none', margin: isSm ? '0px auto' : '0px' }} />
        </AntTabs>

        {tab === 0 && <ProductViewAvailable tab={1} />}
        {tab === 1 && <ProductViewAvailable tab={2} />}
      </PaperContainer>
    </PageContainer>
  )
}

export default Product
