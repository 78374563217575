import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 56px;
  background-color: ${(props) => props.theme.colorsBase.white};
`

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 0 40px;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colorsBase.white};

  @media (max-width: 680px) {
    padding:  0px 0px 72px 0px;
  }
`

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 32px 0 32px 0;


  @media (max-width: 680px) {
    margin: 32px 0 0px 0;
    padding: 0px 20px;
  }

  h3 {
    margin-top: 20px;
    letter-spacing: 0.18px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    align-self: flex-start;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .containerSubTitle {
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  }
`


export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 0.5px;
  background-color: ${(props) => props.theme.colors.ten};

  h5 {
    line-height: 32px;
    letter-spacing: 0.18px;
    text-align: left;
    font-size: ${responsiveFont(24)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    background-color: ${(props) => props.theme.colorsBase.white};
    padding-right: 8px;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(26)};
    }
  }
`

export const ContainerSearch = styled.div`
  width: 50%;
  z-index: 100;
  position: fixed;
  top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
`

export const ButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none; 
  padding: 8px;
  background-color: ${(props) => props.theme.colors.primary};
  margin-left: 8px;
  cursor: pointer;

  .icon {
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;

  width: 60%;

  padding: 8px 0;

  p {
    font-weight: 500;
  }

  @media (max-width: 680px) {
    width: 100%;
    margin-top: -12px;
  }
`

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 0px 12px;

  > div {
    display: flex;
    align-self: flex-start;

    padding: 8px 0;

    input {
      width: 22px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .input {
    width: 100%;

    input {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }

    select {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }
  }
`

export const ButtonScrollToTop = styled.a`
  position: fixed;
  right: 25%;
  bottom: 24px;
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: 40px;
  cursor: pointer;
  z-index: 90;
  background-color:  ${(props) => props.theme.colors.seventh};
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
  padding: 8px;
  
  h5 {
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colorsBase.white};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    padding-left: 8px;
  }

  .icon {
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`

export const LineSeparator = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 32px;

.boxSeparator {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 0.5px;
  background-color: ${(props) => props.theme.colors.ten};
}

  .iconSeparator {
    color: ${(props) => props.theme.colors.seventh};
    font-size: 24px;
    background-color: ${(props) => props.theme.colorsBase.white};

    @media (max-width: 680px) {
      font-size: 16px;
    }
  }

  h5 {
    text-align: left;
    font-size: ${responsiveFont(20)};
    color: ${(props) => props.theme.colors.secundary};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    background-color: ${(props) => props.theme.colorsBase.white};
    padding-right: 12px;
    padding-left: 8px;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(22)};
    }

    @media (max-width: 680px) {
      font-size: ${responsiveFont(14)};
    }
  }

  h6 {
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.friday};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    background-color: ${(props) => props.theme.colorsBase.white};
    padding-right: 12px;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  @media (max-width: 680px) {
    padding: 20px 20px 0px 20px;
  }
`

export const SeparatorButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 40px;

  .buttonSeparator {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;

    .i {
      color: ${(props) => props.theme.colors.secundary};
      font-size: 24px;
  
      @media (max-width: 680px) {
        font-size: 16px;
      }
    }
  
    h5 {
      text-align: left;
      font-size: ${responsiveFont(20)};
      color: ${(props) => props.theme.colors.secundary};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      padding-right: 4px;
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(22)};
      }
  
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
    }
  }

  @media (max-width: 680px) {
    margin-top: 16px;
   padding-bottom: 16px;
    justify-content: center;
  }
`

export const ContainerChallenge = styled.div`
  display: flex;
  justify-content:  flex-start;
  align-items: flex-start;
  flex-direction: row;
  width: 85vw;
  position: relative;

  .btn-prevB {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    position: absolute;
    left: -16px;
    top: 50%;
    margin-top: -40px;

    .ibtn-prevB {
      color: ${(props) => props.theme.colors.seventh};
      font-size: 40px;
    }
  }

  .btn-nextB {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    position: absolute;
    right: -16px;
    top: 50%;
    margin-top: -40px; 

    .ibtn-nextB {
      color: ${(props) => props.theme.colors.seventh};
      font-size: 40px;
    } 
  }


  @media (max-width: 680px) {
    width: 100%;
  }
`