/* eslint-disable no-template-curly-in-string */

import RuntimeEnvironment from "./RuntimeEnvironment"

export const PARAMS_SERVICE_URL = RuntimeEnvironment.REACT_APP_SERVER_API;
export const USER_GET = '@CS:user'
export const TOKEN_GET = '@CS:token'
export const LANGUAGE_GET = '@CS:language'
export const USER_REMEMBER = '@CS:remember'

