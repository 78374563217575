import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  left: 50%;
  position: absolute;
  width: 620px;
  height: 500px;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 4px;
  box-shadow: 24;
  padding: 24px;

  .icon {
    color: ${(props) => props.theme.colorsBase.black};
    width: 20px;
    height: 20px;
    top: 24px;
    right: 24px;
    position: absolute;
  }

  .buttonClose {
    display: flex;
    position: absolute;
    top: 0px;
    right: 0px;
    align-items: center;
    flex-direction: row;
    border: none;
    background-color: transparent;
  
    &:hover {
      opacity: 0.8;
    }

    .icon {
      color: ${(props) => props.theme.colorsBase.black};
      width: 16px;
      height: 16px;
    }
  }
 
  .buttonAcceptedTerm {
    display: flex;
    align-items: center;
  
    flex-direction: row;
    border: none;
    background-color: transparent;
  
    &:hover {
      opacity: 0.8;
    }

    .iconAcceptedTerm {
      color: ${(props) => props.theme.colors.fifteen};
      width: 24px;
      height: 24px;
    }

    .iconAcceptedTermBlank {
      color: ${(props) => props.theme.colors.fourteen};
      width: 24px;
      height: 24px;
    }

    h1 {
      margin-left: 8px;
      line-height: 20px;
      letter-spacing: 0.25px;
      text-align: center;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Medium;
    }
  }

  h5 {
    line-height: 24px;
    letter-spacing: 0.14px;
    text-align: center;
    font-size: ${responsiveFont(16)};
    margin-top: 8px;
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Medium;
  }

  .button {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
  }

  @media (min-width: 1600px) {
    width: 900px;
    height: 700px;

    h5 {
      line-height: 24px;
      font-size: ${responsiveFont(20)};
    }
  }

  @media (max-width: 680px) {
    width: 90%;
    height: 600px;
  }
`

export const Table = styled.div`
  position: relative;
  overflow: auto;
  margin: 48px 24px 32px 16px;
  padding-right: 8px;
  z-index: 10;

  &::-webkit-scrollbar {
    border-radius: 8px;
    width: 4px;
    background: ${(props) => props.theme.colors.eleven};
    }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.seventh};
    border-radius: 8px;
  }
  ::-webkit-scrollbar:vertical { 
    height: 4px;
    border-radius: 8px;
  }

  p {
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    margin-bottom: 8px;
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Medium;
  }

  @media (min-width: 1600px) {
    p {
      line-height: 24px;
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
    padding-right: 4px;
    margin: 48px 8px 32px 16px;
  }
`

export const TableContainer = styled.div<{ register?: boolean }>`
  position: absolute;
  top: 80px;
  left: 20px;
  width: 565px;
  height: ${(props) => (props.register ? '380px' : '290px')};
  // border: solid 1px ${(props) => props.theme.colors.thirteen};
  border-radius: 8px;

  @media (min-width: 1600px) {
    width: 850px;
    height: ${(props) => (props.register ? '580px' : '490px')};    
  }

  @media (max-width: 680px) {
    width: 90%;
    height: ${(props) => (props.register ? '480px' : '390px')};
  }
`
