import { Refresh } from '@mui/icons-material';
import React from 'react'
import styled, { keyframes } from 'styled-components';
import * as S from './styles'

export interface IProps {
  /* eslint-disable-next-line @typescript-eslint/ban-types */
  style?: Object;
  /* eslint-disable-next-line @typescript-eslint/ban-types */
  styleIcon?: Object;
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingElement = styled(Refresh)`
  animation: ${spin} 1s linear infinite;
`;

const Loading: React.FC<IProps> = ({ style, styleIcon }) => {
  return (
    <S.Container style={style}>
      <RotatingElement style={styleIcon}/>
    </S.Container>
  )
}

export default Loading;
