import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding-top: 56px;

  @media (max-width: 680px) {
    height: 100vh;
    background-color: ${(props) => props.theme.colorsBase.white};
  }
`

export const ContainerContent = styled.div`
  padding: 0 40px;
  width: 100%;
  height: 100%;

  @media (max-width: 680px) {
    padding: 0;
  }
`

export const ContainerTable = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 24px;
  border-radius: 16px 16px 0px 0px;
  background-color: ${(props) => props.theme.colorsBase.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .containerButton {
    align-self: flex-end;
    margin-right: 24px;
  }
  
  @media (max-width: 680px) {
    padding: 16px 16px 0px 16px;
    border-radius: 0px;
    background: transparent;
    box-shadow: none;
  }
`

export const ContainerBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 16px 16px;
  margin-top: 40px;
  width: 100%;
  height: 100%;

  @media (max-width: 680px) {
    padding: 16px 0px;
  }
`