import React from 'react';
import { Box, Stack } from '@mui/material';

import { EChallengeStatus, IChalengeScreeningQueryResponse } from '../../../../../services/ChallengesManagement/All/types';
import { useChalengeQuiz } from '../../../../../services/ChallengesDefinition/Quizzes';
import { useChalengeDetails } from '../../../../../services/ChallengesManagement/Details';
import QueryContainer from '../../../../../components/QueryContainer';

import TriageItemModal from '../../TriageItemModal';
import Timeline from '../../Timeline';
import ApprovedInfo from './ApprovedInfo';

interface IProps {
  item: IChalengeScreeningQueryResponse
}

const ChallengeAllDetail: React.FC<IProps> = ({ item }) => {
  const chalengeDetails = useChalengeDetails({ Id: item.Id });
  const chalengeQuizQuery = useChalengeQuiz({ Id: item.Id });

  return (
    <Box width="70vw" maxWidth="1200px">
      <QueryContainer query={chalengeDetails}>
        {({ data: { Data: challengeDetail } }) => (
          <QueryContainer query={chalengeQuizQuery}>
            {({ data: { Data: challengeQuizes } }) => (
              <Box
                flex={1}
                display="flex"
                width="70vw"
                maxWidth="1200px"
                gap={2}
                sx={({ breakpoints }) => ({
                  [breakpoints.down("md")]: {
                    flexDirection: "column"
                  }
                })}
              >
                <Box flex={0.5}>
                  <TriageItemModal
                    challengeDetail={challengeDetail}
                    challengeQuizes={challengeQuizes}
                    hasQuiz={challengeQuizes.length > 0}
                  />
                </Box>

                <Stack flex={0.5} width="100%" spacing={1}>
                  {item.Status === EChallengeStatus.Active ?
                    <ApprovedInfo id={item.Id} /> :
                    null}

                  <Timeline
                    timeLine={challengeDetail.TimeLine}
                    sx={{ flex: 1 }}
                    maxHeight={item.Status === EChallengeStatus.Active ? undefined : "400px"}
                  />
                </Stack>
              </Box>
            )}
          </QueryContainer>
        )}
      </QueryContainer>
    </Box>
  )
}

export default ChallengeAllDetail;
