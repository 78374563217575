import React from 'react';
import { History } from '@mui/icons-material';
import { Box, Paper, Stack, SxProps, Typography } from '@mui/material';
import { format } from 'date-fns';

import { Content } from '../../../../components/ChallengeInfoPage/styles';
import { IChalengeDetailsQueryResponse } from '../../../../services/ChallengesManagement/Details/types';

interface IProps {
  timeLine: IChalengeDetailsQueryResponse["TimeLine"],
  sx?: SxProps,
  maxHeight?: string
}

const Timeline: React.FC<IProps> = ({ timeLine, sx, maxHeight }) => (
  <Paper sx={{ p: "8px 16px 0 16px", height: '100%', ...sx }}>
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <History sx={{ color: '#DBDADA' }} />
      <Typography variant='h6' fontWeight="400">Linha do tempo do Desafio</Typography>
    </Stack>

    <Stack
      sx={({ breakpoints }) => ({
        overflow: "auto",
        maxHeight: maxHeight || "205px",
        // height: "205px",
        paddingBottom: 1,
        [breakpoints.down("md")]: {
          height: "100%",
        }
      })}
    >
      {timeLine.map((item, index) => (
        <Box
          key={`item-timeline-${item.DateTime}-${item.Action}`}
          sx={{
            // minWidth: "300px",
            p: 1,
            bgcolor: index % 2 === 0 ? undefined : "#F9F9F9",
            border: `solid #DBDADA`,
            borderWidth: "1px",
            borderRadius: `${index === 0 ? "4px 4px" : "0px 0px"} ${index === timeLine.length - 1 ? "4px 4px" : "0px 0px"}`,
          }}
        >
          <Content>{`${format(new Date(item.DateTime), "dd/MM/yyyy hh:mm")} - ${item.Action}`}</Content>
        </Box>
      ))}
    </Stack>
  </Paper >
)

export default Timeline;