import styled from 'styled-components'
import { responsiveFont } from '../../../utils/UtilsGlobal'



export const ContainerTable = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-self: center;

  .containerButton {
    display: flex;
    align-self: flex-start;
    padding: 8px 16px 8px 24px;
    flex-direction: row;
    width: 100%;
  }
  
  @media (max-width: 680px) {
    .containerButton {
      display: flex;
    align-self: flex-start;
    padding: 8px 16px 8px 16px;
    flex-direction: column;
    width: 100%;
    }
  }
`



export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  label {
    white-space: nowrap;
    margin-left: 8px;
    color: ${(props) => props.theme.colors.primary};
  }

  .icon {
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`





export const BoxSelectPermission = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  h5 {
    line-height: 20px;
    letter-spacing: 0.15px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black};
    opacity: 0.6;
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(12)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }
`

export const ContainerSelectPermission = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const BoxChip = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`;

export const ContainerChip = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin-top: 16px;
`;
