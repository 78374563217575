import { IGifts } from "../../services/Gifts/types"


export const emptyItem: IGifts = {
  Id: "",
  Nome: "",
  Foto: "",
  QtdPontos: "",
  DtCadastro: "",
}

export const UserType = {
  SELLER: 'Comprador',
  BUYER_AND_SELLER: 'Comprador; Vendedor'
}
export interface ITableUsers {
  listUsers: IGifts[]
  updateList: () => void
}

export class Registration {
  nome = '';
  pontos = '';
}

export class RegistrationError {
  nome = '';
  pontos = '';
}
