import React from 'react';
import logo from '../../assets/images/logo.png'
import { useNavigate } from 'react-router-dom';
import SelectLanguage from '../../components/SelectLanguage';
import * as S from './styles'
import { HeaderProps } from './types';
import ModalNotifications from '../ModalNotifications';
import ModalMenu from '../ModalMenu';


const Header: React.FC<HeaderProps> = () => {
  const navigate = useNavigate()

  return (
    <S.Header>
      <button onClick={() => navigate(`/home`)}>
        <img src={logo} alt='' />
      </button>

      <S.ButtonHeader>
        <SelectLanguage colorPrimary />
        <ModalNotifications colorPrimary />

        <ModalMenu colorPrimary />
      </S.ButtonHeader>
    </S.Header>
  )
}

export default Header
