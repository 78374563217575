import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 16px;

  @media (max-width: 680px) {
    padding: 0;
  }
`



export const ContainerContent = styled.div`
  padding: 0 40px;
  width: 100%;
  min-height: 100%;

  @media (max-width: 680px) {
    padding: 0;
  }
`

export const ContainerTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  padding: 8px 0 24px 0;
  border-radius: 16px 16px 0px 0px;
  background-color: ${(props) => props.theme.colorsBase.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-height: 100%;
   
  .containerButton {
    display: flex;
    align-self: flex-end;
    width: 100%;
    padding:  12px 16px 0px 24px;
  }
  
  @media (max-width: 680px) {
    border-radius: 0px;
    background: transparent;
    box-shadow: none;

    .containerButton {
      display: flex;
      align-self: flex-start;
      padding: 0px 16px 0px 16px;
      flex-direction: column;
      width: 100%;
    }
  }
`

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;

  @media (max-width: 680px) {
    margin: 20px 0 0px 0;
  }
`

export const LineHeader = styled.div<{ register?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-bottom: solid 0.5px ${(props) => props.theme.colorsBase.gray9};
  padding: 0 40px;

  @media (max-width: 680px) {
    margin-bottom: ${(props) => (props.register ? '8px' : ' 48px')};
    border-bottom: none;
    justify-content: center;
    padding: 0;
  }
`

export const ButtonHeader = styled.button<{ active?: boolean }>`
  width: 15%;
  border: none;
  border-bottom: solid 2px ${(props) => (props.active ? props.theme.colors.seventh : 'transparent')};
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }
  
    h5 {
      line-height: 16px;
      text-align: center;
      font-size: ${responsiveFont(12)};
      color: ${(props) => (props.active ? props.theme.colors.seventh : props.theme.colors.friday)};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      padding: 8px 8px 16px 8px;
      text-transform: uppercase;
  
      @media (max-width: 680px) {
        font-size: ${responsiveFont(14)};
        text-transform: none;
      }
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

  @media (max-width: 680px) {
    width: 50%;
  }
`

export const ContainerChallenge = styled.div` 
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;

  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 8px 16px 8px;
    width: 100%;
  }
  
   @media (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 2500px) {
    grid-template-columns: repeat(5, 1fr);
  }
`

export const ButtonScrollToTop = styled.a`
  position: fixed;
  right: 16px;
  bottom: 88px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 90;
  background-color:  ${(props) => props.theme.colors.seventh};
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
  
  .icon {
    position: absolute;
    top: 8px;
    left: 8px;
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`