import { Add } from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ButtonScrollToTop } from '../../pages/Home/styles'

interface IProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  title?: any
  onClick?: () => void
}

const ButtonCreateMobileChallenge: React.FC<IProps> = ({ title, onClick }) => {
  const navigate = useNavigate()
  const { t } = useTranslation();

  return (
    <ButtonScrollToTop onClick={() => onClick ?? navigate('/desafios/criar')}>
      <Add className="icon" />
      <h5>{title ?? t('CRIAR NOVO DESAFIO')}</h5>
    </ButtonScrollToTop>
  )
}

export default ButtonCreateMobileChallenge
