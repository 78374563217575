import React, { useEffect, useState } from 'react';
import * as S from './styles'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ToastNotification from '../../components/ToastNotification';
import ViewLoading from '../../components/ViewLoading';
import ViewError from '../../components/ViewError';
import ModalCustom from '../../components/ModalCustom';
import { MenuItem, Select } from '@mui/material'
import { getChallengesDataPerformed, getChallengesPending } from '../../services/Challenges';
import { IChallengeAccomplished } from '../../services/Challenges/types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ChallengeItemDesktop from '../../components/ChallengeItemDesktop';
import ChallengeDetails from '../../components/ChallengeDetails';
import ModalCustomBottom from '../../components/ModalCustomBottom';
import { useIsSm } from '../../hooks/useIsSm';
import SearchBar from '../../components/SearchBar';
import { Inputs, Item } from '../../pages/MyContacts/styles';
import { ICategories } from '../../components/TableCategory/types';
import { getCategories } from '../../services/Categories';
import Loading from '../../components/Loading';

interface IProps {
  headerActive: number;
}

const MyShares: React.FC<IProps> = ({ headerActive }) => {
  const { t } = useTranslation();
  const isSm = useIsSm()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [challengePerformedList, setChallengePerformedList] = useState<IChallengeAccomplished[]>([])
  let totalPages = 0;
  let currentPage = 0;

  const [openDetailsModal, setOpenDetailsModal] = useState(false)
  const [itemSelected, setItemSelected] = useState<IChallengeAccomplished>()
  const [openResumeChallengeModal, setOpenResumeChallengeModal] = useState(false)
  const [valuePerformed, setValuePerformed] = useState('');
  const [openModalPerformed, setOpenModalPerformed] = useState(false)
  const [categoriesList, setCategoriesList] = useState<ICategories[]>([])

  const [category, setCategory] = useState('');
  const [difficulty, setDifficulty] = useState('');

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    retrieveCategories();
  }, []);

  useEffect(() => {
    setCategory('')
    setDifficulty('')
    setValuePerformed('')
    totalPages = 0;
    currentPage = 0;
    setChallengePerformedList([])
    retrieveChallengePerformed(1, true, '', '', '');
  }, [headerActive]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > (300 * currentPage)) {
        if (currentPage < (totalPages || 0)) {
          retrieveChallengePerformed(currentPage + 1, false, valuePerformed, category, difficulty)
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Função de limpeza para remover o listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const retrieveCategories = async () => {
    setLoading(true)
    try {
      const response = await getCategories();
      if (response.Success) {
        setCategoriesList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar as categorias, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar as categorias, tente novamente.')
      });
    }
    setLoading(false)
  };

  const retrieveChallengePerformed = async (page: number = 1, clear: boolean = false, title: string, category: string, difficulty: string) => {
    if (page === 1) {
      setLoading(true)
    }
    try {
      if (clear) {
        setChallengePerformedList([]);
      }
      const bodyData = {
        PageNumber: 1,
        PageSize: 64 * page,
        Name: title,
        Category: category !== '0' ? category : '',
        DifficultyLevel: difficulty !== '0' ? difficulty : '',
        ChallengesDisabled: headerActive === 0 ? 'true' : 'false'
      };

      const response = await getChallengesPending(bodyData);
      if (response.Success) {
        currentPage = page;
        if (page === 1 && response.TotalPages) {
          totalPages = response.TotalPages;
        }
        setChallengePerformedList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os desafios, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os desafios, tente novamente.')
      });
    }
    setLoading(false)
  };

  const onEnterSearch = (event: { key: string }) => {
    if (event.key === 'Enter') {
      retrieveChallengePerformed(currentPage + 1, true, valuePerformed, category, difficulty)
    }
  };

  const retrieveDataChallengePerformed = async (id?: string) => {
    setLoading(true)
    try {
      const response = await getChallengesDataPerformed(id);
      if (response.Success) {
        setItemSelected(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  const returnChallengeCard = () => {
    if (headerActive === 0) return 4;
    if (headerActive === 1) return 5;
    return 4;
  };

  const returnErrorMsg = () => {
    if (headerActive === 0) return t('Nenhum desafio finalizado') ?? 'Nenhum desafio finalizado';
    if (headerActive === 1) return t('Nenhum desafio iniciado') ?? 'Nenhum desafio iniciado';
    return 'Nenhum desafio iniciado';
  };

  const handleClickCard = (id: string) => {
    if (headerActive === 0) {
      setOpenDetailsModal(true)
      retrieveDataChallengePerformed(id)
    }
    if (headerActive === 1) {
      retrieveDataChallengePerformed(id)
      setOpenDetailsModal(true)
    }
    return 8;
  };

  return (
    <S.Container>
      <SearchBar
        value={valuePerformed}
        setValue={(e) => setValuePerformed(e)}
        onEnterSearch={(e) => onEnterSearch(e)}
        setFilterModal={() => setOpenModalPerformed(true)}
        placeholder={t('Busque o desafio desejado')}
        filterButton
      />

      {loading ? (
        <ViewLoading />
      ) : (
        <>
          {challengePerformedList.length > 0 ? (
            <>
              <S.ContainerChallenge>
                {challengePerformedList.map((item) => (
                  <>
                    {!isSm ?
                      <ChallengeItemDesktop
                        onClick={() => handleClickCard(item.Id)}
                        typeChallenge={returnChallengeCard()}
                        item={item}
                      />
                      :
                      <ChallengeItemDesktop
                        onClick={() => handleClickCard(item.Id)}
                        typeChallenge={returnChallengeCard()}
                        item={item}
                        style={{ width: windowWidth < 400 ? 'none' : '90%', margin: '0' }}
                      />
                    }
                  </>
                ))}
              </S.ContainerChallenge>

              {loading && <Loading />}
            </>
          ) : (
            <ViewError message={returnErrorMsg()} />
          )}
        </>
      )}


      {!isSm ?
        <ModalCustom
          open={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          onClick={() => { }}
          childrenContent={
            <ChallengeDetails item={itemSelected} onClick={() => headerActive === 0 ? navigate(`/desafio/${itemSelected?.ChallengeId}`) : setOpenResumeChallengeModal(true)} type={headerActive} />
          }
          title={t('Detalhes')}
          buttonsEnabled={false}
        />
        :
        <ModalCustomBottom
          open={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          title={t('Detalhes')}
          childrenContent={
            <ChallengeDetails item={itemSelected} onClick={() => headerActive === 0 ? navigate(`/desafio/${itemSelected?.ChallengeId}`) : setOpenResumeChallengeModal(true)} type={headerActive} />
          }
        />
      }

      <ModalCustom
        open={openResumeChallengeModal}
        onClose={() => setOpenResumeChallengeModal(false)}
        onClick={() => navigate(`/desafio/${itemSelected?.ChallengeId}`)}
        title={t('Retomar desafio')}
        description={t('Desafio em espera. Deseja retomar o desafio?')}
        confirmationButtonText={t('RETOMAR')}
        textDeclineButton={t('VOLTAR')}
      />

      <ModalCustom
        open={openModalPerformed}
        onClose={() => setOpenModalPerformed(false)}
        onClick={() => {
          retrieveChallengePerformed(currentPage + 1, true, valuePerformed, category, difficulty)
          setOpenModalPerformed(false)
        }}
        childrenContent={
          <>
            <Item>
              <Inputs>
                <div className="input">
                  <FormControl sx={{ display: 'flex', m: 1, width: '100%' }}>
                    <InputLabel id="demo-simple-select-helper-label">{t('Categoria')}</InputLabel>
                    <Select
                      MenuProps={{
                        MenuListProps: {
                          sx: { maxHeight: '200px', maxWidth: '100%', overflowY: 'auto' }
                        }
                      }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      variant="standard"
                      style={{ display: 'flex', width: '100%' }}
                      value={category}
                      onChange={(e) => {
                        setCategory(String(e.target.value))
                      }}
                    >
                      {categoriesList.map((item) => (
                        <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Inputs>
            </Item>

            <Item>
              <Inputs>
                <div className="input">
                  <FormControl sx={{ display: 'flex', m: 1, width: '100%' }}>
                    <InputLabel id="demo-simple-select-helper-label">{t('Dificuldade')}</InputLabel>
                    <Select
                      MenuProps={{
                        MenuListProps: {
                          sx: { maxHeight: '200px', maxWidth: '100%', overflowY: 'auto' }
                        }
                      }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      variant="standard"
                      style={{ display: 'flex', width: '100%' }}
                      value={difficulty}
                      onChange={(e) => {
                        setDifficulty(String(e.target.value))
                      }}
                    >
                      <MenuItem value={'0'}>{t('Todos')}</MenuItem>
                      <MenuItem value={'2'}>{t('Fácil')}</MenuItem>
                      <MenuItem value={'3'}>{t('Normal')}</MenuItem>
                      <MenuItem value={'1'}>{t('Difícil')}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Inputs>
            </Item>
          </>
        }
        title={t('Filtros')}
        confirmationButtonText={t('Pesquisar')}
        textDeclineButton={t('CANCELAR')}
      />
    </S.Container>
  )
}

export default MyShares
