import React, { useState } from 'react';
import PageContainer from '../../components/Layout/PageContainer';
import PaperContainer from '../../components/Layout/PaperContainer';
import { AntTab, AntTabs } from '../../components/Tabs';
import { theme } from '../../styles/theme';
import HeaderAchievements from '../../components/HeaderAchievements';
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../hooks/useIsSm';
import { AssignmentInd } from '@mui/icons-material';
import MyShares from '.';

const MySharesPage: React.FC = () => {
  const { t } = useTranslation();
  const isSm = useIsSm()
  const [tab, setTab] = useState(0)

  return (
    <PageContainer>
      <HeaderAchievements
        title={t('Minhas participações')}
        description={t('Aqui você encontra informações sobre os desafios que você participou.') ?? ''}
        icon={<AssignmentInd style={{ color: isSm ? theme.colors.secundary : theme.colorsBase.gray3 }} />}
        buttonBack={!isSm}
        marginBottom={isSm ? 24 : 0}
      />

      <PaperContainer>
        <AntTabs value={tab} onChange={(_, newValue) => setTab(newValue)} variant={isSm ? 'scrollable' : 'standard'}>
          <AntTab label="DESAFIOS FINALIZADOS" sx={{ width: isSm ? '50vw' : 'none' }} />
          <AntTab label="DESAFIOS INICIADOS" sx={{ width: isSm ? '50vw' : 'none' }} />
        </AntTabs>

        {tab === 0 && <MyShares headerActive={0} />}
        {tab === 1 && <MyShares headerActive={1} />}
      </PaperContainer>
    </PageContainer>
  )
}

export default MySharesPage
