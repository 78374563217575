import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'

import ThemeMain from "./styles/themeMain";
import GlobalStyle from './styles/stylesGlobal'
import AppRoutes from './routes'
import { AuthProvider } from './contexts/Auth'
import ToastNotificationContainer from './components/ToastNotification/ToastNotificationContainer';
import ContainerApp from './components/ContainerApp'
import ToastNotification from './components/ToastNotification';
import { ErrCodes } from './services/types';

function App() {
  const { t } = useTranslation()
  const [queryClient] = useState(new QueryClient({
    defaultOptions: {
      queries: {
        networkMode: 'always',
        refetchOnWindowFocus: false
      }
    },
    queryCache: new QueryCache({
      onError: (_, query) => {
        switch (query.meta?.errCode) {
          case ErrCodes.MODERATION_FETCH_FAILED:
            return ToastNotification({
              id: 'error',
              type: 'error',
              message: t('Houve um erro ao buscar os desafios para revisão, tente novamente.'),
            });

          default:
            break;
        }
      }
    })
  }))

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeMain>
        <BrowserRouter>
          <AuthProvider>
            <ContainerApp>
              <AppRoutes />
            </ContainerApp>
            <ToastNotificationContainer />
            <GlobalStyle />
          </AuthProvider>
        </BrowserRouter>
      </ThemeMain>
    </QueryClientProvider>
  );
}

export default App;
