import styled from 'styled-components'

export const HeaderSearch = styled.div`
  display: flex;
  width: 101vw;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.secundary}; 
  position: fixed;
  z-index: 999;
  flex-direction: row;
  border-radius: 0px 0px 24px 24px;
`

export const Header = styled.div`
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  background-color: ${(props) => props.theme.colors.secundary}; 
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 99;
  flex-direction: row;
  top: 0px;

  button {
    border: 0;
    background: none;

    img {
      width: 100px;
      object-fit: cover;
    }
  }

  .containerButtons {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

export const ContainerSearch = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? props.theme.colorsBase.gray2 : 'none')}; 

  .i {
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`

const funcOptions = (active: boolean) => {
  return active ? '32px' : '0px'
};

export const ButtonModal = styled.div<{ active?: boolean, mobile?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin-right: ${(props) => (props.mobile ? '16px' : funcOptions(props.active ?? false))};  

  .i {
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`

export const ButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none; 
  padding: 8px;
  background-color: ${(props) => props.theme.colorsBase.gray2}; 
  margin-left: 8px;

  .icon {
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`
