export type IGetPaginated = {
  Page?: number;
  PageSize?: number;
  OrderByProperty?: string;
}

export enum ErrCodes {
  MODERATION_FETCH_FAILED,
  TRIAGE_FETCH_FAILED,
  TERMS_OF_USE_FETCH_FAILED,
  SETTINGS_PARAMETERS_FETCH_FAILED,
  CHALLENGE_DESHBOARD_FETCH_FAILED,
}
