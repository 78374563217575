import React, { useEffect, useState } from 'react';
import ToastNotification from '../../components/ToastNotification';
import ViewLoading from '../../components/ViewLoading';
import ViewError from '../../components/ViewError';
import { Select, MenuItem } from '@mui/material'
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import ModalCustom from '../../components/ModalCustom';
import Input from '../../components/Input';
import { Registration, RegistrationError } from './types';
import { registerValidation } from './validations';
import { useIsSm } from '../../hooks/useIsSm';
import * as S from './styles'
import { PersonAdd, VolunteerActivism } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CardContact from '../../components/CardContact';
import { getContacts, getInvitationsReceived, getInvitationsSent, postAcceptInvite, postContact, postDonatePoints, postRefuseInvitation } from '../../services/Contacts';
import { IContacts } from '../../services/Contacts/types';
import noImage from '../../assets/images/noImage.png'
import { getWallet } from '../../services/Challenges';
import SearchBar from '../../components/SearchBar';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
interface IProps {
  tab: number
}

const MyContacts: React.FC<IProps> = ({ tab }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [openRegisterUsersModal, setOpenRegisterUsersModal] = useState(false)
  const [loadingRegisterUser, setLoadingRegisterUser] = useState(false)
  const [registration, setRegistration] = useState<Registration>(new Registration());
  const [registrationError, setRegistrationError] = useState<RegistrationError>(new RegistrationError());
  const isSm = useIsSm()

  const [contactsList, setContactsList] = useState<IContacts[]>([])
  const [totalPages, setTotalPages] = useState<number | undefined>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [contactsListInvitationsSent, setContactsListInvitationsSent] = useState<IContacts[]>([])
  const [totalPagesInvitationsSent, setTotalPagesInvitationsSent] = useState<number | undefined>(0)
  const [currentPageInvitationsSent, setCurrentPageInvitationsSent] = useState(1)
  const [contactsListInvitationsReceived, setContactsListInvitationsReceived] = useState<IContacts[]>([])
  const [totalPagesInvitationsReceived, setTotalPagesInvitationsReceived] = useState<number | undefined>(0)
  const [currentPageInvitationsReceived, setCurrentPageInvitationsReceived] = useState(1)

  const [itemSelected, setItemSelected] = useState<IContacts | null>(null)
  const [loadingDonatePoints, setLoadingDonatePoints] = useState(false)
  const [openDonatePointsModal, setOpenDonatePointsModal] = useState(false)
  const [openConfirmDonationModal, setOpenConfirmDonationModal] = useState(false)
  const [points, setPoints] = useState(0)
  const [pointsBalance, setPointsBalance] = useState(0)

  const [statusUser, setStatusUser] = useState('0');
  const [openFilterUsersModal, setOpenFilterUsersModal] = useState(false)

  const [valueContacts, setValueContacts] = useState('');
  const [valueContactsInvitationsSent, setValueContactsInvitationsSent] = useState('');
  const [valueContactsInvitationsReceived, setValueContactsInvitationsReceived] = useState('');


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > (300 * currentPage)) {
        if (tab === 0) {
          if (currentPage < (totalPages || 0)) {
            retrieveContacts(currentPage + 1)
          }
        } else if (tab === 1) {
          if (currentPageInvitationsSent < (totalPagesInvitationsSent || 0)) {
            retrieveContactsInvitationsSent(currentPageInvitationsSent + 1)
          }
        } else if (tab === 2) {
          if (currentPageInvitationsReceived < (totalPagesInvitationsReceived || 0)) {
            retrieveContactsInvitationsReceived(currentPageInvitationsReceived + 1)
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Função de limpeza para remover o listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    retrieveContacts(1, true);
    retrieveContactsInvitationsSent(1, true);
    retrieveContactsInvitationsReceived(1, true);
    retrievePoints();
  }, []);

  const retrievePoints = async () => {
    setLoading(true)
    try {
      const response = await getWallet();
      if (response.Success) {
        setPointsBalance(response.Data.Balance)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  const retrieveContacts = async (page: number = 1, clear: boolean = false) => {
    setLoading(true)
    try {
      if (clear) {
        setContactsList([]);
      }
      const bodyData = {
        PageNumber: 1,
        PageSize: 64 * page,
        Nome: valueContacts
      };
      const response = await getContacts(bodyData);
      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }
        setContactsList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os contatos, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os contatos, tente novamente.')
      });
    }
    setLoading(false)
  };

  const retrieveContactsInvitationsSent = async (page: number = 1, clear: boolean = false) => {
    setLoading(true)
    try {
      if (clear) {
        setContactsListInvitationsSent([]);
      }
      const bodyData = {
        PageNumber: 1,
        PageSize: 64 * page,
        Status: statusUser !== '0' ? statusUser : '',
        Nome: valueContactsInvitationsSent
      };
      const response = await getInvitationsSent(bodyData);
      if (response.Success) {
        setCurrentPageInvitationsSent(page);
        if (page === 1 && response.TotalPages) {
          setTotalPagesInvitationsSent(response.TotalPages);
        }
        setContactsListInvitationsSent(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os convites, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os convites, tente novamente.')
      });
    }
    setLoading(false)
  };

  const retrieveContactsInvitationsReceived = async (page: number = 1, clear: boolean = false) => {
    setLoading(true)
    try {
      if (clear) {
        setContactsListInvitationsReceived([]);
      }
      const bodyData = {
        PageNumber: 1,
        PageSize: 64 * page,
        Nome: valueContactsInvitationsReceived
      };
      const response = await getInvitationsReceived(bodyData);
      if (response.Success) {
        setCurrentPageInvitationsReceived(page);
        if (page === 1 && response.TotalPages) {
          setTotalPagesInvitationsReceived(response.TotalPages);
        }
        setContactsListInvitationsReceived(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os convites, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os convites, tente novamente.')
      });
    }
    setLoading(false)
  };

  const handleRegistration = async () => {
    setLoadingRegisterUser(true);
    try {
      let countError = 0;

      const validation = await registerValidation(registration);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError(validation as RegistrationError);
        setLoadingRegisterUser(false);
        return;
      }

      const bodyData = {
        Email: registration.email,
        Name: registration.name
      };
      const response = await postContact(bodyData);
      if (response.Success) {
        setOpenRegisterUsersModal(false)
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Convite enviado com sucesso!')
        });

        retrieveContacts(1, true);
        retrieveContactsInvitationsSent(1, true);
        retrieveContactsInvitationsReceived(1, true);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao enviar o convite, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao enviar o convite, tente novamente.')
      });
    }
    setLoadingRegisterUser(false);
  };

  const handleAcceptInvite = async (id: string) => {
    try {
      const response = await postAcceptInvite(id);
      if (response.Success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Convite aceito com sucesso!')
        });

        retrieveContacts(1, true);
        retrieveContactsInvitationsSent(1, true);
        retrieveContactsInvitationsReceived(1, true);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao aceitar o convite, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao aceitar o convite, tente novamente.')
      });
    }
  };

  const handleRefuseInvitation = async (id: string) => {
    try {
      const response = await postRefuseInvitation(id);
      if (response.Success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Convite recusado com sucesso!')
        });

        retrieveContacts(1, true);
        retrieveContactsInvitationsSent(1, true);
        retrieveContactsInvitationsReceived(1, true);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao recusar o convite, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao recusar o convite, tente novamente.')
      });
    }
  };

  const handleDonatePoints = async () => {
    setLoadingDonatePoints(true);
    try {
      const bodyData = {
        Id: String(itemSelected?.Id),
        QtyPoints: points
      };
      const response = await postDonatePoints(bodyData);
      if (response.Success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Pontos enviados com sucesso!')
        });
        setOpenDonatePointsModal(false)
        setOpenConfirmDonationModal(false)
        retrievePoints();
        retrieveContacts(1, true);
        retrieveContactsInvitationsSent(1, true);
        retrieveContactsInvitationsReceived(1, true);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao doar, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao doar, tente novamente.')
      });
    }
    setLoadingDonatePoints(false);
  };

  const validationToRemovePoints = () => {
    if (points >= 10) return setPoints(points - 10);
  };

  const pointsBalanceValidation = () => {
    if (points <= 0) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('A quantidade de pontos deve ser maior que 0!')
      });
      return;
    }
    if (points > pointsBalance) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('A quantidade de pontos inserido é maior que seu saldo atual!')
      });
      return;
    }

    setOpenConfirmDonationModal(true)
  };

  const onEnterSearch = (event: { key: string }, type: number) => {
    if (event.key === 'Enter') {
      if (type === 1) retrieveContacts(1, true);
      if (type === 2) retrieveContactsInvitationsSent(1, true);
      if (type === 3) retrieveContactsInvitationsReceived(1, true);
    }
  };

  return (
    <S.Container >
      <S.ContainerTable>
        {!isSm ?
          <div className='containerButton'>
            <ButtonMaterial
              style={{ borderColor: theme.colors.secundary, width: '100%' }}
              variant="outlined"
              label={t('NOVO CONTATO')}
              onClick={() => {
                setRegistration({ email: '', name: '' })
                setRegistrationError({ email: '', name: '' })
                setOpenRegisterUsersModal(true)
              }}
            />
          </div>
          :
          <S.ButtonScrollToTop onClick={() => {
            setRegistration({ email: '', name: '' })
            setRegistrationError({ email: '', name: '' })
            setOpenRegisterUsersModal(true)
          }}>
            <PersonAdd className="icon" />
          </S.ButtonScrollToTop>
        }

        {loading ? (
          <ViewLoading />
        ) : (
          <>
            {tab === 0 && (
              <>
                <SearchBar
                  value={valueContacts}
                  setValue={(e) => setValueContacts(e)}
                  onEnterSearch={(e) => onEnterSearch(e, 1)}
                  setFilterModal={() => setOpenFilterUsersModal(true)}
                  placeholder={t('Busque o contato desejado')}
                />
                {contactsList.length > 0 ? (
                  <S.ContainerListContacts>
                    {contactsList.map((item) => (
                      <CardContact tab={tab} key={item.Id} item={item} onClick={() => {
                        setItemSelected(item)
                        setOpenDonatePointsModal(true)
                        setPoints(0)
                      }} />
                    ))}
                  </S.ContainerListContacts>
                ) : (
                  <ViewError message={t('Nenhum contato encontrado')} />
                )}
              </>
            )}

            {tab === 1 && (
              <>
                <SearchBar
                  value={valueContactsInvitationsSent}
                  setValue={(e) => setValueContactsInvitationsSent(e)}
                  onEnterSearch={(e) => onEnterSearch(e, 2)}
                  setFilterModal={() => setOpenFilterUsersModal(true)}
                  placeholder={t('Busque o convite desejado')}
                  filterButton
                />
                {contactsListInvitationsSent.length > 0 ? (
                  <S.ContainerListContacts>
                    {contactsListInvitationsSent.map((item) => (
                      <CardContact tab={tab} key={item.Id} item={item} />
                    ))}
                  </S.ContainerListContacts>
                ) : (
                  <ViewError message={t('Nenhum convite enviado')} />
                )}
              </>
            )}

            {tab === 2 && (
              <>
                <SearchBar
                  value={valueContactsInvitationsReceived}
                  setValue={(e) => setValueContactsInvitationsReceived(e)}
                  onEnterSearch={(e) => onEnterSearch(e, 3)}
                  setFilterModal={() => setOpenFilterUsersModal(true)}
                  placeholder={t('Busque o convite desejado')}
                />
                {contactsListInvitationsReceived.length > 0 ? (
                  <S.ContainerListContacts>
                    {contactsListInvitationsReceived.map((item) => (
                      <CardContact tab={tab} key={item.Id} item={item} onClick={() => handleAcceptInvite(String(item?.Id))} onClickRefuse={() => handleRefuseInvitation(String(item?.Id))} />
                    ))}
                  </S.ContainerListContacts>
                ) : (
                  <ViewError message={t('Nenhum convite recebido')} />
                )}
              </>
            )}
          </>
        )}
      </S.ContainerTable>

      <ModalCustom
        open={openConfirmDonationModal}
        onClose={() => setOpenConfirmDonationModal(false)}
        onClick={handleDonatePoints}
        childrenContent={
          <S.ContainerPointDonation>
            <h5>{t('Você realmente deseja doar')} <b>{points} {t('pontos')}</b> {t('para o usuário')} <b>{itemSelected?.Name}</b>?</h5>
            <h3>{t('Seu saldo de pontos restante será de:')}</h3>
            <h1>{pointsBalance - points} {t('pontos')}</h1>
          </S.ContainerPointDonation>
        }
        loading={loadingDonatePoints}
        title={t('Confirmar doação de pontos')}
        confirmationButtonText={t('CONFIRMAR')}
        textDeclineButton={t('CANCELAR')}
        shortModal={true}
      />

      <ModalCustom
        open={openDonatePointsModal}
        onClose={() => setOpenDonatePointsModal(false)}
        onClick={pointsBalanceValidation}
        childrenContent={
          <S.ContainerDonatePoints>
            <h5>{t('Você pode doar pontos do seu saldo atual para o contato selecionado! Insira a quantidade que você quer doar abaixo e confirme a doação clicando em “Doar Pontos”.')}</h5>

            <div className='boxContent'>

              {isSm && (
                <S.ContainerBalance>
                  <div className='containerDescription'>
                    <h1 className='title'>{t('Meu Saldo Atual')}</h1>
                    <h1 className='description'>{t('Total de pontos atual:')}</h1>
                  </div>

                  <div className='containerValue'>
                    <h1 className='value'>{pointsBalance}</h1>
                    <h2 className='textPoints'>{t('Pontos')}</h2>
                  </div>
                </S.ContainerBalance>
              )}

              <S.ContainerContact>
                <S.ImageContact src={itemSelected?.ProfilePictureUrl ? itemSelected.ProfilePictureUrl : noImage} />

                <div className='boxContentText'>
                  <h1 className='data'>{t('Usuário Selecionado')}:</h1>
                  <h1 className='title'>{itemSelected?.Name}</h1>
                  <h1 className='subTitle'>{itemSelected?.Email}</h1>
                </div>
              </S.ContainerContact>

              <S.ContainerPointSelection>
                <h1 className='titlePoint'>{t('Quantidade de pontos')}:</h1>

                <div className='containerPoint'>
                  <button className='boxButtonLeft' onClick={validationToRemovePoints}>
                    <h1 className='textButton'>-</h1>
                  </button>

                  <input
                    className='inputPoint'
                    type="number"
                    value={points}
                    onChange={(e) => setPoints(Number(e.target.value))}
                  />

                  <button className='boxButtonRight' onClick={() => setPoints(points + 10)}>
                    <h1 className='textButton'>+</h1>
                  </button>
                </div>
              </S.ContainerPointSelection>

              {!isSm && (
                <S.ContainerBalance>
                  <div className='containerDescription'>
                    <h1 className='title'>{t('Meu Saldo Atual')}</h1>
                    <h1 className='description'>{t('Total de pontos atual')}:</h1>
                  </div>

                  <div className='containerValue'>
                    <h1 className='value'>{pointsBalance}</h1>
                    <h2 className='textPoints'>{t('Pontos')}</h2>
                  </div>
                </S.ContainerBalance>
              )}

            </div>
          </S.ContainerDonatePoints >
        }
        title={t('Doar Pontos')}
        confirmationButtonText={t('DOAR PONTOS')}
        textDeclineButton={t('CANCELAR')}
        icon={< VolunteerActivism style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.white }} />}
      />

      <ModalCustom
        open={openFilterUsersModal}
        onClose={() => setOpenFilterUsersModal(false)}
        onClick={() => {
          retrieveContactsInvitationsSent(1, true);
          setOpenFilterUsersModal(false)
        }}
        childrenContent={
          <S.Item>
            <S.Inputs>
              <div className="input">
                <FormControl sx={{ display: 'flex', m: 1, width: '100%' }}>
                  <InputLabel id="demo-simple-select-helper-label">{t('Status')}</InputLabel>
                  <Select
                    MenuProps={{
                      MenuListProps: {
                        sx: { maxHeight: '200px', maxWidth: '100%', overflowY: 'auto' }
                      }
                    }}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    variant="standard"
                    style={{ display: 'flex', width: '100%' }}
                    value={statusUser}
                    onChange={(e) => {
                      setStatusUser(String(e.target.value))
                    }}
                  >
                    <MenuItem value={'0'}>{t('Todos')}</MenuItem>
                    <MenuItem value={'1'}>{t('Pendente')}</MenuItem>
                    <MenuItem value={'2'}>{t('Recusado')}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </S.Inputs>
          </S.Item>
        }
        title={t('Filtros')}
        confirmationButtonText={t('Pesquisar')}
        textDeclineButton={t('CANCELAR')}
      />

      <ModalCustom
        open={openRegisterUsersModal}
        onClose={() => setOpenRegisterUsersModal(false)}
        onClick={handleRegistration}
        childrenContent={
          <>
            <S.TitleAddContact>{t('Insira um e-mail de um usuário que você deseja adicionar como seu novo contato. Caso o e-mail ainda não foi cadastrado no Curar Saúde, será enviado um convite para se cadastrar e participar da nossa plataforma!')}</S.TitleAddContact>

            <Input
              label={t('Nome')}
              type="email"
              value={registration.name}
              onChange={(e) => setRegistration({ ...registration, name: e.target.value })}
              size="small"
              fullWidth
              error={registrationError.name.length > 0}
              helperText={registrationError.name}
              onClick={() => setRegistrationError({ ...registrationError, name: '' })}
              variant="filled"
              style={{ marginTop: '32px' }}
            />

            <Input
              label={t('E-mail')}
              type="email"
              value={registration.email}
              onChange={(e) => setRegistration({ ...registration, email: e.target.value })}
              size="small"
              fullWidth
              error={registrationError.email.length > 0}
              helperText={registrationError.email}
              onClick={() => setRegistrationError({ ...registrationError, email: '' })}
              variant="filled"
              style={{ marginTop: '16px', marginBottom: '32px' }}
            />
          </>
        }
        loading={loadingRegisterUser}
        title={t('Novo Contato')}
        confirmationButtonText={t('ENVIAR CONVITE')}
        textDeclineButton={t('CANCELAR')}
      />
    </S.Container >
  )
}

export default MyContacts
