import * as yup from 'yup';

import { verifyClassEmpty } from '../../utils/ClassEmpty';
import { genericValidation } from '../../utils/ValidationGenerics';

import { Registration, RegistrationError } from './types';


export const registerValidation = async (object: Registration): Promise<true | Registration> => {
  const schemaRegister = yup.object().shape({
    nome: yup.string().required(),
    pontos: yup.string().required(),
  });

  const mountError = await genericValidation<Registration, RegistrationError>(schemaRegister, object, new Registration());

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};
