export interface ICardSpecialProgram {
  status?: "approved" | "rejected" | "under_review" | "edit";
  Id: string
  Title: string
  CoverImageUrl: string
  ChallengeStatus?: string
  DateTime?: string
  Reason?: string
}

export class RegistrationSpecialProgram {
  title = '';
  description = '';
  averagePerformance = '';
  language = '';
  tags = '';
  email = '';
  attempts = '';
}

export class RegistrationSpecialProgramError {
  title = '';
  description = '';
  averagePerformance = '';
  language = '';
  tags = '';
  email = '';
  attempts = '';
}