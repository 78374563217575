import React from 'react';
import { Box, Stack } from '@mui/material';

import { Content, Title } from '../../../../../components/ChallengeInfoPage/styles';
import ModalButton from '../../../../../components/ModalButton';
import { useInvalidateChallengesInCurations } from '../../../../../services/ChallengesManagement/InCurations';
import { IChalengeInCurationsQueryResponse } from '../../../../../services/ChallengesManagement/InCurations/types';
import { useChalengeManagementChangeCuratorMutation } from '../../../../../services/ChallengesManagement/ChangeCurator';
import { useChalengeDetails, useInvalidateChalengeDetails } from '../../../../../services/ChallengesManagement/Details';
import { useChalengeQuiz } from '../../../../../services/ChallengesDefinition/Quizzes';

import Timeline from '../../Timeline';
import TriageItemModal from '../../TriageItemModal';

import RejectChallengeModal from './RejectChallengeModal';

interface IProps {
  item: IChalengeInCurationsQueryResponse,
  onClose: () => void
}

const ChallengeCurationDetail: React.FC<IProps> = ({ item, onClose: onCloseModal }) => {
  const invalidateChallengesInCurations = useInvalidateChallengesInCurations()
  const invalidateChalengeDetails = useInvalidateChalengeDetails()

  const chalengeCurationRejectionMutation = useChalengeManagementChangeCuratorMutation()

  const chalengeDetailsQuery = useChalengeDetails({ Id: item.Id });
  const chalengeQuizQuery = useChalengeQuiz({ Id: item.Id });

  if (chalengeDetailsQuery.isLoading || chalengeQuizQuery.isLoading) return null;
  if (chalengeDetailsQuery.isError || chalengeQuizQuery.isError) return null;

  const challengeDetail = chalengeDetailsQuery.data.Data;
  const challengeQuizes = chalengeQuizQuery.data.Data;

  return (
    <Box
      display="flex"
      width="70vw"
      maxWidth="900px"
      gap={2}
      sx={({ breakpoints }) => ({
        [breakpoints.down("md")]: {
          flexDirection: "column"
        }
      })}
    >
      <Box flex={0.6}>
        <TriageItemModal
          challengeDetail={challengeDetail}
          challengeQuizes={challengeQuizes}
          hasQuiz={challengeQuizes.length > 0}
        />
      </Box>

      <Stack flex={0.4} width="100%" spacing={1}>
        <Box>
          <Title>Curador Responsável</Title>
          <Content>{item.CuratorName || "N/A"}</Content>
        </Box>
        <Timeline timeLine={challengeDetail.TimeLine} sx={{ flex: 1 }} />
        <Stack direction="row" spacing={2} justifyContent="end">
          <ModalButton variant='outlined' size='small' color='primary' buttonChildren="ENCAMINHAR PARA OUTRO CURADOR">
            {({ open, onClose }) => (
              <RejectChallengeModal
                open={open}
                onClose={onClose}
                onSubmit={({ reason }) =>
                  chalengeCurationRejectionMutation.mutateAsync(
                    { id: item.Id, reason },
                    {
                      onSuccess: () => onCloseModal(),
                      onSettled: () => {
                        invalidateChallengesInCurations();
                        invalidateChalengeDetails();
                      }
                    }
                  )}
                loading={false}
              />
            )}
          </ModalButton>
        </Stack>
      </Stack>

    </Box>
  )
}

export default ChallengeCurationDetail;
