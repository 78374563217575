import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { sendRequest } from "../../../services/RequestService";
import { IChalengeInScreeningQueryResponse } from "./types";
import { IBodyGET } from "../../../services/Challenges/types";

const defaultRoot = "challenges-management/in-screenings"

export const useInvalidateChalengesInScreening = () => {
  const queryClient = useQueryClient()

  return useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ["useChalengesInScreening"],
      exact: false
    });
  }, [queryClient])
}

export const getChallengesTriage = async (body: IBodyGET) => {
  let url = `${defaultRoot}`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Title=${body.Nome}`;
  return await sendRequest<IChalengeInScreeningQueryResponse[], IBodyGET>(url, 'GET')
}
