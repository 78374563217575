import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonMaterial from '../../../components/Button';
import * as S from './styles'
import { IProductUser } from '../../../services/Product/types';
import { AccessTimeFilled, Cached, Handshake, Inventory2, LocalPlay, OpenInNew, Savings, Sell, ShoppingCart, SyncProblem } from '@mui/icons-material';
import { theme } from '../../../styles/theme';
import { useNavigate } from 'react-router-dom';
import { differenceInDays, parseISO, format } from 'date-fns';
import { DataMaskFormtData } from '../../../utils/Mask';

interface IProps {
  item: IProductUser
  onClick?: () => void
  type?: number
  pointsBalance?: number
}

const CardProduct: React.FC<IProps> = ({ item, onClick, type, pointsBalance = 0 }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const renderValidationColor = () => {
    const hoje = new Date();
    const data2 = format(hoje, 'yyyy-MM-dd');
    const date1 = parseISO(item.RedemptionLimitDate ?? '');
    const date2 = parseISO(data2);
    const difference = differenceInDays(date1, date2);

    if (difference <= 2) return theme.colors.graphicRed;
    if (difference <= 7) return theme.colors.graphicYellow2;
    return theme.colorsBase.green2;
  };


  return (
    <S.Container>
      <S.ContainerImage>
        <S.Image style={{ aspectRatio: '4/3.2' }} src={item.ImageUrl} />

        <div className='boxInfoTop'>
          {type === 1 && <S.ContainerQuantityInStock>
            <Inventory2 className="iconStock" />
            <h1 className='textStock'>{t('Quantidade em Estoque')}:</h1>
            <h1 className='textStock' style={{ color: theme.colors.seventh }}>{item.Count} {t('un')}.</h1>
          </S.ContainerQuantityInStock>}

          {type === 3 && <S.ContainerQuantityInStock style={{ borderColor: renderValidationColor() }}>
            <AccessTimeFilled className="iconStock" style={{ color: renderValidationColor() }} />
            <h1 className='textStock' style={{ color: renderValidationColor() }}>{t('Prazo para retirar')}:</h1>
            <h1 className='textStock' style={{ color: renderValidationColor() }}>{DataMaskFormtData(item.RedemptionLimitDate)}</h1>
          </S.ContainerQuantityInStock>}
        </div>

        {(item.SponsoredBy && type !== 4) && <div className='boxInfoBottom'>
          <S.ContainerSponsor>
            <Handshake className="iconSponsor" />
            <h1 className='textSponsor'>{t('Patrocinado por')}: {item.SponsoredBy}</h1>
          </S.ContainerSponsor>
        </div>}
      </S.ContainerImage>

      <S.ContainerContent>
        <h1 className='nameProduct'>{item.Name}</h1>

        {type === 1 && <div className='boxInfo'>
          <Sell className="iconInfo" />
          <h1 className='textInfo'>{t('Custo')}:</h1>
          <h1 className='textInfoBig' style={{ color: pointsBalance >= item.Points ? theme.colorsBase.green2 : theme.colors.graphicRed }}>{item.Points} {t('pontos')}</h1>
        </div>}

        {type === 2 && <>
          <div className='boxInfo'>
            <Savings className="iconInfo" />
            <h1 className='textInfo'>{t('pontos gastos')}:</h1>
            <h1 className='textInfoBig' style={{ color: theme.colors.seventh }}>{String(item.Points).substring(1, String(item.Points).length)} {t('pontos')}</h1>
          </div>

          <div className='boxInfo'>
            <ShoppingCart className="iconInfo" />
            <h1 className='textInfo'>{t('Quantidade de Unidades')}:</h1>
            <h1 className='textInfoBig' style={{ color: theme.colors.seventh }}>{item.Count}</h1>
          </div>
        </>}

        {type === 1 && <>
          {pointsBalance >= item.Points ? (
            <ButtonMaterial
              style={{ backgroundColor: theme.colors.seventh, width: '100%', marginTop: '8px' }}
              variant="contained"
              label={t('trocar pontos')}
              onClick={onClick}
              icon={<Cached style={{ fontSize: '16px', marginRight: '8px' }} />}
            />
          ) : (
            <ButtonMaterial
              style={{ backgroundColor: '#BCBCBC', width: '100%', marginTop: '8px', fontSize: '8px', color: theme.colorsBase.white }}
              variant="contained"
              label={t('Ops! Você ainda não possui pontuação suficiente para esta troca. Engaje mais e volte aqui')}
              disable
              icon={<SyncProblem style={{ fontSize: '16px', marginRight: '8px', color: theme.colorsBase.white }} />}
            />
          )}
        </>}

        {(type === 3 || type === 4) && <div className='boxInfo'>
          <Sell className="iconInfo" />
          <h1 className='textInfo'>{t('Desafio')}:</h1>
          <button onClick={() => navigate(`/desafio/${item?.ChallengeId}`)} style={{ display: 'flex', background: 'none', border: 'none', alignItems: 'center' }}>
            <h1 className='textInfo' style={{ color: theme.colors.seventh }}>{item.ChallengeTitle} (ID:{item.ChallengeId})</h1>
            <OpenInNew style={{ fontSize: '16px', color: theme.colors.seventh, marginLeft: '2px' }} />
          </button>
        </div>}

        {(type === 3 && item.ExpirationDate) && <div className='boxInfo' style={{ marginTop: '2px' }}>
          <AccessTimeFilled className="iconInfo" />
          <h1 className='textInfo'>{t('Data de expiração')}:</h1>
          <h1 className='textInfo' style={{ color: theme.colorsBase.gray12 }}>{DataMaskFormtData(item.ExpirationDate ?? '' )}</h1>
        </div>}

        {type === 3 && <ButtonMaterial
          style={{ backgroundColor: theme.colors.seventh, width: '100%', marginTop: '8px' }}
          variant="contained"
          label={t('Resgatar Prêmio')}
          onClick={onClick}
          icon={<LocalPlay style={{ fontSize: '16px', marginRight: '8px' }} />}
        />}

        {(type === 4 && item.SponsoredBy) && <S.ContainerSponsor style={{ marginTop: '8px' }}>
          <Handshake className="iconSponsor" />
          <h1 className='textSponsor'>{t('Patrocinado por')}: {item.SponsoredBy}</h1>
        </S.ContainerSponsor>}
      </S.ContainerContent>
    </S.Container>
  )
}

export default CardProduct;
