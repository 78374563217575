import React, { useEffect, useState } from 'react';
import ToastNotification from '../../../components/ToastNotification';
import ViewLoading from '../../../components/ViewLoading';
import ViewError from '../../../components/ViewError';
import { Pagination } from '@mui/material'
import { useIsSm } from '../../../hooks/useIsSm';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../components/SearchBar';
import { Container, Pages } from '../../../pages/TermsOfUse/ScreenTermsOfUse/styles';
import { getAutoCompleteProducts, getAwardsWon, getPrizesFinalized, postRedeemPrize, } from '../../../services/Product';
import { IProductUser } from '../../../services/Product/types';
import { ContainerTable } from '../../../pages/UserManagement/Users/styles';
import ModalCustomBottom from '../../../components/ModalCustomBottom';
import ModalCustom from '../../../components/ModalCustom';
import { Cached } from '@mui/icons-material';
import { theme } from '../../../styles/theme';
import CardProduct from '../../../pages/Product/CardProduct';
import ViewExchangePointsForProducts from '../../../pages/Product/ViewExchangePointsForProducts';
import { ContainerCards, TextTopPage } from '../../../pages/Product/ProductViewAvailable/styles';

interface IProps {
  tab: number
}

const SpecialAwardsView: React.FC<IProps> = ({ tab = 1 }) => {
  const { t } = useTranslation();
  const [list, setList] = useState<IProductUser[]>([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState<number | undefined>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [valueSearch, setValueSearch] = useState('');
  const isSm = useIsSm()
  const [openExchangePointsForProductsModal, setOpenExchangePointsForProductsModal] = useState(false)
  const [loadingAwards, setLoadingAwards] = useState(false)
  const [searchSelected, setSearchSelected] = useState<IProductUser>()
  const [openCancelModalAwards, setOpenCancelModalAwards] = useState(false)
  const [openSavedModalAwards, setOpenSavedModalAwards] = useState(false)
  const [autoCompleteListAwards, setAutoCompleteListAwards] = useState<string[]>([])
  const [emptySpecialAwards, setEmptySpecialAwards] = useState(false)

  useEffect(() => {
    retrieveSpecialAwards(1, true);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > (300 * currentPage)) {
        if ((currentPage <= (totalPages || 0)) && isSm) {
          retrieveSpecialAwards(currentPage + 1, false)
        }
      }
    });
  }, []);

  const retrieveSpecialAwards = async (page: number = 1, clear: boolean = false, search?: string) => {
    setLoading(true)
    try {
      if (clear) {
        setList([]);
      }
      const bodyData = {
        PageNumber: page,
        PageSize: isSm ? 32 : 4,
        Nome: search ?? valueSearch
      };

      let response;

      if (tab === 1) {
        response = await getAwardsWon(bodyData);
      } else {
        response = await getPrizesFinalized(bodyData);
      }

      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }
        if (clear) {
          setList(response.Data);
        } else if (response.Data.length > 1) {
          const novaArray = list.concat(response.Data);
          setList(novaArray);
        }

        validationEmptySpecialAwards(page, search)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os prêmios, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os prêmios, tente novamente.')
      });
    }
    setLoading(false)
  };

  const validationEmptySpecialAwards = (page: number, search?: string) => {
    if (page === 1 && !search && valueSearch === '') {
      setEmptySpecialAwards(true)
    } else {
      setEmptySpecialAwards(false)
    }
  };

  const onEnterSearch = (event: { key: string }) => {
    if (event.key === 'Enter') {
      retrieveSpecialAwards(1, true);
      setAutoCompleteListAwards([])
    }
  };

  useEffect(() => {
    if (valueSearch.length > 2) {
      getAutoCompleteSpecialAwards()
    } else {
      setAutoCompleteListAwards([])
    }
  }, [valueSearch]);

  const handlePrizeRedemption = async () => {
    setLoadingAwards(true);
    try {
      const response = await postRedeemPrize(Number(searchSelected?.Id));
      if (response.Success) {
        retrieveSpecialAwards(1, true);
        clearDataPrizeRedemption()
        setOpenSavedModalAwards(true)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao resgatar o prêmio, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao resgatar o prêmio, tente novamente.')
      });
    }
    setLoadingAwards(false);
  };

  const getAutoCompleteSpecialAwards = async () => {
    try {
      setAutoCompleteListAwards([])
      const response = await getAutoCompleteProducts(valueSearch);
      if (response.Success) {
        if (response.Data[0] !== valueSearch) {
          setAutoCompleteListAwards(response.Data)
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
  };

  const clearDataPrizeRedemption = () => {
    setOpenCancelModalAwards(false)
    setOpenExchangePointsForProductsModal(false)
  };

  const renderViewErrorSearch = () => {
    return (
      <ViewError message={tab === 1 ? t('Nenhum produto disponível') : t('Nenhum produto trocado')} />
    )
  };

  const renderViewError = () => {
    return (
      emptySpecialAwards ? <div style={{ width: isSm ? "90%" : "60%" }}><ViewError message={t('Parece que no momento você não tem prêmios disponíveis para resgate. Fique de olho! Novos desafios estão a caminho e trazem consigo a chance de ganhar prêmios especiais incríveis! Dica: Fique atento as notificações para ser o primeiro a saber sobre novas oportunidades e não perder nenhuma chance de ser premiado!')} /> </div> : renderViewErrorSearch()
    )
  };

  const renderViewRedeemPrize = () => {
    return (
      <ViewExchangePointsForProducts
        item={searchSelected}
        onClick={handlePrizeRedemption}
        onClickCancel={() => setOpenCancelModalAwards(true)}
        setContentQuantidade={() => { }}
        setCheckOut={() => { }}
        setValue={() => { }}
        value={1}
        award={true}
        checkOut={true}
      />
    )
  };

  return (
    <Container>
      <ContainerTable>
        {!isSm && <TextTopPage>{tab === 1 ? t('Aqui você encontra os prêmios que você conquistou.') : t('Aqui você encontra os prêmios que você conquistou e já retirou ou não.')}</TextTopPage>}
        <div className='containerButton'>
          <SearchBar
            value={valueSearch}
            setValue={(e) => setValueSearch(e)}
            onEnterSearch={onEnterSearch}
            placeholder={t('Busque o desafio desejado')}
            filterButton={false}
            style={{ padding: 0, width: '100%' }}
            buttonSearch
            onClearSearch={() => {
              setValueSearch('')
              retrieveSpecialAwards(1, true, '');
            }}
            autoCompleteList={autoCompleteListAwards}
          />
        </div>

        {loading ? (
          <ViewLoading />
        ) : (
          <>
            {list.length > 0 ? (
              <>
                <ContainerCards>
                  {list.map((item) => (
                    <CardProduct
                      key={item.Id}
                      item={item}
                      type={tab === 1 ? 3 : 4}
                      onClick={() => {
                        setSearchSelected(item)
                        setOpenExchangePointsForProductsModal(true)
                      }}
                    />
                  ))}
                </ContainerCards>

                {!isSm && <Pages>
                  <Pagination
                    showFirstButton
                    showLastButton
                    count={totalPages}
                    page={currentPage}
                    color="primary"
                    onChange={(e, p) => {
                      e.preventDefault()
                      e.stopPropagation()
                      retrieveSpecialAwards(p, true)
                    }}
                  />
                </Pages>}
              </>
            ) : renderViewError()}
          </>
        )}
      </ContainerTable>

      {isSm ?
        <ModalCustomBottom
          open={openExchangePointsForProductsModal}
          onClose={() => setOpenCancelModalAwards(true)}
          onClick={handlePrizeRedemption}
          loading={loadingAwards}
          title={t('Resgatar Prêmio')}
          showIcon
          buttonClose
          buttonsEnabled={false}
          icon={<Cached style={{ fontSize: '24px', color: theme.colorsBase.gray3, backgroundColor: theme.colorsBase.white }} />}
          childrenContent={renderViewRedeemPrize()}
        />
        :
        <ModalCustom
          open={openExchangePointsForProductsModal}
          onClose={() => setOpenCancelModalAwards(true)}
          onClick={handlePrizeRedemption}
          loading={loadingAwards}
          title={t('Resgatar Prêmio')}
          buttonsEnabled={false}
          largeTitle
          iconHeader={<Cached style={{ fontSize: '24px', color: theme.colorsBase.gray3, backgroundColor: theme.colorsBase.white }} />}
          styleLine={{ backgroundColor: 'transparent', marginBottom: '24px' }}
          childrenContent={renderViewRedeemPrize()}
        />
      }

      {isSm ?
        <ModalCustomBottom
          open={openCancelModalAwards}
          onClose={() => setOpenCancelModalAwards(false)}
          onClick={() => clearDataPrizeRedemption()}
          loading={loadingAwards}
          title={t('Cancelar Resgate')}
          confirmationButtonText={t('CANCELAR')}
          textDeclineButton={t('VOLTAR')}
          showIcon={false}
          buttonClose
          buttonsEnabled
          styleButtons={{ justifyContent: 'center' }}
          redButtonRight
          description={t('Deseja cancelar o resgate do prêmio? Nenhuma ação será salva.')}
        />
        :
        <ModalCustom
          open={openCancelModalAwards}
          onClose={() => setOpenCancelModalAwards(false)}
          onClick={() => clearDataPrizeRedemption()}
          loading={loadingAwards}
          title={t('Cancelar Resgate')}
          styleButtons={{ justifyContent: 'center' }}
          confirmationButtonText={t('CANCELAR')}
          textDeclineButton={t('VOLTAR')}
          redButtonRight
          shortModal
          description={t('Deseja cancelar o resgate do prêmio? Nenhuma ação será salva.')}
        />
      }

      {isSm ?
        <ModalCustomBottom
          open={openSavedModalAwards}
          onClose={() => setOpenSavedModalAwards(false)}
          onClick={() => setOpenSavedModalAwards(false)}
          loading={loadingAwards}
          title={t('Resgate de prêmio confirmado')}
          textDeclineButton={t('VOLTAR')}
          showIcon={false}
          buttonClose
          buttonsEnabled
          buttonRight={false}
          styleButtons={{ justifyContent: 'center' }}
          description={t('Um membro da nossa equipe entrará em contato com você pelo e-mail para coordenar a entrega do seu prêmio e discutir os próximos passos. Queremos garantir que você receba sua recompensa o mais rápido e possível.')}
        />
        :
        <ModalCustom
          open={openSavedModalAwards}
          onClose={() => setOpenSavedModalAwards(false)}
          onClick={() => setOpenSavedModalAwards(false)}
          loading={loadingAwards}
          title={t('Resgate de prêmio confirmado')}
          styleButtons={{ justifyContent: 'center' }}
          textDeclineButton={t('VOLTAR')}
          buttonRight={false}
          shortModal
          description={t('Um membro da nossa equipe entrará em contato com você pelo e-mail para coordenar a entrega do seu prêmio e discutir os próximos passos. Queremos garantir que você receba sua recompensa o mais rápido e possível.')}
        />
      }
    </Container >
  )
}

export default SpecialAwardsView
