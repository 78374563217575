import React, { useState } from 'react';
import PageContainer from '../../components/Layout/PageContainer';
import PaperContainer from '../../components/Layout/PaperContainer';
import { AntTab, AntTabs } from '../../components/Tabs';
import { theme } from '../../styles/theme';
import HeaderAchievements from '../../components/HeaderAchievements';
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../hooks/useIsSm';
import { Assignment } from '@mui/icons-material';
import ViewChallenge from '.';
import { useParams } from 'react-router-dom';

const ViewChallengePage: React.FC = () => {
  const { t } = useTranslation();
  const isSm = useIsSm()
  const [tab, setTab] = useState(0)
  const { token } = useParams();

  return (
    <PageContainer>
      <HeaderAchievements
        title={t('Meus Desafios')}
        description={t('Aqui você encontra informações sobre os desafios que você criou.') ?? ''}
        icon={<Assignment style={{ color: isSm ? theme.colors.secundary : theme.colorsBase.gray3 }} />}
        buttonBack={!isSm}
        marginBottom={isSm ? 24 : 0}
      />

      <PaperContainer>
        <AntTabs value={tab} onChange={(_, newValue) => setTab(newValue)} variant={isSm ? 'scrollable' : 'standard'}>
          <AntTab label="ATIVOS" sx={{ width: isSm ? '33.33vw' : 'none' }} />
          <AntTab label="REJEITADOS" sx={{ width: isSm ? '33.33vw' : 'none' }} />
          <AntTab label="EM REVISÃO" sx={{ width: isSm ? '33.33vw' : 'none' }} />
        </AntTabs>

       {tab === 0 && <ViewChallenge headerActive={0} token={token} />}
       {tab === 1 && <ViewChallenge headerActive={1} token={token} />}
       {tab === 2 && <ViewChallenge headerActive={2} token={token} />}
      </PaperContainer>
    </PageContainer>
  )
}

export default ViewChallengePage
