import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  background-color: ${(props) => props.theme.colorsBase.white};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  margin-right: 14px;
  position: fixed;
  z-index: 99;

  button {
    z-index: 3;
    border: 0;
    background: none;

    img {
      width: 120px;
      object-fit: cover;
      margin-left: 8px;
    }
  }

  @media (max-width: 680px) {
    flex-direction: row;
    padding: 16px 16px 16px 24px;
    margin-right: 0px;

    button {
      img {
        width: 120px;
      }
    }
  }
`

export const ButtonHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .iconNotification {
    color: ${(props) => props.theme.colorsBase.black2};
    width: 24px;
    height: 24px;
    margin-left: 24px;
  }

  @media (max-width: 680px) {
    width: 100%;
    justify-content: space-between;
  }
`

export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: red;

  label {
    white-space: nowrap;
    margin-left: 8px;
    color: ${(props) => props.theme.colors.primary};
  }

  .icon {
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`

export const ContainerSearch = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
`

export const ButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none; 
  padding: 8px;
  background-color: ${(props) => props.theme.colors.primary};
  margin-left: 8px;

  .icon {
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`