export type RuleNames = 'length' | 'specialChar' | 'number' | 'capital' | 'lowerCase' | 'match' | 'equalNumber'

export class RegistrationIdentification {
  name = '';
  language = '';
  difficultyLevel = '';
  category = '';
  tags: string[] = [];
  mediaType = '';
  duration = '';
  source = '';
  sourceNotes = '';
}

export class RegistrationIdentificationError {
  name = '';
  language = '';
  difficultyLevel = '';
  category = '';
  tags: string[] = [];
  mediaType = '';
  duration = '';
  source = '';
  sourceNotes = '';
}
