import { ChangeEvent, DragEvent } from 'react';
import { IProductCreationAndEditingProps } from '../TableProduct/types'
import * as S from './styles'
import Input from '../../../components/Input';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField, } from '@mui/material'
import { uploadFiles } from '../../../services/Users';
import ToastNotification from '../../../components/ToastNotification';
import { CalendarMonth, Tag, UploadFile, Warning } from '@mui/icons-material';
import { Image } from '../TableProduct/styles';
import { theme } from '../../../styles/theme';
import { useIsSm } from '../../../hooks/useIsSm';
import { dateMask } from '../../../utils/Mask';

export default function ProductCreationAndEditing(props: Readonly<IProductCreationAndEditingProps>) {
  const { registration, registrationError, setRegistration, setRegistrationError, urlImage, setUrlImage, id, edit, returnWarning, type = 1 } = props
  const { t } = useTranslation();
  const isSm = useIsSm()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedImage = e.target.files?.[0];
    if (selectedImage) {
      postImage(selectedImage)
    }
  };

  const postImage = async (selectedImage: File) => {
    try {
      if (selectedImage.type.startsWith('image/')) {
        const data = new FormData();
        data.append('file', selectedImage);
        const responseImage = await uploadFiles(data, 2);
        if (responseImage.Success) {
          setUrlImage(responseImage.Data.FileUrl)
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Selecione uma imagem!')
        });
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao salvar a foto, tente novamente.')
      });
    }
  };

  const onDragOver = (e: DragEvent<HTMLLabelElement>) => {
    const event = e as DragEvent;
    event.stopPropagation();
    event.preventDefault();
  }

  return (
    <S.ContainerRegisterPage2 smallerSize={isSm && edit}>
      <div className='containerTotal'>
        <div className='containerImageProductRegister'>
          <div className='boxImageProductRegister'>
            {(isSm ? !edit : true) && <h5>{t('Imagem do produto')}</h5>}

            <S.BackgroundImage htmlFor="selecao-arquivo" smallerSize={isSm && edit} onDragOver={(e) => onDragOver(e)} onDrop={(e) => {
              onDragOver(e)
              postImage(e.dataTransfer.files?.[0])
            }}>
              {urlImage ? (
                <Image style={{ aspectRatio: '4/3.2', width: '100%' }} src={urlImage} />
              ) : (
                <div className='noImage' style={{ aspectRatio: '4/3.2' }}>
                  <UploadFile className="iconRegister" />
                  <h1 className='textRegisterImage'>{t('Clique para enviar ou arraste um arquivo de imagem do produto')}</h1>
                </div>
              )}

              <S.Input id="selecao-arquivo" onChange={handleChange} type="file" accept="image/*" />
            </S.BackgroundImage>

            {edit &&
              <div className='boxButtonEditImageMobile'>
                {(isSm && edit) && <h5>{t('Imagem do produto')}:</h5>}

                <S.ButtonImageEdit htmlFor="selecao-arquivo">
                  <h6 className='textButtonEdit'>{t('NOVA IMAGEM')}</h6>
                  <S.Input id="selecao-arquivo" onChange={handleChange} type="file" accept="image/*" />
                </S.ButtonImageEdit>
              </div>
            }
          </div>

          {(edit && isSm) && <div className='boxTextInfoTopEdit' style={{ marginTop: '8px' }}>
            <Tag className="boxIconInfoEdit" />
            <h3>{t('ID')}:</h3>
            <h3 style={{ color: theme.colors.fourth }}>{id}</h3>
          </div>}

          <Input
            label={t('Nome do Produto')}
            type="name"
            value={registration.name}
            onChange={(e) => setRegistration({ ...registration, name: e.target.value })}
            size="small"
            fullWidth
            error={registrationError.name?.length > 0}
            helperText={registrationError.name}
            onClick={() => setRegistrationError({ ...registrationError, name: '' })}
            variant="filled"
            style={{ margin: '8px 0px' }}
          />

          <TextField
            id="outlined-multiline-static"
            label={t('Detalhes do Produto')}
            multiline
            rows={2}
            defaultValue="Default Value"
            variant="outlined"
            value={registration.details}
            error={registrationError.details.length > 0}
            helperText={registrationError.details}
            onClick={() => setRegistrationError({ ...registrationError, details: '' })}
            style={{ marginTop: '12px', width: '100%' }}
            onChange={(e) => setRegistration({ ...registration, details: e.target.value?.length <= 400 ? e.target.value : registration.details })}
          />
          <S.TextCountDetails>
            <h5>{registration.details?.length}/400</h5>
          </S.TextCountDetails>
        </div>


        <div className='boxContentProductRegister'>
          {(edit && !isSm) && <div className='boxTextInfoTopEdit'>
            <Tag className="boxIconInfoEdit" />
            <h3>{t('ID')}:</h3>
            <h3 style={{ color: theme.colors.fourth }}>{id}</h3>
          </div>}


          <Input
            style={{ margin: '8px 0px' }}
            label={t('Data de expiração')}
            value={registration.expirationDate}
            onChange={(e) => setRegistration({ ...registration, expirationDate: dateMask(e.target.value) })}
            size="small"
            fullWidth
            error={registrationError.expirationDate.length > 0}
            helperText={t(registrationError.expirationDate)}
            onClick={() => setRegistrationError({ ...registrationError, expirationDate: '' })}
            variant="filled"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                >
                  <CalendarMonth fill={theme.colors.nine} />
                </InputAdornment>
              ),
            }}
          />

          <Input
            style={{ margin: '8px 0px' }}
            label={t('Período de Retirada (em dias)')}
            value={registration.withdrawalDeadline}
            onChange={(e) => setRegistration({ ...registration, withdrawalDeadline: e.target.value })}
            size="small"
            fullWidth
            error={registrationError.withdrawalDeadline.length > 0}
            helperText={t(registrationError.withdrawalDeadline)}
            onClick={() => setRegistrationError({ ...registrationError, withdrawalDeadline: '' })}
            variant="filled"
            type="number"
          />

          {type === 1 && <Input
            label={t('Pontuação para troca')}
            type="number"
            value={registration.exchangeScore}
            onChange={(e) => setRegistration({ ...registration, exchangeScore: e.target.value })}
            size="small"
            fullWidth
            error={registrationError.exchangeScore?.length > 0}
            helperText={registrationError.exchangeScore}
            onClick={() => setRegistrationError({ ...registrationError, exchangeScore: '' })}
            variant="filled"
            style={{ margin: '8px 0px' }}
          />}

          <Input
            label={t('Quantidade Disponível')}
            type="number"
            value={registration.availableQuantity}
            onChange={(e) => setRegistration({ ...registration, availableQuantity: e.target.value })}
            size="small"
            fullWidth
            error={registrationError.availableQuantity?.length > 0}
            helperText={registrationError.availableQuantity}
            onClick={() => setRegistrationError({ ...registrationError, availableQuantity: '' })}
            variant="filled"
            style={{ margin: '8px 0px' }}
          />

          {type === 2 && <>
            <Input
              label={t('Pontuação Mínima (opcional)')}
              type="number"
              value={registration.minimumScore}
              onChange={(e) => setRegistration({ ...registration, minimumScore: e.target.value })}
              size="small"
              fullWidth
              error={registrationError.minimumScore?.length > 0}
              helperText={registrationError.minimumScore}
              onClick={() => setRegistrationError({ ...registrationError, minimumScore: '' })}
              variant="filled"
              style={{ margin: '8px 0px' }}
            />

            <Input
              label={t('Pontuação Máxima (opcional)')}
              type="number"
              value={registration.maximumScore}
              onChange={(e) => setRegistration({ ...registration, maximumScore: e.target.value })}
              size="small"
              fullWidth
              error={registrationError.maximumScore?.length > 0}
              helperText={registrationError.maximumScore}
              onClick={() => setRegistrationError({ ...registrationError, maximumScore: '' })}
              variant="filled"
              style={{ margin: '8px 0px' }}
            />

            <Input
              label={t('Pontuação Alternativa')}
              type="number"
              value={registration.alternativePunctuation}
              onChange={(e) => setRegistration({ ...registration, alternativePunctuation: e.target.value })}
              size="small"
              fullWidth
              error={registrationError.alternativePunctuation?.length > 0}
              helperText={registrationError.alternativePunctuation}
              onClick={() => setRegistrationError({ ...registrationError, alternativePunctuation: '' })}
              variant="filled"
              style={{ margin: '8px 0px' }}
            />
          </>}

          {type === 1 && <Input
            label={t('Limite por Usuário')}
            type="number"
            value={registration.limitPerUser}
            onChange={(e) => setRegistration({ ...registration, limitPerUser: e.target.value })}
            size="small"
            fullWidth
            error={registrationError.limitPerUser?.length > 0}
            helperText={registrationError.limitPerUser}
            onClick={() => setRegistrationError({ ...registrationError, limitPerUser: '' })}
            variant="filled"
            style={{ margin: '8px 0px' }}
          />}

          <Input
            label={t('Patrocinado por (opcional)')}
            type="patrocinador"
            value={registration.sponsored}
            onChange={(e) => setRegistration({ ...registration, sponsored: e.target.value })}
            size="small"
            fullWidth
            error={registrationError.sponsored?.length > 0}
            helperText={registrationError.sponsored}
            onClick={() => setRegistrationError({ ...registrationError, sponsored: '' })}
            variant="filled"
            style={{ margin: '8px 0px' }}
          />
        </div>
      </div>

      {returnWarning && <div className='containerWarning'>
        <Warning className='iconWarning' />

        <div className='containerWarningText'>
          <h5>{t('O prazo de retirada precisa ser menor que o período entre hoje e a data de expiração.')}<br />{t('Por favor, ajuste as datas para continuar.')}</h5>
        </div>
      </div>}
    </S.ContainerRegisterPage2>
  )
}
