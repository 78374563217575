import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const ContainerBackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  padding: 24px;

    &:hover {
      opacity: 0.8;
    }

    .iconBackButton {
      color: ${(props) => props.theme.colorsBase.white};
      width: 24px;
      height: 24px;
      background-color: ${(props) => props.theme.colors.seventh};
      border-radius: 50%;
      padding: 4px;
    }

    h5 {
      margin-left: 8px;
      line-height: 16px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  

  @media (max-width: 680px) {
    padding: 16px;
  }
`

export const Line = styled.div<{ active?: boolean }>`
display: flex;
align-items: center;
justify-content: flex-start;
width: 100%;
height: 0.5px;
background-color: ${(props) => props.theme.colorsBase.gray11};
   
div {
  background-color: ${(props) => props.theme.colors.seventeen};
  padding-right: 12px;
}

@media (max-width: 680px) {
  div {
    background-color: ${(props) => props.theme.colorsBase.white};
    padding-right: 8px;
  }

  margin-top: 8px;
}

.i {
  width: 24px;
  height: 24px;
  color:  ${(props) => props.theme.colorsBase.gray3};
}

  h5 {
    line-height: 32px;
    text-align: left;
    font-size: ${responsiveFont(24)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    background-color:  #F9F9F9;
    padding-right: 8px;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(20)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(26)};
    }
  }
`

const menuClick = (active?: boolean, noMargin?: boolean) => {
  return noMargin ? 'none' : menuClickB(active)
}

const menuClickB = (active?: boolean) => {
  return active ? '32px 0 32px 0' : '0px 0 40px 0'
}

export const ContainerTitle = styled.div<{ active?: boolean, noMargin?: boolean, margin?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: ${(props) => menuClick(props.active, props.noMargin)};
  padding: 0 40px;

  @media (max-width: 680px) {
    margin: 12px 0 0px 0;
    padding: 0px 20px;
    padding-bottom: ${(props) => props.margin}px;
  }

  h3 {
    letter-spacing: 0.18px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    align-self: flex-start;

    @media (max-width: 680px) {
      margin-top: 8px;
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 24px;

    @media (max-width: 680px) {
      margin-bottom: 8px;
    }
  }
`

export const ContainerBackMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
   
    &:hover {
      opacity: 0.8;
    }

    .iconBackButton {
      color: ${(props) => props.theme.colorsBase.white};
      width: 24px;
      height: 24px;
      background-color: ${(props) => props.theme.colors.seventh};
      border-radius: 50%;
    }
`

export const ContainerButtonRightMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
  
    &:hover {
      opacity: 0.8;
    }

    .iconBackButton {
      color: ${(props) => props.theme.colors.secundary};
      width: 24px;
      height: 24px;
    }
`