import React, { useEffect, useState } from 'react';
import { IModal } from './types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import ModalCustom from '../../components/ModalCustom';
import ReactAudioPlayer from 'react-audio-player';

const ModalChallengeContent: React.FC<IModal> = (props: IModal) => {
  const { t } = useTranslation();
  const { modalContent, setModalContent, mediaType, media, sponsored } = props

  const [larguraDaTela, setLarguraDaTela] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setLarguraDaTela(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const renderContent = () => {
    return (
      <ModalCustom
        buttonsEnabled={false}
        open={modalContent}
        onClose={() => setModalContent(false)}
        onClick={() => { }}
        childrenContent={
          <>
            <S.BoxTitle>
              <div className='boxText'>
                <h2>{t('Tipo de Conteúdo')}</h2>
                <h1>{mediaType === 1 && t('Video')} {mediaType === 2 && t('Imagem')} {mediaType === 3 && t('Texto')} {mediaType === 4 && t('Audio')}</h1>
              </div>

              <div className='boxText'>
                <h2>{t('Contém anúncio patrocinado?')}</h2>
                <h1>{sponsored ? t('Sim') : t('Não')}</h1>
              </div>
            </S.BoxTitle>

            <S.ContainerData>
              {mediaType === 1 && (
                <video style={{ aspectRatio: larguraDaTela > 800 ? '16/9' : 'none' }}
                  id="my-player"
                  controls
                  preload="auto"
                  controlsList="nodownload"
                  data-setup='{}'>
                  <source src={media} ></source>
                </video>
              )}

              {mediaType === 2 && (
                <S.ImageContent style={{ aspectRatio: '16/9' }} src={media} />
              )}

              {mediaType === 4 && (
                <ReactAudioPlayer
                  src={media}
                  autoPlay
                  controls
                  style={{ margin: '48px 0px', width: '100%', padding: '0px 48px' }}
                />
              )}
            </S.ContainerData>
          </>
        }
        title={t('Conteúdo')}
        confirmationButtonText={t('CONFIRMAR')}
        textDeclineButton={t('CANCELAR')}
      />
    )
  }

  return renderContent()
}

export default ModalChallengeContent
