import React from 'react';
import { Card, CardContent, CardProps, Stack, Typography } from '@mui/material';

import { ITopChallenge } from '../../../services/Dashboard/ChallengesDashboard/types';
import Linear from './Linear';

interface IProps extends CardProps<typeof Stack> {
  title: string,
  data: ITopChallenge[]
  colors: string[]
}

const ChallengeRank: React.FC<IProps> = ({ title, data, colors, ...props }) => (
  <Card component={Stack} {...props} >
      <CardContent>
        <Stack p={2} spacing={2}>
          <Typography fontSize="20px" fontWeight="400" color='#201E1E'>{title}</Typography>
        </Stack>

        <Stack p={2} flex={1} justifyContent="space-between">
          {data.map(({ Id, Max, Name, Value }, index) => (
            <Linear key={Id} name={Name} value={Math.floor(Value * 100 / Max)} color={colors[index % colors.length]} />
          ))}
        </Stack>
      </CardContent>
  </Card>
)

export default ChallengeRank;
