import styled from 'styled-components'
import { theme } from '../../styles/theme'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const ContainerStepIndicators = styled.div`
  display: flex;
  justify-content: center;
`;

export const StepIndicator = styled.div<{ active: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? theme.colors.primary : theme.colorsBase.gray2)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ active }) => (active ? '#fff' : theme.colorsBase.gray)};
  margin: 0 8px;
  font-size: 14px;
`;

export const ContainerNavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 16px; 

  @media (max-width: 680px) {
    padding: 0 0 62px 0;
  }
`

export const ContainerBackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  padding: 24px;

    &:hover {
      opacity: 0.8;
    }

    .iconBackButton {
      color: ${(props) => props.theme.colorsBase.white};
      width: 24px;
      height: 24px;
      background-color: ${(props) => props.theme.colors.seventh};
      border-radius: 50%;
    }

    h5 {
      margin-left: 8px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  

  @media (max-width: 680px) {
    padding: 16px;
  }
`

export const ContainerContent = styled.div`
  padding: 0 40px;
  width: 100%;
  height: 100%;
  @media (max-width: 680px) {
    padding: 0;
  }
`

export const ContainerTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  padding: 24px 0;
  border-radius: 16px 16px 0px 0px;
  background-color: ${(props) => props.theme.colorsBase.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
height: 100%;

  .containerButton {
    display: flex;
    align-self: flex-end;
    width: 100%;
    padding:  12px 16px 0px 24px;
  }
  
  @media (max-width: 680px) {
    border-radius: 0px;
    background: transparent;
    box-shadow: none;
    padding: 8px 0 24px 0;

    .containerButton {
      display: flex;
      align-self: flex-start;
      padding: 0px 16px 0px 16px;
      flex-direction: column;
      width: 100%;
    }
  }
`

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;

  @media (max-width: 680px) {
    margin: 20px 0 0px 0;
  }
`

export const LineHeader = styled.div<{ register?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-bottom: solid 0.5px ${(props) => props.theme.colorsBase.gray9};
  padding: 0 40px;

  @media (max-width: 680px) {
    margin-bottom: ${(props) => (props.register ? '8px' : ' 48px')};
    border-bottom: none;
    justify-content: center;
    padding: 0;
  }
`

export const ButtonHeader = styled.button<{ active?: boolean }>`
  width: 15%;
  border: none;
  border-bottom: solid 2px ${(props) => (props.active ? props.theme.colors.seventh : 'transparent')};
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }
  
    h5 {
      line-height: 16px;
      text-align: center;
      font-size: ${responsiveFont(12)};
      color: ${(props) => (props.active ? props.theme.colors.seventh : props.theme.colors.friday)};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      padding: 8px 8px 16px 8px;
      text-transform: uppercase;
  
      @media (max-width: 680px) {
        font-size: ${responsiveFont(14)};
        text-transform: none;
      }
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

  @media (max-width: 680px) {
    width: 50%;
  }
`

export const BoxImageProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 16px;
  width: 100%;

  .InputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 16px;
    margin-left: 16px;

    h2 {
      margin-left: 12px;
      line-height: 24px;
      letter-spacing: 0.15px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.black};
      opacity: 0.6;
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(14)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    h1 {
      margin-left: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(10)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  }
`

export const ContainerChallenge = styled.div` 
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;

  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 8px 16px 8px;
    width: 100%;
  }
  
   @media (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 2500px) {
    grid-template-columns: repeat(5, 1fr);
  }
`

export const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 4px;
  z-index: 1;
  object-fit: cover; 
`

export const BoxChallenge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 280px;
  height: 100px;
  padding: 8px;
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

  .boxImage {
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .boxContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 16px;

    .title {
      max-width: 180px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      line-height: 24px;
      letter-spacing: 0.1px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 500;
      font-family: 'Barlow', Medium;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }

    .boxUser {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .i {
        color: #201E1E;
        font-size: 12px;
    
        &:hover {
          color: ${theme.colors.black};
        }
      }

      .nameUser {
        max-width: 160px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        margin-left: 8px;
        line-height: 16px;
        letter-spacing: 0.4px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(10)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }
    
    .containerChip {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .containerTop {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }
  }
`

export const SelectedLanguage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 4px;

  h5 {
    font-weight: 500;
    letter-spacing: 0.71px;
    margin-left: 6px;
    color: ${(props) => props.theme.colors.black};
    font-size: ${responsiveFont(8)};
    font-family: 'Barlow', Medium;
  }

  .flag {
    width: 16px;
    object-fit: cover;
  }
`

export const BoxChallengePending = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 280px;
  height: 110px;
  padding: 8px;
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  position: relative;

  &:hover {
    opacity: 0.8;
  }

  .iconChevron {
    color: #201E1E;
    font-size: 24px;
    position: absolute;
    right: 0px;
    top: 40px;
  }

  .boxImage {
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .boxContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 16px;

    .containerTitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      .title {
        max-width: 140px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        line-height: 24px;
        letter-spacing: 0.1px;
        text-align: left;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 500;
        font-family: 'Barlow', Medium;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(16)};
        }
      }

      .textPoints {
        line-height: 16px;
        letter-spacing: 0.4px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.seventh};
        font-weight: 500;
        font-family: 'Barlow', Medium;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(10)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }

    .boxUser {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .i {
        color: #201E1E;
        font-size: 12px;
    
        &:hover {
          color: ${theme.colors.black};
        }
      }

      .nameUser {
        max-width: 150px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        margin-left: 8px;
        line-height: 16px;
        letter-spacing: 0.4px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(10)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }
    
    .containerChip {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .containerTop {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }

    .containerProgressBar {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 70%;
      height: 50px;

      .textProgress {
        margin-left: 6px;
        line-height: 10px;
        letter-spacing: 0.8px;
        text-align: left;
        font-size: ${responsiveFont(9)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 500;
        font-family: 'Barlow', Medium;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(8)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(10)};
        }
      }
    }
  }
`

export const Chip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 12px;
  height: 30px;
  background-color: transparent;
  border-radius: 16px;
  border: solid 1px ${(props) => props.theme.colors.seventh};

  .i {
    color: ${theme.colors.seventh};
    font-size: 16px;
    margin-right: 8px;
  }

  .titleChip {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.seventh};

    .i {
      color: ${theme.colorsBase.white};
    }

    .titleChip {
      color: ${(props) => props.theme.colorsBase.white};
    }
  }
`

export const ChipMonochrome = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 12px;
  height: 30px;
  background-color: transparent;
  border-radius: 16px;
  border: solid 1px ${(props) => props.theme.colors.eleven};

  .i {
    color: ${theme.colors.seventh};
    font-size: 16px;
    margin-right: 8px;
  }

  .titleChip {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }
`

export const InputSelect = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  > div {
    display: flex;
    align-self: flex-start;

    padding: 0px;

    input {
      width: 22px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .input {
    width: 100%;

    input {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }

    select {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }
  }
`

export const CoverStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 12px;
  height: 100%;
   width: 100%;

  .title {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .subTitle {
    line-height: 18px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }

  .textError {
    margin-top: 8px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colorsBase.red};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
    padding: 0px;
  }
`

export const BackgroundImageProfile = styled.label`
  margin-top: 16px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  position: relative;
  margin-bottom: 16px;

   img {
    width: 350px;
    border: solid 1px ${(props) => props.theme.colorsBase.gray};
    z-index: 1;
    object-fit: cover; 
   }

   .iconBackground {
    display: flex;
    width: 350px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px ${(props) => props.theme.colorsBase.gray};
    background-color: ${(props) => props.theme.colorsBase.gray3};

    .iconCam {
      color: ${(props) => props.theme.colorsBase.black};
      width: 24px;
      height: 24px;
      align-self: center;
    }

    .textAdd {
      margin-top: 4px;
      margin-bottom: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(10)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
   }

  @media (max-width: 680px) {
    width: 100%;
    height: none;
    margin-bottom: 0px;

    img {
      width: 100%;
    }

    .iconBackground {
      width: 100%;
    }
  }
`

export const Input = styled.input`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`

export const MediaStage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  height: 100%;
  width: 100%;

  .textViewContent {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.secundary};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    background: transparent;
    border: none;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(12)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .textError {
    margin-top: 8px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colorsBase.red};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
    padding: 0px;
    justify-content: flex-start;
  }
`

export const BoxMedia = styled.label`
  margin-top: 24px;
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  position: relative;
  margin-bottom: 16px;
  border: solid 1px ${(props) => props.theme.colorsBase.gray};
  background-color: ${(props) => props.theme.colorsBase.gray3};

  .nameMedia {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .iconBackground {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .iconCam {
      color: ${(props) => props.theme.colorsBase.black};
      width: 24px;
      height: 24px;
      align-self: center;
    }

    .textAdd {
      margin-top: 4px;
      margin-bottom: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(10)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  }
`

export const InputMedia = styled.input`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`

export const BoxResponse = styled.label`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  margin: 4px 0px;

  .textNumber {
    width: 20px;
    margin-right: 16px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(24)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(26)};
    }
  }
`

export const ContainerResponse = styled.label`
  margin-bottom: 8px;
  margin-top: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const switchDivContainer = styled.div`
    margin-top: 24px;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h3 {
      margin-left: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }

    .switchDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      h5 {
        line-height: 20px;
        letter-spacing: 0.15px;
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colors.black};
        opacity: 0.6;
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }
    }

    @media (max-width: 680px) {
      margin-top: 8px;
      margin-left: 0px;
      margin-bottom: 24px;
    }
`

export const TitleQuestionSent = styled.div`
  margin: 0 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  font-size: ${responsiveFont(14)};
  color: ${(props) => props.theme.colors.black};
  font-weight: 400;
  font-family: 'Barlow', Medium;
  margin-bottom: 40px;
`

export const BoxMediaAudio = styled.label`
  display: flex;
  width: 280px;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 4px;
  position: relative;
  background-color: transparent;
  margin-right: 8px;
  padding: 4px 0px;
  
  &:hover {
    opacity: 0.8;
  }

  .iconBackground {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .iconMic {
      color: ${(props) => props.theme.colorsBase.black};
      width: 24px;
      height: 24px;
      align-self: center;
    }

    .textAdd {
      margin-left: 4px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colorsBase.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }
  }

  @media (max-width: 680px) {
    width: auto;
    margin: 16px 0px 24px 0px;
    padding: 4px 8px;
  }
`

export const InputAudio = styled.input`
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`

export const MessageBlank = styled.h5`
  margin-left: 8px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
  font-size: ${responsiveFont(12)};
  color: ${(props) => props.theme.colors.black};
  font-weight: 400;
  font-family: 'Barlow', Regular;
  
  @media (min-width: 1600px) {
    font-size: ${responsiveFont(14)};
  }
`

export const ContainerScrolling = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;

  @media (max-width: 680px) {
    flex-direction: column;
  }
`

export const ContainerInputs = styled.div`
  width: 60%;
  flex-direction: column;
  display: flex;
  align-items: center;

  @media (max-width: 680px) {
    width: 100%;
  }
`

export const ContainerHeader = styled.div`
    width: 100%; 
    display: flex;
    margin-left: 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    .buttonAudio {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-right: 24px;
    
      .titleButton {
        margin-bottom: 6px;
        line-height: 14px;
        letter-spacing: 0.15px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }

      .containerAudio {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
  
        .descriptionButton {
          line-height: 14px;
          letter-spacing: 0.15px;
          text-align: left;
          font-size: ${responsiveFont(10)};
          color: ${(props) => props.theme.colors.black};
          font-weight: 400;
          font-family: 'Barlow', Regular;
          
          @media (max-width: 680px) {
            font-size: ${responsiveFont(8)};
          }
  
          @media (min-width: 1600px) {
            font-size: ${responsiveFont(12)};
          }
        }
      }
    }

    @media (max-width: 680px) {
      flex-direction: column-reverse;
      margin-left: 0px;

      .buttonAudio {
        padding-right: 0px;
        margin-top: 16px;
        width: 100%;
        
        .titleButton {
          padding-left: 16px;
        }
        .containerAudio {
          flex-direction: column;
          align-items: center;
          width: 100%;

          .descriptionButton {
            text-align: center;
            width: 100%;
          }
        }
      }  
    }

`

export const ContainerBottom = styled.div`
    width: 100%; 
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;

    .divLine {
     display: flex;
     align-items: center;
     justify-content: flex-start;
     width: 100%;
     height: 0.5px;
     background-color: ${(props) => props.theme.colors.ten};
     margin: 40px 0 16px 0;
    }

    .buttonAudio {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-right: 24px;
    
      .titleButton {
        margin-bottom: 6px;
        line-height: 14px;
        letter-spacing: 0.15px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }

    }

    @media (max-width: 680px) {
      .divLine {
        display: none;
      }
    }
    
`

export const ButtonScrollToTop = styled.a`
  position: fixed;
  right: 16px;
  bottom: 88px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 90;
  background-color:  ${(props) => props.theme.colors.seventh};
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
  
  .icon {
    position: absolute;
    top: 8px;
    left: 8px;
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`

export const ContainerInRevision = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .boxLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    padding-right: 16px;
  }

  .boxRight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
  }

  .boxText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: 8px 0;

    .boxTextTooltip {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 4px;
       
      .iconRight {
        color: ${(props) => props.theme.colorsBase.gray14};  
        width: 14px;
        height: 14px;
        margin-left: 4px;

        &:hover {
          color: ${(props) => props.theme.colors.friday};
        }
      }

      h2 {
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.fourth};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }

    h1 {
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

    h4 {
      margin: 0px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 700;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    .containerCategory {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
    }

    .boxCategory {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 8px;
      border-radius: 24px;
      margin: 4px;
      background-color: ${(props) => props.theme.colors.seventh2};

      h5 {
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colorsBase.black3};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }
    }
  }

  @media (max-width: 680px) {
    flex-direction: column;
    padding: 0 32px;

    .boxLeft {
      width: 100%;
      padding-right: 0px;
    }

    .boxRight {
      width: 100%;
    }
  }
`

export const ImageModalDetails = styled.img`
  width: 80%;
  border-radius: 4px;
  z-index: 1;
  object-fit: cover; 

  @media (max-width: 680px) {
    width: 100%;
  }
`

export const ContainerMediaButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 4px 0;

  .containerButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
   
    h1 {
      margin-bottom: 4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

    .boxButtons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border-radius: 4px;
      background: transparent;
      padding: 4px;
      border: solid 1px ${(props) => props.theme.colors.secundary2};

      &:hover {
        opacity: 0.8;
      }

      .iconLeft {
        color: ${(props) => props.theme.colors.secundary2};  
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }

      h2 {
        text-align: left;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colors.secundary2};  
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(16)};
        }
      }
    }
  }

  @media (max-width: 680px) {
    .containerButtons {
      .boxButtons {
        width: 100%;
      }
    }
  }
`

export const ContainerModalInRevision = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .containerReasonForDisapproval {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 12px; 
    background: transparent;
    padding: 16px;
    width: 50%;
    box-shadow: 0px 4px 4px 0px ${(props) => props.theme.colorsBase.black6};

    .boxReasonForDisapproval {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
  
      .iconLeft {
        color: ${(props) => props.theme.colorsBase.gray14};  
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
  
      h2 {
        text-align: left;
        font-size: ${responsiveFont(20)};
        color: ${(props) => props.theme.colors.black3};  
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(14)};
        }

        @media (min-width: 1600px) {
          font-size: ${responsiveFont(22)};
        }
      }
    }
  }

  @media (max-width: 680px) {
    flex-direction: column;

    .containerReasonForDisapproval {
      width: 100%;

      .boxReasonForDisapproval {
        .iconLeft {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`

export const ContainerModalBottomButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 680px) {
    margin-top: 22px;
    padding-left: 4px;
    padding-right: 4px;
  }
`

export const ContainerModalActive = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .containerReasonForDisapproval {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 12px; 
    background: transparent;
    padding: 16px;
    width: 50%;
    box-shadow: 0px 4px 4px 0px ${(props) => props.theme.colorsBase.black6};
    margin: auto 0;
    
    .boxGrafic {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 24px;
    }

    .boxReasonForDisapproval {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-start;
      width: 100%;
  
      .iconLeft {
        color: ${(props) => props.theme.colorsBase.gray14};  
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
  
      h2 {
        text-align: left;
        font-size: ${responsiveFont(20)};
        color: ${(props) => props.theme.colors.black3};  
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(14)};
        }

        @media (min-width: 1600px) {
          font-size: ${responsiveFont(22)};
        }
      }

      h3 {
        margin-left: 4px;
        text-align: left;
        font-size: ${responsiveFont(8)};
        color: ${(props) => props.theme.shadows.fourth};  
        font-weight: 400;
        font-family: 'Barlow', Regular;

        @media (min-width: 1600px) {
          font-size: ${responsiveFont(10)};
        }
      }
    }
  }

  @media (max-width: 680px) {
    flex-direction: column;

    .containerReasonForDisapproval {
      width: 100%;

      .boxReasonForDisapproval {
        .iconLeft {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`

export const AnswerQuestions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: -8px;

  .divContentText {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .divNumber {
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      h3 {
        display: flex;
        line-height: 24px;
        letter-spacing: 0.18px;
        text-align: center;
        font-size: ${responsiveFont(20)};
        color: ${(props) => props.theme.colorsBase.white};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        background-color: ${(props) => props.theme.colors.secundary};
         height: 32px;
         width: 32px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        
        @media (max-width: 680px) {
          height: 32px;
          width: 32px;
        }
    
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(22)};
        }
      }
    }

    h5 {
      margin-left: 12px;
      line-height: 24px;
      letter-spacing: 0.18px;
      text-align: left;
      font-size: ${responsiveFont(20)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(22)};
      }
    }
  }

  .divContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 12px;

    .boxAlternative{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      background-color: ${(props) => props.theme.colorsBase.green4};
      padding: 4px 8px;
      border-radius: 8px;
      border: solid 1px ${(props) => props.theme.colorsBase.green3};

      h3 {
        padding-right: 16px;
        text-align: left;
        font-size: ${responsiveFont(24)};
        color: ${(props) => props.theme.colorsBase.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;

        @media (min-width: 1600px) {
          font-size: ${responsiveFont(26)};
        }
      }

      h5 {
        text-align: left;
        font-size: ${responsiveFont(20)};
        color: ${(props) => props.theme.colorsBase.black3};
        font-weight: 400;
        font-family: 'Barlow', Regular;

        @media (min-width: 1600px) {
          font-size: ${responsiveFont(22)};
        }
      }

      .iconDone {
        color: ${(props) => props.theme.colorsBase.green3};
        width: 24px;
        height: 24px;
        margin-left: 12px;
      }

    
    }

  }


  @media (max-width: 680px) {
    margin-top: 4px;

    .divContent{
      margin-left: 16px;
    }
  }
`

export const ModalBottomQuiz = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 80px;

  @media (max-width: 680px) {
   margin-top: 0px;
  }
`

export const ContainerQuestionCount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  align-self: center;
  bottom: 30px;
  width: 100%;
  z-index: -1;

  @media (max-width: 680px) {
    z-index: 1;
    bottom: 0px;
    position: relative;
    margin-bottom: 12px;
    margin-top: 60px;
  }
`

export const ButtonQuestion = styled.div<{ active: boolean }>`
    display: flex;
    height: 20px;
    width: 20px;
    margin: 0px 2px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:  ${props => props.active ? theme.colors.primary : "#cccccc"};
    border-radius: 50%;

    .i {
      color: ${props => props.active ? theme.colorsBase.white : theme.colors.primary};
      width: 16px;
      height: 16px;
      align-self: center;
    }

    .textbutton {
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${props => props.active ? theme.colorsBase.white : theme.colors.black};
      font-weight: 500;
      font-family: 'Barlow', Medium;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
`

export const ButtonSponsoredAd = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .options {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .textAd {
        margin-left: 12px;
        line-height: 16px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${theme.colors.fourth};
        font-weight: 400;
        font-family: 'Barlow', Regular;
  
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }

      div {
        justify-content: flex-end;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;

        .alternative {
          background: transparent;
          border: none;
        }

        .option {
          pointer-events: none;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin: 0;
          font-weight: 400;
          font-family: 'Barlow', Regular;
        }
        
      }
    }
`

export const ContainerInformationConfirmation = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;

    .containerContentInformationConfirmation {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;
      width: 95%;
    }

    .containerButtonsInformationConfirmation {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      margin-top: 24px;
    }
`

export const BoxInformationConfirmation = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;

    .textBoxInformationConfirmation {
      margin-bottom: 16px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
`

export const CardChallengeInformationConfirmation = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: solid 0.5px ${(props) => props.theme.colorsBase.gray8};
    padding-right: 20px;
    margin-right: 24px;
`

export const ContainerInformationConfirmationData = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 16px;
    border: solid 1px ${(props) => props.theme.colors.black5};

    .textTitleInformationConfirmation {
      width: 100%;
      margin-bottom: 16px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }

    .containerButtonsInformationConfirmationData {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
    }

    @media (max-width: 680px) {
      border-radius: 0px;
      border: none;
      padding: 0px;
      padding-bottom: 24px;

      .containerButtonsInformationConfirmationData {
        flex-direction: column;
      }
    }
`

export const ContainerQuestionCountInformationConfirmation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  margin-bottom: 16px;

  @media (max-width: 680px) {
  }
`

export const ContainerInformationConfirmationDataPage1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  .boxInformationConfirmationDataImage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding-right: 24px;
  }

  .boxInformationConfirmationDataInputs{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 680px) {
    flex-direction: column;

    .boxInformationConfirmationDataImage{
      padding-right: 0px;
    }
  
  }
`

export const CoverStepPage1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;

  .title {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .subTitle {
    line-height: 18px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }

  .textError {
    margin-top: 8px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colorsBase.red};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
    padding: 0px;
  }
`

export const BackgroundImageProfilePage1 = styled.label`
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  position: relative;
  margin-bottom: 16px;
  width: 100%;

   img {
    width: 100%;
    border-radius: 2px;
    border: solid 1px ${(props) => props.theme.colorsBase.gray6};
    z-index: 1;
    object-fit: cover; 
   }

  .iconBackground {
    position: absolute;
    bottom: -8px;
    right: -8px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.secundary};
    z-index: 1;

    .iconCam {
      color: ${(props) => props.theme.colorsBase.white};
      width: 24px;
      height: 24px;
      align-self: center;
    }
  }
`

export const ContainerInformationConfirmationDataPage2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  @media (max-width: 680px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const ContainerDataB = styled.div`
  display: flex;
  align-items: flex-start;
  width: 60%;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 680px) {
    width: 100%;
    margin-top: 8px;
  }
`

export const ImageContent = styled.img`
  width: 100%;
  object-fit: cover; 
`

export const VideoContent = styled.video`
  width: 100%;
  border: solid 1px ${(props) => props.theme.colorsBase.gray};
  object-fit: cover; 
`

export const BoxTitleB = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    .doubleContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
    }

    .boxText {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin: 8px 0;
  
        h2 {
          text-align: left;
          font-size: ${responsiveFont(12)};
          color: ${(props) => props.theme.colors.fourth};
          font-weight: 400;
          font-family: 'Barlow', Regular;
          
          @media (min-width: 1600px) {
            font-size: ${responsiveFont(14)};
          }
        }
      
      h1 {
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colorsBase.black3};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }
    }
    
    @media (max-width: 680px) {
      width: 100%;

      .doubleContent {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
      }
    }
`

export const ContainerInformationConfirmationDataPage3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  .titlePage3 {
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.fourth};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .textNotFountQuiz {
    margin: auto 24px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.fourth};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  .listQuiz {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 35vh;
    padding: 0 4px;
    overflow:auto; 

    &::-webkit-scrollbar {
      width: 8px;
      background: ${(props) => props.theme.colorsBase.white};
      }
    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colorsBase.gray5};
      border-radius: 8px;
    }
    ::-webkit-scrollbar:horizontal { 
      height: 4px;
    }
  }

  @media (min-width: 1600px) {
    .listQuiz {
      height: 45vh;
    }
  }

  @media (max-width: 680px) {
    .listQuiz {
      height: 45vh;
    }
  }
`

export const ContainerQuestionCountEdit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  margin-bottom: 12px;

  @media (max-width: 680px) {
    z-index: 1;
    bottom: 0px;
    position: relative;
    margin-bottom: 12px;
  }
`

export const buttonsApproved = styled.div`
  margin:2rem 0rem 0rem;
  gap:20px;
  display:flex;
  align-items:center;
  justify-content:center;
`

export const ContainerApprovedReview = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .containerG{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .boxLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    padding-right: 16px;
  }

  .boxRight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
  }

  .boxText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: 8px 0;
    margin-bottom: 1rem;

    .boxTextTooltip {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 4px;
       
      .iconRight {
        color: ${(props) => props.theme.colorsBase.gray14};  
        width: 14px;
        height: 14px;
        margin-left: 4px;

        &:hover {
          color: ${(props) => props.theme.colors.friday};
        }
      }

      h1{
        font-size: 20px;
      }

      h2 {
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.fourth};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }

    h1 {
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

    h4 {
      margin: 0px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 700;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    .containerCategory {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
    }

    .boxCategory {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 8px;
      border-radius: 24px;
      margin: 4px;
      background-color: ${(props) => props.theme.colors.seventh2};

      h5 {
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colorsBase.black3};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }
    }

    .imgApprovedReview{
      width: 100%;
      aspect-ratio: 16/9;
      border-radius: 4px;
      z-index: 1;
      object-fit: cover;
    }
  }

  @media (max-width: 680px) {
    flex-direction: column;
    padding: 0 32px;

    .boxLeft {
      width: 100%;
      padding-right: 0px;
    }

    .boxRight {
      width: 100%;
    }

    
  }
`

export const ApprovedReviewButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 4px 0;

  .containerButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
   
    h1 {
      margin-bottom: 4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      font-size: 20px;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

    .boxButtons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border-radius: 4px;
      background: transparent;
      padding: 4px;
      border: solid 1px ${(props) => props.theme.colors.secundary2};

      &:hover {
        opacity: 0.8;
      }

      .iconLeft {
        color: ${(props) => props.theme.colors.secundary2};  
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }

      h2 {
        text-align: left;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colors.secundary2};  
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(16)};
        }
      }
    }
  }

  @media (max-width: 680px) {
    .containerButtons {
      .boxButtons {
        width: 100%;
      }
    }
  }
`

export const ContainerRejectedPerformance = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr 2fr;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;

  .containerG{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .boxLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    padding-right: 16px;
  }

  .boxRight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
  }

  .reprobationMotive {
    display: flex;
    gap:20px;
  }

  .reprobationButton{
    margin: 2rem 0rem 0rem;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .performanceGraphic {
    display: grid;
    gap: 1rem;
    box-shadow: 1px 4px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    margin: 30px 0px;
  }

  .graphic {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .titleGraphic{
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-left: 2rem;

    .iconLeft {
        color: ${(props) => props.theme.colorsBase.gray13};  
      }
    .challengePerformance{
      font-size:30px;
      color: ${(props) => props.theme.colorsBase.gray13};  
    }
    .lastDays{
      font-size:12px;
    }
  }

  .containerIcons{
    display:flex;
    justify-content:center;
    align-items: center;
    gap:20px;
    font-size:10px;
    margin-top:2rem;

    .icons{
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .icon1{
      width: 15px;
      height: 15px;
      background-color: #1A1266;
      border-radius: 50%;
    }

    .icon2{
      width: 15px;
      height: 15px;
      background-color: #00DAF9;
      border-radius: 50%;
    }
  }

  
  .boxText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: 8px 0;
    margin-bottom: 1rem;

    .boxTextTooltip {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 4px;
       
      .iconRight {
        color: ${(props) => props.theme.colorsBase.gray14};  
        width: 14px;
        height: 14px;
        margin-left: 4px;

        &:hover {
          color: ${(props) => props.theme.colors.friday};
        }
      }

      h1{
        font-size: 20px;
      }

      h2 {
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.fourth};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }

    h1 {
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

    h4 {
      margin: 0px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 700;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    .containerCategory {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
    }

    .boxCategory {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 8px;
      border-radius: 24px;
      margin: 4px;
      background-color: ${(props) => props.theme.colors.seventh2};

      h5 {
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colorsBase.black3};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }
    }

    .imgRejectedPerformance{
      width: 100%;
      aspect-ratio: 16/9;
      border-radius: 4px;
      z-index: 1;
      object-fit: cover;
    }
  }

  @media (max-width: 680px) {
    flex-direction: column;
    padding: 0 32px;

    .boxLeft {
      width: 100%;
      padding-right: 0px;
    }

    .boxRight {
      width: 100%;
    }

    
  }
`

export const RejectedPerformanceButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 4px 0;

  .containerButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
   
    h1 {
      margin-bottom: 4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      font-size: 20px;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

    .boxButtons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border-radius: 4px;
      background: transparent;
      padding: 4px;
      border: solid 1px ${(props) => props.theme.colors.secundary2};

      &:hover {
        opacity: 0.8;
      }

      .iconLeft {
        color: ${(props) => props.theme.colorsBase.gray13};  
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }

      h2 {
        text-align: left;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colors.secundary2};  
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(16)};
        }
      }
    }
  }

  @media (max-width: 680px) {
    .containerButtons {
      .boxButtons {
        width: 100%;
      }
    }
  }
`