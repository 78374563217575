import React from 'react';
import * as S from './styles'
import { theme } from '../../styles/theme';

interface IProps {
  onClickAdd?: () => void
  onClickRemove?: () => void
  value: number
  setValue: (e: number) => void
  max: number;
  min: number;
}

const InputSelectNumber: React.FC<IProps> = ({ onClickAdd, onClickRemove, value, setValue, max, min }) => {

  return (
    <S.ContainerPointSelectionNumber>
      <div className='containerPoint' >
        <button className='boxButtonLeft'
          onClick={onClickRemove}
          disabled={value <= min}
          style={{ border: `solid 1px ${value <= min ? theme.colors.black5 : theme.colors.secundary}`, backgroundColor: value <= min ? theme.colorsBase.gray2 : theme.colors.seventh2, color: value <= min ? theme.colors.black5 : theme.colors.secundary }}
        >
          <h1 className='textButton'>-</h1>
        </button>

        <input
          className='inputPoint'
          type="number"
          value={value}
          onChange={(e) => setValue(Number(e.target.value))}
          max={max}
          min={min}
          disabled
        />

        <button
          className='boxButtonRight'
          onClick={onClickAdd}
          disabled={value >= max}
          style={{ border: `solid 1px ${value >= max ? theme.colors.black5 : theme.colors.secundary}`, backgroundColor: value >= max ? theme.colorsBase.gray2 : theme.colors.seventh2, color: value >= max ? theme.colors.black5 : theme.colors.secundary }}
        >
          <h1 className='textButton'>+</h1>
        </button>
      </div>
    </S.ContainerPointSelectionNumber>
  )
}

export default InputSelectNumber;
