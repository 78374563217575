import { AccountBalanceWallet } from '@mui/icons-material';
import { t } from 'i18next';
import React from 'react';
import * as S from './styles'
import { useIsSm } from '../../hooks/useIsSm';
import { responsiveFont } from '../../utils/UtilsGlobal';
import { theme } from '../../styles/theme';

interface IProps {
    title: string;
    description: string;
    value?: string;
    onClick?: () => void;
    extractButton?: boolean;
    /* eslint-disable-next-line @typescript-eslint/ban-types */
    style?: Object;
    smallerSize?: boolean
    textPoint?: boolean
    valueTextPoint?: string
    textGreen?: boolean
}


const CardAchievements: React.FC<IProps> = ({ title, description, value, onClick, extractButton, style, smallerSize, textPoint, valueTextPoint, textGreen }) => {
    const isSm = useIsSm()

    return (
        <S.ContainerChallenge onClick={onClick} style={style} smallerSize={smallerSize}>
            <div className='container'>
                <div className='containerTitle' style={{ width: textPoint ? '70%' : '100%' }}>
                    <h1 className='title'>{title}</h1>

                    {(extractButton && !isSm) &&
                        <S.ContainerExtract>
                            <AccountBalanceWallet className="i" />
                            <h1 className='titleExtract'>{t('Ver Extrato')}</h1>
                        </S.ContainerExtract>
                    }
                </div>

                <div className='containerDescription'>
                    <h1 className='description' style={{ width: textPoint ? '50%' : "100%" }}>{description}</h1>
                    {textPoint ? <h1 className='value' style={{ marginTop: '-24px', width: '50%', alignSelf: 'flex-end', textAlign: 'right', color: textGreen ? theme.colorsBase.green2 : theme.colors.seventh }}>{value} <b className='value' style={{ fontSize: responsiveFont(10), color: textGreen ? theme.colorsBase.green2 : theme.colors.seventh }}>{valueTextPoint ?? t('pontos')}</b></h1> : <h1 className='value'>{value}</h1>}
                </div>
            </div>

            {(extractButton && isSm) &&
                <S.ContainerExtract>
                    <AccountBalanceWallet className="i" />
                    <h1 className='titleExtract'>{t('Ver Extrato')}</h1>
                </S.ContainerExtract>
            }
        </S.ContainerChallenge>
    )
}

export default CardAchievements
