import React from 'react';
import { Box, Chip, Stack } from '@mui/material';
import { Search } from '@mui/icons-material';

import { ENivelDificuldate, ETipoConteudo, IGetChallengesTriageResponse } from '../../services/Moderation/types';

import LanguageFlag from '../LanguageFlag';
import ModalButton from '../ModalButton';

import MediaModal from './MediaModal';
import QuizModal from './QuizModalOld';
import { Content, Title } from './styles';

interface IProps {
  data: IGetChallengesTriageResponse
}

const ChallengeInfoPage: React.FC<IProps> = ({ data, children }) => (
  <Box
    display="grid"
    gap={2}
    sx={(theme) => ({
      paddingBottom: '64px',
      gridTemplateAreas: `
                "author   author    author    author   author"
                "title    language  dificult  category tags"
                "duration sorceLink sorceLink empty1   sourceDescription"
                "awards   image     info      info     info"
                "midia    quiz      info      info     info"
                "empty2   empty2    info      info     info"
                `,
      [theme.breakpoints.down(800)]: {
        gridTemplateAreas: `
                  "author author"
                  "title title"
                  "duration dificult"
                  "awards category"
                  "language sorceLink"
                  "sourceDescription sourceDescription"
                  "image image"
                  "tags tags"
                  "midia quiz"
                  "info  info"
                  "empty empty"
                  "another another"
                  "aprove reject"
                  "sugest start"
                `
      }
    })}
  >
    {children}

    <Box gridArea="author">
      <Title>Autor(a) do Desafio</Title>
      <Content>{data.NomeAutor}</Content>
    </Box>
    <Box gridArea="title">
      <Title>Título do Desafio</Title>
      <Content>{data.Titulo}</Content>
    </Box>
    <Box gridArea="duration">
      <Title>Tempo de Duração</Title>
      <Content>{data.TempoDuracao} min</Content>
    </Box>
    <Box gridArea="dificult">
      <Title>Nível de Dificuldade</Title>
      <Content>{ENivelDificuldate[data.NivelDificuldade]}</Content>
    </Box>
    <Box gridArea="awards">
      <Title>Seleção de Prêmios</Title>
      <Content>N/A</Content>
    </Box>
    <Box gridArea="category">
      <Title>Categoria</Title>
      <Content>{data.NomeCategoria}</Content>
    </Box>
    <Box gridArea="language">
      <Title>Idioma</Title>
      <Stack direction="row">
        <LanguageFlag Idioma={data.Idioma} fillSpace={false} />
      </Stack>
    </Box>
    <Box gridArea="sorceLink">
      <Title>Link da Fonte</Title>
      <Content>{data.Fonte ?? "N/A"}</Content>
    </Box>
    <Box gridArea="sourceDescription">
      <Title>Observação sobre a Fonte</Title>
      <Content>{data.ObservacaoFonte ?? "N/A"}</Content>
    </Box>
    <Box gridArea="image">
      <Title>Imagem de Capa</Title>
      <ModalButton
        variant="outlined"
        startIcon={<Search />}
        buttonChildren="VER CAPA"
      >
        {({ open, onClose }) => (
          <MediaModal
            open={open}
            onClose={onClose}
            content={data.ImgCapa}
            contentType={ETipoConteudo.IMAGE}
            title='Visualizar Capa'
          />
        )}
      </ModalButton>
    </Box>
    <Box gridArea="tags">
      <Title>Tags</Title>
      {data.Tags.length === 0 ? <Content>N/A</Content> : null}
      <Stack direction='row' spacing={1}>
        {data.Tags.map((tag) => <Chip key={tag} label={tag} color="info" size="small" />)}
      </Stack>
    </Box>
    <Box gridArea="midia">
      <Title>Mídia</Title>
      <ModalButton
        variant="outlined"
        startIcon={<Search />}
        buttonChildren="VER MÍDIA"
      >
        {({ open, onClose }) => (
          <MediaModal
            open={open}
            onClose={onClose}
            content={data.Conteudo.Conteudo}
            contentType={data.Conteudo.TipoConteudo}
          />
        )}
      </ModalButton>
    </Box>
    <Box gridArea="quiz">
      <Title>Quiz</Title>
      <ModalButton
        variant="outlined"
        startIcon={<Search />}
        buttonChildren="VER QUIZ"
      >
        {({ open, onClose }) => <QuizModal open={open} onClose={onClose} data={data} />}
      </ModalButton>
    </Box>
  </Box>
)

export default ChallengeInfoPage;