import React, { useState } from 'react';

import { AccessTimeFilled, Cached, CalendarMonth, Edit, Handshake, Info, Inventory2, LibraryAddCheck, LocalPlay, OpenInNew, Sell, Shop, Tag, Visibility, VisibilityOff, } from '@mui/icons-material'
import { Tooltip, } from '@mui/material'
import { emptyItem, ITableUsers, Registration, RegistrationError } from './types'
import { useTranslation } from 'react-i18next'
import ModalCustom from '../../../components/ModalCustom'
import ToastNotification from '../../../components/ToastNotification'
import * as S from './styles'
import { useIsSm } from '../../../hooks/useIsSm';
import ModalCustomBottom from '../../../components/ModalCustomBottom';
import { theme } from '../../../styles/theme';
import { IProduct } from '../../../services/Product/types';
import { disableProduct, enableProduct, getProductDetails, putProduct } from '../../../services/Product';
import { Table, Icons } from '../../../pages/UserManagement/TableUsers/styles';
import ButtonMaterial from '../../../components/Button'
import ProductCreationAndEditing from '../ProductCreationAndEditing';
import { ProductDateValidation, registerValidation, registerValidationChallenge } from '../Product/validations';
import { DataMaskFormtData, dateMaskInverter, onlyDigits } from '../../../utils/Mask';
import { useNavigate } from 'react-router-dom';
import { addDays, format } from 'date-fns';


const TableProduct: React.FC<ITableUsers> = (props: ITableUsers) => {
  const { t } = useTranslation();
  const isSm = useIsSm()
  const { listUsers, updateList } = props
  const [itemSelected, setItemSelected] = useState<IProduct>(emptyItem)
  const [loading, setLoading] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openDetails, setOpenDetails] = useState(false)
  const [openEditCancelModal, setOpenEditCancelModal] = useState(false)
  const [registration, setRegistration] = useState<Registration>(new Registration());
  const [registrationError, setRegistrationError] = useState<RegistrationError>(new RegistrationError());
  const [urlImage, setUrlImage] = useState('');
  const [openDisableProductModal, setOpenDisableProductModal] = useState(false)
  const navigate = useNavigate()
  const [dataError, setDataError] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false)

  const retrieveProductDetails = async (item: IProduct, edit: boolean) => {
    setLoading(true)
    try {
      const response = await getProductDetails(item.Id);
      if (response.Success) {
        const objectProduct: IProduct = {
          Id: item.Id,
          Name: item.Name,
          Enabled: item.Enabled,
          Description: response.Data.Description,
          Points: response.Data.Points,
          ImageUrl: response.Data.ImageUrl,
          Count: response.Data.Count,
          Type: response.Data.Type,
          LimitPerUser: response.Data.LimitPerUser,
          ChallengeName: response.Data.ChallengeName,
          ExpirationDate: DataMaskFormtData(response.Data.ExpirationDate ?? ``),
          WithdrawalDeadline: response.Data.WithdrawalDeadline,
          SponsoredBy: response.Data.SponsoredBy ?? ``,
          MinPoints: response.Data.MinPoints ?? 0,
          MaxPoints: response.Data.MaxPoints ?? 0,
          AlternativePoints: response.Data.AlternativePoints,
          ChallengeId: response.Data.ChallengeId
        }

        if (edit) {
          setItemSelected(objectProduct)
          registerEditData(objectProduct)
          setOpenEdit(true)
          setOpenDetails(false)
        } else {
          setItemSelected(objectProduct)
          setOpenDetails(true);
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar o produto, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar o produto, tente novamente.')
      });
    }
    setLoading(false)
  };

  const handleEditProduct = async () => {
    setLoading(true);
    try {
      let countError = 0;

      const validation = Number(itemSelected.Type) === 1 ? await registerValidation(registration) : await registerValidationChallenge(registration);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError(validation as RegistrationError);
        setLoading(false);
        return;
      }

      const calcularData = (somaDias: number) => {
        const dataAtual = new Date();
        const novaData = addDays(dataAtual, somaDias);
        return novaData;
      };

      const dateValidation = ProductDateValidation(onlyDigits(registration.expirationDate), String(format(calcularData(Number(registration.withdrawalDeadline)), 'ddMMyyyy')));
      if (!dateValidation.status) {
        setDataError(true)
        setLoading(false);
        return;
      } else {
        setDataError(false)
      }

      let response;
      if (Number(itemSelected.Type) === 1) {
        const bodyData = {
          Name: registration.name,
          Count: Number(registration.availableQuantity),
          Description: registration.details,
          Points: Number(registration.exchangeScore),
          ImageUrl: urlImage,
          Type: 1,
          LimitPerUser: Number(registration.limitPerUser),

          ExpirationDate: dateMaskInverter(registration.expirationDate),
          WithdrawalDeadline: Number(registration.withdrawalDeadline),
          SponsoredBy: registration.sponsored
        };
        response = await putProduct(bodyData, itemSelected?.Id);
      } else {
        const bodyData = {
          Name: registration.name,
          Count: Number(registration.availableQuantity),
          Description: registration.details,
          ImageUrl: urlImage,
          Type: 2,

          ExpirationDate: dateMaskInverter(registration.expirationDate),
          WithdrawalDeadline: Number(registration.withdrawalDeadline),
          SponsoredBy: registration.sponsored,
          MinPoints: Number(registration.minimumScore),
          MaxPoints: Number(registration.maximumScore),
          AlternativePoints: Number(registration.alternativePunctuation),
        };
        response = await putProduct(bodyData, itemSelected?.Id);
      }

      if (response.Success) {
        updateList();
        setOpenEditModal(true)

        clearEditData();
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao editar o produto, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao editar o produto, tente novamente.')
      });
    }
    setLoading(false);
  };

  const handleEnable = async (id: string) => {
    setLoading(true);
    try {
      if (id) {
        const response = await enableProduct(id);
        if (response.Success) {
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Produto ativado com sucesso!')
          });
          updateList();
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível ativar o produto, tente novamente.'),
            errorMessage: response.Message,
            errors: response.Errors
          });
        }
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível ativar o produto, tente novamente.')
      });
    }
    setLoading(false);
  };

  const handleDisable = async (id: string) => {
    setLoading(true);
    try {
      if (id) {
        const response = await disableProduct(id);
        if (response.Success) {
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Produto desativado com sucesso!')
          });
          updateList();
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível desativar o produto, tente novamente.'),
            errorMessage: response.Message,
            errors: response.Errors
          });
        }
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível desativar o produto, tente novamente.')
      });
    }
    setLoading(false);
  };

  const clearEditData = () => {
    setOpenEditCancelModal(false)
    setOpenEdit(false)
    setUrlImage('')
    setRegistration(new Registration())
    setRegistrationError(new RegistrationError())
    setDataError(false)
  };

  const handleDisableOrEnableProduc = (item: IProduct) => {
    setItemSelected(item)
    if (item.Enabled) {
      if (item.Type === 1) {
        handleDisable(item.Id ?? '')
      } else {
        setOpenDisableProductModal(true)
      }
    } else {
      handleEnable(item.Id ?? '')
    }
  };

  const returnEdit = () => {
    return <ProductCreationAndEditing
      registration={registration}
      registrationError={registrationError}
      setRegistration={(e) => setRegistration(e)}
      setRegistrationError={(e) => setRegistrationError(e)}
      setUrlImage={(e) => setUrlImage(e)}
      urlImage={urlImage}
      edit
      id={itemSelected.Id}
      type={itemSelected.Type}
      returnWarning={dataError}
    />
  };


  const returnDetails = () => {
    return <S.ContainerDetails active={itemSelected.Type === 2}>
      <div className='containerBoxImageProduct'>
        <div className='boxImageProduct'>
          <div className='boxTileImage'>
            <Shop className="boxIconImage" />
            <h5>{itemSelected.Name}</h5>
          </div>

          <div className='boxImageAndText'>
            <S.Image style={{ aspectRatio: '4/3.2' }} src={itemSelected.ImageUrl} />

            {(itemSelected.Type === 2 && isSm) && <div className='boxTextInfoB'>
              <div className='boxTextInfoTopB'>
                <h3>{t('Pontuação Máxima')}:</h3>
                <h3 style={{ color: theme.colors.black4 }}>{itemSelected.MaxPoints ?? '-'}</h3>
              </div>

              <div className='boxTextInfoTopB'>
                <h3>{t('Pontuação Mínima')}:</h3>
                <h3 style={{ color: theme.colors.black4 }}>{itemSelected.MinPoints ?? '-'}</h3>
              </div>

              <div className='boxTextInfoTopB'>
                <h3>{t('Pontuação Alternativa')}:</h3>
                <h3 style={{ color: theme.colors.black4 }}>{itemSelected.AlternativePoints ?? '-'}</h3>
              </div>
            </div>}
          </div>
        </div>

        {(itemSelected.Type === 2 && !isSm) && <div className='boxTextInfoB'>
          <div className='boxTextInfoTopB'>
            <h3>{t('Pontuação Máxima')}:</h3>
            <h3 style={{ color: theme.colors.black4 }}>{itemSelected.MaxPoints ?? '-'}</h3>
          </div>

          <div className='boxTextInfoTopB'>
            <h3>{t('Pontuação Mínima')}:</h3>
            <h3 style={{ color: theme.colors.black4 }}>{itemSelected.MinPoints ?? '-'}</h3>
          </div>

          <div className='boxTextInfoTopB'>
            <h3>{t('Pontuação Alternativa')}:</h3>
            <h3 style={{ color: theme.colors.black4 }}>{itemSelected.AlternativePoints ?? '-'}</h3>
          </div>
        </div>}
      </div>

      <div className='boxInfoProduct'>
        <div className='boxTextInfo'>
          <div className='boxTextInfoTop'>
            <LibraryAddCheck className="boxIconInfo" />
            <h3>{t('Tipo')}:</h3>
            <h3 style={{ color: theme.colors.fourth }}>{itemSelected?.Type === 2 ? t('Atribuído à Desafio') : t('Troca de Pontos')}</h3>
          </div>
        </div>

        <div className='boxTextInfo'>
          <div className='boxTextInfoTop'>
            <Tag className="boxIconInfo" />
            <h3>{t('ID')}:</h3>
            <h3 style={{ color: theme.colors.fourth }}>{itemSelected.Id}</h3>
          </div>
        </div>

        {itemSelected.Type === 1 && <div className='boxTextInfo'>
          <div className='boxTextInfoTop'>
            <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22"><path d="M480-800 243-663l237 137 237-137-237-137ZM120-321v-318q0-22 10.5-40t29.5-29l280-161q10-5 19.5-8t20.5-3q11 0 21 3t19 8l280 161q19 11 29.5 29t10.5 40v159h-80v-116L479-434 200-596v274l240 139v92L160-252q-19-11-29.5-29T120-321ZM720-80q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm-20-80h40v-160h-40v160ZM720 0q-83 0-141.5-58.5T520-200q0-83 58.5-141.5T720-400q83 0 141.5 58.5T920-200q0 83-58.5 141.5T720 0ZM480-491Z" fill={theme.colors.friday} /></svg>
            <h3>{t('Limite por Usuário')}:</h3>
            <h3 style={{ color: theme.colors.fourth }}>{itemSelected.LimitPerUser}</h3>
          </div>
        </div>}

        {renderDetailsChallenge()}

        <div className='boxTextInfo'>
          <div className='boxTextInfoTop'>
            <Inventory2 className="boxIconInfo" />
            <h3>{t('Quantidade em Estoque')}:</h3>
            <h3 style={{ color: theme.colors.seventh }}>{itemSelected.Count} {t('un')}.</h3>
          </div>
        </div>

        <div className='boxTextInfo'>
          <div className='boxTextInfoTop'>
            <Info className="boxIconInfo" />
            <h3>{t('Descrição do produto')}:</h3>
          </div>

          <p className='textDescription'>{itemSelected.Description}</p>
        </div>

        <div className='boxTextInfo'>
          <div className='boxTextInfoTop'>
            <AccessTimeFilled className="boxIconInfo" />
            <h3>{t('Data de expiração')}:</h3>
            <h3 style={{ color: theme.colors.fourth }}>{itemSelected.ExpirationDate ? itemSelected.ExpirationDate : '-'}</h3>
          </div>
        </div>

        <div className='boxTextInfo'>
          <div className='boxTextInfoTop'>
            <CalendarMonth className="boxIconInfo" />
            <h3>{t('Período de Retirada')}:</h3>
            <h3 style={{ color: theme.colors.fourth }}>{itemSelected.WithdrawalDeadline ? Number(itemSelected.WithdrawalDeadline) : '-'} {t(`dias`)}</h3>
          </div>
        </div>

        <div className='boxTextInfo'>
          <div className='boxTextInfoTop'>
            <Handshake className="boxIconInfo" />
            <h3>{t('Patrocinado por')}:</h3>
            <h3 style={{ color: theme.colors.black4 }}>{String(itemSelected.SponsoredBy)?.length > 0 ? itemSelected.SponsoredBy : '-'}</h3>
          </div>
        </div>
      </div>

      {isSm && <div className='boxButtonsDetails'>
        <ButtonMaterial
          style={{ borderColor: theme.colors.secundary, marginRight: '16px' }}
          variant="outlined"
          label={t('EDITAR')}
          icon={<Edit style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.secundary }} />}
          onClick={() => {
            retrieveProductDetails(itemSelected, true)
          }}
        />

        <ButtonMaterial
          style={{ borderColor: itemSelected.Enabled ? theme.colors.graphicRed : theme.colors.graphicGreen, color: itemSelected.Enabled ? theme.colors.graphicRed : theme.colors.graphicGreen }}
          variant="outlined"
          label={itemSelected.Enabled ? t('DESATIVAR') : t('ATIVAR')}
          icon={itemSelected.Enabled ? <VisibilityOff style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.graphicRed }} /> : <Visibility style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.graphicGreen }} />}
          onClick={() => handleDisableOrEnableProduc(itemSelected)}
        />
      </div>}
    </S.ContainerDetails>
  };

  const registerEditData = (item: IProduct) => {
    setUrlImage(item.ImageUrl)
    setRegistration({
      name: item.Name,
      details: item.Description,
      availableQuantity: String(item.Count),
      exchangeScore: String(item.Points),
      limitPerUser: String(item.LimitPerUser),
      sponsored: String(item.SponsoredBy),
      expirationDate: String(item.ExpirationDate),
      withdrawalDeadline: String(item.WithdrawalDeadline),
      minimumScore: String(item.MinPoints),
      maximumScore: String(item.MaxPoints),
      alternativePunctuation: String(item.AlternativePoints),
    })
    setRegistrationError(new RegistrationError())
  };

  const renderDetailsChallenge = () => {
    return (itemSelected?.Type === 1 ? (
      <div className='boxTextInfo'>
        <div className='boxTextInfoTop'>
          <Sell className="boxIconInfo" />
          <h3>{t('Custo')}:</h3>
          <h3 style={{ color: theme.colorsBase.green2 }}>{itemSelected.Points} {t('pontos')}</h3>
          <h1 className='score'>({t('por unidade')})</h1>
        </div>
      </div>
    ) : (
      <div className='boxTextInfo'>
        <button className='boxTextInfoTop' onClick={() => itemSelected?.ChallengeName ? navigate(`/desafio/${itemSelected?.ChallengeId}`) : null}>
          <Sell className="boxIconInfo" />
          <h3>{t('Desafio')}:</h3>
          <h3 style={{ color: itemSelected?.ChallengeName ? theme.colors.seventh : theme.colors.graphicRed, textDecoration: itemSelected?.ChallengeName ? 'underline' : 'none' }}>{itemSelected?.ChallengeName ? `${itemSelected?.ChallengeName} (ID:${itemSelected?.ChallengeId})` : t('Ainda não Atribuído')}</h3>
          {itemSelected?.ChallengeName && <OpenInNew style={{ fontSize: '16px', color: theme.colors.seventh, marginLeft: '2px' }} />}
        </button>
      </div>
    ))
  };

  const renderMobileOrDesktopTypeProduct = (type: number) => {
    return (
      <>
        {isSm ?
          renderMobileOrDesktopTypeProductMobile(type)
          :
          renderMobileOrDesktopTypeProductDesktop(type)
        }
      </>
    )
  };

  const renderMobileOrDesktopTypeProductMobile = (type: number) => {
    return (type === 2 ? <Tooltip title={t('Atribuído à Desafio')}><LocalPlay className="i" style={{ color: theme.colors.black4 }} /></Tooltip> : <Tooltip title={t('Troca de Pontos')}><Cached className="i" style={{ color: theme.colors.black4 }} /></Tooltip>)
  };

  const renderMobileOrDesktopTypeProductDesktop = (type: number) => {
    return (type === 2 ? t('Atribuído à Desafio') : t('Troca de Pontos'))
  };

  return (
    <Table>
      <table>
        <tr className="header">
          <td style={{ paddingLeft: 16 }}>{t('ID')}</td>
          <td>{t('Nome')}</td>
          <td>{isSm ? t('Tipo') : t('Tipo de produto')}</td>
          <td>{isSm ? t('Qnt.') : t('Quantidade disponível')}</td>
          <td>{isSm ? t('Detalhes') : t('Ações')}</td>
        </tr>
        {listUsers.map((item) => (
          <tr key={item?.Id}>
            <td style={{ paddingLeft: 16 }}>{item?.Id}</td>
            <td>{item?.Name}</td>
            <td>{renderMobileOrDesktopTypeProduct(item?.Type ?? 1)}</td>
            <td>{item?.Count}</td>
            <td style={{ width: '20px' }}>
              {!isSm ? (
                <Icons>
                  <button onClick={() => {
                    retrieveProductDetails(item, false)
                  }}>
                    <Tooltip title={t('Ver Detalhes')}>
                      <Info className="i" />
                    </Tooltip>
                  </button>

                  <button onClick={() => {
                    retrieveProductDetails(item, true)
                  }}>
                    <Tooltip title={t('Editar')}>
                      <Edit style={{ marginLeft: '24px' }} className="i" />
                    </Tooltip>
                  </button>

                  <button onClick={() => handleDisableOrEnableProduc(item)}>
                    <Tooltip title={item.Enabled ? t('Ativado. Clique para desativar') : t('Desativado. Clique para ativar')}>
                      {item.Enabled ? <Visibility style={{ marginLeft: '24px', color: theme.colors.friday }} className="i" /> : <VisibilityOff style={{ marginLeft: '24px', color: theme.colors.graphicRed }} className="i" />}
                    </Tooltip>
                  </button>
                </Icons>
              ) : (
                <Icons>
                  <button onClick={() => {
                    retrieveProductDetails(item, false)
                  }}>
                    <Tooltip title={t('Detalhes')}>
                      <Info className="i" />
                    </Tooltip>
                  </button>
                </Icons>
              )}
            </td>
          </tr>
        ))}
      </table>

      {isSm ?
        <ModalCustomBottom
          open={openDetails}
          onClose={() => {
            setOpenDetails(false);
          }}
          onClick={() => { }}
          loading={loading}
          title={t('Detalhes do Produto')}
          buttonClose
          buttonsEnabled={false}
          childrenContent={returnDetails()}
          icon={<Info style={{ fontSize: '24px', color: theme.colorsBase.gray3, backgroundColor: theme.colorsBase.white }} />}
        />
        :
        <ModalCustom
          open={openDetails}
          onClose={() => {
            setOpenDetails(false);
          }}
          onClick={() => { }}
          loading={loading}
          title={t('Detalhes do Produto')}
          largeTitle
          childrenContent={returnDetails()}
          buttonsEnabled={false}
          styleLine={{ backgroundColor: 'transparent', marginBottom: '24px' }}
          iconHeader={<Info style={{ fontSize: '24px', color: theme.colorsBase.gray3, backgroundColor: theme.colorsBase.white }} />}
        />
      }

      {isSm ?
        <ModalCustomBottom
          open={openEdit}
          onClose={() => {
            setOpenEditCancelModal(true)
          }}
          onClick={handleEditProduct}
          loading={loading}
          title={t('Edição de produto')}
          confirmationButtonText={t('SALVAR')}
          textDeclineButton={t('CANCELAR')}
          showIcon
          buttonClose
          buttonsEnabled
          styleButtons={{ justifyContent: 'center' }}
          childrenContent={returnEdit()}
          icon={<Shop style={{ fontSize: '24px', color: theme.colorsBase.gray3, backgroundColor: theme.colorsBase.white }} />}
        />
        :
        <ModalCustom
          open={openEdit}
          onClose={() => {
            setOpenEditCancelModal(true)
          }}
          onClick={handleEditProduct}
          loading={loading}
          title={t('Edição de produto')}
          largeTitle
          confirmationButtonText={t('SALVAR')}
          textDeclineButton={t('CANCELAR')}
          styleButtons={{ justifyContent: 'flex-end' }}
          childrenContent={returnEdit()}
          styleLine={{ backgroundColor: 'transparent', marginBottom: '24px' }}
          iconHeader={<Shop style={{ fontSize: '24px', color: theme.colorsBase.gray3, backgroundColor: theme.colorsBase.white }} />}
        />
      }

      {isSm ?
        <ModalCustomBottom
          open={openEditCancelModal}
          onClose={() => setOpenEditCancelModal(false)}
          onClick={() => clearEditData()}
          loading={loading}
          title={t('Cancelar Edição')}
          confirmationButtonText={t('CANCELAR')}
          textDeclineButton={t('VOLTAR')}
          showIcon={false}
          buttonClose
          buttonsEnabled
          styleButtons={{ justifyContent: 'center' }}
          redButtonRight
          description={t('Você deseja cancelar a edição desse produto? Qualquer alteração não será salva.')}
        />
        :
        <ModalCustom
          open={openEditCancelModal}
          onClose={() => setOpenEditCancelModal(false)}
          onClick={() => clearEditData()}
          loading={loading}
          title={t('Cancelar Edição')}
          styleButtons={{ justifyContent: 'center' }}
          confirmationButtonText={t('CANCELAR')}
          textDeclineButton={t('VOLTAR')}
          redButtonRight
          shortModal
          description={t('Você deseja cancelar a edição desse produto? Qualquer alteração não será salva.')}
        />
      }

      {isSm ?
        <ModalCustomBottom
          open={openDisableProductModal}
          onClose={() => setOpenDisableProductModal(false)}
          onClick={() => handleDisable(itemSelected.Id ?? '')}
          loading={loading}
          title={t('Desativar Produto')}
          confirmationButtonText={t('DESATIVAR')}
          textDeclineButton={t('CANCELAR')}
          showIcon={false}
          buttonClose
          buttonsEnabled
          styleButtons={{ justifyContent: 'center' }}
          redButtonRight
          description={t('Este produto está associado a um desafio especial que oferece premiação.  Ao desativar o produto, o desafio será desvinculado e deixará de oferecer a premiação especial, tornando-se um desafio comum e oferecerá a pontuação alternativa como recompensa. Deseja prosseguir?')}
        />
        :
        <ModalCustom
          open={openDisableProductModal}
          onClose={() => setOpenDisableProductModal(false)}
          onClick={() => handleDisable(itemSelected.Id ?? '')}
          loading={loading}
          title={t('Desativar Produto')}
          styleButtons={{ justifyContent: 'center', marginTop: '0px' }}
          confirmationButtonText={t('DESATIVAR')}
          textDeclineButton={t('CANCELAR')}
          redButtonRight
          shortModal
          description={t('Este produto está associado a um desafio especial que oferece premiação.  Ao desativar o produto, o desafio será desvinculado e deixará de oferecer a premiação especial, tornando-se um desafio comum e oferecerá a pontuação alternativa como recompensa. Deseja prosseguir?')}
        />
      }

      {isSm ?
        <ModalCustomBottom
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          onClick={() => setOpenEditModal(false)}
          title={t('Edição de Produto')}
          textDeclineButton={t('VOLTAR')}
          showIcon={false}
          buttonClose
          buttonsEnabled
          buttonRight={false}
          styleButtons={{ justifyContent: 'center' }}
          description={t('Produto atualizado com sucesso.')}
        />
        :
        <ModalCustom
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          onClick={() => setOpenEditModal(false)}
          title={t('Edição de Produto')}
          styleButtons={{ justifyContent: 'center' }}
          textDeclineButton={t('VOLTAR')}
          buttonRight={false}
          shortModal
          description={t('Produto atualizado com sucesso.')}
        />
      }
    </Table>
  )

}

export default TableProduct
