import React, { useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDropDown, ArrowDropUp, Close, } from '@mui/icons-material'
import * as S from './styles'

interface IProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  listSelected: any[]
  /* eslint-disable @typescript-eslint/no-explicit-any */
  list: any[]
  label: string
  onClickAdd: (e: string) => void
  onClickRemove: (e: string) => void
  error?: boolean
  resetError?: () => void
}

const SelectMultiple: React.FC<IProps> = ({ listSelected, list, label, onClickAdd, onClickRemove, error, resetError }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <S.Container onClick={resetError} active={error}>
      <div className='containerTop'>
        <div className='boxTop'>
          <h1 className='label'>{label}</h1>

          {listSelected.length > 0 ?
            <div className='boxSelected'>
              {listSelected.map((item) => (
                <S.ButtonClose key={item?.Id} onClick={() => onClickRemove(item?.Id)}>
                  <h1 className='title'>{item?.Name}</h1>
                  <Close className="i" />
                </S.ButtonClose>
              ))}
            </div>
            : <h1 className='noElementsSelected'>-</h1>}
        </div>

        <div className='boxRight'>
          <button className='buttonModalB' onClick={() => setModalOpen(!modalOpen)}>
            {!modalOpen ? <ArrowDropDown className="iconB" /> : <ArrowDropUp className="iconB" />}
          </button>
        </div>
      </div>

      {modalOpen &&
        <div className='containerOptions'>
          {list.map((item) => (
            <div className='boxOptions' key={item?.Id}>
              <h1 className='text'>{item?.Name}</h1>

              <S.ButtonAdd onClick={() => onClickAdd(item?.Id)} active={!listSelected.filter((itemX) => String(itemX.Id) === String(item.Id))[0]?.Name}>
                <h1 className='title'>{!listSelected.filter((itemX) => String(itemX.Id) === String(item.Id))[0]?.Name ? t('Adicionar') : t('Adicionado')}</h1>
              </S.ButtonAdd>
            </div>
          ))}
        </div>
      }
    </S.Container>
  )
}

export default SelectMultiple;
