import React from 'react'
import logo from '../../assets/images/logo.png'
import curva from '../../assets/images/curva1.png'
import * as S from './styles'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';

interface IProps {
  description?: boolean
}

const Footer: React.FC<IProps> = ({ children, description = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()

  return (
    <S.ContainerContents>
      <img src={curva} className="curva" alt='' />

      <S.ContentsContent style={{ padding: description ? '56px 24px 24px 24px' : '106px 24px 24px 24px'}}>
        {children ?? (
          <>
            <img src={logo} alt='' />
            {description &&
              <h2>
                {t('Curar Saúde é uma plataforma gamificada de educação em saúde que oferece')}
                <br />
                {t('conteúdo com curadoria de profissionais renomados e premia o usuário com')}
                <br />
                {t('acesso facilitado a serviços de saúde de baixa complexidade.')}
              </h2>
            }
          </>
        )}

        <div>
          <button onClick={() => navigate(`/termos_e_politica/termos`)}>{t('Termos de Uso')}</button>
          <button onClick={() => navigate(`/termos_e_politica/politica`)}>{t('Política de Privacidade')}</button>
        </div>

        <h5>{t('Copyright ©')} {new Date().getFullYear()} {t('- Todos direitos reservados.')}</h5>
      </S.ContentsContent>
    </S.ContainerContents>
  )
}

export default Footer
