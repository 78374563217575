import React, { useState } from 'react';

import logo from '../../assets/images/logo.png'
import curvasUnidas from '../../assets/images/curvasUnidas.png'
import curva2 from '../../assets/images/curva2.png'
import curva5 from '../../assets/images/curva5.png'
import benefit1 from '../../assets/images/benefit1.png'
import benefit2 from '../../assets/images/benefit2.png'
import benefit3 from '../../assets/images/benefit3.png'
import trophy from '../../assets/images/trophy.png'
import ButtonMaterial from '../../components/Button';
import SelectLanguage from '../../components/SelectLanguage';
import { PlayCircleOutline } from '@mui/icons-material'
import Footer from '../../components/Footer'
import { dataChallenges, dataContent } from './information'
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { theme } from '../../styles/theme';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './styles'
import ScrollToTopButton from '../../components/ScrollToTopButton';
import { useIsSm } from '../../hooks/useIsSm';

const LandingPage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [result, setResult] = useState([0]);
  const [resultB, setResultB] = useState([0]);
  const [resultC, setResultC] = useState([0]);
  const isSm = useIsSm()

  return (
    <S.Container id='navbar'>
      <S.ContainerHeader>
        <img src={isSm ? curva5 : curva2} className="curva" alt="" />
        <S.Header>
          <button onClick={() => navigate(`/`)}>
            <img src={logo} alt="" />
          </button>
          <S.ButtonHeader>
            <SelectLanguage />
            <ButtonMaterial style={{ marginLeft: '8px', border: `solid 1px ${theme.colors.primary}` }} variant="outlined" label={t('ENTRE OU CADASTRE-SE')} onClick={() => navigate(`/login`)} />
          </S.ButtonHeader>
        </S.Header>

        <S.TextHeader>
          <p>{t(`A troca de conhecimentos de igual para igual.`)}<br />{t(`Compartilhe conhecimento e aprenda a se cuidar com conteúdos`)}<br />{t(`checados pelos melhores profissionais de saúde.`)}<br />{t(`Some pontos e ganhe prêmios!`)}</p>
        </S.TextHeader>
      </S.ContainerHeader>

      <S.ContainerBenefits>
        <S.Sphere>
          <img src={benefit1} className="image" style={{ width: '210px' }} alt="" />
          <h1>{t(`Dicas de saúde`)}<br />{t(`fáceis de entender`)}</h1>
        </S.Sphere>

        <S.Sphere sphere={2}>
          <img src={benefit2} className="image" alt="" />
          <h1>{t(`Revisão pelos melhores`)}<br />{t(`profissionais da saúde`)}</h1>
        </S.Sphere>

        <S.Sphere sphere={3}>
          <img src={benefit3} className="image" style={{ width: '150px' }} alt="" />
          <h1>{t(`Troca de pontos por`)}<br />{t(`recompensas`)}</h1>
        </S.Sphere>
      </S.ContainerBenefits>

      <S.ContainerContents>
        <img src={curvasUnidas} className="curvab" alt="" />

        <S.ContentsContent>
          <h2>{t(`Conteúdos rápidos do jeito que a gente entende!`)}</h2>

          <S.CardsGrid>
            {dataContent.map((item) => (
              <S.CardContent key={item.name}>
                <button className="thumbnail" onClick={() => navigate(`/home`)}>
                  <img src={item.img} className="image" alt="" />
                  <div className="box" />
                  <PlayCircleOutline className="icon" />
                </button>
                <h3>{t(item.name)}</h3>
              </S.CardContent>
            ))}
          </S.CardsGrid>
        </S.ContentsContent>

        <S.ChallengesContent>
          <h2>{t(`Desafios para checar se você entendeu tudo e ganhar pontos`)}</h2>

          <S.ChallengesGrid>
            {dataChallenges.map((item) => (
              <S.CardChallenges key={item.id}>
                <img src={item.img} className="thumbnail" alt="" />
                <div>
                  <h3>{t(item.name)}</h3>
                  <div className="options">
                    <div>
                      {item.alternatives.map((item,) => (
                        <button key={item.id} className='alternative' onClick={() => {
                          setResultC([item.id, ...resultC])
                          if (!resultC.includes(item.id) && !resultB.includes(item.id)) {
                            setResult([item.value, ...result])
                          }
                        }}>
                          {(resultB.includes(item.id)) ? (
                            < FormControlLabel className="option" checked={result.includes(item.value) || (resultB.includes(item.id) && item.correct)} control={<Radio color={item.correct ? "success" : "error"} />} label={t(item.text)} />
                          ) : (
                            < FormControlLabel className="option" checked={result.includes(item.value)} control={<Radio />} label={t(item.text)} />
                          )}
                        </button>
                      ))}
                    </div>
                    <ButtonMaterial
                      style={{ backgroundColor: `${resultB.includes(item.id) ? theme.colors.secundary : '#5080FF'}` }}
                      variant="contained"
                      label={resultB.includes(item.id) ? t("VER MAIS") : t("VER RESPOSTA")}
                      onClick={() => {
                        resultB.includes(item.id) ? navigate(`/home`) : setResultB([item.id, ...resultB])
                      }} />
                  </div>
                </div>
              </S.CardChallenges>
            ))}
          </S.ChallengesGrid>
        </S.ChallengesContent>
      </S.ContainerContents>

      <S.ContainerAwards>
        <h2>{t(`Troque seus pontos por prêmios incríveis que te ajudam a manter a saúde`)}</h2>
        <img src={trophy} alt="" />
        <h3>{t(`Vale-Brindes Especiais`)}</h3>
      </S.ContainerAwards>

      {/* <AdvanceRegistration /> */}

      <Footer />

      <ScrollToTopButton />
    </S.Container >
  )
}

export default LandingPage
