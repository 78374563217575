import { useMutation } from "@tanstack/react-query";

import { sendRequest } from "../../../services/RequestService";

import { IChalengeManagementApprovalCommand, IChalengeManagementApprovalCommandResponse } from "./types";

const defaultRoot = "challenges-management/approvals"

export const useChalengeManagementApprovalMutation = () => useMutation({
  mutationFn: async ({ command, id }: IChalengeManagementApprovalCommand) => {
    const url = `${defaultRoot}/${id}`

    const tempCommandTags: string[] = [];

    command?.Tags?.map((item) => {
      tempCommandTags.push(item.trim())
    })

    return await sendRequest<IChalengeManagementApprovalCommandResponse>(url, 'POST', { ...command, Tags: tempCommandTags })
  }
})
