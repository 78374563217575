import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'
import { theme } from '../../styles/theme';


export const ContainerModal = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  left: 50%;
  position: absolute;
  width:  ${(props) => (props.active ? '480px' : '720px')}; 
  max-height: 90%;
  overflow:auto; 
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 8px;
  box-shadow: 24;
  padding: 24px;

  &::-webkit-scrollbar {
    width: 8px;
    background: ${(props) => props.theme.colorsBase.white};
    }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colorsBase.gray5};
    border-radius: 8px;
  }
  ::-webkit-scrollbar:horizontal { 
    height: 4px;
  }

  h4 {
    margin: 0 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    margin-bottom: 40px;
  }

  .icon {
    color: ${(props) => props.theme.colorsBase.black};
    width: 20px;
    height: 20px;
    top: 24px;
    right: 24px;
    position: absolute;
  }

  .buttonClose {
    display: flex;
    position: absolute;
    top: 0px;
    right: 0px;
    align-items: center;
    flex-direction: row;
    border: none;
    background-color: transparent;
  
    &:hover {
      opacity: 0.8;
    }

    .icon {
      color: ${(props) => props.theme.colorsBase.black};
      width: 16px;
      height: 16px;
    }
  }
 
  .buttonAcceptedTerm {
    display: flex;
    align-items: center;
  
    flex-direction: row;
    border: none;
    background-color: transparent;
  
    &:hover {
      opacity: 0.8;
    }

    .iconAcceptedTerm {
      color: ${(props) => props.theme.colors.fifteen};
      width: 24px;
      height: 24px;
    }

    .iconAcceptedTermBlank {
      color: ${(props) => props.theme.colors.fourteen};
      width: 24px;
      height: 24px;
    }

    h1 {
      margin-left: 8px;
      line-height: 20px;
      letter-spacing: 0.25px;
      text-align: center;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Medium;
    }
  }

  .button {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
  }

  @media (min-width: 1600px) {
    width:  ${(props) => (props.active ? '480px' : '900px')}; 

    h5 {
      line-height: 24px;
      font-size: ${responsiveFont(20)};
    }
  }

  @media (max-width: 680px) {
    width: 95%;

    .button {
      justify-content: center;
    }

    h4 {
      margin-bottom: 16px;
    }
  }
`

export const Line = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 0.5px;
  background-color: ${(props) => props.theme.colorsBase.gray8};
  margin: 24px 0 48px 0;

  h5 {
    line-height: 18px;
    text-align: left;
    font-size: ${(props) => (props.active ? responsiveFont(20) : responsiveFont(14))};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    background-color: ${(props) => props.theme.colorsBase.white};
    padding: 0px 8px;

    @media (min-width: 1600px) {
      font-size: ${(props) => (props.active ? responsiveFont(22) : responsiveFont(16))};
    }
  }
`


export const ContainerStepIndicators = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepIndicator = styled.div<{ active: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? theme.colors.primary : theme.colorsBase.gray3)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ active }) => (active ? '#fff' : theme.colorsBase.gray2)};
  margin: 0 8px;
  font-size: 14px;
`;

export const ButtonContainer = styled.div<{ step: number }>`
  display: grid;
  width: 100%;
  margin-top: 2rem;
  align-items: center;

  .leftButtons {
    grid-column: '1 / span 1';
    visibility: ${({ step }) => (step > 1 ? 'visible' : 'hidden')};
  }

  .stepIndicators {
    grid-column: 2 / span 1;
    display: flex;
    justify-content: center;
  }

  .actionButtons {
    grid-column: 3 / span 1;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  @media (max-width: 680px) {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin: 8px 0px;
    align-items: center;
    padding: 0px 8px;
    gap: 16px;
  }
`;
