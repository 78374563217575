import React from 'react';
import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import { KeyboardArrowRight, SvgIconComponent } from '@mui/icons-material';

interface IProps {
  title: string;
  icon: SvgIconComponent,
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const Item: React.FC<IProps> = ({ title, icon: Icon, onClick }) => (
  <Grid item xs={1}>
    <Card>
      <CardActionArea onClick={onClick}>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Icon fontSize='large' sx={{ color: "#5080FF" }} />
          <Typography gutterBottom variant="subtitle2" component="div" sx={{ marginRight: "auto", marginLeft: '30px' }}>
            {title}
          </Typography>
          <KeyboardArrowRight fontSize='medium' sx={{ color: "#707070" }} />
        </CardContent>
      </CardActionArea>
    </Card>
  </Grid>
)

export default Item
