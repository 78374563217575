import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const ContainerContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
  position: relative;

  .curva {
    display: flex;
    position: absolute;
    width: 100%;
    height: 300px;
    object-fit: cover;

    @media (max-width: 680px) {
      height: 350px;
    }
  
    @media (min-width: 1600px) {
      height: 430px;
    }
  }
`

export const ContentsContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  padding: 56px 24px 24px 24px;

  img {
    width: 200px;
    object-fit: cover;
  }

  h2 {
    line-height: 18px;
    letter-spacing: 0.18px;
    text-align: center;
    font-size: ${responsiveFont(12)};
    margin-top: 24px;
    margin-bottom: 16px;
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 300;
    font-family: 'Barlow', Medium;
  }

  div {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: center;

    button {
      text-decoration: underline;
      margin: 0px 8px;
      line-height: 18px;
      letter-spacing: 0.18px;
      text-align: center;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Medium;
      background-color: transparent;
      border: none;
    }
  }

  h5 {
    line-height: 18px;
    letter-spacing: 0.18px;
    text-align: center;
    font-size: ${responsiveFont(12)};
    margin-top: 16px;
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Medium;
  }

  @media (min-width: 1600px) {
    padding: 80px 24px 24px 24px;

    h2 {
      line-height: 24px;
      font-size: ${responsiveFont(14)};
      margin-top: 32px;
      margin-bottom: 24px;
    }
  
    div {
      a {
        margin: 0px 16px;
        font-size: ${responsiveFont(20)};
      }
    }
  
    h5 {
      font-size: ${responsiveFont(14)};
      margin-top: 32px;
    }
  }

  @media (max-width: 680px) {
    padding: 40px 24px 24px 24px;

    img {
      width: 160px;
    }
  }
`
