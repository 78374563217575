import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const ContainerTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  height: 100%;

  .containerButton {
    display: flex;
    align-self: flex-end;
    margin-top: -45px;
    margin-right: 16px;
    margin-bottom: 16px;
  }
  
  @media (max-width: 680px) {
    .containerButton {
      width: 100%;
      margin: 0;
      padding: 12px 40px 0px 40px;
    }
  }
`
