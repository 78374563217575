/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import * as S from '../ChallengeItemDesktop/styles';
import { ISpecialProgramDetailCard } from './types';
import Stack from '@mui/material/Stack';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SpeedIcon from '@mui/icons-material/Speed';
import { theme } from '../../styles/theme';
import Chip from '@mui/material/Chip';
import ButtonMaterial from '../Button';
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../hooks/useIsSm';
import { StackedBarChart } from '@mui/icons-material';
import { Box, LinearProgress } from '@mui/material';

const SpecialProgramDetailCard: React.FC<ISpecialProgramDetailCard> = ({ specialProgram, handleFinalizeContent, handleEdit, typePermission, triage, noShadow, participant }) => {
  const { t } = useTranslation();
  const isSm = useIsSm();

  const allowEdit = (typePermission === 6 || typePermission === 5);

  const validateContentsActive = () => {
    let resultNegative = false;

    specialProgram?.Challenges?.map((e) => {
      if (e.ChallengeStatus !== 5) {
        resultNegative = true;
      }
    })
    return !resultNegative
  };


  const validateHeight = () => {
    if (typePermission === 5) return '400px';
    if (typePermission === 6) return '550px';
    return '100px';
  };

  return (
    <S.ContainerSpecialDiv1 style={{ minHeight: isSm ? '100px' : validateHeight() }} noShadow={noShadow}>
      <div className='topBox'>
        <S.ContainerSpecialDiv2>
          <img src={specialProgram.image} alt="Special Program Cover" style={{ aspectRatio: '13/9' }} />

          {participant && isSm && <S.ContainerSpecialDiv3 className='description' style={{ marginTop: '8px' }}>Descrição: {specialProgram.description}</S.ContainerSpecialDiv3>}

          <ul>
            {(specialProgram?.ResumeSpecialProgramStatus === 2 || specialProgram?.ResumeSpecialProgramStatus === 3) ? <li>{<StackedBarChart style={{ fontSize: '14px' }} />}  Desempenho: <span style={{ width: '100%', margin: 'auto 0', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', paddingRight: '4px' }}>
                <LinearProgress variant="determinate" value={Number(specialProgram?.PercentageOfCorrectAswers) * 100} style={{ borderRadius: '8px' }} color="info" />
              </Box>
              {Number(specialProgram?.PercentageOfCorrectAswers) * 100}%
            </span></li> : <li>{<SpeedIcon style={{ fontSize: '14px' }} />}  Média de aproveitamento: <span>{specialProgram.averagePerformance}%</span></li>}

            <li>{<LocalOfferIcon style={{ fontSize: '14px' }} />}Tempo médio de conclusão: <span>{specialProgram?.DurationTime ? specialProgram?.DurationTime : '0'} min</span></li>
            <li>{<AccessTimeFilledIcon style={{ fontSize: '14px' }} />} Tags: <Stack direction="row" spacing={1}>{specialProgram.tags?.split(',').map((tag: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined, index: React.Key | null | undefined) => (
              <Chip key={index} label={tag} size="small" color="default" />
            ))}</Stack></li>
          </ul>
        </S.ContainerSpecialDiv2>

        {(participant ? !isSm : true) && <S.ContainerSpecialDiv3 className='description' style={{ marginBottom: (isSm && triage) ? '16px' : '0px' }}>Descrição: {specialProgram.description}</S.ContainerSpecialDiv3>}
      </div>

      {allowEdit && !triage && < S.ContainerSpecialDiv4 >
        <ButtonMaterial
          style={{ borderColor: theme.colors.secundary }}
          variant="outlined"
          label={t('Editar')}
          onClick={handleEdit}
        />

        {typePermission !== 5 && specialProgram?.status !== 'approved' && Number(specialProgram?.Challenges?.length) > 0 && <ButtonMaterial
          style={{ borderColor: validateContentsActive() ? theme.colors.secundary : theme.colors.black5, color: validateContentsActive() ? theme.colors.secundary : theme.colors.black5, marginLeft: '16px' }}
          variant="outlined"
          label={t('Finalizar Inserção do Conteúdo')}
          onClick={handleFinalizeContent}
        />}
      </S.ContainerSpecialDiv4>}
    </S.ContainerSpecialDiv1 >
  );
};

export default SpecialProgramDetailCard;
