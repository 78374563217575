import React from 'react';
import { ArrowBack, FilterList } from '@mui/icons-material';
import * as S from './styles'
import { useTranslation } from 'react-i18next';
import { theme } from '../../styles/theme';
import { useIsSm } from '../../hooks/useIsSm';
import { useNavigate } from 'react-router-dom';
interface IProps {
    title: string;
    description?: string;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    icon?: any;
    goBackTo?: number;
    buttonBack?: boolean;
    onClickButtonRight?: () => void;
    colorTitle?: string;
    noMargin?: boolean;
    buttonFilter?: boolean;
    headerMobileAll?: boolean;
    onClick?: () => void;
    marginBottom?: number;
    hideTitle?: boolean;
    /* eslint-disable @typescript-eslint/ban-types */
    style?: Object;
}

const HeaderAchievements: React.FC<IProps> = ({ title, description, goBackTo = -1, icon, buttonBack = true, onClick, onClickButtonRight, colorTitle, style, noMargin = false, buttonFilter = false, headerMobileAll = false, marginBottom, hideTitle = false }) => {
    const { t } = useTranslation();
    const isSm = useIsSm()
    const navigate = useNavigate()

    return (
        <>
            {((headerMobileAll ? true : !isSm) && buttonBack) &&
                <S.ContainerBackButton onClick={() => onClick ? onClick() : navigate(goBackTo)} >
                    <ArrowBack className="iconBackButton" />
                    <h5>{t('Voltar')}</h5>
                </S.ContainerBackButton>
            }

            <S.ContainerTitle active={!buttonBack} noMargin={noMargin} margin={marginBottom} style={style}>
                <div className='cont'>
                    {((headerMobileAll ? false : isSm) && buttonBack) &&
                        <S.ContainerBackMobile onClick={() => onClick ? onClick() : navigate(goBackTo)} >
                            <ArrowBack className="iconBackButton" />
                        </S.ContainerBackMobile>
                    }

                    {!hideTitle &&
                        <S.Line active={!buttonBack}>
                            {icon && <div>{icon}</div>}
                            <h5 style={{ color: colorTitle ?? theme.colors.black }}>{title}</h5>
                        </S.Line>
                    }

                    {(isSm && !buttonBack && buttonFilter) &&
                        <S.ContainerButtonRightMobile onClick={onClickButtonRight} >
                            <FilterList className="iconBackButton" />
                        </S.ContainerButtonRightMobile>
                    }
                </div>

                {description && <h3>{description}</h3>}
            </S.ContainerTitle>
        </>
    )
}

export default HeaderAchievements
