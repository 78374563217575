import * as yup from 'yup';
import { verifyClassEmpty } from '../../../utils/ClassEmpty';
import { genericValidation, ValidationData, } from '../../../utils/ValidationGenerics';
import { Registration, RegistrationError } from '../TableProduct/types';

export const registerValidation = async (object: Registration): Promise<true | Registration> => {
  const schemaRegister = yup.object().shape({
    name: yup.string().required(),
    details: yup.string().required(),
    availableQuantity: yup.string().required(),
    exchangeScore: yup.string().required(),
    limitPerUser: yup.string().required(),
    expirationDate: yup.string().required(),
    withdrawalDeadline: yup.string().required(),
  });

  const mountError = await genericValidation<Registration, RegistrationError>(schemaRegister, object, new Registration());

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};

export const registerValidationChallenge = async (object: Registration): Promise<true | Registration> => {
  const schemaRegister = yup.object().shape({
    name: yup.string().required(),
    details: yup.string().required(),
    availableQuantity: yup.string().required(),
    alternativePunctuation: yup.string().required(),
    expirationDate: yup.string().required(),
    withdrawalDeadline: yup.string().required(),
  });

  const mountError = await genericValidation<Registration, RegistrationError>(schemaRegister, object, new Registration());

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};

export function ProductDateValidation(expirationDate: string, maxWithdrawalDate: string) {
  const expirationResult = ValidationData(expirationDate);
  if (!expirationResult.status) {
    return expirationResult;
  }

  const withdrawalResult = ValidationData(maxWithdrawalDate);
  if (!withdrawalResult.status) {
    return withdrawalResult;
  }

  const currentDate = new Date();

  if (parseDate(maxWithdrawalDate) <= currentDate) {
    return { msg: "A data máxima de retirada deve ser maior que a data de hoje", status: false };
  }

  if (parseDate(maxWithdrawalDate) >= parseDate(expirationDate)) {
    return { msg: "A data máxima de retirada deve ser menor que a data de expiração", status: false };
  }

  return { msg: "", status: true };
}

function parseDate(dateString: string): Date {
  const day = parseInt(dateString.substring(0, 2));
  const month = parseInt(dateString.substring(2, 4)) - 1;
  const year = parseInt(dateString.substring(4, 8));

  return new Date(year, month, day);
}