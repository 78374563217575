import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const ContainerEditCategories = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 60%;


  @media (max-width: 680px) {
    width: 100%;
  }
`

export const ItemLanguage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 8px;
  width: 100%;
  .flag {
    width: 24px;
    object-fit: cover;
  }

  .icon {
    width: 24px;
    object-fit: cover;
    color: ${(props) => props.theme.colorsBase.black};
  }

  h5 {
    margin-left: 8px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.fourth};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  } 

  @media (max-width: 680px) {
    padding: 4px 8px;
  }
`

export const ContainerLanguage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .boxMoreLanguages {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

  }

  @media (max-width: 680px) {
    flex-direction: column;
    margin-bottom: 12px;

    .boxMoreLanguages {
      display: none; 
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &:hover {
      .boxMoreLanguages {
         display: flex;
      }
    }
  }
`

export const ModalEnableCategory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  h5 {
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  } 

  b {
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 700;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }

  .boxButtonModal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 58px;
  }

  @media (max-width: 680px) {
  }
`