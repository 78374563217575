import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'


export const Select = styled.button<{ white?: boolean }>`
position: relative;
  display: flex;
  padding: 8px 10px;
  border: none;
  flex-direction: row;
  align-items: center;
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }

  h1 {
    font-weight: 500;
    letter-spacing: 1.25px;
    margin: 0px 8px;
    color: ${(props) => (props.white ? props.theme.colorsBase.white : props.theme.colors.black)};
    font-size: ${responsiveFont(14)};
    font-family: 'Barlow', Medium;
  }
`



export const Option = styled.button<{ white?: boolean }>`
  display: flex;
  width: 100%;
  border: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background: transparent;

  h5 {
    font-weight: 500;
    letter-spacing: 1.25px;
    margin-left: 8px;
    margin-top: auto;
    color: ${(props) => props.theme.colors.black};
    font-size: ${responsiveFont(14)};
    font-family: 'Barlow', Medium;
  }

  .flag {
    width: 24px;
    object-fit: cover;
  }

  @media (max-width: 680px) {
    width: 80%;
    padding: ${(props) => (props.white ? '8px 0px 8px 0px' : '0px 16px 0px 0px')};
    margin: 0;
  }
`