import React, { useState } from 'react';
import { ButtonProps } from './types'
import { Language, ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import iconEN from '../../assets/images/en.png'
import iconES from '../../assets/images/es.png'
import iconFR from '../../assets/images/fr.png'
import iconPT from '../../assets/images/pt.png'
import { useAuth } from '../../contexts/Auth';
import { useTranslation } from 'react-i18next';
import { theme } from '../../styles/theme';
import * as S from './styles'
import { Options, Container } from '../SelectLanguageEditUser/styles';

const SelectLanguage: React.FC<ButtonProps> = (props) => {
  const { colorWhite, colorPrimary } = props
  const { changeLanguage, languageSelected } = useAuth();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const data = [
    {
      img: (iconEN),
      name: 'EN',
      language: 'EN'
    },
    {
      img: (iconES),
      name: 'ES',
      language: 'ES'
    },
    {
      img: (iconFR),
      name: 'FR',
      language: 'FR'
    },
    {
      img: (iconPT),
      name: 'PT',
      language: 'PT'
    }
  ]

  const selectColorB = () => {
    return colorWhite ? theme.colorsBase.white : theme.colors.black;
  };

  const selectColor = () => {
    return colorPrimary ? theme.colors.secundary : selectColorB();
  };

  return (
    <Container>
      <S.Select onClick={() => setOpen(!open)} white={colorWhite}>
        <Language className="icon" style={{ color: selectColor() }} />
        <h1 style={{ color: selectColor() }}>{t(languageSelected ?? '')}</h1>
        {open ? <ArrowDropUp className="icon" style={{ color: selectColor() }} /> : <ArrowDropDown className="icon" style={{ color: selectColor() }} />}
      </S.Select>

      {open &&
        <Options white={colorWhite}>
          {data.map((item) => (
            <S.Option key={item.language} white={colorWhite} onClick={() => {
              changeLanguage(item.language)
              setOpen(false)
            }}>
              <img src={item.img} className="flag" alt="" />
              <h5>{t(item.name || '')}</h5>
            </S.Option>
          ))}
        </Options>
      }
    </Container>
  )
}

export default SelectLanguage
