import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonMaterial from '../../../components/Button';
import * as S from './styles'
import { IProductUser } from '../../../services/Product/types';
import { AccessTimeFilled, CalendarMonth, Done, Handshake, Info, Inventory2, KeyboardArrowDown, KeyboardArrowUp, Mail, OpenInNew, Sell, Shop, } from '@mui/icons-material';
import { theme } from '../../../styles/theme';
import { responsiveFont } from '../../../utils/UtilsGlobal';
import { useIsSm } from '../../../hooks/useIsSm';
import CardAchievements from '../../../components/CardAchievements';
import InputSelectNumber from '../../../components/InputSelectNumber';
import jwt_decode from 'jwt-decode'
import { useAuth } from '../../../contexts/Auth';
import ToastNotification from '../../../components/ToastNotification';
import { useNavigate } from 'react-router-dom';
import { DataMaskFormtData } from '../../../utils/Mask';

interface IProps {
  item?: IProductUser
  onClick?: () => void
  onClickCancel?: () => void
  pointsBalance?: number
  maximumQuantityOfProducts?: number
  setContentQuantidade: (e: boolean) => void
  contentQuantidade?: boolean
  setValue: (e: number) => void
  value: number
  setCheckOut: (e: boolean) => void
  checkOut?: boolean
  award?: boolean
}

const ViewExchangePointsForProducts: React.FC<IProps> = ({ award, item, onClick, onClickCancel, pointsBalance = 0, maximumQuantityOfProducts = 1, checkOut, setCheckOut, value, setValue, contentQuantidade, setContentQuantidade }) => {
  const { t } = useTranslation();
  const isSm = useIsSm()
  const { user } = useAuth();
  const [email, setEmail] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const payload: { Email: string } = jwt_decode(user?.Token ?? '')
    setEmail(payload.Email)
  }, [])

  const validationCount = (item: IProductUser) => {
    if (value <= maximumQuantityOfProducts && value >= 1) {
      if ((value * item.Points) <= pointsBalance) {
        setContentQuantidade(false)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Pontos insuficientes!')
        });
      }
    } else {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('A quantidade inserida é invalida!')
      });
    }
  };


  return (
    <>
      {item && <S.Container>
        <div className='containerContent'>
          <S.ContainerInfo active={checkOut && !isSm}>
            {isSm && <div className='boxTitleProduct'>
              <Shop className="boxTitleIconImage" />
              <h6>{item.Name}</h6>
            </div>}

            <div className='boxContainerProduct'>
              <div className='boxImageProduct'>
                {!isSm && <div className='boxTileImage'>
                  <Shop className="boxIconImage" />
                  <h5>{item.Name}</h5>
                </div>}

                <S.Image style={{ aspectRatio: '4/3.2' }} src={item.ImageUrl} />
              </div>

              <div className='boxInfoProduct'>
                {!award && <div className='boxTextInfo'>
                  <div className='boxTextInfoTop'>
                    <Sell className="boxIconInfo" />
                    <h3>{t('Custo')}:</h3>
                    <h3 style={{ color: theme.colorsBase.green2, fontSize: isSm ? responsiveFont(12) : responsiveFont(20) }}>{item.Points} {t('pontos')}</h3>
                    <h1 className='score'>({isSm ? t('unidade') : t('por unidade')})</h1>
                  </div>
                </div>}

                {Boolean(item.Count) &&    <div className='boxTextInfo'>
                  <div className='boxTextInfoTop'>
                    <Inventory2 className="boxIconInfo" />
                    <h3>{t('Quantidade em Estoque')}:</h3>
                    <h3 style={{ color: theme.colors.seventh }}>{item.Count} {t('un')}.</h3>
                  </div>
                </div>}

                <div className='boxTextInfo'>
                  <div className='boxTextInfoTop'>
                    <Info className="boxIconInfo" />
                    <h3>{t('Informações do produto')}:</h3>
                  </div>

                  <p className='textDescription'>{item.Description}</p>
                </div>

                {!award && <><div className='boxTextInfo'>
                  <div className='boxTextInfoTop'>
                    <AccessTimeFilled className="boxIconInfo" />
                    <h3>{t('Data de expiração')}:</h3>
                    <h3 style={{ color: theme.colors.fourth }}>{DataMaskFormtData(item.ExpirationDate ?? '' )}</h3>
                  </div>
                </div>

                  <div className='boxTextInfo'>
                    <div className='boxTextInfoTop'>
                      <CalendarMonth className="boxIconInfo" />
                      <h3>{t('Período de Retirada')}:</h3>
                      <h3 style={{ color: theme.colors.fourth }}>{item.WithdrawalDeadline} {t(`dias`)}</h3>
                    </div>
                  </div>
                </>}

                {award && <div className='boxTextInfo'>
                  <div className='boxTextInfoTop'>
                    <Sell className="boxIconInfo" />
                    <h3>{t('Desafio')}:</h3>
                    <button onClick={() => navigate(`/desafio/${item?.ChallengeId}`)} style={{ display: 'flex', background: 'none', border: 'none', alignItems: 'center' }}>
                      <h3 className='textInfo' style={{ color: theme.colors.seventh, textDecoration: 'underline' }}>{item.ChallengeTitle} (ID:{item.ChallengeId})</h3>
                      <OpenInNew style={{ fontSize: '16px', color: theme.colors.seventh, marginLeft: '2px' }} />
                    </button>
                  </div>
                </div>}

                {item.SponsoredBy && <div className='boxTextInfo'>
                  <div className='boxTextInfoTop'>
                    <Handshake className="boxIconInfo" />
                    <h3>{t('Patrocinado por')}:</h3>
                    <h3 style={{ color: theme.colors.fourth }}>{item.SponsoredBy}</h3>
                  </div>
                </div>}
              </div>
            </div>
          </S.ContainerInfo>

          <S.ContainerOrder>
            {!checkOut ? <>
              <CardAchievements textPoint style={{ width: '100%', margin: 0, padding: '4px 24px', }} title={t('Meu Saldo Atual')} description={t('Total de pontos atual:')} value={String(pointsBalance)} />

              <S.ContainerSetQuantity active={contentQuantidade}>
                <button className='boxButton'>
                  {contentQuantidade ? <h1 className='numberStage'>1</h1> : <Done className="iNumberStage" />}
                  <h1 className='titleContent'>{t('Definir quantidade')}</h1>
                  {contentQuantidade ? <KeyboardArrowUp className="i" /> : <KeyboardArrowDown className="i" />}
                </button>

                {contentQuantidade && (
                  <div className='containerQuestion'>
                    <h3 className='textTl' style={{ marginBottom: '4px' }} >{t('Quantidade de unidades do produto:')}</h3>

                    <InputSelectNumber setValue={setValue} value={value} min={1} max={maximumQuantityOfProducts} onClickRemove={() => setValue(value - 1)} onClickAdd={() => setValue(value + 1)} />

                    <h3 className='textTl' style={{ marginTop: '12px' }}>{t('Valor total de pontos a pagar:')}</h3>

                    <h3 className='textQtd'>{value * item.Points} <b className='textQtd' style={{ fontSize: responsiveFont(12) }}>{t('pontos')}</b></h3>

                    <div className='boxMessage'>
                      <Info className="iconMessage" />
                      <h5>{t('O valor de quantidades permitidas depende do seu saldo de pontos, número de unidades desse produto em estoque e limite de quantidade do produto por usuário. O valor total de pontos a pagar é o custo unitário do produto, multiplicado pela quantidade de unidades desejadas acima.')}</h5>
                    </div>

                    <ButtonMaterial
                      style={{ borderColor: theme.colors.seventh, color: theme.colors.seventh, width: '100%', marginTop: '12px' }}
                      variant="outlined"
                      label={t('próximo')}
                      onClick={() => validationCount(item)}
                    />
                  </div>
                )}
              </S.ContainerSetQuantity>
            </> : <>
              <CardAchievements
                textPoint
                style={{ width: '100%', margin: 0, padding: '4px 8px', }}
                title={`${t('Quantidade de unidades')}:`}
                description=''
                value={String(value)}
                valueTextPoint={t('un.') ?? ''}
              />

              {!award && <>
                <CardAchievements
                  textPoint
                  style={{ width: '100%', margin: '8px 0 0 0', padding: '4px 8px', }}
                  title={`${t('Valor total de pontos a serem trocados')}:`}
                  description=''
                  value={String(value * item.Points)}
                  textGreen
                />

                <CardAchievements
                  textPoint
                  style={{ width: '100%', margin: '8px 0 0 0', padding: '4px 8px', }}
                  title={`${t('Saldo Restante')}:`}
                  description={t('Valor do seu saldo após a troca de pontos pelo produto.')}
                  value={String(pointsBalance - (value * item.Points))}
                  textGreen
                />
              </>}
            </>}

            <S.ContainerSetQuantity active={!contentQuantidade}>
              <button className='boxButton'>
                {!checkOut && <h1 className='numberStage'>2</h1>}
                <h1 className='titleContent' style={{ marginLeft: checkOut ? '0px' : '12px' }}>{award ? t('Informações de resgate de prêmio:') : t('Informações de Contato')}</h1>
                {!checkOut && (!contentQuantidade ? <KeyboardArrowUp className="i" /> : <KeyboardArrowDown className="i" />)}
              </button>

              {!contentQuantidade && (
                <div className='containerQuestion'>
                  <div className='boxContact' style={{ justifyContent: award ? 'flex-start' : 'center', margin: award ? '8px 0px 32px 0px' : '24px' }}>
                    <Mail className="iconContact" />

                    <div className='boxContactText'>
                      <h1 className='textMail'>{t('E-mail cadastrado: ')}</h1>
                      <h1 className='textMail' style={{ color: theme.colors.seventh, fontSize: responsiveFont(14) }}>{email}</h1>
                    </div>
                  </div>

                  <div className='boxMessage'>
                    <Info className="iconMessage" />
                    <h5>{t('O e-mail informado acima é o e-mail utilizado na sua conta da Curar Saúde. A equipe da Curar Saúde entrará em contato através do e-mail acima para instruções e informações adicionais para você adquirir seu produto trocado! Fique atento à sua caixa de entrada.')}</h5>
                  </div>

                  {!checkOut && <ButtonMaterial
                    style={{ backgroundColor: theme.colorsBase.green2, width: '100%', marginTop: '12px' }}
                    variant="contained"
                    label={t('revisar pedido')}
                    onClick={() => setCheckOut(true)}
                  />}
                </div>
              )}
            </S.ContainerSetQuantity>
          </S.ContainerOrder>
        </div>

        <div className='containerButtom'>
          {(contentQuantidade && !isSm) && <ButtonMaterial
            style={{ backgroundColor: theme.colors.black5, color: theme.colorsBase.white, width: '60%', marginTop: '12px' }}
            variant="contained"
            label={t('revisar pedido')}
            disable
          />}

          {checkOut && <>
            <ButtonMaterial
              style={{ borderColor: theme.colors.graphicRed, color: theme.colors.graphicRed, width: '100%', marginTop: '12px', marginRight: '16px' }}
              variant="outlined"
              label={t('Cancelar')}
              onClick={onClickCancel}
            />

            <ButtonMaterial
              style={{ backgroundColor: theme.colorsBase.green2, color: theme.colorsBase.white, width: '100%', marginTop: '12px' }}
              variant="contained"
              label={t('confirmar')}
              onClick={onClick}
            />
          </>}
        </div>
      </S.Container>}
    </>
  )
}

export default ViewExchangePointsForProducts;
