import styled from 'styled-components'
import { responsiveFont } from '../../../utils/UtilsGlobal'

export const ContainerEditCategories = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 60%;


  @media (max-width: 680px) {
    width: 100%;
  }
`

export const ItemLanguage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 8px;
  width: 100%;
  .flag {
    width: 24px;
    object-fit: cover;
  }

  .icon {
    width: 24px;
    object-fit: cover;
    color: ${(props) => props.theme.colorsBase.black};
  }

  h5 {
    margin-left: 8px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.fourth};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  } 

  @media (max-width: 680px) {
    padding: 4px 8px;
  }
`

export const TextButtonTerms = styled.button`
    display: flex;
    align-items: center;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colorsBase.blue};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    background: none;
    border: none;
    text-decoration: underline;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }

    @media (max-width: 680px) {
      text-decoration: none;
    }
`

export const ContainerModalView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  
  .boxTitle {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .boxText {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 8px;
  }

  .title {
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.fourth};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  .version {
    margin-top: 4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.friday};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .boxListTerms {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    width: 100%;
  }
  
  .containerButtonsModal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 16px;
  }

  .containerDescription {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 300px;
    border: solid 1px ${(props) => props.theme.colors.thirteen};
    padding: 24px 12px 8px 24px;
    border-radius: 8px;
    margin-top: 16px;
  }

  @media (max-width: 680px) {
    .containerDescription {
      height: 300px;
      padding: 8px;
    }

    .containerButtonsModal {
      margin-top: 8px;
      margin-right: 32px;
    }
  }
`

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    border-radius: 8px;
    width: 4px;
    background: ${(props) => props.theme.colors.eleven};
    }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.seventh};
    border-radius: 8px;
  }
  ::-webkit-scrollbar:vertical { 
    height: 4px;
    border-radius: 8px;
  }

  p {
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    margin-bottom: 8px;
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Medium;

    @media (min-width: 1600px) {
        line-height: 24px;
        font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
    padding-right: 4px;
    margin: 48px 8px 32px 16px;
  }
`

export const ButtonTap = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self:  center;

  .flag {
    width: 24px;
    object-fit: cover;
  }

  @media (max-width: 680px) {
    border: solid 1px ${(props) => (props.active ? props.theme.colors.seventh : props.theme.colorsBase.gray3)}; 
    border-radius: 4px;
    padding: 8px;
  }
`

export const BoxText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;

  @media (max-width: 680px) {
    margin-top: 8px;
    margin-bottom: 24px;
  }

    .text {
      text-align: center;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    .red {
      text-align: center;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.graphicRed};
      font-weight: 700;
      font-family: 'Barlow', Regular;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    .green {
      text-align: center;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colorsBase.green2};
      font-weight: 700;
      font-family: 'Barlow', Regular;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }
  
`