import styled from 'styled-components'
import { theme } from '../../styles/theme'
import { responsiveFont } from '../../utils/UtilsGlobal'


export const ContainerChallenge = styled.div<{ smallerSize?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 4px;
  border: solid 0.5px ${(props) => props.theme.colors.eleven};
  padding: 16px;
  width: 100%;
  margin: 0px 16px 0px 16px;

  @media (max-width: 680px) {
    margin: 0px 0px 24px 0px;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%; 
  }

    .containerTitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%; 

      .title {
        letter-spacing: 0.1px;
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colors.secundary};
        font-weight: 400;
        font-family: 'Barlow', Regular;
    
        @media (max-width: 680px) {
          font-size: ${(props) => (props.smallerSize ? responsiveFont(14) : responsiveFont(16))}; 
        }

        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }


    }

    .containerDescription {
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%; 

      .description {
        margin-right: 8px;
        letter-spacing: 0.1px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${(props) => (props.smallerSize ? responsiveFont(10) : responsiveFont(12))}; 
        }

        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }

      .value {
        letter-spacing: 0.1px;
        text-align: left;
        font-size: ${responsiveFont(34)};
        color: ${(props) => props.theme.colors.seventh};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${(props) => (props.smallerSize ? responsiveFont(20) : responsiveFont(34))}; 
        }

        @media (min-width: 1600px) {
          font-size: ${responsiveFont(36)};
        }
      }
    }
`

export const ContainerExtract = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  @media (max-width: 680px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 60%;
    height: 100%;
    padding: 0 16px 0 0;
  }

  .i {
    font-size: 16px;
    color: ${theme.colors.seventh};

    @media (min-width: 1600px) {
      font-size: 18px;
    }

    @media (max-width: 680px) {
      font-size: 24px;
      margin-right: 6px;
    }
  }

 .titleExtract {
    margin-left: 5px;
    letter-spacing: 0.1px;
    text-align: left;
    font-size: ${responsiveFont(8)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (max-width: 680px) {
      margin-left: 0px;
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(10)};
    }
  }
`