import React, { useState } from 'react';

import { useIsSm } from '../../hooks/useIsSm';
import Product from './view';

const ProductUser: React.FC = () => {
  const [tab, setTab] = useState(0)
  const isSm = useIsSm()

  return (
    <Product
      tab={tab}
      setNewTab={setTab}
      variant={isSm ? 'scrollable' : 'standard'}
    />
  );
}

export default ProductUser;