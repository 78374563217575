import React, { useEffect, useState } from 'react';
import ModalCustom from '../../../../../components/ModalCustom';
import { useTranslation } from 'react-i18next';
import ButtonMaterial from '../../../../../components/Button';
import { Info, QueryStats, Assessment, BorderColor, Delete, Search } from '@mui/icons-material';
import { BarChart, XAxis, YAxis, Bar, Legend } from 'recharts';
import * as S from '../../../../ViewChallenge/styles';
import { theme } from '../../../../../styles/theme';
import { IArrayQuestions, IChallengeAccomplished } from '../../../../../services/Challenges/types';
import { Tooltip, TextField } from '@mui/material';
import { useIsSm } from '../../../../../hooks/useIsSm';
import { useNavigate } from 'react-router-dom';
import ViewQuiz from '../../../../../components/ViewQuiz';
import ModalChallengeContent from '../../../../../components/ModalChallengeContent';
import ToastNotification from '../../../../../components/ToastNotification';
import { deleteChallenges, getChallengeDetailsActive, getChallengeDetailsRejected, getChallengeDetailsUnderReview, getSearchChallengeQuiz, } from '../../../../../services/Challenges';
import noImage from '../../../../../assets/images/noImage.png'
import { IPerformanceChallenge } from '../../../../../services/Dashboard/ChallengesDashboard/types';


interface ChallengeDetailsModalProps {
  open: boolean;
  onClose: () => void;
  type: 'approved' | 'rejected' | 'under_review' | "performance";
  itemSelectedId: string;
  update: () => void;
  specialProgram?: boolean;
  noGraph?: boolean;
}

const ChallengeDetailsModal: React.FC<ChallengeDetailsModalProps> = ({ open, onClose, type, itemSelectedId, update, specialProgram, noGraph }) => {
  const { t } = useTranslation();
  const isSm = useIsSm();
  const navigate = useNavigate()
  const [modalContentItem, setModalContentItem] = useState(false);
  const [openModalViewQuiz, setOpenModalViewQuiz] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [challengeDeleted, setChallengeDeleted] = useState(false)
  const [challengePerformance, setChallengePerformance] = useState<IPerformanceChallenge[]>([])
  const [questionContent, setQuestionContent] = useState<IArrayQuestions[]>([])
  const [reasonForDisapproval, setReasonForDisapproval] = useState('')
  const [itemSelected, setItemSelected] = useState<IChallengeAccomplished>()

  useEffect(() => {
    if (open) {
      if (type === 'rejected') challengeDetailsRejected();
      if (type === 'approved') challengeDetailsActive();
      if (type === 'under_review') challengeDetailsUnderReview();
      searchChallengeQuiz();
    }
  }, [open]);

  const closeModalDelete = () => {
    setChallengeDeleted(false)
    setOpenDeleteModal(false)
  };

  const errorSearchNotification = () => {
    ToastNotification({
      id: 'error',
      type: 'error',
      message: t('Houve um erro ao buscar os dados, tente novamente.')
    });
  };

  const challengeDetailsActive = async () => {
    setLoading(true)
    try {
      const response = await getChallengeDetailsActive(itemSelectedId);
      if (response.Success) {
        setItemSelected(response.Data)

        const arrayFinaly: IPerformanceChallenge[] = [];

        response.Data?.ChallengePerformance?.map((item) => {
          arrayFinaly.push({
            DayOfMonth: Number(item.Date.substring(8, 10)),
            EndedCount: item.CompletedCount,
            Month: Number(item.Date.substring(5, 7)),
            StartedCount: item.StartedCount
          })
        });
        setChallengePerformance(arrayFinaly)
      } else {
        errorSearchNotification();
      }
    } catch (ex) {
      errorSearchNotification();
    }
    setLoading(false)
  };

  const challengeDetailsUnderReview = async () => {
    setLoading(true)
    try {
      const response = await getChallengeDetailsUnderReview(itemSelectedId);
      if (response.Success) {
        setItemSelected(response.Data)
      } else {
        errorSearchNotification();
      }
    } catch (ex) {
      errorSearchNotification();
    }
    setLoading(false)
  };

  const challengeDetailsRejected = async () => {
    setLoading(true)
    try {
      const response = await getChallengeDetailsRejected(itemSelectedId);
      if (response.Success) {
        setReasonForDisapproval(response.Data.Reason ?? '')
        setItemSelected(response.Data)
      } else {
        errorSearchNotification();
      }
    } catch (ex) {
      errorSearchNotification();
    }
    setLoading(false)
  };

  const searchChallengeQuiz = async () => {
    setLoading(true)
    try {
      const response = await getSearchChallengeQuiz(itemSelectedId);
      if (response.Success) {
        setQuestionContent(response.Data)
      } else {
        errorSearchNotification();
      }
    } catch (ex) {
      errorSearchNotification();
    }
    setLoading(false)
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      if (itemSelectedId) {
        const response = await deleteChallenges(itemSelectedId);
        if (response.Success) {
          setChallengeDeleted(true)
          update();
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível excluir o desafio, tente novamente mais tarde.'),
            errorMessage: response.Message,
            errors: response.Errors
          });
        }
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível excluir o desafio, tente novamente mais tarde.')
      });
    }
    setLoading(false);
  };

  const renderTextLanguage = (type: number) => ({
    1: "PT",
    2: "EN",
    3: "ES",
    4: "FR",
  }[type])

  const renderContent = () => {
    return (
      <S.ContainerInRevision>
        <div className='boxLeft'>
          <div className='boxText'>
            <div className='boxTextTooltip'>
              <h1>{specialProgram ? t('Título do Conteúdo') : t('Título do Desafio')}</h1>
            </div>
            <h1>{itemSelected?.Title}</h1>
          </div>

          <div className='boxText'>
            <div className='boxTextTooltip'>
              <h1>{t('Idioma')}</h1>
            </div>
            <h1>{renderTextLanguage(Number(itemSelected?.Language))}</h1>
          </div>

          <div className='boxText'>
            <div className='boxTextTooltip'>
              <h1>{t('Endereço do Site')}</h1>
            </div>
            <h1>{itemSelected?.ContentSourceUrl ? itemSelected.ContentSourceUrl : 'N/A'}</h1>
          </div>

          <div className='boxText'>
            <div className='boxTextTooltip'>
              <h1>{t('Mais sobre o site')}</h1>
            </div>
            <h1>{itemSelected?.ContentSourceDetails ? itemSelected?.ContentSourceDetails : 'N/A'}</h1>
          </div>

          <div className='boxText'>
            <div className='boxTextTooltip'>
              <h1>{t('Prêmio Especial')}</h1>
            </div>
            <h1>{itemSelected?.SpecialAwardName ? itemSelected?.SpecialAwardName : 'N/A'}</h1>
          </div>

          <div className='boxText'>
            <div className='boxTextTooltip'>
              <h1>{t('Nível de Dificuldade')}</h1>

              <Tooltip title={t('Inserido pela equipe da Curar Saúde')}>
                <Info className="iconRight" />
              </Tooltip>
            </div>
            {itemSelected?.DifficultyLevel ? <h1>{itemSelected?.DifficultyLevel}</h1> : <h4>{t('Não Definido')}</h4>}
          </div>

          <div className='boxText'>
            <div className='boxTextTooltip'>
              <h1>{t('Categoria')}</h1>

              <Tooltip title={t('Inserido pela equipe da Curar Saúde')}>
                <Info className="iconRight" />
              </Tooltip>
            </div>
            {itemSelected?.Category ? <h1>{itemSelected.Category}</h1> : <h4>{t('Não Definido')}</h4>}
          </div>
        </div>

        <div className='boxRight'>
          <div className='boxText'>
            <div className='boxTextTooltip'>
              <h1>{t('Imagem de Capa')}</h1>
            </div>

            <S.ImageModalDetails style={{ aspectRatio: '16/9' }} src={itemSelected?.CoverImageUrl ? itemSelected?.CoverImageUrl : noImage} />
          </div>

          <S.ContainerMediaButtons>
            <div className='containerButtons' style={{ marginRight: isSm ? '8px' : '0px' }}>
              <h1>{isSm ? t('Mídia') : t('Conteúdo')}</h1>

              <button className='boxButtons' onClick={() => setModalContentItem(true)}>
                <Search className="iconLeft" />
                <h2>{isSm ? t('VER MÍDIA') : t('VER CONTEÚDO')}</h2>
              </button>
            </div>

            <div className='containerButtons'>
              <h1>{t('Perguntas')}</h1>

              <button className='boxButtons' onClick={() => setOpenModalViewQuiz(true)}>
                <Search className="iconLeft" />
                <h2>{t('VER PERGUNTAS')}</h2>
              </button>
            </div>
          </S.ContainerMediaButtons>

          <div className='boxText'>
            <div className='boxTextTooltip'>
              <h1>{t('Tags')}</h1>

              <Tooltip title={t('Inserido pela equipe da Curar Saúde')}>
                <Info className="iconRight" />
              </Tooltip>
            </div>

            {itemSelected?.Tags &&
              <>
                <div className='containerCategory'>
                  {itemSelected?.Tags?.map((item) => (
                    <div className='boxCategory' key={item}>
                      <h5>{item}</h5>
                    </div>
                  ))}
                </div>
                <>
                  {itemSelected?.Tags?.length <= 0 && <h4>{t('Não Definido')}</h4>}
                </>
              </>
            }
          </div>

          <div className='boxText'>
            <div className='boxTextTooltip'>
              <h1>{t('Tempo Estimado (em minutos)')}</h1>

              <Tooltip title={t('Inserido pela equipe da Curar Saúde')}>
                <Info className="iconRight" />
              </Tooltip>
            </div>
            {itemSelected?.EstimatedTime ? <h1>{itemSelected.EstimatedTime} min</h1> : <h4>{t('Não Definido')}</h4>}
          </div>

          <div className='boxText'>
            <div className='boxTextTooltip'>
              <h1>{t('Classificação Indicativa')}</h1>

              <Tooltip title={t('Inserido pela equipe da Curar Saúde')}>
                <Info className="iconRight" />
              </Tooltip>
            </div>
            {itemSelected?.AgeRating ? <h1>{itemSelected.AgeRating}</h1> : <h4>{t('Não Definido')}</h4>}
          </div>
        </div>
      </S.ContainerInRevision>
    );
  };

  return (
    <>
      {(type === 'rejected') && (
        <ModalCustom
          open={open}
          onClose={onClose}
          onClick={() => { }}
          buttonsEnabled={false}
          iconHeader={<Info style={{ fontSize: '24px', color: theme.colorsBase.gray3, backgroundColor: theme.colorsBase.white }} />}
          style={{ width: isSm ? '95%' : '80%', padding: isSm ? '8px' : '24px' }}
          styleLine={{ backgroundColor: 'transparent' }}
          childrenContent={
            <>
              <S.ContainerModalInRevision>
                {renderContent()}

                <div className='containerReasonForDisapproval'>
                  <div className='boxReasonForDisapproval'>
                    <QueryStats className="iconLeft" />
                    <h2>{t('Motivo da Reprovação')}</h2>
                  </div>

                  <TextField
                    id="outlined-multiline-static"
                    label={t('Descrição')}
                    multiline
                    rows={12}
                    defaultValue="Default Value"
                    variant="outlined"
                    value={reasonForDisapproval}
                    disabled
                    style={{ marginTop: '16px', width: '100%', }}
                    onChange={() => { }}
                  />
                </div>
              </S.ContainerModalInRevision>

              <S.ContainerModalBottomButtons>
                <ButtonMaterial
                  style={{ borderColor: theme.colors.secundary, marginRight: isSm ? '16px' : '24px' }}
                  variant="outlined"
                  label={specialProgram ? t('editar conteúdo') : t('editar desafio')}
                  icon={<BorderColor style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.secundary }} />}
                  onClick={() => navigate(`/desafios/editar/${itemSelectedId}/2`)}
                />

                <ButtonMaterial
                  style={{ borderColor: theme.colorsBase.red2, color: theme.colorsBase.red2, }}
                  variant="outlined"
                  label={specialProgram ? t('excluir conteúdo') : t('excluir desafio')}
                  icon={<Delete style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.red2 }} />}
                  onClick={() =>
                    setOpenDeleteModal(true)
                  }
                />
              </S.ContainerModalBottomButtons>
            </>
          }
          title={specialProgram ? t('Detalhes do Conteúdo') : t('Detalhes do Desafio')}
          confirmationButtonText={t('Pesquisar')}
          textDeclineButton={t('CANCELAR')}
        />
      )}

      {type === 'under_review' && (
        <ModalCustom
          open={open}
          onClose={onClose}
          onClick={() => { }}
          buttonsEnabled={false}
          iconHeader={<Info style={{ fontSize: '24px', color: theme.colorsBase.gray3, backgroundColor: theme.colorsBase.white }} />}
          style={{ padding: isSm ? '8px' : '24px' }}
          styleLine={{ backgroundColor: 'transparent' }}
          childrenContent={renderContent()}
          title={specialProgram ? t('Detalhes do Conteúdo') : t('Detalhes do Desafio')}
          confirmationButtonText={t('Pesquisar')}
          textDeclineButton={t('CANCELAR')}
        />
      )}

      {type === 'approved' && (
        <ModalCustom
          open={open}
          onClose={onClose}
          onClick={() => { }}
          buttonsEnabled={false}
          iconHeader={<Info style={{ fontSize: '24px', color: theme.colorsBase.gray3, backgroundColor: theme.colorsBase.white }} />}
          style={{ width: !noGraph ? (isSm ? '95%' : '80%') : 'none', padding: isSm ? '8px' : '24px' }}
          styleLine={{ backgroundColor: 'transparent' }}
          childrenContent={
            <>
              <S.ContainerModalActive>
                {renderContent()}

                {!noGraph && <div className='containerReasonForDisapproval'>
                  <div className='boxReasonForDisapproval'>
                    <Assessment className="iconLeft" />
                    <h2>{specialProgram ? t('Desempenho do Conteúdo') : t('Desempenho do Desafio')}</h2>
                    <h3>{t('(últimos 15 dias)')}</h3>
                  </div>

                  <div className='boxGrafic'>
                    <BarChart
                      width={400}
                      height={230}
                      data={challengePerformance}
                      margin={{
                        right: 24,
                        left: 0,
                      }}
                      barSize={12}
                    >
                      <XAxis dataKey="DayOfMonth" axisLine={false} tickLine={false} />
                      <YAxis axisLine={false} tickLine={false} />
                      <Legend
                        verticalAlign="top"
                        iconType='circle'
                        height={60}
                        formatter={(value) => value === 'EndedCount' ? t("Total de usuários que completaram o desafio") : t("Total de usuários que iniciaram o desafio")}
                      />
                      <Bar dataKey="EndedCount" stackId="a" fill="#00DAF9" />
                      <Bar dataKey="StartedCount" stackId="a" fill="#1A1266" />
                    </BarChart>
                  </div>
                </div>}
              </S.ContainerModalActive>

              {(specialProgram ? noGraph : true) && <S.ContainerModalBottomButtons style={{ justifyContent: 'center' }}>
                <ButtonMaterial
                  style={{ borderColor: theme.colors.secundary, marginRight: isSm ? '16px' : '24px' }}
                  variant="outlined"
                  label={specialProgram ? t('editar conteúdo') : t('editar desafio')}
                  icon={<BorderColor style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.secundary }} />}
                  onClick={() => navigate(`/desafios/editar/${itemSelectedId}/2`)}
                />

                <ButtonMaterial
                  style={{ borderColor: theme.colorsBase.red2, color: theme.colorsBase.red2, }}
                  variant="outlined"
                  label={specialProgram ? t('excluir conteúdo') : t('excluir desafio')}
                  icon={<Delete style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.red2 }} />}
                  onClick={() =>
                    setOpenDeleteModal(true)
                  }
                />
              </S.ContainerModalBottomButtons>}
            </>
          }
          title={specialProgram ? t('Detalhes do Conteúdo') : t('Detalhes do Desafio')}
          confirmationButtonText={t('Pesquisar')}
          textDeclineButton={t('CANCELAR')}
        />
      )}

      <ModalChallengeContent
        setModalContent={() => setModalContentItem(false)}
        modalContent={modalContentItem}
        media={itemSelected?.ContentFileUrl ?? ''}
        mediaType={itemSelected?.ContentType ?? 0}
        extension={itemSelected?.ContentFileExtension ?? ''}
        sponsored={itemSelected?.SponsoredAdvertisement}
      />

      <ModalCustom
        open={openModalViewQuiz}
        onClose={() => setOpenModalViewQuiz(false)}
        onClick={() => { }}
        buttonsEnabled={false}
        style={{ padding: isSm ? '8px' : '24px' }}
        styleLine={{ backgroundColor: 'transparent' }}
        childrenContent={
          <ViewQuiz questionContent={questionContent} />
        }
        title={t('Perguntas')}
        confirmationButtonText={t('Pesquisar')}
        textDeclineButton={t('CANCELAR')}
      />

      <ModalCustom
        open={openDeleteModal}
        onClose={closeModalDelete}
        onClick={() => {
          handleDelete()
        }}
        loading={loading}
        title={specialProgram ? t('Remover Conteúdo') : t('Remover Desafio')}
        description={challengeDeleted ? `${t('O Desafio')} "${itemSelected?.Title}" ${t('foi excluído com sucesso.')}` : t('Você realmente deseja remover o desafio? Ele ficará indisponível na plataforma.')}
        confirmationButtonText={t('CONFIRMAR')}
        textDeclineButton={t('CANCELAR')}
        buttonsEnabled={!challengeDeleted}
        shortModal={true}
        childrenContent={
          <>
            {challengeDeleted &&
              <ButtonMaterial
                style={{ borderColor: theme.colors.secundary }}
                variant="outlined"
                label={t('VOLTAR')}
                onClick={closeModalDelete}
              />
            }
          </>
        }
      />
    </>
  );
};

export default ChallengeDetailsModal;
