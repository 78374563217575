import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'
export const SelectedLanguage = styled.div<{ active?: boolean }>`
  display: flex;
  width: ${(props) => (props.active ? '100%' : 'none')}; 
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 4px;

  h5 {
    font-weight: 500;
    letter-spacing: 0.71px;
    margin-left: 6px;
    color: ${(props) => props.theme.colors.black};
    font-size: ${responsiveFont(10)};
    font-family: 'Barlow', Medium;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(8)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(12)};
    }
  }

  .flag {
    width: 16px;
    object-fit: cover;
  }
`
