import { Visibility, VisibilityOff } from '@mui/icons-material'
import { InputAdornment } from '@mui/material'
import { theme } from '../../styles/theme';
import * as S from './styles'

interface IButtonHidePassword {
  password: boolean
  onClick: () => void
}

export const ButtonHidePassword = (props: IButtonHidePassword) => {
  const { password, onClick } = props
  return (
    <InputAdornment
      position="end"
      onClick={() => onClick()}
    >
      <S.ContainerHide>
        {password ? (
          <Visibility fill={theme.colors.nine} className="iconPassword" />
        ) : (
          <VisibilityOff fill={theme.colors.nine} className="iconPassword" />
        )}
      </S.ContainerHide>
    </InputAdornment>
  )
}
