import styled from 'styled-components'
import { responsiveFont } from '../../../utils/UtilsGlobal'

export const ContainerRegister = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .boxButtonsRegister {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16px;

    .boxButtonsBottom {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .boxButtonsRight {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
`

export const BoxButtonsNav = styled.div<{ active: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: ${props => props.active ? "-30px" : "8px"};

    @media (max-width: 680px) {
      margin-bottom: 16px;
    }
`

export const ContainerRegisterPage1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 48px;

  .textitle {
    margin-top: 16px;
    margin-bottom: 48px;
    text-align: center;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.fourth};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) { 
      font-size: ${responsiveFont(18)};
    }
  }

  @media (max-width: 680px) {
    margin-top: 16px;
  }
`
