export const withLineBreak = (text: string) => text.split("\n").map((line) => (
  <>
    {line}
    <br />
  </>
))


export function toRecordString(data: Record<string, { toString: () => string }>) {
    return Object
      .keys(data)
      .map((key) => ({ key, value: data[key].toString() }))
      .reduce((prev, curr) => {
        prev[curr.key] = curr.value
        return prev
      }, {})
  }