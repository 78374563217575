/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import ToastNotification from '../../../../components/ToastNotification';
import ViewLoading from '../../../../components/ViewLoading';
import { useIsSm } from '../../../../hooks/useIsSm';
import { ICardSpecialProgram } from '../../../../pages/SpecialProgramManagement/ModalCreateSpecialProgram/types';
import ChallengeItemDesktop from '../../../../components/ChallengeItemDesktop';
import { Add } from '@mui/icons-material';
import ChallengeDetailsModal from './ChallengeDetailsModal';
import { IChallengeAccomplished } from '../../../../services/Challenges/types';
import { getSpecialProgram } from '../../../../services/SpecialProgram';
import ViewCardChallenge from '../../../../pages/ViewChallenge/CardQuizChallenge';

interface IProps {
  update: () => void;
  tab: number;
  id: string
}

const ScreenContents: React.FC<IProps> = ({ update, tab, id }) => {
  const { t } = useTranslation();
  const isSm = useIsSm();
  const [loading, setLoading] = useState(false);
  const [specialProgramList, setSpecialProgramList] = useState<ICardSpecialProgram[]>([]);
  const [openContentModal, setOpenContentModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IChallengeAccomplished | null>(null);
  const [modalType, setModalType] = useState<'approved' | 'rejected' | 'under_review' | null>(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    retrieveSpecialProgram();
  }, [tab]);

  const updateScreen = () => {
    retrieveSpecialProgram();
    update();
  };

  const retrieveSpecialProgram = async () => {
    setLoading(true);
    try {
      setSpecialProgramList([]);
      const bodyData = {
        PageNumber: 1,
        PageSize: 1,
        Id: id,
        GetChallenges: true
      };
      const response = await getSpecialProgram(bodyData);
      if (response.Success) {
        setSpecialProgramList(response.Data[0]?.Challenges);
        setActive(response.Data[0].Status === 5)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os conteudos, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors,
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os conteudos, tente novamente.'),
      });
    }
    setLoading(false);
  };

  const handleOpenModal = () => {
    setOpenContentModal(true);
  };

  const handleCloseModal = () => {
    setOpenContentModal(false);
  };

  const handleChallengeItemClick = (item: ICardSpecialProgram) => {
    setSelectedItem(item as any);

    switch (getStatusText(Number(item.ChallengeStatus))) {
      case 'approved':
        setModalType('approved');
        break;
      case 'rejected':
        setModalType('rejected');
        break;
      case 'under_review':
        setModalType('under_review');
        break;
      default:
        setModalType(null);
    }
  };

  const handleCloseChallengeModal = () => {
    setSelectedItem(null);
    setModalType(null);
  };

  function getStatusText(status: number): 'approved' | 'rejected' | 'under_review' {
    switch (status) {
      case 1:
        return 'rejected';
      case 5:
        return 'approved';
      case 7:
        return 'rejected';
      case 3:
        return 'under_review';
      default:
        return 'under_review'; // Padrão para qualquer outro valor não mapeado
    }
  }

  const returnContentAdd = () => (
    <>
      {!active &&
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <S.BoxChallengeAdd onClick={handleOpenModal}>
            <Add className='iconAdd' />
            <h3 className='textAdd'>{t('Criar Conteúdo')}</h3>
          </S.BoxChallengeAdd>
        </div>
      }
    </>
  )

  return (
    <S.Container>
      {loading ? (
        <ViewLoading />
      ) : (
        <S.ContainerChallenge>
          {isSm && returnContentAdd()}

          {specialProgramList?.map((item) => (
            <ChallengeItemDesktop
              key={item.Id}
              onClick={() => handleChallengeItemClick(item)}
              typeChallenge={11}
              item={{
                CoverImageUrl: item.CoverImageUrl,
                Language: '1',
                Title: item.Title,
                Id: item.Id?.toString(),
                StatusAlt: getStatusText(Number(item.ChallengeStatus)),
                DateTime: item.DateTime,
              }}
              showButton={false}
            />
          ))}

          {!isSm && returnContentAdd()}

          <ViewCardChallenge
            openChallengeModal={openContentModal}
            setOpenChallengeModal={handleCloseModal}
            specialProgramId={id}
            update={updateScreen}
          />
        </S.ContainerChallenge>
      )}

      {selectedItem && modalType && (
        <ChallengeDetailsModal
          open={Boolean(selectedItem && modalType)}
          onClose={handleCloseChallengeModal}
          type={modalType}
          itemSelectedId={selectedItem.Id}
          update={updateScreen}
          specialProgram={true}
          noGraph={!active}
        />
      )}
    </S.Container>
  );
};

export default ScreenContents;
