import React, { useState, } from 'react';
import { ButtonProps } from './types'
import { Tooltip } from '@mui/material'
import { Person, Settings, Logout, MoreVert } from '@mui/icons-material'
import { useTranslation } from 'react-i18next';
import { theme } from '../../styles/theme';
import * as S from './styles'
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal'
import { useAuth } from '../../contexts/Auth'
import ModalCustomBottom from '../ModalCustomBottom';
import { useIsSm } from '../../hooks/useIsSm';

const ModalMenu: React.FC<ButtonProps> = (props) => {
  const { colorWhite, colorPrimary } = props
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const { signOut, role } = useAuth()
  const isSm = useIsSm()

  const content = () => {
    return (
      <S.Options white={colorWhite} mobile={isSm} >
        <S.Option white={colorWhite} onClick={() => {
          navigate('/meu_perfil')
          setOpen(false)
        }}>
          <Person className="flag" />
          <h5>{t('Meu Perfil')}</h5>
        </S.Option>

        {role?.includes('CurarManager') && (
          <S.Option white={colorWhite} onClick={() => {
            navigate('/configuracao')
            setOpen(false)
          }}>
            <Settings className="flag" />
            <h5>{t('Configurações')}</h5>
          </S.Option>
        )}

        <div className='containerViewMore'>
          <button className='buttonViewMore' onClick={() => {
            signOut()
            setOpen(false)
          }}>
            <Logout className="icon" />
            <h5>{t('Sair')}</h5>
          </button>
        </div>
      </S.Options>
    )
  };

  const selectColorB = () => {
    return colorPrimary ? theme.colors.secundary : theme.colorsBase.white;
  };

  const selectColor = () => {
    return colorWhite ? theme.colors.black : selectColorB();
  };

  return (
    <S.Container>
      <S.Select white={colorWhite} mobile={isSm} onClick={() => {
        setOpen(!open)
      }}>
        <Tooltip title={t('Menu')}>
          {isSm ? <MoreVert className="iconUser" style={{ color: selectColor() }} /> : (
            <div>
              <Person className="iconUser" style={{ color: colorWhite ? theme.colors.black : theme.colorsBase.white }} />
            </div>
          )}
        </Tooltip>
      </S.Select>

      {!isSm ? (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
        >
          {content()}
        </Modal>
      ) : (
        <ModalCustomBottom
          open={open}
          onClose={() => setOpen(false)}
          title={t('Desafio')}
          hideTitle
          childrenContent={
            <>
              {content()}
            </>
          }
        />
      )}
    </S.Container>
  )
}

export default ModalMenu
