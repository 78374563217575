import * as yup from 'yup';

import { verifyClassEmpty } from '../../../utils/ClassEmpty';
import { genericValidation } from '../../../utils/ValidationGenerics';
import { RegistrationSpecialProgram, RegistrationSpecialProgramError } from './types';


export const registerValidation1 = async (object: RegistrationSpecialProgram): Promise<true | RegistrationSpecialProgram> => {
  const schemaRegister = yup.object().shape({
    title: yup.string().required(),
    averagePerformance: yup.string().required(),
    language: yup.string().required(),
  });

  const mountError = await genericValidation<RegistrationSpecialProgram, RegistrationSpecialProgramError>(schemaRegister, object, new RegistrationSpecialProgram());


  if (Number(object.averagePerformance) > 100 || Number(object.averagePerformance) < 0) {
    mountError.averagePerformance = "A nota deve estar entre 0% e 100%.";
  }

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};

export const emailValidation = async (object: RegistrationSpecialProgram): Promise<true | RegistrationSpecialProgram> => {
  const schemaRegister = yup.object().shape({
    email: yup.string().required().email()
  });

  const mountError = await genericValidation<RegistrationSpecialProgram, RegistrationSpecialProgramError>(schemaRegister, object, new RegistrationSpecialProgram());

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};
