import { Construction } from '@mui/icons-material'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/images/logoAllone.png'
import * as S from './styles'

export interface IViewShortlyProps {
  message?: string | null
  children?: ReactNode
}

const ViewShortly: React.FC<IViewShortlyProps> = (props: IViewShortlyProps) => {
  const { message } = props
  const { t } = useTranslation();
  return (
    <S.Container>
      <img src={logo} className="image" />
      <Construction className="icon" />
      <S.Message>{message ? message : t('Página não encontrada!')}</S.Message>
    </S.Container>
  )
}

export default ViewShortly
