import styled from 'styled-components'
import { Box, Typography, styled as mStyled } from '@mui/material'
import { theme } from '../../styles/theme';

export const Grid = mStyled(Box)(({ theme }) => ({
  paddingLeft: "48px",
  paddingRight: "48px",
  gap: "48px",
  display: "grid",
  marginBottom: '64px',
  gridTemplateAreas: `
    "geral      geral"
    "fazemos    fazemos"
    "site       todos"
    "criadores  confiavel"
    "pontos     privacidade"
  `,
  [theme.breakpoints.down(800)]: {
    marginTop: '32px',
    gridTemplateAreas: `
    "geral"
    "fazemos"
    "site"
    "todos"
    "criadores"
    "confiavel"
    "pontos"
    "privacidade"
  `,
  }
}))

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  background-color: ${(props) => props.theme.colors.secundary};

  margin-right: 14px;
  position: fixed;
  z-index: 99;

  button {
    z-index: 3;
    border: 0;
    background: none;

    img {
      width: 120px;
      object-fit: cover;
      margin-left: 8px;
    }
  }

  @media (max-width: 680px) {
    flex-direction: row;
    padding: 16px 16px 16px 24px;
    margin-right: 0px;

    button {
      img {
        width: 120px;
      }
    }
  }

  @media (min-width: 1600px) {
    button {
      img {
        width: 160px;
      }
    }
  }
`

export const ButtonHeader = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 680px) {
    width: 100%;
    justify-content: space-between;
  }
`

export const Title = mStyled(Typography)(() => ({
  fontSize: "20px",
  color: "#1A1266",
  fontWeight: '400'
}))

export const ContentText = mStyled(Typography)(() => ({
  fontSize: "14px",
  color: "#201E1E",
  fontWeight: '400'
}))

export const BoxWithImage = mStyled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  paddingLeft: '150px',
  minHeight: '200px',
  [theme.breakpoints.down(800)]: {
    paddingLeft: '80px',
  }
}))

export const SideImage = mStyled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: "200px",
  [theme.breakpoints.down(800)]: {
    width: "100px",
  }
}))

export const BoxWithImageReverse = mStyled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  paddingRight: '150px',
  minHeight: '200px',
  [theme.breakpoints.down(800)]: {
    paddingRight: '80px',
  }
}))

export const SideImageReverse = mStyled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: "200px",
  [theme.breakpoints.down(800)]: {
    width: "100px",
  }
}))

export const Title2 = mStyled(Typography)(() => ({
  fontSize: "24px",
  color: theme.colors.seventh,
  fontWeight: '400'
}))