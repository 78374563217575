import React, { ChangeEvent, useEffect, useState } from 'react';
import * as S from './styles'
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../../../hooks/useIsSm';
import ModalCustom from '../../../../components/ModalCustom';
import ButtonMaterial from '../../../../components/Button';
import { theme } from '../../../../styles/theme';
import { RegistrationSpecialProgram, RegistrationSpecialProgramError } from '../../ModalCreateSpecialProgram/types';
import Logger from '../../../../utils/Logger';
import { ICardSpecialProgram } from '../../ScreenUnderConstruction/types';
import ToastNotification from '../../../../components/ToastNotification';
import { putSpecialProgram } from '../../../../services/SpecialProgram';
import Input from '../../../../components/Input';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Checkbox, MenuItem, Select, } from '@mui/material';
import offImage from '../../../../assets/images/imgErro.png'
import { AddPhotoAlternate } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'
import { registerValidation1 } from '../../ModalCreateSpecialProgram/validations';
import ModalCustomBottom from '../../../../components/ModalCustomBottom';

interface IProps {
  open: boolean;
  setOpen: (e: boolean) => void;
  program?: ICardSpecialProgram
  update: () => void;
}

const ModalEditSpecialProgram: React.FC<IProps> = ({ open, setOpen, program, update }) => {
  const { t } = useTranslation();
  const isSm = useIsSm();
  const [registration, setRegistration] = useState<RegistrationSpecialProgram>(new RegistrationSpecialProgram());
  const [registrationError, setRegistrationError] = useState<RegistrationSpecialProgramError>(new RegistrationSpecialProgramError());
  const [loading, setLoading] = useState(false);
  const [imageSet, setImageSet] = useState<File>();
  const [image, setImage] = useState<string>(offImage);
  const navigate = useNavigate()
  const [attemps, setAttemps] = useState(false);

  useEffect(() => {
    if (open) {
      setRegistration({
        title: program?.title ?? '',
        description: program?.description ?? '',
        averagePerformance: program?.averagePerformance,
        language: program?.language ?? '',
        tags: program?.tags,
        email: '',
        attempts: program?.attempts ?? ''
      })
      setImage(program?.image ?? '')

      if (Number(program?.attempts) > 0) {
        setAttemps(true)
      }
    }
  }, [open]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedImage = e.target.files?.[0];
    if (selectedImage) {
      setImageSet(selectedImage)
      setImage(URL.createObjectURL(selectedImage));
    }
  };

  const handleCancel = () => {
    setRegistration(new RegistrationSpecialProgram());
    setRegistrationError(new RegistrationSpecialProgramError());
    setOpen(false);
  };

  const handleEdit = async () => {
    setLoading(true)
    try {
      let countError = 0;
      const validation = await registerValidation1(registration);
      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError(validation as RegistrationSpecialProgramError);
        setLoading(false)
        return;
      }

      if ((attemps && registration.attempts.length <= 0) || (attemps && registration.attempts === '0')) {
        if (registration.attempts === '0') {
          setRegistrationError({ ...registrationError, attempts: 'O valor deve ser maior que 0' });
        } else {
          setRegistrationError({ ...registrationError, attempts: 'Insira a quantidade' });
        }
        setLoading(false)
        return;
      }

      const listEmailRegister: string[] = program?.emailList;

      const data = new FormData();
      if (imageSet) data.append('SpecialProgram.CoverImageUrl', imageSet as File);
      data.append('SpecialProgram.Title', registration.title);
      data.append('SpecialProgram.Description', registration.description);
      data.append('SpecialProgram.AverageRate', registration.averagePerformance);
      data.append('SpecialProgram.Language', registration.language);
      data.append('SpecialProgram.Attempts', attemps ? registration.attempts : '0');
      data.append('SpecialProgram.Tags', registration?.tags);
      listEmailRegister.forEach(e => data.append('SpecialProgram.Emails', e));

      const response = await putSpecialProgram(data, String(program?.id));
      if (response.Success) {
        update();
        if (program?.status === "approved") {
          navigate('/gerenciamento_de_programas_especiais')
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      Logger('ex', 'Houve um erro, tente novamente.');
    }
    setLoading(false)
  };

  const returnContent = () => {
    return (
      <S.ContainerRegister>
        <div className='containerInputsRegister'>
          <div className='boxImageRegister'>
            <h5>{t('Imagem de Capa')}</h5>

            <S.Image style={{ aspectRatio: '4/3.2', width: '100%' }} src={image} />

            <h5 style={{ width: '100%' }}>{t('Trocar Capa')}</h5>

            <S.ButtonImageEdit htmlFor="selecao-arquivo">
              <AddPhotoAlternate style={{ fontSize: '24px', marginRight: '8px', color: "#6A3AE7", marginLeft: '8px' }} />
              <h6 className='textButtonEdit'>{t('ENVIAR IMAGEM DE CAPA')}</h6>
              <S.InputMedia id="selecao-arquivo" onChange={handleChange} type="file" accept="image/*" />
            </S.ButtonImageEdit>
          </div>

          <div className='boxInputsRegister'>
            <Input
              label={t('Título')}
              placeholder='Dê um nome ao programa especial'
              type='titulo'
              value={registration.title}
              onChange={(e) => setRegistration({ ...registration, title: e.target.value })}
              size='small'
              fullWidth
              error={registrationError.title.length > 0}
              helperText={registrationError.title}
              onClick={() => setRegistrationError({ ...registrationError, title: '' })}
              variant='filled'
              style={{ width: '100%' }}
            />

            <Input
              label={t('Descrição')}
              placeholder='Descreva brevemente o objetivo do programa.'
              type='description'
              value={registration.description}
              onChange={(e) => setRegistration({ ...registration, description: e.target.value })}
              size='small'
              fullWidth
              error={registrationError.description.length > 0}
              helperText={registrationError.description}
              onClick={() => setRegistrationError({ ...registrationError, description: '' })}
              variant='filled'
              style={{ width: '100%', marginTop: '16px' }}
            />

            <Input
              label={t('Nota de Aprovação')}
              placeholder='Nota para aprovação. (de 0 a 100%)'
              type='number'
              value={registration.averagePerformance}
              onChange={(e) => setRegistration({ ...registration, averagePerformance: e.target.value })}
              size='small'
              fullWidth
              error={registrationError.averagePerformance.length > 0}
              helperText={registrationError.averagePerformance}
              onClick={() => setRegistrationError({ ...registrationError, averagePerformance: '' })}
              variant='filled'
              style={{ width: '100%', marginTop: '16px' }}
            />

            <FormControl sx={{ m: 1, width: '100%', marginTop: '24px', marginLeft: '0px' }}>
              <InputLabel id='demo-simple-select-helper-label'>{t('Idioma')}</InputLabel>
              <Select
                labelId='demo-simple-select-helper-label'
                id='demo-simple-select-helper'
                variant='standard'
                style={{ display: 'flex', width: '100%' }}
                value={registration.language}
                error={registrationError.language.length > 0}
                onClick={() => setRegistrationError({ ...registrationError, language: '' })}
                onChange={(e) => {
                  setRegistration({ ...registration, language: String(e.target.value) });
                }}
              >
                <MenuItem value={'1'}>{t('Português')}</MenuItem>
                <MenuItem value={'2'}>{t('Inglês')}</MenuItem>
                <MenuItem value={'3'}>{t('Espanhol')}</MenuItem>
                <MenuItem value={'4'}>{t('Francês')}</MenuItem>
              </Select>
            </FormControl>

            <Input
              label={t('Tags')}
              type='tags'
              placeholder={isSm ? 'Insira as tags (separe-as por vírgula)' : 'Adicione tags para facilitar a busca do programa. (separe-as por vírgula)'}
              value={registration.tags}
              onChange={(e) => setRegistration({ ...registration, tags: e.target.value })}
              size='small'
              fullWidth
              error={registrationError.tags.length > 0}
              helperText={registrationError.tags}
              onClick={() => setRegistrationError({ ...registrationError, tags: '' })}
              variant='filled'
              style={{ width: '100%', marginTop: '16px' }}
            />

            <div className='boxSelect'>
              <h1>{t('O participante pode refazer o Programa?')}</h1>

              <div className='switchDiv'>
                <Checkbox
                  checked={attemps}
                  onChange={(event) => setAttemps(event.target.checked)}
                  color='info'
                />
                <h5>{t('Sim')}</h5>

                <Checkbox
                  checked={!attemps}
                  onChange={(event) => setAttemps(!event.target.checked)}
                  color='info'
                />
                <h5>{t('Não')}</h5>
              </div>
            </div>

            {attemps && <Input
              label={t('Quantidade de Tentativas')}
              type='number'
              placeholder='Defina a quantidade de vezes o participante poderá participar'
              value={registration.attempts}
              onChange={(e) => setRegistration({ ...registration, attempts: e.target.value })}
              size='small'
              fullWidth
              error={registrationError.attempts.length > 0}
              helperText={registrationError.attempts}
              onClick={() => setRegistrationError({ ...registrationError, attempts: '' })}
              variant='filled'
              style={{ width: '100%' }}
            />}
          </div>
        </div>

        <div className='containerButtonsRegister'>
          <ButtonMaterial
            style={{ borderColor: theme.colors.secundary, color: theme.colors.secundary, width: isSm ? '100%' : 'auto' }}
            variant='outlined'
            label={t('CANCELAR')}
            onClick={handleCancel}
          />
          <ButtonMaterial
            style={{ backgroundColor: theme.colors.seventh, marginLeft: '24px', width: isSm ? '100%' : 'auto' }}
            variant='contained'
            label={program?.status === "approved" ? t('ENVIAR PARA REVISÃO') : t('SALVAR')}
            onClick={handleEdit}
            loading={loading}
          />
        </div>
      </S.ContainerRegister>
    )
  }

  return (
    <>
      {isSm ? (
        <ModalCustomBottom
          open={open}
          onClose={handleCancel}
          onClick={() => setOpen(true)}
          title={t('Editar Programa')}
          showIcon={false}
          buttonsEnabled={false}
          confirmationButtonText={t('ENVIAR CONVITES')}
          textDeclineButton={t('CANCELAR')}
          childrenContent={returnContent()}
          styleButtons={{ justifyContent: 'center' }}
          buttonClose
        />
      ) : (
        <ModalCustom
          open={open}
          onClose={handleCancel}
          onClick={() => setOpen(true)}
          title={t('Editar Programa')}
          buttonsEnabled={false}
          confirmationButtonText={t('ENVIAR CONVITES')}
          textDeclineButton={t('CANCELAR')}
          childrenContent={returnContent()}
          style={{ height: 'auto' }}
        />
      )}
    </>
  );
};

export default ModalEditSpecialProgram
