import styled from 'styled-components'

export const ContainerModalQuiz = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  left: 50%;
  position: absolute;
  width:  ${(props) => (props.active ? '400px' : '720px')}; 
  max-height: 90%;
  overflow:auto; 
  transform: translate(-50%, -50%);
  background: #efefef;
  border-radius: 4px;
  box-shadow: 24;
  padding: 24px;

  .initialContainer{
    display: flex;
    width: 100%;
    height: 5vh;
  }

  .icon {
    color: ${(props) => props.theme.colorsBase.black};
    width: 20px;
    height: 20px;
    top: 24px;
    right: 24px;
    position: absolute;
  }

  .buttonClose {
    display: flex;
    position: absolute;
    top: 0px;
    right: 0px;
    align-items: center;
    flex-direction: row;
    border: none;
    background-color: transparent;
  
    &:hover {
      opacity: 0.8;
    }

    .icon {
      color: ${(props) => props.theme.colorsBase.black};
      width: 2rem;
      height: 2rem;
    }
  }


  @media (min-width: 1600px) {
    width: 900px;

  }

  @media (max-width: 680px) {
    width: 100%;


    height: 100%;
    max-height: 100%;


    transform: none;
    top: 0;
    left: 0;
    position: relative;
    border-radius: 0px;
    // margin-top: 50px;
    padding-top: 70px;
    // padding: 24px 16px 24px 16px;
    justify-content: flex-start;

    .buttonClose {
      display: none;
    }

    .button {
      flex-direction: column;
      justify-content: center;
      padding: 0px;
      margin-top: 0px;

      .buttonTop {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 24px;
      }
    }

    h4 {
      margin-bottom: 16px;
    }
  }
`

export const containerImage = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-bottom: 16px;
  background: #efefef;
  overflow: hidden;

   img {
    border: solid 1px ${(props) => props.theme.colorsBase.gray6};
    z-index: 1;
    object-fit: none; 
    height: 30rem;
    width: 50rem;
    object-fit: cover; 
   }
   
   @media (max-width: 1600px) {
    img{
      width: 37.5rem;
    }
  }
 
`

export const containerButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  .Input{
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
}

  .NovaFoto{
    margin-top: .5rem;
    display: flex;
    align-items: center;
    border: none;
    gap: .5rem;
    font-size: 18px;
    color: ${(props) => props.theme.colors.black4};
  }
  .NovaFoto:hover {
      opacity: 0.8;
    }

  .Salvar{
    margin-top: .5rem;
    display: flex;
    align-items: center;
    border: none;
    gap: .5rem;
    font-size: 18px;
    color: ${(props) => props.theme.colors.twelve};
    background-color: ${(props) => props.theme.colors.primary};
    padding: 0.5rem 6rem;
    border-radius: 4px;
  }

  .Salvar:hover {
      opacity: 0.8;
    }
 
`
