import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
  justify-content: flex-start;

  h5 {
    height: 100%;
    margin-bottom: 24px;
    line-height: 18px;
    letter-spacing: 0.18px;
    text-align: center;
    font-size: ${responsiveFont(12)};
    margin-top: auto;
    padding-top: 40px;
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }
`

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  padding: 0;
  margin-top: 16px;

  h1 {
    margin-bottom: 32px;
    line-height: 18px;
    letter-spacing: 0.18px;
    text-align: center;
    font-size: ${responsiveFont(20)};
    color: ${(props) => props.theme.colors.secundary};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(22)};
    }
  }

  p {
    line-height: 18px;
    letter-spacing: 0.18px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(14)};
      text-align: left;
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }

 
  @media (max-width: 680px) {
    width: 100%;
    padding: 0px 16px;
    margin-top: 90px;
  }
`
