import React, { useState } from 'react';

import { useIsSm } from '../../hooks/useIsSm';

import InspectionView from './view';

const Inspection: React.FC = () => {
  const [tab, setTab] = useState(0)
  const isSm = useIsSm()

  return (
    <InspectionView
      tab={tab}
      setNewTab={setTab}
      variant={isSm ? 'fullWidth' : 'standard'}
    />
  );
}

export default Inspection;
