import React, { useMemo } from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { Assessment } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { useStartEndDate } from '../../../../../hooks/useStartEndDate';
import ChallengeInpactGraph from '../../../../../components/Graph/ChallengeInpactGraph';
import { IPerformanceChallenge } from '../../../../../services/Dashboard/ChallengesDashboard/types';
import { useChalengeActiveDetails } from '../../../../../services/ChallengesManagement/ActiveDetails';

interface IProps {
  id: number;
}

const ApprovedInfo: React.FC<IProps> = ({ id }) => {
  const { t } = useTranslation()
  const { end } = useStartEndDate()

  const chalengeDetailsQuery = useChalengeActiveDetails({ Id: id });


  const data = useMemo(() => chalengeDetailsQuery.data?.Data.ChallengePerformance.map<IPerformanceChallenge>(
    ({ CompletedCount, Date: stringDate, StartedCount }) => {
      const timezoneDate = new Date(stringDate)
      const userTimezoneOffset = timezoneDate.getTimezoneOffset() * 60000;
      const date = new Date(timezoneDate.getTime() - userTimezoneOffset);

      return {
        StartedCount: StartedCount,
        EndedCount: CompletedCount,
        DayOfMonth: date.getDate(),
        Month: date.getMonth(),
      }
    }
  ), [chalengeDetailsQuery])

  return (
    <Paper component={Stack} p={2} gridArea="info" spacing={2} maxWidth="510px">
      <Stack direction="row" alignItems='baseline' spacing={1}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Assessment sx={{ color: '#DBDADA' }} />
          <Typography variant='h6' fontWeight="400" color='#201E1E'>{t("Desempenho do Desafio")}</Typography>
        </Stack>
        <Typography variant='caption' color='#201E1E'>({t("Nos últimos 15 dias")})</Typography>
      </Stack>

      <Box height="128px">
        <ChallengeInpactGraph endDate={end} data={data} />
      </Box>
    </Paper>
  )
}

export default ApprovedInfo;
