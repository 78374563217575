import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin-top: 56px;
`

export const ContainerContent = styled.div`
  padding: 0 40px;
  width: 100%;

  @media (max-width: 680px) {
    padding: 0;
  }
`

export const ContainerPoints = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: center;
  padding: 24px 0;
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 16px 16px 0px 0px;
  
  @media (max-width: 680px) {
    flex-direction: column;
    padding: 24px 40px;
  }
`
