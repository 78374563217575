import styled from 'styled-components'
import { theme } from '../../styles/theme'
import { responsiveFont } from '../../utils/UtilsGlobal'


export const EmptyQuiz = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

      .textEmptyQuiz {
        text-align: center;
        font-size: ${responsiveFont(16)};
        color: ${theme.colors.fourth};
        font-weight: 400;
        font-family: 'Barlow', Regular;
  
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }
`