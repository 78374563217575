import React, { useState } from 'react';

import { useIsSm } from '../../hooks/useIsSm';

import ContactsView from './view';

const Contacts: React.FC = () => {
  const [tab, setTab] = useState(0)
  const isSm = useIsSm()

  return (
    <ContactsView
      tab={tab}
      setNewTab={setTab}
      variant={isSm ? 'scrollable' : 'standard'}
    />
  );
}

export default Contacts;