import React, { useEffect, useState, ChangeEvent, useRef, DragEvent } from 'react';
import { useAuth } from '../../../contexts/Auth';
import * as S from './styles'
import { Add, ArrowBack, ArrowForward, Done, Edit, UploadFile, } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonMaterial from '../../../components/Button';
import { theme } from '../../../styles/theme';
import ToastNotification from '../../../components/ToastNotification';
import ModalCustom from '../../../components/ModalCustom';
import { Tooltip, MenuItem, Select, InputAdornment } from '@mui/material'
import { IArrayQuestions, IQuizAlternatives } from '../../../services/Challenges/types';
import Input from '../../../components/Input';
import { RegistrationAdditionalInformation, RegistrationAdditionalInformationError, RegistrationIdentification, RegistrationIdentificationError, RegistrationQuiz, RegistrationQuizError } from './../types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { mediaTypeValidation, registerChallengeValidation, } from './../validations';
import offImage from '../../../assets/images/imgErro.png'
import ModalChallengeContent from '../../../components/ModalChallengeContent';
import ChallengeItemDesktop from '../../../components/ChallengeItemDesktop';
import ModalRegistrationAndChallengeEditing from '../../../components/ModalRegistrationAndChallengeEditing';
import ModalRegistrationAndQuizEditing from '../../../components/ModalRegistrationAndQuizEditing';
import { useIsSm } from '../../../hooks/useIsSm';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { uploadFiles } from '../../../services/Users';
import ImageAdjustment from '../../../components/ImageAdjustment';
import jwt_decode from 'jwt-decode'
import ReactAudioPlayer from 'react-audio-player';
import CardQuizResume from './../CardQuizResume';
import { responsiveFont } from '../../../utils/UtilsGlobal';
import QuestionsQuiz from '../../EditAndViewChallenge/questionsQuiz';
import ModalSendCustomCover from '../../../components/ModalSendCustomCover';
import { postContent } from '../../../services/SpecialProgram';
import { postChallenge } from '../../../services/Challenges';
import { getSpecialAdminAwards } from '../../../services/Product';
import { ISpecialAdminAwards } from '../../../services/Product/types';

const ViewCardChallenge = ({ openChallengeModal, setOpenChallengeModal, specialProgramId, update }: { update: () => void, specialProgramId?: string, openChallengeModal: boolean, setOpenChallengeModal: (e: boolean) => void }) => {
  const { user, role } = useAuth();
  const { t } = useTranslation();
  const isSm = useIsSm()
  const navigate = useNavigate()

  const [registration, setRegistration] = useState<RegistrationIdentification>(new RegistrationIdentification());
  const [registrationError, setRegistrationError] = useState<RegistrationIdentificationError>(new RegistrationIdentificationError());
  const [loadingRegister, setLoadingRegister] = useState(false)
  const [registrationAdditionalInformation, setRegistrationAdditionalInformation] = useState<RegistrationAdditionalInformation>(new RegistrationAdditionalInformation());
  const [registrationAdditionalInformationError, setRegistrationAdditionalInformationError] = useState<RegistrationAdditionalInformationError>(new RegistrationAdditionalInformationError());
  const [registrationStep, setRegistrationStep] = useState(1);
  const [openConfirmClosingModal, setOpenConfirmClosingModal] = useState(false)
  const [openSubmitReviewModal, setOpenSubmitReviewModal] = useState(false)
  const [openConfirmSaveModal, setOpenConfirmSaveModal] = useState(false)

  const [imageSet, setImageSet] = useState<File | null>();
  const [image, setImage] = useState<string>(offImage);
  const [imageError, setImageError] = useState<string>('');
  const [mediaSet, setMediaSet] = useState<File>();
  const [mediaError, setMediaError] = useState<string>('');

  const [openQuizModal, setOpenQuizModal] = useState(false)
  const [status, setStatus] = useState<boolean | null>(null);
  const [registrationQuiz, setRegistrationQuiz] = useState<RegistrationQuiz>(new RegistrationQuiz());
  const [registrationQuizError, setRegistrationQuizError] = useState<RegistrationQuizError>(new RegistrationQuizError());
  const [openSendChallengeModal, setOpenSendChallengeModal] = useState(false)
  const [audioSet, setAudioSet] = useState<File | string>();
  const [modalContent, setModalContent] = useState(false);
  const [media, setMedia] = useState<string>(offImage);
  const [extensionMedia, setExtensionMedia] = useState<string>('');

  const [numberOfAlternatives, setNumberOfAlternatives] = useState(3);
  const { token } = useParams();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [sponsoredAd, setSponsoredAd] = useState(false)
  const [openAdditionalInformationModal, setOpenAdditionalInformationModal] = useState(false)
  const [urlFileImage, setUrlFileImage] = useState<string | null>(null)
  const [urlFileMidia, setUrlFileMidia] = useState<string>('')
  const [listQuestions, setListQuestions] = useState<IArrayQuestions[] | []>([])
  const [openModalImageAdjustment, setOpenModalImageAdjustment] = useState(false)
  const [openModalInformationConfirmation, setOpenModalInformationConfirmation] = useState(false)
  const [page, setPage] = useState(1)
  const [questionSelected, setQuestionSelected] = useState(-1);
  const [openQuizModalEdit, setOpenQuizModalEdit] = useState(false);
  const [openModalSendCustomCover, setOpenModalSendCustomCover] = useState(false)

  const [awardsList, setAwardsList] = useState<ISpecialAdminAwards[]>([])
  const [openAddSpecialPrizeModal, setOpenAddSpecialPrizeModal] = useState(false)


  const dispararCliqueInputMedia = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  useEffect(() => {
    setStatus(null)
    setSponsoredAd(false)
    console.log(mediaError)

    if (role?.includes('CurarManager')) retrieveAwards()
  }, []);

  useEffect(() => {
    if (token) {
      setOpenChallengeModal(true)
    }
  }, [token]);

  const handleChangeMedia = (e: ChangeEvent<HTMLInputElement>) => {
    setMediaError('')
    const selectedMedia = e.target.files?.[0];
    if (selectedMedia) {
      setMediaSet(selectedMedia)
      setMedia(URL.createObjectURL(selectedMedia))
      setExtensionMedia(selectedMedia.type)
    }
  };

  const onDragOver = (e: DragEvent<HTMLLabelElement>) => {
    const event = e as DragEvent;
    event.stopPropagation();
    event.preventDefault();
  }

  const resetFileInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleChangeDrop = (e: File) => {
    setImageError('')
    setOpenModalImageAdjustment(true)
    setImageSet(e)
    setImage(URL.createObjectURL(e));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setImageError('')
    setOpenModalImageAdjustment(true)
    const selectedImage = e.target.files?.[0];
    if (selectedImage) {
      setImageSet(selectedImage)
      setImage(URL.createObjectURL(selectedImage));

      if (inputFileRef.current) {
        inputFileRef.current.value = '';
      }
    }
  };

  const handleChangeAudio = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedAudio = e.target.files?.[0];
    if (selectedAudio) {
      setAudioSet(selectedAudio)
    }
  };

  const handleChangeModalB = (e: File) => {
    setImageError('')
    setOpenModalImageAdjustment(false)
    if (e) {
      setImageSet(e)
      setImage(URL.createObjectURL(e));
    }
  };

  const handleChangeModalCloseB = () => {
    setImageError('')
    setOpenModalImageAdjustment(false)
    if (!openModalInformationConfirmation) {
      setImageSet(undefined)
      setImage('');
    }
  };

  const handleBackButton = () => {
    if (registrationStep === 1) {
      setOpenChallengeModal(false)
    } else {
      setRegistrationStep(registrationStep - 1)
    }
  };

  const saveSpecialAdminAwards = () => {
    if (registrationAdditionalInformation.award) {
      setOpenModalInformationConfirmation(true)
      setOpenAddSpecialPrizeModal(false)
    } else {
      setRegistrationAdditionalInformationError({ ...registrationAdditionalInformationError, award: t('Selecione um premio') ?? 'Selecione um premio' })
    }
  };

  const contentSpecialAdminAwards = () => {
    return (
      <S.InputSelect style={{ width: isSm ? '100%' : '60%', margin: isSm ? '0px 0px' : '10% 0px' }}>
        <div className="input">
          <FormControl sx={{ display: 'flex', m: 1, width: '100%', marginTop: '16px' }}>
            <InputLabel id="demo-simple-select-helper-label">{t('Premio Especial')}</InputLabel>
            <Select
              MenuProps={{
                MenuListProps: {
                  sx: { maxHeight: '200px', maxWidth: '100%', overflowY: 'auto' }
                }
              }}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              variant="standard"
              style={{ display: 'flex', width: '100%' }}
              value={registrationAdditionalInformation.award}
              error={registrationAdditionalInformationError.award.length > 0}
              onClick={() => setRegistrationAdditionalInformationError({ ...registrationAdditionalInformationError, award: '' })}
              onChange={(e) => setRegistrationAdditionalInformation({ ...registrationAdditionalInformation, award: e.target.value })}
            >
              {awardsList.map((item) => (
                <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
              ))}
              {awardsList.length <= 0 && <S.MessageBlank>{t('Sem Prêmios Disponíveis')}</S.MessageBlank>}
            </Select>
          </FormControl>
        </div>
      </S.InputSelect>
    )
  };

  const validationQuiz1 = (arraytotalResponse: string[]) => {
    try {
      if (registrationQuiz.question.length <= 0
        || registrationQuiz.response1.length <= 0
        || registrationQuiz.response2.length <= 0
        || registrationQuiz.response3.length <= 0
        || String(arraytotalResponse[Number(registrationQuiz.trueAnswer) - 1]).length <= 0
      ) {
        setRegistrationQuizError({
          questionType: '',
          question: registrationQuiz.question.length <= 0 ? 'Campo obrigatório' : '',
          response1: registrationQuiz.response1.length <= 0 ? 'Campo obrigatório' : '',
          response2: registrationQuiz.response2.length <= 0 ? 'Campo obrigatório' : '',
          response3: registrationQuiz.response3.length <= 0 ? 'Campo obrigatório' : '',
          response4: '',
          response5: '',
          trueAnswer: String(arraytotalResponse[Number(registrationQuiz.trueAnswer) - 1]).length <= 0 ? 'Resposta não preenchida' : ''
        });
        setLoadingRegister(false);
        return true;
      }
    } catch (err) {
      console.error("Error", err)
    }
  };

  const validationQuiz2 = () => {
    try {
      if (registrationQuiz.question.length <= 0) {
        setRegistrationQuizError({
          questionType: '',
          question: registrationQuiz.question.length <= 0 ? 'Campo obrigatório' : '',
          response1: '',
          response2: '',
          response3: '',
          response4: '',
          response5: '',
          trueAnswer: '',
        });
        setLoadingRegister(false);
        return true;
      }
      if (status === null) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Selecione a Resposta Correta')
        });
        setLoadingRegister(false);
        return true;
      }
    } catch (err) {
      console.error("Error", err)
    }
  };

  const validationQuiz = (arraytotalResponse: string[]) => {
    try {
      if (registrationQuiz.questionType === '1') {
        if (registrationQuiz.trueAnswer === '') {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Selecione a Resposta Correta')
          });
          setLoadingRegister(false);
          return true;
        }

        if (validationQuiz1(arraytotalResponse)) return true;
      } else if (validationQuiz2()) return true;
    } catch (err) {
      console.error("Error", err)
    }
  };

  const handleRegistrationQuiz = async (finish: boolean) => {
    setLoadingRegister(true);
    try {
      const arraytotalResponse = [registrationQuiz.response1, registrationQuiz.response2, registrationQuiz.response3, registrationQuiz.response4, registrationQuiz.response5];

      if (validationQuiz(arraytotalResponse)) return;

      let urlFile: string = ''

      if (audioSet) {
        if (typeof audioSet !== 'string') {
          const data = new FormData();
          data.append('file', audioSet as File);
          const responseAudio = await uploadFiles(data, 4);
          if (responseAudio.Success) {
            urlFile = responseAudio.Data.FileUrl
          } else {
            ToastNotification({
              id: 'error',
              type: 'error',
              message: t('Houve um erro ao salvar o audio, tente novamente.'),
              errorMessage: responseAudio.Message,
              errors: responseAudio.Errors
            });
            setLoadingRegister(false);
            return;
          }
        } else {
          urlFile = audioSet
        }
      }


      let arrayResponse: IQuizAlternatives[] = [];

      if (registrationQuiz.questionType === '1') {
        arraytotalResponse.map((item, index) => {
          if (item) {
            arrayResponse.push({
              "Answer": String(item),
              "RightAnswer": registrationQuiz.trueAnswer === String(index + 1) ? true : false
            })
          }
        })
      } else {
        arrayResponse = [
          {
            "Answer": "Verdadeiro",
            "RightAnswer": status === null ? false : status
          },
          {
            "Answer": "Falso",
            "RightAnswer": status === null ? false : !status
          }
        ]
      }


      const objectQuestion = {
        QuestionType: registrationQuiz.questionType,
        Question: registrationQuiz.question,
        AudioUrl: urlFile,
        QuizAlternatives: arrayResponse
      }

      if (questionSelected >= 0) {
        const listQuestionsTemp: IArrayQuestions[] = listQuestions;

        listQuestionsTemp.splice(questionSelected, 0, objectQuestion)

        listQuestionsTemp.splice(questionSelected + 1, 1)
        setListQuestions(listQuestionsTemp)
      } else {
        const listQuestionsTemp: IArrayQuestions[] = listQuestions;

        listQuestionsTemp.push(objectQuestion)

        setListQuestions(listQuestionsTemp)
      }

      setQuestionSelected(-1)
      setAudioSet(undefined)
      urlFile = ''
      handleCleanQuestion()
      setNumberOfAlternatives(3)
      if (finish) {
        setOpenQuizModal(false)
        setOpenQuizModalEdit(false)
        validationQuizModalClose()
      }
    } catch (err) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao cadastrar o quiz, tente novamente.')
      });
      console.error("Error", err)
    }
    setLoadingRegister(false);
  };

  const handleRegistration = async () => {
    setLoadingRegister(true);
    try {
      let countError = 0;

      if (registrationStep === 1) {
        const responseImage = await handleRegistrationImage();
        if (responseImage === false) {
          setLoadingRegister(false);
          return;
        }
        setRegistrationStep(2)
      } else if (registrationStep === 2) {
        const responseImage = await handleRegistrationImage();
        if (responseImage === false) {
          setLoadingRegister(false);
          return;
        }

        setRegistrationStep(3)
      } else if (registrationStep === 3) {
        const validation = await mediaTypeValidation(registration);

        if (typeof validation !== 'boolean') {
          countError++;
        }
        if (countError > 0) {
          setRegistrationError(validation as RegistrationIdentificationError);
          setLoadingRegister(false);
          if (!mediaSet) {
            setMediaError(t('Campo obrigatório') || 'Campo obrigatório')
          }
          return;
        }

        const responseMedia = await handleRegistrationMedia();
        if (responseMedia === false) {
          setLoadingRegister(false);
          return;
        }

        setOpenChallengeModal(false)
        setOpenSendChallengeModal(true)
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: specialProgramId ? t('Houve um erro ao cadastrar o conteúdo, tente novamente.') : t('Houve um erro ao cadastrar o desafio, tente novamente.')
      });
    }
    setLoadingRegister(false);
  };

  const handleRegistrationImage = async () => {
    try {
      if (imageSet) {
        const data = new FormData();
        data.append('file', imageSet);
        const responseImage = await uploadFiles(data, 2);
        if (responseImage.Success) {
          setUrlFileImage(responseImage.Data.FileUrl)
          return true;
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Houve um erro ao salvar a imagem, tente novamente.'),
            errorMessage: responseImage.Message,
            errors: responseImage.Errors
          });
          setLoadingRegister(false);
          return false;
        }
      }
      return true;
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao salvar a imagem, tente novamente.')
      });
      return false;
    }
  };

  const handleRegistrationMedia = async () => {
    try {
      if (mediaSet) {
        const data = new FormData();
        data.append('file', mediaSet);
        const responseMidia = await uploadFiles(data, Number(registration.mediaType));
        if (responseMidia.Success) {
          setUrlFileMidia(responseMidia.Data.FileUrl)
          return true;
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Houve um erro ao salvar a mídia, tente novamente.'),
            errorMessage: responseMidia.Message,
            errors: responseMidia.Errors
          });
          setLoadingRegister(false);
          return false;
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Selecione uma midia')
        });
        setLoadingRegister(false);
        return false;
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao salvar a mídia, tente novamente.'),
      });
      return false;
    }
  };

  const handleValidationTotal = async () => {
    setLoadingRegister(true);
    try {
      let countError = 0;
      const validation = await registerChallengeValidation(registration);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError(validation as RegistrationIdentificationError);
        setLoadingRegister(false);
        return;
      }

      setOpenConfirmSaveModal(true)
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: specialProgramId ? t('Houve um erro ao cadastrar o conteúdo, tente novamente.') : t('Houve um erro ao cadastrar o desafio, tente novamente.')
      });
    }
    setLoadingRegister(false);
  };

  const handleRegistrationTotal = async () => {
    setLoadingRegister(true);
    try {
      const bodyData = {
        SpecialProgramId: specialProgramId ?? '',
        Content: {
          SponsoredAdvertisement: sponsoredAd,
          SpecialGiftId: registrationAdditionalInformation.award,
          Title: registration.name,
          Language: registration.language,
          ContentSourceUrl: registrationAdditionalInformation.source,
          ContentSourceDetails: registrationAdditionalInformation.sourceNotes,
          CoverImageUrl: urlFileImage ? urlFileImage : null,
          ContentFileUrl: urlFileMidia,
          ContentType: registration.mediaType,
          Questions: listQuestions.length > 0 ? listQuestions : [],
          Tags: []
        }
      };

      const response = await postContent(bodyData);
      if (response.Success) {
        update()
        setOpenQuizModal(false)
        setOpenChallengeModal(false)
        setOpenAdditionalInformationModal(false)
        setOpenSubmitReviewModal(true)
        clearData()
        setOpenConfirmSaveModal(false)
        setOpenModalInformationConfirmation(false)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: specialProgramId ? t('Houve um erro ao cadastrar o conteúdo, tente novamente.') : t('Houve um erro ao cadastrar o desafio, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: specialProgramId ? t('Houve um erro ao cadastrar o conteúdo, tente novamente.') : t('Houve um erro ao cadastrar o desafio, tente novamente.')
      });
    }
    setLoadingRegister(false);
  };

  const handleRegistrationTotalChallenge = async () => {
    setLoadingRegister(true);
    try {
      const bodyData = {
        SponsoredAdvertisement: sponsoredAd,
        SpecialGiftId: registrationAdditionalInformation.award,
        Title: registration.name,
        Language: registration.language,
        ContentSourceUrl: registrationAdditionalInformation.source,
        ContentSourceDetails: registrationAdditionalInformation.sourceNotes,
        CoverImageUrl: urlFileImage ? urlFileImage : null,
        ContentFileUrl: urlFileMidia,
        ContentType: registration.mediaType,
        Questions: listQuestions.length > 0 ? listQuestions : [],
        Tags: []
      };

      const response = await postChallenge(bodyData);
      if (response.Success) {
        update()
        setOpenQuizModal(false)
        setOpenChallengeModal(false)
        setOpenAdditionalInformationModal(false)
        setOpenSubmitReviewModal(true)
        clearData()
        setOpenConfirmSaveModal(false)
        setOpenModalInformationConfirmation(false)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao cadastrar o desafio, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao cadastrar o desafio, tente novamente.')
      });
    }
    setLoadingRegister(false);
  };

  const retrieveAwards = async () => {
    try {
      const bodyData = {
        PageNumber: 1,
        PageSize: 999
      };
      const response = await getSpecialAdminAwards(bodyData);
      if (response.Success) {
        setAwardsList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os prêmios, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os prêmios, tente novamente.')
      });
    }
  };

  const renderExtension = (type: number) => ({
    1: "video/*",
    2: "image/*",
    4: "audio/*",
  }[type]);

  const clearData = async () => {
    setRegistration({
      name: '',
      language: '',
      difficultyLevel: '',
      category: '',
      tags: '',
      mediaType: '',
    })

    setRegistrationAdditionalInformation({
      duration: '',
      source: '',
      sourceNotes: '',
      award: '',
    })

    setRegistrationStep(1)

    setImageSet(null)
    setImage(offImage)
    setMediaSet(undefined)
    setOpenModalInformationConfirmation(false)
    setOpenAdditionalInformationModal(false)
    setListQuestions([])
    setPage(1)
    setQuestionSelected(-1)
    setOpenAddSpecialPrizeModal(false)
    if (!specialProgramId) {
      navigate(`/desafios`)
    }
  };

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const dispararCliqueInput = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const [nameUser, setNameUser] = useState('')

  useEffect(() => {
    const payload: { Name: string } = jwt_decode(user?.Token ?? '')
    setNameUser(payload.Name)
  }, [])

  const validationColumnReverse = () => {
    return page === 3 ? 'column-reverse' : 'column'
  };

  const contentInformationConfirmation = () => {
    return (
      <S.ContainerInformationConfirmation>
        <div className='containerContentInformationConfirmation'>
          <S.BoxInformationConfirmation>
            <h3 className='textBoxInformationConfirmation'>{t('Pré-Visualização')}:</h3>

            <S.CardChallengeInformationConfirmation>
              <ChallengeItemDesktop
                onClick={() => { }}
                typeChallenge={9}
                item={{
                  CoverImageUrl: image,
                  Language: '1',
                  AuthorName: nameUser,
                  Title: registration.name,
                  Id: ''
                }}
                style={{ marginBottom: '0px' }}
              />
            </S.CardChallengeInformationConfirmation>
          </S.BoxInformationConfirmation>

          <S.BoxInformationConfirmation style={{ width: '100%' }}>
            <h3 className='textBoxInformationConfirmation'>{t('Informações')}:</h3>

            {contentInformationConfirmationData()}
          </S.BoxInformationConfirmation>
        </div>

        <div className='containerButtonsInformationConfirmation'>
          <ButtonMaterial
            style={{ borderColor: theme.colors.secundary, color: theme.colors.secundary }}
            variant="outlined"
            label={t('CANCELAR')}
            onClick={() => setOpenConfirmClosingModal(true)}
          />

          <ButtonMaterial
            style={{ backgroundColor: theme.colors.seventh, marginLeft: '24px' }}
            variant="contained"
            label={t('ENVIAR PARA REVISÃO')}
            onClick={handleValidationTotal}
            loading={loadingRegister}
          />
        </div>
      </S.ContainerInformationConfirmation>
    )
  };

  const contentInformationConfirmationDataPage1 = () => {
    return (
      <S.ContainerInformationConfirmationDataPage1>
        <div className='boxInformationConfirmationDataImage'>
          <S.CoverStepPage1>
            <h5 className='title'>{t('Imagem de Capa')}</h5>

            <S.BackgroundImageProfilePage1 htmlFor="selecao-arquivo">
              <img style={{ aspectRatio: '13/9' }} src={image} alt='' />

              <div className='iconBackground'>
                <UploadFile className="iconCam" />
              </div>

              <S.Input id="selecao-arquivo" onChange={handleChange} type="file" accept="image/*" ref={inputFileRef} />
            </S.BackgroundImageProfilePage1>
          </S.CoverStepPage1>
        </div>

        <div className='boxInformationConfirmationDataInputs'>
          {contentInputsPage1()}

          {contentAdditionalInformation()}
        </div>
      </S.ContainerInformationConfirmationDataPage1>
    )
  };

  const contentInformationConfirmationDataPage2 = () => {
    return (
      <S.ContainerInformationConfirmationDataPage2>
        <S.BoxTitleB>
          <div className='boxText' style={{ marginTop: isSm ? '0px' : '-12px' }}>
            <h2>{t('Conteúdo')}:</h2>
          </div>

          <div className='doubleContent'>
            <div className='boxText'>
              <h2>{specialProgramId ? t('Tipo de Conteúdo') : t('Tipo de Desafio')}</h2>
              <h1>{registration.mediaType === "1" && t('Video')} {registration.mediaType === "2" && t('Imagem')} {registration.mediaType === "3" && t('Texto')} {registration.mediaType === "4" && t('Audio')}</h1>
            </div>

            <div className='boxText'>
              <h2>{t('Contém anúncio patrocinado?')}</h2>
              <h1>{sponsoredAd ? t('Sim') : t('Não')}</h1>
            </div>
          </div>
        </S.BoxTitleB>

        <S.ContainerDataB>
          <S.InputMedia id="selecao-arquivo" accept={renderExtension(Number(registration.mediaType))} onChange={handleChangeMedia} type="file" ref={inputRef} />
          {registration.mediaType === "1" && (
            <video style={{ aspectRatio: '16/9', width: '100%' }}
              id="my-player"
              controls
              controlsList="nodownload"
              preload="auto"
              data-setup='{}'>
              <source src={media} ></source>
              <track src="captions_en.vtt" kind="captions" label="english_captions" />
            </video>
          )}

          {registration.mediaType === "2" && (
            <S.ImageContent style={{ aspectRatio: '16/9' }} src={media} />
          )}

          {registration.mediaType === "4" && (
            <ReactAudioPlayer
              src={media}
              controls
              style={{ margin: '48px 0px', width: '100%' }}
            />
          )}
        </S.ContainerDataB>
      </S.ContainerInformationConfirmationDataPage2>
    )
  };

  const contentInformationConfirmationDataPage3 = () => {
    return (
      <S.ContainerInformationConfirmationDataPage3>
        <h2 className='titlePage3'>{t('Perguntas')}</h2>

        {listQuestions.length > 0 ? (
          <div className='listQuiz'>
            {listQuestions.map((item, index) => (
              <CardQuizResume key={item.Id} item={item} index={index} onClickDelete={() => deleteQuestion(index)} />
            ))}
          </div>
        ) : <h2 className='textNotFountQuiz'>{t('Você optou por não adicionar quiz, a equipe da curar saúde poderá adicionar por você. Caso deseje adicionar, clique em "Criar Quiz".')}</h2>}
      </S.ContainerInformationConfirmationDataPage3>
    )
  };

  const contentInformationConfirmationDataButtonsPage2 = () => {
    return (
      <ButtonMaterial
        style={{ borderColor: theme.colors.seventh, color: theme.colors.seventh, border: 'dashed', borderWidth: '1px', width: isSm ? '100%' : 'auto', marginTop: isSm ? '16px' : 0 }}
        variant="outlined"
        label={specialProgramId ? t('Editar Conteúdo') : t('Editar Desafio')}
        icon={<Edit style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.seventh }} />}
        onClick={() => dispararCliqueInputMedia()}
      />
    )
  };

  const contentInformationConfirmationDataButtonsPage3 = () => {
    return (
      <>
        {page < 3 ?
          <ButtonMaterial
            style={{ borderColor: theme.colors.secundary, color: theme.colors.secundary, marginLeft: page > 1 ? '0px' : 'auto', width: isSm ? '100%' : 'auto', marginTop: isSm ? '16px' : 0 }}
            variant="outlined"
            label={t('PRÓXIMO')}
            iconRight={<ArrowForward style={{ fontSize: '24px', marginLeft: '8px', color: theme.colors.secundary }} />}
            onClick={() => setPage(page + 1)}
          />
          : <ButtonMaterial
            style={{ borderColor: theme.colors.seventh, color: theme.colors.seventh, border: 'dashed', borderWidth: '1px', width: isSm ? '100%' : 'auto', marginTop: isSm ? '16px' : 0 }}
            variant="outlined"
            label={listQuestions.length > 0 ? t('Editar Perguntas') : t('Criar Perguntas')}
            icon={<Edit style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.seventh }} />}
            onClick={createOrEditQuiz}
          />}
      </>
    )
  };

  const contentInformationConfirmationDataButtons = () => {
    return (
      <div className='containerButtonsInformationConfirmationData' style={{ flexDirection: isSm ? validationColumnReverse() : 'row' }}>
        {page > 1 &&
          <ButtonMaterial
            style={{ borderColor: theme.colors.secundary, color: theme.colors.secundary, width: isSm ? '100%' : 'auto', marginTop: isSm ? '16px' : 0 }}
            variant="outlined"
            label={t('ANTERIOR')}
            icon={<ArrowBack style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.secundary }} />}
            onClick={() => setPage(page - 1)}
          />
        }

        {page === 2 && contentInformationConfirmationDataButtonsPage2()}

        {contentInformationConfirmationDataButtonsPage3()}
      </div>
    )
  };

  const contentInformationConfirmationData = () => {
    return (
      <S.ContainerInformationConfirmationData>
        {isSm && <h2 className='textTitleInformationConfirmation'>{t('Confirmação de Informações')}</h2>}

        <S.ContainerQuestionCountInformationConfirmation>
          <S.ButtonQuestion active={page === 1}>
            <h5 className='textbutton'>1</h5>
          </S.ButtonQuestion>

          <S.ButtonQuestion active={page === 2}>
            <h5 className='textbutton'>2</h5>
          </S.ButtonQuestion>

          <S.ButtonQuestion active={page === 3}>
            <h5 className='textbutton'>3</h5>
          </S.ButtonQuestion>
        </S.ContainerQuestionCountInformationConfirmation>

        {page === 1 && contentInformationConfirmationDataPage1()}

        {page === 2 && contentInformationConfirmationDataPage2()}

        {page === 3 && contentInformationConfirmationDataPage3()}

        {contentInformationConfirmationDataButtons()}
      </S.ContainerInformationConfirmationData>
    )
  };

  const createOrEditQuiz = () => {
    handleCleanQuestion()
    setNumberOfAlternatives(3)
    setAudioSet(undefined)

    if (listQuestions.length > 0) {
      setOpenQuizModalEdit(true)
    } else {
      setOpenQuizModal(true)
    }
  };

  const validationQuizModalClose = () => {
    if (!openModalInformationConfirmation) {
      setOpenAdditionalInformationModal(true)
    }
  };

  const deleteQuestion = async (question: number) => {
    if (question >= 0) {
      const newArray = [...listQuestions];
      newArray.splice(question, 1);
      setListQuestions(newArray)
    } else {
      setListQuestions([])
    }
  };



  const contentAdditionalInformation = () => {
    return (
      <>
        <Tooltip enterTouchDelay={1} title={t("Coloque aqui o link do site que você usou. Não esqueça de usar o formato certinho, tipo 'https://www.exemplo.com'. Se você não tiver um link agora, tudo bem, é só se precisar mesmo!")}>
          <Input
            label={t('Site de referência (opcional)')}
            type="source"
            value={registrationAdditionalInformation.source}
            onChange={(e) => setRegistrationAdditionalInformation({ ...registrationAdditionalInformation, source: e.target.value })}
            size="small"
            fullWidth
            error={registrationAdditionalInformationError.source.length > 0}
            helperText={registrationAdditionalInformationError.source}
            onClick={() => setRegistrationAdditionalInformationError({ ...registrationAdditionalInformationError, source: '' })}
            variant="filled"
            style={{ width: '100%', marginBottom: openModalInformationConfirmation ? '16px' : '40px' }}
          />
        </Tooltip>

        <Tooltip enterTouchDelay={1} title={t('Quer contar mais sobre o site que usou? Escreva aqui qualquer coisa que achar importante! Não é obrigatório, mas pode ajudar a entender melhor o que você encontrou.')}>
          <Input
            label={t('Mais sobre o site (opcional)')}
            type="sourceNotes"
            value={registrationAdditionalInformation.sourceNotes}
            onChange={(e) => setRegistrationAdditionalInformation({ ...registrationAdditionalInformation, sourceNotes: e.target.value })}
            size="small"
            fullWidth
            error={registrationAdditionalInformationError.sourceNotes.length > 0}
            helperText={registrationAdditionalInformationError.sourceNotes}
            onClick={() => setRegistrationAdditionalInformationError({ ...registrationAdditionalInformationError, sourceNotes: '' })}
            variant="filled"
            style={{ width: '100%', marginBottom: openModalInformationConfirmation ? '0px' : '40px' }}
          />
        </Tooltip>
      </>
    )
  };

  const closeAdditionalInformation = () => {
    if (specialProgramId) {
      setOpenModalInformationConfirmation(true)
      setOpenAdditionalInformationModal(false)
    } else {
      if (role?.includes('CurarManager')) {
        setOpenAdditionalInformationModal(false)
        setOpenAddSpecialPrizeModal(true)
      } else {
        setOpenModalInformationConfirmation(true)
        setOpenAdditionalInformationModal(false)
      }
    }
  };

  const contentSelectRight = (numb: number) => {
    return (
      <InputAdornment
        position="end"
      >
        <FormControlLabel style={{ width: '100%', display: 'flex', margin: '0px' }}
          checked={registrationQuiz.trueAnswer === String(numb)}
          onClick={() => setRegistrationQuiz({ ...registrationQuiz, trueAnswer: String(numb) })}
          control={<Radio />}
          label={<h1 style={{ textAlign: 'center', fontSize: responsiveFont(10), fontWeight: '500' }}>Alternativa <br /> correta</h1>}
        />
      </InputAdornment>
    )
  };

  const contentSelectRightB = (bool: boolean) => {
    return (
      <InputAdornment
        position="end"
      >
        <FormControlLabel style={{ width: '100%', display: 'flex', margin: '0px' }}
          checked={status === null ? false : (status === bool)}
          onClick={() => setStatus(bool)}
          control={<Radio />}
          label={<h1 style={{ textAlign: 'center', fontSize: responsiveFont(10), fontWeight: '500' }}>Alternativa <br /> correta</h1>}
        />
      </InputAdornment>
    )
  };

  const contentInputsQuiz = () => {
    return (
      <QuestionsQuiz
        registrationQuiz={registrationQuiz}
        setRegistrationQuiz={setRegistrationQuiz}
        setRegistrationQuizError={setRegistrationQuizError}
        registrationQuizError={registrationQuizError}
        numberOfAlternatives={numberOfAlternatives}
        setNumberOfAlternatives={setNumberOfAlternatives}
        contentSelectRightC={contentSelectRightB}
        contentSelectRight={contentSelectRight}
        buttonAudio={
          <div className='buttonAudio'>
            <h1 className='titleButton'>{t('Adicionar áudio à pergunta (opcional)')}</h1>

            <div className='containerAudio'>
              <S.BoxMediaAudio htmlFor="selecao-arquivo" style={{ border: `solid 1px ${theme.colorsBase.gray3}` }}>
                <div className='iconBackground'>
                  <UploadFile className="iconMic" />
                  {audioSet ? <h5 className='textAdd'>{t('Audio selecionado')}</h5> : <h5 className='textAdd'>{t('Fazer upload de audio')}</h5>}
                </div>

                <S.InputAudio id="selecao-arquivo" onChange={handleChangeAudio} type="file" accept="audio/*,audio/mpeg,.wav" />
              </S.BoxMediaAudio>
              <h2 className='descriptionButton'>{t('Formato: mp3 ou WAV, tamanho máximo: 10 MB, duração máxima: 3 minutos')}</h2>
            </div>
          </div>
        }
      />
    )
  };

  const handleCleanQuestion = () => {
    setStatus(null)
    setRegistrationQuiz({
      questionType: '1',
      question: '',
      response1: '',
      response2: '',
      response3: '',
      response4: '',
      response5: '',
      trueAnswer: ''
    })
  };



  const contentInputsPage1 = () => {
    return (
      <>
        <Input
          label={t('Título')}
          type="name"
          value={registration.name}
          onChange={(e) => setRegistration({ ...registration, name: e.target.value })}
          size="small"
          fullWidth
          error={registrationError.name.length > 0}
          helperText={registrationError.name}
          onClick={() => setRegistrationError({ ...registrationError, name: '' })}
          variant="filled"
          style={{ width: '100%', marginBottom: openModalInformationConfirmation ? '8px' : '24px' }}
        />

        <S.InputSelect>
          <div className="input">
            <FormControl sx={{ m: 1, width: '100%', }}>
              <InputLabel id="demo-simple-select-helper-label">{t('Idioma')}</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="standard"
                style={{ display: 'flex', width: '100%' }}
                value={registration.language}
                error={registrationError.language.length > 0}
                onClick={() => setRegistrationError({ ...registrationError, language: '' })}
                onChange={(e) => {
                  setRegistration({ ...registration, language: String(e.target.value) })
                }}
              >
                <MenuItem value={'1'}>{t('Português')}</MenuItem>
                <MenuItem value={'2'}>{t('Inglês')}</MenuItem>
                <MenuItem value={'3'}>{t('Espanhol')}</MenuItem>
                <MenuItem value={'4'}>{t('Francês')}</MenuItem>
              </Select>
            </FormControl>
          </div>
        </S.InputSelect>
      </>
    )
  };

  return (
    <>

      <ModalRegistrationAndQuizEditing
        open={openQuizModal}
        onClose={() => {
          handleCleanQuestion()
          setOpenQuizModal(false)
          setNumberOfAlternatives(3)
          setAudioSet(undefined)

          validationQuizModalClose()
        }}
        onClick={() => handleRegistrationQuiz(false)}
        onCloseBottom={() => handleRegistrationQuiz(true)}
        childrenContent={contentInputsQuiz()}
        loading={loadingRegister}
        title={t('Perguntas')}
        confirmationButtonText={t('SALVAR')}
        textDeclineButton={t('PRÓXIMA PERGUNTA')}
      />

      <ModalRegistrationAndChallengeEditing
        open={openChallengeModal}
        onClose={() => setOpenConfirmClosingModal(true)}
        onClick={() => handleRegistration()}
        onCloseBottom={() => handleBackButton()}
        closeBottom={true}
        page={registrationStep}
        pageTotal={3}
        childrenContent={
          <>
            {registrationStep === 1 &&
              <S.ContainerInputs>
                {contentInputsPage1()}
              </S.ContainerInputs>
            }

            {registrationStep === 2 &&
              <S.CoverStep>
                <h3 className='title'>{specialProgramId ? t('Capa do conteúdo (opcional)') : t('Capa do desafio (opcional)')}</h3>
                <h5 className='subTitle'>{specialProgramId ? t('Envie uma imagem de capa do conteúdo') : t('Envie uma imagem de capa do desafio')}</h5>

                <Tooltip title={specialProgramId ? t('Escolha uma imagem bacana que mostre do que se trata seu conteúdo ou que chame atenção. Lembre-se: essa parte é muito importante e não pode faltar') : t('Escolha uma imagem bacana que mostre do que se trata seu desafio ou que chame atenção. Lembre-se: essa parte é muito importante e não pode faltar')}>
                  <S.BackgroundImageProfile htmlFor="selecao-arquivo" onDragOver={(e) => onDragOver(e)} onDrop={(e) => {
                    onDragOver(e)
                    handleChangeDrop(e.dataTransfer.files?.[0])
                  }}>
                    {imageSet?.name ? (
                      <img style={{ aspectRatio: '13/9' }} src={image} alt='' />
                    ) : (
                      <div className='iconBackground' style={{ aspectRatio: '13/9' }}>
                        <UploadFile className="iconCam" />
                        <h5 className='textAdd'>{isSm ? t('Clique para enviar imagem') : t('Clique para enviar ou arraste um arquivo de imagem')}</h5>
                      </div>
                    )}

                    <S.Input id="selecao-arquivo" onChange={handleChange} type="file" accept="image/*" ref={inputFileRef} />
                  </S.BackgroundImageProfile>
                </Tooltip>
                <div className='textError'>{imageError}</div>
              </S.CoverStep>
            }

            {registrationStep === 3 &&
              <S.MediaStage>
                <S.InputSelect>
                  <div className="input" style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <FormControl sx={{ m: 1, width: isSm ? '100%' : '60%' }}>
                      <Tooltip enterTouchDelay={1} title={t('Opa, é a sua vez de escolher! Quer mostrar um vídeo legal, uma foto bonita ou escrever algo interessante? Conte pra gente o que você vai usar!')}>
                        <InputLabel id="demo-simple-select-helper-label">{t('Tipo de Conteúdo')}</InputLabel>
                      </Tooltip>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        variant="standard"
                        style={{ display: 'flex', width: '100%' }}
                        value={registration.mediaType}
                        error={registrationError.mediaType.length > 0}
                        onClick={() => setRegistrationError({ ...registrationError, mediaType: '' })}
                        onChange={(e) => {
                          setRegistration({ ...registration, mediaType: String(e.target.value) })
                          setExtensionMedia('')
                          setMediaSet(undefined)
                          setMedia(offImage)
                          resetFileInput()
                        }}
                      >
                        <MenuItem value={'1'}>{t('Vídeo')}</MenuItem>
                        <MenuItem value={'2'}>{t('Imagem')}</MenuItem>
                        <MenuItem value={'4'}>{t('Audio')}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </S.InputSelect>

                {registration.mediaType &&
                  <>
                    <S.BoxMedia htmlFor="selecao-arquivo">
                      {mediaSet?.name ? (
                        <div className='iconBackground'>
                          <Done className="iconCam" color="info" />
                          <h5 className='textAdd'>{mediaSet?.name}</h5>
                        </div>
                      ) : (
                        <div className='iconBackground'>
                          <UploadFile className="iconCam" />
                          <h5 className='textAdd'>{t('Clique para enviar a mídia*')}</h5>
                        </div>
                      )}

                      <S.InputMedia id="selecao-arquivo" accept={renderExtension(Number(registration.mediaType))} onChange={handleChangeMedia} type="file" ref={inputRef} />
                    </S.BoxMedia>
                    {mediaError && <div className='textError'>{mediaError}</div>}

                    {mediaSet && <button className='textViewContent' onClick={() => setModalContent(true)}>{t('Visualizar Mídia')}</button>}
                  </>
                }

                <S.ButtonSponsoredAd style={{ marginTop: registration.mediaType ? '8px' : '40px' }}>
                  <div className="options">
                    <h5 className='textAd'>{t('Contém anúncio patrocinado?')}</h5>

                    <div>
                      <button className='alternative' onClick={() => setSponsoredAd(true)}>
                        < FormControlLabel className="option" checked={sponsoredAd} control={<Radio />} label={t('Sim')} />
                      </button>

                      <button className='alternative' onClick={() => setSponsoredAd(false)}>
                        < FormControlLabel className="option" checked={!sponsoredAd} control={<Radio />} label={t('Não')} />
                      </button>
                    </div>
                  </div>
                </S.ButtonSponsoredAd>
              </S.MediaStage>
            }
          </>
        }
        loading={loadingRegister}
        title={specialProgramId ? t('Novo conteúdo') : t('Novo desafio')}
        confirmationButtonText={t('PRÓXIMO')}
        textDeclineButton={t('CANCELAR')}
      />

      <ModalChallengeContent
        setModalContent={() => setModalContent(false)}
        modalContent={modalContent}
        media={media}
        mediaType={Number(registration.mediaType)}
        extension={extensionMedia}
        sponsored={sponsoredAd}
      />

      <ModalCustom
        open={openSendChallengeModal}
        onClose={() => {
          setOpenSendChallengeModal(false)
          setOpenAdditionalInformationModal(true)
        }}
        onClick={() => {
          setOpenSendChallengeModal(false)
          setOpenQuizModal(true)
        }}
        title={t('Adicionar Perguntas?')}
        description={specialProgramId ? t('Você gostaria de melhorar ainda mais seu conteúdo com Perguntas? Caso contrário, nossa equipe de Curadoria ficará feliz em criar elas para você.') : t('Você gostaria de melhorar ainda mais seu desafio com Perguntas? Caso contrário, nossa equipe de Curadoria ficará feliz em criar elas para você.')}
        confirmationButtonText={t('Criar Perguntas')}
        textDeclineButton={t('CONTINUAR SEM CRIAR PERGUNTAS')}
      />

      <ModalCustom
        open={openSubmitReviewModal}
        onClose={() => setOpenSubmitReviewModal(false)}
        onClick={() => { }}
        title={specialProgramId ? t('Conteúdo enviado para Revisão') : t('Desafio enviado para Revisão')}
        description={specialProgramId ? t('Seu conteúdo foi salvo com Sucesso! Seu conteúdo foi enviado para revisão.') : t('Seu desafio foi salvo com Sucesso! Seu desafio foi enviado para revisão.')}
        confirmationButtonText={t('CONFIRMAR')}
        textDeclineButton={t('FECHAR')}
        buttonRight={false}
        styleButtons={{ justifyContent: 'center' }}
      />

      <ModalCustom
        open={openConfirmSaveModal}
        onClose={() => setOpenConfirmSaveModal(false)}
        onClick={() => specialProgramId ? handleRegistrationTotal() : handleRegistrationTotalChallenge()}
        title={specialProgramId ? t('Salvar conteúdo') : t('Salvar desafio')}
        description={specialProgramId ? t('Deseja finalizar a criação do conteúdo? Seu conteúdo será enviado para revisão.') : t('Deseja finalizar a criação do desafio? Seu desafio será enviado para revisão.')}
        confirmationButtonText={t('ENVIAR PARA REVISÃO')}
        textDeclineButton={t('CANCELAR')}
        styleButtons={{ justifyContent: 'center' }}
        redButtonLeft={true}
      />

      <ModalCustom
        open={openConfirmClosingModal}
        onClose={() => setOpenConfirmClosingModal(false)}
        onClick={() => {
          setOpenChallengeModal(false)
          setOpenConfirmClosingModal(false)
          clearData()
        }}
        title={t('Cancelar Cadastro')}
        description={specialProgramId ? t('Você realmente deseja cancelar a criação de um novo conteúdo?') : t('Você realmente deseja cancelar a criação de um novo desafio?')}
        confirmationButtonText={t('CONFIRMAR')}
        textDeclineButton={t('CANCELAR')}
      />

      <ModalCustom
        open={openModalImageAdjustment}
        onClose={handleChangeModalCloseB}
        onClick={() => { }}
        childrenContent={<>{imageSet && <ImageAdjustment onClickAdd={dispararCliqueInput} onClickSave={handleChangeModalB} imageSet={imageSet} />}</>}
        title=''
        buttonsEnabled={false}
        styleLine={{ backgroundColor: 'transparent', marginBottom: '24px' }}
        style={{ backgroundColor: theme.colorsBase.white2 }}
      />

      {!isSm ? (
        <ModalCustom
          open={openModalInformationConfirmation}
          onClose={() => setOpenConfirmClosingModal(true)}
          onClick={() => { }}
          childrenContent={contentInformationConfirmation()}
          title={t('Confirmação de Informações')}
          buttonsEnabled={false}
          style={{ width: '80%', height: '90%' }}
        />
      ) : (
        <ModalRegistrationAndChallengeEditing
          open={openModalInformationConfirmation}
          onClose={() => setOpenConfirmClosingModal(true)}
          onClick={() => handleValidationTotal()}
          onCloseBottom={() => handleBackButton()}
          closeBottom={true}
          page={registrationStep}
          pageTotal={3}
          pageActive={false}
          childrenContent={contentInformationConfirmationData()}
          loading={loadingRegister}
          title={specialProgramId ? t('Novo conteúdo') : t('Novo desafio')}
          confirmationButtonText={t('ENVIAR PARA REVISÃO')}
          textDeclineButton={t('CANCELAR')}
        />
      )}

      {!isSm ? (
        <ModalCustom
          open={openAdditionalInformationModal}
          onClose={closeAdditionalInformation}
          onClick={closeAdditionalInformation}
          childrenContent={contentAdditionalInformation()}
          title={t('Informações Adicionais')}
          confirmationButtonText={t('CONFIRMAR INFORMAÇÕES')}
          textDeclineButton={t('CANCELAR')}
        />
      ) : (
        <ModalRegistrationAndChallengeEditing
          open={openAdditionalInformationModal}
          onClose={closeAdditionalInformation}
          onClick={closeAdditionalInformation}
          onCloseBottom={() => handleBackButton()}
          closeBottom={true}
          page={registrationStep}
          pageTotal={3}
          pageActive={false}
          childrenContent={contentAdditionalInformation()}
          title={specialProgramId ? t('Novo conteúdo') : t('Novo desafio')}
          confirmationButtonText={t('CONFIRMAR INFORMAÇÕES')}
          textDeclineButton={t('CANCELAR')}
        />
      )}

      {isSm ? (
        <ModalRegistrationAndChallengeEditing
          open={openAddSpecialPrizeModal}
          onClose={() => {
            setOpenModalInformationConfirmation(true)
            setOpenAddSpecialPrizeModal(false)
            setRegistrationAdditionalInformation({ ...registrationAdditionalInformation, award: '' })
          }}
          onClick={saveSpecialAdminAwards}
          onCloseBottom={() => { }}
          closeBottom={false}
          pageActive={false}
          pageTotal={0}
          childrenContent={contentSpecialAdminAwards()}
          loading={loadingRegister}
          title={t('Novo Desafio')}
          confirmationButtonText={t('ADICIONAR PRÊMIO')}
          textDeclineButton={t('NÃO ADICIONAR PRÊMIO')}
        />
      ) : (
        <ModalCustom
          open={openAddSpecialPrizeModal}
          onClose={() => {
            setOpenModalInformationConfirmation(true)
            setOpenAddSpecialPrizeModal(false)
            setRegistrationAdditionalInformation({ ...registrationAdditionalInformation, award: '' })
          }}
          onClick={saveSpecialAdminAwards}
          childrenContent={contentSpecialAdminAwards()}
          title={t('Adicionar Prêmio Especial')}
          confirmationButtonText={t('ADICIONAR PRÊMIO')}
          textDeclineButton={t('NÃO ADICIONAR PRÊMIO')}
        />
      )}

      <ModalRegistrationAndQuizEditing
        open={openQuizModalEdit}
        onClose={() => {
          setQuestionSelected(-1)
          handleCleanQuestion()
          setOpenQuizModalEdit(false)
          setNumberOfAlternatives(3)
          setAudioSet(undefined)
        }}
        onClick={() => handleRegistrationQuiz(false)}
        onCloseBottom={() => setOpenModalInformationConfirmation(true)}
        childrenContent={
          <>
            <S.ContainerQuestionCountEdit>
              {listQuestions?.map((item, index) => (
                <S.ButtonQuestion active={questionSelected === index} key={item.Id} onClick={() => {
                  setQuestionSelected(index)
                  const findA = listQuestions[index]?.QuizAlternatives ?? [];

                  let findRightAnswer = 0;
                  listQuestions[index]?.QuizAlternatives?.map((item, index) => {
                    if (item.RightAnswer === true) {
                      findRightAnswer = index + 1;
                    }
                  })

                  setRegistrationQuiz({
                    questionType: String(item.QuestionType),
                    question: item.Question,
                    response1: findA[0]?.Answer || "",
                    response2: findA[1]?.Answer || "",
                    response3: findA[2]?.Answer || "",
                    response4: findA[3]?.Answer || "",
                    response5: findA[4]?.Answer || "",
                    trueAnswer: String(findRightAnswer)
                  })
                  setNumberOfAlternatives(findA.length || 3)
                  setAudioSet(item.AudioUrl)
                  setStatus(findA[0]?.RightAnswer)
                }}>
                  <h5 className='textbutton'>{item && index + 1}</h5>
                </S.ButtonQuestion>
              ))}
              <Tooltip title={t('Adicionar Pergunta')}>
                <S.ButtonQuestion active={true} onClick={() => {
                  setQuestionSelected(-1)
                  handleCleanQuestion()
                  setNumberOfAlternatives(3)
                  setAudioSet(undefined)
                }}>
                  <Add className="i" />
                </S.ButtonQuestion>
              </Tooltip>
            </S.ContainerQuestionCountEdit>

            {contentInputsQuiz()}
          </>
        }
        loading={loadingRegister}
        title={t('Perguntas')}
        confirmationButtonText={t('SALVAR')}
        textDeclineButton={t('CANCELAR')}
      />

      <ModalSendCustomCover
        open={openModalSendCustomCover}
        onClose={() => setOpenModalSendCustomCover(false)}
      />
    </>
  )
}

export default ViewCardChallenge
