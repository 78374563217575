import styled from 'styled-components'
import { responsiveFont } from '../../../utils/UtilsGlobal'

export const InputSelect = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
 

  > div {
    display: flex;
    align-self: flex-start;

    padding: 0px 0;

    input {
      width: 22px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .input {
    width: 100%;

    input {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }

    select {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }
  }
`

export const CoverStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 12px;
  height: 100%;

  .title {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(14)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }
`

export const BackgroundImageProfile = styled.label`
  margin-top: 24px;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 8px;
  position: relative;
  margin-bottom: 16px;

   img {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 8px;
    border: solid 2px ${(props) => props.theme.colorsBase.gray};
    z-index: 1;
   }

  .iconBackground {
    position: absolute;
    bottom: -8px;
    right: -24px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.secundary};
    z-index: 1;

    .iconCam {
      color: ${(props) => props.theme.colorsBase.white};
      width: 24px;
      height: 24px;
      align-self: center;
    }
  }
`

export const Input = styled.input`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`

export const MediaStage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 12px;
  height: 100%;
  width: 100%;

  .title {
    margin-bottom: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(14)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }
`

export const BoxMedia = styled.label`
  margin-top: 24px;
  width: 350px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 8px;
  position: relative;
  margin-bottom: 16px;
  border: dashed 1px ${(props) => props.theme.colorsBase.gray};

  .nameMedia {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .iconBackground {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .iconCam {
      color: ${(props) => props.theme.colorsBase.black};
      width: 24px;
      height: 24px;
      align-self: center;
    }

    .textAdd {
      margin-bottom: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 500;
      font-family: 'Barlow', Medium;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
  }
`

export const InputMedia = styled.input`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`

export const BoxResponse = styled.label`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;

  .textNumber {
    margin-right: 8px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(14)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }
`

export const ContainerResponse = styled.label`
  margin-bottom: 8px;
  margin-top: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .textTitle {
    margin-left: 16px;
    margin-bottom: 8px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(14)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }
`

export const switchDivContainer = styled.div`
    margin-top: 24px;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h3 {
      margin-left: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }

    .switchDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      h5 {
        line-height: 20px;
        letter-spacing: 0.15px;
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colors.black};
        opacity: 0.6;
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }
    }
`

export const TitleQuestionSent = styled.div`
  margin: 0 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  font-size: ${responsiveFont(14)};
  color: ${(props) => props.theme.colors.black};
  font-weight: 400;
  font-family: 'Barlow', Medium;
  margin-bottom: 40px;
`

export const BoxMediaAudio = styled.label`
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 8px;
  position: relative;
  background-color: ${(props) => props.theme.colors.secundary};
  margin-right: 8px;
  padding: 4px;

  .iconBackground {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .iconMic {
      color: ${(props) => props.theme.colorsBase.white};
      width: 24px;
      height: 24px;
      align-self: center;
    }

    .textAdd {
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colorsBase.white};
      font-weight: 500;
      font-family: 'Barlow', Medium;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
  }
`

export const InputAudio = styled.input`
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`

export const BoxMediaAudioB = styled.label`
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 8px;
  position: relative;
  background-color: ${(props) => props.theme.colors.secundary};
  margin-right: 8px;
  padding: 4px;

  .iconBackground {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .iconMic {
      color: ${(props) => props.theme.colorsBase.white};
      width: 24px;
      height: 24px;
      align-self: center;
    }

    .textAdd {
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colorsBase.white};
      font-weight: 500;
      font-family: 'Barlow', Medium;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
  }
`

export const InputAudioB = styled.input`
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`

export const BoxMediaE = styled.label`
  margin-top: 24px;
  width: 350px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 8px;
  position: relative;
  margin-bottom: 16px;
  border: dashed 1px ${(props) => props.theme.colorsBase.gray};

  .nameMedia {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .iconBackground {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .iconCam {
      color: ${(props) => props.theme.colorsBase.black};
      width: 24px;
      height: 24px;
      align-self: center;
    }

    .textAdd {
      margin-bottom: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 500;
      font-family: 'Barlow', Medium;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
  }
`