import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const ContainerSearch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 24px;
  position: relative;
  @media (max-width: 680px) {
    padding: 8px 16px;
  }
`

export const Filter = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8.5px;
  border-radius: 40px;
  border: solid 1px ${(props) => props.theme.colors.seventh};
  padding: 12px;
  height: 40px;
  background-color: transparent;
  
  &:hover {
    opacity: 0.8;
  }

  label {
      margin-left: 8px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.seventh};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
  }

  .icon {
    color: ${(props) => props.theme.colors.seventh};
    width: 24px;
    height: 24px;
  }

  @media (max-width: 680px) {
    margin-left: 8px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 12px;
  }
`

export const ContainerAutoComplete = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  border: solid 1px ${(props) => props.theme.colors.black5};
  padding: 28px 4px 4px 4px;
  width: 50%;
  z-index: 90;
  position: absolute;
  top: 20px;
  background-color:  ${(props) => props.theme.colorsBase.white2};
  flex-direction: column;
 
  .buttonAuto {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 4px 4px 20px;
    border: none;
    width: 100%;
    background: none;
 
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }
 
  .buttonAuto:nth-child(2n) {
    background: ${(props) => props.theme.colorsBase.white};
  }
 
  @media (max-width: 680px) {
    width: 100%;
  }
`
