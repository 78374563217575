import React from 'react';
import { Stack } from '@mui/material';
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';

import {
  IGetChallengesModerationResponse,
  IPostAcceptModerationCommand,
  IPostBlockModerationCommand,
  IPostRefuseModerationCommand,
  IPostRejectModerationCommand
} from '../../services/Moderation/types';
import PageContainer from '../../components/Layout/PageContainer';
import BackPageHeader from '../../components/Template/BackPageHeader';
import PaperContainer from '../../components/Layout/PaperContainer';
import QueryContainer from '../../components/QueryContainer';
import ViewLoading from '../../components/ViewLoading';
import ViewError from '../../components/ViewError';
import ModalButton from '../../components/ModalButton';

import ChallengeInfoPage from '../../components/ChallengeInfoPage';
import ApproveChallengeModal from './components/ApproveChallengeModal';
// import RejectChallengeModal from '../TriageChallenge/components/RejectChallengeModal';
import ChallengeChangesRequestModal from '../TriageChallenge/components/ChallengeChangesRequestModal';
import CantModerateModal from './components/CantModerateModal';

interface IProps {
  query: UseQueryResult<IGetChallengesModerationResponse, unknown>

  approveMutation: UseMutationResult<unknown, unknown, Omit<IPostAcceptModerationCommand, "DesafioId">, unknown>
  rejectChallengeMutation: UseMutationResult<unknown, unknown, Omit<IPostBlockModerationCommand, "DesafioId">, unknown>
  requestChangesChallengeMutation: UseMutationResult<unknown, unknown, Omit<IPostRejectModerationCommand, "DesafioId">, unknown>
  cantModerateMutation: UseMutationResult<unknown, unknown, Omit<IPostRefuseModerationCommand, "DesafioId">, unknown>
}

const ChallengeInspectionView: React.FC<IProps> = ({
  query,
  approveMutation,
  // rejectChallengeMutation,
  requestChangesChallengeMutation,
  cantModerateMutation
}) => (
  <PageContainer>
    <BackPageHeader title='Moderação' />
    <PaperContainer
      sx={(theme) => ({
        position: 'relative',
        px: '82px',
        pt: '32px',
        [theme.breakpoints.down(800)]: {
          px: '26px',
          pt: '18px',
        },
      })}
    >
      <QueryContainer
        query={query}
        onLoading={ViewLoading}
        onError={ViewError}
      >
        {({ data }) => (
          <>
            <Stack position="absolute" bottom={0} right={0} pr="24px" pb="16px" direction='row' spacing={2}>
              <ModalButton
                variant="outlined"
                buttonChildren="NÃO POSSO MODERAR"
              >
                {({ open, onClose }) => (
                  <CantModerateModal
                    open={open}
                    onClose={onClose}
                    mutation={cantModerateMutation}
                  />
                )}
              </ModalButton>

              <ModalButton
                variant="outlined"
                buttonChildren="SUGERIR"
              >
                {({ open, onClose }) => (
                  <ChallengeChangesRequestModal
                    open={open}
                    onClose={onClose}
                    mutation={requestChangesChallengeMutation}
                  />
                )}
              </ModalButton>

              <ModalButton
                variant="outlined"
                buttonChildren="BLOQUEAR"
              >
                {() => <></>/* {({ open, onClose }) => (
                  <RejectChallengeModal
                    open={open}
                    onClose={onClose}
                    mutation={rejectChallengeMutation}
                  />
                )} */}
              </ModalButton>

              <ModalButton
                variant="outlined"
                buttonChildren="APROVAR"
              >
                {({ open, onClose }) => (
                  <ApproveChallengeModal
                    open={open}
                    onClose={onClose}
                    mutation={approveMutation}
                  />
                )}
              </ModalButton>
            </Stack>
            <ChallengeInfoPage data={{ ...data, AdicionarQuiz: false }} />
          </>
        )}
      </QueryContainer>
    </PaperContainer>
  </PageContainer>
)

export default ChallengeInspectionView;
