import styled from 'styled-components'
import { theme } from '../../styles/theme'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(100vh - 56px);
  margin-top: 56px;
  background-color: ${(props) => props.theme.colorsBase.white};
`

export const ContainerBackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: ${(props) => props.theme.colors.primary};
  width: 100%;
  border-radius: 16px 0 0 0;

  .i {
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;

    &:hover {
      opacity: 0.8;
    }
  }

    .iconBackButton {
      color: ${(props) => props.theme.colors.seventh};
      width: 24px;
      height: 24px;
      background-color: ${(props) => props.theme.colorsBase.white};
      border-radius: 50%;

      &:hover {
        opacity: 0.8;
      }
    }

    h5 {
      margin-left: 16px;
      line-height: 32px;
      letter-spacing: 0.18px;
      text-align: left;
      font-size: ${responsiveFont(24)};
      color: ${(props) => props.theme.colorsBase.white};
      font-weight: 500;
      font-family: 'Barlow', Medium;
  
      @media (max-width: 680px) {
        font-size: ${responsiveFont(20)};
      }
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(28)};
      }
    }
  

  @media (max-width: 680px) {
    padding: 16px;
  }
`

export const ContainerLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 12px 40px;
  background-color: ${(props) => props.theme.colorsBase.white};

  .i {
    color: ${(props) => props.theme.colors.black};
    width: 24px;
    height: 24px;
  }

  h5 {
    margin-left: 8px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => (props.theme.colors.black)};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(12)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }
`

export const ContainerContent = styled.div`
  padding: 0px 24px 0px 24px;
  width: 100%;
  height: 100%;

  @media (max-width: 680px) {
    padding: 0px 16px 0px 16px;
  }
`

export const LineHeader = styled.div<{ register?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 50%;
  margin-bottom: 24px;

  @media (max-width: 680px) {
    width: 100%;
    margin-bottom: ${(props) => (props.register ? '8px' : ' 48px')};
  }
`

export const ButtonHeader = styled.button<{ active?: boolean }>`
  width: 40%;
  border: none;
  border-bottom: solid 2px ${(props) => (props.active ? props.theme.colors.seventh : 'transparent')};
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }
  
    h5 {
      line-height: 16px;
            letter-spacing: 0.4px;
      text-align: center;
      font-size: ${responsiveFont(12)};
      color: ${(props) => (props.active ? props.theme.colors.seventh : props.theme.colors.friday)};
      font-weight: 500;
      font-family: 'Barlow', Medium;
      padding: 8px 8px 16px 8px;
  
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(12)};
      }
    }

  @media (max-width: 680px) {
    width: 50%;
  }
`

export const BoxImageProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 16px;
  width: 100%;

  .InputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 16px;
    margin-left: 16px;

    h2 {
      margin-left: 12px;
      line-height: 24px;
      letter-spacing: 0.15px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.black};
      opacity: 0.6;
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(14)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    h1 {
      margin-left: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(10)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  }
`

export const ContainerChallenge = styled.div`
  display: flex;
  height: 100%;
  width: 45%;
  margin: 16px 8px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
`

export const BoxChallenge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 280px;
  height: 100px;
  padding: 8px;
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

  .boxImage {
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .boxContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 16px;

    .title {
      max-width: 180px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      line-height: 24px;
      letter-spacing: 0.1px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 500;
      font-family: 'Barlow', Medium;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }

    .boxUser {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .i {
        color: #201E1E;
        font-size: 12px;
    
        &:hover {
          color: ${theme.colors.black};
        }
      }

      .nameUser {
        max-width: 160px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        margin-left: 8px;
        line-height: 16px;
        letter-spacing: 0.4px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(10)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }
    
    .containerChip {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .containerTop {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }
  }
`

export const BoxChallengePending = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 280px;
  height: 110px;
  padding: 8px;
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  position: relative;

  &:hover {
    opacity: 0.8;
  }

  .iconChevron {
    color: #201E1E;
    font-size: 24px;
    position: absolute;
    right: 0px;
    top: 40px;
  }

  .boxImage {
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .boxContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 16px;

    .containerTitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      .title {
        max-width: 140px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        line-height: 24px;
        letter-spacing: 0.1px;
        text-align: left;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 500;
        font-family: 'Barlow', Medium;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(16)};
        }
      }

      .textPoints {
        line-height: 16px;
        letter-spacing: 0.4px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.seventh};
        font-weight: 500;
        font-family: 'Barlow', Medium;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(10)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }

    .boxUser {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .i {
        color: #201E1E;
        font-size: 12px;
    
        &:hover {
          color: ${theme.colors.black};
        }
      }

      .nameUser {
        max-width: 150px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        margin-left: 8px;
        line-height: 16px;
        letter-spacing: 0.4px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(10)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }
    
    .containerChip {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .containerTop {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }

    .containerProgressBar {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 70%;
      height: 50px;

      .textProgress {
        margin-left: 6px;
        line-height: 10px;
        letter-spacing: 0.8px;
        text-align: left;
        font-size: ${responsiveFont(9)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 500;
        font-family: 'Barlow', Medium;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(8)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(10)};
        }
      }
    }
  }
`

export const Chip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 12px;
  height: 30px;
  background-color: transparent;
  border-radius: 16px;
  border: solid 1px ${(props) => props.theme.colors.seventh};

  .i {
    color: ${theme.colors.seventh};
    font-size: 16px;
    margin-right: 8px;
  }

  .titleChip {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }
`

export const ChipMonochrome = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 12px;
  height: 30px;
  background-color: transparent;
  border-radius: 16px;
  border: solid 1px ${(props) => props.theme.colors.eleven};

  .i {
    color: ${theme.colors.seventh};
    font-size: 16px;
    margin-right: 8px;
  }

  .titleChip {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }
`

export const InputSelect = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
 

  > div {
    display: flex;
    align-self: flex-start;

    padding: 0px 0;

    input {
      width: 22px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .input {
    width: 100%;

    input {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }

    select {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }
  }
`

export const CoverStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 12px;
  height: 100%;

  .title {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(14)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }
`

export const BackgroundImageProfile = styled.label`
  margin-top: 24px;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 8px;
  position: relative;
  margin-bottom: 16px;

   img {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 8px;
    border: solid 2px ${(props) => props.theme.colorsBase.gray};
    z-index: 1;
    object-fit: cover; 
   }

  .iconBackground {
    position: absolute;
    bottom: -8px;
    right: -24px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.secundary};
    z-index: 1;

    .iconCam {
      color: ${(props) => props.theme.colorsBase.white};
      width: 24px;
      height: 24px;
      align-self: center;
    }
  }
`

export const Input = styled.input`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`

export const MediaStage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 12px;
  height: 100%;
  width: 100%;
  margin-top: 16px;

  .title {
    margin-bottom: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(14)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }
`

export const BoxMedia = styled.label`
  margin-top: 24px;
  width: 350px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 8px;
  position: relative;
  margin-bottom: 16px;
  border: dashed 1px ${(props) => props.theme.colorsBase.gray};

  .nameMedia {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .iconBackground {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .iconCam {
      color: ${(props) => props.theme.colorsBase.black};
      width: 24px;
      height: 24px;
      align-self: center;
    }

    .textAdd {
      margin-bottom: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 500;
      font-family: 'Barlow', Medium;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
  }
`



export const ContainerResponse = styled.label`
  margin-bottom: 8px;
  margin-top: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .textTitle {
    margin-left: 16px;
    margin-bottom: 8px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(14)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }
`

export const switchDivContainer = styled.div`
    margin-top: 24px;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h3 {
      margin-left: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }

    .switchDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      h5 {
        line-height: 20px;
        letter-spacing: 0.15px;
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colors.black};
        opacity: 0.6;
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }
    }
`

export const TitleQuestionSent = styled.div`
  margin: 0 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  font-size: ${responsiveFont(14)};
  color: ${(props) => props.theme.colors.black};
  font-weight: 400;
  font-family: 'Barlow', Medium;
  margin-bottom: 40px;
`

export const ContainerData = styled.div`
  display: flex;
  height: 100%
  align-items: flex-start;
  padding: 24px 25%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`

export const Image = styled.img`
  width: 100%;
  border-radius: 4px;
  object-fit: cover; 
`

export const Table = styled.div`
  position: relative;
  height: 350px;
  min-height: 350px;
  overflow: auto;
  margin: 16px 16px;

  z-index: 10;

  &::-webkit-scrollbar {
    border-radius: 8px;
    width: 4px;
    background: ${(props) => props.theme.colors.eleven};
    }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.seventh};
    border-radius: 8px;
  }
  ::-webkit-scrollbar:vertical { 
    height: 4px;
    border-radius: 8px;
  }

  h2 {
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    margin-bottom: 8px;
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Medium;
  }

  @media (min-width: 1600px) {
    h2 {
      line-height: 24px;
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
    padding-right: 4px;
    margin: 48px 8px 32px 16px;
  }
`

export const AnswerQuestions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 4px;

  .divContentText {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .divNumber {
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      h3 {
        display: flex;
        line-height: 24px;
        letter-spacing: 0.18px;
        text-align: center;
        font-size: ${responsiveFont(20)};
        color: ${(props) => props.theme.colorsBase.white};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        background-color: ${(props) => props.theme.colors.secundary};
         height: 32px;
         width: 32px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        
        @media (max-width: 680px) {
          height: 32px;
          width: 32px;
        }
    
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(22)};
        }
      }
    }

    h5 {
      margin-left: 12px;
      line-height: 24px;
      letter-spacing: 0.18px;
      text-align: left;
      font-size: ${responsiveFont(20)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(22)};
      }
    }
  }

  .divContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 12px;
    margin-left: 24px;

    .boxAlternative{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      h5 {
        width: 4px;
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colors.seventh};
        font-weight: 400;
        font-family: 'Barlow', Regular;

        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }

     .alternative {
      display: flex;
      border: none;
      background: none;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: auto;
      padding: 0px 20px;

      .option {
        text-align: left;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colors.fourth};
        font-weight: 400;
        font-family: 'Barlow', Regular;
      }
     }
    }

  }


  @media (max-width: 680px) {
    margin-top: 4px;

    .divContent{
      margin-left: 16px;
    }
  }
`

export const ContainerButtons = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content:  flex-start;
  margin-top: 64px;
  margin-bottom: 24px;

  .divButtonTop{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 32px;
  }

  @media (max-width: 680px) {
    flex-direction: column;
    margin-top: 77px;

    .divButtonTop{
      width: 100%;
      margin-left: 0px;
      align-items: flex-end;
      margin-bottom: 12px;
      flex-direction: row;
    }
  }
`