import { sendRequest } from '../RequestService';
import { IPostRegister } from './types';

const defaultRoot = 'advance-registers';


export const postEarlyRegistration = async (body: IPostRegister) => {
  const url = `${defaultRoot}`
  return await sendRequest<boolean, IPostRegister>(url, 'POST', body)
}

export const getEarlyRegistration = async (type: number) => {
  const url = `${defaultRoot}/exists/${type}`
  return await sendRequest<boolean, boolean>(url, 'GET')
}