import React, { useEffect, useState } from 'react';
import { Box, Typography, Stack, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

import patrocinador1 from "../../assets/images/patrocinador1.png"
import patrocinador2 from "../../assets/images/patrocinador2.png"
import patrocinador3 from "../../assets/images/patrocinador3.png"
import patrocinador4 from "../../assets/images/patrocinador4.png"
import patrocinador5 from "../../assets/images/patrocinador5.png"
import patrocinador6 from "../../assets/images/patrocinador6.png"

import PageContainer from '../../components/Layout/PageContainer';
import AboutHeader from '../../components/Layout/AboutHeader';
import Footer from '../../components/Footer';
import { withLineBreak } from '../../utils';
import ButtonMaterial from '../../components/Button';
import { Title, ContentText, BoxWithImage, SideImage, BoxWithImageReverse, SideImageReverse, Title2 } from '../About/styles';
import { Grid } from './styles';
import { useIsSm } from '../../hooks/useIsSm';
import { theme } from '../../styles/theme';
import ToastNotification from '../../components/ToastNotification';
import { getEarlyRegistration, postEarlyRegistration } from '../../services/EarlyRegistration';

const SponsorChannelView: React.FC = () => {
  const { t } = useTranslation()
  const isSm = useIsSm()
  const [loading, setLoading] = useState(false)
  const [alreadyRegistered, setAlreadyRegistered] = useState(false)

  useEffect(() => {
    handlealreadyRegistered()
  }, []);

  const handlealreadyRegistered = async () => {
    setLoading(true);
    try {
      const response = await getEarlyRegistration(2);
      if (response.Success) {
        setAlreadyRegistered(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.')
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false);
  };

  const handleRegisterEmail = async () => {
    setLoading(true);
    try {
      const bodyData = {
        Type: 2
      };

      const response = await postEarlyRegistration(bodyData);
      if (response.Success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Registro realizado com sucesso!')
        });
        setAlreadyRegistered(true)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao se registrar, tente novamente.')
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao se registrar, tente novamente.'),
      });
    }
    setLoading(false);
  };

  return (
    <PageContainer>
      <AboutHeader title="Canal de Patrocínio" subTitle='(Em Construção)'/> 

      <Grid>
        <Box gridArea="geral">
          <Title>{t("Bem-vindo ao Canal de Patrocínio")}</Title>
          <ContentText>{withLineBreak(t("canal_patrocinador_sobre"))}</ContentText>
        </Box>

        <Box gridArea="importancia">
          <Title>{t("Faça Parte da Mudança na Saúde")}</Title>
          <ContentText>{withLineBreak(t("canal_patrocinador_importancia"))}</ContentText>
        </Box>

        <Box gridArea="neste_canal">
          <Title2 textAlign="center" fontSize="24px" color="#5080FF">{t("O que você encontrará neste Canal?")}</Title2>
        </Box>

        <BoxWithImage gridArea="empresa">
          <SideImage src={patrocinador1} />
          <Title>{t("Patrocine com Propósito")}</Title>
          <ContentText>{withLineBreak(t("canal_patrocinador_empresa"))}</ContentText>
        </BoxWithImage>

        <BoxWithImage gridArea="publicacao">
          <SideImage src={patrocinador2} />
          <Title>{t("Inspire")}</Title>
          <ContentText>{withLineBreak(t("canal_patrocinador_publicacao"))}</ContentText>
        </BoxWithImage>

        <BoxWithImage gridArea="feedback">
          <SideImage src={patrocinador3} />
          <Title>{t("Razões para Se Tornar um Patrocinador")}</Title>
          <ContentText>{withLineBreak(t("canal_patrocinador_razoes"))}</ContentText>
        </BoxWithImage>

        {isSm ? (
          <BoxWithImage gridArea="feedbackB">
            <SideImage src={patrocinador4} />
            <Title>{t("Planos Pensados para Você!")}</Title>
            <ContentText>{withLineBreak(t("canal_patrocinador_planos"))}</ContentText>
          </BoxWithImage>
        ) : (
          <BoxWithImageReverse gridArea="feedbackB">
            <SideImageReverse src={patrocinador4} />
            <Title>{t("Planos Pensados para Você!")}</Title>
            <ContentText>{withLineBreak(t("canal_patrocinador_planos"))}</ContentText>
          </BoxWithImageReverse>
        )}

        <Box gridArea="primeiro" style={{ marginTop: '-20px' }}>
          <Title2 textAlign="center" fontSize="24px" color="#5080FF">{t("Seja o Primeiro a Saber!")}</Title2>
        </Box>

        <BoxWithImage gridArea="registro">
          <SideImage src={patrocinador5} />
          <Title>{t("Registro Antecipado")}</Title>
          <ContentText>{withLineBreak(t("canal_patrocinador_registro"))}</ContentText>
        </BoxWithImage>

        <BoxWithImage gridArea="notificacao">
          <SideImage src={patrocinador6} />
          <Title>{t("Opção de Notificação")}</Title>
          <ContentText>{withLineBreak(t("canal_patrocinador_notificacao"))}</ContentText>
        </BoxWithImage>
      </Grid>

      <Paper
        component={Stack}
        mx="48px"
        mb="64px"
        p="32px"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ width: isSm ? '100%' : 'none', marginLeft: isSm ? '0px' : 'none', marginTop: '-150px' }}
      >
        {alreadyRegistered ?
          <Typography textAlign="center" style={{ color: theme.colors.seventh }}>{t("Você está registrado! Quando estiver disponível, você será notificado.")}</Typography>
          :
          <Typography textAlign="center" style={{ color: theme.colors.seventh }}>{t("Seja avisado! Basta clicar no botão abaixo.")}</Typography>
        }

        <ButtonMaterial
          style={{ border: `solid 1px ${alreadyRegistered ? 'transparent' : theme.colors.seventh}`, maxWidth: '320px', width: '100%' }}
          variant="contained"
          color='info'
          label={t('Desejo ser notificado')}
          onClick={handleRegisterEmail}
          loading={loading}
          disable={alreadyRegistered}
        />
      </Paper>

      <Footer />
    </PageContainer>
  );
}

export default SponsorChannelView;
