import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/Auth';
import * as S from './styles'
import { Category, Schedule, Person, StackedBarChart, Handshake, OpenInNew, AdminPanelSettings, Info, Stars, Redeem } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import ToastNotification from '../../components/ToastNotification';
import { postStartChallenge, getChallengeExecution } from '../../services/Challenges';
import iconEN from '../../assets/images/en.png'
import iconES from '../../assets/images/es.png'
import iconFR from '../../assets/images/fr.png'
import iconPT from '../../assets/images/pt.png'
import BoxAssessment from '../../components/BoxAssessment';
import ChallengeHeader from '../../components/ChallengeHeader';
import { useIsSm } from '../../hooks/useIsSm';
import Loading from '../../components/Loading';
import ViewError from '../../components/ViewError';
import HeaderAchievements from '../../components/HeaderAchievements';
import { getLocalStorage } from '../../utils/StorageLocal'
import { USER_GET } from '../../config/constants'
import { IUser } from '../../contexts/Auth/types';
import { IChallenge } from '../../services/Challenges/types';
import ModalCustomBottom from '../../components/ModalCustomBottom';
import ModalCustom from '../../components/ModalCustom';

const Challenge: React.FC = () => {
  const { setLink, setChallenge } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { token, token2 } = useParams();
  const isSm = useIsSm()
  const [loading, setLoading] = useState(false)
  const [challenge, setChallengeInitial] = useState<IChallenge>()
  const [openModalStartChallenge, setOpenModalStartChallenge] = useState(false)

  useEffect(() => {
    const loadAsyncStorage = (id: string) => {
      const storagedUser = getLocalStorage<IUser>(USER_GET);
      if (storagedUser && storagedUser.Token) {
        handleChallenge(id)
      } else {
        navigate('/login');
      }
    }
    if (token) {
      loadAsyncStorage(token);
    }
  }, [token])

  const handleChallenge = async (id: string) => {
    setLoading(true)
    try {
      const response = await getChallengeExecution(id);
      if (response.Success) {
        if (response.Data?.Title) {
          setChallenge({
            ...response.Data,
            Id: token ?? ''
          })
          setChallengeInitial(response.Data)
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('O desafio não está mais disponível!'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('O desafio não está mais disponível!')
      });
    }
    setLoading(false)
  };

  const startChallenge = async () => {
    try {
      setOpenModalStartChallenge(false);
      const response = await postStartChallenge(token ?? '');
      if (response.Success) {
        navigate(`/desafio_conteudo/${response.Data}/${token2 === 'PE' ? 'PE' : ''}`)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao iniciar o desafio, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao iniciar o desafio, tente novamente.')
      });
    }
  };

  const renderTextLanguageMobile = (type: number) => ({
    1: "PT",
    2: "EN",
    3: "ES",
    4: "FR",
  }[type])

  const renderImageLanguage = (type: number) => ({
    1: iconPT,
    2: iconEN,
    3: iconES,
    4: iconFR
  }[type])

  const openLink = (id: string) => {
    navigate(`/link_externo`)
    setLink(id)
  };

  const renderTextStartChallenge = () => {
    const validTokens = ['159', '160', '161'];
    if (token && validTokens.includes(token)) {
      return t("Você receberá 300 pontos ao finalizar esse desafio! Como alternativa ao prêmio, você ganhará 300 pontos ao finalizar o desafio.")
    }

    if (Number(challenge?.ProductsCount) > 0) {
      return `${t('Atenção! No momento, restam apenas')} ${challenge?.ProductsCount} ${t('prêmios especiais disponíveis para este desafio. Se o prêmio especial se esgotar antes de você completar o desafio, você poderá ganhar')} ${challenge?.AlternativePoints} ${t('pontos! Deseja Iniciar?')}`
    }

    return t("Ah, que pena! Nossos prêmios acabaram! Mas olha só: você ainda pode ganhar pontos participando! Esses pontos vão te ajudar a trocar por produtos incríveis no futuro. E aí, topa continuar e acumular pontos? Se sim, é só clicar em ‘Iniciar’.  Se preferir dar uma olhadinha em outras opções, clique em 'Voltar'.")
  };

  const handleStartChallenge = () => {
    if (String(challenge?.SpecialAwardName)?.length > 3) {
      setOpenModalStartChallenge(true)
    } else {
      startChallenge()
    }
  };

  const renderTags = (challenge: IChallenge) => {
    return (challenge?.Tags.length > 0 && <>
      <S.Line>
        <h5>{t('Tags')}</h5>
      </S.Line>

      <S.ContainerChip>
        {challenge?.Tags?.map((item) => (
          <S.Chip key={item}>
            <h1 className='titleChip'>{item}</h1>
          </S.Chip>
        ))}
      </S.ContainerChip>
    </>)
  };

  const renderFonte = (challenge: IChallenge) => {
    return (challenge?.Fonte || challenge?.ObservacaoFonte ? (
      <>
        <S.Line>
          <h5>{t('Fonte')}</h5>
        </S.Line>

        <S.TextContentFonte onClick={() => openLink(challenge?.Fonte)}>{challenge?.Fonte}</S.TextContentFonte>

        <S.TextContentSobreFonte>{challenge?.ObservacaoFonte}</S.TextContentSobreFonte>
      </>
    ) : null)
  };

  const renderModerador = (challenge: IChallenge) => {
    return (challenge?.CuratorName &&
      <>
        <S.Line>
          <h5>{t('Moderado por')}</h5>
        </S.Line>

        <S.ContainerProfile>
          <AdminPanelSettings className="i" />

          <div className='divContent'>
            <h3>{challenge?.CuratorName}</h3>
          </div>
        </S.ContainerProfile>
      </>)
  };

  const renderPoint = (challenge: IChallenge) => {
    return (String(challenge?.SpecialAwardName)?.length > 3 ? (
      <>
        {Number(challenge?.ProductsCount) > 0 ? (
          <div className='containerAwardWarning'>
            <Redeem className='iAward' />
            <h5 className='nameAward'>{t('Ao completar o desafio corretamente, você receberá')}:  <h5 className='nameAward' style={{ marginLeft: '2px', fontWeight: 'bold' }}>{challenge?.SpecialAwardName}</h5></h5>
          </div>
        ) : (
          <div className='containerAwardWarning' style={{ borderColor: theme.colors.graphicYellow2 }}>
            <Redeem className='iAward' style={{ color: theme.colors.graphicYellow2 }} />
            <h5 className='nameAward' style={{ color: theme.colors.graphicYellow2 }}>{t('Prêmios esgotados! Ao completar, você receberá a pontuação alternativa')}: <h5 className='nameAward' style={{ marginLeft: '2px', fontWeight: 'bold', color: theme.colors.graphicYellow2 }}>+{challenge?.AlternativePoints} {t('pontos')}</h5></h5>
          </div>
        )}
      </>
    ) : (
      <div className='containerAwardWarning'>
        <Stars className='iAward' />
        <h5 className='nameAward'>{t('Ao completar o desafio corretamente, você receberá')}:  <h5 className='nameAward' style={{ marginLeft: '2px', fontWeight: 'bold' }}>+{challenge?.ScoreAcquired} {t('pontos')}</h5></h5>
      </div>
    ))
  };

  return (
    <S.Container>
      {loading ? (
        <Loading />
      ) : (
        <>
          {challenge?.Title ? (
            <>
              <ChallengeHeader
                title={challenge?.Title}
                buttonNavigate={() => navigate('/home')}
                mediaAvaliacoes={challenge?.AverageRating}
                qtdAvaliacoes={String(challenge?.RatingReviewCount)}
                id={token}
              />

              <S.ContainerLine>
                <S.SelectedLanguage>
                  <img src={renderImageLanguage(Number(challenge?.Language))} className="flag" alt="" />
                  <h5>{renderTextLanguageMobile(Number(challenge?.Language))}</h5>
                </S.SelectedLanguage>

                <S.BoxTime>
                  <Schedule className="i" />
                  <h5>{challenge?.EstimatedTime} {t('min')}</h5>
                </S.BoxTime>

                <S.BoxTime>
                  <Category className="i" />
                  <h5>{challenge?.Category}</h5>
                </S.BoxTime>

                <S.BoxTime>
                  <StackedBarChart className="i" />
                  <h5>{challenge?.DifficultyLevel}</h5>
                </S.BoxTime>
              </S.ContainerLine>

              <S.ContainerID>
                <h2>#{token}</h2>
              </S.ContainerID>

              <S.ContainerContent>
                <S.ContainerData>
                  <div className='divImageContent'>
                    <div className='divImage'>

                      {token2 !== 'PE' && renderPoint(challenge)}

                      <S.Image style={{ aspectRatio: '16/9' }} src={challenge?.CoverImageUrl} />



                      <ButtonMaterial
                        style={{ width: '100%', marginTop: '16px' }}
                        variant="contained"
                        color='info'
                        label={token2 === 'PE' ? t("INICIAR CONTEÚDO") : t("INICIAR DESAFIO")}
                        onClick={handleStartChallenge}
                        disable={Number(challenge?.DaysToTryAgain) > 0}
                      />

                      {challenge.AlreadyCompleted && <div className='divTextReturnChallenge'>
                        <Info style={{ fontSize: '20px', marginRight: '4px', color: theme.colors.seventh }} />
                        <h2 className='textReturnChallenge'>{Number(challenge?.DaysToTryAgain) > 0 ? `${t('Desafio disponível para nova tentiva apenas em')} ${challenge.DaysToTryAgain} ${t('dias.')}` : t('Desafio disponível novamente, participe!')}</h2>
                      </div>}


                    </div>
                  </div>

                  <div className='divContent'>
                    <S.Line>
                      <h5>{t('Autor')}</h5>
                    </S.Line>

                    <S.ContainerProfile>
                      <Person className="i" />

                      <div className='divContent'>
                        <h3>{challenge?.AuthorName}</h3>
                      </div>
                    </S.ContainerProfile>

                    <S.TextContentOfTheAuthor>{t("Essas informações são de responsabilidade do autor.")}</S.TextContentOfTheAuthor>

                    {renderTags(challenge)}

                    {renderFonte(challenge)}

                    {renderModerador(challenge)}

                    <S.ContainerSponsorRetargeting>
                      <Handshake className="iconA" />

                      <div className='divtext'>
                        <h3>{t('Seu patrocínio pode fazer a diferença! Apoie os desafios que você ama e ajude-nos a crescer.')}</h3>

                        <button className='divtextlink' onClick={() => navigate('/canal_dos_patrocinadores')}>
                          <h4>{t('Saiba mais sobre Patrocínios')}</h4>
                          <OpenInNew className="iconB" />
                        </button>
                      </div>
                    </S.ContainerSponsorRetargeting>
                  </div>


                </S.ContainerData>
              </S.ContainerContent>

              <BoxAssessment id={token} content={token2 === 'PE'} />
            </>
          ) : (
            <>
              <HeaderAchievements
                title={t('Desafio')}
                onClick={() => navigate('/home')}
                hideTitle={true}
              />
              <ViewError message={t('Desculpe, o desafio não foi encontrado!')} />
            </>
          )}
        </>
      )
      }




      {isSm ?
        <ModalCustomBottom
          open={openModalStartChallenge}
          onClose={() => setOpenModalStartChallenge(false)}
          onClick={startChallenge}
          title={token2 === 'PE' ? t("Iniciar Conteúdo") : t('Iniciar Desafio')}
          confirmationButtonText={t('INICIAR')}
          textDeclineButton={t('VOLTAR')}
          showIcon={false}
          buttonClose
          buttonsEnabled
          styleButtons={{ justifyContent: 'center' }}
          description={renderTextStartChallenge()}
        />
        :
        <ModalCustom
          open={openModalStartChallenge}
          onClose={() => setOpenModalStartChallenge(false)}
          onClick={startChallenge}
          title={token2 === 'PE' ? t("Iniciar Conteúdo") : t('Iniciar Desafio')}
          styleButtons={{ justifyContent: 'center' }}
          confirmationButtonText={t('INICIAR')}
          textDeclineButton={t('VOLTAR')}
          description={renderTextStartChallenge()}
        />
      }
    </S.Container >
  )
}

export default Challenge
