import React, { useEffect, useState } from 'react';

import * as S from './styles'
import { useTranslation } from 'react-i18next';
import ButtonMaterial from '../../../components/Button';
import { theme } from '../../../styles/theme';
import { useIsSm } from '../../../hooks/useIsSm';
import ToastNotification from '../../../components/ToastNotification';
import ViewLoading from '../../../components/ViewLoading';
import ViewError from '../../../components/ViewError';
import { Pagination } from '@mui/material'
import ModalCustom from '../../../components/ModalCustom';
import iconEN from '../../../assets/images/en.png'
import iconES from '../../../assets/images/es.png'
import iconFR from '../../../assets/images/fr.png'
import iconPT from '../../../assets/images/pt.png'
import { Registration } from '../../../components/TableCategory/types';
import { postCreateTermsOfUse, getTermsOfUseAdmin } from '../../../services/TermsOfUse';
import { IResponseTermsOfUseMultiLanguages } from '../../../services/TermsOfUse/types';

import { Error, NoteAdd } from '@mui/icons-material';
import TableTermsOfUse from '../TableTermsOfUse';
import { registerValidation } from '../../../components/TableCategory/validations';


interface IProps {
  tab: number
}

const ScreenTermsOfUse: React.FC<IProps> = ({ tab }) => {
  const { t } = useTranslation();
  const [termsOfUseList, setTermsOfUseList] = useState<IResponseTermsOfUseMultiLanguages[]>([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState<number | undefined>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [openRegisterCategoriesModal, setOpenRegisterCategoriesModal] = useState(false)
  const [loadingRegister, setLoadingRegister] = useState(false)
  const [registration, setRegistration] = useState<Registration>(new Registration());
  const [errorRegister, setErrorRegister] = useState(true)
  const isSm = useIsSm()
  const [tabs, setTabs] = useState(0)
  const [openRegisterConfirmModal, setOpenRegisterConfirmModal] = useState(false)
  const [currentVersion, setCurrentVersion] = useState('')

  useEffect(() => {
    retrieveCurrentTermsOfUse();
    retrieveTermsOfUse(1, true);
  }, []);

  useEffect(() => {
    if (registration.ValueEn !== '' && registration.ValuePtBr !== '' && registration.ValueEs !== '' && registration.ValueFr !== '') {
      setErrorRegister(false)
    } else {
      setErrorRegister(true)
    }
  }, [registration]);

  const registerTermsOfUse = async () => {
    setLoadingRegister(true)
    try {
      let countError = 0;

      const validation = await registerValidation(registration);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setErrorRegister(true)
        setLoadingRegister(false);
        return;
      }

      const bodyData = {
        ValuePtBr: registration.ValuePtBr,
        ValueEn: registration.ValueEn,
        ValueEs: registration.ValueEs,
        ValueFr: registration.ValueFr,
        Version: `V${Number(currentVersion.substring(1, currentVersion.length)) + 1}`
      };
      const response = await postCreateTermsOfUse(bodyData);
      if (response.Success) {
        setOpenRegisterConfirmModal(false)
        retrieveCurrentTermsOfUse();
        retrieveTermsOfUse(1, true)
        setOpenRegisterCategoriesModal(false)
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Termos de Uso atualizado com sucesso!')
        });
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao atualizar os termos, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao atualizar os termos, tente novamente.')
      });
    }
    setLoadingRegister(false)
  };

  const retrieveCurrentTermsOfUse = async () => {
    setLoading(true)
    try {
      const bodyData = {
        Page: 1,
        PageSize: 1,
        CreatedAt: true
      };
      const response = await getTermsOfUseAdmin(bodyData);
      if (response.Success) {
        setRegistration({
          ValuePtBr: response.Data[0].ValuePtBr,
          ValueEn: response.Data[0].ValueEn,
          ValueEs: response.Data[0].ValueEs,
          ValueFr: response.Data[0].ValueFr,
        })
        setCurrentVersion(response.Data[0].Version)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os termos, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os termos, tente novamente.')
      });
    }
    setLoading(false)
  };

  const retrieveTermsOfUse = async (page: number = 1, clear: boolean = false) => {
    setLoading(true)
    try {
      if (clear) {
        setTermsOfUseList([]);
      }
      const bodyData = {
        Page: page,
        PageSize: 8
      };
      const response = await getTermsOfUseAdmin(bodyData);
      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }
        setTermsOfUseList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os termos, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os termos, tente novamente.')
      });
    }
    setLoading(false)
  };

  const renderTerms = (current?: boolean) => {
    return (
      <>
        {isSm && <S.ContainerButtonsTap>
          <S.ButtonTap active={tabs === 0} onClick={() => setTabs(0)}>
            <img src={iconPT} className="flag" alt="" />
            {!registration.ValuePtBr && <Error className='iErrorB' />}
          </S.ButtonTap>

          <S.ButtonTap active={tabs === 1} onClick={() => setTabs(1)}>
            <img src={iconEN} className="flag" alt="" />
            {!registration.ValueEn && <Error className='iErrorB' />}
          </S.ButtonTap>

          <S.ButtonTap active={tabs === 2} onClick={() => setTabs(2)}>
            <img src={iconES} className="flag" alt="" />
            {!registration.ValueEs && <Error className='iErrorB' />}
          </S.ButtonTap>

          <S.ButtonTap active={tabs === 3} onClick={() => setTabs(3)}>
            <img src={iconFR} className="flag" alt="" />
            {!registration.ValueFr && <Error className='iErrorB' />}
          </S.ButtonTap>
        </S.ContainerButtonsTap>}

        <div className='boxListTerms'>
          {(isSm ? tabs === 0 : true) && <S.BoxTerms style={{ marginRight: isSm ? '0px' : '16px' }}>
            <div className='boxTitle'>
              {!isSm && <img src={iconPT} className="flag" alt="" />}

              <div className='boxText'>
                <h1 className='title'>{current ? t('Termos de Uso atuais') : t('Novos termos de uso')}</h1>
                <h2 className='version'>{currentVersion}</h2>
              </div>
            </div>

            <S.InputTerms
              value={registration.ValuePtBr}
              onChange={(e) => setRegistration({ ...registration, ValuePtBr: e.target.value })}
              disabled={current}
            />
            <div className='lineText'></div>
            {(!registration.ValuePtBr && !isSm) && <Error className='iError' />}
          </S.BoxTerms>}

          {(isSm ? tabs === 1 : true) && <S.BoxTerms style={{ marginRight: isSm ? '0px' : '16px' }}>
            <div className='boxTitle'>
              {!isSm && <img src={iconEN} className="flag" alt="" />}

              <div className='boxText'>
                <h1 className='title'>{current ? t('Current terms of use') : t('New terms of use')}</h1>
                <h2 className='version'>{currentVersion}</h2>
              </div>
            </div>

            <S.InputTerms
              value={registration.ValueEn}
              onChange={(e) => setRegistration({ ...registration, ValueEn: e.target.value })}
              disabled={current}
            />
            <div className='lineText'></div>
            {(!registration.ValueEn && !isSm) && <Error className='iError' />}
          </S.BoxTerms>}

          {(isSm ? tabs === 2 : true) && <S.BoxTerms style={{ marginRight: isSm ? '0px' : '16px' }}>
            <div className='boxTitle'>
              {!isSm && <img src={iconES} className="flag" alt="" />}

              <div className='boxText'>
                <h1 className='title'>{current ? t('Terminos de uso actuales') : t('Nuevos terminos de uso')}</h1>
                <h2 className='version'>{currentVersion}</h2>
              </div>
            </div>

            <S.InputTerms
              value={registration.ValueEs}
              onChange={(e) => setRegistration({ ...registration, ValueEs: e.target.value })}
              disabled={current}
            />
            <div className='lineText'></div>
            {(!registration.ValueEs && !isSm) && <Error className='iError' />}
          </S.BoxTerms>}

          {(isSm ? tabs === 3 : true) && <S.BoxTerms>
            <div className='boxTitle'>
              {!isSm && <img src={iconFR} className="flag" alt="" />}

              <div className='boxText'>
                <h1 className='title'>{current ? t("Conditions d'utilisation actuelles") : t("Nouvelles conditions d'utilisation")}</h1>
                <h2 className='version'>{currentVersion}</h2>
              </div>
            </div>

            <S.InputTerms
              value={registration.ValueFr}
              onChange={(e) => setRegistration({ ...registration, ValueFr: e.target.value })}
              disabled={current}
            />
            <div className='lineText'></div>
            {(!registration.ValueFr && !isSm) && <Error className='iError' />}
          </S.BoxTerms>}
        </div>
      </>
    )
  };

  const onCloseRegister = () => {
    setOpenRegisterCategoriesModal(false)
    retrieveCurrentTermsOfUse();
  };

  return (
    <S.Container>
      <S.ContainerTable>
        <div className='containerButton'>
          <ButtonMaterial
            style={{ borderColor: theme.colors.secundary, width: '100%' }}
            variant="outlined"
            label={t('ATUALIZAR TERMOS DE USO')}
            onClick={() => setOpenRegisterCategoriesModal(true)}
          />
        </div>

        {tab === 0 &&
          <>
            {loading ?
              <ViewLoading />
              :
              <S.ContainerViewTerms>
                {renderTerms(true)}
              </S.ContainerViewTerms>
            }
          </>
        }

        {tab === 1 &&
          <>
            {loading ? (
              <ViewLoading />
            ) : (
              <>
                {termsOfUseList.length > 0 ? (
                  <>
                    < TableTermsOfUse listUsers={termsOfUseList} currentVersion={currentVersion} updateList={() => {
                      retrieveTermsOfUse(1, true)
                      retrieveCurrentTermsOfUse()
                    }} />
                    <S.Pages>
                      <Pagination
                        showFirstButton
                        showLastButton
                        count={totalPages}
                        page={currentPage}
                        color="primary"
                        onChange={(e, p) => {
                          e.preventDefault()
                          e.stopPropagation()
                          retrieveTermsOfUse(p)
                        }}
                      />
                    </S.Pages>
                  </>
                ) : (
                  <ViewError message={t('Nenhum termo de uso encontrado')} />
                )}
              </>
            )}
          </>
        }
      </S.ContainerTable>

      <ModalCustom
        open={openRegisterCategoriesModal}
        onClose={onCloseRegister}
        onClick={registerTermsOfUse}
        styleLine={{ backgroundColor: 'transparent', marginBottom: '24px' }}
        iconHeader={<NoteAdd style={{ fontSize: '24px', color: theme.colorsBase.gray3, backgroundColor: theme.colorsBase.white }} />}
        style={{ width: isSm ? '95%' : '80%', padding: isSm ? '8px' : '24px' }}
        buttonsEnabled={false}
        childrenContent={
          <S.ContainerModalRegister>
            {renderTerms()}

            <div className='containerButtonsModal'>
              <ButtonMaterial
                style={{ borderColor: theme.colors.graphicRed, marginRight: '16px', color: theme.colors.graphicRed }}
                variant="outlined"
                label={t('CANCELAR')}
                onClick={onCloseRegister}
              />

              <ButtonMaterial
                style={{ backgroundColor: errorRegister ? theme.colorsBase.gray3 : theme.colors.seventh, color: theme.colorsBase.white }}
                variant="contained"
                label={t('Salvar')}
                disable={errorRegister}
                onClick={() => setOpenRegisterConfirmModal(true)}
              />
            </div>
          </S.ContainerModalRegister>
        }
        loading={loadingRegister}
        title={t('Atualizar Termos de Uso')}
        confirmationButtonText={t('SALVAR')}
        textDeclineButton={t('CANCELAR')}
      />

      <ModalCustom
        open={openRegisterConfirmModal}
        onClose={() => setOpenRegisterConfirmModal(false)}
        onClick={registerTermsOfUse}
        loading={loading}
        title={t('Confirmar Atualização')}
        confirmationButtonText={t('CONFIRMAR')}
        textDeclineButton={t('CANCELAR')}
        styleButtons={{ justifyContent: 'center' }}
        shortModal
        styleLine={{ marginBottom: '24px' }}
        childrenContent={
          <S.BoxText>
            <p className='text'>{t('Você deseja salvar a nova versão dos Termos de Uso?')}</p>
            <p style={{ color: theme.colors.graphicRed }}>{t('Versão atual')}: <b className='red'>{currentVersion}</b></p>
            <p style={{ color: theme.colorsBase.green2 }}>{t('Nova Versão')}: <b className='green'>V{Number(currentVersion.substring(1, currentVersion.length)) + 1}</b></p>
          </S.BoxText>
        }
      />

    </S.Container>
  )
}

export default ScreenTermsOfUse
