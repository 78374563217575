import React from 'react';
import { Box, Stack, TextField } from '@mui/material';

import ModalCustom from '../ModalCustom';
import { ETipoConteudo } from '../../services/Moderation/types';

import { Content, Title } from './styles';

interface IProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  contentType: ETipoConteudo;
  content: string;
}

const MediaModal: React.FC<IProps> = ({ content, contentType, title = "Visualizar Mídia", open, onClose, }) => (
  <ModalCustom
    open={open}
    onClose={onClose}
    onClick={() => { }}
    buttonsEnabled={false}
    title={title}
    childrenContent={(
      <Stack width="100%" spacing={2}>
        <Box>
          <Title>Tipo De Mídia</Title>
          <Content>{ETipoConteudo[contentType]}</Content>
        </Box>
        {contentType === ETipoConteudo.IMAGE ? (
          <Box width="100%">
            <Title>Mídia</Title>
            <Stack width="100%" bgcolor="#707070" justifyContent='center' alignItems='center'>
              <img
                src={content}
                alt=''
                loading="lazy"
                height="230px"
              />
            </Stack>
          </Box>
        ) : null}
        {contentType === ETipoConteudo.VIDEO ? (
          <Box width="100%">
            <Title>Mídia</Title>
            <Stack width="100%" bgcolor="#707070" justifyContent='center' alignItems='center'>
              <video
                style={{ height: '230px' }}
                id="my-player"
                preload="auto"
                controls
                data-setup='{}'
              >
                <source src={content} ></source>
              </video>
            </Stack>
          </Box>
        ) : null}
        {contentType === ETipoConteudo.AUDIO ? (
          <Box width="100%">
            <Title>Mídia</Title>
            <Stack width="100%" bgcolor="#707070" justifyContent='center' alignItems='center'>
              <audio
                src={content}
              />
            </Stack>
          </Box>
        ) : null}
        {contentType === ETipoConteudo.TEXTFILE ? (
          <TextField
            label="Mídia"
            multiline
            rows={10}
            fullWidth
            disabled
            value={content}
          />
        ) : null}
      </Stack>
    )}
  />
)

export default MediaModal;