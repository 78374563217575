import React from 'react';

import PageContainer from '../../components/Layout/PageContainer';
import PaperContainer from '../../components/Layout/PaperContainer';
import { AntTab, AntTabs } from '../../components/Tabs';
import { theme } from '../../styles/theme';
import HeaderAchievements from '../../components/HeaderAchievements';
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../hooks/useIsSm';
import { CardGiftcard, } from '@mui/icons-material';
import SpecialAwardsView from './SpecialAwardsView';


interface IProps {
  tab: number;
  setNewTab: (newTab: number) => void;
  variant?: "fullWidth" | "standard" | "scrollable";
}

const SpecialAwards: React.FC<IProps> = ({ setNewTab, tab, variant }) => {
  const { t } = useTranslation();
  const isSm = useIsSm()

  const renderDescriptionTab = () => {
    return tab === 0 ? t('Aqui você encontra os prêmios que você conquistou.') : t('Aqui você encontra os prêmios que você conquistou e já retirou ou não.')
  };

  return (
    <PageContainer>
      <HeaderAchievements
        title={t('Prêmios Especiais')}
        icon={<CardGiftcard style={{ color: theme.colors.black5 }} />}
        description={isSm ? renderDescriptionTab() ?? '' : ''}
      />
      <PaperContainer>
        <AntTabs value={tab} onChange={(_, newValue) => setNewTab(newValue)} variant={variant}>
          <AntTab label="PRÊMIOS CONQUISTADOS" sx={{ width: isSm ? '50vw' : 'none', margin: isSm ? '0px auto' : '0px' }} />
          <AntTab label="PRÊMIOS FINALIZADOS" sx={{ width: isSm ? '50vw' : 'none', margin: isSm ? '0px auto' : '0px' }} />
        </AntTabs>

        {tab === 0 && <SpecialAwardsView tab={1} />}
        {tab === 1 && <SpecialAwardsView tab={2} />}
      </PaperContainer>
    </PageContainer>
  )
}

export default SpecialAwards
