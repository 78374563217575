import styled from 'styled-components';
import { theme } from '../../../../styles/theme';
import { responsiveFont } from '../../../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 680px) {
    flex-direction: column;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 16px;

  @media (max-width: 680px) {
    flex-direction: column;
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px 8px 0px 8px;
  margin: 16px 0 8px 0;
  height: 100%;
  width: 100%;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  background-color: ${theme.colorsBase.white};
  border-radius: 8px;

  .titleContent {
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colorsBase.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
  }
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw; 
  height: 100vh;
  overflow:auto; 
  padding: 70px 16px 16px 16px;
  background-color: ${(props) => props.theme.colorsBase.white};

  .boxTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;

    .titleMobile {
      margin-left: 8px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.fourth};
      font-weight: 400;
      font-family: 'Barlow', Regular;
    }
  }
`













export const ContainerChallenge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 15px;

  .btn-prevB {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    position: absolute;
    left: -16px;
    top: 50%;

    .ibtn-prevB {
      color: ${theme.colors.seventh};
      font-size: 40px;
    }
  }

  .btn-nextB {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    position: absolute;
    right: -16px;
    top: 50%;
    margin-top: -40px;

    .ibtn-nextB {
      color: ${theme.colors.seventh};
      font-size: 40px;
    }
  }

  @media (max-width: 680px) {
    width: 100%;
    .btn-prevB, .btn-nextB {
      display: none; // Oculta os botões em telas menores
    }
  }
`;
