import styled from 'styled-components'
import { theme } from '../../styles/theme'
import { responsiveFont } from '../../utils/UtilsGlobal'
import { Card } from '@mui/material'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding-top: 56px; 

  @media (max-width: 680px) {
    background-color: ${(props) => props.theme.colorsBase.white};
  }
`

export const ContainerContent = styled.div`
  padding: 0 40px;
  width: 100%;
  height: 100%;

  @media (max-width: 680px) {
    padding: 0;
  }
`

export const BoxChallenge = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  padding: 40px 16px  24px 16px;
  width: 100%;
  margin: 0 16px;
  min-height: 300px;
  height: 100%;

  @media (max-width: 680px) {
    padding: 16px 8px 16px 16px;
    margin: 6px 0px;
    min-height: 10px;
    height: none;
    flex-direction: row;
  }

  &:hover {
    opacity: 0.8;
  }

      .i {
        font-size: 96px;
        color: ${theme.colors.seventh};

        @media (max-width: 680px) {
          font-size: 42px;
        }
      }

      .title {
        margin-top: 24px;
        text-align: center;
        font-size: ${responsiveFont(20)};
        color: ${(props) => props.theme.colors.secundary};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          margin-top: 0px;
          font-size: ${responsiveFont(14)};
          text-align: left;
        }

        @media (min-width: 1600px) {
          font-size: ${responsiveFont(22)};
        }
      }

      .description {
        margin-top: 16px;
        text-align: center;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colors.friday};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          margin-top: 0px;
          text-align: left;
          font-size: ${responsiveFont(10)};
        }

        @media (min-width: 1600px) {
          font-size: ${responsiveFont(16)};
        }
      }

      .containerText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%; 

        @media (max-width: 680px) {
          justify-content: flex-start;
          align-items: flex-start;
          margin-left: 8px;
        }
      }

      .iBack {
        font-size: 24px;
        color: ${theme.colors.friday};
      }
`

export const ContainerData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: center;
  
  .boxDubleCard {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }

  @media (max-width: 680px) {
    flex-direction: column;

    .boxDubleCard {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 6px;
    }
  }
`

export const ContainerPoints = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  padding: 40px 24px 0px 24px;
  height: 100%;
  border-radius: 16px 16px 0px 0px;
  background-color: ${(props) => props.theme.colorsBase.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 680px) {
    flex-direction: column;
    padding: 24px 16px;
    border-radius: 0px;
    background: transparent;
    box-shadow: none;
  }
`

export const ContainerAchivements = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: center;
  padding: 24px 0 24px 0;
  height: 100%;
  
  @media (max-width: 680px) {
    padding: 0 0 40px 0;
    flex-direction: column;
  }
`


export const ModalExtract = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .topModal {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
  }

      .title {
        text-align: center;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colorsBase.black3};
        font-weight: 400;
        font-family: 'Barlow', Regular;

        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }

      .value {
        margin-left: 16px;
        text-align: center;
        font-size: ${responsiveFont(34)};
        color: ${(props) => props.theme.colors.seventh};
        font-weight: 400;
        font-family: 'Barlow', Regular;

        @media (min-width: 1600px) {
          font-size: ${responsiveFont(36)};
        }
      }
`

export const ListExtract = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props) => props.theme.colorsBase.white2};
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  width: 100%;
  overflow:auto; 

  &::-webkit-scrollbar {
    width: 6px;
    background: ${(props) => props.theme.colorsBase.white2};
    }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colorsBase.gray3};
    border-radius: 8px;
  }
  ::-webkit-scrollbar:horizontal { 
    height: 4px;
  }

  .itemList {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    border-bottom: solid 1px ${(props) => props.theme.colorsBase.gray3};
    padding: 2px 12px 18px 12px;
    background-color: ${(props) => props.theme.colorsBase.white};
    
    .text {
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colorsBase.green2};
      font-weight: 400;
      font-family: 'Barlow', Regular;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

    b {
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colorsBase.green2};
      font-weight: 700;
      font-family: 'Barlow', Regular;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  }
`

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`