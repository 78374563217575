import React from 'react';
import { UseMutationResult } from '@tanstack/react-query';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import ModalCustom from '../../../components/ModalCustom';
import { EMotivoRecusa, IPostRefuseModerationCommand } from '../../../services/Moderation/types';

interface IProps {
  open: boolean;
  onClose: () => void;
  mutation: UseMutationResult<unknown, unknown, Omit<IPostRefuseModerationCommand, "DesafioId">, unknown>
}

const CantModerateModal: React.FC<IProps> = ({ open, onClose, mutation }) => {
  const { t } = useTranslation()

  const { control, handleSubmit } = useForm<Omit<IPostRefuseModerationCommand, 'DesafioId'>>({
  })

  return (
    <ModalCustom
      open={open}
      onClick={handleSubmit((data) => mutation.mutateAsync(data, { onSettled: onClose }))}
      onClose={onClose}
      buttonsEnabled={true}
      title={t("Não posso moderar")}
      childrenContent={(
        <Controller
          control={control}
          name='MotivoRecusa'
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value={EMotivoRecusa.Ferias} control={<Radio />} label="Férias" />
              <FormControlLabel value={EMotivoRecusa.AreaAtuacao} control={<Radio />} label="Não domino o conteúdo/Fora da minha atuação" />
              <FormControlLabel value={EMotivoRecusa.ConflitoInteresse} control={<Radio />} label="Potencial conflito de interesse" />
              <FormControlLabel value={EMotivoRecusa.NaoInformar} control={<Radio />} label="Não informar" />
              <FormControlLabel value={EMotivoRecusa.SemTempo} control={<Radio />} label="Sem tempo" />
            </RadioGroup>
          )}
        />
      )}
      loading={mutation.isLoading}
      confirmationButtonText={t("ENVIAR")}
      textDeclineButton={t("CANCELAR")}
    />
  )
}

export default CantModerateModal;
