import { Close, FilterList, Search } from '@mui/icons-material';
import React from 'react';
import { TextField, InputAdornment } from '@mui/material'
import { theme } from '../../styles/theme'
import * as S from './styles'
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../hooks/useIsSm';

interface IEvent {
    key: string
}

interface IProps {
    value: string;
    onEnterSearch?: (e: IEvent) => void;
    setValue: (e: string) => void;
    setFilterModal?: () => void;
    filterButton?: boolean;
    placeholder: string;
    /* eslint-disable-next-line @typescript-eslint/ban-types */
    style?: Object;
    buttonSearch?: boolean;
    onClearSearch?: () => void;
    autoCompleteList?: string[];
}


const SearchBar: React.FC<IProps> = ({ value, onEnterSearch, setValue, setFilterModal, filterButton, placeholder, style, buttonSearch, onClearSearch, autoCompleteList }) => {
    const { t } = useTranslation();
    const isSm = useIsSm()

    const filterButtonStyleMobile = () => {
        return filterButton ? '85%' : '100%';
    };

    return (
        <S.ContainerSearch style={style}>
            <TextField
                color='secondary'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {' '}
                            {(onEnterSearch && buttonSearch) ? <button style={{ backgroundColor: 'transparent', border: 'none', zIndex: '5' }} onClick={() => onEnterSearch({ key: 'Enter' })}><Search style={{ color: theme.colors.black3 }} /></button> : <Search style={{ color: theme.colors.black3 }} />}{' '}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {(onClearSearch && value.length > 0) && <button style={{ backgroundColor: 'transparent', border: 'none', zIndex: '5' }} onClick={onClearSearch}><Close style={{ color: theme.colors.black3 }} /></button>}
                        </InputAdornment>
                    ),
                    style: {
                        border: `solid 1px ${theme.colors.black3}`,
                        borderRadius: '40px',
                        color: theme.colors.friday,
                        height: '40px',
                        zIndex: 91,
                        backgroundColor: theme.colorsBase.white
                    }
                }}
                placeholder={placeholder ?? "Pesquise"}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={onEnterSearch}
                style={{ width: isSm ? '100%' : '50%', }}
            />

            {autoCompleteList && autoCompleteList?.length > 0 && onEnterSearch &&
                <S.ContainerAutoComplete style={{ width: isSm ? filterButtonStyleMobile() : '50%' }}>
                    {autoCompleteList.slice(0, 3).map((item) => (
                        <button key={item} onClick={() => {
                            setValue(item)
                            onEnterSearch({ key: 'Enter' })
                        }} className='buttonAuto'>{item}</button>
                    ))}
                </S.ContainerAutoComplete>
            }

            {filterButton &&
                <S.Filter onClick={setFilterModal}>
                    <FilterList className='icon' />
                    {!isSm && <label>{t('FILTRAR')}</label>}
                </S.Filter>
            }
        </S.ContainerSearch>
    )
}

export default SearchBar
