import React, { useMemo } from 'react';
import { Autocomplete, TextField, TextFieldProps } from '@mui/material';

import { useCategories } from '../../services/Categories';

type IProps = Omit<TextFieldProps, "value" | "onChange"> & {
  value?: number | null;
  onChange?: (value: number | null) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>
}

const CategorySelect: React.FC<IProps> = ({ value, onChange, onBlur, ...props }) => {
  const { data, isLoading } = useCategories()

  const options = useMemo(() => data?.Data ?? [], [data])
  const selectedValue = useMemo(() => options.find(({Id}) => Id === value) ?? null, [options, value])

  return (
    <Autocomplete
      options={options}
      getOptionLabel={({ Name }) => Name}
      value={selectedValue}
      isOptionEqualToValue={(option, value) => option.Id === value.Id}
      loading={isLoading}
      onChange={(_, value) => onChange?.(value?.Id ?? null)}
      onBlur={onBlur}
      renderInput={(params) => <TextField {...params} {...props} />}
    />
  )

}

export default CategorySelect;
