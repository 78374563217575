import React from 'react';
import { Box } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import PageContainer from '../../components/Layout/PageContainer';
import ChallengeImpact from './components/ChallengeImpact';
import ChallengeTotal from './components/ChallengeTotal';
import { IBaseResponse } from '../../services/RequestService/types';
import { IChallengeDashboardQueryResponse } from '~/services/Dashboard/ChallengesDashboard/types';
import { useTranslation } from 'react-i18next';
import ChallengeRank from './components/ChallengeRank';
import HeaderAchievements from '../../components/HeaderAchievements';

const COLORS = ["#7E57FF", "#05DB89", "#00DAF9"]

interface IProps {
  endDate: number | Date
  query: UseQueryResult<IBaseResponse<IChallengeDashboardQueryResponse>, unknown>
}

const UserDeshboardView: React.FC<IProps> = ({ endDate, query }) => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <HeaderAchievements
        title={t('Dashboard')}
        marginBottom={24}
      />

      <Box
        px="40px"
        display="grid"
        sx={(theme) => ({
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
          gridTemplateAreas: `
            "totalChallenge totalChallenge totalChallenge challengeImpact challengeImpact challengeImpact"
            "views views most most great great"
          `,
          [theme.breakpoints.down(1048)]: {
            gridTemplateColumns: '1fr',
            gridTemplateAreas: `
              "totalChallenge"
              "challengeImpact"
              "views"
              "most"
              "great"
            `,
          }
        })}
        gap={4}
      >
        <ChallengeTotal gridArea="totalChallenge" data={query.data?.Data} />
        <ChallengeImpact gridArea="challengeImpact" endDate={endDate} query={query} />

        <ChallengeRank
          gridArea="views"
          title='Visualizações'
          height="248px"
          data={query.data?.Data.MostStartedChallenges ?? []}
          colors={COLORS}
        />
        <ChallengeRank
          gridArea="most"
          title="Mais Avaliados"
          height="248px"
          data={query.data?.Data.ChallengesWithMoreReviews ?? []}
          colors={COLORS}
        />
        <ChallengeRank
          gridArea="great"
          title='Mais Bem Avaliados'
          height="248px"
          data={query.data?.Data.ChallengesWithBestReviews ?? []}
          colors={COLORS}
        />
      </Box>
    </PageContainer>
  )
}

export default UserDeshboardView;
