import { sendRequest } from '../RequestService';
import { IBodyGET, IGifts, } from './types';

const defaultRoot = 'brindes';

export const getGifts = async (body: IBodyGET) => {
  let url = `${defaultRoot}`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Nome=${body.Nome}`;
  return await sendRequest<IGifts[], IBodyGET>(url, 'GET')
}

export const deleteGifts = async (id: number) => {
  const url = `${defaultRoot}/${id}`
  return await sendRequest<boolean, boolean>(url, 'DELETE')
}

export const postGifts = async (body: FormData) => {
  const url = `${defaultRoot}`
  return await sendRequest<boolean, FormData>(url, 'POST', body)
}

export const putGifts = async (body: FormData, id: number) => {
  const url = `${defaultRoot}/${id}`
  return await sendRequest<boolean, FormData>(url, 'PUT', body)
}