import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  background-color: ${(props) => props.theme.colors.secundary};

  // margin-right: 14px;
  position: fixed;
  z-index: 99;

  button {
    z-index: 3;
    border: 0;
    background: none;

    img {
      width: 120px;
      object-fit: cover;
      margin-left: 8px;
    }
  }

  @media (max-width: 680px) {
    flex-direction: row;
    padding: 16px 16px 16px 24px;
    margin-right: 0px;

    button {
      img {
        width: 120px;
      }
    }
  }

  @media (min-width: 1600px) {
    button {
      img {
        width: 160px;
      }
    }
  }
`

export const ButtonHeader = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 680px) {
    width: 100%;
    justify-content: space-between;
  }
`

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  position: relative;

  margin-top: 56px;

  .curva {
    display: flex;
    position: absolute;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 680px) {
    height: 170px;
    margin-top: 70px;

    .curva {
      height: 170px;
    }
  }

  @media (min-width: 681px) {
    height: 170px;

    .curva {
      height: 170px;
    }
  }

  @media (min-width: 1250px) {
    height: 170px;

    .curva {
      height: 170px;
    }
  }

  @media (min-width: 1600px) {
    height: 200px;

    .curva {
      height: 200px;
    }
  }
`

export const TextHeader = styled.image`
  display: flex; 
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: absolute;
  top: 24px;

  img {
    width: 200px;
    object-fit: cover;
  }

  .icon {
    color: ${(props) => props.theme.colors.secundary};
    width: 40px;
    height: 40px;
  }

  p {
    margin-top: 8px;
    text-align: center;
    font-size: ${responsiveFont(24)};
    color: ${(props) => props.theme.colors.third};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(14)};
      width: 100%;
      text-align: center;
      line-height: 24px;
    }

    @media (min-width: 1600px) {
      margin-top: 16px;
      line-height: 48px;
      font-size: ${responsiveFont(26)};
    }
  }
`

export const BackButton = styled.button`
  display: flex; 
  position: absolute;
  top: 24px;
  left: 24px;
  align-items: center;
  flex-direction: row;
  z-index: 13;

  border: none;
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }
  
  .icon {
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
    background-color: ${(props) => props.theme.colors.seventh};
    border-radius: 50%;
    padding: 4px;
  }

  h1 {
    margin-left: 8px;
    line-height: 16px;
    text-align: center;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;


    @media (min-width: 1600px) {
      line-height: 24px;
      font-size: ${responsiveFont(14)};
    }
  }
`