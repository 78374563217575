import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'
import { theme } from '../../styles/theme'


export const ContainerModal = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
   top: 50%;
  left: 50%;
   position: absolute;
  width:  ${(props) => (props.active ? '400px' : '720px')}; 
  max-height: 90%;
  overflow:auto; 
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 4px;
  box-shadow: 24;
  padding: 24px;

  h4 {
    margin: 0 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    margin-bottom: 40px;
  }

  .icon {
    color: ${(props) => props.theme.colorsBase.black};
    width: 20px;
    height: 20px;
    top: 24px;
    right: 24px;
    position: absolute;
  }

  .buttonClose {
    display: flex;
    position: absolute;
    top: 0px;
    right: 0px;
    align-items: center;
    flex-direction: row;
    border: none;
    background-color: transparent;
  
    &:hover {
      opacity: 0.8;
    }

    .icon {
      color: ${(props) => props.theme.colorsBase.black};
      width: 16px;
      height: 16px;
    }
  }
 
  .buttonAcceptedTerm {
    display: flex;
    align-items: center;
  
    flex-direction: row;
    border: none;
    background-color: transparent;
  
    &:hover {
      opacity: 0.8;
    }

    .iconAcceptedTerm {
      color: ${(props) => props.theme.colors.fifteen};
      width: 24px;
      height: 24px;
    }

    .iconAcceptedTermBlank {
      color: ${(props) => props.theme.colors.fourteen};
      width: 24px;
      height: 24px;
    }

    h1 {
      margin-left: 8px;
      line-height: 20px;
      letter-spacing: 0.25px;
      text-align: center;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Medium;
    }
  }

  .button {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  @media (min-width: 1600px) {
    width: 900px;

    h5 {
      line-height: 24px;
      font-size: ${responsiveFont(20)};
    }
  }

  @media (max-width: 680px) {
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    transform: none;
    top: 0;
    left: 0;
    position: none;
    border-radius: 0px;
    padding-top: 70px;
    // padding: 70px 0px 16px 0px;
    justify-content: flex-start;

    .buttonClose {
      display: none;
    }

    .button {
      flex-direction: column;
      justify-content: center;
      padding: 0px 24px;
      margin-top: 0px;

      .buttonTop {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }

    h4 {
      margin-bottom: 16px;
    }
  }
`

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 0.5px;
  background-color: ${(props) => props.theme.colors.ten};
  margin: 24px 0 48px 0;

  .i {
    color: #201E1E;
    font-size: 24px;
    background-color:  #F9F9F9;
  }

  h5 {
    line-height: 32px;
    letter-spacing: 0.18px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    background-color:  ${(props) => props.theme.colorsBase.white};
    padding: 0px 8px;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(24)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
    margin: 16px 0 0px 0;
  }
`

export const ContainerQuestionCount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  align-self: center;
  bottom: 30px;
  width: 100%;
  z-index: -1;

  @media (max-width: 680px) {
    z-index: 1;
    bottom: 0px;
    position: relative;
    margin-bottom: 18px;
    margin-top: 40px;
  }
`

export const ButtonQuestion = styled.div<{ active: boolean }>`
    display: flex;
    height: 20px;
    width: 20px;
    margin: 0px 2px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:  ${props => props.active ? theme.colors.primary : "#cccccc"};
    border-radius: 50%;

    .i {
      color: ${props => props.active ? theme.colorsBase.white : theme.colors.primary};
      width: 16px;
      height: 16px;
      align-self: center;
    }

    .textbutton {
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${props => props.active ? theme.colorsBase.white : theme.colors.black};
      font-weight: 500;
      font-family: 'Barlow', Medium;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
`

export const ContainerChildren = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;

  @media (max-width: 680px) {
    height: 100%;
    margin-top: 42px;
    margin-bottom: auto;
  }
`
