import React from 'react';
import { Box, Card, CardContent, CardProps, Stack, Typography } from '@mui/material';

import CircularPercent from '../../../components/CircularPercent';
import { theme } from '../../../styles/theme';
import { IChallengeDashboardQueryResponse } from '~/services/Dashboard/ChallengesDashboard/types';

interface IProps extends CardProps<typeof Box> {
  data?: IChallengeDashboardQueryResponse,
}

const ChallengeTotal: React.FC<IProps> = ({ data, ...props }) => (
  <Card component={Box} {...props}>
    <CardContent>
      <Stack spacing={2}>
        <Typography fontSize="20px" fontWeight="400" color='#201E1E'>Total de Desafios: {data?.Total}</Typography>

        <Stack
          direction="row"
          spacing={4}
          flexWrap="wrap"
        >
          <Stack direction="row" alignItems="center" spacing={0.8}>
            <Box width="14px" height="14px" bgcolor={theme.colors.graphicGreen} borderRadius="50%" />
            <Typography>Ativos</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.8}>
            <Box width="14px" height="14px" bgcolor={theme.colors.graphicYellow} borderRadius="50%" />
            <Typography>Em Revisão</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.8}>
            <Box width="14px" height="14px" bgcolor={theme.colors.graphicRed} borderRadius="50%" />
            <Typography>Rejeitados</Typography>
          </Stack>
        </Stack>

        <Stack
          justifyContent="space-between"
          sx={(theme) => ({
            flexDirection: "row",
            [theme.breakpoints.down(800)]: {
              flexDirection: "column",
            }
          })}
        >
          <Stack alignItems="center">
            <CircularPercent
              value={data?.PercentageOfActive}
              color={theme.colors.graphicGreen}
              backgroundColor={theme.colors.graphicBackgroundGreen}
            >
              <Typography fontSize="24px" fontFamily="Barlow">{data?.PercentageOfActive}%</Typography>
            </CircularPercent>
            <Typography textAlign="center">Desafios ativos: {data?.CountOfActive}/{data?.Total}</Typography>
          </Stack>
          <Stack alignItems="center">
            <CircularPercent
              value={data?.PercentageOfUnderReview}
              color={theme.colors.graphicYellow}
              backgroundColor={theme.colors.graphicBackgroundYellow}
            >
              <Typography fontSize="24px" fontFamily="Barlow">{data?.PercentageOfUnderReview}%</Typography>
            </CircularPercent>
            <Typography textAlign="center">Desafios em revisão: {data?.CountOfUnderReview}/{data?.Total}</Typography>
          </Stack>
          <Stack alignItems="center">
            <CircularPercent
              value={data?.PercentageOfUnapproved}
              color={theme.colors.graphicRed}
              backgroundColor={theme.colors.graphicBackgroundRed}
            >
              <Typography fontSize="24px" fontFamily="Barlow">{data?.PercentageOfUnapproved}%</Typography>
            </CircularPercent>
            <Typography textAlign="center">Desafios rejeitados: {data?.CountOfUnapproved}/{data?.Total}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </CardContent>
  </Card>
)

export default ChallengeTotal;
