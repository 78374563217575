export interface ModalProps {
  open: boolean;
}

export class Registration {
  dateOfBirth = '';
  cpf = '';
}

export class RegistrationError {
  dateOfBirth = '';
  cpf = '';
}