/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useAuth } from './contexts/Auth'
import LandingPage from './pages/LandingPage'
import RecoverPassword from './pages/RecoverPassword';
import LoginAndRegister from './pages/loginAndRegister'
import EmailConfirmation from './pages/EmailConfirmation';
import Settings from './pages/Settings';
import MyProfile from './pages/MyProfile';
import Gifts from './pages/Gifts';
import Home from './pages/Home';
import Challenge from './pages/Challenge';
import AnswerQuestions from './pages/AnswerQuestions';
import ChallengeConcluded from './pages/ChallengeConcluded';
import Notification from './pages/Notification';
import Inspection from './pages/Inspection';
import ChallengeInspection from './pages/ChallengeInspection';
import EditAndViewChallenge from './pages/EditAndViewChallenge';
import ViewShortly from './components/ViewShortly';
import ChallengeContent from './pages/ViewChallengeContent';
import MyAchievements from './pages/MyAchievements';
import MyPoints from './pages/MyPoints';
import MyGiftsAndProducts from './pages/MyGiftsAndProducts';
import MyCertificates from './pages/MyCertificates';
import NotificationHistory from './pages/NotificationHistory';
import PageUnderConstruction from './pages/PageUnderConstruction';
import UserDeshboard from './pages/UserDeshboard';
import InspectionDashboard from './pages/InspectionDashboard';
import About from './pages/About';
import CompanyChannel from './pages/CompanyChannel';
import TermsAndPolicy from './pages/TermsAndPolicy';
import MyHealers from './pages/MyHealers';
import CuratorInformation from './pages/CuratorInformation';
import OpenExternalLink from './pages/OpenExternalLink';
import SponsorChannel from './pages/SponsorChannel';
import Contacts from './pages/Contacts';
import ChallengeAvailable from './pages/ChallengeAvailable';
import SpecialChallenges from './pages/SpecialChallenges';
import TermsOfUseAdm from './pages/TermsOfUse';
import UserAdm from './pages/UserManagement';
import AdministrativeFunctions from './pages/AdministrativeFlow/AdministrativeFunctions';
import AdmChallenge from './pages/AdministrativeFlow/AdmChallenge';
import ProductAdm from './pages/ProductManagement';
import ProductUser from './pages/Product';
import SpecialAwardsUser from './pages/SpecialAwards';
import SpecialProgramManagement from './pages/SpecialProgramManagement';
import SpecialProgramDetails from './pages/SpecialProgramManagement/SpecialProgramDetails';
import TriageSpecialProgram from './pages/TriageSpecialProgram';
import SpecialProgram from './pages/SpecialProgram';
import SpecialProgramContentView from './pages/SpecialProgram/SpecialProgramContent';
import ViewChallengePage from './pages/ViewChallenge/view';
import MySharesPage from './pages/MyShares/view';


const AppRoutes: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    return isAuthenticated ? children : <Navigate to="/login" replace />
  }

  const LoginRoute = () => {
    return isAuthenticated ? <Navigate to="/home" replace /> : <LoginAndRegister />
  }

  const LandingPageRoute = () => {
    return isAuthenticated ? <Navigate to="/home" replace /> : <LandingPage />
  }

  const EmailConfirmationRoute = () => {
    return isAuthenticated ? <Navigate to="/home" replace /> : <EmailConfirmation />
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <LandingPageRoute />
        }
      />

      <Route
        path="/login/:token?/:token2?/:token3?"
        element={
          <LoginRoute />
        }
      />

      <Route
        path="/recover_password/:token?"
        element={
          <RecoverPassword />
        }
      />

      <Route
        path="/email_confirmation/:token?"
        element={
          <EmailConfirmationRoute />
        }
      />

      <Route
        path="/meu_perfil"
        element={
          <ProtectedRoute>
            <MyProfile />
          </ProtectedRoute>
        }
      />

      <Route
        path="/inspection"
        element={
          <ProtectedRoute>
            <Inspection />
          </ProtectedRoute>
        }
      />

      <Route
        path="/inspection/:id"
        element={
          <ProtectedRoute>
            <ChallengeInspection />
          </ProtectedRoute>
        }
      />

      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <UserDeshboard />
          </ProtectedRoute>
        }
      />

      <Route
        path="/dashboard/inspection"
        element={
          <ProtectedRoute>
            <InspectionDashboard />
          </ProtectedRoute>
        }
      />

      <Route
        path="/configuracao"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />

      <Route
        path="/administracao"
        element={
          <ProtectedRoute>
            <AdministrativeFunctions />
          </ProtectedRoute>
        }
      />

      <Route
        path="/administracao/desafios"
        element={
          <ProtectedRoute>
            <AdmChallenge />
          </ProtectedRoute>
        }
      />

      <Route
        path="/brindes"
        element={
          <ProtectedRoute>
            <Gifts />
          </ProtectedRoute>
        }
      />

      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />

      <Route
        path="/desafios_disponiveis"
        element={
          <ProtectedRoute>
            <ChallengeAvailable />
          </ProtectedRoute>
        }
      />

      <Route
        path="/desafios_especiais"
        element={
          <ProtectedRoute>
            <SpecialChallenges />
          </ProtectedRoute>
        }
      />

      <Route
        path="/desafio/:token?/:token2?"
        element={
          <Challenge />
        }
      />

      <Route
        path="/desafio_conteudo/:token?/:token2?"
        element={
          <ProtectedRoute>
            <ChallengeContent />
          </ProtectedRoute>
        }
      />


      <Route
        path="/desafio/perguntas/:token?/:token2?"
        element={
          <ProtectedRoute>
            <AnswerQuestions />
          </ProtectedRoute>
        }
      />

      <Route
        path="/desafio/concluido/:token?/:token2?"
        element={
          <ProtectedRoute>
            <ChallengeConcluded />
          </ProtectedRoute>
        }
      />

      <Route
        path="/notificacoes/"
        element={
          <ProtectedRoute>
            <Notification />
          </ProtectedRoute>
        }
      />

      <Route
        path="/desafios/:token?"
        element={
          <ProtectedRoute>
            <ViewChallengePage />
          </ProtectedRoute>
        }
      />

      <Route
        path="/desafios/editar/:token?/:token2?"
        element={
          <ProtectedRoute>
            <EditAndViewChallenge />
          </ProtectedRoute>
        }
      />

      <Route
        path="/minhas_conquistas/:token?"
        element={
          <ProtectedRoute>
            <MyAchievements />
          </ProtectedRoute>
        }
      />

      <Route
        path="/meus_pontos"
        element={
          <ProtectedRoute>
            <MyPoints />
          </ProtectedRoute>
        }
      />

      <Route
        path="/canal_empresarial"
        element={
          <ProtectedRoute>
            <CompanyChannel />
          </ProtectedRoute>
        }
      />

      <Route
        path="/meus_brindes_e_produtos"
        element={
          <ProtectedRoute>
            <MyGiftsAndProducts />
          </ProtectedRoute>
        }
      />

      <Route
        path="/meus_certificados"
        element={
          <ProtectedRoute>
            <MyCertificates />
          </ProtectedRoute>
        }
      />

      <Route
        path="/historico_notificacoes"
        element={
          <ProtectedRoute>
            <NotificationHistory />
          </ProtectedRoute>
        }
      />

      <Route
        path="/em_desenvolvimento"
        element={
          <ProtectedRoute>
            <PageUnderConstruction />
          </ProtectedRoute>
        }
      />

      <Route
        path="/sobre"
        element={
          <ProtectedRoute>
            <About />
          </ProtectedRoute>
        }
      />

      <Route
        path="/termos_e_politica/:token?"
        element={
          <TermsAndPolicy />
        }
      />

      <Route
        path="/curadores"
        element={
          <ProtectedRoute>
            <MyHealers />
          </ProtectedRoute>
        }
      />

      <Route
        path="/informacoes_curador/:token?"
        element={
          <ProtectedRoute>
            <CuratorInformation />
          </ProtectedRoute>
        }
      />

      <Route
        path="/link_externo"
        element={
          <ProtectedRoute>
            <OpenExternalLink />
          </ProtectedRoute>
        }
      />

      <Route
        path="/canal_dos_patrocinadores"
        element={
          <ProtectedRoute>
            <SponsorChannel />
          </ProtectedRoute>
        }
      />

      <Route
        path="/contatos"
        element={
          <ProtectedRoute>
            <Contacts />
          </ProtectedRoute>
        }
      />

      <Route
        path="/minhas_participacoes"
        element={
          <ProtectedRoute>
            <MySharesPage />
          </ProtectedRoute>
        }
      />

      <Route
        path="/gerenciamento_de_termos"
        element={
          <ProtectedRoute>
            <TermsOfUseAdm />
          </ProtectedRoute>
        }
      />

      <Route
        path="/gerenciamento_de_produtos"
        element={
          <ProtectedRoute>
            <ProductAdm />
          </ProtectedRoute>
        }
      />

      <Route
        path="/produtos"
        element={
          <ProtectedRoute>
            <ProductUser />
          </ProtectedRoute>
        }
      />

      <Route
        path="/premios_especiais"
        element={
          <ProtectedRoute>
            <SpecialAwardsUser />
          </ProtectedRoute>
        }
      />

      <Route
        path="/gerenciamento_de_usuarios"
        element={
          <ProtectedRoute>
            <UserAdm />
          </ProtectedRoute>
        }
      />

      <Route
        path="/gerenciamento_de_programas_especiais"
        element={
          <ProtectedRoute>
            <SpecialProgramManagement />
          </ProtectedRoute>
        }
      />

      <Route
        path="/detalhes_do_programa_especial/:token?"
        element={
          <ProtectedRoute>
            <SpecialProgramDetails />
          </ProtectedRoute>
        }
      />

      <Route
        path="/detalhes_do_programa_especial/conteudo/:token?"
        element={
          <ProtectedRoute>
            <SpecialProgramDetails />
          </ProtectedRoute>
        }
      />

      <Route
        path="/triagem_de_programas_especiais"
        element={
          <ProtectedRoute>
            <TriageSpecialProgram />
          </ProtectedRoute>
        }
      />

      <Route
        path="/programas_especiais/:token?"
        element={
          <ProtectedRoute>
            <SpecialProgram />
          </ProtectedRoute>
        }
      />

      <Route
        path="/programa_especial/:token?"
        element={
          // <ProtectedRoute>
          <SpecialProgramContentView />
          // </ProtectedRoute>
        }
      />

      <Route path="*" element={<ViewShortly />} />
    </Routes>
  )
}

export default AppRoutes
