import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

import logo from "../../../assets/images/logo.png"
import curva6 from '../../../assets/images/curva6.png'

import { useIsSm } from '../../../hooks/useIsSm';

import { ContainerHeader, BackButton, TextHeader, TitleHeader } from './styles';

export interface IProps {
  title?: string;
  subTitle?: string;
}

const AboutHeader: React.FC<IProps> = ({ title, subTitle }) => {
  const navigate = useNavigate()
  const isSm = useIsSm()
  const { t } = useTranslation()

  if (isSm) return null;

  return (
    <ContainerHeader>
      <img src={curva6} className="curva" alt='' />

      <BackButton onClick={() => navigate(-1)}>
        <ArrowBack className="icon" />
        <h1>{t(`Voltar`)}</h1>
      </BackButton>

      {title ? (
        <>
          <TitleHeader>{t(title)}</TitleHeader>
          {subTitle && <TitleHeader style={{ top: "82px" }}>{t(subTitle)}</TitleHeader>}
        </>
      ) :
        <TextHeader>
          <img src={logo} height='74px' alt='' />
        </TextHeader>
      }
    </ContainerHeader>
  )
}

export default AboutHeader;
