import { cleanLocalStorage, getLocalStorage, setLocalStorage } from '../../utils/StorageLocal';
import { USER_GET } from '../../config/constants';
import { IToken, IAuthRequest, IExternalAuthRequest } from './types';
import { sendRequest } from '../RequestService';
import { IUser } from '../../contexts/Auth/types';

const defaultRoot = 'authentications';

export const login = async (body: IAuthRequest) => {
  const url = `${defaultRoot}`
  return await sendRequest<IUser, IAuthRequest>(url, 'POST', body)
}

export const externalLogin = async (body: IExternalAuthRequest) => {
  const url = `${defaultRoot}/externals/${body.ProviderLoginType}`
  return await sendRequest<IUser, string>(url, 'POST', body.Token)
}

export const getRefreshToken = async () => {
  const url = `${defaultRoot}/refresh-tokens`
  const storagedUser = getLocalStorage<IUser>(USER_GET);
  const body = {
    RefreshToken: storagedUser?.RefreshToken ? storagedUser.RefreshToken : ''
  };
  const response = await sendRequest<IToken, { RefreshToken: string }>(url, 'POST', body)
  if (response.Success) {
    setLocalStorage(USER_GET, response.Data);
  } else {
    cleanLocalStorage();
    window.location.reload();
  }

  return response
}

export const logout = () => {
  setTimeout(() => {
    cleanLocalStorage()
    window.location.reload()
  }, 1000) // 1 seconds
}