import { CSSProperties } from 'react'
import { toast, ToastContent, ToastOptions } from 'react-toastify'

import { IToastProps } from './Interface/IToastProps'
import ToastTypes from './ToastTypes'

import './style.css'

const toastList = new Set()

/**
 * Retorna um tipo de notificação em Toast
 * @param tID Id da notificação, necessário para
 *  não exibir a mesma notificação  *  várias vezes
 * @param type Tipo de notificação que será exibida.
 * Caso queira enviar um componente próprio estilizado à sua vontade,
 *  envie uma string vazia e o componente desejado no parametro 'content'
 * @param content Mensagem ou componente que será exibido dentro da notificação
 * @param toastOptions Opcional* Objeto com parâmetros de configuração
 * da notificação, substituem os correspondentes que estão padronizados (override)
 */
const ToastNotification = (params: IToastProps): void => {
  const { id, type, toastOptions } = params

  const component: ToastContent = ToastTypes(params)

  const backgroundWhite: CSSProperties = { backgroundColor: 'black' }

  const defaultOptions: ToastOptions = {
    toastId: id,
    type: 'default',
    className: `toast toast-${type}`,
    position: 'bottom-right',
    draggable: true,
    onClose: () => {
      const toastArr = Array.from(toastList)
      const toastIndex = toastArr.indexOf(id)
      const dltToast = toastArr.splice(toastIndex, 1)
      toastList.delete(dltToast)
    },
    onOpen: () => {
      toastList.add(id)
    },
    progressStyle: backgroundWhite
  }

  interface IcloseOptions {
    autoClose: number | false
    closeOnClick: boolean
  }

  const closeSuccess: IcloseOptions = {
    autoClose: 3500,
    closeOnClick: true
  }

  const closeError: IcloseOptions = {
    autoClose: 3500,
    closeOnClick: true
  }

  switch (type) {
    case 'success':
      toast(component, {
        ...defaultOptions,
        ...closeSuccess,
        type,
        ...toastOptions,
        progressStyle: { backgroundColor: '#4cae4c' }
      })
      break
    case 'error':
      toast(component, {
        ...defaultOptions,
        type,
        ...toastOptions,
        ...closeError,
        progressStyle: { backgroundColor: '#d8544f' }
      })
      break
    case 'info':
      toast(component, {
        ...defaultOptions,
        type,
        closeOnClick: true,
        ...toastOptions,
        progressStyle: { backgroundColor: '#2a10ab' }
      })
      break
    case 'loading-info':
      toast(component, {
        ...defaultOptions,
        type: 'info',
        autoClose: false,
        closeButton: false,
        closeOnClick: false,
        ...toastOptions,
        progressStyle: { backgroundColor: '#2a10ab' }
      })
      break
    case 'update':
      toast(component, {
        ...defaultOptions,
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        position: toast.POSITION.TOP_CENTER,
        ...toastOptions,
        progressStyle: { backgroundColor: '#2a10ab' }
      })
      break
    case 'custom':
      toast(component, { ...defaultOptions, ...toastOptions })
      break
    default:
      break
  }
}

export default ToastNotification
