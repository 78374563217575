import { IEmailConfirmation, IRedefinePassword, IRegistration, ISendCode, ISendEmail } from './types';
import { sendRequest } from '../RequestService';
import { ICompleteRegistration, IUser } from '../../contexts/Auth/types';
import { ICompleteTermsOfUse } from '../TermsOfUse/types';

const defaultRoot = 'usuarios';

export const postSendEmail = async (body: ISendEmail) => {
  const url = `${defaultRoot}/enviar-codigo`
  return await sendRequest<boolean, ISendEmail>(url, 'POST', body)
}

export const postConfirmCode = async (body: ISendCode) => {
  const url = `${defaultRoot}/alterar-senha/codigo/${body.Codigo}/hash`
  return await sendRequest<ISendCode, ISendCode>(url, 'POST', body)
}

export const putRedefinePassword = async (body: IRedefinePassword) => {
  const url = `${defaultRoot}/alterar-senha`
  return await sendRequest<boolean, IRedefinePassword>(url, 'PUT', body)
}

export const postCompleteRegistration = async (body: ICompleteRegistration) => {
  const url = `user-accounts/with-providers`
  return await sendRequest<IUser, ICompleteRegistration>(url, 'POST', body)
}

export const postTermsOfUse = async (body: ICompleteTermsOfUse) => {
  const url = `terms-of-uses/accept`
  return await sendRequest<boolean, ICompleteTermsOfUse>(url, 'POST', body)
}

export const postUser = async (body: IRegistration) => {
  const url = `user-accounts`
  return await sendRequest<boolean, IRegistration>(url, 'POST', body)
}

export const postEmailConfirmation = async (body: IEmailConfirmation) => {
  const url = `${defaultRoot}/confirmar-email`
  return await sendRequest<IUser, IEmailConfirmation>(url, 'POST', body)
}

export const postSendEmailConfirmation = async (body: ISendEmail) => {
  const url = `${defaultRoot}/enviar-codigo`
  return await sendRequest<boolean, ISendEmail>(url, 'POST', body)
}