import React from 'react';
import { Control, useFieldArray } from 'react-hook-form';

import ModalButton from '../../../../components/ModalButton';
import QuizForm from '../../../../components/forms/QuizForm';
import { IChalengeManagementApprovalCommand } from '../../../../services/ChallengesManagement/Approvals/types';
import { ETipoConteudo, ETipoPergunta } from '../../../../services/Moderation/types';
import { uploadFiles } from '../../../../services/Users';

interface IProps {
  control?: Control<IChalengeManagementApprovalCommand>,
  hidden?: boolean
}

const CreateQuizModal: React.FC<IProps> = ({ control, hidden }) => {
  const { append } = useFieldArray({ control, name: "command.Questions" })

  return (
    <ModalButton
      sx={{ display: hidden ? 'none' : undefined }}
      variant="contained"
      size='small'
      color="info"
      buttonChildren="ADICIONAR QUIZ"
    >
      {({ open, onClose }) => (
        <QuizForm
          open={open}
          onSubmit={async (submitData) => {
            const formData = new FormData()
            let fileUrl: string | null = null

            if (submitData.audio) {
              formData.append("file", submitData.audio)
              const { Data: { FileUrl } } = await uploadFiles(formData, ETipoConteudo.AUDIO)
              fileUrl = FileUrl
            }
            if (submitData.questionType === '1') {
              const trueAnswer = parseInt(submitData.trueAnswer, 10)

              const QuizAlternatives = [
                submitData.response1,
                submitData.response2,
                submitData.response3,
                submitData.response4,
                submitData.response5
              ].map(value => value.trim())
                .filter(value => value !== "")
                .map<{
                  Answer: string | null;
                  RightAnswer: boolean;
                }>((value, index) => ({
                  Answer: value,
                  RightAnswer: index + 1 === trueAnswer
                }));

              append({
                Question: submitData.question,
                QuestionType: ETipoPergunta.ALTERNATIVAS,
                QuizAlternatives: QuizAlternatives,
                AudioUrl: fileUrl
              }, { shouldFocus: false })
            } else {
              append({
                Question: submitData.question,
                QuestionType: ETipoPergunta.ALTERNATIVAS,
                QuizAlternatives: [
                  {
                    Answer: "Verdadeiro",
                    RightAnswer: submitData.status === null ? false : submitData.status
                  },
                  {
                    Answer: "Falso",
                    RightAnswer: submitData.status === null ? false : !submitData.status
                  }
                ],
                AudioUrl: fileUrl
              }, { shouldFocus: false })
            }
          }}
          onClose={onClose}
        />
      )}
    </ModalButton>
  );
}

export default CreateQuizModal;