import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import site from "../../assets/images/about/site.png"
import todos from "../../assets/images/about/todos.png"
import criadores from "../../assets/images/about/criadores.png"
import confiavel from "../../assets/images/about/confiavel.png"
import pontos from "../../assets/images/about/pontos.png"
import privacidade from "../../assets/images/about/privacidade.png"

import PageContainer from '../../components/Layout/PageContainer';
import AboutHeader from '../../components/Layout/AboutHeader';
import Footer from '../../components/Footer';
import { withLineBreak } from '../../utils';

import { Title, ContentText, BoxWithImage, SideImage, Grid } from './styles';

const AboutView: React.FC = () => {
  const { t } = useTranslation()

  return (
    <PageContainer>
      <AboutHeader />

      <Grid>
        <Box gridArea="geral">
          <Title>{t("Visão Geral")}</Title>
          <ContentText>{withLineBreak(t("sobre_visao_geral"))}</ContentText>
        </Box>

        <Box gridArea="fazemos">
          <Title>{t("O Que Fazemos?")}</Title>
          <ContentText>{withLineBreak(t("sobre_oque_fazemos"))}</ContentText>
        </Box>

        <BoxWithImage gridArea="site">
          <SideImage src={site} />
          <Title>{t("Nosso Site, Seu Site")}</Title>
          <ContentText>{withLineBreak(t("sobre_nosso_site"))}</ContentText>
        </BoxWithImage>

        <BoxWithImage gridArea="todos">
          <SideImage src={todos} />
          <Title>{t("Pra Todo Mundo!")}</Title>
          <ContentText>{withLineBreak(t("sobre_pra_todo_mundo"))}</ContentText>
        </BoxWithImage>

        <BoxWithImage gridArea="criadores">
          <SideImage src={criadores} />
          <Title>{t("Todos Podem Ser Criadores")}</Title>
          <ContentText>{withLineBreak(t("sobre_todos_podem_ser_criadores"))}</ContentText>
        </BoxWithImage>

        <BoxWithImage gridArea="confiavel">
          <SideImage src={confiavel} />
          <Title>{t("Informação Confiável")}</Title>
          <ContentText>{withLineBreak(t("sobre_informação_confiavel"))}</ContentText>
        </BoxWithImage>

        <BoxWithImage gridArea="pontos">
          <SideImage src={pontos} />
          <Title>{t("Ganhe Mais que Pontos")}</Title>
          <ContentText>{withLineBreak(t("sobre_ganhe_mais_que_pontos"))}</ContentText>
        </BoxWithImage>

        <BoxWithImage gridArea="privacidade">
          <SideImage src={privacidade} />
          <Title>{t("Comprometidos com sua Privacidade")}</Title>
          <ContentText>{withLineBreak(t("sobre_comprometidos_com_sua_privacidade"))}</ContentText>
        </BoxWithImage>
      </Grid>

      <Footer>

        <Title>{t("Fale Conosco")}</Title>
        <ul style={{ marginBottom: '48px' }}>
          <li><ContentText fontSize="16px">Responsável técnico: Dra Raquel Ajub Moyses</ContentText></li>
          <li><ContentText fontSize="16px">Email: curar@curarsaude.com.br</ContentText></li>
        </ul>
      </Footer>
    </PageContainer>
  );
}

export default AboutView;
