

export const emptyItem: ISettings = {
  Id: 0,
  Value: "",
  DataType: 1,
  Name: ""
}

export interface ITableSettings {
  listUsers: ISettings[]
  updateList: () => void
}

export interface ISettings {
  Id: number,
  Name: string
  Value: string,
  DataType: number
}

export interface ISettingsPut {
  Id?: number,
  Value: string | boolean,
  DataType?: number
}
