import styled from 'styled-components'
import { responsiveFont } from '../../../utils/UtilsGlobal'

export const ContainerCardQuizResume = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 8px;
  border: solid 1px ${(props) => props.theme.colors.black5};
  border-radius: 16px;
  margin-top: 12px;
  
  .containerAlternatives {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 24px;
    margin-top: 8px;
    padding-left: 40px;
  }

  .boxAlternative{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: ${(props) => props.theme.colorsBase.green4};
    padding: 0px 8px;
    border-radius: 12px;
    border: solid 1px ${(props) => props.theme.colorsBase.green3};

    h3 {
      padding-right: 12px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colorsBase.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    h5 {
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
    
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    .iconDone {
      color: ${(props) => props.theme.colorsBase.green3};
      width: 18px;
      height: 18px;
      margin-left: 24px;
    }
  }

  .boxExpansive {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: -24px;

    .buttonBoxExpansive {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      background: none;
      border: none;

      .textExpansive {
        text-align: left;
        font-size: ${responsiveFont(10)};
        color: ${(props) => props.theme.colors.fourth};
        font-weight: 400;
        font-family: 'Barlow', Regular;
      }

      .iExpansiveQuestion {
        color: ${(props) => props.theme.colors.black};
        width: 24px;
        height: 24px;
      }
    }
  }

  .boxAudioWithExpansive {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 4px;
    padding-left: 40px;
  }

  .responseQuestion {
    margin-top: 2px;
    margin-left: 40px;
    margin-right: 100px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colorsBase.green2};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }

    @media (max-width: 680px) {
      margin-right: 0px;
    }
  }

  .topTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    
    .buttonDeleteQuestion{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: none;
      border: none;

      .iDeleteQuestion {
        color: ${(props) => props.theme.colors.graphicRed};
        width: 24px;
        height: 24px;
      }
    }

    .titleQuestion {
      margin-right: auto;
      text-align: left;
      font-size: ${responsiveFont(20)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(22)};
      }
    }

    .numberQuestionBox{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: ${(props) => props.theme.colors.secundary};
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 8px;

      .numberQuestion {
        text-align: center;
        font-size: ${responsiveFont(20)};
        color: ${(props) => props.theme.colorsBase.white};
        font-weight: 400;
        font-family: 'Barlow', Regular;
      }
    }
  }

  @media (max-width: 680px) {
    .boxExpansive {
      margin-top: 4px;
    }

    .containerAlternatives {
      margin-bottom: 4px;
    }
  }
`