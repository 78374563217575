import styled from 'styled-components'
import { theme } from '../../styles/theme'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding-top: 56px;

  @media (max-width: 680px) {
    height: 100vh;
    background-color: ${(props) => props.theme.colorsBase.white};
  }
`

export const ContainerContent = styled.div`
  padding: 0 40px;
  width: 100%;
  height: 100%;

  @media (max-width: 680px) {
    padding: 0 16px;
  }
`

export const ContainerTable = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  padding: 24px 0px;
  border-radius: 16px 16px 0px 0px;
  background-color: ${(props) => props.theme.colorsBase.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .containerButton {
    align-self: flex-end;
    margin-right: 24px;
  }
  
  @media (max-width: 680px) {
   padding: 0px;
   border-radius: 0px;
   background: transparent;
   box-shadow: none;
  }
`

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`

export const LineHeader = styled.div<{ register?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 50%;
  margin-bottom: 24px;

  @media (max-width: 680px) {
    width: 100%;
    margin-bottom: ${(props) => (props.register ? '8px' : ' 48px')};
  }
`

export const ButtonHeader = styled.button<{ active?: boolean }>`
  width: 40%;
  border: none;
  border-bottom: solid 2px ${(props) => (props.active ? props.theme.colors.seventh : 'transparent')};
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }
  
    h5 {
      line-height: 16px;
      letter-spacing: 1.07px;
      text-align: center;
      font-size: ${responsiveFont(12)};
      color: ${(props) => (props.active ? props.theme.colors.seventh : props.theme.colors.friday)};
      font-weight: 500;
      font-family: 'Barlow', Medium;
      padding: 8px 8px 16px 8px;
  
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(12)};
      }
    }

  @media (max-width: 680px) {
    width: 50%;
  }
`

export const BoxImageProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 16px;
  width: 100%;

  .InputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 16px;
    margin-left: 16px;

    h2 {
      margin-left: 12px;
      line-height: 24px;
      letter-spacing: 0.15px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.black};
      opacity: 0.6;
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(14)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    h1 {
      margin-left: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(10)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  }
`

export const ContainerChallenge = styled.div`
  display: flex;
  height: 100%;
  width: 45%;
  margin: 16px 8px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
`

export const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 4px;
  z-index: 1;
  object-fit: cover; 
`

export const BoxChallenge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 280px;
  height: 100px;
  padding: 8px;
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

  .boxImage {
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .boxContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 16px;

    .title {
      max-width: 180px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      line-height: 24px;
      letter-spacing: 0.1px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 500;
      font-family: 'Barlow', Medium;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }

    .boxUser {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .i {
        color: #201E1E;
        font-size: 12px;
    
        &:hover {
          color: ${theme.colors.black};
        }
      }

      .nameUser {
        max-width: 160px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        margin-left: 8px;
        line-height: 16px;
        letter-spacing: 0.4px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(10)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }
    
    .containerChip {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .containerTop {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }
  }
`

export const InputSelect = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
 

  > div {
    display: flex;
    align-self: flex-start;

    padding: 0px 0;

    input {
      width: 22px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .input {
    width: 100%;

    input {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }

    select {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }
  }
`

export const CoverStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  height: 100%;
  width: 354px;

  .title {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  @media (max-width: 680px) {
    width: 100%;
   }
`

export const BackgroundImageProfile = styled.label`
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  position: relative;
  margin-bottom: 16px;
  width: 100%;

   img {
    width: 100%;
    border-radius: 2px;
    border: solid 1px ${(props) => props.theme.colorsBase.gray6};
    z-index: 1;
    object-fit: cover; 
   }

  .iconBackground {
    position: absolute;
    bottom: -8px;
    right: -8px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.secundary};
    z-index: 1;

    .iconCam {
      color: ${(props) => props.theme.colorsBase.white};
      width: 24px;
      height: 24px;
      align-self: center;
    }
  }
`

export const Input = styled.input`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`

export const MediaStage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 4px;

  .textViewContent {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: center;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.secundary};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    background: transparent;
    border: none;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .textViewContent {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.secundary};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(12)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }

    &:hover {
      opacity: 0.8;
    }
  }
`

export const BoxMedia = styled.label`
  margin-top: 8px;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 4px;
  position: relative;
  margin-bottom: 8px;
  border: dashed 1px ${(props) => props.theme.colorsBase.gray};

  .iconBackground {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .iconCam {
      color: ${(props) => props.theme.colorsBase.black};
      width: 24px;
      height: 24px;
      align-self: center;
      margin-right: 4px;
    }

    .textAdd {
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  }
`

export const InputMedia = styled.input`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`

export const BoxResponse = styled.label`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;

  .textNumber {
    margin-right: 8px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(14)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }
`

export const ContainerResponse = styled.label`
  margin-bottom: 8px;
  margin-top: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .textTitle {
    margin-left: 16px;
    margin-bottom: 8px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(14)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }
`

export const switchDivContainer = styled.div`
    margin-top: 24px;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h3 {
      margin-left: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }

    .switchDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      h5 {
        line-height: 20px;
        letter-spacing: 0.15px;
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colors.black};
        opacity: 0.6;
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }
    }
`

export const TitleQuestionSent = styled.div`
  margin: 0 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  font-size: ${responsiveFont(14)};
  color: ${(props) => props.theme.colors.black};
  font-weight: 400;
  font-family: 'Barlow', Medium;
  margin-bottom: 40px;
`

export const ImageContent = styled.img`
  width: 100%;
  border-radius: 4px;
  object-fit: cover; 
`

export const Table = styled.div`
  position: relative;
  height: 350px;
  min-height: 350px;
  overflow: auto;
  margin: 16px 16px;

  z-index: 10;

  &::-webkit-scrollbar {
    border-radius: 8px;
    width: 4px;
    background: ${(props) => props.theme.colors.eleven};
    }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.seventh};
    border-radius: 8px;
  }
  ::-webkit-scrollbar:vertical { 
    height: 4px;
    border-radius: 8px;
  }

  h2 {
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    margin-bottom: 8px;
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Medium;
  }

  @media (min-width: 1600px) {
    h2 {
      line-height: 24px;
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
    padding-right: 4px;
    margin: 48px 8px 32px 16px;
  }
`

export const ContainerData = styled.div`
  display: flex;
  height: 100%
  align-items: flex-start;
  padding: 0px 24px 24px 24px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`

export const BoxMediaAudio = styled.label`
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 8px;
  position: relative;
  background-color: ${(props) => props.theme.colors.secundary};
  margin-right: 8px;
  padding: 4px;

  .iconBackground {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .iconMic {
      color: ${(props) => props.theme.colorsBase.white};
      width: 24px;
      height: 24px;
      align-self: center;
    }

    .textAdd {
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colorsBase.white};
      font-weight: 500;
      font-family: 'Barlow', Medium;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
  }
`

export const InputAudio = styled.input`
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`






export const ContainerQuestionCount = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 16px 16px 16px;
  justify-content: center;
  align-items: center;
`

export const ButtonQuestion = styled.div<{ active: boolean }>`
    display: flex;
    height: 24px;
    width: 24px;
    margin: 0px 2px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:  ${props => props.active ? theme.colors.primary : "#cccccc"};
    border-radius: 50%;

    &:hover {
      opacity: 0.8;
    }

    .i {
      color: ${props => props.active ? theme.colorsBase.white : theme.colors.primary};
      width: 16px;
      height: 16px;
      align-self: center;
    }

    .textbutton {
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${props => props.active ? theme.colorsBase.white : theme.colors.black};
      font-weight: 500;
      font-family: 'Barlow', Medium;
    
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
    
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
`

export const ButtonDelete = styled.div`
    display: flex;
    height: 40px;
    min-width: 40px;
    margin-left: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:  ${theme.colors.primary};
    border-radius: 50%;

    &:hover {
      opacity: 0.8;
    }

    .i {
      color: ${theme.colorsBase.white};
      width: 24px;
      height: 24px;
      align-self: center;
    }
`

export const ContainerColumnRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0px 16px 0px;
  justify-content: flex-start;
  align-items: flex-start;
width: 100%;


  @media (max-width: 680px) {
    flex-direction: column;
  }
`

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 80px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  .divBottom {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 680px) {
    flex-direction: column;
    padding: 16px 24px 24px 24px;
    background-color:  ${theme.colorsBase.white};
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 3;
    box-shadow: 5px 1px 10px rgba(0, 0, 0, 0.6);

    .divBottom {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
    }
  }
`

export const ContainerTotal = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 80px 24px 80px;
  justify-content: center;
  align-items: flex-start;
  justify-content: flex-end;
  align-items: center;
  align-self: center;

  @media (max-width: 680px) {
    flex-direction: column-reverse;
    margin: 0px 0px 200px 0px;
    width: 100%;
    padding-bottom: 200px;
  }
`

export const BoxTotal = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
    margin-right:  ${(props) => (props.active ? '24px' : '0px')};
    margin-left:  ${(props) => (props.active ? '0px' : '24px')};
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;

  @media (max-width: 680px) {
    margin-right:  0px;
    margin-left:  0px;
    width: 100%;
  }
`

export const ContainerButtonQuiz = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    h3 {
      margin-bottom: 4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

    .boxQuizButtons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    @media (max-width: 680px) {
      align-items: center;
      justify-content: center;
      margin-top: 16px;

      .boxQuizButtons {
        width: 100%;
        flex-direction: column-reverse;
      }
    }
`

export const ContainerHeader = styled.div`
    width: 100%; 
    display: flex;
    margin-left: 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    .buttonAudio {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-right: 24px;
    
      .titleButton {
        margin-bottom: 6px;
        line-height: 14px;
        letter-spacing: 0.15px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }

      .containerAudio {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
  
        .descriptionButton {
          line-height: 14px;
          letter-spacing: 0.15px;
          text-align: left;
          font-size: ${responsiveFont(10)};
          color: ${(props) => props.theme.colors.black};
          font-weight: 400;
          font-family: 'Barlow', Regular;
          
          @media (max-width: 680px) {
            font-size: ${responsiveFont(8)};
          }
  
          @media (min-width: 1600px) {
            font-size: ${responsiveFont(12)};
          }
        }
      }
    }

    @media (max-width: 680px) {
      flex-direction: column-reverse;
      margin-left: 0px;

      .buttonAudio {
        padding-right: 0px;
        margin-top: 16px;
        width: 100%;
        
        .titleButton {
          padding-left: 16px;
        }
        .containerAudio {
          flex-direction: column;
          align-items: center;
          width: 100%;

          .descriptionButton {
            text-align: center;
            width: 100%;
          }
        }
      }  
    }

`

export const ContainerBottom = styled.div`
    width: 100%; 
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;

    .divLine {
     display: flex;
     align-items: center;
     justify-content: flex-start;
     width: 100%;
     height: 0.5px;
     background-color: ${(props) => props.theme.colors.ten};
     margin: 40px 0 16px 0;
    }

    .buttonAudio {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-right: 24px;
    
      .titleButton {
        margin-bottom: 6px;
        line-height: 14px;
        letter-spacing: 0.15px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }

    }

    @media (max-width: 680px) {
      .divLine {
        display: none;
      }
    }
    
`


export const ContainerViewTotalQuiz = styled.div`
    width: 80%; 
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .titleContainerViewTotalQuiz {
      margin-bottom: 8px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }

    .containerQuiz {
      width: 100%; 
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      border: solid 1px ${(props) => props.theme.colors.black5};
      border-radius: 16px;
      background-color: ${(props) => props.theme.colors.seventeen};
      padding: 24px 16px 16px 16px;
    }
    
    .textNotFountQuiz {
      margin: auto;
      text-align: center;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.fourth};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }

    .listQuiz {
      width: 100%; 
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      border: solid 1px ${(props) => props.theme.colors.black5};
      border-radius: 16px;
      padding: 0 8px 0 8px;

      height: 40vh;
      overflow:auto; 
      overflow-x: hidden; 

      &::-webkit-scrollbar {
        width: 8px;
        background: transparent;
        }
      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 8px;
      }
      ::-webkit-scrollbar:horizontal { 
        height: 4px;
      }
    }


    @media (max-width: 680px) {
      width: 100%; 

      .titleContainerViewTotalQuiz {
        margin-bottom: 12px;
      }

      .containerQuiz {
        border: none;
        border-radius: 0px;
        background-color: transparent;
        padding: 0px;
      }

      .listQuiz {
        border: none;
        border-radius: 0px;
        padding: 0;
  
        height: 100%;
        overflow: hidden; 
      }
    }
`

export const LineTitleModal = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 0.5px;
  background-color: ${(props) => props.theme.colorsBase.gray11};
  margin-bottom: 24px;
  margin-top: 16px;   

  h5 {
    line-height: 32px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    background-color:  ${(props) => props.theme.colorsBase.white};
    padding-right: 8px;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }
`