import styled from 'styled-components'
import { responsiveFont } from '../../../../../../utils/UtilsGlobal'

export const ContainerTableReport = styled.div`
  width: 100%;
  min-height: 380px;
  overflow: auto;

  &::-webkit-scrollbar {
    border-radius: 8px;
    width: 4px;
    background: ${(props) => props.theme.colors.sixteen};
    }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.sixteen};
    border-radius: 8px;
  }
  ::-webkit-scrollbar:horizontal { 
    height: 4px;
  }

  .i {
    font-size: 22px;
  }

  table {
    width: 100%;
    border: none;
    border-collapse: collapse;

    tr:nth-child(2n) {
      background: ${(props) => props.theme.colors.seventeen};
    }
    tr {
      height: 40px;
      width: 150px;
    }
    td {
     padding-right: 16px;
     max-width: 300px;
     white-space: nowrap;
     text-overflow: ellipsis;
     overflow: hidden;
     font-size: ${responsiveFont(14)};
     color: ${(props) => props.theme.colorsBase.black3};
    }
    .header {
      height: 40px;
      margin-bottom: 40px;
      text-align: left;
      border-bottom: solid 2px ${(props) => props.theme.colors.secundary};
    }

    .body-item{
      height: 60px;
    }

    .itemBlocked {
      color: red;
    }

    .iconBlue {
      color: ${(props) => props.theme.colors.seventh};
      font-size: 24px;
    }
  }

  @media (max-width: 680px) {
    margin: 0px;
    padding: 0px;

    table {
      tr:nth-child(2n) {
        background: ${(props) => props.theme.colorsBase.white2};
      }

      td {
        max-width: 100px;
        font-size: ${responsiveFont(14)};
       }

       .header {
        border-bottom: none;
      }

    }
  }
`

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 80%;

  button {
    border: none;
    background: none;

    width: auto;
    height: auto;
  }

  .i {
    color: ${(props) => props.theme.colors.black4};
    font-size: 24px;

    &:hover {
     opacity: 0.8;
    }
  }
`


export const ContainerDetailsUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .boxInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0px 16px 16px 16px;

    h1 {
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.fourth};
      opacity: 0.6;
      font-weight: 400;
      font-family: 'Barlow', Regular;
    }

    h2 {
      margin-top: 4px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
    }
  }
`

export const ContainerTableReportMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 0px;
  border: none;
  border-radius: 0px;

  @media (max-width: 680px) {
   width: auto;
   margin: 8px 8px 16px 8px;
   border: solid 1px ${(props) => props.theme.colors.black5};
   border-radius: 16px;
  }
`

