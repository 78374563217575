import { ICategory } from "../../services/Categories/types"


export const emptyItem: ICategory = {
  Id: "",
  ValuePtBr: "",
  ValueEn: "",
  ValueEs: "",
  ValueFr: "",
  Enabled: true
}
export interface ITableUsers {
  listUsers: ICategory[]
  updateList: () => void
}

export interface ICategories {
  Id: string,
  Name: string,
  DataCadastro?: string,
  Ativo?: boolean
}

export interface ICategoryPost {
  ValuePtBr: string
  ValueEn: string
  ValueEs: string
  ValueFr: string
}

export class Registration {
  ValuePtBr = '';
  ValueEn = '';
  ValueEs = '';
  ValueFr = '';
}

export class RegistrationError {
  ValuePtBr = '';
  ValueEn = '';
  ValueEs = '';
  ValueFr = '';
}