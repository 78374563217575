import React, { useEffect, useState } from 'react';
import * as S from './styles'
import { MedicalServices, } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ToastNotification from '../../components/ToastNotification';
import ViewLoading from '../../components/ViewLoading';
import ModalCustom from '../../components/ModalCustom';
import { ICurators } from './types';
import { getAdvice, getCurators, getCuratorsInformation, getUser, postProfessionalUser } from '../../services/Users';
import HeaderAchievements from '../../components/HeaderAchievements';
import ViewError from '../../components/ViewError';
import CardCurators from '../../components/CardCurators';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem, Select, TextField } from '@mui/material'
import { IPais, IState } from '../../services/Locality/types';
import ModalCustomBottom from '../../components/ModalCustomBottom';
import { useIsSm } from '../../hooks/useIsSm';
import CuratorsDetails from '../../components/CuratorsDetails';
import SearchBar from '../../components/SearchBar';
import SelectMultiple from '../../components/SelectMultiple';
import Input from '../../components/Input';
import { ICategories } from '../../components/TableCategory/types';
import { RegistrationProfessional, RegistrationProfessionalError } from '../MyProfile/types';
import { getCategories } from '../../services/Categories';
import { registerProfessionalValidation } from '../MyProfile/validations';
import { getStateAddressUser } from '../../services/Locality';

const MyHealers: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState(false);
  const [category, setCategory] = useState('');
  const [curatorsList, setCuratorsList] = useState<ICurators[]>([])
  const [totalPages, setTotalPages] = useState<number | undefined>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [adviceList, setAdviceList] = useState<IPais[]>([])
  const [openModal, setOpenModal] = useState(false)
  const isSm = useIsSm()
  const [value, setValue] = useState('');
  const [curator, setCurator] = useState<ICurators>()

  const [openModalCuratorRequest, setOpenModalCuratorRequest] = useState(false)
  const [registrationCompleted, setRegistrationCompleted] = useState(false)
  const [openModalProfessionalInformation, setOpenModalProfessionalInformation] = useState(false)

  const [registrationProfessional, setRegistrationProfessional] = useState<RegistrationProfessional>(new RegistrationProfessional());
  const [registrationProfessionalError, setRegistrationProfessionalError] = useState<RegistrationProfessionalError>(new RegistrationProfessionalError());
  const [stateList, setStateList] = useState<IState[]>([])

  const [categoriesList, setCategoriesList] = useState<ICategories[]>([])
  const [categoriesListSelected, setCategoriesListSelected] = useState<ICategories[]>([])
  const [languageListSelected, setLanguageListSelected] = useState<ICategories[]>([])

  const [categoriesError, setCategoriesError] = useState(false)
  const [languageError, setLanguageError] = useState(false)

  const languageList = [
    {
      Name: 'Inglês',
      Id: '2'
    },
    {
      Name: 'Espanhol',
      Id: '3'
    },
    {
      Name: 'Francês',
      Id: '4'
    },
    {
      Name: 'Português',
      Id: '1'
    }
  ]

  const onClose = () => {
    setOpenModalCuratorRequest(false)
    setRegistrationCompleted(false)
    setOpenModalProfessionalInformation(false)
  };

  const retrieveUF = async () => {
    setLoading(true)
    try {
      setStateList([]);
      const response = await getStateAddressUser();
      if (response.Success) {
        setStateList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  const retrieveCategories = async () => {
    setLoading(true)
    try {
      setCategoriesList([]);
      const response = await getCategories();
      if (response.Success) {
        setCategoriesList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar as categorias, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar as categorias, tente novamente.')
      });
    }
    setLoading(false)
  };

  useEffect(() => {
    retrieveCurators(1, true);
    retrieveAdvice();
    retrieveUF();
    retrieveCategories();
  }, []);

  const retrieveUser = async () => {
    setLoading(true)
    try {
      const response = await getUser();
      if (response.Success) {
        if (!response.Data.ProfessionalData) {
          setOpenModalCuratorRequest(false)
          setOpenModalProfessionalInformation(true)
        } else {
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Você já se inscreveu!')
          });
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (err) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
      console.error("Error",err)
    }
    setLoading(false)
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > (300 * currentPage)) {
        if (currentPage <= (totalPages || 0)) {
          retrieveCurators(currentPage + 1, false)
        }
      }
    });
  }, []);

  const retrieveCuratorInformation = async (id: string) => {
    setLoading(true)
    try {
      const response = await getCuratorsInformation(id);
      if (response.Success) {
        setCurator(response.Data);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.'),
      });
    }
    setLoading(false)
  };

  const retrieveCurators = async (page: number = 1, clear: boolean = false) => {
    setLoading(true)
    try {
      if (clear) {
        setCuratorsList([]);
      }
      const bodyData = {
        PageNumber: page,
        PageSize: 64,
        Conselho: category,
        Nome: value
      };
      const response = await getCurators(bodyData);
      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }

        if (clear) {
          setCuratorsList(response.Data);
        } else if (response.Data.length > 1) {
          const novaArray = curatorsList.concat(response.Data);
          setCuratorsList(novaArray);
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.'),
      });
    }
    setLoading(false)
  };

  const retrieveAdvice = async () => {
    setLoading(true)
    try {
      setAdviceList([]);
      const response = await getAdvice();
      if (response.Success) {
        setAdviceList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  const selectCurator = async (item: ICurators) => {
    if (isSm) {
      await retrieveCuratorInformation(item.Id)
      setOpenModal(true)
    } else {
      navigate(`/informacoes_curador/${item.Id}`)
    }
  };


  const onEnterSearch = (event: { key: string }) => {
    if (event.key === 'Enter') {
      retrieveCurators(1, true);
    }
  };

  const handlePostProfessional = async () => {
    setLoading(true);
    try {
      let countError = 0;

      const validation = await registerProfessionalValidation(registrationProfessional);

      if (typeof validation !== 'boolean') {
        countError++;
      }

      if (countError > 0) {
        if (!categoriesListSelected[0]?.Name) {
          setCategoriesError(true)
        }

        if (!languageListSelected[0]?.Name) {
          setLanguageError(true)
        }

        setRegistrationProfessionalError(validation as RegistrationProfessionalError);
        setLoading(false);
        return;
      }

      if (!categoriesListSelected[0]?.Name) {
        if (!languageListSelected[0]?.Name) {
          setLanguageError(true)
        }

        setCategoriesError(true)
        setLoading(false);
        return;
      }

      if (!languageListSelected[0]?.Name) {
        setLanguageError(true)
        setLoading(false);
        return;
      }

      const singleText: string[] = []

      categoriesListSelected.map((item) => {
        singleText.push(item.Id)
      });

      const singleTextLanguage: string[] = []

      languageListSelected.map((item) => {
        singleTextLanguage.push(item.Id)
      });

      const bodyData = {
        AboutProfessional: registrationProfessional.SobreAutor,
        RegulatoryBoardId: registrationProfessional.ConselhoProfissional,
        RegulatoryBoard: adviceList.filter((itemX) => String(itemX.Id) === String(registrationProfessional.ConselhoProfissional))[0]?.Name ?? '',
        RegulatoryBoardNumber: registrationProfessional.NumeroConselho,
        RegulatoryBoardState: registrationProfessional.UfConselhoId,
        SiteUrl: registrationProfessional.LinkReferencia,
        FacebookUrl: registrationProfessional.LinkFacebook,
        XUrl: registrationProfessional.LinkTwitter,
        InstagramUrl: registrationProfessional.LinkInstagram,
        RQENumber: registrationProfessional.RQE,
        Categories: singleText,
        Languages: singleTextLanguage
      };

      const response = await postProfessionalUser(bodyData);
      if (response.Success) {
        setRegistrationCompleted(true)
        setOpenModalCuratorRequest(true)
        setOpenModalProfessionalInformation(false)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao registrar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao registrar os dados, tente novamente.')
      });
    }
    setLoading(false);
  };

  const contentCuratorRequest = () => {
    return (
      <>
        {registrationCompleted ?
          <S.BoxText>
            <b>{t('Inscrição Concluída com Sucesso!')}</b>
            <p className='text'>{t('Sua inscrição foi recebida e está em processo de análise. Em breve, nossa equipe entrará em contato com você através do e-mail cadastrado para as próximas etapas.')}</p>
            <p className='text'>{t('Agradecemos seu interesse e comprometimento com a saúde e o bem-estar. Estamos ansiosos para trabalhar juntos!')}</p>
            <b>{t('Fique atento à sua caixa de entrada!')}</b>
          </S.BoxText>
          :
          <S.BoxText>
            <b>{t('Junte-se à nossa equipe de Curadores da Saúde!')}</b>
            <p className='text'>{t('Se você é assim como nós é apaixonado pela saúde e já atua na área, estamos procurando por pessoas como você! Ao se tornar parte do nosso time de curadores, você terá a oportunidade única de revisar e enriquecer os desafios de saúde que inspiram e educam nossa comunidade.')}</p>

            <b style={{ marginTop: '16px' }}>{t('Não perca esta chance de fazer a diferença!')}</b>
            <p className='text'>{t('clique em "Completar Cadastro" para se candidatar à equipe de curadores.')}</p>
          </S.BoxText>
        }
      </>
    )
  };

  const contentProfessionalInformation = () => {
    return (
      <>
        <S.TextProfile>{t('Preencha os seguintes campos com suas informações profissionais:')}</S.TextProfile>

        <S.ContainerProfile >
          <div className='box'>
            <FormControl style={{ display: 'flex', width: '100%', marginTop: '16px' }}>
              <InputLabel id="demo-simple-select-helper-label">{t('Conselho')}</InputLabel>
              <Select
                MenuProps={{
                  MenuListProps: {
                    sx: { maxHeight: '180px', overflowY: 'auto' }
                  }
                }}
                label={t('Conselho')}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="standard"
                style={{ display: 'flex', width: '100%' }}
                value={registrationProfessional.ConselhoProfissional}
                error={registrationProfessionalError.ConselhoProfissional.length > 0}
                onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, ConselhoProfissional: '' })}
                onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, ConselhoProfissional: e.target.value })}
              >
                {adviceList.map((item) => (
                  <MenuItem key={item.Id} value={item.Id}>{t(item.Name)}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={{ display: 'flex', width: '100%', marginTop: '16px' }}>
              <InputLabel id="demo-simple-select-helper-label">{t('Estado do Conselho')}</InputLabel>
              <Select
                MenuProps={{
                  MenuListProps: {
                    sx: { maxHeight: '180px', overflowY: 'auto' }
                  }
                }}
                label={t('Estado do Conselho')}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="standard"
                style={{ display: 'flex', width: '100%' }}
                value={registrationProfessional.UfConselhoId}
                error={registrationProfessionalError.UfConselhoId.length > 0}
                onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, UfConselhoId: '' })}
                onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, UfConselhoId: e.target.value })}
              >
                {stateList.map((item) => (
                  <MenuItem key={item.Uf} value={item.Uf}>{t(item.Name)}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <SelectMultiple
              error={categoriesError}
              resetError={() => setCategoriesError(false)}
              listSelected={categoriesListSelected}
              list={categoriesList}
              label={t('Categorias que atende')}
              onClickAdd={(e) => {
                const indexCategorySelected = categoriesListSelected.find((state) => state.Id === e);
                if (!indexCategorySelected) {
                  const tempCategories = categoriesList.filter((itemX) => itemX?.Id === e)[0];
                  setCategoriesListSelected([...categoriesListSelected, tempCategories])
                }
              }}
              onClickRemove={(e) => {
                const tempCategories = categoriesListSelected.filter((itemX) => itemX.Id !== e);
                setCategoriesListSelected(tempCategories)
              }}
            />

            <SelectMultiple
              error={languageError}
              resetError={() => setLanguageError(false)}
              listSelected={languageListSelected}
              list={languageList}
              label={t('Idioma(s) que atende')}
              onClickAdd={(e) => {
                const indexCategorySelected = languageListSelected.find((state) => state.Id === e);
                if (!indexCategorySelected) {
                  const tempCategories = languageList.filter((itemX) => itemX?.Id === e)[0];
                  setLanguageListSelected([...languageListSelected, tempCategories])
                }
              }}
              onClickRemove={(e) => {
                const tempCategories = languageListSelected.filter((itemX) => itemX.Id !== e);
                setLanguageListSelected(tempCategories)
              }}
            />

            <Input
              label={t('N° Conselho Profissional')}
              type="number"
              value={registrationProfessional.NumeroConselho}
              onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, NumeroConselho: e.target.value })}
              size="small"
              fullWidth
              error={registrationProfessionalError.NumeroConselho.length > 0}
              helperText={registrationProfessionalError.NumeroConselho}
              onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, NumeroConselho: '' })}
              variant="filled"
              style={{ marginTop: '16px' }}
            />

            <Input
              label={t('N° R.Q.E')}
              type="number"
              value={registrationProfessional.RQE}
              onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, RQE: e.target.value })}
              size="small"
              fullWidth
              error={registrationProfessionalError.RQE.length > 0}
              helperText={registrationProfessionalError.RQE}
              onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, RQE: '' })}
              variant="filled"
              style={{ marginTop: '16px' }}
            />
          </div>

          <div className='box' style={{ marginLeft: isSm ? '0px' : '24px' }}>
            <Input
              label={t('Link do Site Pessoal')}
              type="name"
              value={registrationProfessional.LinkReferencia}
              onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, LinkReferencia: e.target.value })}
              size="small"
              fullWidth
              error={registrationProfessionalError.LinkReferencia.length > 0}
              helperText={registrationProfessionalError.LinkReferencia}
              onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, LinkReferencia: '' })}
              variant="filled"
              style={{ marginTop: '16px' }}
            />

            <Input
              label={t('Link do Facebook')}
              type="name"
              value={registrationProfessional.LinkFacebook}
              onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, LinkFacebook: e.target.value })}
              size="small"
              fullWidth
              error={registrationProfessionalError.LinkFacebook.length > 0}
              helperText={registrationProfessionalError.LinkFacebook}
              onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, LinkFacebook: '' })}
              variant="filled"
              style={{ marginTop: '16px' }}
            />

            <Input
              label={t('Link Instagram')}
              type="name"
              value={registrationProfessional.LinkInstagram}
              onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, LinkInstagram: e.target.value })}
              size="small"
              fullWidth
              error={registrationProfessionalError.LinkInstagram.length > 0}
              helperText={registrationProfessionalError.LinkInstagram}
              onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, LinkInstagram: '' })}
              variant="filled"
              style={{ marginTop: '16px' }}
            />

            <Input
              label={t('Link Twitter')}
              type="name"
              value={registrationProfessional.LinkTwitter}
              onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, LinkTwitter: e.target.value })}
              size="small"
              fullWidth
              error={registrationProfessionalError.LinkTwitter.length > 0}
              helperText={registrationProfessionalError.LinkTwitter}
              onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, LinkTwitter: '' })}
              variant="filled"
              style={{ marginTop: '16px' }}
            />

            <TextField
              id="outlined-multiline-static"
              label={t('Sobre o Autor')}
              multiline
              rows={4}
              defaultValue="Default Value"
              variant="outlined"
              value={registrationProfessional.SobreAutor}
              error={registrationProfessionalError.SobreAutor.length > 0}
              helperText={registrationProfessionalError.SobreAutor}
              onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, SobreAutor: '' })}
              style={{ marginTop: '24px', width: '100%' }}
              onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, SobreAutor: e.target.value?.length <= 400 ? e.target.value : registrationProfessional.SobreAutor })}
            />
            <S.TextCount>
              <h5>{registrationProfessional.SobreAutor.length}/400</h5>
            </S.TextCount>
          </div>
        </S.ContainerProfile>
      </>
    )
  };

  return (
    <S.Container>
      <HeaderAchievements
        title={t('Nossos Curadores')}
        onClick={() => navigate(-1)}
        buttonBack={false}
        onClickButtonRight={() => setFilter(true)}
      />

      <S.ContainerContent>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <ViewLoading />
          </div>
        ) : (
          <>
            <div className='buttonFilter'>
              <SearchBar
                value={value}
                setValue={(e) => setValue(e)}
                onEnterSearch={onEnterSearch}
                setFilterModal={() => setFilter(true)}
                placeholder={t('Busque o curador desejado')}
                filterButton
                style={{ padding: 0, width: isSm ? '100%' : '80%', marginRight: 'auto' }}
              />

              {/* <ButtonMaterial
                style={{ border: `solid 1px ${theme.colors.seventh}`, color: theme.colors.seventh, marginTop: isSm ? '16px' : '0px', marginLeft: isSm ? '24px' : '0px', marginRight: isSm ? '24px' : '0px', }}
                variant="outlined"
                label={t('Quero ser curador')}
                icon={<MedicalServices style={{ fontSize: '20px', marginRight: '8px', color: theme.colors.seventh }} />}
                onClick={() => setOpenModalCuratorRequest(true)}
              /> */}
            </div>

            {curatorsList.length > 0 ? (
              <S.ContainerChallenge>
                {curatorsList.map((item) => (
                  <CardCurators key={item.Id} item={item} onClick={() => selectCurator(item)} />
                ))}
              </S.ContainerChallenge>

            ) : <ViewError message={t('Nenhum curador encontrado!')} />}
          </>
        )}
      </S.ContainerContent>


      <ModalCustom
        open={filter}
        onClose={() => setFilter(false)}
        onClick={() => {
          retrieveCurators(1, true)
          setFilter(false)
        }}
        onCloseBottom={() => {
          setCategory('')
          retrieveCurators(1, true)
          setFilter(false)
        }}
        closeBottom={true}
        childrenContent={
          <S.Item>
            <S.Inputs>
              <div className="input">
                <FormControl sx={{ display: 'flex', m: 1, width: '100%' }}>
                  <InputLabel id="demo-simple-select-helper-label">{t('Conselho')}</InputLabel>
                  <Select
                    MenuProps={{
                      MenuListProps: {
                        sx: { maxHeight: '200px', maxWidth: '100%', overflowY: 'auto' }
                      }
                    }}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    variant="standard"
                    style={{ display: 'flex', width: '100%' }}
                    value={category}
                    onChange={(e) => {
                      setCategory(String(e.target.value))
                    }}
                  >
                    {adviceList.map((item) => (
                      <MenuItem key={item.Id} value={item.Id}><p>{item.Name}</p></MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </S.Inputs>
          </S.Item>
        }
        loading={loading}
        title={t('Filtrar Curadores')}
        confirmationButtonText={t('Pesquisar')}
        textDeclineButton={t('LIMPAR')}
      />

      <ModalCustomBottom
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={t('Informações do Curador')}
        showIcon={false}
        childrenContent={
          <CuratorsDetails curator={curator} />
        }
      />


      {!isSm ? (
        <ModalCustom
          open={openModalCuratorRequest}
          onClose={onClose}
          onClick={() => retrieveUser()}
          title={t('Quero Ser Curador')}
          confirmationButtonText={t('completar cadastro')}
          textDeclineButton={registrationCompleted ? t('VOLTAR') : t('CANCELAR')}
          buttonRight={!registrationCompleted}
          styleButtons={{ justifyContent: 'center' }}
          buttonsEnabled
          childrenContent={
            <>
              {contentCuratorRequest()}
            </>
          }
        />
      ) : (
        <ModalCustomBottom
          open={openModalCuratorRequest}
          onClose={onClose}
          onClick={() => retrieveUser()}
          title={t('Quero Ser Curador')}
          confirmationButtonText={t('completar cadastro')}
          textDeclineButton={registrationCompleted ? t('VOLTAR') : t('CANCELAR')}
          buttonRight={!registrationCompleted}
          styleButtons={{ justifyContent: 'center' }}
          icon={<MedicalServices className='icon' />}
          buttonsEnabled
          childrenContent={
            <>
              {contentCuratorRequest()}
            </>
          }
        />
      )}

      {!isSm ? (
        <ModalCustom
          open={openModalProfessionalInformation}
          onClose={onClose}
          onClick={handlePostProfessional}
          title={t('Informações Profissionais')}
          confirmationButtonText={t('SALVAR')}
          textDeclineButton={t('CANCELAR')}
          buttonsEnabled
          childrenContent={
            <>
              {contentProfessionalInformation()}
            </>
          }
        />
      ) : (
        <ModalCustomBottom
          open={openModalProfessionalInformation}
          onClose={onClose}
          onClick={handlePostProfessional}
          title={t('Informações Profissionais')}
          confirmationButtonText={t('SALVAR')}
          textDeclineButton={t('CANCELAR')}
          styleButtons={{ justifyContent: 'center' }}
          icon={<MedicalServices className='icon' />}
          buttonsEnabled
          childrenContent={
            <>
              {contentProfessionalInformation()}
            </>
          }
        />
      )}
    </S.Container >
  )
}

export default MyHealers
