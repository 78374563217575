import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../../hooks/useIsSm';
import ModalCustom from '../../../components/ModalCustom';
import ButtonMaterial from '../../../components/Button';
import { theme } from '../../../styles/theme';
import { ButtonQuestion } from '../../../components/ModalRegistrationAndChallengeEditing/styles';
import { RegistrationSpecialProgram, RegistrationSpecialProgramError } from './types';
import { Checkbox, MenuItem, Modal, Select, Tooltip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { emailValidation, registerValidation1 } from './validations';
import { Star, UploadFile } from '@mui/icons-material';
import Input from '../../../components/Input';
import Logger from '../../../utils/Logger';
import ImageAdjustment from '../../../components/ImageAdjustment';
import HeaderMobile from '../../../components/HeaderMobile';
import HeaderAchievements from '../../../components/HeaderAchievements';
import ModalCustomBottom from '../../../components/ModalCustomBottom';
import SendingInvitations from './SendingInvitations';
import { useNavigate } from 'react-router-dom';
import ToastNotification from '../../../components/ToastNotification';
import { postSpecialProgram } from '../../../services/SpecialProgram';

interface IProps {
  open: boolean
  setOpen: (e: boolean) => void
}

const ModalCreateSpecialProgram: React.FC<IProps> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const isSm = useIsSm();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [registration, setRegistration] = useState<RegistrationSpecialProgram>(new RegistrationSpecialProgram());
  const [registrationError, setRegistrationError] = useState<RegistrationSpecialProgramError>(new RegistrationSpecialProgramError());
  const [imageSet, setImageSet] = useState<File | null>();
  const [image, setImage] = useState('');
  const [imageError, setImageError] = useState('');
  const [email, setEmail] = useState<string[]>([]);
  const [openModalImageAdjustment, setOpenModalImageAdjustment] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idRegistred, setIdRegistred] = useState('');
  const [attemps, setAttemps] = useState(false);

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const dispararCliqueInput = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleChangeModal = (e: File) => {
    setImageError('');
    setOpenModalImageAdjustment(false);
    if (e) {
      setImageSet(e);
      setImage(URL.createObjectURL(e));
    }
  };

  const handleChangeModalClose = () => {
    setImageError('');
    setOpenModalImageAdjustment(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setImageError('');
    setOpenModalImageAdjustment(true);
    const selectedImage = e.target.files?.[0];
    if (selectedImage) {
      setImageSet(selectedImage);
      setImage(URL.createObjectURL(selectedImage));
    }
  };

  useEffect(() => {
    setPage(1);
  }, []);

  type ParseStringFunction = (input: string) => string[];

  const parseString: ParseStringFunction = (input) => {
    const array = input.split(',').map(item => decodeURIComponent(item.trim()));
    return array;
  };

  const handleRegister = async () => {
    setLoading(true);
    try {
      let listTags: string[] = [];
      if (registration?.tags) {
        listTags = parseString(registration.tags);
      }

      const body = {
        Title: registration.title,
        Description: registration.description,
        AverageRate: registration.averagePerformance,
        Language: registration.language,
        Attempts: attemps ? registration.attempts : '0',
        Tags: listTags,
        Emails: email
      };

      const data = new FormData();
      if (imageSet) data.append('CoverImageUrl', imageSet as File);

      const response = await postSpecialProgram(body, data);
      if (response.Success) {
        setOpenModal(true);
        setIdRegistred(response.Data.Id)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao cadastrar, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }


    } catch (ex) {
      Logger('ex', 'Houve um erro, tente novamente.');
    }
    setLoading(false);
  };

  const handleNext = async () => {
    try {
      if (page === 1) {
        let countError = 0;
        const validation = await registerValidation1(registration);
        if (typeof validation !== 'boolean') {
          countError++;
        }
        if (countError > 0) {
          setRegistrationError(validation as RegistrationSpecialProgramError);
          return;
        }

        if ((attemps && registration.attempts.length <= 0) || registration.attempts === '0') {
          if (registration.attempts === '0') {
            setRegistrationError({ ...registrationError, attempts: 'O valor deve ser maior que 0' });
          } else {
            setRegistrationError({ ...registrationError, attempts: 'Insira a quantidade' });
          }
          return;
        }

        setPage(2);
      } else if (page === 2) {
        setPage(3);
      }
    } catch (ex) {
      Logger('ex', 'Houve um erro, tente novamente.');
    }
  };

  const handleAddEmail = async () => {
    try {
      let countError = 0;
      const validation = await emailValidation(registration);
      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError({ ...registrationError, email: "Por favor, insira um e-mail no formato correto 'usuario@dominio.com.'" });
        return;
      }
      setRegistration({ ...registration, email: '' });
      setEmail([...email, registration.email]);
    } catch (ex) {
      Logger('ex', 'Houve um erro, tente novamente.');
    }
  };

  const handleAddListEmail = (listEmail: string[]) => {
    setEmail([...email, ...listEmail]);
  };

  const handleDeleteEmail = (index: number) => {
    const updatedEmails = [...email];
    updatedEmails.splice(index, 1);
    setEmail(updatedEmails);
  };

  const handleCancel = () => {
    setRegistration(new RegistrationSpecialProgram());
    setRegistrationError(new RegistrationSpecialProgramError());
    setEmail([]);
    setPage(1);
    setImage('');
    setImageError('');
    setImageSet(undefined);
    setOpenModal(false);
    setOpen(false);
    setAttemps(false)
  };

  const returnContent = () => {
    return (
      <S.ContainerRegister>
        <div className='containerInputsRegister'>
          {page === 1 && (
            <>
              <Input
                label={t('Título')}
                placeholder='Dê um nome ao programa especial'
                type='titulo'
                value={registration.title}
                onChange={(e) => setRegistration({ ...registration, title: e.target.value })}
                size='small'
                fullWidth
                error={registrationError.title.length > 0}
                helperText={registrationError.title}
                onClick={() => setRegistrationError({ ...registrationError, title: '' })}
                variant='filled'
                style={{ width: '100%' }}
              />

              <Input
                label={t('Descrição')}
                placeholder='Descreva brevemente o objetivo do programa.'
                type='description'
                value={registration.description}
                onChange={(e) => setRegistration({ ...registration, description: e.target.value })}
                size='small'
                fullWidth
                error={registrationError.description.length > 0}
                helperText={registrationError.description}
                onClick={() => setRegistrationError({ ...registrationError, description: '' })}
                variant='filled'
                style={{ width: '100%', marginTop: '16px' }}
              />

              <div style={{ display: 'flex', width: '100%', marginTop: '16px', flexDirection: isSm ? 'column' : 'row' }}>
                <Input
                  label={t('Nota de Aprovação')}
                  placeholder='Defina a nota para aprovação. (de 0 a 100%)'
                  type='number'
                  value={registration.averagePerformance}
                  onChange={(e) => setRegistration({ ...registration, averagePerformance: e.target.value })}
                  size='small'
                  fullWidth
                  error={registrationError.averagePerformance.length > 0}
                  helperText={registrationError.averagePerformance}
                  onClick={() => setRegistrationError({ ...registrationError, averagePerformance: '' })}
                  variant='filled'
                  style={{ width: '100%', marginTop: isSm ? '0px' : '8px' }}
                />

                <FormControl sx={{ m: 1, width: '100%', marginTop: isSm ? '16px' : '8px', marginLeft: isSm ? '0px' : '16px' }}>
                  <InputLabel id='demo-simple-select-helper-label'>{t('Idioma')}</InputLabel>
                  <Select
                    labelId='demo-simple-select-helper-label'
                    id='demo-simple-select-helper'
                    variant='standard'
                    style={{ display: 'flex', width: '100%' }}
                    value={registration.language}
                    error={registrationError.language.length > 0}
                    onClick={() => setRegistrationError({ ...registrationError, language: '' })}
                    onChange={(e) => {
                      setRegistration({ ...registration, language: String(e.target.value) });
                    }}
                  >
                    <MenuItem value={'1'}>{t('Português')}</MenuItem>
                    <MenuItem value={'2'}>{t('Inglês')}</MenuItem>
                    <MenuItem value={'3'}>{t('Espanhol')}</MenuItem>
                    <MenuItem value={'4'}>{t('Francês')}</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <Input
                label={t('Tags')}
                type='tags'
                placeholder={isSm ? 'Insira as tags (separe-as por vírgula)' : 'Adicione tags para facilitar a busca do programa. (separe-as por vírgula)'}
                value={registration.tags}
                onChange={(e) => setRegistration({ ...registration, tags: e.target.value })}
                size='small'
                fullWidth
                error={registrationError.tags.length > 0}
                helperText={registrationError.tags}
                onClick={() => setRegistrationError({ ...registrationError, tags: '' })}
                variant='filled'
                style={{ width: '100%', marginTop: '16px' }}
              />

              <div className='boxSelect'>
                <h1>{t('O participante pode refazer o Programa?')}</h1>

                <div className='switchDiv'>
                  <Checkbox
                    checked={attemps}
                    onChange={(event) => setAttemps(event.target.checked)}
                    color='info'
                  />
                  <h5>{t('Sim')}</h5>

                  <Checkbox
                    checked={!attemps}
                    onChange={(event) => setAttemps(!event.target.checked)}
                    color='info'
                  />
                  <h5>{t('Não')}</h5>
                </div>
              </div>

              {attemps && <Input
                label={t('Quantidade de Tentativas')}
                type='number'
                placeholder='Defina a quantidade de vezes o participante poderá participar'
                value={registration.attempts}
                onChange={(e) => setRegistration({ ...registration, attempts: e.target.value })}
                size='small'
                fullWidth
                error={registrationError.attempts.length > 0}
                helperText={registrationError.attempts}
                onClick={() => setRegistrationError({ ...registrationError, attempts: '' })}
                variant='filled'
                style={{ width: '100%' }}
              />}
            </>
          )}
          {page === 2 && (
            <S.CoverStepProgram>
              <h3 className='title'>{t('Capa do Programa Especial')}</h3>
              <h5 className='subTitle'>{t('Envie uma imagem de capa do desafio')}</h5>

              <Tooltip title={t('Escolha uma imagem bacana que mostre do que se trata seu desafio ou que chame atenção. Lembre-se: essa parte é muito importante e não pode faltar')}>
                <S.BackgroundImageProgram htmlFor='selecao-arquivo'>
                  {imageSet?.name ? (
                    <img style={{ aspectRatio: '13/8' }} src={image} alt='' />
                  ) : (
                    <div className='iconBackground' style={{ aspectRatio: '13/8' }}>
                      <UploadFile className='iconCam' />
                      <h5 className='textAdd'>{isSm ? t('Clique para fazer upload de um arquivo de imagem') : t('Clique aqui para selecionar uma imagem de capa para o programa especial.')}</h5>
                    </div>
                  )}
                  <S.InputFile id='selecao-arquivo' onChange={handleChange} type='file' accept='image/*' ref={inputFileRef} />
                </S.BackgroundImageProgram>
              </Tooltip>
              <div className='textError'>{imageError}</div>

              {imageSet?.name && (
                <S.ButtonImageEditProgram htmlFor='selecao-arquivo'>
                  <h6 className='textButtonEdit'>{t('ALTERAR IMAGEM')}</h6>
                  <S.InputFile id='selecao-arquivo' onChange={handleChange} type='file' accept='image/*' />
                </S.ButtonImageEditProgram>
              )}
            </S.CoverStepProgram>
          )}
          {page === 3 && (
            <SendingInvitations
              email={email}
              handleDeleteEmail={handleDeleteEmail}
              handleAddEmail={handleAddEmail}
              registration={registration}
              setRegistration={setRegistration}
              registrationError={registrationError}
              setRegistrationError={setRegistrationError}
              setListEmails={(e) => handleAddListEmail(e)}
            />
          )}
        </div>

        {returnButtons()}
      </S.ContainerRegister>
    );
  };

  const returnButtons = () => {
    return (
      <>
        <div className='containerPaggingRegister'>
          <ButtonQuestion active={page === 1}>
            <h5 className='textbutton'>1</h5>
          </ButtonQuestion>

          <ButtonQuestion active={page === 2}>
            <h5 className='textbutton'>2</h5>
          </ButtonQuestion>

          <ButtonQuestion active={page === 3}>
            <h5 className='textbutton'>3</h5>
          </ButtonQuestion>
        </div>

        <div className='containerButtonsRegister'>
          {page > 1 ? (
            <ButtonMaterial
              style={{ borderColor: theme.colors.secundary, color: theme.colors.secundary, width: isSm ? '33.33%' : 'auto' }}
              variant='outlined'
              label={t('ANTERIOR')}
              onClick={() => setPage(page - 1)}
            />
          ) : (
            <div style={{ width: isSm ? '33.33%' : 'auto' }} />
          )}

          <div className='containerButtonsRegisterRight'>
            <ButtonMaterial
              style={{ borderColor: theme.colors.secundary, color: theme.colors.secundary, width: isSm ? '100%' : 'auto' }}
              variant='outlined'
              label={t('CANCELAR')}
              onClick={handleCancel}
            />
            {page === 3 ? (
              <ButtonMaterial
                style={{ backgroundColor: theme.colorsBase.green2, marginLeft: '24px', width: isSm ? '100%' : 'auto' }}
                variant='contained'
                label={t('SALVAR')}
                onClick={handleRegister}
                loading={loading}
              />
            ) : (
              <ButtonMaterial
                style={{ backgroundColor: theme.colors.seventh, marginLeft: '24px', width: isSm ? '100%' : 'auto' }}
                variant='contained'
                label={t('PRÓXIMO')}
                onClick={handleNext}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isSm ? (
        <Modal open={open}>
          <>
            <HeaderMobile />
            <S.ContainerModal>
              <HeaderAchievements
                title={t('Cadastro Programa Especial')}
                headerMobileAll
                icon={<Star className='i' style={{ color: theme.colors.secundary }} />}
                buttonBack={false}
                style={{ padding: '0px 0px 32px 0px' }}
              />
              {returnContent()}
            </S.ContainerModal>
          </>
        </Modal>
      ) : (
        <ModalCustom
          open={open}
          onClose={handleCancel}
          onClick={() => setOpen(true)}
          title={t('Novo Programa Especial')}
          buttonsEnabled={false}
          confirmationButtonText={t('SALVAR')}
          textDeclineButton={t('CANCELAR')}
          childrenContent={returnContent()}
          style={{ height: '95%' }}
        />
      )}

      <ModalCustom
        open={openModalImageAdjustment}
        onClose={handleChangeModalClose}
        onClick={() => { }}
        childrenContent={<>{imageSet && <ImageAdjustment onClickAdd={dispararCliqueInput} onClickSave={handleChangeModal} imageSet={imageSet} />}</>}
        title=''
        buttonsEnabled={false}
        styleLine={{ backgroundColor: 'transparent', marginBottom: '24px' }}
        style={{ backgroundColor: theme.colorsBase.white2 }}
      />

      {!isSm ? (
        <ModalCustom
          open={openModal}
          onClose={() => {
            handleCancel()
            navigate(`/detalhes_do_programa_especial/${idRegistred}`)
          }}
          onClick={() => { }}
          title={t('Novo Programa Especial')}
          confirmationButtonText={t('CONTINUAR')}
          textDeclineButton={t('CONFIRMAR')}
          description={t('Programa Especial criado com sucesso!')}
          styleButtons={{ justifyContent: 'center' }}
          buttonRight={false}
        />
      ) : (
        <ModalCustomBottom
          open={openModal}
          onClose={() => {
            handleCancel()
            navigate(`/detalhes_do_programa_especial/${idRegistred}`)
          }}
          onClick={() => {
            handleCancel()
            navigate(`/detalhes_do_programa_especial/${idRegistred}`)
          }}
          title={t('Novo Programa Especial')}
          showIcon={false}
          description={t('Programa Especial criado com sucesso!')}
          buttonsEnabled={true}
          confirmationButtonText={t('CONTINUAR')}
          textDeclineButton={t('VOLTAR')}
          styleButtons={{ justifyContent: 'center' }}
          buttonClose
        />
      )}
    </>
  );
};

export default ModalCreateSpecialProgram;
