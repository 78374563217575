import { useState } from 'react';
import logo from '../../assets/images/logoBranca.png'
import { useNavigate } from 'react-router-dom';
import * as S from './styles'
import { HeaderProps } from './types';
import { ChevronLeft, Menu, Search, FilterList } from '@mui/icons-material';
import InputSearch from '../../components/InputSearch';
import { useTranslation } from 'react-i18next';
import ModalNotifications from '../ModalNotifications';
import { useAuth } from '../../contexts/Auth';
import ModalMenu from '../ModalMenu';
import { useIsSm } from '../../hooks/useIsSm';

const HeaderMobile: React.FC<HeaderProps> = (props: HeaderProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [openSearchField, setOpenSearchField] = useState(false);
  const { setSearch, updateFilter } = props
  const { menu, setMenu } = useAuth();
  const [searchA, setSearchA] = useState("");
  const isSm = useIsSm()

  return (
    <>
      <S.Header>
        <S.ButtonModal mobile={isSm} active={typeof setSearch !== 'undefined'} onClick={() => {
          setMenu(!menu);
          if (openSearchField) setOpenSearchField(false);
        }}>
          {menu ? <ChevronLeft className='i' /> : <Menu className='i' />}
        </S.ButtonModal>

        <button onClick={() => {
          navigate(`/home`)
          if (menu) setMenu(false);
          if (openSearchField) setOpenSearchField(false);
        }}>
          <img src={logo} alt='' />
        </button>

        <div className='containerButtons'>
          {setSearch && (
            <S.ContainerSearch active={openSearchField} onClick={() => {
              setOpenSearchField(!openSearchField);
              if (menu) setMenu(false);
            }}>
              <Search className='i' />
            </S.ContainerSearch>
          )}

          <ModalNotifications colorWhite />

          <ModalMenu />
        </div>
      </S.Header>

      {openSearchField && setSearch && (
        <S.HeaderSearch>
          <InputSearch
            type='search'
            placeholder={t('O que deseja procurar?') ?? "O que deseja procurar?"}
            value={searchA}
            onChange={(e) => {
              setSearchA(e.target.value)
              setSearch(e.target.value)
            }}
            variant="outlined"
            style={{ width: '100%', backgroundColor: 'white', borderRadius: '40px' }}
          />

          {updateFilter && (
            <S.ButtonStyled onClick={updateFilter}>
              <FilterList className='icon' />
            </S.ButtonStyled>
          )}
        </S.HeaderSearch>
      )}
    </>
  )
}

export default HeaderMobile
