import React, { useEffect, useState } from 'react';
import * as S from './styles'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderAchievements from '../../components/HeaderAchievements';
import { getCuratorsInformation } from '../../services/Users';
import ToastNotification from '../../components/ToastNotification';
import ViewLoading from '../../components/ViewLoading';
import { ICurators } from '../MyHealers/types';
import CuratorsDetails from '../../components/CuratorsDetails';

const CuratorInformation: React.FC = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [curator, setCurator] = useState<ICurators>()

  useEffect(() => {
    retrieveCurators();
  }, []);

  const retrieveCurators = async () => {
    setLoading(true)
    try {
      const response = await getCuratorsInformation(token ?? '');
      if (response.Success) {
        setCurator(response.Data);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.'),
      });
    }
    setLoading(false)
  };

  return (
    <S.Container>
      <HeaderAchievements
        title={t('Informações do Curador')}
        onClick={() => navigate(-1)}
      />

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <ViewLoading />
        </div>
      ) : (
        <CuratorsDetails curator={curator} />
      )}
    </S.Container>
  )
}

export default CuratorInformation
