import React from 'react'
import logo2 from '../../assets/images/logo2.png'
import curva6 from '../../assets/images/curva6.png'
import { useNavigate } from 'react-router-dom';
import ButtonMaterial from '../../components/Button';
import SelectLanguage from '../../components/SelectLanguage';
import { theme } from '../../styles/theme';
import { useTranslation } from 'react-i18next';
import * as S from './styles'
import { ArrowBack, MonitorHeart, Password } from '@mui/icons-material';
import { HeaderProps } from './types';
import logo from '../../assets/images/logo.png'

const HeaderAuthentication: React.FC<HeaderProps> = (props) => {
  const { recoverPassword = false, title, terms = false, hideHeader = false, hideCurve = false } = props
  const navigate = useNavigate()
  const { t } = useTranslation();

  return (
    <>
      {!hideHeader &&
        <S.Header>
          <button onClick={() => navigate(`/`)}>
            <img src={logo2} alt="" />
          </button>
          {window.screen.width > 680 ? (
            <S.ButtonHeader>
              <SelectLanguage colorWhite={true} />
              <ButtonMaterial style={{ marginLeft: '8px', border: `solid 1px ${theme.colorsBase.white}` }} variant="outlined" color='secondary' label={t('ENTRE OU CADASTRE-SE')} onClick={() => navigate(`/login`)} />
            </S.ButtonHeader>
          ) : (
            <SelectLanguage colorWhite={true} />
          )}
        </S.Header>
      }

      {!hideCurve &&
        <S.ContainerHeader>
          <img src={curva6} className="curva" alt="" />

          <S.BackButton onClick={() => navigate(-1)}>
            <ArrowBack className="icon" />
            <h1>{t(`Voltar`)}</h1>
          </S.BackButton>

          {!terms ? (
            <S.TextHeader>
              {recoverPassword ? <Password className="icon" /> : <MonitorHeart className="icon" />}
              <p>{title ?? (recoverPassword ? t(`Esqueci a Senha`) : t(`O melhor da saúde a um clique de distância`))}</p>
            </S.TextHeader>
          ) : (
            <S.TextHeader>
              <img src={logo} alt="" />
            </S.TextHeader>
          )}
        </S.ContainerHeader>
      }
    </>
  )
}

export default HeaderAuthentication
