import React from 'react';
import { useTranslation } from 'react-i18next';
import { Download, WorkspacePremium } from '@mui/icons-material'
import * as S from './styles'
import { ICertificates } from '../../services/Challenges/types';
import { DataMaskFormtData } from '../../utils/Mask';

interface IProps {
  item: ICertificates
  onClick?: () => void
}

const CardCertificates: React.FC<IProps> = ({ item, onClick, }) => {
  const { t } = useTranslation();

  return (
    <S.Container onClick={onClick}>
      <S.ContainerImage>
        <S.Image style={{ aspectRatio: '4/2.7' }} src={item.Imagem} />
      </S.ContainerImage>

      <div className='boxContent'>
        <div className='boxUser'>
          <WorkspacePremium className="i" />
          <h1 className='nameUser'>{item.Titulo}</h1>
        </div>

        <div className='containerChip'>
          <S.ChipMonochrome style={{ marginLeft: '8px' }}>
            <h1 className='titleChip'>{DataMaskFormtData(item.Data)}</h1>
          </S.ChipMonochrome>

          <S.LinkDownload style={{ marginLeft: '8px' }} href={item.LinkDowload} target="_blank" >
            <Download className="i" />
            <h1 className='titleChip'>{t('Download')}</h1>
          </S.LinkDownload>
        </div>
      </div>

      {/* <S.ButtonViewMore>
        <h1 className='title'>{t('+ Ver detalhes')}</h1>
      </S.ButtonViewMore> */}
    </S.Container>
  )
}

export default CardCertificates;
