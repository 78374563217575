
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsSm } from '../../hooks/useIsSm'
import * as S from './styles'
import { AntTab, AntTabs } from '../../components/Tabs';
import ButtonMaterial from '../Button'
import { theme } from '../../styles/theme'
import { ButtonQuestion } from '../ModalRegistrationAndChallengeEditing/styles';
import AliceCarousel from 'react-alice-carousel';

import firstAccess1 from '../../assets/images/firstAccess1.png'
import firstAccess2 from '../../assets/images/firstAccess2.png'
import firstAccess3 from '../../assets/images/firstAccess3.png'
import firstAccess4 from '../../assets/images/firstAccess4.png'
import firstAccess5 from '../../assets/images/firstAccess5.png'
import firstAccess6 from '../../assets/images/firstAccess6.png'
import firstAccess7 from '../../assets/images/firstAccess7.png'
import firstAccess8 from '../../assets/images/firstAccess8.png'
import firstAccess9 from '../../assets/images/firstAccess9.png'

interface IButtonLink {
  onCancel?: () => void
}

export const FirstAccessCarousel = (props: IButtonLink) => {
  const { onCancel } = props
  const { t } = useTranslation();
  const isSm = useIsSm()
  const [page, setPage] = useState(1)

  const clickButtonNext = async () => {
    if (isSm) {
      if (page === 2 && onCancel) {
        onCancel();
      } else {
        setPage(page + 1)
      }
    } else   if (page === 3 && onCancel) {
        onCancel();
      } else {
        setPage(page + 1)
      }
    
  };

  const card1 = () => {
    return (
      <S.CardCarroselA>
        <img src={firstAccess1} className="imgCard" alt="" />
        <h1>{t("Nosso Site, Seu Site")}</h1>
        <h2>{t("Nossa principal missão é fornecer um espaço acessível, onde o conhecimento alcance todo mundo!")}</h2>
      </S.CardCarroselA>
    )
  };

  const card2 = () => {
    return (
      <S.CardCarroselA>
        <img src={firstAccess2} className="imgCard" alt="" />
        <h1>{t("Todos Podem Ser Criadores")}</h1>
        <h2>{t("Se tem algo legal para contar sobre saúde, pode compartilhar aqui. Não importa se é uma grande novidade ou uma dica simples - queremos ouvir!")}</h2>
      </S.CardCarroselA>
    )
  };

  const card3 = () => {
    return (
      <S.CardCarroselA>
        <img src={firstAccess3} className="imgCard" alt="" />
        <h1>{t("Pra Todo Mundo!")}</h1>
        <h2>{t("Nossa plataforma fala várias línguas. Então, seja de onde você for, e qual a realidade de saúde em que vive, vai se sentir em casa por aqui!")}</h2>
      </S.CardCarroselA>
    )
  };

  const card4 = () => {
    return (
      <S.CardCarroselA>
        <img src={firstAccess4} className="imgCard" alt="" />
        <h1>{t("Ganhe Mais que Pontos")}</h1>
        <h2>{t("Aqui, quem participa ganha! Além dos pontos, temos prêmios que vão te surpreender. Desafios levam à conquista de pontos e prêmios especiais!")}</h2>
      </S.CardCarroselA>
    )
  };

  const card5 = () => {
    return (
      <S.CardCarroselA>
        <img src={firstAccess5} className="imgCard" alt="" />
        <h1>{t("Informação Confiável")}</h1>
        <h2>{t("Antes de mostrar qualquer informação, a gente confere! Uma característica fundamental da Curar Saúde é o nosso rigoroso processo de curadoria. Assim, você pode confiar no que lê por aqui.")}</h2>
      </S.CardCarroselA>
    )
  };

  const card6 = () => {
    return (
      <S.CardCarroselA>
        <img src={firstAccess6} className="imgCard" alt="" />
        <h1>{t("Privacidade é Coisa Séria!")}</h1>
        <h2>{t("Na Curar Saúde, levamos sua privacidade a sério! Todos os dados pessoais fornecidos são tratados com o máximo respeito e integridade. Para obter detalhes completos, consulte nossa Política de Privacidade.")}</h2>
      </S.CardCarroselA>
    )
  };

  const card7 = () => {
    return (
      <S.CardCarroselB>
        <h1>{t("1. Realizar Desafio")}</h1>
        <img src={firstAccess7} className="imgCardB" alt="" />
        <h2>{t("Para iniciar um desafio e ganhar prêmios ou pontos, é só clicar em um dos desafios disponíveis na página “Home”. Realizando um desafio, você pode aprender mais sobre qualquer área da saúde! Alguns desafios possuem perguntas para por em prova o que você aprendeu! Então preste bastante atenção! ")}</h2>
      </S.CardCarroselB>
    )
  };

  const card8 = () => {
    return (
      <S.CardCarroselB>
        <h1>{t("2. Responder Perguntas")}</h1>
        <img src={firstAccess8} className="imgCardB" alt="" />
        <h2>{t("Alguns desafios possuem Perguntas sobre o conteúdo que foi apresentado. Respondendo as perguntas, você não só comprova o que aprendeu nesse desafio, como tem a chance de ganhar prêmios ou pontos! Para responder é simples: leia a questão com atenção, escolha a alternativa que você acredita ser a resposta correta, e clique em “Salvar Resposta”.")}</h2>
      </S.CardCarroselB>
    )
  };

  const card9 = () => {
    return (
      <S.CardCarroselB>
        <h1>{t("3. Criar seu próprio Desafio")}</h1>
        <img src={firstAccess9} className="imgCardB" alt="" />
        <h2>{t("Clicando no botão “Criar Novo Desafio” você consegue postar seus próprios conteúdos criando seus próprios desafios, e se quiser, você consegue até criar as perguntas sobre o que você postou! Todos os desafios criados são avaliados por profissionais da saúde, assim vamos ter certeza que está tudo certo com o seu conteúdo! Ah, e também dá pra ganhar alguns pontinhos criando desafios, viu? Não perca essa chance de compartilhar seu conhecimento e ganhar pontos!")}</h2>
      </S.CardCarroselB>
    )
  };

  const valueTab = () => {
    if (isSm) {
      if (page === 2) {
        return 1
      } else {
        return 0
      }
    } else   if (page === 3) {
        return 1
      } else {
        return 0
      }
    
  };

  const labelButtonNext = () => {
    if (isSm) {
      if (page === 2) {
        return t('EXPLORAR')
      } else {
        return t('PRÓXIMO')
      }
    } else   if (page === 3) {
        return t('EXPLORAR')
      } else {
        return t('PRÓXIMO')
      }
    
  };

  const responsive = {
    0: { items: 1 },
  };

  return (
    <S.Container>
      <AntTabs value={valueTab()} variant={isSm ? 'scrollable' : 'standard'}>
        <AntTab label={t("Sobre Nós")} sx={{ width: isSm ? '50vw' : 'none' }} />
        <AntTab label={t("Desafios")} sx={{ width: isSm ? '50vw' : 'none' }} />
      </AntTabs>

      {!isSm ? (
        <S.ContainerContent>
          <h1>{page === 3 ? t("O que são os “Desafios”?") : t("Seja bem-vindo(a) à Curar Saúde!")}</h1>

          {page === 1 && <h2>{t("A Curar é mais do que uma simples rede social; é uma comunidade dedicada à saúde. Com uma abordagem inovadora, combinamos dicas de saúde, jogos interativos de perguntas e respostas, e um sistema de recompensas para os melhores conteúdos publicados.")}</h2>}
          {page === 3 && <h2>{t("Desafios são conteúdos acessíveis e avaliados, que você pode participar ou até criar o seu! Para os usuários, a Curar oferece não apenas uma fonte confiável de informações sobre saúde, já que todo conteúdo é revisto pelos melhores especialistas em saúde, mas também a chance de se engajar, ganhar pontos e trocá-los por descontos ou prêmios exclusivos, que nos ajudam a manter a saúde.")}</h2>}

          {page === 1 &&
            <div className='boxContent'>
              {card1()}
              {card2()}
              {card3()}
            </div>
          }

          {page === 2 &&
            <div className='boxContent'>
              {card4()}
              {card5()}
              {card6()}
            </div>
          }

          {page === 3 &&
            <div className='boxContent'>
              {card7()}
              {card8()}
              {card9()}
            </div>
          }
        </S.ContainerContent>
      ) : (
        <S.ContainerContent>
          <h1>{page === 2 ? t("O que são os “Desafios”?") : t("Seja bem-vindo(a) à Curar Saúde!")}</h1>

          {page === 1 && <h2>{t("A Curar é mais do que uma simples rede social; é uma comunidade dedicada à saúde. Com uma abordagem inovadora, combinamos dicas de saúde, jogos interativos de perguntas e respostas, e um sistema de recompensas para os melhores conteúdos publicados.")}</h2>}
          {page === 2 && <h2>{t("Desafios são conteúdos acessíveis e avaliados, que você pode participar ou até criar o seu! Para os usuários, a Curar oferece não apenas uma fonte confiável de informações sobre saúde, já que todo conteúdo é revisto pelos melhores especialistas em saúde, mas também a chance de se engajar, ganhar pontos e trocá-los por descontos ou prêmios exclusivos, que nos ajudam a manter a saúde.")}</h2>}

          {page === 1 &&
            <div className='boxContent'>
              <AliceCarousel
                mouseTracking
                responsive={responsive}
                disableButtonsControls={true}
                items={[
                  card1(),
                  card2(),
                  card3(),
                  card4(),
                  card5(),
                  card6()
                ]}
              />
            </div>
          }

          {page === 2 &&
            <div className='boxContent'>
              <AliceCarousel
                mouseTracking
                responsive={responsive}
                disableButtonsControls={true}
                items={[
                  card7(),
                  card8(),
                  card9()
                ]}
              />
            </div>
          }
        </S.ContainerContent>
      )}

      <S.ContainerBottom>
        <ButtonMaterial
          style={{ borderColor: theme.colors.seventh, color: theme.colors.seventh, backgroundColor: theme.colorsBase.white, }}
          variant="outlined"
          label={t('Fechar')}
          onClick={onCancel}
        />

        <S.ContainerQuestionCount>
          <ButtonQuestion active={page === 1}>
            <h5 className='textbutton'>1</h5>
          </ButtonQuestion>

          <ButtonQuestion active={page === 2}>
            <h5 className='textbutton'>2</h5>
          </ButtonQuestion>

          {!isSm && <ButtonQuestion active={page === 3}>
            <h5 className='textbutton'>3</h5>
          </ButtonQuestion>}
        </S.ContainerQuestionCount>

        <ButtonMaterial
          style={{ color: theme.colorsBase.white, backgroundColor: theme.colors.seventh, }}
          variant="outlined"
          label={labelButtonNext()}
          onClick={clickButtonNext}
        />
      </S.ContainerBottom>
    </S.Container>
  )
}

