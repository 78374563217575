/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';
import { Tooltip, Box, LinearProgress, Rating } from '@mui/material';
import {
  AccessTimeFilled,
  AdminPanelSettings,
  CardGiftcard,
  Category,
  Diamond,
  Error,
  Info,
  Leaderboard,
  Numbers,
  Person,
  Redeem,
  Schedule,
  StackedBarChart,
  Visibility,
  VolumeOff,
  VolumeUp,
  VisibilityOff,
  Troubleshoot,
  CheckCircle,
  Cancel,
  Star
} from '@mui/icons-material';

import { useTranslation } from 'react-i18next';

import { theme } from '../../styles/theme';
import LanguageFlag from '../LanguageFlag';
import { ETypeChallenge } from '../ChallengeItem/types';
import { DataMaskFormtData } from '../../utils/Mask';
import * as S from './styles'
import { IChallengeItem } from './types';
import Loading from '../Loading';
import { useIsSm } from '../../hooks/useIsSm';
import ButtonMaterial from '../../components/Button';

interface IProps {
  item: IChallengeItem
  onClick?: () => void
  typeChallenge?: ETypeChallenge
  showAuthorName?: boolean
  showId?: boolean
  showModeratorName?: boolean
  hidenDifficultyInfo?: boolean
  hidenBottonInfo?: boolean
  showCreateDate?: boolean
  SubmissionDate?: string
  DateTime?: string
  /* eslint-disable-next-line @typescript-eslint/ban-types */
  style?: Object;
  playVideo?: boolean
  labelButton?: string
  greenButton?: boolean
  showButton?: boolean
  showSituation?: boolean;
  allowEstimatedTime?: boolean;
  buttonCustom?: any;
  especialProgram?: boolean;
}

const ChallengeItemDesktop: React.FC<IProps> = ({
  item,
  onClick,
  typeChallenge,
  showAuthorName,
  showId,
  showModeratorName,
  hidenDifficultyInfo,
  hidenBottonInfo,
  showCreateDate,
  style,
  playVideo,
  labelButton,
  showButton = true,
  greenButton,
  showSituation,
  allowEstimatedTime,
  buttonCustom,
  especialProgram
}) => {
  const { t } = useTranslation();
  const isSm = useIsSm()

  const allowLangFlag = (typeChallenge === 6 || typeChallenge === 7 || typeChallenge === 8)
  const allowOnlyTitle = (typeChallenge === 1 || typeChallenge === 2 || typeChallenge === 3 || typeChallenge === 9 || typeChallenge === 10)
  const allowId = (typeChallenge === 7 || typeChallenge === 8 || typeChallenge === 10 || typeChallenge === 11) || showId
  const allowCuratorDate = (typeChallenge === 7 || typeChallenge === 8)
  const allowAuthorName = (typeChallenge === 1 || typeChallenge === 2 || typeChallenge === 3) || showAuthorName
  const allowCategory = (typeChallenge === 4 || typeChallenge === 5 || typeChallenge === 6)
  const allowModeratorName = showModeratorName
  const allowProgressBar = typeChallenge === 5
  const allowDifficultyInfo = (typeChallenge !== 7 && typeChallenge !== 8 && typeChallenge !== 10 && typeChallenge !== 11 && typeChallenge !== 12) && !hidenDifficultyInfo
  const allowBottonInfo = typeChallenge !== 4 && typeChallenge !== 5 && typeChallenge !== 6 && typeChallenge !== 7 && typeChallenge !== 8 && typeChallenge !== 1 && typeChallenge !== 9 && typeChallenge !== 10 && typeChallenge !== 11 && typeChallenge !== 12 && !hidenBottonInfo
  const allowCreateDate = showCreateDate || typeChallenge === 11
  const homeScreenInformation = typeChallenge === 1
  const allowAuthorNameHoldings = typeChallenge === 4 || typeChallenge === 5 || typeChallenge === 9
  const allowBottonButton = typeChallenge === 10 || typeChallenge === 11

  const [ativo, setAtivo] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAudio, setIsAudio] = useState(false);

  const handlePlay = async () => {
    try {
      if (videoRef.current) {
        if (videoRef.current.readyState === 4) {
          await videoRef.current.play();
        }
      }
    } catch (err) {
      console.error("Error", err)
    }
  };

  const handleLoadedMetadata = () => {
    try {
      handlePlay();
      setIsLoading(false);
    } catch (err) {
      console.error('Error', err)
    }
  };

  const handleMouseOver = () => {
    setAtivo(true);
  };

  const handleMouseOut = () => {
    setAtivo(false);
    setIsLoading(false);
  };

  const handleAudio = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (!isSm) {
      setIsAudio(!isAudio)
    }
  };

  const handleAudioTouch = (event: React.TouchEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (isSm) {
      setIsAudio(!isAudio)
    }
  };

  const getChallengeStatus = (status: number) => {
    switch (status) {
      case 0:
        return { label: "NÃO INCIADO", color: theme.colors.black4, icon: Info };
      case 1:
        return { label: "INICIADO", color: theme.colors.seventh, icon: Info };
      case 2:
        return { label: "FINALIZADO", color: theme.colorsBase.green2, icon: CheckCircle };
      case 3:
        return { label: "APROVADO", color: theme.colorsBase.green2, icon: CheckCircle };
      case 4:
        return { label: "REPROVADO", color: theme.colors.graphicRed, icon: Cancel };
      default:
        return { label: "NÃO INCIADO", color: theme.colors.black4, icon: Info };
    }
  };

  const challengeStatus = getChallengeStatus(item.Status ?? 0);

  return (
    <S.ContainerTotal >
      <S.BoxChallengePending onTouchMove={handleMouseOver} onTouchEndCapture={handleMouseOut} onMouseOver={handleMouseOver} onMouseLeave={handleMouseOut} onClick={onClick} style={style} active={item?.SpecialAwardName ? item?.SpecialAwardName?.length > 1 : false} >
        <S.ContainerImage>
          {playVideo ? <>
            {!ativo && <S.ImageVideo style={{ aspectRatio: '13/9' }} src={item.CoverImageUrl} active={item.ChallengeUnavailable} />}

            {ativo && <S.ContainerVideo style={{ aspectRatio: '13/9' }}
              ref={videoRef}
              id="my-player"
              autoPlay
              controls={false}
              muted={!isAudio}
              playsInline
              preload="metadata"
              onLoadedMetadata={handleLoadedMetadata}
              onLoadStart={() => setIsLoading(true)}
              data-setup='{}'>
              <source src={item.ContentFileUrl}></source>
            </S.ContainerVideo>}

            {isLoading && <Loading style={{ position: 'absolute', left: '50%', right: '50%', padding: 0, zIndex: 900 }} styleIcon={{ color: 'white', fontSize: '32px' }} />}

            {(!isLoading && videoRef.current?.played && ativo) && <S.BoxOptionsVideo onTouchStart={handleAudioTouch} onClick={handleAudio}>
              {isAudio ? <VolumeUp className="iOptionVideo" /> : <VolumeOff className="iOptionVideo" />}
            </S.BoxOptionsVideo>}

          </> : <S.Image style={{ aspectRatio: '13/9' }} src={item.CoverImageUrl} active={item.ChallengeUnavailable} />}
        </S.ContainerImage>

        {item.IsVisualizado &&
          <div className='boxViewed'>
            <Visibility className="iconViewed" />
          </div>
        }

        {item.ChallengeUnavailable &&
          <S.ChallengeUnavailable>
            <h1 className='titleChallengeUnavailable'>{t('Desafio indisponível')}</h1>
          </S.ChallengeUnavailable>
        }

        {item.SpecialAwardName &&
          <div className='boxPremium'>
            <Diamond className="iconPremium" />
          </div>
        }

        {allowLangFlag &&
          <div className='topLeft'>
            <LanguageFlag fillSpace={false} Idioma={Number(item.Language)} style={{ marginTop: 0 }} />
          </div>
        }

        {showSituation &&
          <div className='topRight' style={{ borderRadius: 4, borderColor: challengeStatus.color, padding: 12 }}>
            <challengeStatus.icon style={{ marginRight: 4, color: challengeStatus.color }} />
            <h1 className='textMin' style={{ color: challengeStatus.color }}>{challengeStatus.label}</h1>
          </div>
        }

        {(typeChallenge === 4 || typeChallenge === 5 || typeChallenge === 6) &&
          <div className='topRight'>
            <h1 className='textMin'>+{item.ScoreAcquired ?? item.Score} pts</h1>
          </div>
        }

        {especialProgram && (
          <div className='topRight2'>
            <S.StatusTag underReview>
              <Star className="iconStatus" />
              {t('Conteúdo de Programa Especial')}
            </S.StatusTag>
          </div>
        )}

        {/* Tag de status para typeChallenge 11 */}
        {typeChallenge === 11 && (
          <div className='topRight2'>
            {item.StatusAlt === 'approved' && (
              <Tooltip title={t('APROVADO')}>
                <S.StatusTag approved>
                  <Visibility className="iconStatus" />
                  {t('APROVADO')}
                </S.StatusTag>
              </Tooltip>
            )}
            {item.StatusAlt === 'rejected' && (
              <Tooltip title={t('REJEITADO')}>
                <S.StatusTag rejected>
                  <VisibilityOff className="iconStatus" />
                  {t('REJEITADO')}
                </S.StatusTag>
              </Tooltip>
            )}
            {item.StatusAlt === 'under_review' && (
              <Tooltip title={t('EM REVISÃO')}>
                <S.StatusTag underReview>
                  <Troubleshoot className="iconStatus" />
                  {t('EM REVISÃO')}
                </S.StatusTag>
              </Tooltip>
            )}
          </div>
        )}

        <div className='boxContent'>
          {allowOnlyTitle ?
            <h1 className='title'>{item.Title}</h1>
            :
            <div className='boxTitle'>
              <h1 className='titleB'>{item.Title}</h1>

              {typeChallenge === 7 ?
                <Tooltip title={t('Ver motivo de reprovação')}>
                  <Error className="iconTitleError" />
                </Tooltip>
                :
                <Tooltip title={t('Ver mais informações')}>
                  <Info className="iconTitle" />
                </Tooltip>
              }
            </div>
          }

          {allowId && (
            <div className='boxUser'>
              <Numbers className="i" style={{ color: theme.shadows.fourth }} />
              <h1 className='nameUser'>{t('ID')}: <b>{item.Id}</b></h1>
            </div>
          )}

          {allowCuratorDate &&
            <div className='boxUser' style={{ marginBottom: '24px' }} >
              <AccessTimeFilled className="i" style={{ color: theme.shadows.fourth }} />
              {typeChallenge === 7 ? (
                <h1 className='nameUser'>
                  {t('Data da Reprovação')}: <b>{DataMaskFormtData(item.RepprovedAt ?? '')}</b>
                </h1>
              ) : (
                <h1 className='nameUser'>
                  {t('Data de envio para revisão')}: <b>{DataMaskFormtData(item.SubmissionDate ?? '')}</b>
                </h1>
              )}
            </div>
          }

          {allowAuthorName && (
            <div className='boxUser'>
              <Person className="i" />
              <h1 className='nameUser'>{item.AuthorName}</h1>
            </div>
          )}

          {allowEstimatedTime && (
            <div className='boxUser'>
              <Schedule className="i" style={{ color: theme.shadows.fourth }} />
              <h1 className='nameUser'>
                {t('Tempo médio de conclusão')}: <b>{(item.EstimatedTime)} {t('min')}</b>
              </h1>
            </div>
          )}

          {allowCreateDate &&
            <div className='boxUser'>
              <AccessTimeFilled className="i" style={{ color: theme.shadows.fourth }} />
              <h1 className='nameUser'>
                {t('Data e hora de envio')}: <b>{DataMaskFormtData(item.DateTime ?? '')}</b>
              </h1>
            </div>
          }

          {allowCategory && (
            <div className='boxUser'>
              <Category className="i" />
              <h1 className='nameUser'>{item.Category}</h1>
            </div>
          )}

          {allowDifficultyInfo &&
            <div className='containerContent'>
              <div className='leftItens'>
                <S.BoxContent>
                  <StackedBarChart className="i" />
                  <h5>{item?.DifficultyLevel}</h5>
                </S.BoxContent>

                <S.BoxContent style={{ marginLeft: '8px' }}>
                  <Schedule className="i" />
                  <h5>{item?.EstimatedTime} {t('min')}</h5>
                </S.BoxContent>
              </div>

              {typeChallenge !== 4 && typeChallenge !== 5 && typeChallenge !== 6 &&
                <S.BoxCategory>
                  <h1 className='titleChip'>{item.Category}</h1>
                </S.BoxCategory>
              }
            </div>
          }

          {allowModeratorName &&
            <div className='boxUser' style={{ marginBottom: typeChallenge === 4 || typeChallenge === 6 ? '8px' : '0px' }}>
              <AdminPanelSettings className="i" />
              <h1 className='nameUser'>{t('Moderado por')}: <b>{item.NomeCurador || "N/A"}</b></h1>
            </div>
          }

          {typeChallenge === 9 &&
            <S.ContainerNotContent>
              <div className='topLine' />
              <div className='bottomLine' />
            </S.ContainerNotContent>
          }

          {allowAuthorNameHoldings &&
            <div className='boxUser' style={{}}>
              <AdminPanelSettings className="i" />
              <h1 className='nameUser'>{t('Autor')}: {item.ChallengeUnavailable ? t('Curar Saúde') : item.AuthorName}</h1>
            </div>
          }

          {allowProgressBar &&
            <div className='boxUser' style={{ marginBottom: '16px' }}>
              <Leaderboard className="i" />
              <h1 className='nameUser'>{t('Progresso')}:</h1>

              <div className='containerProgressBar'>
                <Box sx={{ width: '100%' }}>
                  <LinearProgress variant="determinate" value={item.PercentageCompleted} style={{ borderRadius: '8px' }} color="info" />
                </Box>

                <h1 className='textProgress'>{item.PercentageCompleted}%</h1>
              </div>
            </div>
          }
        </div>

        <div className='containerBottom'>
          {buttonCustom}

          {allowBottonButton && showButton && (
            <ButtonMaterial
              style={{ color: theme.colorsBase.white, backgroundColor: greenButton ? theme.colorsBase.green2 : theme.colors.seventh, width: "100%" }}
              variant="contained"
              label={labelButton ?? t('Ver mais informações')}
            />
          )}

          {allowBottonInfo && (
            <>
              <LanguageFlag fillSpace={false} Idioma={Number(item.Language)} />

              <div className='containerChip'>
                {(item.ScoreAcquired !== 0 || item.Score) && (
                  <S.Chip>
                    <h1 className='titleChip'>+{item.ScoreAcquired ?? item.Score} pts</h1>
                  </S.Chip>
                )}

                {item?.Brinde && (
                  <Tooltip title={item.Brinde}>
                    <S.Chip style={{ marginLeft: '8px' }}>
                      <Redeem className="i" />
                      <h1 className='titleChip'>{t('Brinde')}</h1>
                    </S.Chip>
                  </Tooltip>
                )}
              </div>
            </>
          )}

          {homeScreenInformation && (
            <>
              <Rating
                name="read-only"
                size="small"
                value={item?.AverageRating}
                readOnly
              />

              <div className='containerChip'>
                {item?.SpecialAwardName ? (
                  <>
                    <CardGiftcard style={{ color: theme.colors.seventh, fontSize: '16px', marginRight: '4px' }} />

                    <Tooltip title={t('Atenção! Caso os prêmios se esgotem antes da sua conclusão, não se preocupe, você ainda será recompensado com a quantidade de pontos informada.')}>
                      <S.BoxPoint>
                        <h1 className='titlePoint' style={{ textDecorationLine: 'underline' }}>{item?.SpecialAwardName} ou +{item.ScoreAcquired ?? item.Score} pts</h1>
                      </S.BoxPoint>
                    </Tooltip>
                  </>
                ) : (
                  <S.BoxPoint>
                    <h1 className='titlePoint'>+{item.ScoreAcquired ?? item.Score} pts</h1>
                  </S.BoxPoint>
                )}
              </div>
            </>
          )}
        </div>

      </S.BoxChallengePending>
    </S.ContainerTotal>
  )
}

export default ChallengeItemDesktop;
