import React from 'react';
import * as S from './styles'
import { Home } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/pageconstruction.png'
import Footer from '../../components/Footer';
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import { useIsSm } from '../../hooks/useIsSm';

const PageUnderConstruction: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const isSm = useIsSm()

  return (
    <S.Container>
      <S.ContainerContent>
        <div className='box'>
          <S.Title>{t('Página em Construção!')}</S.Title>
          <S.Message>{t('Essa funcionalidade da Curar Saúde está em desenvolvimento! Aguenta aí! Estamos preparando algo incrível para você, fique ligado pois em breve ficará disponível.')}</S.Message>

          <ButtonMaterial
            style={{ backgroundColor: theme.colors.seventh, marginTop: isSm ? '48px' : '24px' }}
            variant="contained"
            label={t('Voltar para a página inicial')}
            icon={<Home style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.white }} />}
            onClick={() => navigate('/home')}
          />
        </div>

        <img src={logo} className="image" alt="" />
      </S.ContainerContent>

      <Footer description={false} />
    </S.Container>
  )
}

export default PageUnderConstruction
