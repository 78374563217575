import React from 'react';
import PageContainer from '../../components/Layout/PageContainer';
import PaperContainer from '../../components/Layout/PaperContainer';
import { AntTab, AntTabs } from '../../components/Tabs';
import { theme } from '../../styles/theme';
import HeaderAchievements from '../../components/HeaderAchievements';
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../hooks/useIsSm';
import { FolderSpecial } from '@mui/icons-material';
import ScreenTriageSpecialProgram from './ScreenTriageSpecialProgram';

interface IProps {
  tab: number;
  variant?: "fullWidth" | "standard" | "scrollable";
}

const TriageSpecialProgramView: React.FC<IProps> = ({ tab, variant }) => {
  const { t } = useTranslation();
  const isSm = useIsSm()

  return (
    <PageContainer>
      <HeaderAchievements
        title={t('Gerenciamento de Programas Especiais')}
        marginBottom={isSm ? 24 : 0}
        buttonBack={!isSm}
        icon={<FolderSpecial style={{ color: isSm ? theme.colors.secundary : theme.colorsBase.gray3 }} />}
        style={{ marginTop: isSm ? '32px' : '0px' }}
      />
      <PaperContainer>
        <AntTabs value={tab} variant={variant}>
          <AntTab label="TRIAGEM" sx={{ width: isSm ? '50vw' : 'none', margin: isSm ? '0 auto' : '0px 0px 0px 32px' }} />
        </AntTabs>

        <ScreenTriageSpecialProgram update />
      </PaperContainer>
    </PageContainer>
  )
}

export default TriageSpecialProgramView
