import React, { useEffect, useState } from 'react';
import * as S from './styles'
import { Add, } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import ToastNotification from '../../components/ToastNotification';
import Loading from '../../components/Loading';
import ViewError from '../../components/ViewError';
import ModalCustom from '../../components/ModalCustom';
import { MenuItem, Select, } from '@mui/material'
import { getChallengesCreated, getChallengesFailed, getChallengesPerformed, } from '../../services/Challenges';
import { IChallengeAccomplished } from '../../services/Challenges/types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { ICategories } from '../../components/TableCategory/types';
import { getCategories } from '../../services/Categories';
import ChallengeItemDesktop from '../../components/ChallengeItemDesktop';
import { useIsSm } from '../../hooks/useIsSm';
import SearchBar from '../../components/SearchBar';
import { Inputs, Item } from '../../pages/MyContacts/styles';
import ButtonCreateMobileChallenge from '../../components/ButtonCreateMobileChallenge';
import ViewCardChallenge from './CardQuizChallenge';
import ChallengeDetailsModal from '../SpecialProgramManagement/SpecialProgramDetails/ScreenSpecialProgramContents/ChallengeDetailsModal';

interface IProps {
  token?: string;
  headerActive: number;
}

const ViewChallenge: React.FC<IProps> = ({ token, headerActive }) => {
  const { t } = useTranslation();
  const isSm = useIsSm()
  const [loading, setLoading] = useState(false)
  const [openChallengeModal, setOpenChallengeModal] = useState(false)
  const [challengeList, setChallengeList] = useState<IChallengeAccomplished[]>([])
  let totalPages = 0;
  let currentPage = 0;
  const [categoriesList, setCategoriesList] = useState<ICategories[]>([])
  const [itemSelectedId, setItemSelectedId] = useState('')
  const [valueCreated, setValueCreated] = useState('');
  const [openModalCreated, setOpenModalCreated] = useState(false)
  const [statusCreated, setStatusCreated] = useState('0');
  const [modalType, setModalType] = useState<'approved' | 'rejected' | 'under_review' | null>(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    retrieveCategories();
  }, []);

  useEffect(() => {
    setModalType(null)
    setStatusCreated('0')
    setValueCreated('')
    totalPages = 0;
    currentPage = 0;
    setChallengeList([])
    retrieveChallenge(1, true, '0', '');
  }, [headerActive]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > (300 * currentPage)) {
        if (currentPage < (totalPages || 0)) {
          retrieveChallenge(currentPage + 1, false, statusCreated, valueCreated);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Função de limpeza para remover o listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (token) {
      setOpenChallengeModal(true)
    }
  }, [token]);

  const retrieveCategories = async () => {
    setLoading(true)
    try {
      const response = await getCategories();
      if (response.Success) {
        setCategoriesList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar as categorias, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar as categorias, tente novamente.')
      });
    }
    setLoading(false)
  };

  const retrieveChallenge = async (page: number = 1, clear: boolean = false, category: string, title: string) => {
    if (page === 1) {
      setLoading(true)
    }
    try {
      if (clear) {
        setChallengeList([]);
      }
      const bodyData = {
        PageNumber: 1,
        PageSize: 64 * page,
        Nome: title,
        CategoriaId: category !== "0" ? category : '',
      };

      let response;
      if (headerActive === 0) {
        response = await getChallengesCreated(bodyData);
      } else if (headerActive === 2) {
        response = await getChallengesPerformed(bodyData);
      } else if (headerActive === 1) {
        response = await getChallengesFailed(bodyData);
      } else {
        response = await getChallengesCreated(bodyData);
      }

      if (response.Success) {
        currentPage = page;
        if (response.TotalPages) {
          totalPages = response.TotalPages;
        }
        setChallengeList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os desafios, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os desafios, tente novamente.')
      });
    }
    setLoading(false)
  };

  const onEnterSearch = (event: { key: string }) => {
    if (event.key === 'Enter') {
      retrieveChallenge(1, true, statusCreated, valueCreated);
    }
  };

  const returnChallengeCard = () => {
    if (headerActive === 0) return 6;
    if (headerActive === 1) return 7;
    if (headerActive === 2) return 8;
    return 8;
  };

  const returnErrorMsg = () => {
    if (headerActive === 0) return t('Nenhum desafio ativo') ?? 'Nenhum desafio ativo';
    if (headerActive === 1) return t('Nenhum desafio rejeitado') ?? 'Nenhum desafio rejeitado';
    if (headerActive === 2) return t('Nenhum desafio em revisão') ?? 'Nenhum desafio em revisão';
    return 'Nenhum desafio ativo';
  };

  const handleCloseChallengeModal = () => {
    setItemSelectedId("");
    setModalType(null);
  };

  const handleClickCard = (id: string) => {
    setItemSelectedId(id)
    if (headerActive === 0) setModalType('approved');
    if (headerActive === 1) setModalType('rejected');
    if (headerActive === 2) setModalType('under_review');
    return 8;
  };

  return (
    <S.Container>
      <SearchBar
        value={valueCreated}
        setValue={(e) => setValueCreated(e)}
        onEnterSearch={(e) => onEnterSearch(e)}
        setFilterModal={() => setOpenModalCreated(true)}
        placeholder={t('Busque o desafio desejado')}
        filterButton
      />

      {!isSm ?
        <ButtonMaterial
          style={{ borderColor: theme.colors.seventh, color: theme.colorsBase.white, backgroundColor: theme.colors.seventh, minWidth: !isSm ? "none" : "100%", borderRadius: '50px  8px 8px 50px', marginTop: '8px', position: 'absolute', right: '60px' }}
          variant="outlined"
          label={t('Criar novo desafio')}
          icon={<Add style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.white }} />}
          onClick={() => setOpenChallengeModal(true)}
          disabled={loading}
        />
        : <ButtonCreateMobileChallenge />}

      {loading ? (
        <Loading />
      ) : (
        <>
          {challengeList.length > 0 ? (
            <>
              <S.ContainerChallenge>
                {challengeList.map((item) => (
                  <>
                    {!isSm ?
                      <ChallengeItemDesktop
                        onClick={() => handleClickCard(item.Id)}
                        typeChallenge={returnChallengeCard()}
                        item={item}
                      />
                      :
                      <ChallengeItemDesktop
                        onClick={() => handleClickCard(item.Id)}
                        typeChallenge={returnChallengeCard()}
                        item={item}
                        style={{ width: windowWidth < 400 ? 'none' : '90%', margin: '0' }}
                      />
                    }
                  </>
                ))}
              </S.ContainerChallenge>

              {loading && <Loading />}
            </>
          ) : (
            <ViewError message={returnErrorMsg()} />
          )}
        </>
      )}

      <ModalCustom
        open={openModalCreated}
        onClose={() => setOpenModalCreated(false)}
        onClick={() => {
          retrieveChallenge(1, true, statusCreated, valueCreated);
          setOpenModalCreated(false)
        }}
        childrenContent={
          <>
            <Item>
              <Inputs>
                <div className="input">
                  <FormControl sx={{ display: 'flex', m: 1, width: '100%' }}>
                    <InputLabel id="demo-simple-select-helper-label">{t('Categoria')}</InputLabel>
                    <Select
                      MenuProps={{
                        MenuListProps: {
                          sx: { maxHeight: '200px', maxWidth: '100%', overflowY: 'auto' }
                        }
                      }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      variant="standard"
                      style={{ display: 'flex', width: '100%' }}
                      value={statusCreated}
                      onChange={(e) => {
                        setStatusCreated(String(e.target.value))
                      }}
                    >
                      <MenuItem value={'0'}>{t('Todos')}</MenuItem>
                      {categoriesList?.map((item) => (
                        <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Inputs>
            </Item>
          </>
        }
        title={t('Filtros')}
        confirmationButtonText={t('Pesquisar')}
        textDeclineButton={t('CANCELAR')}
      />

      <ViewCardChallenge
        openChallengeModal={openChallengeModal}
        setOpenChallengeModal={setOpenChallengeModal}
        update={() => retrieveChallenge(1, true, statusCreated, valueCreated)}
      />

      {itemSelectedId && modalType && (
        <ChallengeDetailsModal
          open={Boolean(itemSelectedId && modalType)}
          onClose={handleCloseChallengeModal}
          type={modalType}
          itemSelectedId={itemSelectedId}
          update={() => retrieveChallenge(1, true, statusCreated, valueCreated)}
        />
      )}
    </S.Container>
  )
}

export default ViewChallenge
