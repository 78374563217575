import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './languages/en.json'
import pt from './languages/pt.json'
import es from './languages/es.json'
import fr from './languages/fr.json'

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    lng: 'BR',
    resources: {
        EN: en,
        BR: pt,
        ES: es,
        FR: fr
    },
    react: {
        useSuspense: false,
    },
    interpolation: {
        escapeValue: false,
    }
})

export default i18n;
