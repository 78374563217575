import React, { useState } from 'react';
import * as S from './styles'
import { useTranslation } from 'react-i18next'
import Rating from '@mui/material/Rating';
import Input from '../../components/Input';
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import ToastNotification from '../../components/ToastNotification';
import { postAssessment } from '../../services/Assessment';
import ModalCustom from '../ModalCustom'
import { useIsSm } from '../../hooks/useIsSm';
interface IProps {
  id?: string;
  content?: boolean
}

const BoxAssessment: React.FC<IProps> = (props: IProps) => {
  const { id, content } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState<number | null>(5);
  const [commentEvaluation, setCommentEvaluation] = useState("");
  const [openModal, setOpenModal] = useState(false)
  const isSm = useIsSm()

  const registerAssessment = async () => {
    setLoading(true)
    try {
      const bodyData = {
        ReviewScore: value,
        Review: commentEvaluation
      };
      const response = await postAssessment(bodyData, id);
      if (response.Success) {
        setOpenModal(true)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao avaliar, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao avaliar, tente novamente.')
      });
    }
    setLoading(false)
  };

  return (
    <S.ContainerDataAssessment>
      <div className='divContentText'>
        <div className='boxTextLeft'>
          <h3>{content ? t("Nos diga o que achou do Conteúdo") : t("Nos diga o que achou do desafio")}</h3>
          <h5>{t("Sua avaliação é muito importante para nós")}</h5>
        </div>
      </div>

      <div className='divContentAssessment'>
        <h5>{t("Avaliação")}</h5>

        <Rating
          name="simple-controlled"
          size={isSm ? "small" : "medium"}
          value={value}
          onChange={(_, newValue) => {
            if (newValue) {
              setValue(newValue);
            } else {
              setValue(1);
            }
          }}
        />

        <Input
          label={t('Comentário')}
          type="comentario"
          value={commentEvaluation}
          onChange={(e) => setCommentEvaluation(e.target.value)}
          size="small"
          fullWidth
          variant="filled"
          style={{ width: '100%', marginBottom: '16px' }}
        />

        <ButtonMaterial
          style={{ border: `solid 1px ${theme.colors.seventh}`, width: '100%' }}
          variant="outlined"
          color='info'
          label={t("ENVIAR AVALIAÇÃO")}
          onClick={registerAssessment}
          loading={loading}
        />
      </div>

      <ModalCustom
        open={openModal}
        onClose={() => setOpenModal(false)}
        onClick={() => setOpenModal(false)}
        title={t('Avaliação de desafio')}
        description={t('Sua avaliação foi enviada com sucesso!')}
        confirmationButtonText={t('CONFIRMAR')}
        textDeclineButton={t('CANCELAR')}
        buttonsEnabled={false}
        closeBottomTop={false}
        childrenContent={
          <ButtonMaterial
            style={{ borderColor: theme.colors.secundary }}
            variant="outlined"
            label={t('VOLTAR')}
            onClick={() => setOpenModal(false)}
          />
        }
      />
    </S.ContainerDataAssessment>
  )
}

export default BoxAssessment