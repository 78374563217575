import React from 'react';
import { UseMutationResult } from '@tanstack/react-query';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import ModalCustom from '../../../components/ModalCustom';
import { ELocalErroDesafio, IPostRejectModerationCommand } from '../../../services/Moderation/types';

interface IProps {
  open: boolean;
  onClose: () => void;
  mutation: UseMutationResult<unknown, unknown, Omit<IPostRejectModerationCommand, "DesafioId">, unknown>
}

const ChallengeChangesRequestModal: React.FC<IProps> = ({ open, onClose, mutation }) => {
  const { t } = useTranslation()

  const { control, register, handleSubmit } = useForm< Omit<IPostRejectModerationCommand, "DesafioId">>({
    defaultValues: { AceiteDivulgacao: false }
  })

  return (
    <ModalCustom
      open={open}
      onClick={handleSubmit((data) => mutation.mutateAsync(data, { onSettled: onClose }))}
      onClose={onClose}
      buttonsEnabled={true}
      title={t("Sugerir mudanças")}
      childrenContent={(
        <Stack spacing={2}>
          <Controller
            control={control}
            name='LocalErroDesafio'
            render={({ field }) => (
              <FormControl fullWidth size='small'>
                <InputLabel>{t("Local do erro")}</InputLabel>
                <Select {...field} label="Local do erro" size='small'>
                  <MenuItem value={ELocalErroDesafio.CONTEUDO}>{t("Conteudo")}</MenuItem>
                  <MenuItem value={ELocalErroDesafio.PERGUNTA}>{t("Pergunta")}</MenuItem>
                  <MenuItem value={ELocalErroDesafio.RESPOSTA}>{t("Resposta")}</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <TextField
            label={t('Mudanças sugeridas')}
            size='small'
            rows={4}
            {...register('Observacao')}
            multiline
            fullWidth
          />

          <Controller
            control={control}
            name='AceiteDivulgacao'
            render={({ field: { value, ...field } }) => (
              <FormControlLabel
                control={<Checkbox checked={value} {...field} />}
                label={t("Autorizo a aparição do meu nome como moderador resposável pelo desafio.")}
              />
            )}
          />
        </Stack>
      )}
      loading={mutation.isLoading}
      confirmationButtonText={t("ENVIAR")}
      textDeclineButton={t("CANCELAR")}
    />
  )
}

export default ChallengeChangesRequestModal;
