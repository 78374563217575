import React, { useEffect, useState } from 'react'
import { useIsSm } from '../../hooks/useIsSm';
import SpecialProgramView from './view';
import { useParams } from 'react-router-dom';

const SpecialProgram: React.FC = () => {
  const [tab, setTab] = useState(0)
  const isSm = useIsSm()
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      setTab(Number(token))
    }
  }, [token]);

  return (
    <SpecialProgramView
      tab={tab}
      setNewTab={setTab}
      variant={isSm ? 'scrollable' : 'standard'}
    />
  );
}

export default SpecialProgram;
