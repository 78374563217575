import React from 'react';

import { useChallengeDashboard } from '../../services/Dashboard/ChallengesDashboard';
import { useStartEndDate } from '../../hooks/useStartEndDate';

import UserDeshboardView from './view';

const UserDeshboard: React.FC = () => {
  const { end, today } = useStartEndDate()

  const query = useChallengeDashboard({
    Start: end.toISOString(),
    End: today.toISOString()
  });

  return <UserDeshboardView endDate={end} query={query} />;
}

export default UserDeshboard;
