

export const emptyItem: IReport = {
  Name: "",
  Email: "",
  CompletedAt: "",
  PercentageOfCorrectAswers: 0,
  Attempts: 0,
  ResumeSpecialProgramStatus: 0,
}

export interface ITableUsers {
  listReport: IReport[]
  updateList: () => void
}

export interface IReport {
  Name: string,
  Email: string,
  CompletedAt: string;
  PercentageOfCorrectAswers: number;
  Attempts: number;
  ResumeSpecialProgramStatus: number;
}
