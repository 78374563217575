import { sendRequest } from '../RequestService';
import { IPageInfo } from '../RequestService/types';
import { ISettings } from '../../components/TableSettings/types';

const defaultRoot = 'parameters';

export const getSettings = async (body: IPageInfo) => {
  let url = `${defaultRoot}`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  return await sendRequest<ISettings[], IPageInfo>(url, 'GET')
}

export const editSettings = async (body: string | number | boolean, id: number) => {
  const url = `${defaultRoot}/${id}`
  return await sendRequest<boolean, string | number | boolean>(url, 'PUT', body)
}

export const getParametersMaximumPrizes = async () => {
  const url = `${defaultRoot}/fghfhg`
  return await sendRequest<number, string>(url, 'GET')
}