import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'


export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  width: 95%;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: ${(props) => props.theme.colorsBase.white};
  box-shadow: 24;
  padding: 4px 8px 8px 8px;
  bottom: 0px;
  border-radius: 16px 16px 0px 0px;
  max-height: 90%;
  overflow:auto; 
  
  h4 {
    align-self: center;
    margin: 40px 16px 0 16px;
    text-align: center;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black3};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    margin-bottom: 40px;
  }

  .button {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
  }
`

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 4px 0px;
  border-bottom: solid 1px ${(props) => props.theme.colorsBase.gray4};
  margin-bottom: 16px;
   
  .buttonClose {
    display: flex;
    position: absolute;
    top: 12px;
    right: 16px;
    align-items: center;
    flex-direction: row;
    border: none;
    background-color: transparent;
  
    &:hover {
      opacity: 0.8;
    }

    .icon {
      color: ${(props) => props.theme.colorsBase.black};
      width: 16px;
      height: 16px;
    }
  }

  .icon {
    color: ${(props) => props.theme.colorsBase.gray3};
    width: 24px;
    height: 24px;
  }

  h5 {
    margin-left: 8px;
    line-height: 18px;
    letter-spacing: 0.25px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(14)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }
`

export const LineTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
  margin-top: 4px;
  border-bottom: solid 1px ${(props) => props.theme.colorsBase.gray4};
  align-self: center;
`
