import { IReport } from '../../pages/SpecialProgramManagement/SpecialProgramDetails/ScreenSpecialProgramContents/SpecialProgramSummaryModal/TableReport/types';
import { ISpecialProgram } from '../../pages/SpecialProgramManagement/ScreenUnderConstruction/types';
import { sendRequest } from '../RequestService';
import { IBodyGET, IPostContent, IPostFinalizeContent, IPostSpecialProgram, } from './types';
import axios from 'axios';
import { PARAMS_SERVICE_URL, USER_GET } from '../../config/constants'
import { IUser } from '../../contexts/Auth/types';
import { getLocalStorage } from '../../utils/StorageLocal';

const defaultRoot = 'special-programs';

export const getSpecialProgramDetails = async (id: string) => {
  const url = `${defaultRoot}/details?SpecialProgramId=${id}`;
  return await sendRequest<ISpecialProgram, IBodyGET>(url, 'GET')
}

export const getDownloadReport = async (id: string) => {
  try {
    const urlAPI = `${PARAMS_SERVICE_URL}/${defaultRoot}/download-resume?SpecialProgramId=${id}`;
    const user = getLocalStorage<IUser>(USER_GET);

    const response = await axios.get(urlAPI, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${user?.Token}`,
      },
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.pdf');
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Erro ao baixar o arquivo:', error);
  }
}

export const getReport = async (body: IBodyGET) => {
  let url = `${defaultRoot}/resume`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Id) url += `&SpecialProgramId=${body.Id}`;
  url += `&OrderByProperty=-Id`;
  return await sendRequest<IReport[], IBodyGET>(url, 'GET')
}

export const postProgramInviteExcel = async (data: FormData) => {
  const url = `${defaultRoot}-participant/read`
  return await sendRequest<{ Emails: string[] }, FormData>(url, 'POST', data)
}

export const postProgramInvite = async (id: string, listEmail: string[]) => {
  const url = `${defaultRoot}-participant/invite`
  return await sendRequest<string, { SpecialProgramId: string, Emails: string[] }>(url, 'POST', { SpecialProgramId: id, Emails: listEmail })
}

export const postReject = async (id: string, reason?: string) => {
  const url = `${defaultRoot}-timeline/reject`
  return await sendRequest<string, { SpecialProgramId: string, Reason?: string }>(url, 'POST', { SpecialProgramId: id, Reason: reason })
}

export const postBlocked = async (id: string, reason?: string) => {
  const url = `${defaultRoot}-timeline/blocked`
  return await sendRequest<string, { SpecialProgramId: string, Reason?: string }>(url, 'POST', { SpecialProgramId: id, Reason: reason })
}

export const postApprove = async (id: string) => {
  const url = `${defaultRoot}-timeline/approve`
  return await sendRequest<string, { SpecialProgramId: string }>(url, 'POST', { SpecialProgramId: id })
}

export const postSubmit = async (id: string) => {
  const url = `${defaultRoot}-timeline/submit`
  return await sendRequest<string, { SpecialProgramId: string }>(url, 'POST', { SpecialProgramId: id })
}

export const postFinalizeContent = async (body: IPostFinalizeContent) => {
  const url = `${defaultRoot}/content-addition-completed`
  return await sendRequest<string, IPostFinalizeContent>(url, 'POST', body)
}

export const putSpecialProgram = async (body: FormData, id: string) => {
  const url = `${defaultRoot}/${id}`
  return await sendRequest<{ Id: string }, FormData>(url, 'PUT', body)
}

export const postContent = async (body: IPostContent) => {
  const url = `special-programs-content`
  return await sendRequest<string, IPostContent>(url, 'POST', body)
}

export const getSpecialProgram = async (body: IBodyGET) => {
  let url = `${defaultRoot}`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Name) url += `&Text=${body.Name}`;
  if (body?.Type) url += `&SpecialProgramStatus=${body.Type}`;
  if (body?.Id) url += `&Id=${body.Id}`;
  if (body?.GetChallenges) url += `&GetChallenges=${body.GetChallenges}`;
  if (body?.ContentAdditionCompleted) url += `&ContentAdditionCompleted=${body.ContentAdditionCompleted}`;
  if (body?.SpecialProgramEstado) url += `&SpecialProgramEstado=${body.SpecialProgramEstado}`;
  if (body?.ParticipantId) url += `&ParticipantId=${body.ParticipantId}`;
  if (body?.AuthorId) url += `&AuthorId=${body.AuthorId}&ByAuthor=true`;
  url += `&OrderByProperty=-Id`;
  return await sendRequest<ISpecialProgram[], IBodyGET>(url, 'GET')
}

export const postSpecialProgram = async (body: IPostSpecialProgram, data: FormData) => {
  let url = `${defaultRoot}?Title=${body.Title}&Description=${body.Description}&AverageRate=${body.AverageRate}&Language=${body.Language}&Attempts=${body.Attempts}`

  if (body?.Tags) {
    body?.Tags.map((item) => {
      url += `&Tags=${item}`;
    })
  }

  if (body?.Emails) {
    body?.Emails.map((item) => {
      url += `&Emails=${item}`;
    })
  }

  return await sendRequest<{ Id: string }, FormData>(url, 'POST', data)
}
