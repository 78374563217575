import { STR } from '../../assets/Strings'
import { PARAMS_SERVICE_URL, USER_GET } from '../../config/constants'
import { IUser } from '../../contexts/Auth/types'
import Logger from '../../utils/Logger'
import { getLocalStorage } from '../../utils/StorageLocal'
import { getRefreshToken, logout } from '../Auth'
import { IBaseResponse, IMethod } from './types'

const apiUrl = PARAMS_SERVICE_URL
const headers = new Headers()

function appendHeader(prop: string, value: string): void {
  if (headers.has(prop)) {
    headers.set(prop, value)
  } else {
    headers.append(prop, value)
  }
}

function removeHeader(key: string) {
  if (headers.has(key)) {
    headers.delete(key);
  }

}

export async function sendRequest<TResponse, TRequest = unknown>(url: string, method: IMethod, body?: TRequest, removeAuthenticationValidation?: boolean): Promise<IBaseResponse<TResponse>> {
  try {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    let payload: any;

    const user = getLocalStorage<IUser>(USER_GET);
    if (user?.Token) appendHeader('Authorization', `Bearer ${user.Token}`)

    if (method !== 'GET') {
      if (body instanceof FormData) {
        appendHeader('Content-Type', 'multipart/form-data');
        removeHeader('Content-Type');
        payload = body;
      } else {
        appendHeader('Content-Type', 'application/json-patch+json');
        payload = JSON.stringify(body);
      }
    }

    const requestInit: RequestInit = {
      method,
      headers,
      cache: 'no-cache',
      body: payload,
      mode: 'cors'
    }

    Logger('url', url);
    Logger('init', requestInit);
    const result = await fetch(`${apiUrl}/${url}`, requestInit)

    verifyStatus(result);
    if (result.status === 401 && user && !removeAuthenticationValidation) {
        const responseRefresh = await getRefreshToken()
        if (responseRefresh.Success) {
          return sendRequest<TResponse, TRequest>(url, 'POST', body)
        } else {
          logout()
          throw mountError(STR.REQUEST_EXCEPTION.UNAUTHORIZED);
        }
    }

    try {
      const data: IBaseResponse<TResponse> = await result.json()
      if (result.ok) {
        data.Success = true;
      } else {
        data.Success = false;
      }
      Logger('res', data);
      return data
    } catch (err) {
      throw mountError(STR.REQUEST_EXCEPTION.APP_JSON_PROCESS_ERROR);
    }
  } catch (err) {
    console.error('Error', err);
    throw mountError(STR.REQUEST_EXCEPTION.NETWORK_ERROR);
  }
}

const verifyStatus = (result: Response) => {
  if (result.status === 500) {
    mountError(STR.REQUEST_EXCEPTION.API_INTERNAL_ERROR);
  }

  if (result.status === 404) {
    mountError(STR.REQUEST_EXCEPTION.NOT_FOUND_ERROR);
  }
}

const mountError = (message: string): IBaseResponse<undefined> => {
  return {
    Data: undefined,
    Message: message,
    ErrorDetails: message,
    Errors: [],
    Success: false
  };
}

export async function getJson<TResponse>(url: string) {
  return await sendRequest<TResponse>(url, 'GET')
}

export async function postJson<TRequest, TResponse>(url: string, body: TRequest) {
  return await sendRequest<TResponse, TRequest>(url, 'POST', body)
}

export async function putJson<TRequest, TResponse>(url: string, body: TRequest) {
  return await sendRequest<TResponse, TRequest>(url, 'PATCH', body)
}

export async function deleteJson<TRequest, TResponse>(url: string, body?: TRequest, removeAuthenticationValidation?: boolean) {
  return await sendRequest<TResponse, TRequest>(url, 'DELETE', body, removeAuthenticationValidation)
}
