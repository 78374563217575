import React from 'react';
import { useTranslation } from 'react-i18next';
import { Cancel, Check, Close, Error, VolunteerActivism } from '@mui/icons-material'
import * as S from './styles'
import { IContacts } from '../../services/Contacts/types';
import { DataMaskFormtData } from '../../utils/Mask'
import ButtonMaterial from '../../components/Button'
import { theme } from '../../styles/theme'
import noImage from '../../assets/images/noImage.png'

interface IProps {
  item: IContacts
  onClick?: () => void
  tab: number
  onClickRefuse?: () => void
}

const CardContact: React.FC<IProps> = ({ item, tab, onClick, onClickRefuse }) => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Image src={item.ProfilePictureUrl ? item.ProfilePictureUrl : noImage} />

      <div className='boxContent'>
        <div className='boxText'>
          <h1 className='title'>{item.Name}</h1>
          <h1 className='subTitle'>{item.Email}</h1>
          <h1 className='data'>{`${tab === 0 ? t('Contato desde') : ''}${tab === 1 ? t('Convite enviado em') : ''}${tab === 2 ? t('Convite recebido em') : ''}: ${DataMaskFormtData(item?.InviteDate ?? item?.AcceptanceDate)}`}</h1>
        </div>

        <div className='boxButtons'>
          {tab === 0 && (
            <ButtonMaterial
              style={{ borderColor: theme.colors.seventh, borderRadius: '50px  8px 8px 50px', color: theme.colors.secundary2, backgroundColor: theme.colors.seventh2 }}
              variant="outlined"
              label={t('DOAR PONTOS')}
              icon={<VolunteerActivism style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.seventh }} />}
              onClick={onClick}
            />
          )}

          {tab === 1 && (
            <>
              {item?.Status === 1 &&
                <ButtonMaterial
                  style={{ borderColor: theme.colorsBase.yellow, borderRadius: '4px', color: theme.colors.secundary2, backgroundColor: theme.colorsBase.yellow2 }}
                  variant="outlined"
                  label={t('PENDENTE')}
                  icon={<Error style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.yellow }} />}
                  disable={true}
                />
              }
              {item?.Status === 2 &&
                <ButtonMaterial
                  style={{ borderColor: theme.colorsBase.red2, borderRadius: '4px', color: theme.colors.secundary2, backgroundColor: theme.colorsBase.red3 }}
                  variant="outlined"
                  label={t('RECUSADO')}
                  icon={<Cancel style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.red2 }} />}
                  disable={true}
                />
              }
            </>
          )}

          {tab === 2 && (
            <div className='Buttons'>
              <ButtonMaterial
                style={{ borderColor: theme.colors.secundary2, borderRadius: '4px', color: theme.colors.secundary2, backgroundColor: theme.colorsBase.red3, textTransform: 'none' }}
                variant="outlined"
                label={t('Recusar')}
                icon={<Close style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.red2 }} />}
                onClick={onClickRefuse}
              />

              <ButtonMaterial
                style={{ borderColor: theme.colors.secundary2, borderRadius: '4px', color: theme.colors.secundary2, backgroundColor: theme.colorsBase.green4, marginLeft: '10px', textTransform: 'none' }}
                variant="outlined"
                label={t('Aceitar')}
                icon={<Check style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.green3 }} />}
                onClick={onClick}
              />
            </div>
          )}
        </div>
      </div>
    </S.Container>
  )
}

export default CardContact;
