import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../../../components/Layout/PageContainer';
import { theme } from '../../../styles/theme';
import HeaderAchievements from '../../../components/HeaderAchievements';
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../../hooks/useIsSm';
import { ClosedCaption, MenuBook, PlayCircleFilled, RotateRight, Star } from '@mui/icons-material';
import ToastNotification from '../../../components/ToastNotification';
import ViewError from '../../../components/ViewError';
import { Pagination } from '@mui/material';
import { IChallengeItem } from '../../../components/ChallengeItemDesktop/types';
import { Pages } from '../../ViewChallenge/styles';
import { getSpecialProgram, getSpecialProgramDetails } from '../../../services/SpecialProgram';
import { ICardSpecialProgram, ISpecialProgram } from '../../../pages/SpecialProgramManagement/ScreenUnderConstruction/types';
import SpecialProgramDetailCard from '../../../components/SpecialProgramDetailCard';
import imageCertificate from '../../../assets/images/certificate.png'
import ButtonMaterial from '../../../components/Button';
import ChallengeItemDesktop from '../../../components/ChallengeItemDesktop';
import ModalCustom from '../../../components/ModalCustom';
import ModalCustomBottom from '../../../components/ModalCustomBottom';
import ChallengeDetails from '../../../components/ChallengeDetails';
import { IChallengeAccomplished } from '../../../services/Challenges/types';
import { getChallengesDataPerformed, postStartChallenge } from '../../../services/Challenges';
import { useAuth } from '../../../contexts/Auth';

const SpecialProgramContentView: React.FC = () => {
  const { t } = useTranslation();
  const isSm = useIsSm();
  const { setListContents } = useAuth();
  const navigate = useNavigate();
  const { token } = useParams();
  const [specialProgramList, setSpecialProgramList] = useState<IChallengeItem[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [specialProgram, setSpecialProgram] = useState<ICardSpecialProgram>();
  const [specialProgramListTotal, setSpecialProgramListTotal] = useState<IChallengeItem[]>([]);
  const [openDetailsModal, setOpenDetailsModal] = useState(false)
  const [itemSelected, setItemSelected] = useState<IChallengeAccomplished>()
  let errorRedoProgram = false;
  const handlePageContent = (page: number, listTotal?: IChallengeItem[]) => {
    const list = listTotal ?? specialProgramListTotal;

    if (isSm) {
      setSpecialProgramList(list)
    } else {
      const startIndex = (page - 1) * 2;
      const endIndex = startIndex + 2;

      setSpecialProgramList(list.slice(startIndex, endIndex));

      setTotalPages(Math.ceil(list.length / 2))
      setCurrentPage(page)
    }
  };

  useEffect(() => {
    retrieveSpecialProgram(true);
    errorRedoProgram = false;
  }, []);

  useEffect(() => {
    retrieveSpecialProgram(true);
  }, [isSm]);

  function convertSpecialProgram(program: ISpecialProgram, i: number, data?: { ContentExecuntionId?: number }[]): IChallengeItem {
    return {
      CoverImageUrl: program?.CoverImageUrl ?? '',
      Title: program?.Title,
      AuthorName: program?.AuthorName,
      Language: String(program?.Language),
      Id: program?.Id,
      Status: program?.ChallengeStatus,
      EstimatedTime: program?.EstimateTime,
      Started: program?.Started,
      Finished: program?.Finished,
      ContentExecuntionId: data ? data[i]?.ContentExecuntionId : 0
    };
  }

  const retrieveSpecialProgram = async (clear: boolean = false) => {
    try {
      if (clear) {
        setSpecialProgramListTotal([]);
      }
      const bodyData = {
        PageNumber: 1,
        PageSize: 1,
        GetChallenges: true,
        Id: token
      };
      const response = await getSpecialProgram(bodyData);
      if (response.Success) {
        const dataTemp = await handleDetails(token ?? '');

        setSpecialProgram({
          language: String(response.Data[0].Language),
          image: response.Data[0].Image,
          averagePerformance: response.Data[0].AverageRate,
          tags: response.Data[0].Tags,
          description: response.Data[0].Description,
          emailList: response.Data[0].EmailList,
          mockData: specialProgram,
          id: response.Data[0].Id,
          title: response.Data[0].Title,
          coverImageUrl: response.Data[0].Image,
          ChallengeStatus: response.Data[0].Status,
          attempts: String(response.Data[0].Attempts),
          AverageRate: response.Data[0].AverageRate,
          PercentageOfCorrectAswers: dataTemp?.PercentageOfCorrectAswers,
          ResumeSpecialProgramStatus: dataTemp?.ResumeSpecialProgramStatus,
          RemainingAttempts: response.Data[0].RemainingAttempts,
          DurationTime: response.Data[0].DurationTime,
        });

        const responseAPI: ISpecialProgram[] = response.Data[0]?.Challenges
        const mapperSpecialPrograms = responseAPI.map((item, i) => convertSpecialProgram(item, i, dataTemp?.Contents))

        setSpecialProgramListTotal(mapperSpecialPrograms);
        handlePageContent(1, mapperSpecialPrograms);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os programas, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os programas, tente novamente.'),
      });
    }
  };

  const handleDetails = async (id: string) => {
    try {
      const response = await getSpecialProgramDetails(id);
      if (response?.Success) {
        return response?.Data
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os detalhes do programa, tente novamente.'),
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os detalhes do programa, tente novamente.'),
      });
    }
  };

  const initializedContent = async (id: string) => {
    try {
      const response = await postStartChallenge(id);
      if (!response.Success) {
        errorRedoProgram = true;
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao iniciar o desafio, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao iniciar o desafio, tente novamente.')
      });
    }
  };

  const handleAttempts = async () => {
    await Promise.all(
      specialProgramListTotal?.map((item) => initializedContent(item?.Id ?? ''))
    );
    if (!errorRedoProgram) {
      navigate(`/programas_especiais/1`);
    }
  };

  const retrieveDataChallengePerformed = async (id?: string) => {
    try {
      const response = await getChallengesDataPerformed(id);
      if (response.Success) {
        setItemSelected(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
  };

  const getButtonLabel = (status: number) => {
    switch (status) {
      case 1:
        return "INICIAR CONTEÚDO";
      case 2:
        return "RETOMAR CONTEÚDO";
      case 3:
        return "VER DETALHES";
      default:
        return "INICIAR CONTEÚDO";
    }
  };

  const getLabel = () => {
    switch (specialProgram?.ResumeSpecialProgramStatus) {
      case 0:
        return `Ao completar o desafio com um desempenho maior ou igual a ${specialProgram?.AverageRate}%, você receberá um certificado por email!`;
      case 1:
        return `Ao completar o desafio com um desempenho maior ou igual a ${specialProgram?.AverageRate}%, você receberá um certificado por email!`;
      case 2:
        return `Parabéns! Você finalizou todos os Conteúdos desse Programa Especial com nota acima da média! Você receberá um certificado no seu E-mail.`;
      case 3:
        return Number(specialProgram?.RemainingAttempts) > 0 ? `Você ainda não recebeu um certificado! Tente novamente!` : `Você não recebeu um certificado!`;
      default:
        return `Ao completar o desafio com um desempenho maior ou igual a ${specialProgram?.AverageRate}%, você receberá um certificado por email!`;
    }
  };

  const renderSituation = (item: IChallengeItem) => {
    if (item?.Finished) {
      return 2;
    } else if (item?.Started && !item?.Finished) {
      return 1;
    } else {
      return 0;
    }
  };

  const renderSituationButton = (item: IChallengeItem) => {
    if (item?.Finished) {
      return <ButtonMaterial
        style={{ borderColor: theme.colors.seventh, color: theme.colors.seventh, width: '90%', margin: '0 auto' }}
        icon={<PlayCircleFilled style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.seventh }} />}
        variant="outlined"
        label={t('VER detalhes')}
      />;
    } else if (item?.Started && !item?.Finished) {
      return <ButtonMaterial
        style={{ backgroundColor: theme.colors.seventh, width: '90%', margin: '0 auto' }}
        icon={<PlayCircleFilled style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.white }} />}
        variant="contained"
        label={t('RETOMAR conteúdo')}
      />;
    } else {
      return <ButtonMaterial
        style={{ backgroundColor: theme.colors.seventh, width: '90%', margin: '0 auto' }}
        icon={<PlayCircleFilled style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.white }} />}
        variant="contained"
        label={t('iniciar conteúdo')}
      />;
    }
  };

  const handleButton = (item: IChallengeItem, index: number) => {
    if (item?.Finished) {
      retrieveDataChallengePerformed(String(item?.ContentExecuntionId))
      setOpenDetailsModal(true)
    } else if (item?.Started && !item?.Finished) {
      handleNextContent(index);
      navigate(`/desafio/${item.Id}/PE`);
    } else {
      handleNextContent(index);
      navigate(`/desafio/${item.Id}/PE`);
    }
  };

  const handleNextContent = (index: number) => {
    const nextItems = specialProgramListTotal.slice(index + 1);

    const listTemp: string[] = [];

    nextItems.map((item) => {
      if (!item.Finished) {
        listTemp.push(item.Id ?? '')
      }
    })

    setListContents(listTemp)
  };

  return (
    <PageContainer>
      <HeaderAchievements
        title={t('Programa Especial')}
        marginBottom={isSm ? 24 : 0}
        icon={<Star style={{ color: theme.colors.seventh }} />}
      />

      <S.Container>
        <S.ContainerCard>
          <h1 className='titleDetails'>{specialProgram?.title}</h1>

          {specialProgram?.ResumeSpecialProgramStatus !== 2 && <>
            {specialProgram?.ResumeSpecialProgramStatus !== 3 ?
              <>
                <div className='boxSubTitle'>
                  <MenuBook style={{ color: theme.colors.black, marginRight: '8px' }} />
                  <h2 className='subTitleDetails'>Tenha certeza de analisar o conteúdo com cuidado antes de responder às perguntas!</h2>
                </div>

                {isSm && <div className='boxSubTitle'>
                  <ClosedCaption style={{ color: theme.colors.black, marginRight: '8px' }} />
                  <h2 className='subTitleDetails'>Caso precise de legendas, não se esqueça de ativar a função de acessibilidade no seu celular!</h2>
                </div>}
              </>
              :
              <div className='boxSubTitle' style={{ justifyContent: 'flex-start' }}>
                <RotateRight style={{ color: theme.colors.black, marginRight: '8px' }} />
                <h2 className='subTitleDetails'>Chances: {specialProgram?.RemainingAttempts}</h2>
              </div>
            }
          </>}

          {specialProgram && <SpecialProgramDetailCard
            specialProgram={specialProgram}
            handleEdit={() => { }}
            handleFinalizeContent={() => { }}
            typePermission={1}
            triage
            noShadow={true}
            participant
          />}

          <S.ContainerCertificate style={{ backgroundColor: (specialProgram?.ResumeSpecialProgramStatus === 3 && Number(specialProgram?.RemainingAttempts) <= 0) ? '#FFCCCD' : 'none' }}>
            <div className='boxDescription'>
              <img src={imageCertificate} className='imgCertificate' style={{ filter: (specialProgram?.ResumeSpecialProgramStatus === 3 && Number(specialProgram?.RemainingAttempts) <= 0) ? 'grayscale(100%) brightness(0)' : 'none' }} />

              <h2 className='titleCertificate'>{getLabel()}</h2>
            </div>

            {(specialProgram?.ResumeSpecialProgramStatus === 3 && Number(specialProgram?.RemainingAttempts) > 0) && <ButtonMaterial
              style={{ backgroundColor: theme.colorsBase.green2, whiteSpace: "nowrap", padding: '4px 32px' }}
              variant='contained'
              label={t('REFAZER PROGRAMA')}
              onClick={handleAttempts}
            />}
          </S.ContainerCertificate>
        </S.ContainerCard>

        <S.ContainerCard>
          <h1 className='title'>Conteúdos:</h1>

          {specialProgramList.length > 0 ? (
            <>
              <S.ContainerChallenge>
                {specialProgramList.map((item, i) => (
                  <ChallengeItemDesktop
                    key={item.Id}
                    item={{ ...item, Status: renderSituation(item) }}
                    onClick={() => handleButton(item, i)}
                    typeChallenge={12}
                    hidenDifficultyInfo
                    labelButton={getButtonLabel(item.Status ?? 0)}
                    showSituation
                    showAuthorName
                    buttonCustom={renderSituationButton(item)}
                    style={{ width: '280px' }}
                  />
                ))}
              </S.ContainerChallenge>

              {!isSm && (
                <Pages>
                  <Pagination
                    showFirstButton
                    showLastButton
                    count={totalPages}
                    page={currentPage}
                    color="primary"
                    onChange={(e, p) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handlePageContent(p)
                    }}
                  />
                </Pages>
              )}
            </>
          ) : (
            <ViewError message={t('Não há conteúdos disponíveis')} />
          )}
        </S.ContainerCard>
      </S.Container>

      {!isSm ?
        <ModalCustom
          open={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          onClick={() => { }}
          childrenContent={
            <ChallengeDetails item={itemSelected} onClick={() => { }} type={0} content />
          }
          title={t('Detalhes')}
          buttonsEnabled={false}
        />
        :
        <ModalCustomBottom
          open={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          title={t('Detalhes')}
          childrenContent={
            <ChallengeDetails item={itemSelected} onClick={() => { }} type={0} content />
          }
        />
      }
    </PageContainer>
  );
};

export default SpecialProgramContentView;
