import React from 'react';

import iconEN from '../../assets/images/en.png'
import iconES from '../../assets/images/es.png'
import iconFR from '../../assets/images/fr.png'
import iconPT from '../../assets/images/pt.png'
import { SelectedLanguage } from './styles';

const renderTextLanguage = (type: number) => ({
  1: "PT",
  2: "EN",
  3: "ES",
  4: "FR",
}[type])

const renderImageLanguage = (type: number) => ({
  1: iconPT,
  2: iconEN,
  3: iconES,
  4: iconFR
}[type])

interface IProps {
  Idioma: number
  fillSpace?: boolean,
  style?: React.CSSProperties;
}

const LanguageFlag: React.FC<IProps> = ({ Idioma, fillSpace = true, style }) => (
  <SelectedLanguage active={fillSpace} style={style}>
    <img src={renderImageLanguage(Idioma)} className="flag" />
    <h5>{renderTextLanguage(Idioma)}</h5>
  </SelectedLanguage>
)


export default LanguageFlag;
