import React, { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';

import ModalCustom from '../ModalCustom';
import QuizForm from '../forms/QuizForm';
import ModalButton from '../ModalButton';
import { useCreateQuizMutation } from '../../hooks/useCreateQuizMutation';
import { EStatusPost, IGetChallengesTriageResponse } from '../../services/Moderation/types';

import { Content, Title } from './styles';

const CREATE_QUIZ_IN_TRIAGE = false

interface IProps {
  data: IGetChallengesTriageResponse;
  open: boolean;
  onClose: () => void;
}

const QuizModalOld: React.FC<IProps> = ({ data, open, onClose }) => {
  const [index, setIndex] = useState(0)
  const pergunta = data.Conteudo.Pergunta.at(index)

  const { mutateAsync } = useCreateQuizMutation()

  return (
    <ModalCustom
      open={open}
      onClick={() => { }}
      onClose={onClose}
      buttonsEnabled={false}
      title="Visualizar Quiz"
      childrenContent={(
        <Stack width="100%" spacing={2}>
          {data.Conteudo.Pergunta.length > 0 ? <>
            <Box>
              <Title>Pergunta</Title>
              <Content>{pergunta?.Pergunta}</Content>
            </Box>
            <Box>
              <Title>Resposta Correta</Title>
              <Content>{pergunta?.Resposta.find((r) => r.RespostaCorreta)?.Resposta ?? "N/A"}</Content>
            </Box>
            <Box>
              <Title>Outras Alternativas</Title>
              <Stack direction='row' spacing={2}>
                {pergunta?.Resposta.map(({ RespostaId, Resposta }) => (
                  <Content key={RespostaId}>{Resposta}</Content>
                ))}
              </Stack>
            </Box>
            <Box>
              <Title>Audio da Pergunta</Title>
              <Content>N/A</Content>
            </Box>

            <Stack justifyContent='space-between' direction='row'>
              <Button
                variant="outlined"
                disabled={index === 0}
                onClick={() => setIndex(prev => prev - 1)}
              >
                PERGUNTA ANTERIOR
              </Button>
              <Button
                variant="outlined"
                disabled={index >= data.Conteudo.Pergunta.length - 1}
                onClick={() => setIndex(prev => prev + 1)}
              >
                PRÓXIMA PERGUNTA
              </Button>
            </Stack>
          </> : (
            <Stack height="300px" justifyContent="center" alignItems="center">
              <Content>O desafio ainda não possui um quiz.</Content>
            </Stack>
          )}

          {data.AdicionarQuiz && data.Status === EStatusPost.EmTriagem && CREATE_QUIZ_IN_TRIAGE ? <Stack>
            <ModalButton
              variant="outlined"
              disabled={!data.AdicionarQuiz}
              buttonChildren="INSERIR QUIZ"
            >
              {({ open, onClose }) => (
                <QuizForm
                  open={open}
                  onSubmit={(submitData) => mutateAsync({ ...submitData, challengeId: data.DesafioId })}
                  onClose={onClose}
                />
              )}
            </ModalButton>
          </Stack> : null}
        </Stack>
      )}
    />
  )
}

export default QuizModalOld;
