import styled from 'styled-components'
import { theme } from '../../styles/theme'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Table = styled.div`
  width: 100%;
  margin-top: 24px;
  min-height: 380px;
  overflow: auto;

  &::-webkit-scrollbar {
    border-radius: 8px;
    width: 4px;
    background: ${(props) => props.theme.colors.sixteen};
    }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.sixteen};
    border-radius: 8px;
  }
  ::-webkit-scrollbar:horizontal { 
    height: 4px;
  }


  table {
    width: 100%;
    border: none;
    border-collapse: collapse;

    tr:nth-child(2n) {
      background: ${(props) => props.theme.colors.seventeen};
    }
    tr {
      height: 40px;
      width: 150px;
    }
    td {
     padding-right: 16px;
     max-width: 300px;
     white-space: nowrap;
     text-overflow: ellipsis;
     overflow: hidden;
    }
    .header {
      height: 40px;
      margin-bottom: 40px;
      text-align: left;
      border-bottom: solid 2px ${(props) => props.theme.colors.secundary};
    }

    .body-item{
      height: 60px;
    }

    .itemBlocked {
      color: red;
    }
  }
`

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 80%;

  button {
    border: none;
    background: none;

    width: auto;
    height: auto;
  }

  .i {
    color: #201E1E;
    font-size: 24px;

    &:hover {
      color: ${theme.colors.black};
    }

  }
  
  .iconBlocked {
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
  }

  .iconDisable {
    opacity: 0.5;
    color: rgba(0, 0, 0, 0.7);
    font-size: 24px;
  }

  .iconDisableBlocked {
    opacity: 0.5;
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
  }
`

export const ContainerEditCategories = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .InputContainer {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h2 {
      margin-left: 12px;
      line-height: 24px;
      letter-spacing: 0.15px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.black};
      opacity: 0.6;
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(14)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    h1 {
      margin-left: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(10)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  }

  .switchDivContainer {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h3 {
      margin-left: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(10)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

    .switchDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      h5 {
        line-height: 20px;
        letter-spacing: 0.15px;
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colors.black};
        opacity: 0.6;
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }
    }
  }
`

export const BoxSelectUser = styled.div`
margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 60%;
    margin-left: 40px;

    h3 {
      margin-left: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(10)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

    .switchDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      h5 {
        line-height: 20px;
        letter-spacing: 0.15px;
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colors.black};
        opacity: 0.6;
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }
    }
  
`

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`

export const TableS = styled.div`
  width: 100%;

  min-height: 280px;
  overflow: auto;

  &::-webkit-scrollbar {
    border-radius: 8px;
    width: 4px;
    background: ${(props) => props.theme.colors.sixteen};
    }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.sixteen};
    border-radius: 8px;
  }
  ::-webkit-scrollbar:horizontal { 
    height: 4px;
  }


  table {
    width: 100%;
    border: none;
    border-collapse: collapse;

    tr:nth-child(2n) {
      background: ${(props) => props.theme.colors.seventeen};
    }
    tr {
      height: 40px;
      width: 150px;
    }
    td {
     padding-right: 16px;
     max-width: 300px;
     white-space: nowrap;
     text-overflow: ellipsis;
     overflow: hidden;
    }
    .header {
      height: 40px;
      margin-bottom: 40px;
      text-align: left;
      border-bottom: solid 2px ${(props) => props.theme.colors.secundary};
    }

    .body-item{
      height: 60px;
    }

    .itemBlocked {
      color: red;
    }
  }
`

export const IconsS = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 80%;

  button {
    border: none;
    background: none;

    width: auto;
    height: auto;
  }

  .i {
    color: #201E1E;
    font-size: 24px;

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }

  }
  
  .iconBlocked {
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
  }

  .iconDisable {
    opacity: 0.5;
    color: rgba(0, 0, 0, 0.7);
    font-size: 24px;
  }

  .iconDisableBlocked {
    opacity: 0.5;
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
  }
`

export const ContainerBackButtonB = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  padding: 0px 24px 24px 24px;

    &:hover {
      opacity: 0.8;
    }

    .iconBackButton {
      color: ${(props) => props.theme.colorsBase.white};
      width: 24px;
      height: 24px;
      background-color: ${(props) => props.theme.colors.seventh};
      border-radius: 50%;
    }

    h5 {
      margin-left: 8px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  
    h3 {
      margin-left: 32px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(16)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
    

  @media (max-width: 680px) {
    padding: 16px;
  }
`

export const ContainerBackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  padding: 24px;

    &:hover {
      opacity: 0.8;
    }

    .iconBackButton {
      color: ${(props) => props.theme.colorsBase.white};
      width: 24px;
      height: 24px;
      background-color: ${(props) => props.theme.colors.seventh};
      border-radius: 50%;
    }

    h5 {
      margin-left: 8px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  
    h3 {
      margin-left: 32px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(16)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }


  @media (max-width: 680px) {
    padding: 16px;
  }
`

export const BackgroundImageProfile = styled.label`
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 50%;
  position: relative;
  margin-bottom: 16px;

   img {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: solid 2px ${(props) => props.theme.colors.secundary};
    z-index: 1;
   }

  .iconBackground {
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.secundary};
    z-index: 1;
    .iconCam {
      color: ${(props) => props.theme.colorsBase.white};
      width: 16px;
      height: 16px;
      align-self: center;
    }
  }
`

export const Input = styled.input`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`

export const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  // border: solid 1px ${(props) => props.theme.colors.secundary};
  z-index: 1;
  margin: 8px 0px 4px 0px;
`