import React from 'react';
import * as S from './styles'
import { ArrowBack, OpenInNew } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { theme } from '../../styles/theme';
import Footer from '../../components/Footer'
import HeaderAchievements from '../../components/HeaderAchievements';
import { useAuth } from '../../contexts/Auth'
import curva from '../../assets/images/curva7.png'


const OpenExternalLink: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { link } = useAuth()

  return (
    <S.Container>
      <img src={curva} className="curvaN" alt="" />

      <HeaderAchievements
        title={t('Link Externo')}
        onClick={() => navigate(-1)}
        buttonBack={false}
        colorTitle={theme.colors.secundary}
        noMargin={true}
        buttonFilter={false}
      />


      <S.ContainerContent>
        <S.ContainerDescription>
          <h1>{t('Parece que a página que você quer acessar não faz parte do Curar Saúde!')}</h1>
          <h2>{t('Nosso sistema não endossa e nem assume responsabilidade pelo conteúdo desse link.')}</h2>
        </S.ContainerDescription>

        <S.ContainerBackButton>
          <button onClick={() => navigate(-1)}>
            <ArrowBack className="iconBackButton" />
            <h5>{t('Voltar para o Curar Saúde')}</h5>
          </button>
        </S.ContainerBackButton>

        <S.ContainerNewTab onClick={() => window.location.replace(`${link}`)}>
          <OpenInNew className="icon" />
          <div className='container'>
            <div className='box'>
              <h1>{t('Continuar para')}:</h1>
              <h3>{link}</h3>
            </div>
            <h4>{t('Você será redirecionado para a página externa.')}</h4>
          </div>
        </S.ContainerNewTab>
      </S.ContainerContent>

      <div className='containerButtonFooter'>
        <Footer />
      </div>
    </S.Container>
  )
}

export default OpenExternalLink
