import React, { useEffect, useState, useCallback } from 'react';
import * as S from './styles';
import { theme } from '../../../../styles/theme';
import { useTranslation } from 'react-i18next';
import ToastNotification from '../../../../components/ToastNotification';
import ViewError from '../../../../components/ViewError';
import { useIsSm } from '../../../../hooks/useIsSm';
import ModalCustom from '../../../../components/ModalCustom';
import SpecialProgramDetailCard from '../../../../components/SpecialProgramDetailCard';
import ParticipantsList from '../../../../components/ParticipantsList';
import { KeyboardArrowLeft, KeyboardArrowRight, Info, ForwardToInbox, Star } from '@mui/icons-material';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ChallengeItemDesktop from '../../../../components/ChallengeItemDesktop';
import { IChallengeItem } from '../../../../components/ChallengeItemDesktop/types';
import { getSpecialProgram, postApprove, postBlocked, postReject, postSubmit, putSpecialProgram } from '../../../../services/SpecialProgram';
import { ICardSpecialProgram } from '../types';
import ModalInviteParticipants from '../../SpecialProgramDetails/ModalInviteParticipants';
import ButtonMaterial from '../../../../components/Button';
import HeaderMobile from '../../../../components/HeaderMobile';
import { Modal } from '@mui/material';
import HeaderAchievements from '../../../../components/HeaderAchievements';
import ModalEditSpecialProgram from '../../SpecialProgramDetails/ModalEditSpecialProgram';
import DisapproveProgramModal from './DisapproveProgramModal';
import BlockProgramModal from './BlockProgramModal';
import ChallengeDetailsModal from '../../SpecialProgramDetails/ScreenSpecialProgramContents/ChallengeDetailsModal';

interface IProps {
  open: boolean;
  setOpen: (e: boolean) => void;
  id: string;
  update: () => void;
  triage?: boolean;
}

const ModalSpecialProgram: React.FC<IProps> = ({ open, setOpen, id, update, triage }) => {
  const { t } = useTranslation();
  const isSm = useIsSm();
  const [specialProgramList, setSpecialProgramList] = useState<IChallengeItem[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [specialProgram, setSpecialProgram] = useState<ICardSpecialProgram>();
  const [openParticipants, setOpenParticipants] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isDisapproveModalOpen, setIsDisapproveModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  const handleToastError = () => {
    ToastNotification({
      id: 'error',
      type: 'error',
      message: t('Houve um erro, tente novamente.')
    });
  }

  const handleResize = useCallback(() => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    if (scrollY + windowHeight >= documentHeight - 100) {
      if ((currentPage <= (totalPages || 0)) && isSm) {
        retrieveSpecialProgram(currentPage + 1, false);
      }
    }
  }, [currentPage, totalPages, isSm]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (open) {
      retrieveSpecialProgram(1, true);
    }
  }, [open]);

  function getStatusText(status: number): 'approved' | 'rejected' | 'under_review' {
    switch (status) {
      case 5:
        return 'approved';
      case 7:
        return 'rejected';
      case 3:
        return 'under_review';
      default:
        return 'under_review'; // Padrão para qualquer outro valor não mapeado
    }
  }

  const retrieveSpecialProgram = async (page: number = 1, clear: boolean = false) => {
    try {
      setOpenEdit(false)
      if (clear) {
        setSpecialProgramList([]);
      }
      const bodyData = {
        PageNumber: 1,
        PageSize: 1,
        Id: id,
        GetChallenges: true
      };
      const response = await getSpecialProgram(bodyData);
      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }
        setSpecialProgramList(response.Data[0]?.Challenges);
        setCurrentIndex(0); // Reset carousel index

        setSpecialProgram({
          language: String(response.Data[0].Language),
          image: response.Data[0].Image,
          averagePerformance: response.Data[0].AverageRate,
          tags: response.Data[0].Tags,
          description: response.Data[0].Description,
          emailList: response.Data[0].EmailList,
          mockData: specialProgram,
          id: response.Data[0].Id,
          title: response.Data[0].Title,
          coverImageUrl: response.Data[0].Image,
          status: getStatusText(response.Data[0].Status),
          attempts: String(response.Data[0].Attempts),
          DurationTime: response.Data[0].DurationTime,
        });
      } else {
        handleToastError();
      }
    } catch (ex) {
      handleToastError();
    }
  };

  const submitProgram = async () => {
    setLoading(true)
    try {
      const response = await postSubmit(id);
      if (response.Success) {
        setOpen(false)
        update();
      } else {
        handleToastError();
      }
    } catch (ex) {
      handleToastError();
    }
    setLoading(false)
  };

  const handleSendInvitations = async (index: number) => {
    try {
      const updatedEmails: string[] = specialProgram?.emailList;
      updatedEmails.splice(index, 1);

      const data = new FormData();
      updatedEmails.forEach(e => data.append('SpecialProgram.Emails', e));
      data.append('SpecialProgram.Title', specialProgram?.title ?? '');
      data.append('SpecialProgram.Description', specialProgram?.description ?? '');
      data.append('SpecialProgram.AverageRate', specialProgram?.averagePerformance ?? '');
      data.append('SpecialProgram.Language', specialProgram?.language ?? '');
      data.append('SpecialProgram.Attempts', specialProgram?.attempts ?? '');
      data.append('SpecialProgram.Tags', specialProgram?.tags);

      const response = await putSpecialProgram(data, String(specialProgram?.id));
      if (response.Success) {
        retrieveSpecialProgram(1, true)
        update();
      } else {
        handleToastError();
      }
    } catch (ex) {
      handleToastError();
    }
  };

  const handleDisapproveProgram = async (reason: string) => {
    setLoading(true)
    try {
      if (reason.length < 1) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Insira um Motivo.')
        });
        setLoading(false)
        return;
      }

      const response = await postReject(id, reason);
      if (response.Success) {
        setOpen(false)
        update();
      } else {
        handleToastError();
      }
    } catch (ex) {
      handleToastError();
    }
    setLoading(false)
  };

  const handleBlockProgram = async (reason: string) => {
    setLoading(true)
    try {
      if (reason.length < 1) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Insira um Motivo.')
        });
        setLoading(false)
        return;
      }

      const response = await postBlocked(id, reason);
      if (response.Success) {
        setOpen(false)
        update();
      } else {
        handleToastError();
      }
    } catch (ex) {
      handleToastError();
    }
    setLoading(false)
  };

  const handleApprove = async () => {
    setLoading(true)
    try {
      const response = await postApprove(id);
      if (response.Success) {
        setOpen(false)
        update();
      } else {
        handleToastError();
      }
    } catch (ex) {
      handleToastError();
    }
    setLoading(false)
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    setOpenParticipants(true)
  };

  const handleBlockButtonClick = () => {
    setIsBlockModalOpen(true);
  };

  const handleBlockModalClose = () => {
    setIsBlockModalOpen(false);
  };

  const handleDisapproveButtonClick = () => {
    setIsDisapproveModalOpen(true);
  };

  const handleDisapproveModalClose = () => {
    setIsDisapproveModalOpen(false);
  };

  const handleCloseChallengeModal = () => {
    setSelectedItem("");
  };

  const responsive = {
    0: { items: 0.3 },
    234: { items: 1.1 },
    568: { items: 1.3 },
    1024: { items: 2 },
    1300: { items: 2.6 },
    1600: { items: 3.3 },
    1900: { items: 4 },
  };

  const slideNext = () => {
    if (currentIndex < specialProgramList.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const slidePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const renderPrevButton: React.FC<{ isDisabled: boolean }> = ({ isDisabled }) => (
    <div className="btn-prevB" style={{ top: '-20px', height: '100%', width: '10%' }}>
      <button onClick={slidePrev} style={{ border: 'none', background: 'none', marginLeft: '-40px' }}>
        <KeyboardArrowLeft className="ibtn-prevB" style={{ color: !isDisabled ? theme.colors.seventh : theme.colorsBase.gray3 }} />
      </button>
    </div>
  );

  const renderNextButton: React.FC<{ isDisabled: boolean }> = ({ isDisabled }) => (
    <div className="btn-nextB" style={{ top: '20px', height: '100%', width: '10%', backgroundImage: 'linear-gradient(265.8deg, #FFFFFF 31.36%, rgba(255, 255, 255, 0) 96.39%)' }}>
      <button onClick={slideNext} style={{ border: 'none', background: 'none', marginRight: '-100px' }}>
        <KeyboardArrowRight className="ibtn-nextB" style={{ color: !isDisabled ? theme.colors.seventh : theme.colorsBase.gray3 }} />
      </button>
    </div>
  );

  const returnContent = () => (
    <S.Container>
      {specialProgram &&
        <S.Box>
          <SpecialProgramDetailCard
            specialProgram={specialProgram}
            handleEdit={() => setOpenEdit(true)}
            handleFinalizeContent={() => { }}
            typePermission={5}
            triage={triage}
          />

          <ParticipantsList
            specialProgram={specialProgram}
            handleSendInvitations={handleSendInvitations}
            typePermission={triage ? 4 : 5}
          />
        </S.Box>
      }

      <S.ContainerContent>
        <h5 className='titleContent'>{t('Conteúdos')}</h5>

        {specialProgramList?.length > 0 ? (
          <S.ContainerChallenge>
            <AliceCarousel
              paddingLeft={isSm ? 0 : 40}
              paddingRight={isSm ? 0 : 40}
              mouseTracking
              responsive={responsive}
              disableDotsControls
              renderPrevButton={renderPrevButton}
              renderNextButton={renderNextButton}
              disableButtonsControls={isSm}
              activeIndex={currentIndex}
              onSlideChanged={(e) => setCurrentIndex(e.item)}
              items={specialProgramList?.map((item) => (
                <ChallengeItemDesktop
                  key={item.Id}
                  item={item}
                  showAuthorName
                  showId
                  hidenDifficultyInfo
                  hidenBottonInfo
                  style={{ margin: '0px 8px 8px 8px' }}
                  onClick={() => setSelectedItem(item.Id ?? '')}
                />
              ))}
            />
          </S.ContainerChallenge>
        ) : (
          <ViewError message={t('Nenhum conteúdo encontrado!')} />
        )}
      </S.ContainerContent>

      <S.ContainerButton>
        {!triage && <>
          <ButtonMaterial
            style={{ borderColor: theme.colors.graphicRed, color: theme.colors.graphicRed, width: isSm ? '100%' : 'none' }}
            variant="outlined"
            label={t('Cancelar')}
            onClick={() => setOpen(false)}
          />

          <ButtonMaterial
            style={{ backgroundColor: theme.colorsBase.green2, margin: isSm ? '16px 0 16px 0' : '0 16px 0 16px', width: isSm ? '100%' : 'none' }}
            variant='contained'
            label={t('CONFIRMAR E ENVIAR')}
            onClick={submitProgram}
            loading={loading}
          />

          <ButtonMaterial
            icon={<ForwardToInbox style={{ color: theme.colors.seventh, marginRight: '8px' }} />}
            style={{ borderColor: theme.colors.seventh, color: theme.colors.seventh, width: isSm ? '100%' : 'none' }}
            variant="outlined"
            label={t('CONVIDAR MAIS PARTICIPANTES')}
            onClick={handleAdd}
          />
        </>}

        {triage && <>
          {!isSm && <ButtonMaterial
            style={{ borderColor: theme.colors.seventh, color: theme.colors.seventh, width: isSm ? '100%' : 'none' }}
            variant="outlined"
            label={t('Cancelar')}
            onClick={() => setOpen(false)}
          />}

          <ButtonMaterial
            style={{ backgroundColor: theme.colorsBase.green2, margin: isSm ? '16px 0 16px 0' : '0 16px 0 16px', width: isSm ? '100%' : 'none' }}
            variant='contained'
            label={t('APROVAR')}
            onClick={handleApprove}
            loading={loading}
          />

          <ButtonMaterial
            style={{ borderColor: theme.colors.graphicRed, color: theme.colors.graphicRed, width: isSm ? '100%' : 'none' }}
            variant="outlined"
            label={t('REJEITAR')}
            onClick={handleDisapproveButtonClick}
          />

          <ButtonMaterial
            style={{ backgroundColor: theme.colors.graphicRed, width: isSm ? '100%' : 'none', margin: isSm ? '16px 0 0 0' : '0 0 0 16px' }}
            variant='contained'
            label={t('BLOQUEAR')}
            onClick={handleBlockButtonClick}
          />

          {isSm && <ButtonMaterial
            style={{ borderColor: theme.colors.seventh, color: theme.colors.seventh, width: isSm ? '100%' : 'none', marginTop: '16px' }}
            variant="outlined"
            label={t('cancelar moderação')}
            onClick={() => setOpen(false)}
          />}
        </>}
      </S.ContainerButton>
    </S.Container>
  );

  return (
    <>
      {isSm ? (
        <Modal open={open}>
          <>
            <HeaderMobile />

            <S.ContainerModal>
              <HeaderAchievements
                title={specialProgram?.title ?? ''}
                icon={<Star className='i' style={{ color: theme.colors.secundary }} />}
                buttonBack={true}
                style={{ padding: '0px 0px 32px 0px' }}
                onClick={() => setOpen(false)}
              />

              {isSm && <div className='boxTitle'>
                <Info style={{ color: theme.colors.black5 }} />
                <h5 className='titleMobile'>{triage ? t('Gerenciar Programa Especial') : t('Publicar Programa Especial')}</h5>
              </div>}

              {returnContent()}
            </S.ContainerModal>
          </>
        </Modal>
      ) : (
        <ModalCustom
          open={open}
          onClose={handleCancel}
          onClick={() => setOpen(true)}
          iconHeader={<Info style={{ color: theme.colorsBase.gray3 }} />}
          title={triage ? t('Gerenciar Programa Especial') : t('Publicar Programa Especial')}
          largeTitle
          buttonsEnabled={false}
          childrenContent={returnContent()}
          style={{ height: '100%', width: '85%', maxHeight: '98%', overflow: 'auto' }}
        />
      )}

      <ModalInviteParticipants
        open={openParticipants}
        setOpen={setOpenParticipants}
        program={specialProgram}
        update={() => {
          retrieveSpecialProgram(1, true)
          update();
        }}
      />

      <ModalEditSpecialProgram
        open={openEdit}
        setOpen={setOpenEdit}
        program={specialProgram}
        update={() => {
          retrieveSpecialProgram(1, true)
          update();
        }}
      />

      {isDisapproveModalOpen && (
        <DisapproveProgramModal
          open={isDisapproveModalOpen}
          onClose={handleDisapproveModalClose}
          onSubmit={({ reason }) => handleDisapproveProgram(reason)}
          loading={false}
        />
      )}
      {isBlockModalOpen && (
        <BlockProgramModal
          open={isBlockModalOpen}
          onClose={handleBlockModalClose}
          onSubmit={({ reason }) => handleBlockProgram(reason)}
          loading={false}
        />
      )}

      {selectedItem && (
        <ChallengeDetailsModal
          open={Boolean(selectedItem)}
          onClose={handleCloseChallengeModal}
          type='under_review'
          itemSelectedId={selectedItem}
          update={() => { }}
          specialProgram={true}
          noGraph={true}
        />
      )}
    </>
  );
};

export default ModalSpecialProgram;
