import React from 'react'
import { ButtonProps } from './types'
import ButtonStyled from '@mui/material/Button';
import { Autorenew } from '@mui/icons-material';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingElement = styled(Autorenew)`
  animation: ${spin} 2s linear infinite;
`;

const ButtonMaterial: React.FC<ButtonProps> = (props) => {
  const { disable, loading, onClick, variant, label, style, color, icon, iconRight } = props

  return (
    <ButtonStyled variant={variant ?? 'outlined'} onClick={onClick} disabled={disable ?? loading} color={color ?? 'primary'} style={style}>
      {!loading && icon}
      {loading ? <RotatingElement /> : label}
      {iconRight}
    </ButtonStyled>
  )
}

export default ButtonMaterial
