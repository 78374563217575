import React from 'react';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AgeRatingSelect: React.FC<TextFieldProps> = (props) => {
  const { t } = useTranslation()

  return (
    <TextField
      select
      label="Classificação Indicativa"
      placeholder="Selecione a Classificação"
      {...props}
    >
      <MenuItem value={1}>
        {t("Livre")}
      </MenuItem>
      <MenuItem value={2}>
        {t("Maiores de 10 anos")}
      </MenuItem>
      <MenuItem value={3}>
        {t("Maiores de 12 anos")}
      </MenuItem>
      <MenuItem value={4}>
        {t("Maiores de 14 anos")}
      </MenuItem>
      <MenuItem value={5}>
        {t("Maiores de 16 anos")}
      </MenuItem>
      <MenuItem value={6}>
        {t("Maiores de 18 anos")}
      </MenuItem>
    </TextField>
  );
}

export default AgeRatingSelect;
