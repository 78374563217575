import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 24px;

  @media (max-width: 680px) {
    margin-top: 0px;
  }
`

export const ContainerTable = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding-bottom: 16px;

  .containerButton {
    display: flex;
    align-self: flex-end;
    margin-right: 16px;
    padding: 0;
    margin-top: -75px;
    margin-bottom: 12px;
  }
  
  @media (max-width: 680px) {
    .containerButton {
      width: 100%;
      margin: 0;
      padding: 24px;
    }
  }
`

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`

export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  label {
    white-space: nowrap;
    margin-left: 8px;
    color: ${(props) => props.theme.colors.primary};
  }

  .icon {
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`

export const ContainerSearch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
`

export const InputsRight = styled.div`
  width: 50%;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const LineHeader = styled.div<{ register?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  border-bottom: solid 0.5px ${(props) => props.theme.colors.ten};
  margin-bottom: 24px;

  @media (max-width: 680px) {
    width: 100%;
    margin-bottom: ${(props) => (props.register ? '8px' : ' 48px')};
  }
`

export const ButtonHeader = styled.button<{ active?: boolean }>`
  width: 40%;
  border: none;
  border-bottom: solid 2px ${(props) => (props.active ? props.theme.colors.seventh : 'transparent')};
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }
  
    h5 {
      line-height: 16px;
      letter-spacing: 1.07px;
      text-align: center;
      font-size: ${responsiveFont(12)};
      color: ${(props) => (props.active ? props.theme.colors.seventh : props.theme.colors.friday)};
      font-weight: 500;
      font-family: 'Barlow', Medium;
      padding: 16px 8px 16px 8px;
  
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(12)};
      }
    }

  @media (max-width: 680px) {
    width: 50%;
  }
`

export const ContainerModalUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 150px;
`

export const BoxSelectPermission = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  h5 {
    line-height: 20px;
    letter-spacing: 0.15px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black};
    opacity: 0.6;
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(12)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }
`

export const ContainerSelectPermission = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const BoxChip = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`;

export const ContainerChip = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin-top: 16px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;

  width: 60%;

  padding: 8px 0;

  p {
    font-weight: 500;
  }

  @media (max-width: 680px) {
    width: 100%;
    margin-top: -12px;
  }
`

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 0px 12px;

  > div {
    display: flex;
    align-self: flex-start;

    padding: 8px 0;

    input {
      width: 22px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .input {
    width: 100%;

    input {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }

    select {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }
  }
`


export const ButtonScrollToTop = styled.a`
  position: fixed;
  right: 24px;
  bottom: 24px;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 90;
  background-color:  ${(props) => props.theme.colors.seventh};
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
  
  .icon {
    position: absolute;
    top: 12px;
    left: 12px;
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`

export const TitleAddContact = styled.h1`
  display: flex;
  line-height: 16px;
  text-align: center;
  font-size: ${responsiveFont(16)};
  color: ${(props) => props.theme.colorsBase.black3};
  font-weight: 400;
  font-family: 'Barlow', Regular;
  padding: 0px 17% 0px 17%;

  @media (max-width: 680px) {
    font-size: ${responsiveFont(14)};
    padding: 0px;
  }

  @media (min-width: 1600px) {
    font-size: ${responsiveFont(18)};
  }
`

export const ContainerListContacts = styled.div`
  display: grid;
  justify-content: start;
  align-items: start;
  padding: 8px 32px  24px 32px;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 80%;
  min-width: 850px;
  align-self: flex-start;

  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 8px 0px 8px;
    width: 100%;
    min-width: 100%;
  }
  
  @media (min-width: 1600px) {
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 2000px) {
    gap: 40px;
    grid-template-columns: repeat(4, 1fr);
  }
  `

export const ContainerDonatePoints = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  h5 {
    line-height: 20px;
    text-align: center;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }

  .boxContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 8px;
    width: 100%;

    @media (max-width: 680px) {
      margin-top: 16px;
      flex-direction: column;
      justify-content: center;
    }
  }
`

export const ContainerContact = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 680px) {
    margin: 21px 0px 16px 0px;
  }

  .boxContentText {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 12px;

    @media (max-width: 680px) {
     
    }

    .data {
      margin-bottom: 8px;
      line-height: 16px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colorsBase.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;

      width: 130px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      
      @media (max-width: 680px) {
        max-width: ${`calc(100vw - 145px)`};
      }
    }

    .title {
          margin-bottom: 4px;
          line-height: 16px;
          text-align: left;
          font-size: ${responsiveFont(16)};
          color: ${(props) => props.theme.colors.secundary};
          font-weight: 400;
          font-family: 'Barlow', Regular;

          width: 130px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @media (max-width: 680px) {
            max-width: ${`calc(100vw - 145px)`};
          }
    }

    .subTitle {
          line-height: 16px;
          text-align: left;
          font-size: ${responsiveFont(12)};
          color: ${(props) => props.theme.colorsBase.black};
          font-weight: 400;
          font-family: 'Barlow', Regular;

          width: 130px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          
          @media (max-width: 680px) {
            max-width: ${`calc(100vw - 145px)`};
          }
    }

  }
`

export const ImageContact = styled.img`
  width: 65px;
  height: 65px;
  object-fit: cover; 
  border-radius: 50%;
`


export const ContainerBalance = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  border-radius: 4px;
  border: solid 0.5px ${(props) => props.theme.colors.eleven};
  padding:  8px 16px;

  @media (max-width: 680px) {
    width: 100%;
  }

  .containerDescription {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%; 
    height: 100%;

   .title {
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.secundary};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
   }

   .description {
    margin-top: 8px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
   }
  }
  
      .containerValue {
        margin-left: 16px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        height: 100%;

      .value {
        text-align: left;
        font-size: ${responsiveFont(34)};
        color: ${(props) => props.theme.colors.seventh};
        font-weight: 400;
        font-family: 'Barlow', Regular;

        @media (min-width: 1600px) {
          font-size: ${responsiveFont(36)};
        }
      }
      
      .textPoints {
        margin-left: 4px;
        margin-bottom: 6px;
        text-align: left;
        font-size: ${responsiveFont(10)};
        color: ${(props) => props.theme.colors.seventh};
        font-weight: 400;
        font-family: 'Barlow', Regular;

        @media (min-width: 1600px) {
          font-size: ${responsiveFont(12)};
        }
      }
    
    }
`

export const ContainerPointSelection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0px 24px;
  
  @media (max-width: 680px) {
    width: 100%;
    margin: 0px;
    align-items: center;
  }

  .titlePoint {
    margin-bottom: 7px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colorsBase.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (max-width: 680px) {
      text-align: center;
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .containerPoint {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%; 
    height: 100%;

    .boxButtonLeft {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border-radius: 20px 0px 0px 20px;
      border: solid 1px ${(props) => props.theme.colors.secundary};
      background-color: ${(props) => props.theme.colors.seventh2};

      &:hover {
        opacity: 0.8;
      }
    }

    .boxButtonRight {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border-radius: 0px 20px 20px 0px;
      border: solid 1px ${(props) => props.theme.colors.secundary};
      background-color: ${(props) => props.theme.colors.seventh2};

      &:hover {
        opacity: 0.8;
      }
    }

   .textButton {
    text-align: center;
    font-size: ${responsiveFont(18)};
    color: ${(props) => props.theme.colors.secundary};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(20)};
    }
   }

   .inputPoint {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    width: 75px;
    padding: 0px 8px;
    border-top: solid 1px ${(props) => props.theme.colorsBase.gray13};
    border-bottom: solid 1px ${(props) => props.theme.colorsBase.gray13};
    border-left: none;
    border-right: none;

    text-align: left;
    font-size: ${responsiveFont(20)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(22)};
    }
  }
  } 
`

export const ContainerPointDonation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: -16px;

  h5 {
    line-height: 20px;
    text-align: center;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }

  b {
    line-height: 20px;
    text-align: center;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.secundary};
    font-weight: 700;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }

  h3 {
    margin-top: 4px;
    line-height: 20px;
    text-align: center;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }

  h1 {
    line-height: 20px;
    text-align: center;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 700;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }

`