export const verifyClassEmpty = <T extends object>(entity: T | undefined) => {
  if (!entity) return false;
  const errors = loopObject(entity);
  return errors > 0;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
const loopObject = <T extends object>(entity: T | object | any[]) => {
  let error = 0;
  Object.values(entity).forEach(item => {
    if (typeof item === 'string' && item !== '') {
      error++;
    } else if (typeof item === 'object') {
      error += loopObject(item);
    }
  });
  return error;
};
