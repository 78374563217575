import React, { useMemo } from 'react';
import { addDays, getDate, getMonth } from 'date-fns';
import { Bar, BarChart, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useTranslation } from 'react-i18next';

import { useIsSm } from '../../hooks/useIsSm';
import { IPerformanceChallenge } from '../../services/Dashboard/ChallengesDashboard/types';

const normalizeData = ({ DayOfMonth, Month, StartedCount, EndedCount }: IPerformanceChallenge): IPerformanceChallenge => ({
  DayOfMonth,
  Month,
  StartedCount: StartedCount - EndedCount,
  EndedCount: EndedCount
})

interface IProps {
  data?: IPerformanceChallenge[]
  endDate: number | Date,
  daysAmount?: number
}

const ChallengeInpactGraph: React.FC<IProps> = ({ data, endDate, daysAmount = 15 }) => {
  const { t } = useTranslation()
  const isSm = useIsSm()
  const normalizedData = useMemo(() => Array.from({ length: daysAmount }).map<IPerformanceChallenge>((_, index) => {
    const currentDay = addDays(endDate, index + 1)

    const DayOfMonth = getDate(currentDay)
    const Month = getMonth(currentDay) + 1

    const fromApi = data?.find(e => e.DayOfMonth === DayOfMonth && e.Month === Month)

    return normalizeData(fromApi ?? { DayOfMonth, Month, EndedCount: 0, StartedCount: 0 })
  }), [endDate, data, daysAmount])

  return (
    <ResponsiveContainer>
      <BarChart
        width={500}
        height={300}
        data={normalizedData}
        margin={{
          right: 30,
          left: 20,
        }}
        barSize={14}
      >
        <XAxis dataKey="DayOfMonth" axisLine={false} tickLine={false} />
        <YAxis axisLine={false} tickLine={false} />
        <Legend
          verticalAlign={isSm ? "bottom" : "top"}
          iconType='circle'
          height={36}
          formatter={(value) => value === 'EndedCount' ? t("Número de Pessoas Alcançadas") : t("Total de Views")}
        />
        <Bar dataKey="StartedCount" stackId="a" fill="#1A1266" />
        <Bar dataKey="EndedCount" stackId="a" fill="#00DAF9" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default ChallengeInpactGraph;