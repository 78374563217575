import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
`

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 590px;
  position: relative;

  .curva {
    display: flex;
    position: absolute;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 680px) {
    height: 580px;

    .curva {
      height: 450px;
    }
  }

  @media (min-width: 681px) {
    height: 580px;

    .curva {
      height: 600px;
    }
  }

  @media (min-width: 1250px) {
    height: 580px;

    .curva {
      height: 650px;
    }
  }

  @media (min-width: 1600px) {
    height: 830px;

    .curva {
      height: 100%;
    }
  }
`

export const TextHeader = styled.image`
  display: flex; 
  width: 100%;
  flex-direction: flex-start;

  p {
    z-index: 1;
    line-height: 34px;
    letter-spacing: 0.18px;
    margin-top: 56px;
    margin-left: 48px;
    text-align: left;
    font-size: ${responsiveFont(24)};
    color: ${(props) => props.theme.colors.third};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(12)};
      margin: 350px 24px 0px 24px;
      width: 100%;
      text-align: center;
      line-height: 16px;
    }

    @media (min-width: 1600px) {
      margin-top: 96px;
      line-height: 48px;
      font-size: ${responsiveFont(32)};
    }
  }
`

export const CurvaHeader = styled.image`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px;

  button {
    z-index: 3;
    border: 0;
    background: none;

    img {
      width: 200px;
      object-fit: cover;
      margin-left: 8px;
      margin-top: 8px;
    }
  }

  @media (max-width: 680px) {
    flex-direction: column-reverse;
    padding: 8px 16px;

    button {
      img {
        width: 120px;
        margin-left: 24px;
        margin-top: 32px;
      }
    }
  }
`

export const ButtonHeader = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 680px) {
    width: 100%;
    justify-content: space-between;
  }
`

export const ContainerBenefits = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 32px;

  @media (max-width: 900px) {
    padding: 0px;
    flex-direction: column;
  }
`

export const Sphere = styled.div<{ sphere?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.fifth};
  padding: 24px;
  margin-top: ${(props) => (props.sphere ? (props.sphere === 2 ? '174px' : '48px') : 0)};

  .image {
    width: 230px;
    object-fit: cover;
  }

  h1 {
    line-height: 24px;
    letter-spacing: 0.18px;
    width: 90%;
    margin-top: 32px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.fourth};
    font-weight: 500;
    font-family: 'Barlow', Medium;
  }

  @media (max-width: 680px) {
    margin-top: 24px;
    margin-left: ${(props) => (props.sphere === 2 ? 'auto' : '-16px')};

    h1 {
      font-size: ${responsiveFont(14)};
    }
  }

  @media (min-width: 681px) {
    margin-top: 24px;
    margin-left: ${(props) => (props.sphere === 2 ? 'auto' : '24px')};
    margin-right: 24px;

    h1 {
      font-size: ${responsiveFont(14)};
    }
  }

  @media (min-width: 900px) {
    margin-top: ${(props) => (props.sphere ? (props.sphere === 2 ? '174px' : '48px') : 0)};
    margin-left: 0px;
    margin-right: 0px;

    h1 {
      font-size: ${responsiveFont(14)};
    }
  }

  @media (min-width: 1600px) {
    width: 400px;
    height: 400px;

    h1 {
      font-size: ${responsiveFont(20)};
    }

    .image {
      width: 280px;
    }
  }
`

export const ContainerContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
  .curvab {
    display: flex;
    position: absolute;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 680px) {
    .curvab {
      height: 1400px;
    }
  }

  @media (max-width: 1329px) {
    .curvab {
      height: 1400px;
    }
  }
`

export const ContentsContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 132px 24px 0px 24px;
  z-index: 1;

  h2 {
    line-height: 32px;
    letter-spacing: 0.18px;
    text-align: center;
    font-size: 24px;
    margin-bottom: 40px;
    color: ${(props) => props.theme.colors.third};
    font-weight: 400;
    font-family: 'Barlow', Regular;
  }

  @media (max-width: 680px) {
    h2 {
      font-size: ${responsiveFont(18)};
    }
  }

  @media (min-width: 1600px) {
    padding: 200px 24px 0px 24px;

    h2 {
      font-size: ${responsiveFont(32)};
    }
  }
`

export const CardContent = styled.div`
  display: flex;
  width: 300px;
  border-radius: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: ${(props) => props.theme.colorsBase.white};
  padding: 16px 24px;
  
  .thumbnail {
    border-radius: 8px;
    width: 100%;
    height: 67.9%;
    object-fit: cover;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    position: relative;
    border: none;
    background: transparent;

    .image {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .box {
      position: absolute;
      top: 0px;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      background-color: ${(props) => props.theme.colorsBase.black};
    }

    .icon {
      position: absolute;
      color: ${(props) => props.theme.colorsBase.white};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
    }

    &:hover {
      .icon {
        opacity: 0.8;
        width: 42px;
        height: 42px;
      }
    }
  }

  h3 {
    margin-top: 24px;
    margin-bottom: 16px;
    line-height: 24px;
    letter-spacing: 0.18px;
    text-align: center;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.fourth};
    font-weight: 500;
    font-family: 'Barlow', Medium;
  }

  @media (max-width: 680px) {
    h3 {
      font-size: ${responsiveFont(16)};
    }
  }

  @media (min-width: 1600px) {
    width: 400px;

    h3 {
      font-size: ${responsiveFont(24)};
    }
  }
`

export const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  overflow: auto;
  gap: 24px;

  &::-webkit-scrollbar {
    background: none;
    }
  &::-webkit-scrollbar-thumb {
      background: none;
    border-radius: 8px;
  }
`

export const ContainerChallenges = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: -82px;

  .curvac {
    display: flex;
    position: absolute;
    width: 100%;
    object-fit: cover;
  }
`

export const ChallengesContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 140px 24px 0px 24px;
  z-index: 1;

  h2 {
    line-height: 32px;
    letter-spacing: 0.18px;
    text-align: center;
    font-size: 24px;
    margin-bottom: 48px;
    color: ${(props) => props.theme.colors.third};
    font-weight: 400;
    font-family: 'Barlow', Regular;
  }

  @media (max-width: 680px) {
    h2 {
      font-size: ${responsiveFont(18)};
    }
  }

  @media (min-width: 1600px) {
    padding: 300px 24px 0px 24px;

    h2 {
      font-size: ${responsiveFont(32)};
      margin-bottom: 64px;
    }
  }

  @media (min-width: 2000px) {
    padding: 600px 24px 0px 24px;

    h2 {
      font-size: ${responsiveFont(32)};
      margin-bottom: 64px;
    }
  }
`

export const ChallengesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  overflow: auto;
  gap: 24px;

  &::-webkit-scrollbar {
    background: none;
    }
  &::-webkit-scrollbar-thumb {
      background: none;
    border-radius: 8px;
  }
`

export const CardChallenges = styled.div`
  display: flex;
  width: 300px;
  border-radius: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: ${(props) => props.theme.colorsBase.white};
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);

  .thumbnail {
    border-radius: 8px;
    width: 100%;
    height: 160px;
    object-fit: cover;
  }

  div {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 8px;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      margin-top: 8px;
      line-height: 24px;
      letter-spacing: 0.18px;
      text-align: center;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.fourth};
      font-weight: 500;
      font-family: 'Barlow', Medium;
    }

    .options {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      div {
        justify-content: flex-end;
        margin: 0;
        padding: 0;
        margin-bottom: 24px;

        .alternative {
          background: transparent;
          border: none;
        }

        .option {
          pointer-events: none;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin: 0;
          font-weight: 400;
          font-family: 'Barlow', Regular;
        }
        
      }
    }
  }

  @media (max-width: 680px) {
    
  }

  @media (min-width: 1600px) {
    width: 400px;

    div {
      h3 {
        font-size: ${responsiveFont(24)};
        margin-top: 16px;
        margin-bottom: 8px;
      }

      .options {
        div {
          margin-bottom: 32px;
        }
      }
    }

    .thumbnail {
      height: 220px;
    }
  }
`

export const ContainerAwards = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 120px 24px 0px 24px;

  h2 {
    line-height: 32px;
    letter-spacing: 0.18px;
    text-align: center;
    font-size: ${responsiveFont(24)};
    margin-bottom: 72px;
    color: ${(props) => props.theme.colors.third};
    font-weight: 400;
    font-family: 'Barlow', Regular;
  }

  h3 {
    line-height: 32px;
    letter-spacing: 0.18px;
    text-align: center;
    font-size: ${responsiveFont(16)};
    margin-top: 24px;
    margin-bottom: 32px;
    color: ${(props) => props.theme.colors.fourth};
    font-weight: 400;
    font-family: 'Barlow', Regular;
  }

  img {
    width: 250px;
    object-fit: cover;
    margin-top: 8px;
  }

  @media (max-width: 680px) {
    padding: 120px 24px 24px 24px;
    
    h2 {
      font-size: ${responsiveFont(16)};
      line-height: 24px;
      margin-bottom: 32px;
    }

    h3 {
      line-height: 32px;
      font-size: ${responsiveFont(20)};
    }
  }

  @media (min-width: 586px) {
    padding-top: 160px;
  }

  @media (min-width: 1600px) {
    padding: 290px 24px 100px 24px;

    h2 {
      font-size: ${responsiveFont(32)};
    }

    img {
      width: 300px;
      margin-top: 24px;
    }

    h3 {
      font-size: ${responsiveFont(24)};
      margin-top: 32px;
    }
  }
`

export const Button = styled.a`
  position: fixed;
  right: 24px;
  bottom: 24px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 90;
  background-color:  ${(props) => props.theme.colors.secundary};


  .icon {
    position: absolute;
    top: 8px;
    left: 8px;
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`
