import { useMutation } from "@tanstack/react-query";

import { sendRequest } from "../../RequestService";

import { IChallengesCurationApproveCommand, IChallengesCurationApproveCommandResponse } from "./types";

const defaultRoot = "challenges-curations/approvals"

/**
 * Aprovar desafio
 * @returns 
 */
export const useChalengeCurationApproveMutation = () => useMutation({
  mutationFn: async (command: IChallengesCurationApproveCommand) => {
    const url = `${defaultRoot}/${command.id}`

    return await sendRequest<IChallengesCurationApproveCommandResponse>(url, 'POST', command.shareName)
  }
})
