import React from 'react';

import PageContainer from '../../components/Layout/PageContainer';
import PaperContainer from '../../components/Layout/PaperContainer';
import { AntTab, AntTabs } from '../../components/Tabs';

import HeaderAchievements from '../../components/HeaderAchievements';
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../hooks/useIsSm';
import MyContacts from '../MyContacts';
import { theme } from '../../styles/theme'
import { Contacts } from '@mui/icons-material';

interface IProps {
  tab: number;
  setNewTab: (newTab: number) => void;
  variant?: "fullWidth" | "standard" | "scrollable";
}

const ContactsView: React.FC<IProps> = ({ setNewTab, tab, variant }) => {
  const { t } = useTranslation();
  const isSm = useIsSm()

  return (
    <PageContainer>
      <HeaderAchievements
        title={t('Contatos')}
        marginBottom={isSm ? 24 : 0}
        icon={!isSm ? <Contacts style={{ color: theme.colorsBase.gray3 }} /> : null}
      />
      <PaperContainer>
        <AntTabs value={tab} onChange={(_, newValue) => setNewTab(newValue)} variant={variant}>
          <AntTab label="contatos" sx={{ width: isSm ? '33vw' : 'none' }} />
          <AntTab label="convites enviados" sx={{ width: isSm ? '33vw' : 'none' }} />
          <AntTab label="convites recebidos" sx={{ width: isSm ? '33vw' : 'none' }} />
        </AntTabs>

        <MyContacts tab={tab} />
      </PaperContainer>
    </PageContainer>
  )
}

export default ContactsView;
