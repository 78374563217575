import React, { useState } from 'react'

import { Restore, TextSnippet } from '@mui/icons-material'
import { Tooltip, } from '@mui/material'
import { emptyItem, ITableUsers, } from './types'
import { useTranslation } from 'react-i18next'
import ModalCustom from '../../../components/ModalCustom'
import ToastNotification from '../../../components/ToastNotification'
import ButtonMaterial from '../../../components/Button';
import { useIsSm } from '../../../hooks/useIsSm';
import iconEN from '../../../assets/images/en.png'
import iconES from '../../../assets/images/es.png'
import iconFR from '../../../assets/images/fr.png'
import iconPT from '../../../assets/images/pt.png'
import * as S from './styles'
import { Icons, Table } from '../../UserManagement/TableUsers/styles'
import { theme } from '../../../styles/theme';
import { IResponseTermsOfUseMultiLanguages } from '../../../services/TermsOfUse/types'
import { withLineBreak } from '../../../utils'
import { postCreateTermsOfUse } from '../../../services/TermsOfUse'

const TableTermsOfUse: React.FC<ITableUsers> = (props: ITableUsers) => {
  const { t } = useTranslation();
  const { listUsers, updateList, currentVersion } = props
  const [itemSelected, setItemSelected] = useState<IResponseTermsOfUseMultiLanguages>(emptyItem)
  const [loading, setLoading] = useState(false)
  const [openViewModal, setOpenViewModal] = useState(false)
  const [viewModal, setViewModal] = useState(1)
  const isSm = useIsSm()
  const [openRegisterConfirmModal, setOpenRegisterConfirmModal] = useState(false)

  const registerTermsOfUse = async () => {
    setLoading(true)
    try {
      const bodyData = {
        ValuePtBr: itemSelected.ValuePtBr,
        ValueEn: itemSelected.ValueEn,
        ValueEs: itemSelected.ValueEs,
        ValueFr: itemSelected.ValueFr,
        Version: `V${Number(currentVersion?.substring(1, currentVersion.length)) + 1}`
      };
      const response = await postCreateTermsOfUse(bodyData);
      if (response.Success) {
        setOpenRegisterConfirmModal(false)
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Termos de Uso restaurado com sucesso!')
        });
        updateList();
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao restaurar os termos, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao restaurar os termos, tente novamente.')
      });
    }
    setLoading(false)
  };

  const renderImageLanguage = (type: number) => ({
    1: iconPT,
    2: iconEN,
    3: iconES,
    4: iconFR
  }[type])

  const renderTextLanguage = (type: number) => ({
    1: t("Termos de Uso"),
    2: t("Terms of use"),
    3: t("Terminos de uso"),
    4: t("Conditions d'utilisation"),
  }[type])

  const renderTable = () => {
    return (
      <Table style={{ marginTop: '8px' }}>
        <table>
          <tr className="header">
            <td style={{ paddingLeft: 16, width: isSm ? '20px' : 'auto' }}>{t('ID')}</td>
            <td>
              <S.ItemLanguage>
                <img src={iconES} className="flag" alt="" />
              </S.ItemLanguage>
            </td>
            <td>
              <S.ItemLanguage>
                <img src={iconFR} className="flag" alt="" />
              </S.ItemLanguage>
            </td>
            <td>
              <S.ItemLanguage>
                <img src={iconEN} className="flag" alt="" />
              </S.ItemLanguage>
            </td>
            <td>
              <S.ItemLanguage>
                <img src={iconPT} className="flag" alt="" />
              </S.ItemLanguage>
            </td>
            <td>{t('Restaurar Versão')}</td>
          </tr>
          {listUsers.map((item) => (
            <tr key={item?.Version}>
              <td style={{ paddingLeft: 16 }}>{item?.Version}</td>
              <td><S.TextButtonTerms onClick={() => {
                setItemSelected(item)
                setOpenViewModal(true)
                setViewModal(3)
              }}>{isSm ? <TextSnippet style={{ color: theme.colorsBase.blue, width: '12px' }} /> : t('Termos de Uso')} {item.Version} (ES)</S.TextButtonTerms></td>
              <td onClick={() => {
                setItemSelected(item)
                setOpenViewModal(true)
                setViewModal(4)
              }}><S.TextButtonTerms>{isSm ? <TextSnippet style={{ color: theme.colorsBase.blue, width: '12px' }} /> : t('Termos de Uso')} {item.Version} (FR)</S.TextButtonTerms></td>
              <td onClick={() => {
                setItemSelected(item)
                setOpenViewModal(true)
                setViewModal(2)
              }}><S.TextButtonTerms>{isSm ? <TextSnippet style={{ color: theme.colorsBase.blue, width: '12px' }} /> : t('Termos de Uso')} {item.Version} (EN)</S.TextButtonTerms></td>
              <td onClick={() => {
                setItemSelected(item)
                setOpenViewModal(true)
                setViewModal(1)
              }}><S.TextButtonTerms>{isSm ? <TextSnippet style={{ color: theme.colorsBase.blue, width: '12px' }} /> : t('Termos de Uso')} {item.Version} (PT)</S.TextButtonTerms></td>
              <td style={{ width: '20px' }}>
                <Icons>
                  <button onClick={() => {
                    setItemSelected(item)
                    setOpenRegisterConfirmModal(true)
                  }}>
                    <Tooltip title={t('Restaurar Versão')}>
                      <Restore style={{ color: theme.colors.seventh }} className="i" />
                    </Tooltip>
                  </button>
                </Icons>
              </td>
            </tr>
          ))}
        </table>

        <ModalCustom
          open={openRegisterConfirmModal}
          onClose={() => setOpenRegisterConfirmModal(false)}
          onClick={registerTermsOfUse}
          loading={loading}
          title={t('Confirmar restauração de Versão')}
          confirmationButtonText={t('CONFIRMAR')}
          textDeclineButton={t('CANCELAR')}
          styleButtons={{ justifyContent: 'center' }}
          shortModal
          styleLine={{ marginBottom: '24px' }}
          childrenContent={
            <S.BoxText>
              <p className='text'>{t('Você realmente deseja restaurar essa versão dos Termos de Uso?')}</p>
              <p style={{ color: theme.colors.graphicRed }}>{t('Versão atual')}: <b className='red'>{currentVersion}</b></p>
              <p style={{ color: theme.colorsBase.green2 }}>{t('Versão a ser restaurada')}: <b className='green'>{itemSelected.Version}</b></p>
            </S.BoxText>
          }
        />

        <ModalCustom
          open={openViewModal}
          onClose={() => setOpenViewModal(false)}
          onClick={() => { }}
          styleLine={{ display: 'none' }}
          style={{ padding: isSm ? '8px' : '24px' }}
          buttonsEnabled={false}
          childrenContent={
            <S.ContainerModalView>
              <div className='boxTitle'>
                <S.ButtonTap>
                  <img src={renderImageLanguage(viewModal)} className="flag" alt="" />
                </S.ButtonTap>

                <div className='boxText'>
                  <h1 className='title'>{renderTextLanguage(viewModal)}</h1>
                  <h2 className='version'>{itemSelected.Version}</h2>
                </div>
              </div>

              <div className='containerDescription '>
                <S.Table>
                  {viewModal === 1 && <p>{withLineBreak(itemSelected.ValuePtBr)}</p>}
                  {viewModal === 2 && <p>{withLineBreak(itemSelected.ValueEn)}</p>}
                  {viewModal === 3 && <p>{withLineBreak(itemSelected.ValueEs)}</p>}
                  {viewModal === 4 && <p>{withLineBreak(itemSelected.ValueFr)}</p>}
                </S.Table>
              </div>

              <div className='containerButtonsModal'>
                <ButtonMaterial
                  style={{ backgroundColor: theme.colors.seventh, color: theme.colorsBase.white }}
                  variant="contained"
                  label={t('Fechar')}
                  onClick={() => setOpenViewModal(false)}
                />
              </div>
            </S.ContainerModalView>
          }
          title=""
          confirmationButtonText={t('SALVAR')}
          textDeclineButton={t('CANCELAR')}
        />
      </Table>
    )
  }

  return renderTable()
}

export default TableTermsOfUse
