import styled from 'styled-components'
import { responsiveFont } from '../../../utils/UtilsGlobal'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%; 

    .containerContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%; 
    }

    .containerButtom {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%; 
    }

    @media (max-width: 680px) {
      .containerContent {
        flex-direction: column;
      }
    }
`

export const Image = styled.img`
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
`

export const ContainerInfo = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
  border: solid 1px ${(props) => (props.active ? 'transparent' : props.theme.colorsBase.gray3)};
  border-radius: 8px;
  padding: 12px 8px 4px 8px;

  .boxTitleProduct {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    .boxTitleIconImage {
      color: ${(props) => props.theme.colorsBase.gray3};
      font-size: 16px;
    }

    h6 {
      margin-left: 8px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colorsBase.black5};
      font-weight: 700;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
  }

  .boxContainerProduct {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .boxImageProduct {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .boxTileImage {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;

      .boxIconImage {
        color: ${(props) => props.theme.colorsBase.gray3};
        font-size: 16px;
      }

      h5 {
        margin-left: 8px;
        text-align: left;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colorsBase.black5};
        font-weight: 700;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(16)};
        }
      }
    }
  }

  .boxInfoProduct {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px 4px 0px 4px;

    .boxTextInfo {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 6px 0px;
      
      .boxTextInfoTop {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }

      .boxIconInfo {
        color: ${(props) => props.theme.colors.friday};
        font-size: 16px;
        margin-right: 8px;
      }

      h3 {
        margin-left: 4px;
        text-align: left;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colors.black4};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) { 
          font-size: ${responsiveFont(16)};
        }

        @media (max-width: 680px) {
          font-size: ${responsiveFont(10)};
        }
      }

      .score {
        margin-top: auto;
        margin-bottom: 2px;
        margin-left:  4px;
        text-align: left;
        font-size: ${responsiveFont(8)};
        color: ${(props) => props.theme.colors.friday};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) { 
          font-size: ${responsiveFont(10)};
        }
      }

      .textDescription {
        margin-top: 2px;
        margin-left: 30px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.friday};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(8)};
        }

        @media (min-width: 1600px) { 
          font-size: ${responsiveFont(14)};
        }
      }
    }
    
  }

  @media (max-width: 680px) {
    width: 100%;
    flex-direction: column;
    padding: 8px;
   
    .boxContainerProduct {
      flex-direction: row;
    }

    .boxImageProduct {
      width: 35%;
    }

    .boxInfoProduct {
      width: 65%;
      padding: 0px 4px 0px 4px;
      margin-left: 4px;
      margin-top: -4px;
      
      .boxTextInfo {
        .boxIconInfo {
          font-size: 12px;
          margin-right: 0px;
        }

        .textDescription {
          margin-top: 2px;
          margin-left: 0px;
        }

        .score {
          margin-top: 8px;
          margin-left:  4px;
        }
      }
      
    }

    
  }
`

export const ContainerOrder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
  margin-left: 16px;

  @media (max-width: 680px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 8px;
  }
`

export const ContainerSetQuantity = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
  margin-top: 8px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colorsBase.gray3};

  .boxMessage {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: ${(props) => props.theme.colorsBase.gray3};
    width: 100%;
    margin-top: 8px;
    border-radius: 4px;
    padding: 4px;

    .iconMessage {
      color: ${(props) => props.theme.colors.friday};
      font-size: 16px;
      margin-right: 4px;
    }

    h5 {
      line-height: 14px;
      text-align: left;
      font-size: ${responsiveFont(10)};
      color: ${(props) => props.theme.colors.friday};
      font-weight: 400;
      font-family: 'Barlow', Regular;
    }
  }

    .boxButton {
      padding: 8px 16px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background: none;
      border: none;
      
      .i {
        color: ${(props) => (props.active ? props.theme.colors.black : props.theme.colors.black5)};
        font-size: 24px;
      }

      .iNumberStage {
        color: ${(props) => props.theme.colorsBase.white};
        background-color: ${(props) => props.theme.colorsBase.green2};
        font-size: 16px;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        padding: 4px;
      }

      .numberStage {
        text-align: center;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colorsBase.white};
        background-color:  ${(props) => (props.active ? props.theme.colors.seventh : props.theme.colors.black5)};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        padding: 1px;
      
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }

      .titleContent {
        margin-left: 12px;
        margin-right: auto;
        text-align: left;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(16)};
        }
      }
    }

    .containerQuestion {
      padding: 12px 12px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .textQtd {
        text-align: center;
        font-size: ${responsiveFont(20)};
        color: ${(props) => props.theme.colorsBase.green2};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(22)};
        }
      }

      .textTl {
        margin-bottom: 4px;
        text-align: center;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colorsBase.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }

      .boxContact {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin: 24px 0px;

        .iconContact {
          color: ${(props) => props.theme.colors.black4};
          font-size: 20px;
          margin-right: 12px;
        }

        .boxContactText {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          // margin-left: 12px;
        }

        .textMail {
          margin-bottom: 4px;
          text-align: left;
          font-size: ${responsiveFont(10)};
          color: ${(props) => props.theme.colorsBase.black};
          font-weight: 400;
          font-family: 'Barlow', Regular;
          
          @media (min-width: 1600px) {
            font-size: ${responsiveFont(12)};
          }
        }
      }

    }
    }
  }
`