export const getLocalStorageNoExpiration = <T>(key: string): T | null => {
  const getItem = localStorage.getItem(key)
  if (!getItem) return null
  try {
    return JSON.parse(getItem)
  } catch (e) {
    return getItem as unknown as T
  }
}

export const setLocalStorageNoExpiration = <T>(key: string, value: T) => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
    return true
  } catch (e) {
    return false
  }
}

export const cleanLocalStorageNoExpiration = () => {
  try {
    localStorage.clear()
    return true
  } catch (e) {
    return false
  }
}

export const removeLocalStorageNoExpiration = (key: string) => {
  try {
    localStorage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
}
