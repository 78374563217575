/* eslint-disable */

import { ObjectSchema, setLocale } from 'yup';
import { Assign, ObjectShape } from 'yup/lib/object';
import { LOCALE_YUP_BR } from '../assets/Constants';

setLocale(LOCALE_YUP_BR);

interface ICatchValidate {
  path: string
  message: string
}

export const genericValidation = async <T, L>(schema: ObjectSchema<Assign<ObjectShape, any>>, object: T, ErrorClass: any): Promise<L> => {
  const validate: boolean | ICatchValidate[] = await schema.validate(object, { abortEarly: false })
    .then(() => true)
    .catch((err) => err.inner);

  if (typeof validate !== 'boolean') validate.forEach((item) => { ErrorClass[item.path] = item.message; });

  return ErrorClass;
};

export const isValidCpf = (input?: string | null): boolean => {
  if (!input) return false;

  input = input.replace(/\D/g, "");

  const regExp = new RegExp(`^(\\d{${11}})$`);
  const checkNumbers = input.match(regExp);
  if (!checkNumbers) return false;

  const index0 = input[0];
  const checkEqualsNumber = input.split("").every((char) => char === index0);
  if (checkEqualsNumber) return false;

  let sum1 = 0;
  let sum2 = 0;
  let j = input.length;

  for (let i = 0; i < 10; i++, j--) {
    if (j > 2) {
      sum1 += Number(input.charAt(i)) * (j - 1);
    }
    sum2 += Number(input.charAt(i)) * j;
  }

  const checker1 = 11 - (sum1 % 11);
  const chacker2 = 11 - (sum2 % 11);

  const isOk1 =
    ((checker1 === 10 || checker1 === 11) && Number(input.charAt(9)) === 0) ||
    Number(input.charAt(9)) === checker1;

  const isOk2 =
    ((chacker2 === 10 || chacker2 === 11) && Number(input.charAt(10)) === 0) ||
    Number(input.charAt(10)) === chacker2;

  return isOk1 && isOk2;
};

export function ValidationData(date: string) {
  const RegExPattern =
    /^((((0?[1-9]|[12]\d|3[01])[\.\-\/](0?[13578]|1[02])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|[12]\d|30)[\.\-\/](0?[13456789]|1[012])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|1\d|2[0-8])[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|(29[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00)))|(((0[1-9]|[12]\d|3[01])(0[13578]|1[02])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|[12]\d|30)(0[13456789]|1[012])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|1\d|2[0-8])02((1[6-9]|[2-9]\d)?\d{2}))|(2902((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00))))$/;
  if (date.length <= 0) {
    return { msg: "Campo obrigatório", status: false };
  }
  if (date.length < 8) {
    return { msg: "Desculpe, a data não é válida", status: false };
  }
  if (!(date.match(RegExPattern) && date != "")) {
    return { msg: "Desculpe, a data não é válida", status: false };
  }
  return { msg: "", status: true };
}

function digit(numbers: string): number {
  let index = 2

  const sum = Array.from(numbers).reverse().reduce((buffer, number) => {
    buffer += Number(number) * index
    index = index === 9 ? 2 : index + 1
    return buffer
  }, 0)

  const mod = sum % 11

  return mod < 2 ? 0 : 11 - mod
}

export const isValidCNPJ = (cnpj: string | number): boolean => {
  const cleaned = cnpj.toString().replace(/[\.\/\-]/g, '')

  if (
    !cleaned ||
    cleaned.length !== 14 ||
    /^(\d)\1+$/.test(cleaned)
  ) {
    return false
  }

  let registration = cleaned.substring(0, 12)
  registration += digit(registration)
  registration += digit(registration)

  return registration.substring(-2) === cleaned.substring(-2)
}

export function BirthDateValidation(birthDate: any, age = 5) {
  const result = ValidationData(birthDate);
  if (!result.status) {
    return { msg: result.msg, status: result.status };
  }
  const d = new Date();
  const ano_atual = d.getFullYear();
  const mes_atual = d.getMonth() + 1;
  const dia_atual = d.getDate();

  const diaNasc = parseInt(birthDate.substring(0, 2));
  const mesNasc = parseInt(birthDate.substring(2, 4));
  const anoNasc = parseInt(birthDate.substring(4, 8));
  let quantos_anos = ano_atual - anoNasc;

  if (mes_atual < mesNasc || (mes_atual == mesNasc && dia_atual < diaNasc)) {
    quantos_anos = quantos_anos - 1;
  }
  if (quantos_anos < age) {
    return {
      msg: "Cadastro permitido apenas para maiores de 5 anos",
      status: false,
    };
  }
  if (quantos_anos > 150) {
    return { msg: "Desculpe, a data não é válida", status: false };
  }
  return { msg: "", status: true };
}
