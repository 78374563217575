import React, { useEffect, useState, ChangeEvent, useRef } from 'react';
import { useAuth } from '../../contexts/Auth';
import { cleanLocalStorage } from '../../utils/StorageLocal';
import * as S from './styles'
import { Badge, CheckBox, CheckBoxOutlineBlank, Lock, Person, PhotoCamera, Place, UploadFile, Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import ToastNotification from '../../components/ToastNotification';
import ViewLoading from '../../components/ViewLoading';
import ModalCustom from '../../components/ModalCustom';
import Input from '../../components/Input';
import { cepMask, cpfMask, DataMaskFormtData, dateMask, objetosSaoIguais, onlyDigits, dateMaskInverter } from '../../utils/Mask'
import { ChangePassword, ChangePasswordError, Registration, RegistrationAddress, RegistrationAddressError, RegistrationError, RegistrationProfessional, RegistrationProfessionalError } from './types';
import { deleteUserProfile, getAdvice, getUser, postAddressUser, postChangePassword, postCheckPassword, postProfessionalUser, putUser, uploadFiles } from '../../services/Users';
import { Select, MenuItem, FormControl, InputLabel, TextField, InputAdornment } from '@mui/material'
import { registerAddressValidation, registerProfessionalValidation, registerValidation } from './validations';
import SelectLanguageEditUser from '../../components/SelectLanguageEditUser';
import { getCEPAddressUser, getCityAddressUser, getStateAddressUser } from '../../services/Locality';
import { IPais, IState } from '../../services/Locality/types';
import HeaderAchievements from '../../components/HeaderAchievements';
import { logout } from '../../services/Auth';
import { BirthDateValidation } from '../../utils/ValidationGenerics';
import { resetPasswordValidation } from '../RecoverPassword/validations';
import { ButtonHidePassword } from '../../components/ButtonHidePassword';
import { ContainerPasswordValidation, TextValidationPassword } from '../loginAndRegister/styles';
import SelectMultiple from '../../components/SelectMultiple';
import { getCategories } from '../../services/Categories';
import { ICategories } from '../../components/TableCategory/types'
import ImageAdjustment from '../../components/ImageAdjustment';

const MyProfile: React.FC = () => {
  const { changeLanguage, } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)
  const [headerActive, setHeaderActive] = useState(0);
  const [registration, setRegistration] = useState<Registration>(new Registration());
  const [registrationError, setRegistrationError] = useState<RegistrationError>(new RegistrationError());
  const [registrationAddress, setRegistrationAddress] = useState<RegistrationAddress>(new RegistrationAddress());
  const [registrationAddressError, setRegistrationAddressError] = useState<RegistrationAddressError>(new RegistrationAddressError());
  const [image, setImage] = useState<string>("https://http2.mlstatic.com/D_Q_NP_626854-MLB54269491476_032023-R.webp");
  const [imageFirst, setImageFirst] = useState<string>("https://http2.mlstatic.com/D_Q_NP_626854-MLB54269491476_032023-R.webp");
  const [imageSet, setImageSet] = useState<File>();
  const [loadingGet, setLoadingGet] = useState(false)
  const [stateList, setStateList] = useState<IState[]>([])
  const [cityList, setCityList] = useState<IPais[]>([])
  const [registrationProfessional, setRegistrationProfessional] = useState<RegistrationProfessional>(new RegistrationProfessional());
  const [registrationProfessionalError, setRegistrationProfessionalError] = useState<RegistrationProfessionalError>(new RegistrationProfessionalError());
  const [adviceList, setAdviceList] = useState<IPais[]>([])

  const [registrationFirstState, setRegistrationFirstState] = useState<Registration>(new Registration());
  const [registrationAddressFirstState, setRegistrationAddressFirstState] = useState<RegistrationAddress>(new RegistrationAddress());
  const [registrationProfessionalFirstState, setRegistrationProfessionalFirstState] = useState<RegistrationProfessional>(new RegistrationProfessional());

  const [editable, setEditable] = useState(false)
  const [openConfirmClosingModal, setOpenConfirmClosingModal] = useState(false)
  const [accountDeleted, setAccountDeleted] = useState(false)
  const [firstStage, setFirstStage] = useState(true)
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false)
  const [accountRecovered, setAccountRecovered] = useState(false)
  const [firstStageRecovered, setFirstStageRecovered] = useState(true)
  const [tabProfessional, setTabProfessional] = useState(false)

  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showPasswordRegistration, setShowPasswordRegistration] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('')
  const [currentPasswordError, setCurrentPasswordError] = useState('')
  const [tokenPassword, setTokenPassword] = useState('')
  const [recoverPassword, setRecoverPassword] = useState<ChangePassword>(new ChangePassword());
  const [recoverPasswordError, setRecoverPasswordError] = useState<ChangePasswordError>(new ChangePasswordError());
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);

  const [singleText, setSingleText] = useState('')
  const [singleTextLanguage, setSingleTextLanguage] = useState('')

  const [categoriesError, setCategoriesError] = useState(false)
  const [languageError, setLanguageError] = useState(false)

  const [openModalImageAdjustment, setOpenModalImageAdjustment] = useState(false)

  const gender = [
    {
      Id: '1',
      Titulo: 'Feminino'
    },
    {
      Id: '2',
      Titulo: 'Masculino'
    }
  ]

  const olderGender = [
    ...gender,
    {
      Id: '3',
      Titulo: 'Prefiro não Informar'
    },
    {
      Id: '4',
      Titulo: 'Outros'
    }
  ]

  const [larguraDaTela, setLarguraDaTela] = useState(window.innerWidth);

  const [eightCharacters, setEightCharacters] = useState(false);
  const [capitalLetter, setCapitalLetter] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [number, setNumber] = useState(false);
  const [spaceCharacter, setSpaceCharacter] = useState(false);

  const regExpCapitalLowerCase = (password: string) => {
    let i = 0;
    if (password.length === 0) {
      return false;
    }
    while (i < password.length) {
      const character = password.charAt(i);
      if (character === character.toLowerCase()) {
        return true;
      }
      i++;
    }
    return false;
  };
  const regExpCapitalUpperCase = (password: string) => {
    let i = 0;
    if (password.length === 0) {
      return false;
    }
    while (i < password.length) {
      const character = password.charAt(i);
      if (character === character.toUpperCase()) {
        return true;
      }
      i++;
    }
    return false;
  };

  const validatePassword = (password: string) => {

    const regExpSpecialChar = /[^a-zA-Z0-9]/;
    const regExpNumber = /\d/;


    if (regExpSpecialChar.test(password)) {
      setSpaceCharacter(true);
    } else {
      setSpaceCharacter(false);
    }

    if (regExpNumber.test(password)) {
      setNumber(true);
    } else {
      setNumber(false);
    }

    if (regExpCapitalUpperCase(password)) {
      setCapitalLetter(true);
    } else {
      setCapitalLetter(false);
    }

    if (regExpCapitalLowerCase(password)) {
      setLowerCase(true);
    } else {
      setLowerCase(false);
    }

    if (password.length >= 8) {
      setEightCharacters(true);
    } else {
      setEightCharacters(false);
    }


    return password;
  };

  useEffect(() => {
    const handleResize = () => {
      setLarguraDaTela(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOpenModalImageAdjustment(true)
    const selectedImage = e.target.files?.[0];
    if (selectedImage) {
      setImageSet(selectedImage)
      setImage(URL.createObjectURL(selectedImage));
    }
  };

  useEffect(() => {
    retrieveUser();
    retrieveAdvice();
    retrieveUF();
    retrieveCategories();
  }, []);

  const [categoriesList, setCategoriesList] = useState<ICategories[]>([])
  const [categoriesListSelected, setCategoriesListSelected] = useState<ICategories[]>([])
  const [languageListSelected, setLanguageListSelected] = useState<ICategories[]>([])
  const [categoriesListSelectedFirstState, setCategoriesListSelectedFirstState] = useState<string[]>([])
  const [languageListSelectedFirstState, setLanguageListSelectedFirstState] = useState<string[]>([])

  const languageList = [
    {
      Name: 'Inglês',
      Id: '2'
    },
    {
      Name: 'Espanhol',
      Id: '3'
    },
    {
      Name: 'Francês',
      Id: '4'
    },
    {
      Name: 'Português',
      Id: '1'
    }
  ]

  const retrieveCategories = async (e?: string[]) => {
    setLoading(true)
    try {
      setCategoriesList([]);
      const response = await getCategories();
      if (response.Success) {
        setCategoriesList(response.Data)

        if (e) {
          let singleText = ''

          e.map((item) => {
            const tempCategories = response.Data.filter((itemX) => itemX?.Id === item)[0]?.Name;
            singleText = singleText + tempCategories + ', '
          });
          setSingleText(singleText)


          const tempCategories: ICategories[] = []

          e.map((item) => {
            const category = response.Data.filter((itemX) => itemX?.Id === item)[0];
            tempCategories.push(category)
          });

          setCategoriesListSelected(tempCategories)
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar as categorias, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar as categorias, tente novamente.')
      });
    }
    setLoading(false)
  };

  const handleCloseAccount = async () => {
    setLoading(true);
    try {
      if (password.length <= 0) {
        setPasswordError("Campo obrigatório");
        setLoading(false);
        return;
      }

      const response = await deleteUserProfile(password);
      if (response.Success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Conta encerrada com sucesso!')
        });
        setAccountDeleted(true);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Senha Incorreta')
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao encerrar a conta, tente novamente.'),
      });
    }
    setLoading(false);
  };

  const handlePostProfessional = async (mobile?: boolean) => {
    setLoading(true);
    try {
      let countError = 0;

      const validation = await registerProfessionalValidation(registrationProfessional);

      if (typeof validation !== 'boolean') {
        countError++;
      }

      if (countError > 0) {
        if (!categoriesListSelected[0]?.Name) {
          setCategoriesError(true)
        }

        if (!languageListSelected[0]?.Name) {
          setLanguageError(true)
        }

        setRegistrationProfessionalError(validation as RegistrationProfessionalError);
        setLoading(false);
        return;
      }

      if (!categoriesListSelected[0]?.Name) {
        if (!languageListSelected[0]?.Name) {
          setLanguageError(true)
        }

        setCategoriesError(true)
        setLoading(false);
        return;
      }

      if (!languageListSelected[0]?.Name) {
        setLanguageError(true)
        setLoading(false);
        return;
      }

      const singleText: string[] = []

      categoriesListSelected.map((item) => {
        singleText.push(item.Id)
      });

      const singleTextLanguage: string[] = []

      languageListSelected.map((item) => {
        singleTextLanguage.push(item.Id)
      });

      const bodyData = {
        AboutProfessional: registrationProfessional.SobreAutor,
        RegulatoryBoardId: registrationProfessional.ConselhoProfissional,
        RegulatoryBoard: adviceList.filter((itemX) => String(itemX.Id) === String(registrationProfessional.ConselhoProfissional))[0]?.Name ?? '',
        RegulatoryBoardNumber: registrationProfessional.NumeroConselho,
        RegulatoryBoardState: registrationProfessional.UfConselhoId,
        SiteUrl: registrationProfessional.LinkReferencia,
        FacebookUrl: registrationProfessional.LinkFacebook,
        XUrl: registrationProfessional.LinkTwitter,
        InstagramUrl: registrationProfessional.LinkInstagram,
        RQENumber: registrationProfessional.RQE,
        Categories: singleText,
        Languages: singleTextLanguage
      };

      const response = await postProfessionalUser(bodyData);
      if (response.Success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Edição realizada com sucesso!')
        });
        if (mobile) {
          setEditable(false)
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro editar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro editar os dados, tente novamente.')
      });
    }
    setLoading(false);
  };

  const retrieveAdvice = async () => {
    setLoading(true)
    try {
      setAdviceList([]);
      const response = await getAdvice();
      if (response.Success) {
        setAdviceList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  const retrieveUF = async () => {
    setLoading(true)
    try {
      setStateList([]);
      const response = await getStateAddressUser();
      if (response.Success) {
        setStateList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  const retrieveCity = async (uf: string) => {
    setLoading(true)
    try {
      setCityList([]);
      const response = await getCityAddressUser(uf);
      if (response.Success) {
        setCityList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  const editLanguageUser = async (language?: string) => {
    if (language === '1') changeLanguage('PT')
    if (language === '2') changeLanguage('EN')
    if (language === '3') changeLanguage('ES')
    if (language === '4') changeLanguage('FR')
  }


  const editUser = async (mobile?: boolean) => {
    setLoading(true)
    try {
      let countError = 0;

      const validation = await registerValidation(registration);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError(validation as RegistrationError);
        setLoading(false);
        return;
      }

      let urlFile: string | null = null

      if (imageSet) {
        const data = new FormData();
        data.append('file', imageSet as File);
        const responseImage = await uploadFiles(data, 2);
        if (responseImage.Success) {
          urlFile = responseImage.Data.FileUrl
        }
      }

      const bodyData = {
        Name: registration.name,
        DocumentNumber: onlyDigits(registration.cpf),
        ProfilePhotoUrl: urlFile ? urlFile : imageFirst,
        GenderId: registration.sexoId,
        LanguageId: registration.language,
        BornDate: dateMaskInverter(registration.dateOfBirth)
      };

      const response = await putUser(bodyData);
      if (response.Success) {
        editLanguageUser(registration.language)
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Edição realizada com sucesso!')
        });

        if (mobile) {
          setEditable(false)
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao alterar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }

    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao alterar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  const retrieveUser = async () => {
    setLoadingGet(true)
    try {
      const response = await getUser();
      if (response.Success) {
        setRegistration({
          name: response.Data.Name,
          email: response.Data.Email,
          dateOfBirth: DataMaskFormtData(response.Data.BornDate),
          cpf: cpfMask(response.Data.DocumentNumber),
          registrationDate: response.Data.CreatedAt,
          sexoId: response.Data.GenderId ? response.Data.GenderId : '0',
          language: response.Data.Language
        })
        setRegistrationFirstState({
          name: response.Data.Name,
          email: response.Data.Email,
          dateOfBirth: DataMaskFormtData(response.Data.BornDate),
          cpf: cpfMask(response.Data.DocumentNumber),
          registrationDate: response.Data.CreatedAt,
          sexoId: response.Data.GenderId ? response.Data.GenderId : '0',
          language: response.Data.Language
        })
        setImage(response.Data.ProfilePhotoUrl ? response.Data.ProfilePhotoUrl : "")
        setImageFirst(response.Data.ProfilePhotoUrl ? response.Data.ProfilePhotoUrl : "")
        if (response.Data.Address) {
          setRegistrationAddress({
            Logradouro: response.Data.Address.Steet,
            Numero: response.Data.Address.Number,
            Complemento: response.Data.Address.Complement,
            Bairro: response.Data.Address.Neighborhood,
            Cep: cepMask(response.Data.Address.ZipCode),
            CidadeId: response.Data.Address.CityId,
            EstadoId: response.Data.Address.State ?? ""
          })

          setRegistrationAddressFirstState({
            Logradouro: response.Data.Address.Steet,
            Numero: response.Data.Address.Number,
            Complemento: response.Data.Address.Complement,
            Bairro: response.Data.Address.Neighborhood,
            Cep: cepMask(response.Data.Address.ZipCode),
            CidadeId: response.Data.Address.CityId,
            EstadoId: response.Data.Address.State ?? ""
          })
          retrieveCity(String(response.Data.Address.State))
        }
        setTabProfessional(response.Data.ProfessionalData ? true : false)
        if (response.Data.ProfessionalData) {
          retrieveCategories(response.Data.ProfessionalData.Categories)
          setCategoriesListSelectedFirstState(response.Data.ProfessionalData.Categories)

          if (response.Data.ProfessionalData.Languages) {
            setLanguageListSelectedFirstState(response.Data.ProfessionalData.Languages)

            let singleText = ''

            response.Data.ProfessionalData.Languages.map((item) => {
              const tempCategories = languageList.filter((itemX) => itemX?.Id === item)[0]?.Name;
              singleText = singleText + tempCategories + ', '
            });
            setSingleTextLanguage(singleText)


            const tempCategories: ICategories[] = []

            response.Data.ProfessionalData.Languages.map((item) => {
              const category = languageList.filter((itemX) => itemX?.Id === item)[0];
              tempCategories.push(category)
            });

            setLanguageListSelected(tempCategories)
          }
          setRegistrationProfessional({
            SobreAutor: response.Data.ProfessionalData.AboutProfessional,
            ConselhoProfissional: response.Data.ProfessionalData.RegulatoryBoardId,
            UfConselhoId: response.Data.ProfessionalData.RegulatoryBoardState,
            LinkReferencia: response.Data.ProfessionalData.SiteUrl,
            LinkFacebook: response.Data.ProfessionalData.FacebookUrl,
            LinkTwitter: response.Data.ProfessionalData.XUrl,
            LinkInstagram: response.Data.ProfessionalData.InstagramUrl,
            NumeroConselho: response.Data.ProfessionalData.RegulatoryBoardNumber,
            RQE: response.Data.ProfessionalData.RQENumber ?? ""
          })

          setRegistrationProfessionalFirstState({
            SobreAutor: response.Data.ProfessionalData.AboutProfessional,
            ConselhoProfissional: response.Data.ProfessionalData.RegulatoryBoardId,
            UfConselhoId: response.Data.ProfessionalData.RegulatoryBoardState,
            LinkReferencia: response.Data.ProfessionalData.SiteUrl,
            LinkFacebook: response.Data.ProfessionalData.FacebookUrl,
            LinkTwitter: response.Data.ProfessionalData.XUrl,
            LinkInstagram: response.Data.ProfessionalData.InstagramUrl,
            NumeroConselho: response.Data.ProfessionalData.RegulatoryBoardNumber,
            RQE: response.Data.ProfessionalData.RQENumber ?? ""
          })
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (err) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
      console.error("Error",err)
    }
    setLoadingGet(false)
  };

  const handleChangeCEP = async (cep: string) => {
    setRegistrationAddress({ ...registrationAddress, Cep: cepMask(cep) })
    try {
      const unmaskedCep = onlyDigits(cep);
      if (unmaskedCep.length === 8) {
        const response = await getCEPAddressUser(unmaskedCep);
        if (response.Success) {
          if (response.Data) {
            setRegistrationAddress({
              ...registrationAddress,
              Cep: response.Data.ZipCode,
              Logradouro: response.Data.Steet,
              Bairro: response.Data.Neighborhood,
              CidadeId: response.Data.CityId,
              EstadoId: response.Data.State,
            })
            retrieveCity(response.Data.State)
          }
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possivél encontrar informações para o CEP informado.')
          });
        }
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar o CEP.')
      });
    }
  };

  const handlePostAddress = async (mobile?: boolean) => {
    setLoading(true);
    try {
      let countError = 0;

      const validation = await registerAddressValidation(registrationAddress);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationAddressError(validation as RegistrationAddressError);
        setLoading(false);
        return;
      }

      const tempState = stateList.filter((itemX) => itemX.Uf === registrationAddress.EstadoId);
      const tempCity = cityList.filter((itemX) => String(itemX.Id) === String(registrationAddress.CidadeId));
      if (tempCity.length <= 0 || tempState.length <= 0) {
        setRegistrationAddressError({
          ...registrationAddressError,
          CidadeId: tempCity.length <= 0 ? "Campo obrigatório" : '',
          EstadoId: tempState.length <= 0 ? "Campo obrigatório" : ''
        })
        setLoading(false);
        return;
      }

      const bodyData = {
        Steet: registrationAddress.Logradouro,
        Number: registrationAddress.Numero,
        Complement: registrationAddress.Complemento,
        Neighborhood: registrationAddress.Bairro,
        ZipCode: onlyDigits(registrationAddress.Cep),
        CityId: registrationAddress.CidadeId,
        State: registrationAddress.EstadoId,
        City: cityList.filter((itemX) => String(itemX.Id) === String(registrationAddress.CidadeId))[0]?.Name ?? '',
      };
      const response = await postAddressUser(bodyData);
      if (response.Success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Edição realizada com sucesso!')
        });

        if (mobile) {
          setEditable(false)
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro editar o endereço, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro editar o endereço, tente novamente.')
      });
    }
    setLoading(false);
  };

  const onCancel = () => {
    setRegistration({
      name: '',
      email: '',
      dateOfBirth: '',
      cpf: '',
      registrationDate: '',
      sexoId: '0',
      language: '1'
    })
    setRegistrationAddress({
      Logradouro: '',
      Numero: '',
      Complemento: '',
      Bairro: '',
      Cep: '',
      CidadeId: '',
      EstadoId: ''
    })
    setRegistrationProfessional({
      SobreAutor: '',
      ConselhoProfissional: '',
      UfConselhoId: '',
      LinkReferencia: '',
      LinkFacebook: '',
      LinkTwitter: '',
      LinkInstagram: '',
      NumeroConselho: '',
      RQE: ''
    })

    setRegistrationFirstState({
      name: '',
      email: '',
      dateOfBirth: '',
      cpf: '',
      registrationDate: '',
      sexoId: '0',
      language: '1'
    })
    setRegistrationAddressFirstState({
      Logradouro: '',
      Numero: '',
      Complemento: '',
      Bairro: '',
      Cep: '',
      CidadeId: '',
      EstadoId: ''
    })
    setRegistrationProfessionalFirstState({
      SobreAutor: '',
      ConselhoProfissional: '',
      UfConselhoId: '',
      LinkReferencia: '',
      LinkFacebook: '',
      LinkTwitter: '',
      LinkInstagram: '',
      NumeroConselho: '',
      RQE: ''
    })

    retrieveUser();
    setHeaderActive(0);
    setEditable(false);
    setOpenConfirmClosingModal(false)
  };

  const onClickSaveMobile = () => {
    if (headerActive === 0) editUser(true);
    if (headerActive === 1) handlePostProfessional(true);
    if (headerActive === 2) handlePostAddress(true);
  };

  const onClickDeleteUser = () => {
    if (firstStage) {
      setFirstStage(false)
    } else if (accountDeleted) {
      logout();
    } else {
      handleCloseAccount()
    }
  };

  const onClickEditPasswordUser = () => {
    if (firstStageRecovered) {
      handleValidatePassword()
    } else if (accountRecovered) {
      cancelChangePassword()
    } else {
      handleChangePassword()
    }
  };

  const onClickDescriptionDeleteUserFistStage = `${firstStage ? t('Tem certeza que deseja encerrar sua conta? Todos os dados referentes à sua conta serão removidos da plataforma.') : ''}`;
  const onClickDescriptionDeleteUser = `${accountDeleted ? t('Sua conta foi encerrada com sucesso.') : onClickDescriptionDeleteUserFistStage}`;

  const handleValidate = async () => {
    let countErrorTotal = 0;

    if (!objetosSaoIguais(registration, registrationFirstState)) {
      let countError = 0;

      const validation = await registerValidation(registration);

      if (typeof validation !== 'boolean') {
        countError++;
        countErrorTotal++;
      }

      if (countError > 0) {
        setRegistrationError(validation as RegistrationError);
      }
    }
    if (!objetosSaoIguais(registrationAddress, registrationAddressFirstState)) {
      let countError = 0;

      const validation = await registerAddressValidation(registrationAddress);

      if (typeof validation !== 'boolean') {
        countError++;
        countErrorTotal++;
      }
      if (countError > 0) {
        setRegistrationAddressError(validation as RegistrationAddressError);
      }
    }
    if (!objetosSaoIguais(registrationProfessional, registrationProfessionalFirstState)) {
      let countError = 0;

      const validation = await registerProfessionalValidation(registrationProfessional);

      if (typeof validation !== 'boolean') {
        countError++;
        countErrorTotal++;
      }
      if (countError > 0) {
        setRegistrationProfessionalError(validation as RegistrationProfessionalError);
      }
    }

    return countErrorTotal;
  };

  const handleValidateSaveTotal = async () => {
    try {
      const countErrorTotal = await handleValidate();

      if (countErrorTotal > 0) {
        setLoading(false);
        return true;
      }
      return false;
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao salvar os dados, tente novamente.')
      });
      return true;
    }
  };

  const handleSaveTotal = async () => {
    setLoading(true);
    try {
      const result = await handleValidateSaveTotal();
      if (result) {
        setLoading(false);
        return;
      }

      if ((!objetosSaoIguais(registration, registrationFirstState)) || (imageFirst !== image)) {
        await editUser();
      }
      if (!objetosSaoIguais(registrationAddress, registrationAddressFirstState)) {
        await handlePostAddress();
      }

      const singleText: string[] = []

      categoriesListSelected.map((item) => {
        singleText.push(item.Id)
      });

      const singleTextLanguage: string[] = []

      languageListSelected.map((item) => {
        singleTextLanguage.push(item.Id)
      });

      if (!objetosSaoIguais(registrationProfessional, registrationProfessionalFirstState) || (JSON.stringify(singleText) !== JSON.stringify(categoriesListSelectedFirstState)) || (JSON.stringify(singleTextLanguage) !== JSON.stringify(languageListSelectedFirstState))) {
        await handlePostProfessional();
      }

      onCancel();
      setEditable(false)
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao salvar os dados, tente novamente.')
      });
    }
    setLoading(false);
  };

  const handleValidatePassword = async () => {
    setLoading(true);
    try {
      if (currentPassword.length <= 0) {
        setCurrentPasswordError("Campo obrigatório");
        setLoading(false);
        return;
      }

      const response = await postCheckPassword(currentPassword);
      if (response.Success) {
        setTokenPassword(response.Data)
        setFirstStageRecovered(false)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Senha Incorreta')
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao confirmar a senha, tente novamente.'),
      });
    }
    setLoading(false);
  };

  const handleChangePassword = async () => {
    setLoading(true);
    try {
      let countError = 0;

      const validation = await resetPasswordValidation(recoverPassword);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRecoverPasswordError(validation as ChangePasswordError);
        setLoading(false);
        return;
      }

      const bodyData = {
        NewPassword: recoverPassword.password,
        Cipher: tokenPassword
      };
      const response = await postChangePassword(bodyData);
      if (response.Success) {
        setAccountRecovered(true)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao alterar a senha, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao  alterar a senha, tente novamente.'),
      });
    }
    setLoading(false);
  };

  const cancelChangePassword = async () => {
    setFirstStageRecovered(true)
    setAccountRecovered(false)
    setOpenChangePasswordModal(false)
    setCurrentPassword('')
    setRecoverPasswordError({
      email: '',
      password: '',
      confirmPassword: '',
      codigo: '',
      hash: '',
    })
  };

  const onCloseDeleteUser = () => {
    setOpenDeleteUserModal(false)
    setFirstStage(true)
    setPassword('')
  };

  const handleChangeModalCloseB = () => {
    setOpenModalImageAdjustment(false)
    setImageSet(undefined)
    setImage('');
  };

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const dispararCliqueInput = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleChangeModalB = (e: File) => {
    setOpenModalImageAdjustment(false)
    if (e) {
      setImageSet(e)
      setImage(URL.createObjectURL(e));
    }
  };

  return (
    <S.Container>
      <HeaderAchievements
        title={t('Meu Perfil')}
        onClick={() => navigate(-1)}
        headerMobileAll
      />

      <S.ContainerContent>
        <S.ContainerTable>
          <div className='divs'>
            <S.LineHeader register={true}>
              <S.ButtonHeader disabled={loading} active={headerActive === 0} onClick={() => setHeaderActive(0)}>
                <Badge className="icon" />
                <h3>{t('DADOS DE ACESSO')}</h3>
              </S.ButtonHeader>

              {tabProfessional &&
                <S.ButtonHeader disabled={loading} active={headerActive === 1} onClick={() => setHeaderActive(1)}>
                  <Person className="icon" />
                  <h3>{t('DADOS PROFISSIONAIS')}</h3>
                </S.ButtonHeader>
              }

              <S.ButtonHeader disabled={loading} active={headerActive === 2} onClick={() => setHeaderActive(2)}>
                <Place className="icon" />
                <h3>{t('ENDEREÇO')}</h3>
              </S.ButtonHeader>
            </S.LineHeader>

            {loadingGet ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <ViewLoading />
              </div>
            ) : (
              <>
                {(larguraDaTela > 800 || headerActive === 0) &&
                  <S.ContainerProfile professional={tabProfessional}>
                    <S.HeaderInfo>
                      <Badge className="icon" />
                      <h3>{t('Dados de Acesso')}</h3>
                    </S.HeaderInfo>

                    <S.BoxImageProfile>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <S.BackgroundImageProfile htmlFor="selecao-arquivo">
                          {image ? (
                            <img src={image} />
                          ) : (
                            <div className='noImage'>
                              <UploadFile className="icon" />
                            </div>
                          )}

                          {editable &&
                            <div className='iconBackground'>
                              <PhotoCamera className="iconCam" />
                            </div>
                          }

                          <S.Input id="selecao-arquivo" onChange={handleChange} type="file" accept="image/*" disabled={!editable} ref={inputFileRef} />
                        </S.BackgroundImageProfile>


                        <div className='InputContainer'>
                          <h1>{t('Data de Cadastro')}</h1>
                          <h2>{DataMaskFormtData(registration.registrationDate)}</h2>
                        </div>
                      </div>

                      <SelectLanguageEditUser language={registration.language} languageUser={(e) => setRegistration({ ...registration, language: e })} />
                    </S.BoxImageProfile>

                    <Input
                      notEditable={!editable}
                      label={t('Nome')}
                      type="name"
                      size="small"
                      value={registration.name}
                      onChange={(e) => setRegistration({ ...registration, name: e.target.value })}
                      error={registrationError.name.length > 0}
                      helperText={registrationError.name}
                      onClick={() => setRegistrationError({ ...registrationError, name: '' })}
                      fullWidth
                      variant="filled"
                      style={{ marginTop: '16px' }}
                    />

                    <Input
                      backBlack={true}
                      notEditable={!editable}
                      disabled={true}
                      label={t('E-mail')}
                      type="email"
                      size="small"
                      value={registration.email}
                      onChange={(e) => setRegistration({ ...registration, email: e.target.value })}
                      error={registrationError.email.length > 0}
                      helperText={registrationError.email}
                      onClick={() => setRegistrationError({ ...registrationError, email: '' })}
                      sx={{
                        marginTop: 2,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                          >
                            <Lock fill={theme.colors.black} />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      variant="filled"
                      style={{ marginTop: '16px' }}

                    />

                    <Input
                      backBlack={true}
                      notEditable={!editable}
                      disabled={true}
                      label={t('Data de Nascimento')}
                      size="small"
                      value={registration.dateOfBirth}
                      onChange={(e) => setRegistration({ ...registration, dateOfBirth: dateMask(e.target.value) })}
                      error={registrationError.dateOfBirth.length > 0}
                      helperText={t(registrationError.dateOfBirth)}
                      onClick={() => setRegistrationError({ ...registrationError, dateOfBirth: '' })}
                      sx={{
                        marginTop: 2,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                          >
                            <Lock fill={theme.colors.black} />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      variant="filled"
                      style={{ marginTop: '16px' }}
                    />

                    <Input
                      backBlack={registrationFirstState.cpf ? true : false}
                      disabled={!editable || registrationFirstState.cpf.length > 0}
                      notEditable={!editable}
                      label={t('CPF')}
                      size="small"
                      value={registration.cpf}
                      onChange={(e) => setRegistration({ ...registration, cpf: cpfMask(e.target.value) })}
                      error={registrationError.cpf.length > 0}
                      helperText={t(registrationError.cpf)}
                      onClick={() => setRegistrationError({ ...registrationError, cpf: '' })}
                      fullWidth
                      variant="filled"
                      style={{ marginTop: '16px' }}
                      sx={{
                        marginTop: 2,
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {registrationFirstState.cpf &&
                              <InputAdornment
                                position="end"
                              >
                                <Lock fill={theme.colors.black} />
                              </InputAdornment>
                            }
                          </>
                        ),
                      }}
                    />

                    {editable ? (
                      <>
                        <FormControl style={{ display: 'flex', marginTop: '24px', width: '100%' }}>
                          <InputLabel id="demo-simple-select-helper-label">{t('Gênero')}</InputLabel>
                          <Select
                            MenuProps={{
                              MenuListProps: {
                                sx: { maxHeight: '180px', overflowY: 'auto' }
                              }
                            }}
                            label={t('Gênero')}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            variant="standard"
                            style={{ display: 'flex', width: '100%' }}
                            value={registration.sexoId}
                            onChange={(e) => setRegistration({ ...registration, sexoId: e.target.value })}
                          >
                            {(BirthDateValidation(onlyDigits(registration.dateOfBirth)).status ? olderGender : gender).map((item) => (
                              <MenuItem key={item.Id} value={item.Id}>{t(item.Titulo)}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <S.CheckBoxWithText>
                          <button onClick={() => setTabProfessional(!tabProfessional)}>
                            {tabProfessional ? <CheckBox className="i" /> : <CheckBoxOutlineBlank className="i" />}
                          </button>

                          <h5>{t('Sou Profissional da Saúde')}</h5>
                        </S.CheckBoxWithText>
                      </>
                    ) : (
                      <Input
                        backBlack={true}
                        notEditable={!editable}
                        disabled={true}
                        label={t('Gênero')}
                        size="small"
                        value={(BirthDateValidation(onlyDigits(registration.dateOfBirth)).status ? olderGender : gender).filter((itemX) => String(itemX.Id) === String(registration.sexoId))[0]?.Titulo ?? '-'}
                        fullWidth
                        variant="filled"
                        style={{ marginTop: '16px' }}
                      />
                    )}
                  </S.ContainerProfile>
                }


                {(larguraDaTela > 800 || headerActive === 1) && tabProfessional &&
                  <S.ContainerProfile active professional={tabProfessional} >
                    <S.HeaderInfo>
                      <Person className="icon" />
                      <h3>{t('Dados Profissionais')}</h3>
                    </S.HeaderInfo>

                    {editable ? (
                      <>
                        <FormControl style={{ display: 'flex', width: '100%', marginTop: '16px' }}>
                          <InputLabel id="demo-simple-select-helper-label">{t('Conselho')}</InputLabel>
                          <Select
                            MenuProps={{
                              MenuListProps: {
                                sx: { maxHeight: '180px', overflowY: 'auto' }
                              }
                            }}
                            label={t('Conselho')}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            variant="standard"
                            style={{ display: 'flex', width: '100%' }}
                            value={registrationProfessional.ConselhoProfissional}
                            error={registrationProfessionalError.ConselhoProfissional.length > 0}
                            onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, ConselhoProfissional: '' })}
                            onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, ConselhoProfissional: e.target.value })}
                          >
                            {adviceList.map((item, index) => (
                              <MenuItem key={index} value={item.Id}>{t(item.Name)}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl style={{ display: 'flex', width: '100%', marginTop: '16px' }}>
                          <InputLabel id="demo-simple-select-helper-label">{t('Estado do Conselho')}</InputLabel>
                          <Select
                            MenuProps={{
                              MenuListProps: {
                                sx: { maxHeight: '180px', overflowY: 'auto' }
                              }
                            }}
                            label={t('Estado do Conselho')}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            variant="standard"
                            style={{ display: 'flex', width: '100%' }}
                            value={registrationProfessional.UfConselhoId}
                            error={registrationProfessionalError.UfConselhoId.length > 0}
                            onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, UfConselhoId: '' })}
                            onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, UfConselhoId: e.target.value })}
                          >
                            {stateList.map((item) => (
                              <MenuItem key={item.Uf} value={item.Uf}>{t(item.Name)}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <SelectMultiple
                          error={categoriesError}
                          resetError={() => setCategoriesError(false)}
                          listSelected={categoriesListSelected}
                          list={categoriesList}
                          label={t('Categorias que atende')}
                          onClickAdd={(e) => {
                            const indexCategorySelected = categoriesListSelected.find((state) => state.Id === e);
                            if (!indexCategorySelected) {
                              const tempCategories = categoriesList.filter((itemX) => itemX?.Id === e)[0];
                              setCategoriesListSelected([...categoriesListSelected, tempCategories])
                            }
                          }}
                          onClickRemove={(e) => {
                            const tempCategories = categoriesListSelected.filter((itemX) => itemX.Id !== e);
                            setCategoriesListSelected(tempCategories)
                          }}
                        />

                        <SelectMultiple
                          error={languageError}
                          resetError={() => setLanguageError(false)}
                          listSelected={languageListSelected}
                          list={languageList}
                          label={t('Idioma(s) que atende')}
                          onClickAdd={(e) => {
                            const indexCategorySelected = languageListSelected.find((state) => state.Id === e);
                            if (!indexCategorySelected) {
                              const tempCategories = languageList.filter((itemX) => itemX?.Id === e)[0];
                              setLanguageListSelected([...languageListSelected, tempCategories])
                            }
                          }}
                          onClickRemove={(e) => {
                            const tempCategories = languageListSelected.filter((itemX) => itemX.Id !== e);
                            setLanguageListSelected(tempCategories)
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Input
                          notEditable={!editable}
                          disabled={true}
                          label={t('Conselho')}
                          size="small"
                          value={adviceList.filter((itemX) => String(itemX.Id) === String(registrationProfessional.ConselhoProfissional))[0]?.Name ?? '-'}
                          fullWidth
                          variant="filled"
                          style={{ marginTop: '16px' }}
                        />

                        <Input
                          notEditable={!editable}
                          disabled={true}
                          label={t('Estado do Conselho')}
                          size="small"
                          value={stateList.filter((itemX) => String(itemX.Uf) === String(registrationProfessional.UfConselhoId))[0]?.Name ?? '-'}
                          fullWidth
                          variant="filled"
                          style={{ marginTop: '16px' }}
                        />

                        <Input
                          notEditable={!editable}
                          disabled={true}
                          label={t('Categorias que atende')}
                          size="small"
                          value={singleText}
                          fullWidth
                          variant="filled"
                          style={{ marginTop: '16px' }}
                        />

                        <Input
                          notEditable={!editable}
                          disabled={true}
                          label={t('Idioma(s) que atende')}
                          size="small"
                          value={singleTextLanguage}
                          fullWidth
                          variant="filled"
                          style={{ marginTop: '16px' }}
                        />
                      </>
                    )}

                    <Input
                      notEditable={!editable}
                      label={t('N° Conselho Profissional')}
                      type="number"
                      value={registrationProfessional.NumeroConselho}
                      onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, NumeroConselho: e.target.value })}
                      size="small"
                      fullWidth
                      error={registrationProfessionalError.NumeroConselho.length > 0}
                      helperText={registrationProfessionalError.NumeroConselho}
                      onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, NumeroConselho: '' })}
                      variant="filled"
                      style={{ marginTop: '16px' }}
                    />

                    <Input
                      notEditable={!editable}
                      label={t('N° R.Q.E')}
                      type="number"
                      value={registrationProfessional.RQE}
                      onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, RQE: e.target.value })}
                      size="small"
                      fullWidth
                      error={registrationProfessionalError.RQE.length > 0}
                      helperText={registrationProfessionalError.RQE}
                      onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, RQE: '' })}
                      variant="filled"
                      style={{ marginTop: '16px' }}
                    />

                    <Input
                      notEditable={!editable}
                      label={t('Link do Site Pessoal')}
                      type="name"
                      value={registrationProfessional.LinkReferencia}
                      onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, LinkReferencia: e.target.value })}
                      size="small"
                      fullWidth
                      error={registrationProfessionalError.LinkReferencia.length > 0}
                      helperText={registrationProfessionalError.LinkReferencia}
                      onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, LinkReferencia: '' })}
                      variant="filled"
                      style={{ marginTop: '16px' }}
                    />

                    <Input
                      notEditable={!editable}
                      label={t('Link do Facebook')}
                      type="name"
                      value={registrationProfessional.LinkFacebook}
                      onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, LinkFacebook: e.target.value })}
                      size="small"
                      fullWidth
                      error={registrationProfessionalError.LinkFacebook.length > 0}
                      helperText={registrationProfessionalError.LinkFacebook}
                      onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, LinkFacebook: '' })}
                      variant="filled"
                      style={{ marginTop: '16px' }}
                    />

                    <Input
                      notEditable={!editable}
                      label={t('Link Instagram')}
                      type="name"
                      value={registrationProfessional.LinkInstagram}
                      onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, LinkInstagram: e.target.value })}
                      size="small"
                      fullWidth
                      error={registrationProfessionalError.LinkInstagram.length > 0}
                      helperText={registrationProfessionalError.LinkInstagram}
                      onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, LinkInstagram: '' })}
                      variant="filled"
                      style={{ marginTop: '16px' }}
                    />

                    <Input
                      notEditable={!editable}
                      label={t('Link Twitter')}
                      type="name"
                      value={registrationProfessional.LinkTwitter}
                      onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, LinkTwitter: e.target.value })}
                      size="small"
                      fullWidth
                      error={registrationProfessionalError.LinkTwitter.length > 0}
                      helperText={registrationProfessionalError.LinkTwitter}
                      onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, LinkTwitter: '' })}
                      variant="filled"
                      style={{ marginTop: '16px' }}
                    />

                    <TextField
                      disabled={!editable}
                      id="outlined-multiline-static"
                      label={t('Sobre o Autor')}
                      multiline
                      rows={4}
                      defaultValue="Default Value"
                      variant="outlined"
                      value={registrationProfessional.SobreAutor}
                      error={registrationProfessionalError.SobreAutor.length > 0}
                      helperText={registrationProfessionalError.SobreAutor}
                      onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, SobreAutor: '' })}
                      style={{ marginTop: '24px', width: '100%' }}
                      onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, SobreAutor: e.target.value?.length <= 400 ? e.target.value : registrationProfessional.SobreAutor })}
                    />
                    {editable &&
                      <S.TextCount>
                        <h5>{registrationProfessional.SobreAutor.length}/400</h5>
                      </S.TextCount>
                    }
                  </S.ContainerProfile>
                }

                {(larguraDaTela > 800 || headerActive === 2) &&
                  <S.ContainerProfile active={!tabProfessional} professional={tabProfessional}>
                    <S.HeaderInfo>
                      <Place className="icon" />
                      <h3>{t('Endereço')}</h3>
                    </S.HeaderInfo>

                    <Input
                      notEditable={!editable}
                      label={t('CEP')}
                      type="name"
                      value={registrationAddress.Cep}
                      onChange={(e) => handleChangeCEP(e.target.value)}
                      size="small"
                      fullWidth
                      error={registrationAddressError.Cep.length > 0}
                      helperText={registrationAddressError.Cep}
                      onClick={() => setRegistrationAddressError({ ...registrationAddressError, Cep: '' })}
                      variant="filled"
                    />

                    {editable ? (
                      <>
                        <FormControl style={{ display: 'flex', width: '100%', marginTop: '20px' }}>
                          <InputLabel id="demo-simple-select-helper-label">{t('UF')}</InputLabel>
                          <Select
                            MenuProps={{
                              MenuListProps: {
                                sx: { maxHeight: '180px', overflowY: 'auto' }
                              }
                            }}
                            label={t('UF')}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            variant="standard"
                            style={{ display: 'flex', width: '100%' }}
                            value={registrationAddress.EstadoId}
                            error={registrationAddressError.EstadoId.length > 0}
                            onClick={() => setRegistrationAddressError({ ...registrationAddressError, EstadoId: '' })}
                            onChange={(e) => {
                              setRegistrationAddress({ ...registrationAddress, EstadoId: String(e.target.value), CidadeId: '' })
                              retrieveCity(String(e.target.value))
                            }}
                          >
                            {stateList.map((item) => (
                              <MenuItem key={item.Uf} value={item.Uf}>{t(item.Name)}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {registrationAddress.EstadoId &&
                          <FormControl style={{ display: 'flex', width: '100%', marginTop: '20px' }}>
                            <InputLabel id="demo-simple-select-helper-label">{t('Cidade')}</InputLabel>
                            <Select
                              MenuProps={{
                                MenuListProps: {
                                  sx: { maxHeight: '180px', overflowY: 'auto' }
                                }
                              }}
                              label={t('Cidade')}
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              variant="standard"
                              style={{ display: 'flex', width: '100%' }}
                              value={registrationAddress.CidadeId}
                              error={registrationAddressError.CidadeId.length > 0}
                              onClick={() => setRegistrationAddressError({ ...registrationAddressError, CidadeId: '' })}
                              onChange={(e) => {
                                setRegistrationAddress({ ...registrationAddress, CidadeId: String(e.target.value) })
                              }}
                            >
                              {cityList.map((item, index) => (
                                <MenuItem key={index} value={item.Id}>{t(item.Name)}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        }
                      </>
                    ) : (
                      <>
                        <Input
                          notEditable={!editable}
                          disabled={true}
                          label={t('UF')}
                          size="small"
                          value={stateList.filter((itemX) => itemX.Uf === registrationAddress.EstadoId)[0]?.Name ?? '-'}
                          fullWidth
                          variant="filled"
                          style={{ marginTop: '16px' }}
                        />

                        <Input
                          notEditable={!editable}
                          disabled={true}
                          label={t('Cidade')}
                          size="small"
                          value={cityList.filter((itemX) => String(itemX.Id) === String(registrationAddress.CidadeId))[0]?.Name ?? '-'}
                          fullWidth
                          variant="filled"
                          style={{ marginTop: '16px' }}
                        />
                      </>
                    )}

                    <Input
                      notEditable={!editable}
                      label={t('Bairro')}
                      type="name"
                      value={registrationAddress.Bairro}
                      onChange={(e) => setRegistrationAddress({ ...registrationAddress, Bairro: e.target.value })}
                      size="small"
                      fullWidth
                      error={registrationAddressError.Bairro.length > 0}
                      helperText={registrationAddressError.Bairro}
                      onClick={() => setRegistrationAddressError({ ...registrationAddressError, Bairro: '' })}
                      variant="filled"
                      style={{ marginTop: '16px' }}
                    />

                    <Input
                      notEditable={!editable}
                      label={t('Logradouro')}
                      type="name"
                      value={registrationAddress.Logradouro}
                      onChange={(e) => setRegistrationAddress({ ...registrationAddress, Logradouro: e.target.value })}
                      size="small"
                      fullWidth
                      error={registrationAddressError.Logradouro.length > 0}
                      helperText={registrationAddressError.Logradouro}
                      onClick={() => setRegistrationAddressError({ ...registrationAddressError, Logradouro: '' })}
                      variant="filled"
                      style={{ marginTop: '16px' }}
                    />

                    <Input
                      notEditable={!editable}
                      label={t('Número')}
                      type="number"
                      value={registrationAddress.Numero}
                      onChange={(e) => setRegistrationAddress({ ...registrationAddress, Numero: e.target.value })}
                      size="small"
                      fullWidth
                      error={registrationAddressError.Numero.length > 0}
                      helperText={registrationAddressError.Numero}
                      onClick={() => setRegistrationAddressError({ ...registrationAddressError, Numero: '' })}
                      variant="filled"
                      style={{ marginTop: '16px' }}
                    />

                    <Input
                      notEditable={!editable}
                      label={t('Complemento (opcional)')}
                      type="name"
                      value={registrationAddress.Complemento}
                      onChange={(e) => setRegistrationAddress({ ...registrationAddress, Complemento: e.target.value })}
                      size="small"
                      fullWidth
                      error={registrationAddressError.Complemento.length > 0}
                      helperText={registrationAddressError.Complemento}
                      onClick={() => setRegistrationAddressError({ ...registrationAddressError, Complemento: '' })}
                      variant="filled"
                      style={{ marginTop: '16px' }}
                    />
                  </S.ContainerProfile>
                }
              </>
            )}
          </div>

          <S.ContainerBottom>
            {larguraDaTela > 800 ?
              <>
                <div className='left'>
                  <ButtonMaterial
                    style={{ borderColor: theme.colorsBase.red, color: theme.colorsBase.red, width: "100%", marginRight: '40px' }}
                    variant="outlined"
                    label={t('ENCERRAR CONTA')}
                    onClick={() => setOpenDeleteUserModal(true)}
                  />

                  <ButtonMaterial
                    style={{ borderColor: theme.colors.secundary, width: "100%" }}
                    variant="outlined"
                    label={t('ALTERAR SENHA')}
                    onClick={() => setOpenChangePasswordModal(true)}
                    disabled={loading}
                  />
                </div>

                <div className='right'>
                  {editable ?
                    <>
                      <ButtonMaterial
                        style={{ borderColor: theme.colors.secundary, marginRight: '16px' }}
                        variant="outlined"
                        label={t('CANCELAR')}
                        onClick={() => setOpenConfirmClosingModal(true)}
                      />

                      <ButtonMaterial
                        style={{ border: `solid 1px ${theme.colors.seventh}` }}
                        variant="contained"
                        color='info'
                        label={t("SALVAR")}
                        onClick={handleSaveTotal}
                        loading={loading}
                      />
                    </>
                    :
                    <ButtonMaterial
                      style={{ border: `solid 1px ${theme.colors.seventh}`, minWidth: "50%" }}
                      variant="contained"
                      color='info'
                      label={t("EDITAR DADOS")}
                      onClick={() => setEditable(true)}
                    />
                  }
                </div>
              </>
              :
              <>
                {editable ?
                  <>
                    <ButtonMaterial
                      style={{ border: `solid 1px ${theme.colors.seventh}`, width: "100%" }}
                      variant="contained"
                      color='info'
                      label={t("SALVAR ALTERAÇÕES")}
                      onClick={onClickSaveMobile}
                      disabled={loading}
                    />

                    <ButtonMaterial
                      style={{ borderColor: theme.colors.secundary, width: "100%", marginTop: '16px' }}
                      variant="outlined"
                      label={t('CANCELAR')}
                      onClick={() => setOpenConfirmClosingModal(true)}
                    />
                  </>
                  :
                  <>
                    <ButtonMaterial
                      style={{ borderColor: theme.colors.secundary, width: "100%" }}
                      variant="outlined"
                      label={t('ALTERAR SENHA')}
                      onClick={() => setOpenChangePasswordModal(true)}
                    />

                    <ButtonMaterial
                      style={{ border: `solid 1px ${theme.colors.seventh}`, width: "100%", marginTop: '16px' }}
                      variant="contained"
                      color='info'
                      label={t("EDITAR DADOS")}
                      onClick={() => setEditable(true)}
                    />

                    <ButtonMaterial
                      style={{ borderColor: theme.colorsBase.red, color: theme.colorsBase.red, width: "100%", marginTop: '16px' }}
                      variant="outlined"
                      label={t('ENCERRAR CONTA')}
                      onClick={() => setOpenDeleteUserModal(true)}
                    />
                  </>
                }
              </>}
          </S.ContainerBottom>
        </S.ContainerTable>
      </S.ContainerContent>

      <ModalCustom
        open={openConfirmClosingModal}
        onClose={() => setOpenConfirmClosingModal(false)}
        onClick={onCancel}
        title={t('Cancelar Edição')}
        description={t('Deseja cancelar a edição do perfil? Todas as alterações não serão salvas.')}
        confirmationButtonText={t('CONFIRMAR')}
        textDeclineButton={t('CANCELAR')}
      />

      <ModalCustom
        open={openDeleteUserModal}
        onClose={() => accountDeleted ? logout() : onCloseDeleteUser()}
        onClick={onClickDeleteUser}
        childrenContent={
          (firstStage === false && accountDeleted === false) && (
            <S.ContainerRecoverPassword>
              <h3>{t('Para encerrar sua conta, informe sua senha atual como confirmação de segurança.')}</h3>

              <Input
                type={showPasswordRegistration ? 'text' : 'password'}
                label={t('Senha Atual')}
                size="small"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={passwordError.length > 0}
                helperText={t(passwordError)}
                onClick={() => setPasswordError("")}
                sx={{
                  marginTop: 2,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => setShowPasswordRegistration((showPassword) => !showPassword)}
                    >
                      {showPasswordRegistration ? (
                        <Visibility fill={theme.colors.nine} />
                      ) : (
                        <VisibilityOff fill={theme.colors.nine} />
                      )}
                    </InputAdornment>
                  ),
                }}
                fullWidth
                variant="filled"
              />

              <a href="/recover_password" onClick={() => {
                cleanLocalStorage()
              }}>{t('Esqueceu a Senha?')}</a>
            </S.ContainerRecoverPassword>
          )
        }
        title={t('Encerrar Conta')}
        description={onClickDescriptionDeleteUser}
        confirmationButtonText={accountDeleted ? t('INÍCIO') : t('PRÓXIMO')}
        textDeclineButton={t('CANCELAR')}
        twoButton={!accountDeleted}
      />

      <ModalCustom
        open={openChangePasswordModal}
        onClose={cancelChangePassword}
        onClick={onClickEditPasswordUser}
        childrenContent={
          <>
            {(firstStageRecovered === true && accountRecovered === false) && (
              <S.ContainerRecoverPassword>
                <h3>{t('Para realizar a alteração da sua senha, é preciso inserir sua senha atual, como medida de segurança.')}</h3>

                <Input
                  label={t('Senha Atual')}
                  type={showPasswordCurrent ? 'text' : 'password'}
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  size="small"
                  fullWidth
                  error={currentPasswordError.length > 0}
                  helperText={currentPasswordError}
                  onClick={() => setCurrentPasswordError('')}
                  variant="filled"
                  sx={{
                    marginTop: 2,
                  }}
                  InputProps={{
                    endAdornment: (
                      <ButtonHidePassword password={showPasswordCurrent} onClick={() => setShowPasswordCurrent((showPasswordCurrent) => !showPasswordCurrent)} />
                    ),
                  }}
                />

                <a href="/recover_password" onClick={() => {
                  cleanLocalStorage()
                }}>{t('Esqueceu a Senha?')}</a>
              </S.ContainerRecoverPassword>
            )}
            {(firstStageRecovered === false && accountRecovered === false) && (
              <S.ContainerRecoverPassword>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  label={t('Nova Senha')}
                  size="small"
                  value={recoverPassword.password}
                  onChange={(e) => setRecoverPassword({ ...recoverPassword, password: validatePassword(e.target.value) })}
                  error={recoverPasswordError.password.length > 0}
                  helperText={t(recoverPasswordError.password)}
                  onClick={() => setRecoverPasswordError({ ...recoverPasswordError, password: '' })}
                  sx={{
                    marginTop: 2,
                  }}
                  InputProps={{
                    endAdornment: (
                      <ButtonHidePassword password={showPassword} onClick={() => setShowPassword((showPassword) => !showPassword)} />
                    ),
                  }}
                  fullWidth
                  variant="filled"
                />

                <ContainerPasswordValidation>
                  <h1 className='titlePassword'>{t('Sua senha deve conter:')}</h1>

                  <div className='boxPassword'>
                    <TextValidationPassword active={eightCharacters}>{t('• No mínimo 8 caracteres')}</TextValidationPassword>
                    <TextValidationPassword active={capitalLetter}>{t('• Pelo menos uma letra MAIÚSCULA')}</TextValidationPassword>
                    <TextValidationPassword active={lowerCase}>{t('• Pelo menos uma letra minúscula')}</TextValidationPassword>
                    <TextValidationPassword active={number}>{t('• Pelo menos um número')}</TextValidationPassword>
                    <TextValidationPassword active={spaceCharacter}>{t('• Pelo menos um caractere especial (*, -, #, $, @)')}</TextValidationPassword>
                  </div>
                </ContainerPasswordValidation>

                <Input
                  type={showConfirmPassword ? 'text' : 'password'}
                  label={t('Confirmar Senha')}
                  size="small"
                  value={recoverPassword.confirmPassword}
                  onChange={(e) => setRecoverPassword({ ...recoverPassword, confirmPassword: e.target.value })}
                  error={recoverPasswordError.confirmPassword.length > 0}
                  helperText={t(recoverPasswordError.confirmPassword)}
                  onClick={() => setRecoverPasswordError({ ...recoverPasswordError, confirmPassword: '' })}
                  sx={{
                    marginTop: 2,
                  }}
                  InputProps={{
                    endAdornment: (
                      <ButtonHidePassword password={showConfirmPassword} onClick={() => setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)} />
                    ),
                  }}
                  fullWidth
                  style={{ marginTop: '16px' }}
                  variant="filled"
                />

                <ContainerPasswordValidation>
                  <h1 className='titlePassword'>{t('Deve ser igual à senha criada.')}</h1>
                </ContainerPasswordValidation>
              </S.ContainerRecoverPassword>
            )}
          </>
        }
        title={t('Alterar Senha')}
        description={accountRecovered ? t('Sua senha foi alterada com sucesso!') : null}
        confirmationButtonText={accountRecovered ? t('VOLTAR') : t('PRÓXIMO')}
        textDeclineButton={t('CANCELAR')}
        twoButton={!accountRecovered}
      />

      <ModalCustom
        open={openModalImageAdjustment}
        onClose={handleChangeModalCloseB}
        onClick={() => { }}
        childrenContent={<>{imageSet && <ImageAdjustment aspectRatioSquare={true} onClickAdd={dispararCliqueInput} onClickSave={handleChangeModalB} imageSet={imageSet} />}</>}
        title=''
        buttonsEnabled={false}
        styleLine={{ backgroundColor: 'transparent', marginBottom: '24px' }}
        style={{ backgroundColor: theme.colorsBase.white2 }}
      />
    </S.Container>
  )
}

export default MyProfile
