import React, { useEffect, useState, ChangeEvent } from 'react';

import * as S from './styles'
import { AddPhotoAlternate, ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import ToastNotification from '../../components/ToastNotification';
import ViewLoading from '../../components/ViewLoading';
import ViewError from '../../components/ViewError';
import { Pagination } from '@mui/material'
import ModalCustom from '../../components/ModalCustom';
import Input from '../../components/Input';
import { Registration, RegistrationError } from './types';
import { registerValidation } from './validations';
import InputSearch from '../../components/InputSearch';
import TableGifts from '../../components/TableGifts';
import { getGifts, postGifts } from '../../services/Gifts';
import { IGifts } from '../../services/Gifts/types';
import offImage from '../../assets/images/imgErro.png'

const Gifts: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [categoriesList, setCategoriesList] = useState<IGifts[]>([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState<number | undefined>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [openRegisterCategoriesModal, setOpenRegisterCategoriesModal] = useState(false)
  const [loadingRegisterCategory, setLoadingRegisterCategory] = useState(false)
  const [registration, setRegistration] = useState<Registration>(new Registration());
  const [registrationError, setRegistrationError] = useState<RegistrationError>(new RegistrationError());
  const [value, setValue] = useState('');
  const [imageSet, setImageSet] = useState<File>();
  const [image, setImage] = useState<string>(offImage);
  const [imageError, setImageError] = useState<string>('');

  useEffect(() => {
    retrieveSponsor(1, true);
  }, []);

  const registerSponsor = async () => {
    setLoadingRegisterCategory(true)
    try {
      let countError = 0;

      const validation = await registerValidation(registration);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0 || !imageSet) {
        setRegistrationError(validation as RegistrationError);
        setLoadingRegisterCategory(false);
        if (!imageSet) {
          setImageError(t('Campo obrigatório') || 'Campo obrigatório')
        }
        return;
      }

      const data = new FormData();
      data.append('Nome', registration.nome);
      data.append('QtdPontos', registration.pontos);
      if (imageSet) data.append('Foto', imageSet as File);

      const response = await postGifts(data);
      if (response.Success) {
        setImage(offImage)
        setImageSet(undefined)
        setRegistration({
          nome: '',
          pontos: ''
        })
        retrieveSponsor(1, true)
        setOpenRegisterCategoriesModal(false)
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Brinde cadastrado com sucesso!')
        });
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao cadastrar o brinde, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao cadastrar o brinde, tente novamente.')
      });
    }
    setLoadingRegisterCategory(false)
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setImageError('')
    const selectedImage = e.target.files?.[0];
    if (selectedImage) {
      setImageSet(selectedImage)
      setImage(URL.createObjectURL(selectedImage));
    }
  };

  const retrieveSponsor = async (page: number = 1, clear: boolean = false) => {
    setLoading(true)
    try {
      if (clear) {
        setCategoriesList([]);
      }
      const bodyData = {
        PageNumber: page,
        PageSize: 8,
        Nome: value
      };
      const response = await getGifts(bodyData);
      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }
        setCategoriesList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os brindes, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os brindes, tente novamente.')
      });
    }
    setLoading(false)
  };

  return (
    <S.Container>
      <S.ContainerBackButton onClick={() => navigate('/')} >
        <ArrowBack className="iconBackButton" />
        <h5>{t('Voltar')}</h5>
      </S.ContainerBackButton>

      <S.ContainerContent>
        <S.Line>
          <h5>{t('Brindes')}</h5>
        </S.Line>

        <S.ContainerTable>
          <S.ContainerSearch>
            <InputSearch
              placeholder={t('Pesquise pelo nome do brinde') || "Pesquise pelo nome do brinde"}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              variant="outlined"
              style={{ width: '50%', marginLeft: '16px', marginRight: '16px' }}
            />

            <ButtonMaterial
              style={{ border: `solid 1px ${theme.colors.seventh}` }}
              variant="contained"
              color='info'
              label={t('Pesquisar')}
              onClick={() => retrieveSponsor(1, true)}
            />
          </S.ContainerSearch>

          <div className='containerButton'>
            <ButtonMaterial
              style={{ border: `solid 1px ${theme.colors.seventh}` }}
              variant="outlined"
              color='primary'
              label={t('NOVO BRINDE')}
              onClick={() => setOpenRegisterCategoriesModal(true)}
            />
          </div>

          {loading ? (
            <ViewLoading />
          ) : (
            <>
              {categoriesList.length > 0 ? (
                <>
                  <TableGifts listUsers={categoriesList} updateList={() => retrieveSponsor(1, true)} />
                  <S.Pages>
                    <Pagination
                      showFirstButton
                      showLastButton
                      count={totalPages}
                      page={currentPage}
                      color="primary"
                      onChange={(e, p) => {
                        e.preventDefault()
                        e.stopPropagation()
                        retrieveSponsor(p)
                      }}
                    />
                  </S.Pages>
                </>
              ) : (
                <ViewError message={t('Nenhum brinde encontrado')} />
              )}
            </>
          )}
        </S.ContainerTable>
      </S.ContainerContent>

      <ModalCustom
        open={openRegisterCategoriesModal}
        onClose={() => setOpenRegisterCategoriesModal(false)}
        onClick={() => registerSponsor()}
        childrenContent={
          <>
            <S.BackgroundImageProfile htmlFor="selecao-arquivo">
              <img src={image} />

              <div className='iconBackground'>
                <AddPhotoAlternate className="iconCam" />
              </div>

              <S.Input id="selecao-arquivo" onChange={handleChange} type="file" accept="image/*" />
            </S.BackgroundImageProfile>

            <h4 style={{ color: 'red', marginBottom: '8px' }}>{imageError}</h4>

            <Input
              label={t('Nome')}
              type="name"
              value={registration.nome}
              onChange={(e) => setRegistration({ ...registration, nome: e.target.value })}
              size="small"
              fullWidth
              error={registrationError.nome ? true : false}
              helperText={registrationError.nome}
              onClick={() => setRegistrationError({ ...registrationError, nome: '' })}
              variant="filled"
              style={{ width: '50%', marginBottom: '16px' }}
            />

            <Input
              label={t('Quantidade de Pontos')}
              type="number"
              value={registration.pontos}
              onChange={(e) => setRegistration({ ...registration, pontos: e.target.value })}
              size="small"
              fullWidth
              error={registrationError.pontos ? true : false}
              helperText={registrationError.pontos}
              onClick={() => setRegistrationError({ ...registrationError, pontos: '' })}
              variant="filled"
              style={{ width: '50%', marginBottom: '16px' }}
            />
          </>
        }
        loading={loadingRegisterCategory}
        title={t('Novo Brinde')}
        confirmationButtonText={t('SALVAR')}
        textDeclineButton={t('CANCELAR')}
      />

    </S.Container>
  )
}

export default Gifts
