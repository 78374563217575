import { sendRequest } from '../RequestService';
import { IBodyGET, INotification } from './types';

const defaultRoot = 'notifications';

export const getNotifications = async (body: IBodyGET) => {
  let url = `${defaultRoot}`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Id) url += `&Id=${body.Id}`;
  if (body?.Visualizadas) url += `&IsViewed=${body.Visualizadas}`;
  url += `&OrderByProperty=-Id`;
  return await sendRequest<INotification[], IBodyGET>(url, 'GET')
}

export const postNotificationRead = async (id: string) => {
  const url = `${defaultRoot}/viewds/${id}`
  return await sendRequest<boolean, boolean>(url, 'PATCH')
}

export const deleteNotification = async (id: string) => {
  const url = `${defaultRoot}/${id}`
  return await sendRequest<boolean, boolean>(url, 'DELETE')
}
