import { sendRequest } from '../RequestService';
import { IGETContacts, IContacts, IPostContact, IDonatePoints } from './types';

const defaultRoot = 'user-accounts/contacts';

export const getContacts = async (body: IGETContacts) => {
  let url = `${defaultRoot}`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Name=${body.Nome}`;
  return await sendRequest<IContacts[], IGETContacts>(url, 'GET')
}

export const getInvitationsSent = async (body: IGETContacts) => {
  let url = `${defaultRoot}/invites/sent`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Name=${body.Nome}`;
  if (body?.Status) url += `&Status=${body.Status}`;
  return await sendRequest<IContacts[], IGETContacts>(url, 'GET')
}

export const getInvitationsReceived = async (body: IGETContacts) => {
  let url = `${defaultRoot}/invites/received`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Name=${body.Nome}`;
  return await sendRequest<IContacts[], IGETContacts>(url, 'GET')
}

export const postContact = async (body: IPostContact) => {
  const url = `${defaultRoot}/invites`
  return await sendRequest<boolean, IPostContact>(url, 'POST', body)
}

export const postAcceptInvite = async (id: string) => {
  const url = `${defaultRoot}/invites/${id}/accept`
  return await sendRequest<boolean, string>(url, 'PUT')
}

export const postRefuseInvitation = async (id: string) => {
  const url = `${defaultRoot}/invites/${id}/refuse`
  return await sendRequest<boolean, string>(url, 'PUT')
}

export const postDonatePoints = async (body: IDonatePoints) => {
  const url = `user-wallets/donate-points`
  return await sendRequest<boolean, IDonatePoints>(url, 'POST', body)
}
