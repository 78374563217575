import React from 'react'

import { Search } from '@mui/icons-material'
import { TextField, InputAdornment, OutlinedTextFieldProps } from '@mui/material'
import { theme } from '../../styles/theme'

interface InputSearchProps extends OutlinedTextFieldProps {
}

const InputSearch: React.FC<InputSearchProps> = (props: InputSearchProps) => {
  return (
    <TextField
      color='secondary'
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {' '}
            <Search style={{ color: theme.colors.seventh }} />{' '}
          </InputAdornment>
        ),
        style: {
          border: `solid 1px ${theme.colors.seventh}`,
          borderRadius: 40,
          color: theme.colorsBase.black3
        }
      }}
      size="small"
      {...props}
    />
  )
}

export default InputSearch
