import * as yup from 'yup';
import { onlyDigits } from '../../utils/Mask';

import { verifyClassEmpty } from '../../utils/ClassEmpty';
import { BirthDateValidation, genericValidation, isValidCpf } from '../../utils/ValidationGenerics';

import { Registration, RegistrationAddress, RegistrationAddressError, RegistrationError, RegistrationProfessional, RegistrationProfessionalError } from './types';


export const registerProfessionalValidation = async (object: RegistrationProfessional): Promise<true | RegistrationProfessional> => {
  const schemaRegister = yup.object().shape({
    SobreAutor: yup.string(), 
    ConselhoProfissional: yup.string().required(),
    NumeroConselho: yup.string().required(),
    UfConselhoId: yup.string().required(),
    LinkReferencia: yup.string().url(),
    LinkFacebook: yup.string().url(),
    LinkTwitter: yup.string().url(),
    LinkInstagram: yup.string().url(),
    RQE: yup.string().required()
  });

  const mountError = await genericValidation<RegistrationProfessional, RegistrationProfessionalError>(schemaRegister, object, new RegistrationProfessional());

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};

export const registerAddressValidation = async (object: RegistrationAddress): Promise<true | RegistrationAddress> => {
  const schemaRegister = yup.object().shape({
    Cep: yup.string().required(),
    Logradouro: yup.string().required(),
    Bairro: yup.string().required(),
    Numero: yup.string().required(),
    CidadeId: yup.string().required(),
    EstadoId: yup.string().required()
  });

  const mountError = await genericValidation<RegistrationAddress, RegistrationAddressError>(schemaRegister, object, new RegistrationAddress());

  if (object.Cep.length > 0 && object.Cep.length < 9) {
    mountError.Cep = 'CEP inválido';
  }

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};

export const registerValidation = async (object: Registration): Promise<true | Registration> => {
  const schemaRegister = yup.object().shape({
    dateOfBirth: yup.string().required(),
    cpf: yup.string().required(),
    email: yup.string().email().required(),
    name: yup.string().required(),
  });

  const mountError = await genericValidation<Registration, RegistrationError>(schemaRegister, object, new Registration());

  const cpfLenght = onlyDigits(object.cpf).length;
  if (cpfLenght > 0 && cpfLenght <= 11) {
    if (!isValidCpf(object.cpf)) mountError.cpf = 'CPF inválido';
  }

  const dateValidation = BirthDateValidation(onlyDigits(object.dateOfBirth));
  if (object.dateOfBirth.length > 0) {
    if (!dateValidation.status) {
      mountError.dateOfBirth = dateValidation.msg;
    }
  }

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};
