import { Add } from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonScrollToTop } from '../../pages/Home/styles'

interface IProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  title?: any
  onClick: () => void
}

const ButtonCreateMobileSpecialProgram: React.FC<IProps> = ({ title, onClick }) => {
  const { t } = useTranslation();

  return (
    <ButtonScrollToTop onClick={onClick}>
      <Add className="icon" />
      <h5>{title ?? t('CRIAR NOVO PROGRAMA ESPECIAL')}</h5>
    </ButtonScrollToTop>
  )
}

export default ButtonCreateMobileSpecialProgram
