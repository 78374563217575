import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  html, body, #root {
    max-width: 100vw;
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
    font-family: 'Barlow', Medium;
  }   

  button, a, ::placeholder {
    cursor: pointer;
    font-family: 'Barlow', Medium;
  }
`
