import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Title = styled.h1`
  width: 100%;
  text-align: left;
  font-size: ${responsiveFont(34)};
  color: ${(props) => props.theme.colors.seventh};
  font-weight: 400;
  font-family: 'Barlow', Medium;

  @media (min-width: 1600px) {
    font-size: ${responsiveFont(36)};
  }

  @media (max-width: 680px) {
    font-size: ${responsiveFont(24)};
    text-align: center;
  }
`

export const Message = styled.h1`
  width: 100%;
  margin-top: 24px;
  text-align: left;
  font-size: ${responsiveFont(16)};
  color: ${(props) => props.theme.colorsBase.black};
  font-weight: 400;
  font-family: 'Barlow', Medium;

  @media (min-width: 1600px) {
    font-size: ${responsiveFont(18)};
  }

  @media (max-width: 680px) {
    font-size: ${responsiveFont(14)};
    margin-top: 32px;
    text-align: center;
  }
`

export const ContainerContent = styled.div`
flex: 1;
width: 70%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
padding-top: 40px;

.image {
  width: 240px;
  object-fit: cover;

  @media (max-width: 680px) {
    width: 160px;
    margin-bottom: 36px;
  }

  @media (min-width: 1600px) {
    width: 260px;
  }
}

.box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-right: 40px;
}

@media (max-width: 680px) {
  flex-direction: column-reverse;
  width: 90%;
  padding-top: 80px;

  .box {
    align-items: center;
    justify-content: center;
    padding-right: 0px;
    margin-bottom: 40px;
  }
}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: ${(props) => props.theme.colorsBase.white};
`