

export const emptyItem: IUsers = {
  Id: "",
  Name: "",
  Email: "",
  Enabled: true,
  CreatedAt: "",
  Role: "",
}

export interface ITableUsers {
  listUsers: IUsers[]
  updateList: () => void
}

export interface IUsers {
  Id: string,
  Name: string,
  Email: string,
  Enabled: boolean,
  CreatedAt: string
  Role: string;
}
