import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles'
import { Delete, Done, ExpandLess, ExpandMore } from '@mui/icons-material';
import { theme } from '../../../styles/theme';
import ReactAudioPlayer from 'react-audio-player';
import { Tooltip } from '@mui/material';
import { IArrayQuestions, } from '../../../services/Challenges/types';
import { useIsSm } from '../../../hooks/useIsSm';

interface IProps {
  item: IArrayQuestions
  onClickDelete?: () => void
  index: number
  edit?: boolean
}

const CardQuizResume: React.FC<IProps> = ({ item, onClickDelete, index, edit = false }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false)
  const isSm = useIsSm()

  return (
    <S.ContainerCardQuizResume style={{ marginBottom: edit ? '8px' : '0px', marginTop: edit ? '8px' : '12px' }}>
      <div className='topTitle'>
        <div className='numberQuestionBox'>
          <h2 className='numberQuestion'>{index + 1}</h2>
        </div>

        <h2 className='titleQuestion'>{item.Question}</h2>

        <Tooltip title={t('Excluir pergunta')}>
          <button className='buttonDeleteQuestion' onClick={onClickDelete}>
            <Delete className='iDeleteQuestion' />
          </button>
        </Tooltip>
      </div>

      <h2 className='responseQuestion'>{edit ? item?.Alternatives?.filter((itemX) => itemX?.RightAnswer === true)[0].Answer : item?.QuizAlternatives?.filter((itemX) => itemX?.RightAnswer === true)[0].Answer}</h2>

      {item.AudioUrl && <div className='boxAudioWithExpansive'> <ReactAudioPlayer
        src={item.AudioUrl}
        controls
        style={{ margin: '0px', width: isSm ? '100%' : '70%' }}
      />  </div>}

      {open && <div className='containerAlternatives'>
        {(edit ? item.Alternatives : item.QuizAlternatives)?.map((item, index) => (
          <div key={item.Id} className='boxAlternative' style={{ backgroundColor: item.RightAnswer ? theme.colorsBase.green4 : 'transparent', border: item.RightAnswer ? `solid 1px ${theme.colorsBase.green3}` : 'solid 1px transparent' }}>
            <h3>{index + 1}. </h3>
            <h5>{item.Answer}</h5>
            {item.RightAnswer && <Done className="iconDone" />}
          </div>
        ))}
      </div>}

      <div className='boxExpansive'>
        <button className='buttonBoxExpansive' onClick={() => setOpen(!open)}>
          <h2 className='textExpansive'>{t('Visualizar alternativas')}</h2>
          {open ? <ExpandLess className='iExpansiveQuestion' /> : <ExpandMore className='iExpansiveQuestion' />}
        </button>
      </div>
    </S.ContainerCardQuizResume>
  )
}

export default CardQuizResume;
