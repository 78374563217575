import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: ${(props) => props.theme.colorsBase.white};
`

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  border: solid 1px ${(props) => props.theme.colorsBase.gray10};
  border-radius: 8px;
  padding: 16px 16.5px;
  margin-top: 8px;
  margin-bottom: 24px;

  .login {
    width: 50%;

    .belowTheInput {
      display: flex;
      align-items: flex-end;
      margin-top: 4px;
      margin-bottom: 24px;
      flex-direction: column;
      justify-content: flex-end;

      .rememberPassword {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: none;
        background-color: transparent;
        width: 100%;
        margin-top: 24px;


        button {
          display: flex;
          border: none;
          background: none;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding: 4px;
    
          .i {
            color: ${(props) => props.theme.colors.fourteen};
            font-size: 24px;
          }

          .iTrue {
            color: ${(props) => props.theme.colors.seventh};
            font-size: 24px;
          }
    
          h5 {
            margin-left: 8px;
            line-height: 16px;
            text-align: left;
            font-size: ${responsiveFont(12)};
            color: ${(props) => props.theme.colors.black};
            font-weight: 400;
            font-family: 'Barlow', Regular;
        
            @media (min-width: 1600px) {
              font-size: ${responsiveFont(14)};
            }
          }
        }
      }

      .recoverPassword {
        display: flex;
        align-items: center;
        border: none;
        background-color: transparent;
        justify-content: flex-end;

        &:hover {
          opacity: 0.8;
        }

        line-height: 16px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.seventh};
        font-weight: 400;
        font-family: 'Barlow', Regular;
      
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
        }
      
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }
  }

  .register {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0px 7.5px 16px 7.5px;
  
    .containerVerticalOrLandscape {
      display: flex;
      flex-direction: column; 
      width: 100%;
    }

    .verticalOrLandscape {
      display: flex;
      flex-direction: row; 
      width: 100%;

      .divResponsivenessLeft {
        display: flex;
        width: 100%;
        margin-top: 24px;
        align-self: flex-start;
        flex-direction: column;
      }

      .divResponsivenessRight {
        display: flex;
        width: 100%;
        margin-left: 16px;
        margin-top: 24px;
        flex-direction: column;
        align-self: flex-start;
      }
      
      .divResponsivenessCenter {
        display: flex;
        width: 100%;
        margin-top: 24px;
        align-self: flex-end;
      }

      @media (max-width: 680px) {
        flex-direction: column;

        .divResponsivenessLeft {
          margin-top: 16px;
          display: flex;
          align-self: flex-end;
        }
  
        .divResponsivenessRight {
          margin-top: 16px;
          margin-left: 0px;
          display: flex;
          align-self: flex-end;
        }

        .divResponsivenessCenter {
          display: flex;
          width: 100%;
          margin-top: 16px;
          align-self: flex-end;
        }
      }
    }


    .terms {
      width: 70%;
      display: flex;
      margin: 24px 0px 0px 0px;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      .containerAcceptTerms {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: none;
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 16px;

        .buttonClickTerms {
          display: flex;
          border: none;
          background: none;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
    
          .i {
            color: ${(props) => props.theme.colors.fourteen};
            font-size: 24px;
          }

          .iTrue {
            color: ${(props) => props.theme.colors.seventh};
            font-size: 24px;
          }
        }

        .boxTerms {
          display: flex;
          flex-direction: column;
          align-items:  flex-start;
          width: 100%;
          justify-content: center;
          margin-left: 4px;
        }

        h5 {
          margin-bottom: 2px;
          letter-spacing: 0.4px;
          text-align: left;
          font-size: ${responsiveFont(12)};
          color: ${(props) => props.theme.colors.black};
          font-weight: 400;
          font-family: 'Barlow', Regular;
      
          @media (min-width: 1600px) {
            font-size: ${responsiveFont(14)};
          }
        }

        .buttonTerms {
          border: 0;
          background: none;
  
          h4 {
            letter-spacing: 0.4px;
            text-align: center;
            font-size: ${responsiveFont(12)};
            color: ${(props) => props.theme.colors.primary};
            font-weight: 400;
            font-family: 'Barlow', Regular;
            text-decoration: underline;
  
            @media (min-width: 1600px) {
              font-size: ${responsiveFont(14)};
            }
          }
        }

      }
      .icon {
        color: ${(props) => props.theme.colors.nine};
        width: 24px;
        height: 24px;
        top: 0px;
        right: 0px;
        position: absolute;

        @media (max-width: 680px) {
         display: none;
        }
      }
    }
  }

  @media (max-width: 680px) {
    width: 100%;
    border: none;
    border-radius: 0px;
    margin-top: 0px;
    padding: 0px 16.5px 16px 16.5px;

    .register {
      .terms {
        width: 100%;

        .containerAcceptTerms {
          margin-bottom: 32px;
        }
      }
    }

    .login {
      width: 100%;

      .belowTheInput {  
        margin-bottom: 92px;

        .rememberPassword {
          margin-top: 4px;
        }
      }
    }
  }

  @media (min-width: 681px) {
    width: 80%;
  }

  @media (min-width: 900px) {
    width: 50%;
  }
`

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // border: solid 0.5px ${(props) => props.theme.colors.ten};
  height: 0.5px;
  background-color: ${(props) => props.theme.colorsBase.gray9};
  margin: 24px 0;

  h5 {
    line-height: 16px;
    letter-spacing: 0.1px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    background-color: ${(props) => props.theme.colorsBase.white};
    padding: 8px;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(14)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }
`

export const ContainerSocialLoginButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  flex-direction: row;

  @media (max-width: 680px) {
    width: 100%;
    flex-direction: column;
  }
`

export const SocialLoginButtonFacebook = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 0.5px ${(props) => props.theme.colors.eleven};
  background-color: #0D47A1;
  border-radius: 4px;
  height: 40px;
  margin-right: 8px;
  padding-left: 8px;
  padding-right: 8px;

  @media (max-width: 680px) {
    margin-bottom: 16px;
    margin-right: 0px;
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }

  img {
    width: 20px;
    object-fit: cover;
    margin-right: 8px;
  }

  h5 {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.twelve};
    font-weight: 500;
    font-family: 'Barlow', Regular;
    opacity: 0.8;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(12)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(12)};
    }
  }
`

export const SocialLoginButtonGoogle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: solid 0.5px ${(props) => props.theme.colors.eleven};
  border-radius: 4px;
  height: 40px;
  margin-left: 8px;
  
  &:hover {
    opacity: 0.8;
  }

  img {
    width: 20px;
    object-fit: cover;
    margin-right: 8px;
  }

  h5 {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Regular;
    opacity: 0.8;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(12)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(12)};
    }
  }
`

export const LineHeader = styled.div<{ register?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  border-bottom: solid 0.5px ${(props) => props.theme.colorsBase.gray9};
  margin-bottom: 48px;

  @media (max-width: 680px) {
    width: 100%;
    margin-bottom: ${(props) => (props.register ? '8px' : ' 48px')};
  }
`

export const ButtonHeader = styled.button<{ active?: boolean }>`
  width: 40%;
  border: none;
  border-bottom: solid 2px ${(props) => (props.active ? props.theme.colors.seventh : 'transparent')};
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }
  
    h5 {
      line-height: 16px;
      letter-spacing: 1.07px;
      text-align: center;
      font-size: ${responsiveFont(12)};
      color: ${(props) => (props.active ? props.theme.colors.seventh : props.theme.colors.friday)};
      font-weight: 500;
      font-family: 'Barlow', Medium;
      padding: 16px 8px 16px 8px;
  
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(12)};
      }
    }

  @media (max-width: 680px) {
    width: 50%;
  }
`





export const ContainerPasswordValidation = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 4px 0 0px 0;

  .titlePassword {
    line-height: 16px;
    letter-spacing: 0.1px;
    text-align: center;
    font-size: ${responsiveFont(10)};
    color: ${(props) => props.theme.colors.friday};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(8)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(12)};
    }
  }

  .boxPassword {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 4px;
  }

  @media (max-width: 680px) {
    margin: 4px 0 4px 0;
  }
`

export const TextValidationPassword = styled.h5<{ active?: boolean }>`
  text-align: left;
  font-size: ${responsiveFont(10)};
  color: ${(props) => (props.active ? props.theme.colorsBase.green2 : props.theme.colors.friday)};
  font-weight: 400;
  font-family: 'Barlow', Regular;
  margin: 2px 0;

  @media (max-width: 680px) {
    font-size: ${responsiveFont(10)};
  }

  @media (min-width: 1600px) {
    font-size: ${responsiveFont(12)};
  }
`