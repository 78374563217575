import { Refresh } from '@mui/icons-material';
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import * as S from './styles'

export interface IProps {
  message?: string;
  children?: ReactNode;
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingElement = styled(Refresh)`
  animation: ${spin} 1s linear infinite;
`;

const ViewLoading: React.FC<IProps> = (props: IProps) => {
  const { message, children } = props;
  const { t } = useTranslation();
  return (
    <S.Container>
      <RotatingElement />
      <S.Message>
        {message ?? children ?? t('Carregando os dados')}
      </S.Message>
    </S.Container>
  )
}

export default ViewLoading;
