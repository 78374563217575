import React from 'react';
import { Box, Paper, Stack, StackProps, Typography } from '@mui/material';
import { WithTranslationProps, withTranslation } from 'react-i18next';

import CircularPercent from '../../../components/CircularPercent';
import { IChallengeCurationCounter } from '../../../services/Dashboard/CurationsDashboard/types';
import { theme } from '../../../styles/theme';

interface IProps extends StackProps, WithTranslationProps {
  curationCounter: IChallengeCurationCounter
}

const InspectionOverviewCard: React.FC<IProps> = ({ curationCounter, i18n, ...props }) => (
  <Paper component={Stack} {...props}>
    <Typography fontSize="20px" fontWeight="400" color='#201E1E' p={2}>
      {i18n?.t("Total de desafios moderados")}: {curationCounter.CurationCount}
    </Typography>
    <Stack direction="row" spacing={4} px={2} pb={2} justifyContent="center">
      <Stack direction="row" alignItems="center" spacing={0.8}>
        <Box width="14px" height="14px" bgcolor={theme.colors.graphicGreen} borderRadius="50%" />
        <Typography>{i18n?.t("Aprovados")}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={0.8}>
        <Box width="14px" height="14px" bgcolor={theme.colors.graphicRed} borderRadius="50%" />
        <Typography>{i18n?.t("Reprovados")}</Typography>
      </Stack>
    </Stack>

    <Stack direction="row" justifyContent="center" spacing={3}>
      <Stack alignItems="center">
        <CircularPercent
          value={curationCounter.PercentageApproved}
          color={theme.colors.graphicGreen}
          backgroundColor={theme.colors.graphicBackgroundGreen}
        >
          <Typography fontSize="24px" fontFamily="Barlow">{curationCounter.PercentageApproved}%</Typography>
        </CircularPercent>
        <Typography textAlign="center">{i18n?.t("Desafios aprovados")}: {curationCounter.ApprovedCount}/{curationCounter.CurationCount}</Typography>
      </Stack>
      <Stack alignItems="center">
        <CircularPercent
          value={curationCounter.PercentageUnapproved}
          color={theme.colors.graphicRed}
          backgroundColor={theme.colors.graphicBackgroundRed}
        >
          <Typography fontSize="24px" fontFamily="Barlow">{curationCounter.PercentageUnapproved}%</Typography>
        </CircularPercent>
        <Typography textAlign="center">{i18n?.t("Desafios reprovados")}: {curationCounter.UnapprovedCount}/{curationCounter.CurationCount}</Typography>
      </Stack>
    </Stack>
  </Paper>
);

export default withTranslation()(InspectionOverviewCard);
