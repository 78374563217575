import styled from 'styled-components';                   

                      
export const ButtonScrollToTop = styled.a`
  position: fixed;
  right: 24px;
  bottom: 24px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 90;
  background-color:  ${(props) => props.theme.colors.secundary};

  .icon {
    position: absolute;
    top: 8px;
    left: 8px;
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`
