import React, { useState } from 'react'

import { Edit, Visibility, VisibilityOff, ArrowDropDown } from '@mui/icons-material'
import { Tooltip, } from '@mui/material'
import { emptyItem, ITableUsers, Registration, RegistrationError } from './types'
import { useTranslation } from 'react-i18next'
import ModalCustom from '../ModalCustom'
import ToastNotification from '../ToastNotification'
import { disableCategory, editCategories, enableCategory } from '../../services/Categories'
import Input from '../../components/Input'; 
import ButtonMaterial from '../../components/Button';
import { useIsSm } from '../../hooks/useIsSm';
import iconEN from '../../assets/images/en.png'
import iconES from '../../assets/images/es.png'
import iconFR from '../../assets/images/fr.png'
import iconPT from '../../assets/images/pt.png'
import * as S from './styles'
import { Icons, Table } from '../../pages/UserManagement/TableUsers/styles'
import { ICategory } from '../../services/Categories/types'
import { theme } from '../../styles/theme';
import { registerValidation } from './validations'

const TableCategory: React.FC<ITableUsers> = (props: ITableUsers) => {
  const { t } = useTranslation();
  const { listUsers, updateList } = props
  const [itemSelected, setItemSelected] = useState<ICategory>(emptyItem)
  const [openDisableCategoryModal, setOpenDisableCategoryModal] = useState(false)
  const [openEnableCategoryModal, setOpenEnableCategoryModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openEditCategoriesModal, setOpenEditCategoriesModal] = useState(false)
  const isSm = useIsSm()
  const [registration, setRegistration] = useState<Registration>(new Registration());
  const [registrationError, setRegistrationError] = useState<RegistrationError>(new RegistrationError());

  const handleDisableCategory = async () => {
    setLoading(true);
    try {
      if (itemSelected?.Id) {
        const response = await disableCategory(itemSelected.Id);
        if (response.Success) {
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Categoria desabilitada com sucesso!')
          });
          updateList();
          setOpenDisableCategoryModal(false)
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível desabilitar a categoria, tente novamente mais tarde.'),
            errorMessage: response.Message,
            errors: response.Errors
          });
        }
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível desabilitar a categoria, tente novamente mais tarde.')
      });
    }
    setLoading(false);
  };

  const handleEnableCategory = async () => {
    setLoading(true);
    try {
      if (itemSelected?.Id) {
        const response = await enableCategory(itemSelected.Id);
        if (response.Success) {
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Categoria habilitada com sucesso!')
          });
          updateList();
          setOpenDisableCategoryModal(false)
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível habilitar a categoria, tente novamente mais tarde.'),
            errorMessage: response.Message,
            errors: response.Errors
          });
        }
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível habilitar a categoria, tente novamente mais tarde.')
      });
    }
    setLoading(false);
  };

  const editCategory = async () => {
    setLoading(true)
    try {
      let countError = 0;

      const validation = await registerValidation(registration); 

      if (typeof validation !== 'boolean') { 
        countError++;
      } 
      if (countError > 0) {
        setRegistrationError(validation as RegistrationError);
        setLoading(false);
        return;
      }

      const bodyData = {
        ValuePtBr: registration.ValuePtBr,
        ValueEn: registration.ValueEn,
        ValueEs: registration.ValueEs,
        ValueFr: registration.ValueFr
      };
      const response = await editCategories(bodyData, itemSelected.Id);
      if (response.Success) {
        updateList();
        setOpenEditCategoriesModal(false)
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Categoria editada com sucesso!')
        });
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao editar a categoria, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        }); 
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao editar a categoria, tente novamente.')
      });
    }
    setLoading(false)
  };

  const renderTable = () => {
    return (
      <Table style={{ marginTop: '8px' }}>
        <table>
          <tr className="header">
            <td style={{ paddingLeft: 16, width: isSm ? '20px' : 'auto' }}>{t('ID')}</td> 
            <td>{t('Nome')}</td>
            <td>{t('Ações')}</td>
          </tr>
          {listUsers.map((item) => (
            <tr key={item?.Id}> 
              <td style={{ paddingLeft: 16 }}>{item?.Id}</td>
              <td> 
                <S.ContainerLanguage>
                  <S.ItemLanguage>
                    <img src={iconPT} className="flag" alt="" />
                    <h5>{item.ValuePtBr}</h5>
                    {isSm && <ArrowDropDown className="icon" />}
                  </S.ItemLanguage>
                  {isSm ?
                    <div className='boxMoreLanguages'>
                      <S.ItemLanguage>
                        <img src={iconEN} className="flag" alt="" />
                        <h5>{item.ValueEn}</h5>
                      </S.ItemLanguage> 

                      <S.ItemLanguage>
                        <img src={iconES} className="flag" alt="" />
                        <h5>{item.ValueEs}</h5>
                      </S.ItemLanguage>

                      <S.ItemLanguage>
                        <img src={iconFR} className="flag" alt="" />
                        <h5>{item.ValueFr}</h5>
                      </S.ItemLanguage>
                    </div>
                    :
                    <>
                      <S.ItemLanguage>
                        <img src={iconEN} className="flag" alt="" />
                        <h5>{item.ValueEn}</h5>
                      </S.ItemLanguage>

                      <S.ItemLanguage>
                        <img src={iconES} className="flag" alt="" />
                        <h5>{item.ValueEs}</h5>
                      </S.ItemLanguage>

                      <S.ItemLanguage>
                        <img src={iconFR} className="flag" alt="" />
                        <h5>{item.ValueFr}</h5>
                      </S.ItemLanguage>
                    </>
                  }
                </S.ContainerLanguage>
              </td>
              <td style={{ width: '20px' }}>
                <Icons>
                  <button onClick={() => {
                    setItemSelected(item)
                    setRegistration({
                      ValuePtBr: item.ValuePtBr,
                      ValueEn: item.ValueEn, 
                      ValueEs: item.ValueEs,
                      ValueFr: item.ValueFr
                    })
                    setOpenEditCategoriesModal(true)
                  }}>
                    <Tooltip title={t('Editar')}>
                      <Edit style={{ marginRight: '16px', color: theme.colors.friday }} className="i" />
                    </Tooltip>
                  </button>

                  <button onClick={() => { 
                    setItemSelected(item)
                    if (item.Enabled) {
                      setOpenDisableCategoryModal(true)
                    } else {
                      setOpenEnableCategoryModal(true)
                    }
                  }}>
                    <Tooltip title={item.Enabled ? t('Habilitado. Clique para desabilitar') : t('Desabilitado. Clique para habilitar')}>
                      {item.Enabled ? <Visibility className="i" style={{ color: theme.colors.friday }} /> : <VisibilityOff className="i" style={{ color: theme.colors.friday }} />}
                    </Tooltip>
                  </button>
                </Icons>
              </td>
            </tr>
          ))}
        </table>

        <ModalCustom
          open={openEnableCategoryModal}
          onClose={() => setOpenEnableCategoryModal(false)}
          onClick={() => { }}
          loading={loading}
          title={t('Habilitar Categoria')}
          confirmationButtonText={t('CONFIRMAR')}
          textDeclineButton={t('CANCELAR')} 
          buttonsEnabled={false}
          shortModal
          childrenContent={
            <S.ModalEnableCategory>
              <p>{t('Você deseja habilitar a categoria')} <b>{itemSelected.ValuePtBr}</b>?</p>

              <div className='boxButtonModal'>
                <ButtonMaterial
                  style={{ borderColor: theme.colors.secundary }}
                  variant="outlined"
                  label={t('CANCELAR')}
                  onClick={() => setOpenEnableCategoryModal(false)}
                />

                <ButtonMaterial
                  style={{ backgroundColor: theme.colorsBase.green2, color: theme.colorsBase.white, marginLeft: '16px' }}
                  variant="contained"
                  label={t('HABILITAR')}
                  onClick={() => handleEnableCategory()}
                />
              </div>
            </S.ModalEnableCategory>
          }
        />

        <ModalCustom
          open={openDisableCategoryModal}
          onClose={() => setOpenDisableCategoryModal(false)}
          onClick={() => {
            handleDisableCategory()
          }}
          loading={loading}
          title={t('Desabilitar Categoria')} 
          confirmationButtonText={t('CONFIRMAR')}
          textDeclineButton={t('CANCELAR')}
          buttonsEnabled={false}
          shortModal
          childrenContent={
            <S.ModalEnableCategory>
              <p>{t('Você deseja desabilitar a categoria')} <b>{itemSelected.ValuePtBr}</b>?</p>

              <div className='boxButtonModal'>
                <ButtonMaterial
                  style={{ borderColor: theme.colors.secundary }}
                  variant="outlined"
                  label={t('CANCELAR')}
                  onClick={() => setOpenEnableCategoryModal(false)}
                /> 

                <ButtonMaterial
                  style={{ backgroundColor: theme.colors.graphicRed, color: theme.colorsBase.white, marginLeft: '16px' }}
                  variant="contained"
                  label={t('DESABILITAR')}
                  onClick={() => handleDisableCategory()}
                />
              </div>
            </S.ModalEnableCategory>
          }
        />

        <ModalCustom
          open={openEditCategoriesModal}
          onClose={() => setOpenEditCategoriesModal(false)}
          onClick={editCategory}
          childrenContent={
            <S.ContainerEditCategories>
              <Input
                label={t('Nome da categoria (Português)')}
                type="name"
                value={registration.ValuePtBr}
                onChange={(e) => setRegistration({ ...registration, ValuePtBr: e.target.value })}
                size="small"
                fullWidth
                error={registrationError.ValuePtBr.length > 0}
                helperText={registrationError.ValuePtBr}
                onClick={() => setRegistrationError({ ...registrationError, ValuePtBr: '' })}
                variant="filled"
                style={{ marginBottom: '8px' }}
              />

              <Input
                label={t('Nome da categoria (Inglês)')}
                type="name"
                value={registration.ValueEn}
                onChange={(e) => setRegistration({ ...registration, ValueEn: e.target.value })}
                size="small"
                fullWidth
                error={registrationError.ValueEn.length > 0}
                helperText={registrationError.ValueEn}
                onClick={() => setRegistrationError({ ...registrationError, ValueEn: '' })}
                variant="filled"
                style={{ margin: '8px 0px' }}
              />

              <Input
                label={t('Nome da categoria (Espanhol)')} 
                type="name"
                value={registration.ValueEs}
                onChange={(e) => setRegistration({ ...registration, ValueEs: e.target.value })}
                size="small"
                fullWidth
                error={registrationError.ValueEs.length > 0}
                helperText={registrationError.ValueEs}
                onClick={() => setRegistrationError({ ...registrationError, ValueEs: '' })}
                variant="filled"
                style={{ margin: '8px 0px' }}
              />

              <Input
                label={t('Nome da categoria (Françês)')}
                type="name"
                value={registration.ValueFr}
                onChange={(e) => setRegistration({ ...registration, ValueFr: e.target.value })}
                size="small"
                fullWidth
                error={registrationError.ValueFr.length > 0}
                helperText={registrationError.ValueFr}
                onClick={() => setRegistrationError({ ...registrationError, ValueFr: '' })}
                variant="filled"
                style={{ marginTop: '8px' }}
              />
            </S.ContainerEditCategories>
          }
          loading={loading}
          title={t('Editar Categoria')}
          confirmationButtonText={t('SALVAR')}
          textDeclineButton={t('CANCELAR')}
        />
      </Table>
    )
  }

  return renderTable()
}

export default TableCategory
