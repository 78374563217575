import styled from 'styled-components'
import { responsiveFont } from '../../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const ContainerTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;

  .containerButton {
    display: flex;
    align-self: flex-end;
    margin-top: -45px;
    margin-right: 16px;
  }
  
  @media (max-width: 680px) {
    .containerButton {
      width: 100%;
      margin: 0;
      padding: 12px 40px 0px 40px;
    }
  }
`

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`

export const InputTerms = styled.textarea`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  height: 300px;
  border: none;
  padding: 8px;
  resize: none;
  z-index: 3;
  background: transparent;

  text-align: left;
  font-size: ${responsiveFont(12)};
  color: ${(props) => props.theme.colorsBase.black3};
  font-weight: 400;
  font-family: 'Barlow', Regular;

  &::-webkit-scrollbar {
    width: 8px;
    background: ${(props) => props.theme.colorsBase.white};
    }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colorsBase.gray3};
    border-radius: 8px;
  }
  ::-webkit-scrollbar:horizontal { 
    height: 4px;
  }
`

export const BoxTerms = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  width: 100%;
  position: relative;
  border: solid 1px ${(props) => props.theme.colorsBase.gray3};
  border-radius: 8px;
  padding: 12px 8px 12px 12px;


  .flag {
    width: 24px;
    object-fit: cover;
  }
  
  .iError {
    width: 12px;
    object-fit: cover;
    position: absolute;
    top: 0px;
    right: 4px;
    color: ${(props) => props.theme.colors.graphicRed};
  }

  .boxTitle {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .boxText {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 8px;
  }

  .title {
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.fourth};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  .version {
    margin-top: 4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.friday};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .lineText {
    display: flex;
    border: solid 1px ${(props) => props.theme.colorsBase.gray3};
    position: absolute;
    bottom: 12px;
    left: 12px;
    width: 87%;
    height: 80%;
    border-radius: 8px;
  }

  @media (max-width: 680px) {
    border: none;

    .boxText {
      align-items: center;
      justify-content: center;
    }
  }
`

export const ContainerModalRegister = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;

  .boxListTerms {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    width: 100%;
  }
  
  .containerButtonsModal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 16px;
  }

  @media (max-width: 680px) {
    .containerButtonsModal {
      align-items: center;
      justify-content: center;
      margin-top: 8px;
    }
  }
`

export const ContainerButtonsTap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around; 
  width: 100%;

`

export const ButtonTap = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self:  center;

  border: solid 1px ${(props) => (props.active ? props.theme.colors.seventh : props.theme.colorsBase.gray3)}; 
  border-radius: 4px;
  padding: 8px;
  position: relative;

  .flag {
    width: 24px;
    object-fit: cover;
  }

  .iErrorB {
    width: 12px;
    object-fit: cover;
    position: absolute;
    top: -12px;
    right: -6px;
    color: ${(props) => props.theme.colors.graphicRed};
  }
`

export const BoxText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;

  @media (max-width: 680px) {
    margin-top: 8px;
    margin-bottom: 24px;
  }

    .text {
      text-align: center;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    .red {
      text-align: center;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.graphicRed};
      font-weight: 700;
      font-family: 'Barlow', Regular;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    .green {
      text-align: center;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colorsBase.green2};
      font-weight: 700;
      font-family: 'Barlow', Regular;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }
  
`

export const ContainerViewTerms = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around; 
  width: 100%;

  .boxListTerms {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    width: 100%;
    padding: 32px 40px 24px 40px;
  }

  @media (max-width: 680px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; 
    border: solid 1px ${(props) => props.theme.colorsBase.gray3};
    border-radius: 8px;
    padding: 8px;
    margin-top: 16px;
    margin-bottom: 12px;
    width: 95%;

    .boxListTerms {
      padding: 0;
    }
  }
`