/* eslint-disable @typescript-eslint/restrict-plus-operands */
declare global {
  interface Window {
    ENV: Record<string, string>
  }
}

class RuntimeEnvironment {
  public readonly REACT_APP_SERVER_API: string
  public readonly REACT_APP_ENVIRONMENT: string

  constructor () {
    const runtimeEnv = window.ENV ?? {}

    this.REACT_APP_SERVER_API = runtimeEnv.REACT_APP_SERVER_API ?? process.env.REACT_APP_SERVER_API ?? ''


    this.REACT_APP_ENVIRONMENT = runtimeEnv.REACT_APP_ENVIRONMENT ??
      process.env.REACT_APP_ENVIRONMENT
  }
}

export default new RuntimeEnvironment()
