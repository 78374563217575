import { Error } from '@mui/icons-material'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

interface IViewErrorProps {
  message?: string | null
  children?: ReactNode
}

const ViewError: React.FC<IViewErrorProps> = (props: IViewErrorProps) => {
  const { message, children } = props
  const { t } = useTranslation();
  return (
    <S.Container>
      <Error />
      <S.Message>
        {message ?? children ?? t('Não foi possível carregar os dados, tente novamente mais tarde.')}
      </S.Message>
    </S.Container>
  )
}

export default ViewError
