import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'


export const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 48px 16px;
  svg {
    color: ${props => props.theme.colors.primary}
  }

  .image {
    z-index: 2;
    width: 210px;
    object-fit: cover;

    @media (max-width: 680px) {
      width: 100px;
    }
  
    @media (min-width: 1600px) {
      width: 300px;
    }
  }

  .icon {
    position: absolute;
   padding-bottom: 78px;
    color: ${(props) => props.theme.colors.secundary};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 350px;

    @media (max-width: 680px) {
      width: 250px;
      height: 250px;
    }
  
    @media (min-width: 1600px) {
      width: 480px;
      height: 480px;
    }
  }
`

export const Message = styled.h1`
  line-height: 24px;
  letter-spacing: 0.18px;
  width: 100%;
  margin-top: 32px;
  text-align: center;
  font-size: ${responsiveFont(24)};
  color: ${(props) => props.theme.colors.secundary};
  font-weight: 500;
  font-family: 'Barlow', Medium;


  @media (max-width: 680px) {
    font-size: ${responsiveFont(14)};
  }

  @media (min-width: 1600px) {
    font-size: ${responsiveFont(32)};
  }
`
