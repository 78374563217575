import 'react-pro-sidebar/dist/css/styles.css'
import { useState } from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  SubMenu
} from 'react-pro-sidebar'
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/Auth'
import { useTranslation } from 'react-i18next';
import * as S from './styles'
import {
  AdminPanelSettings,
  Assignment,
  AssignmentInd,
  BorderColor,
  ChevronLeft,
  ChevronRight,
  Contacts,
  Dashboard,
  EmojiEvents,
  FilterAlt,
  Gavel,

  Home,
  Info,
  Logout,
  MedicalInformation,
  Person,
  Settings,

  Rule,
  Storefront,
  Handshake,
  Analytics,
  Star
} from '@mui/icons-material'
import { theme } from '../../styles/theme'
import Header from '../Header'
import HeaderMobile from '../HeaderMobile'
import { useIsSm } from '../../hooks/useIsSm';

const Sidebar = () => {
  const [menuCollapse, setMenuCollapse] = useState(true)
  const { signOut, role, menu, setMenu } = useAuth()
  const { t } = useTranslation();

  const menuIconClick = () => {
    setMenuCollapse(true)
    setMenu(false)
  }

  const menuClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    setMenu(false)
  }

  const isSm = useIsSm()

  return (
    <>
      {isSm ? (
        <HeaderMobile />
      ) : (
        <Header />
      )}

      {(isSm ? menu : true) && (
        <S.Container>
          <div id="header" >
            <ProSidebar collapsed={isSm ? false : menuCollapse}>
              {!isSm && (
                <SidebarHeader>
                  <button className="closemenu" onClick={() => menuClick()}>
                    {menuCollapse
                      ? <ChevronRight fill={theme.colorsBase.white} />
                      : <ChevronLeft fill={theme.colorsBase.white} />
                    }
                  </button>
                </SidebarHeader>
              )}
              <SidebarContent>
                <Menu iconShape="square">
                  <MenuItem active={true} icon={<Home fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                    <Link to="/home">{t('Home')}</Link>
                  </MenuItem>

                  {(!role?.includes('Curator') && !role?.includes("CurarManager")) && (
                    <MenuItem active={true} icon={<Dashboard fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                      <Link to="/dashboard">{t('Dashboard')}</Link>
                    </MenuItem>
                  )}

                  {(role?.includes('Curator') || role?.includes("CurarManager")) && (
                    <SubMenu icon={<Dashboard fill={theme.colorsBase.white} />} title={t('Dashboard') ?? ''} placeholder="...">
                      <MenuItem active={true} icon={<Analytics fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                        <Link to="/dashboard/inspection">{t('Curadoria')}</Link>
                      </MenuItem>

                      <MenuItem active={true} icon={<Analytics fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                        <Link to="/dashboard">{t('Meus Desafios Criados')}</Link>
                      </MenuItem>
                    </SubMenu>
                  )}

                  {role?.includes("CurarManager") && (
                    <MenuItem active={true} icon={<AdminPanelSettings fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                      <Link to="/administracao">{t('Funções Administrativas')}</Link>
                    </MenuItem>
                  )}

                  {(role?.includes('Curator') || role?.includes("CurarManager")) && (
                    <MenuItem active={true} icon={<BorderColor fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                      <Link to="/inspection">{t('Moderação')}</Link>
                    </MenuItem>
                  )}

                  <MenuItem active={true} icon={<Person fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                    <Link to="/meu_perfil">{t('Meu Perfil')}</Link>
                  </MenuItem>

                  <MenuItem active={true} icon={<EmojiEvents fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                    <Link to="/minhas_conquistas">{t('Minhas Conquistas')}</Link>
                  </MenuItem>


                  {role?.includes('Admin') && (
                    <>
                      <MenuItem active={true} icon={<FilterAlt fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                        <Link to="/triage">{t('Triagens')}</Link>
                      </MenuItem>

                      <MenuItem active={true} icon={<Settings fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                        <Link to="/configuracao">{t('Configurações')}</Link>
                      </MenuItem>
                    </>
                  )}

                  <SubMenu icon={<Rule fill={theme.colorsBase.white} />} title={t('Desafios') ?? ''} placeholder="...">
                    <MenuItem active={true} icon={<AssignmentInd fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                      <Link to="/minhas_participacoes">{t('Minhas Participações')}</Link>
                    </MenuItem>

                    <MenuItem active={true} icon={<Assignment fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                      <Link to="/desafios">{t('Meus Desafios')}</Link>
                    </MenuItem>
                  </SubMenu>

                  {(role?.includes('SpecialProgramManager') || role?.includes("CurarManager")) && (
                    <MenuItem active={true} icon={<Star fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                      <Link to="/gerenciamento_de_programas_especiais">{t('Gerenciamento de Programas Especiais')}</Link>
                    </MenuItem>
                  )}

                  <MenuItem active={true} icon={<Star fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                    <Link to="/programas_especiais">{t('Programas Especiais')}</Link>
                  </MenuItem>


                  <MenuItem active={true} icon={<Contacts fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                    <Link to="/contatos">{t('Meus Contatos')}</Link>
                  </MenuItem>

                  {/* <MenuItem active={true} icon={<Dashboard fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                    <Link to="/desafios">{t('Dashboard')}</Link>
                  </MenuItem> */}

                  <MenuItem active={true} icon={<Storefront fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                    <Link to="/canal_empresarial">{t('Canal Empresarial')}</Link>
                  </MenuItem>

                  <MenuItem active={true} icon={<Handshake fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                    <Link to="/canal_dos_patrocinadores">{t('Patrocinador')}</Link>
                  </MenuItem>

                  <MenuItem active={true} icon={<MedicalInformation fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                    <Link to="/curadores">{t('Nossos Curadores')}</Link>
                  </MenuItem>

                  <MenuItem active={true} icon={<Info fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                    <Link to="/sobre">{t('Sobre')}</Link>
                  </MenuItem>

                  <MenuItem active={true} icon={<Gavel fill={theme.colorsBase.white} />} onClick={menuIconClick}>
                    <Link to="/termos_e_politica/termos">{t('Termos de Uso')}</Link>
                  </MenuItem>

                </Menu>
              </SidebarContent>
              <SidebarFooter>
                <Menu className="menuLeft" iconShape="square">
                  <MenuItem onClick={signOut} icon={<Logout fill={theme.colorsBase.white} />}>
                    {t('Sair')}
                  </MenuItem>
                </Menu>
              </SidebarFooter>
            </ProSidebar>
          </div>
        </S.Container>
      )}
    </>
  )
}

export default Sidebar
