import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import ToastNotification from '../../../components/ToastNotification';
import ViewError from '../../../components/ViewError';
import { useIsSm } from '../../../hooks/useIsSm';
import { Pagination } from '@mui/material';
import SearchBar from '../../../components/SearchBar';
import { ISpecialProgram } from './types';
import { Pages } from '../../../pages/ViewChallenge/styles';
import { useNavigate } from 'react-router-dom';
import { getSpecialProgram, getSpecialProgramDetails } from '../../../services/SpecialProgram';

import { getLocalStorage } from '../../../utils/StorageLocal';
import { IUser } from '../../../contexts/Auth/types';
import { USER_GET } from '../../../config/constants';
import ChallengeItemDesktop from '../../../components/ChallengeItemDesktop';
import ButtonMaterial from '../../../components/Button';
import { theme } from '../../../styles/theme';
import Loading from '../../../components/Loading';

interface IProps {
  update: boolean;
  tab: number;
}

const ScreenParticipant: React.FC<IProps> = ({ update, tab }) => {
  const { t } = useTranslation();
  const isSm = useIsSm();
  const [value, setValue] = useState('');
  const [specialProgramList, setSpecialProgramList] = useState<ISpecialProgram[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [larguraDaTela, setLarguraDaTela] = useState(window.innerWidth);
  const navigate = useNavigate();
  const [tabNumber, setTabNumber] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTabNumber(tab)
    retrieveSpecialProgram(1, true, tab);
  }, [update, tab, isSm]);

  const onEnterSearch = (event: { key: string }) => {
    if (event.key === 'Enter') {
      retrieveSpecialProgram(1, true, tabNumber);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setLarguraDaTela(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = async () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (scrollY + windowHeight >= documentHeight - 100) {
        if ((currentPage <= (totalPages || 0)) && isSm) {
          await retrieveSpecialProgram(currentPage + 1, false, tabNumber);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, totalPages, isSm]);

  const numberElements = () => {
    if (larguraDaTela > 800 && larguraDaTela < 1600) {
      return 3;
    } else if (larguraDaTela > 1600 && larguraDaTela < 2000) {
      return 4;
    } else if (larguraDaTela > 2000) {
      return 5;
    }
    return 3;
  };

  const retrieveSpecialProgram = async (page: number = 1, clear: boolean = false, tabGet: number) => {
    setLoading(true)
    try {
      if (clear) {
        setSpecialProgramList([]);
      }
      const storagedUser = getLocalStorage<IUser>(USER_GET);

      const bodyData = {
        PageNumber: page,
        PageSize: isSm ? 64 : numberElements(),
        Name: value,
        SpecialProgramEstado: String(tabGet),
        GetChallenges: true,
        ParticipantId: storagedUser?.id
      };

      const response = await getSpecialProgram(bodyData);

      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }

        let tempResponseData: ISpecialProgram[] = [];

        const promises = response.Data?.map(async (item) => {
          const dataTemp = await handleDetails(item?.Id ?? '');

          return {
            ...item,
            PercentageOfCorrectAswers: dataTemp?.PercentageOfCorrectAswers,
            ResumeSpecialProgramStatus: dataTemp?.ResumeSpecialProgramStatus,
          };
        }) || [];

        tempResponseData = await Promise.all(promises);


        if (clear) {
          setSpecialProgramList(tempResponseData);
        } else if (tempResponseData.length > 1) {
          const novaArray = specialProgramList.concat(tempResponseData);
          setSpecialProgramList(novaArray);
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os programas, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors,
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os programas, tente novamente.'),
      });
    }
    setLoading(false)
  };

  const renderViewError = (type: number) => ({
    0: t('Ainda não há programas disponíveis'),
    1: t('Ainda não há programas iniciados'),
    2: t('Ainda não há programas concluídos'),
  }[type]);

  const handleItemClick = async (id: string) => {
    try {
      const programDetails = specialProgramList.find(item => item.Id === id);

      navigate(`/programa_especial/${id}`, { state: { programDetails } });
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os detalhes do programa, tente novamente.'),
      });
    }
  };

  const handleDetails = async (id: string) => {
    try {
      const response = await getSpecialProgramDetails(id);
      if (response?.Success) {
        return response?.Data
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os detalhes do programa, tente novamente.'),
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os detalhes do programa, tente novamente.'),
      });
    }
  };

  const renderSituation = (item: ISpecialProgram) => {
    if (item?.ResumeSpecialProgramStatus === 2) {
      return 3;
    } else if (item?.ResumeSpecialProgramStatus === 3) {
      return 4;
    } else {
      return 4;
    }
  };

  const renderCard = (item: ISpecialProgram) => {
    if (tabNumber === 0) {
      return <ChallengeItemDesktop
        key={item.Id}
        item={{
          CoverImageUrl: item.Image ?? '',
          Language: String(item.Language),
          Title: item.Title,
          Id: item.Id,
          EstimatedTime: item.DurationTime
        }}
        onClick={() => handleItemClick(item?.Id)}
        labelButton='VER PROGRAMA ESPECIAL'
        typeChallenge={12}
        allowEstimatedTime
        buttonCustom={
          <ButtonMaterial
            style={{ backgroundColor: theme.colors.seventh, width: '100%' }}
            variant="contained"
            label={t('ver programa especial')}
          />
        }
      />;
    } else if (tabNumber === 1) {
      return <ChallengeItemDesktop
        key={item.Id}
        item={{
          CoverImageUrl: item.Image ?? '',
          Language: String(item.Language),
          Title: item.Title,
          Id: item.Id,
          EstimatedTime: item.DurationTime
        }}
        onClick={() => handleItemClick(item?.Id)}
        labelButton='CONTINUAR PROGRAMA ESPECIAL'
        typeChallenge={12}
        allowEstimatedTime
        buttonCustom={
          <ButtonMaterial
            style={{ backgroundColor: theme.colorsBase.green2, width: '100%' }}
            variant="contained"
            label={t('Continuar programa especial')}
          />
        }
      />;
    } else if (tabNumber === 2) {
      return <ChallengeItemDesktop
        key={item.Id}
        item={{
          CoverImageUrl: item.Image ?? '',
          Language: String(item.Language),
          Title: item.Title,
          Id: item.Id,
          EstimatedTime: item.DurationTime,
          Status: renderSituation(item)
        }}
        onClick={() => handleItemClick(item?.Id)}
        labelButton='VER PROGRAMA ESPECIAL'
        typeChallenge={12}
        showSituation={true}
        allowEstimatedTime
        buttonCustom={
          <ButtonMaterial
            style={{ borderColor: theme.colors.seventh, color: theme.colors.seventh, width: '100%' }}
            variant="outlined"
            label={t('ver programa especial')}
          />
        }
      />;
    }
  };

  return (
    <S.Container style={{ marginTop: '8px' }}>
      <SearchBar
        value={value}
        setValue={(e) => setValue(e)}
        onEnterSearch={(e) => onEnterSearch(e)}
        placeholder={t('Busque o programa especial')}
        filterButton={false}
        style={{ padding: 0, width: isSm ? '100%' : '80%', marginRight: 'auto' }}
      />

      {loading ? (
        <Loading />
      ) : (
        <>
          {specialProgramList.length > 0 ? (
            <>
              <S.ContainerChallenge>
                {specialProgramList.map((item) => renderCard(item))}
              </S.ContainerChallenge>

              {!isSm && (
                <Pages>
                  <Pagination
                    showFirstButton
                    showLastButton
                    count={totalPages}
                    page={currentPage}
                    color="primary"
                    onChange={(e, p) => {
                      e.preventDefault();
                      e.stopPropagation();
                      retrieveSpecialProgram(p, true, tabNumber);
                    }}
                  />
                </Pages>
              )}
            </>
          ) : (
            <ViewError message={renderViewError(tabNumber)} />
          )}
        </>
      )}
    </S.Container>
  );
};

export default ScreenParticipant
