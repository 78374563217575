import Modal from '@mui/material/Modal'
import { IModalCancelCustomProps } from './types'
import * as S from './styles'
import { Close, Rule, Share } from '@mui/icons-material'
import ButtonMaterial from '../Button'
import { theme } from '../../styles/theme'

export default function ModalCustomBottom(props: Readonly<IModalCancelCustomProps>) {
  const { open, onClose, title, redButtonRight, childrenContent, description, showIcon = true, hideTitle, buttonShare = false, icon, buttonsEnabled = false, twoButton = true, onCloseBottom, closeBottom, loading = false, onClick, confirmationButtonText, textDeclineButton, iconButtom, buttonRight = true, styleButtons, buttonClose } = props

  const returnIcon = () => {
    return (
      <>
        {icon ? <>{icon}</> : buttonShare ? <Share className="icon" /> : <Rule className="icon" />}
      </>
    )
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <S.ContainerModal>
        <S.LineTop onClick={onClose} />

        {!hideTitle &&
          <S.Line>
            {showIcon && returnIcon()}
            <h5>{title}</h5>

            {buttonClose &&
              <button className='buttonClose' onClick={onClose}>
                <Close className="icon" />
              </button>}
          </S.Line>
        }

        {description && <h4>{description}</h4>}

        {childrenContent}

        {buttonsEnabled && (
          <div className='button' style={styleButtons}>
            {twoButton &&
              <>
                {(onCloseBottom && closeBottom) ?
                  <ButtonMaterial
                    style={{ borderColor: theme.colors.secundary }}
                    variant="outlined"
                    label={textDeclineButton}
                    onClick={() => onCloseBottom()}
                    disabled={loading}
                  />
                  :
                  <ButtonMaterial
                    style={{ borderColor: theme.colors.secundary }}
                    variant="outlined"
                    label={textDeclineButton}
                    onClick={() => onClose()}
                    disabled={loading}
                  />
                }
              </>
            }

            {buttonRight &&
              <ButtonMaterial
                style={{ backgroundColor: redButtonRight ? theme.colors.graphicRed : theme.colors.seventh, marginLeft: twoButton ? '24px' : '0px' }}
                variant="contained"
                label={confirmationButtonText}
                onClick={onClick}
                loading={loading}
                disabled={loading}
                icon={iconButtom}
              />
            }
          </div>
        )}
      </S.ContainerModal>
    </Modal>
  )
}
