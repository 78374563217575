import { useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { sendRequest } from "../../../services/RequestService";

import { IChalengeQuizQuery, IChalengeQuizQueryResponse } from "./types";

const defaultRoot = "challenge-definitions"

export const useInvalidateChalengeQuiz = () => {
  const queryClient = useQueryClient()

  return useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ["useChalengeQuiz"],
      exact: false
    });
  }, [queryClient])
}


export const useChalengeQuiz = (params: IChalengeQuizQuery) => useQuery({
  queryKey: ["useChalengeQuiz", params],
  queryFn: async () => {
    const url = `${defaultRoot}/${params.Id}/quizzes`

    return await sendRequest<IChalengeQuizQueryResponse[]>(url, 'GET')
  }
})
