import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const ContainerPointSelectionNumber = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0px 24px;
  
  @media (max-width: 680px) {
    width: 100%;
    margin: 0px;
    align-items: center;
  }

  .titlePoint {
    margin-bottom: 7px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colorsBase.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (max-width: 680px) {
      text-align: center;
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .containerPoint {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%; 
    height: 100%;

    .boxButtonLeft {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border-radius: 20px 0px 0px 20px;
      border: solid 1px ${(props) => props.theme.colors.secundary};
      background-color: ${(props) => props.theme.colors.seventh2};

      &:hover {
        opacity: 0.8;
      }
    }

    .boxButtonRight {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border-radius: 0px 20px 20px 0px;
      border: solid 1px ${(props) => props.theme.colors.secundary};
      background-color: ${(props) => props.theme.colors.seventh2};

      &:hover {
        opacity: 0.8;
      }
    }

   .textButton {
    text-align: center;
    font-size: ${responsiveFont(18)};
    color: ${(props) => props.theme.colors.secundary};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(20)};
    }
   }

   .inputPoint {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    width: 75px;
    padding: 0px 8px;
    border-top: solid 1px ${(props) => props.theme.colors.black5};
    border-bottom: solid 1px ${(props) => props.theme.colors.black5};
    border-left: none;
    border-right: none;

    text-align: left;
    font-size: ${responsiveFont(20)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(22)};
    }
  }
  } 
`
