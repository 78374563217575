import React, { useEffect, useState } from 'react';
import ToastNotification from '../../../components/ToastNotification';
import ViewLoading from '../../../components/ViewLoading';
import ViewError from '../../../components/ViewError';
import TableUsers from '../TableUsers';
import ModalCustom from '../../../components/ModalCustom';
import { getUsers } from '../../../services/Users';
import { IUsers } from '../TableUsers/types';
import { Select, MenuItem, Pagination } from '@mui/material'
import { useIsSm } from '../../../hooks/useIsSm';
import FormControl from '@mui/material/FormControl';
import * as S from './styles'
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import SearchBar from '../../../components/SearchBar';
import { Container, Pages } from '../../../pages/TermsOfUse/ScreenTermsOfUse/styles';
import { Inputs, Item } from '../../../pages/MyContacts/styles';


const Users: React.FC = () => {
  const { t } = useTranslation();
  const [usersList, setUsersList] = useState<IUsers[]>([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState<number | undefined>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [value, setValue] = useState('');
  const [openFilterUsersModal, setOpenFilterUsersModal] = useState(false)
  const [accessLevel, setAccessLevel] = useState('0');
  const isSm = useIsSm()

  useEffect(() => {
    retrieveUsers(1, true);
  }, []);

  const retrieveUsers = async (page: number = 1, clear: boolean = false) => {
    setLoading(true)
    try {
      if (clear) {
        setUsersList([]);
      }
      const bodyData = {
        PageNumber: page,
        PageSize: 8,
        TiposUsuarios: accessLevel !== '0' ? accessLevel : '',
        Nome: value
      };
      const response = await getUsers(bodyData);
      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }
        setUsersList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os usuários, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os usuários, tente novamente.')
      });
    }
    setLoading(false)
  };

  const onEnterSearch = (event: { key: string }) => {
    if (event.key === 'Enter') {
      retrieveUsers(1, true);
    }
  };

  useEffect(() => {
    if (value.length > 1) {
      retrieveUsers(1, true);
    }
  }, [value]);

  return (
    <Container>
      <S.ContainerTable>
        <div className='containerButton'>
          <SearchBar
            value={value}
            setValue={(e) => setValue(e)}
            onEnterSearch={onEnterSearch}
            setFilterModal={() => setOpenFilterUsersModal(true)}
            placeholder={t('Busque o usuário desejado')}
            filterButton
            style={{ padding: 0, width: isSm ? '100%' : '80%', marginRight: 'auto' }}
          />
        </div>

        {loading ? (
          <ViewLoading />
        ) : (
          <>
            {usersList.length > 0 ? (
              <>
                <TableUsers listUsers={usersList} updateList={() => retrieveUsers(1, true)} />
                <Pages>
                  <Pagination
                    showFirstButton
                    showLastButton
                    count={totalPages}
                    page={currentPage}
                    color="primary"
                    onChange={(e, p) => {
                      e.preventDefault()
                      e.stopPropagation()
                      retrieveUsers(p)
                    }}
                  />
                </Pages>
              </>
            ) : (
              <ViewError message={t('Nenhum usuário encontrado')} />
            )}
          </>
        )}
      </S.ContainerTable>

      <ModalCustom
        open={openFilterUsersModal}
        onClose={() => setOpenFilterUsersModal(false)}
        onClick={() => {
          retrieveUsers(1, true)
          setOpenFilterUsersModal(false)
        }}
        childrenContent={
          <Item>
            <Inputs>
              <div className="input">
                <FormControl sx={{ display: 'flex', m: 1, width: '100%' }}>
                  <InputLabel id="demo-simple-select-helper-label">{t('Função')}</InputLabel>
                  <Select
                    MenuProps={{
                      MenuListProps: {
                        sx: { maxHeight: '200px', maxWidth: '100%', overflowY: 'auto' }
                      }
                    }}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    variant="standard"
                    style={{ display: 'flex', width: '100%' }}
                    value={accessLevel}
                    onChange={(e) => {
                      setAccessLevel(String(e.target.value))
                    }}
                  >
                    <MenuItem value={'0'}>{t('Todos')}</MenuItem>
                    <MenuItem value={'DefaultUser'}>{t('Usuário Padrão')}</MenuItem>
                    <MenuItem value={'CurarManager'}>{t('Gerente Curar')}</MenuItem>
                    <MenuItem value={'Guardian'}>{t('Guardião')}</MenuItem>
                    <MenuItem value={'Curator'}>{t('Curador')}</MenuItem>
                    <MenuItem value={'CorporateManager'}>{t('Gerente corporativo')}</MenuItem>
                    <MenuItem value={'CorporateUser'}>{t('Usuário Corporativo')}</MenuItem>
                    <MenuItem value={'Sponsor'}>{t('Patrocinador')}</MenuItem>
                    <MenuItem value={'AccreditedManager'}>{t('Gerente Credenciado')}</MenuItem>
                    <MenuItem value={'AccreditedUser'}>{t('Usuário Credenciado')}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Inputs>
          </Item>
        }
        loading={loading}
        title={t('Filtros')}
        confirmationButtonText={t('Pesquisar')}
        textDeclineButton={t('CANCELAR')}
      />
    </Container >
  )
}

export default Users
