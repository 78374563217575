import { sendRequest } from '../RequestService';
import { IBodyGET, IExchangePointsForProducts, IProduct, IProductUser,  ISpecialAdminAwards, } from './types';

const defaultRoot = 'product-management';
const defaultRoot2 = 'products';
const defaultRoot3 = 'redemption-challenge-products';

export const getProducts = async (body: IBodyGET) => {
  let url = `${defaultRoot}`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Name=${body.Nome}`;
  if (body?.Id) url += `&Id=${body.Id}`;
  if (body?.Tipo) url += `&Type=${body.Tipo}`;
  if (body?.Active) url += `&Enabled=${body.Active}`;
  url += `&OrderByProperty=-Id`;
  return await sendRequest<IProduct[], IBodyGET>(url, 'GET')
}

export const enableProduct = async (id?: string) => {
  const url = `${defaultRoot}/${id}/enabled`
  return await sendRequest<boolean, string>(url, 'PATCH')
}

export const disableProduct = async (id?: string) => {
  const url = `${defaultRoot}/${id}/disabled`
  return await sendRequest<boolean, string>(url, 'PATCH')
}

export const postProduct = async (body: IProduct) => {
  const url = `${defaultRoot}`
  return await sendRequest<boolean, IProduct>(url, 'POST', body)
}

export const putProduct = async (body: IProduct, id?: string) => {
  const url = `${defaultRoot}/${id}`
  return await sendRequest<boolean, IProduct>(url, 'PUT', body)
}

export const getProductDetails = async (id?: string) => {
  const url = `${defaultRoot}/${id}/details`
  return await sendRequest<IProduct, string>(url, 'GET')
}


export const getProductsAvailable = async (body: IBodyGET) => {
  let url = `${defaultRoot2}/availables`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Name=${body.Nome}`;
  url += `&OrderByProperty=-Id`;
  return await sendRequest<IProductUser[], IBodyGET>(url, 'GET')
}

export const getProductsExchanged = async (body: IBodyGET) => {
  let url = `${defaultRoot2}/exchangeds`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Name=${body.Nome}`;
  url += `&OrderByProperty=-Id`;
  return await sendRequest<IProductUser[], IBodyGET>(url, 'GET')
}

export const postExchangePointsForProducts = async (body: IExchangePointsForProducts) => {
  const url = `${defaultRoot2}/exchange-for-points`
  return await sendRequest<boolean, IExchangePointsForProducts>(url, 'POST', body)
}

export const getAutoCompleteProducts = async (value: string) => {
  const url = `${defaultRoot2}/${value}/autocomplete`
  return await sendRequest<string[], string>(url, 'GET')
}

export const getAwardsWon = async (body: IBodyGET) => {
  let url = `${defaultRoot3}`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Name=${body.Nome}`;
  url += `&OrderByProperty=-Id`;
  url += `&Status=1`;
  return await sendRequest<IProductUser[], IBodyGET>(url, 'GET')
}

export const getPrizesFinalized = async (body: IBodyGET) => {
  let url = `${defaultRoot3}`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Name=${body.Nome}`;
  url += `&OrderByProperty=-Id`;
  url += `&Status=2`;
  return await sendRequest<IProductUser[], IBodyGET>(url, 'GET')
}

export const postRedeemPrize = async (body: number) => {
  const url = `${defaultRoot3}/redeem`
  return await sendRequest<boolean, number>(url, 'POST', body)
}

export const getSpecialAdminAwards = async (body: IBodyGET) => {
  let url = `${defaultRoot}/challenge-products`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  url += `&OrderByProperty=-Id`;
  return await sendRequest<ISpecialAdminAwards[], IBodyGET>(url, 'GET')
}