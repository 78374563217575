import { IGetPaginated } from "../types"

export interface ISendModeration {
  DesafioId: string
}

export type IGetChallengesModeration = IGetPaginated & {
  Id?: number;
  Status?: EStatusPost[]
};

export enum ETipoConteudo {
  VIDEO = 1,
  IMAGE = 2,
  TEXTFILE = 3,
  AUDIO = 4
}

export enum ENivelDificuldate {
  DIFICIL = 1,
  FACIL = 2, 
  MEDIO = 3
}

export enum EStatusPost {
  EmEdicao = 1,
  AnaliseResponsavel = 2,
  EmTriagem = 3,
  EmModeracao = 4,
  Aprovado = 5,
  Bloqueado = 6
}

export enum ETipoPergunta {
  ALTERNATIVAS = 1,
  VERDADEIROFALSO = 2
}

export type IChallengeContent = {
  ConteudoId: number,
  TipoConteudo: ETipoConteudo,
  Conteudo: string,
  Titulo: string,
}

export interface IChallengeContentWithQuestions extends IChallengeContent {
  Pergunta: {
    PerguntaId: number,
    TipoPergunta: ETipoPergunta,
    Pergunta: string,
    Audio: string | null,
    Resposta: {
      RespostaId: number,
      Resposta: string,
      RespostaCorreta: boolean
    }[]
  }[]
}

export interface IGetChallengesModerationResponse {
  DesafioId: number,
  NomeAutor: string,
  Titulo: string,
  TempoDuracao: number;
  Idioma: number,
  CategoriaId: number,
  NomeCategoria: string,
  NivelDificuldade: ENivelDificuldate,
  DataCadastro: string,
  Aprovado: boolean,
  Tags: string[],
  Fonte: string,
  ObservacaoFonte: string;
  ImgCapa: string,
  Conteudo: IChallengeContentWithQuestions
  Status: EStatusPost
}

export interface IGetChallengesTriageResponse extends IGetChallengesModerationResponse {
  AdicionarQuiz: boolean
}

export interface IPostAcceptTriageCommand {
  DesafioId: number,
}

export interface IPostCancelTriageCommand {
  DesafioId: number,
}

export interface IPostAcceptModerationCommand {
  DesafioId: number,
  AceiteDivulgacao: boolean
}

export enum EMotivoRecusa {
  AreaAtuacao = 1,
  ConflitoInteresse = 2,
  Ferias = 3,
  NaoInformar = 4,
  SemTempo = 5
}

export interface IPostRefuseModerationCommand {
  DesafioId: number,
  MotivoRecusa: EMotivoRecusa
}

export enum ELocalErroDesafio {
  CONTEUDO = 1,
  PERGUNTA = 2,
  RESPOSTA = 3
}

export interface IPostRejectModerationCommand {
  DesafioId: number,
  Observacao: string,
  AceiteDivulgacao: boolean,
  LocalErroDesafio: ELocalErroDesafio
}


export interface IPostBlockModerationCommand {
  DesafioId: number,
  MotivoBloqueio: string,
  AceiteDivulgacao: boolean,
}
