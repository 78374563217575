import Modal from '@mui/material/Modal';
import offImage from '../../assets/images/imgErro.png';
import { IModalSendCustomCover } from './types';
import * as S from './styles';
import { Close } from '@mui/icons-material';
import { useState } from 'react';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import SaveIcon from '@mui/icons-material/Save';

export default function ModalSendCustomCover({ open, onClose }: IModalSendCustomCover) {
  const [image, setImage] = useState<string>(offImage);
  const [fileInput, setFileInput] = useState<HTMLInputElement | null>(null);

  // Função para manipular a seleção do arquivo
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const newImageUrl = URL.createObjectURL(file);
      setImage(newImageUrl);
    }
  };

  const handleButtonClick = () => {
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleSave = () => {
    console.log('Imagem salva:', image);
    onClose(); 
  };

  return (
    <Modal open={open} onClose={onClose}>
      <>
        <S.ContainerModalQuiz>
          <header className='initialContainer'>
            <button className='buttonClose' onClick={onClose}>
              <Close className="icon" />
            </button>
          </header>
          <S.containerImage>
            <img src={image} alt='' />
          </S.containerImage>
          <S.containerButtons>
            <button onClick={handleButtonClick} className='NovaFoto'>
              <AddAPhotoIcon /> NOVA FOTO
            </button>
            <input
              ref={input => setFileInput(input)}
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <button onClick={handleSave} className='Salvar'>
              <SaveIcon /> SALVAR
            </button>
          </S.containerButtons>
        </S.ContainerModalQuiz>
      </>
    </Modal>
  );
}