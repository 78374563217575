import { useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { sendRequest } from "../../RequestService";

import { IChalengeActiveDetailsQuery, IChalengeActiveDetailsQueryResponse } from "./types";

const defaultRoot = "challenges-management"

export const useInvalidateChalengeActiveDetails = () => {
  const queryClient = useQueryClient()

  return useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ["useChalengeActiveDetails"],
      exact: false
    });
  }, [queryClient])
}

export const useChalengeActiveDetails = (params: IChalengeActiveDetailsQuery) => useQuery({
  queryKey: ["useChalengeActiveDetails", params],
  queryFn: async () => {
    const url = `${defaultRoot}/actives/${params.Id}/details`

    const response = await sendRequest<IChalengeActiveDetailsQueryResponse>(url, 'GET')

    if (!response.Success) throw new Error(response.Message)

    return response
  }
})
