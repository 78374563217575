import * as yup from 'yup';
import { onlyDigits } from '../../utils/Mask';

import { verifyClassEmpty } from '../../utils/ClassEmpty';
import { BirthDateValidation, genericValidation } from '../../utils/ValidationGenerics';

import { Registration, RegistrationError } from './types';

export const signInValidation = async (object: Registration): Promise<true | RegistrationError> => {
  const schemaRegister = yup.object().shape({
    dateOfBirth: yup.string().required()
  });

  const mountError = await genericValidation<Registration, RegistrationError>(schemaRegister, object, new RegistrationError());


  const dateValidation = BirthDateValidation(onlyDigits(object.dateOfBirth));
  if (object.dateOfBirth.length > 0) {
    if (!dateValidation.status) {
      mountError.dateOfBirth = dateValidation.msg;
    }
  }

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};
