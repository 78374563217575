import React, { useState } from 'react';

import { useIsSm } from '../../hooks/useIsSm';
import TermsOfUse from './view';

const TermsOfUseAdm: React.FC = () => {
  const [tab, setTab] = useState(0)
  const isSm = useIsSm()

  return (
    <TermsOfUse
      tab={tab}
      setNewTab={setTab}
      variant={isSm ? 'scrollable' : 'standard'}
    />
  );
}

export default TermsOfUseAdm;