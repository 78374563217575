import React from 'react';
import { useTranslation } from 'react-i18next';
import { Info, PhotoCamera } from '@mui/icons-material'
import * as S from './styles'
import { ICurators } from '../../pages/MyHealers/types';
import { validateIfNull } from '../../utils/UtilsGlobal';

interface IProps {
  item: ICurators
  onClick?: () => void
}

const CardCurators: React.FC<IProps> = ({ item, onClick, }) => {
  const { t } = useTranslation();

  return (
    <S.Container onClick={onClick}>
      {item.ProfilePhotoUrl ? (
        <S.Image src={item.ProfilePhotoUrl} />
      ) : (
        <div className='noImage'>
          <PhotoCamera className="icon" />
        </div>
      )}

      <div className='boxContent'>
        <h1 className='title'>{item.Name}</h1>
        <h1 className='subTitle'>{item.RegulatoryBoad}</h1>
        <p className='description'>{t('Sobre')}: {validateIfNull(item.AboutProfessional)}</p>
      </div>

      <Info className="i" />
    </S.Container>
  )
}

export default CardCurators;
