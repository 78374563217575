import React from 'react';
import { Pagination, Stack } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';

import { IBaseResponse } from '../../services/RequestService/types';

interface IProps {
  setPage: (page: number) => void
  query: UseQueryResult<IBaseResponse<unknown>, unknown>
}

const QueryPagination: React.FC<IProps> = ({ setPage, query }) => (
  <Stack mt="24px" pb="16px" alignItems='center'>
    <Pagination
      showFirstButton
      showLastButton
      count={query.data?.TotalPages}
      page={query.data?.PageInfo?.PageNumber}
      color="primary"
      onChange={(e, p) => {
        e.preventDefault()
        e.stopPropagation()
        setPage(p)
      }}
    />
  </Stack>
)

export default QueryPagination;
