import styled from 'styled-components'
import { responsiveFont } from '../../../utils/UtilsGlobal'


export const ContainerRegisterPage2 = styled.div<{ smallerSize?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 48px;

  .containerWarning {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 12px 10%;
    margin-top: 8px;
    background-color: ${(props) => props.theme.colorsBase.yellow2};

    .iconWarning {
      color: ${(props) => props.theme.colorsBase.yellow};
      font-size: 24px;
    }

    .containerWarningText {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 24px;
    }

    h5 {
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black4};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  }

  .containerTotal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .containerImageProductRegister {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .boxImageProductRegister {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px ${(props) => props.theme.colorsBase.gray3};
    border-radius: 8px;
    padding: 12px 8px;

      h5 {
        margin-bottom: 16px;
        text-align: center;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colorsBase.black5};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(16)};
        }
      }
  }
 
  .boxContentProductRegister {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
  }

  .boxTextInfoTopEdit {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .boxIconInfoEdit {
    color: ${(props) => props.theme.colors.friday};
    font-size: 24px;
  }

  h3 {
    margin-left: 8px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.friday};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) { 
      font-size: ${responsiveFont(16)};
    }
  }
 
  .boxButtonEditImageMobile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: flex-start;
  }

  @media (max-width: 680px) {
    margin-bottom: 16px;
 
    .containerImageProductRegister {
      width: 100%;
    }

    .containerTotal {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .boxImageProductRegister {
      width: 100%;
      flex-direction:  ${(props) => (props.smallerSize ? 'row-reverse' : 'column')}; 
      padding: ${(props) => (props.smallerSize ? '8px 12px' : '12px 8px')};
    }

    .boxContentProductRegister {
      width: 100%;
      margin-left: 0px;
      margin-top: 8px;
    }
  }
`

export const TextCountDetails = styled.div`
display: flex;
align-items: left;
justify-content: flex-start;
width: 100%;
margin-top: 2px;

h5 {
  display: flex;
  align-self: flex-start;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
  font-size: ${responsiveFont(10)};
  color: ${(props) => props.theme.colors.black2};
  font-weight: 400;
  font-family: 'Barlow', Medium;

  @media (min-width: 1600px) {
    font-size: ${responsiveFont(10)};
  }
}
`

export const BackgroundImage = styled.label<{ smallerSize?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;

  .noImage {
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.shadows.primary};

    .iconRegister {
      color: ${(props) => props.theme.colors.fourth};
      width: 24px;
      height: 24px;
      align-self: center;
    }

    .textRegisterImage {
      margin: 4px 24px 0px 24px;
      text-align: center;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.fourth};
      font-weight: 400;
      font-family: 'Barlow', Medium;
    
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  }

  @media (max-width: 680px) {
    width: ${(props) => (props.smallerSize ? '50%' : '70%')}; 
  }
`

export const Input = styled.input`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`

export const ButtonImageEdit = styled.label`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border: solid 1px ${(props) => props.theme.colors.secundary};
  height: 40px;
  border-radius: 4px;
  margin-top: 16px;
  position: relative;
  cursor: pointer;

  .textButtonEdit {
    position: absolute;
    bottom: 10px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.secundary};
    font-weight: 400;
    font-family: 'Barlow', Medium;
  
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
    margin-top: -4px; 
    width: 50%;
    align-self: flex-start;
  }
`