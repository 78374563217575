import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles'
import { CardGiftcard, KeyboardArrowRight, Shop, VolunteerActivism, WorkspacePremium } from '@mui/icons-material';
import { useIsSm } from '../../hooks/useIsSm';

interface IProps {
    type: number;
    onClick?: () => void;
}

const renderText = (typeText: number) => ({
    1: "Produtos",
    2: "Doe Pontos",
    3: "Meus Prêmios",
    4: "Meus Certificados"
}[typeText])

const renderDescription = (typeText: number) => ({
    1: "Troque seus pontos conquistados em desafios, por produtos! Veja os produtos disponíveis.",
    2: "Doe seus pontos conquistados em desafios! Escolha um contato e quantos pontos você quer doar.",
    3: "Veja os prêmios que você conquistou realizando desafios! Aqui você vê onde e como retirar prêmios.",
    4: "Veja os certificados que você conquistou ao completar programas especiais!"
}[typeText])

const renderIcon = (typeIcon: number) => ({
    1: <Shop className="i" />,
    2: <VolunteerActivism className="i" />,
    3: <CardGiftcard className="i" />,
    4: <WorkspacePremium className="i" />
}[typeIcon])

const CardLink: React.FC<IProps> = ({ type, onClick }) => {
    const { t } = useTranslation();
    const isSm = useIsSm()

    return (
        <S.BoxChallenge onClick={onClick}>
            {renderIcon(type)}
            <div className='containerText'>
                <h1 className='title'>{t(renderText(type) ?? "")}</h1>
                <h1 className='description'>{t(renderDescription(type) ?? "")}</h1>
            </div>

            {isSm && <KeyboardArrowRight className="iBack" />}
        </S.BoxChallenge>
    )
}

export default CardLink
