import React, { useEffect, useState, } from 'react';


import { useTranslation } from 'react-i18next';
import ToastNotification from '../../components/ToastNotification';
import ViewError from '../../components/ViewError';
import { MenuItem, Select, Tooltip } from '@mui/material'
import ModalCustom from '../../components/ModalCustom';
import { IChallenge } from '../../services/Challenges/types';
import { getChallenges } from '../../services/Challenges';
import FormControl from '@mui/material/FormControl';
import { theme } from '../../styles/theme'
import InputLabel from '@mui/material/InputLabel';
import { ICategories } from '../../components/TableCategory/types';
import { getCategories } from '../../services/Categories';
import Loading from '../../components/Loading';
import { ArrowBack, FilterList, Rule } from '@mui/icons-material';
import InputSearch from '../../components/InputSearch';
import { useNavigate } from 'react-router-dom';
import { useIsSm } from '../../hooks/useIsSm';
import ChallengeItemDesktop from '../../components/ChallengeItemDesktop';
import { LineSeparator } from '../Home/styles';
import SearchBar from '../../components/SearchBar';
import { Container, ContainerContent, ContainerTitle, BackButton, ContainerSearch, ButtonStyled, ContainerChallenge, Item, Inputs } from '../SpecialChallenges/styles';
import ButtonCreateMobileChallenge from '../../components/ButtonCreateMobileChallenge';
import ButtonCreateDesktopChallenge from '../../components/ButtonCreateDesktopChallenge';

const ChallengeAvailable: React.FC = () => {
  const { t } = useTranslation();
  const [challengesList, setChallengesList] = useState<IChallenge[]>([])
  const [loading, setLoading] = useState(false)
  let totalPages = 0;
  let currentPage = 0;
  const [category, setCategory] = useState('');
  const [categoriesList, setCategoriesList] = useState<ICategories[]>([])
  const [homeSearch, setHomeSearch] = useState('');
  const [homeFilter, setHomeFilter] = useState(false);
  const navigate = useNavigate()
  const isSm = useIsSm()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    retrieveCategories();
  }, []);

  useEffect(() => {
    retrieveChallenges(1, true, homeSearch);
  }, [homeSearch]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > (300 * currentPage)) {
        if (currentPage < (totalPages || 0)) {
          retrieveChallenges(currentPage + 1, false, homeSearch)
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Função de limpeza para remover o listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const retrieveCategories = async () => {
    setLoading(true)
    try {
      const bodyData = {
        PageNumber: 1,
        PageSize: 9999
      }
      const response = await getCategories(bodyData);
      if (response.Success) {
        setCategoriesList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar as categorias, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar as categorias, tente novamente.')
      });
    }
    setLoading(false)
  };

  const retrieveChallenges = async (page: number = 1, clear: boolean = false, search?: string) => {
    if (page === 1) {
      setLoading(true)
    }
    try {
      if (clear) {
        setChallengesList([]);
      }

      const bodyData = {
        PageNumber: 1,
        PageSize: 64 * page,
        Nome: search,
        CategoriaId: category,
        HasSpecialAward: 'false'
      };
      const response = await getChallenges(bodyData);
      if (response.Success) {
        currentPage = page;
        if (page === 1 && response.TotalPages) {
          totalPages = response.TotalPages;
        }

        setChallengesList(response.Data);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os desafios, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os desafios, tente novamente.')
      });
    }
    setLoading(false)
  };


  return (
    <Container>
      <ContainerContent>
        <ContainerTitle>
          {!isSm ?
            <div className='containerSubTitle'>
              <BackButton onClick={() => navigate(-1)}>
                <ArrowBack className="icon" />
                <h1>{t(`Voltar`)}</h1>
              </BackButton>

              <ButtonCreateDesktopChallenge />
            </div>
            :
            <ButtonCreateMobileChallenge />
          }

          <LineSeparator style={{ marginTop: !isSm ? '16px' : '0px', marginBottom: '0px' }}>
            <div className='boxSeparator'>
              {isSm && <BackButton onClick={() => navigate(-1)} style={{ marginLeft: '0px', paddingRight: '8px', backgroundColor: theme.colorsBase.white }}>
                <ArrowBack className="icon" />
              </BackButton>}

              <Rule className="iconSeparator" />
              <h5>{t('Desafios Disponíveis')}</h5>
              {!isSm && <h6>{t('Vai ficar de fora? Cada desafio te dá mais pontos. Bora acumular!')}</h6>}
            </div>
          </LineSeparator>
        </ContainerTitle>

        {!isSm ? (
          <ContainerSearch>
            <InputSearch
              type='search'
              placeholder={t('O que deseja procurar?') ?? "O que deseja procurar?"}
              value={homeSearch}
              onChange={(e) => setHomeSearch(e.target.value)}
              variant="outlined"
              style={{ width: '100%' }}
            />

            <Tooltip placement='right' title={t('Filtros')}>
              <ButtonStyled onClick={() => setHomeFilter(!homeFilter)}>
                <FilterList className='icon' />
              </ButtonStyled>
            </Tooltip>
          </ContainerSearch>
        ) : (
          <SearchBar
            value={homeSearch}
            setValue={(e) => setHomeSearch(e)}
            setFilterModal={() => setHomeFilter(!homeFilter)}
            placeholder={t('O que deseja procurar?')}
            filterButton
            style={{ padding: '0 16px', width: '100%', marginBottom: '24px', marginTop: '24px' }}
          />
        )}

        {loading ? (
          <Loading />
        ) : (
          <>
            {challengesList.length > 0 ? (
              <>
                <ContainerChallenge>
                  {challengesList.map((item) => (
                    <>
                      {!isSm ?
                        <ChallengeItemDesktop onClick={() => {
                          navigate(`/desafio/${item.Id}`)
                        }}
                          item={item}
                          typeChallenge={1}
                          playVideo={item?.ContentType === 1}
                          key={item.Id}
                        />
                        :
                        <ChallengeItemDesktop onClick={() => {
                          navigate(`/desafio/${item.Id}`)
                        }}
                          item={item}
                          typeChallenge={1}
                          playVideo={item?.ContentType === 1}
                          style={{ width: windowWidth < 400 ? 'none' : '90%', margin: '0' }}
                          key={item.Id}
                        />
                      }
                    </>
                  ))}
                </ContainerChallenge>

                {loading && <Loading />}
              </>
            ) : <ViewError message={t('Nenhum desafio encontrado!')} />}
          </>
        )}
      </ContainerContent>

      <ModalCustom
        open={homeFilter}
        onClose={() => setHomeFilter(false)}
        onClick={() => {
          retrieveChallenges(1, true)
          setHomeFilter(false)
        }}
        onCloseBottom={() => {
          setCategory('')
          retrieveChallenges(1, true)
          setHomeFilter(false)
        }}
        closeBottom={true}
        childrenContent={
          <Item>
            <Inputs>
              <div className="input">
                <FormControl sx={{ display: 'flex', m: 1, width: '100%' }}>
                  <InputLabel id="demo-simple-select-helper-label">{t('Categoria')}</InputLabel>
                  <Select
                    MenuProps={{
                      MenuListProps: {
                        sx: { maxHeight: '200px', maxWidth: '100%', overflowY: 'auto' }
                      }
                    }}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    variant="standard"
                    style={{ display: 'flex', width: '100%' }}
                    value={category}
                    onChange={(e) => {
                      setCategory(String(e.target.value))
                    }}
                  >
                    {categoriesList?.map((item) => (
                      <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Inputs>
          </Item>
        }
        loading={loading}
        title={t('Refine sua pesquisa')}
        confirmationButtonText={t('Pesquisar')}
        textDeclineButton={t('LIMPAR')}
      />
    </Container>
  )
}

export default ChallengeAvailable
