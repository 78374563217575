import React, { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';

import { IChalengeQuizQueryResponse } from '../../services/ChallengesDefinition/Quizzes/types';
import ModalCustom from '../ModalCustom';
// import QuizForm from '../forms/QuizForm';
// import ModalButton from '../ModalButton';
// import { useCreateQuizMutation } from '../../hooks/useCreateQuizMutation';
// import { EStatusPost, IGetChallengesTriageResponse } from '../../services/Moderation/types';

import { Content, Title } from './styles';

// const CREATE_QUIZ_IN_TRIAGE = false

interface IProps {
  data: IChalengeQuizQueryResponse[];
  open: boolean;
  onClose: () => void;
}

const QuizModal: React.FC<IProps> = ({ data, open, onClose }) => {
  const [index, setIndex] = useState(0)
  const pergunta = data.at(index)

  //const { mutateAsync } = useCreateQuizMutation()

  return (
    <ModalCustom
      open={open}
      onClick={() => { }}
      onClose={onClose}
      buttonsEnabled={false}
      title="Visualizar Quiz"
      childrenContent={(
        <Stack width="100%" spacing={2}>
          {data.length > 0 ? (
            <>
              <Box>
                <Title>Pergunta</Title>
                <Content>{pergunta?.Question}</Content>
              </Box>
              <Box>
                <Title>Resposta Correta</Title>
                <Content>{pergunta?.Alternatives.find((r) => r.RightAnswer)?.Answer ?? "N/A"}</Content>
              </Box>
              <Box>
                <Title>Outras Alternativas</Title>
                <Stack direction='row' spacing={2}>
                  {pergunta?.Alternatives.map(({ Id, Answer }) => (
                    <Content key={Id}>{Answer}</Content>
                  ))}
                </Stack>
              </Box>
              <Box>
                <Title>Audio da Pergunta</Title>
                {pergunta?.AudioUrl ? <audio controls src={pergunta?.AudioUrl} /> : <Content>N/A</Content>}
              </Box>

              <Stack justifyContent='space-between' direction='row'>
                <Button
                  variant="outlined"
                  disabled={index === 0}
                  onClick={() => setIndex(prev => prev - 1)}
                >
                  PERGUNTA ANTERIOR
                </Button>
                <Button
                  variant="outlined"
                  disabled={index >= data.length - 1}
                  onClick={() => setIndex(prev => prev + 1)}
                >
                  PRÓXIMA PERGUNTA
                </Button>
              </Stack>
            </>
          ) : (
            <Stack height="300px" justifyContent="center" alignItems="center">
              <Content>O desafio ainda não possui um quiz.</Content>
            </Stack>
          )}
        </Stack>
      )}
    />
  )
}

export default QuizModal;
