import React, { useEffect, useState } from 'react';

import { AdminPanelSettings, Apartment, Attribution, Block, Edit, Handshake, Info, MedicalInformation, Person } from '@mui/icons-material'
import { Tooltip, Select, MenuItem, FormControl, InputLabel, TextField, Checkbox } from '@mui/material'
import { emptyItem, IUsers, ITableUsers } from './types'
import { useTranslation } from 'react-i18next'
import ModalCustom from '../../../components/ModalCustom'
import ToastNotification from '../../../components/ToastNotification'
import { DataMaskFormtData } from '../../../utils/Mask'
import Input from '../../../components/Input';
import { deleteUsers, getAdvice, postProfessionalUserAdmin, getUserAdmin, putRoleAdmin } from '../../../services/Users'
import * as S from './styles'
import { ICategories } from '../../../components/TableCategory/types'
import { getCategories } from '../../../services/Categories';
import { useIsSm } from '../../../hooks/useIsSm';
import ModalCustomBottom from '../../../components/ModalCustomBottom';
import ButtonMaterial from '../../../components/Button';
import { theme } from '../../../styles/theme';
import SelectMultiple from '../../../components/SelectMultiple';
import { RegistrationProfessional, RegistrationProfessionalError } from '../../../pages/MyProfile/types';
import { IPais, IState } from '../../../services/Locality/types';
import { getStateAddressUser } from '../../../services/Locality';
import { registerProfessionalValidation } from '../../../pages/MyProfile/validations';

const TableSettings: React.FC<ITableUsers> = (props: ITableUsers) => {
  const { t } = useTranslation();
  const isSm = useIsSm()
  const { listUsers, updateList } = props
  const [itemSelected, setItemSelected] = useState<IUsers>(emptyItem)
  const [loading, setLoading] = useState(false)
  const [openEditSettingsModal, setOpenEditSettingsModal] = useState(false)
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)
  const [reasonForDeactivation, setReasonForDeactivation] = useState('')
  const [reasonForDeactivationError, setReasonForDeactivationError] = useState('')
  const [healthcareProfessional, setHealthcareProfessional] = useState(false)
  const [openOptionsModal, setOpenOptionsModal] = useState(false)

  const [registrationProfessional, setRegistrationProfessional] = useState<RegistrationProfessional>(new RegistrationProfessional());
  const [registrationProfessionalError, setRegistrationProfessionalError] = useState<RegistrationProfessionalError>(new RegistrationProfessionalError());
  const [stateList, setStateList] = useState<IState[]>([])
  const [adviceList, setAdviceList] = useState<IPais[]>([])
  const [categoriesList, setCategoriesList] = useState<ICategories[]>([])
  const [categoriesListSelected, setCategoriesListSelected] = useState<ICategories[]>([])
  const [languageListSelected, setLanguageListSelected] = useState<ICategories[]>([])
  const [categoriesError, setCategoriesError] = useState(false)
  const [languageError, setLanguageError] = useState(false)
  const [openConfirmChangesModal, setOpenConfirmChangesModal] = useState(false)
  const [completedProfileData, setcompletedProfileData] = useState(false)
  const [openAssignRoleModal, setOpenAssignRoleModal] = useState(false)
  const [role, setRole] = useState('')
  const [roleError, setRoleError] = useState('')
  const [openConfirmChangesRoleModal, setOpenConfirmChangesRoleModal] = useState(false)
  const [openConfirmDeactivateUserModal, setOpenConfirmDeactivateUserModal] = useState(false)

  const languageList = [
    {
      Name: 'Inglês',
      Id: '2'
    },
    {
      Name: 'Espanhol',
      Id: '3'
    },
    {
      Name: 'Francês',
      Id: '4'
    },
    {
      Name: 'Português',
      Id: '1'
    }
  ]


  useEffect(() => {
    retrieveAdvice();
    retrieveUF();
    retrieveCategories();
  }, []);

  const retrieveAdvice = async () => {
    setLoading(true)
    try {
      setAdviceList([]);
      const response = await getAdvice();
      if (response.Success) {
        setAdviceList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  const retrieveUF = async () => {
    setLoading(true)
    try {
      setStateList([]);
      const response = await getStateAddressUser();
      if (response.Success) {
        setStateList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  const retrieveCategories = async (e?: string[]) => {
    setLoading(true)
    try {
      setCategoriesList([]);
      const response = await getCategories();
      if (response.Success) {
        setCategoriesList(response.Data)

        if (e) {
          const tempCategories: ICategories[] = []

          e.map((item) => {
            const category = response.Data.filter((itemX) => itemX?.Id === item)[0];
            tempCategories.push(category)
          });

          setCategoriesListSelected(tempCategories)
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar as categorias, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar as categorias, tente novamente.')
      });
    }
    setLoading(false)
  };

  const handlePostProfessional = async () => {
    setLoading(true);
    try {
      let countError = 0;

      const validation = await registerProfessionalValidation(registrationProfessional);

      if (typeof validation !== 'boolean') {
        countError++;
      }

      if (countError > 0) {
        if (!categoriesListSelected[0]?.Name) {
          setCategoriesError(true)
        }

        if (!languageListSelected[0]?.Name) {
          setLanguageError(true)
        }

        setRegistrationProfessionalError(validation as RegistrationProfessionalError);
        setLoading(false);
        setOpenConfirmChangesModal(false)
        return;
      }

      if (!categoriesListSelected[0]?.Name) {
        if (!languageListSelected[0]?.Name) {
          setLanguageError(true)
        }

        setCategoriesError(true)
        setLoading(false);
        setOpenConfirmChangesModal(false)
        return;
      }

      if (!languageListSelected[0]?.Name) {
        setLanguageError(true)
        setLoading(false);
        setOpenConfirmChangesModal(false)
        return;
      }

      const singleText: string[] = []

      categoriesListSelected.map((item) => {
        singleText.push(item.Id)
      });

      const singleTextLanguage: string[] = []

      languageListSelected.map((item) => {
        singleTextLanguage.push(item.Id)
      });

      const bodyData = {
        AboutProfessional: registrationProfessional.SobreAutor,
        RegulatoryBoardId: registrationProfessional.ConselhoProfissional,
        RegulatoryBoard: adviceList.filter((itemX) => String(itemX.Id) === String(registrationProfessional.ConselhoProfissional))[0]?.Name ?? '',
        RegulatoryBoardNumber: registrationProfessional.NumeroConselho,
        RegulatoryBoardState: registrationProfessional.UfConselhoId,
        SiteUrl: registrationProfessional.LinkReferencia,
        FacebookUrl: registrationProfessional.LinkFacebook,
        XUrl: registrationProfessional.LinkTwitter,
        InstagramUrl: registrationProfessional.LinkInstagram,
        RQENumber: registrationProfessional.RQE,
        Categories: singleText,
        Languages: singleTextLanguage
      };

      const response = await postProfessionalUserAdmin(bodyData, itemSelected.Id);
      if (response.Success) {
        setOpenConfirmChangesModal(false)
        setOpenEditSettingsModal(false);
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Edição realizada com sucesso!')
        });
        setHealthcareProfessional(true)
        retrieveProfessionalProfile(itemSelected.Id);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro editar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro editar os dados, tente novamente.')
      });
    }
    setLoading(false);
  };

  const handleDeleteUser = async () => {
    setLoading(true);
    try {
      if (itemSelected?.Id) {
        if (reasonForDeactivation.length <= 0) {
          setReasonForDeactivationError('Error')
          setLoading(false);
          return;
        }

        const response = await deleteUsers(reasonForDeactivation, itemSelected.Id);
        if (response.Success) {
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Usuário desativado com sucesso!')
          });
          updateList();
          setOpenDeleteUserModal(false)
          setOpenConfirmDeactivateUserModal(false)
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível desativar o usuário, tente novamente.'),
            errorMessage: response.Message,
            errors: response.Errors
          });
        }
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível desativar o usuário, tente novamente.')
      });
    }
    setLoading(false);
  };

  const retrieveProfessionalProfile = async (id: string) => {
    setLoading(true)
    try {
      const response = await getUserAdmin(id);
      if (response.Success) {
        if (response.Data.RQENumber) {
          setcompletedProfileData(true)
          setHealthcareProfessional(true)
          retrieveCategories(response.Data.Categories)

          if (response.Data.Languages) {
            const tempCategories: ICategories[] = []

            response.Data.Languages.map((item) => {
              const category = languageList.filter((itemX) => itemX?.Id === item)[0];
              tempCategories.push(category)
            });

            setLanguageListSelected(tempCategories)
          }
          setRegistrationProfessional({
            SobreAutor: response.Data.AboutProfessional,
            ConselhoProfissional: response.Data.RegulatoryBoardId,
            UfConselhoId: response.Data.RegulatoryBoardState,
            LinkReferencia: response.Data.SiteUrl,
            LinkFacebook: response.Data.FacebookUrl,
            LinkTwitter: response.Data.XUrl,
            LinkInstagram: response.Data.InstagramUrl,
            NumeroConselho: response.Data.RegulatoryBoardNumber,
            RQE: response.Data.RQENumber ?? ""
          })
        }
      } else {
        setcompletedProfileData(false)
        setHealthcareProfessional(false)

        setRegistrationProfessional({
          SobreAutor: '',
          ConselhoProfissional: '',
          UfConselhoId: '',
          LinkReferencia: '',
          LinkFacebook: '',
          LinkTwitter: '',
          LinkInstagram: '',
          NumeroConselho: '',
          RQE: ''
        })
        setLanguageListSelected([])
        setCategoriesListSelected([])
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  const handleEditRole = async () => {
    setLoading(true);
    try {
      if (itemSelected?.Id) {
        if (role.length <= 0) {
          setRoleError('Error')
          setLoading(false);
          return;
        }

        const response = await putRoleAdmin(role, itemSelected.Id);
        if (response.Success) {
          setOpenConfirmChangesRoleModal(false)
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Função editada com sucesso!')
          });
          updateList();
          setOpenAssignRoleModal(false)
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível editar a função, tente novamente.'),
            errorMessage: response.Message,
            errors: response.Errors
          });
        }
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível editar a função, tente novamente.')
      });
    }
    setLoading(false);
  };

  const handleModalEditUser = async (id: string) => {
    await retrieveProfessionalProfile(id);
    setOpenEditSettingsModal(true)
    setOpenOptionsModal(false)
  };

  const handleModalEditRole = async (id: string, role?: string) => {
    await retrieveProfessionalProfile(id);
    setOpenAssignRoleModal(true)
    setOpenOptionsModal(false)
    setRole(role ?? itemSelected.Role)
  };

  const renderTextFunction = (type: string) => ({
    "DefaultUser": t("Usuário Padrão"),
    "CurarManager": t("Gerente Curar"),
    "Guardian": t("Guardião"),
    "Curator": t("Curador"),
    "CorporateManager": t("Gerente corporativo"),
    "CorporateUser": t("Usuário Corporativo"),
    "Sponsor": t("Patrocinador"),
    "AccreditedManager": t("Gerente Credenciado"),
    "AccreditedUser": t("Usuário Credenciado"),
    "SpecialProgramManager": t("Gerente de Programas Especiais"),
  }[type])

  const renderIconFunction = (type: string) => ({
    "DefaultUser": <Person className="iconBlue" />,
    "CurarManager": <AdminPanelSettings className="iconBlue" />,
    "Guardian": <Person className="iconBlue" />,
    "Curator": <MedicalInformation className="iconBlue" />,
    "CorporateManager": <Handshake className="iconBlue" />,
    "CorporateUser": <Handshake className="iconBlue" />,
    "Sponsor": <Apartment className="iconBlue" />,
    "AccreditedManager": <Handshake className="iconBlue" />,
    "AccreditedUser": <Handshake className="iconBlue" />,
    "SpecialProgramManager": <Handshake className="iconBlue" />,
  }[type])

  const returnDeactivateUser = () => {
    return <S.ContainerDeactivateUser>
      <div className='boxDeactivateUser'>
        <h5>{t('Você deseja desativar a conta do usuário')} <b>{itemSelected.Name}</b>? {t('Se sim, descreva o motivo da desativação de conta abaixo.')}</h5>
      </div>

      <TextField
        id="outlined-multiline-static"
        label={t('Motivo')}
        multiline
        rows={10}
        defaultValue="Default Value"
        variant="outlined"
        value={reasonForDeactivation}
        error={reasonForDeactivationError.length > 0}
        onClick={() => setReasonForDeactivationError('')}
        style={{ width: '100%' }}
        onChange={(e) => setReasonForDeactivation(e.target.value)}
      />
    </S.ContainerDeactivateUser>
  };

  const returnMarginBottomEditUser = () => {
    if (isSm) {
      if (healthcareProfessional) {
        return '0px'
      } else {
        return '120px'
      }
    } else return '0px'
  };


  const returnEditUser = () => {
    return <S.ContainerEditUser>
      <div className='boxLeftB' style={{ marginBottom: returnMarginBottomEditUser() }}>
        <div className='boxInfo'>
          <h1>{t('Nome')}</h1>
          <h2>{itemSelected.Name}</h2>
        </div>

        <div className='switchDiv'>
          <Checkbox
            checked={healthcareProfessional}
            onChange={(event) => setHealthcareProfessional(event.target.checked)}
            color='info'
          />
          <h5>{t('Profissional da Saúde')}</h5>
        </div>
      </div>

      {healthcareProfessional &&
        <div className='boxRightB'>
          {contentProfessionalInformation()}
        </div>
      }
    </S.ContainerEditUser>
  };

  const contentProfessionalInformation = () => {
    return (
      <>
        <S.HeaderInfo>
          <Person className="iconProfile" />
          <h3>{t('Dados Profissionais')}</h3>
        </S.HeaderInfo>

        <S.ContainerProfile >
          <div className='box'>
            <FormControl style={{ display: 'flex', width: '100%', marginTop: '16px' }}>
              <InputLabel id="demo-simple-select-helper-label">{t('Conselho')}</InputLabel>
              <Select
                MenuProps={{
                  MenuListProps: {
                    sx: { maxHeight: '180px', overflowY: 'auto' }
                  }
                }}
                label={t('Conselho')}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="standard"
                style={{ display: 'flex', width: '100%' }}
                value={registrationProfessional.ConselhoProfissional}
                error={registrationProfessionalError.ConselhoProfissional.length > 0}
                onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, ConselhoProfissional: '' })}
                onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, ConselhoProfissional: e.target.value })}
              >
                {adviceList.map((item) => (
                  <MenuItem key={item.Id} value={item.Id}>{t(item.Name)}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={{ display: 'flex', width: '100%', marginTop: '16px' }}>
              <InputLabel id="demo-simple-select-helper-label">{t('Estado do Conselho')}</InputLabel>
              <Select
                MenuProps={{
                  MenuListProps: {
                    sx: { maxHeight: '180px', overflowY: 'auto' }
                  }
                }}
                label={t('Estado do Conselho')}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="standard"
                style={{ display: 'flex', width: '100%' }}
                value={registrationProfessional.UfConselhoId}
                error={registrationProfessionalError.UfConselhoId.length > 0}
                onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, UfConselhoId: '' })}
                onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, UfConselhoId: e.target.value })}
              >
                {stateList.map((item) => (
                  <MenuItem key={item.Uf} value={item.Uf}>{t(item.Name)}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <SelectMultiple
              error={categoriesError}
              resetError={() => setCategoriesError(false)}
              listSelected={categoriesListSelected}
              list={categoriesList}
              label={t('Categorias que atende')}
              onClickAdd={(e) => {
                const indexCategorySelected = categoriesListSelected.find((state) => state.Id === e);
                if (!indexCategorySelected) {
                  const tempCategories = categoriesList.filter((itemX) => itemX?.Id === e)[0];
                  setCategoriesListSelected([...categoriesListSelected, tempCategories])
                }
              }}
              onClickRemove={(e) => {
                const tempCategories = categoriesListSelected.filter((itemX) => itemX.Id !== e);
                setCategoriesListSelected(tempCategories)
              }}
            />

            <SelectMultiple
              error={languageError}
              resetError={() => setLanguageError(false)}
              listSelected={languageListSelected}
              list={languageList}
              label={t('Idioma(s) que atende')}
              onClickAdd={(e) => {
                const indexCategorySelected = languageListSelected.find((state) => state.Id === e);
                if (!indexCategorySelected) {
                  const tempCategories = languageList.filter((itemX) => itemX?.Id === e)[0];
                  setLanguageListSelected([...languageListSelected, tempCategories])
                }
              }}
              onClickRemove={(e) => {
                const tempCategories = languageListSelected.filter((itemX) => itemX.Id !== e);
                setLanguageListSelected(tempCategories)
              }}
            />

            <Input
              label={t('N° Conselho Profissional')}
              type="number"
              value={registrationProfessional.NumeroConselho}
              onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, NumeroConselho: e.target.value })}
              size="small"
              fullWidth
              error={registrationProfessionalError.NumeroConselho.length > 0}
              helperText={registrationProfessionalError.NumeroConselho}
              onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, NumeroConselho: '' })}
              variant="filled"
              style={{ marginTop: '16px' }}
            />

            <Input
              label={t('N° R.Q.E')}
              type="number"
              value={registrationProfessional.RQE}
              onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, RQE: e.target.value })}
              size="small"
              fullWidth
              error={registrationProfessionalError.RQE.length > 0}
              helperText={registrationProfessionalError.RQE}
              onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, RQE: '' })}
              variant="filled"
              style={{ marginTop: '16px' }}
            />
          </div>

          <div className='box' style={{ marginLeft: isSm ? '0px' : '24px' }}>
            <Input
              label={t('Link do Site Pessoal')}
              type="name"
              value={registrationProfessional.LinkReferencia}
              onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, LinkReferencia: e.target.value })}
              size="small"
              fullWidth
              error={registrationProfessionalError.LinkReferencia.length > 0}
              helperText={registrationProfessionalError.LinkReferencia}
              onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, LinkReferencia: '' })}
              variant="filled"
              style={{ marginTop: '16px' }}
            />

            <Input
              label={t('Link do Facebook')}
              type="name"
              value={registrationProfessional.LinkFacebook}
              onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, LinkFacebook: e.target.value })}
              size="small"
              fullWidth
              error={registrationProfessionalError.LinkFacebook.length > 0}
              helperText={registrationProfessionalError.LinkFacebook}
              onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, LinkFacebook: '' })}
              variant="filled"
              style={{ marginTop: '16px' }}
            />

            <Input
              label={t('Link Instagram')}
              type="name"
              value={registrationProfessional.LinkInstagram}
              onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, LinkInstagram: e.target.value })}
              size="small"
              fullWidth
              error={registrationProfessionalError.LinkInstagram.length > 0}
              helperText={registrationProfessionalError.LinkInstagram}
              onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, LinkInstagram: '' })}
              variant="filled"
              style={{ marginTop: '16px' }}
            />

            <Input
              label={t('Link Twitter')}
              type="name"
              value={registrationProfessional.LinkTwitter}
              onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, LinkTwitter: e.target.value })}
              size="small"
              fullWidth
              error={registrationProfessionalError.LinkTwitter.length > 0}
              helperText={registrationProfessionalError.LinkTwitter}
              onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, LinkTwitter: '' })}
              variant="filled"
              style={{ marginTop: '16px' }}
            />

            <TextField
              id="outlined-multiline-static"
              label={t('Sobre o Autor')}
              multiline
              rows={2}
              defaultValue="Default Value"
              variant="outlined"
              value={registrationProfessional.SobreAutor}
              error={registrationProfessionalError.SobreAutor.length > 0}
              helperText={registrationProfessionalError.SobreAutor}
              onClick={() => setRegistrationProfessionalError({ ...registrationProfessionalError, SobreAutor: '' })}
              style={{ marginTop: '24px', width: '100%' }}
              onChange={(e) => setRegistrationProfessional({ ...registrationProfessional, SobreAutor: e.target.value?.length <= 400 ? e.target.value : registrationProfessional.SobreAutor })}
            />
            <S.TextCount>
              <h5>{registrationProfessional.SobreAutor.length}/400</h5>
            </S.TextCount>
          </div>
        </S.ContainerProfile>
      </>
    )
  };

  const returnAssignRole = () => {
    return <S.ContainerEditRole>
      <div className='boxInfo'>
        <h1>{t('Nome')}</h1>
        <h2>{itemSelected.Name}</h2>
      </div>

      <div className='boxLineSeparator' />

      <FormControl style={{ display: 'flex', width: '100%', marginTop: '16px' }}>
        <InputLabel id="demo-simple-select-helper-label">{t('Função')}</InputLabel>
        <Select
          MenuProps={{
            MenuListProps: {
              sx: { maxHeight: '180px', overflowY: 'auto' }
            }
          }}
          label={t('Função')}
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          variant="standard"
          style={{ display: 'flex', width: '100%' }}
          value={role}
          error={roleError.length > 0}
          onClick={() => setRoleError('')}
          onChange={(e) => {
            setRole(String(e.target.value))
          }}
        >
          <MenuItem value={'DefaultUser'}>{t('Usuário Padrão')}</MenuItem>
          <MenuItem value={'CurarManager'}>{t('Gerente Curar')}</MenuItem>
          <MenuItem value={'SpecialProgramManager'}>{t('Gerente de Programas Especiais')}</MenuItem>
          <MenuItem value={'Guardian'}>{t('Guardião')}</MenuItem>
          {completedProfileData && <MenuItem value={'Curator'}>{t('Curador')}</MenuItem>}
          <MenuItem value={'CorporateManager'}>{t('Gerente corporativo')}</MenuItem>
          <MenuItem value={'CorporateUser'}>{t('Usuário Corporativo')}</MenuItem>
          <MenuItem value={'Sponsor'}>{t('Patrocinador')}</MenuItem>
          <MenuItem value={'AccreditedManager'}>{t('Gerente Credenciado')}</MenuItem>
          <MenuItem value={'AccreditedUser'}>{t('Usuário Credenciado')}</MenuItem>
        </Select>
      </FormControl>

      {completedProfileData ?
        <div className='boxMessage'>
          <Info className="iconMessage" />
          <h5>{t('Ao atribuir a função Curador, as informações profissionais de “Áreas de atuação” e “Idiomas que atende”, estão diretamente relacionadas à Categoria e Idioma dos desafios que serão moderados por esse Usuário Curador. Como Administrador você pode alterar esses e outros dados profissionais através da Edição na tela de Gerenciamento de usuários, ou solicitar ao usuário que deseja ser curador para que complete seu cadastro na plataforma.')}</h5>
        </div>
        :
        <div className='boxMessage'>
          <Info className="iconMessage" />
          <h5>{t('Caso deseje atribuir a função de Curador, é preciso que a opção “Sou Profissional da Saúde” esteja marcada no cadastro desse usuário, e os Dados Profissionais devidamente preenchidos. Como Administrador você pode completar o cadastro desse usuário através da ação de Edição na tela de Gerenciamento de usuários, ou solicitar ao usuário que deseja ser curador para que complete seu cadastro na plataforma.')}</h5>
        </div>
      }
    </S.ContainerEditRole>
  };

  return (
    <S.Table>
      <table>
        <tr className="header">
          <td style={{ paddingLeft: 16 }}>{t('ID')}</td>
          <td>{t('Nome')}</td>
          {!isSm && <td>{t('E-mail')}</td>}
          {!isSm && <td>{t('Data de Cadastro')}</td>}
          <td>{t('Função')}</td>
          <td>{isSm ? t('Detalhes') : t('Ações')}</td>
        </tr>
        {listUsers.map((item) => (
          <tr key={item?.Id}>
            <td style={{ paddingLeft: 16 }}>{item?.Id}</td>
            <td>{item?.Name}</td>
            {!isSm && <td>{item?.Email}</td>}
            {!isSm && <td>{DataMaskFormtData(item?.CreatedAt)}</td>}
            {!isSm && <td>{renderTextFunction(item?.Role)}</td>}
            {isSm && <td>{renderIconFunction(item?.Role)}</td>}
            <td style={{ width: '20px' }}>
              {!isSm ? (
                <S.Icons>
                  <button onClick={() => {
                    setItemSelected(item)
                    handleModalEditRole(item.Id, item.Role)
                  }}>
                    <Tooltip title={t('Atribuir função')}>
                      <Attribution className="i" />
                    </Tooltip>
                  </button>

                  <button onClick={() => {
                    setItemSelected(item)
                    handleModalEditUser(item.Id)
                  }}>
                    <Tooltip title={t('Editar')}>
                      <Edit style={{ marginLeft: '24px' }} className="i" />
                    </Tooltip>
                  </button>

                  <button onClick={() => {
                    if (item.Enabled) {
                      setItemSelected(item)
                      setOpenOptionsModal(false)
                      setOpenDeleteUserModal(true)
                    }
                  }
                  }>
                    <Tooltip title={item.Enabled ? t('Bloquear') : t('Bloqueado')}>
                      <Block style={{ marginLeft: '24px', color: item.Enabled ? theme.colors.friday : theme.colors.graphicRed }} className="i" />
                    </Tooltip>
                  </button>
                </S.Icons>
              ) : (
                <S.Icons>
                  <button onClick={() => {
                    setItemSelected(item)
                    setOpenOptionsModal(true)
                  }}>
                    <Tooltip title={t('Detalhes')}>
                      <Info className="i" />
                    </Tooltip>
                  </button>
                </S.Icons>
              )}
            </td>
          </tr>
        ))}
      </table>

      <ModalCustom
        open={openConfirmDeactivateUserModal}
        onClose={() => setOpenConfirmDeactivateUserModal(false)}
        onClick={handleDeleteUser}
        loading={loading}
        title={t('Confirmar ação')}
        description={t('Você realmente deseja desativar o usuário?')}
        confirmationButtonText={t('CONFIRMAR')}
        textDeclineButton={t('CANCELAR')}
      />

      <ModalCustom
        open={openConfirmChangesRoleModal}
        onClose={() => setOpenConfirmChangesRoleModal(false)}
        onClick={handleEditRole}
        loading={loading}
        title={t('Salvar alteração')}
        description={t('Você realmente deseja salvar a alteração?')}
        confirmationButtonText={t('CONFIRMAR')}
        textDeclineButton={t('CANCELAR')}
      />

      <ModalCustom
        open={openConfirmChangesModal}
        onClose={() => setOpenConfirmChangesModal(false)}
        onClick={handlePostProfessional}
        loading={loading}
        title={t('Salvar alterações')}
        description={t('Você realmente deseja editar as informações desse usuário?')}
        confirmationButtonText={t('CONFIRMAR')}
        textDeclineButton={t('CANCELAR')}
      />

      {isSm ?
        <ModalCustomBottom
          open={openAssignRoleModal}
          onClose={() => {
            setOpenAssignRoleModal(false);
          }}
          onClick={() => setOpenConfirmChangesRoleModal(true)}
          loading={loading}
          title={t('Atribuir Função')}
          confirmationButtonText={t('SALVAR')}
          textDeclineButton={t('CANCELAR')}
          showIcon={false}
          buttonClose
          buttonsEnabled
          styleButtons={{ justifyContent: 'center' }}
          childrenContent={returnAssignRole()}
        />
        :
        <ModalCustom
          open={openAssignRoleModal}
          onClose={() => {
            setOpenAssignRoleModal(false);
          }}
          onClick={() => setOpenConfirmChangesRoleModal(true)}
          loading={loading}
          title={t('Atribuir Função')}
          confirmationButtonText={t('SALVAR')}
          textDeclineButton={t('CANCELAR')}
          childrenContent={returnAssignRole()}
          style={{ width: '30%' }}
        />
      }

      {isSm ?
        <ModalCustomBottom
          open={openEditSettingsModal}
          onClose={() => {
            setOpenEditSettingsModal(false);
          }}
          onClick={() => setOpenConfirmChangesModal(true)}
          loading={loading}
          title={t('Editar Usuário')}
          confirmationButtonText={t('SALVAR')}
          textDeclineButton={t('CANCELAR')}
          showIcon={false}
          buttonClose
          buttonsEnabled
          buttonRight={healthcareProfessional}
          styleButtons={{ justifyContent: 'center' }}
          childrenContent={returnEditUser()}
        />
        :
        <ModalCustom
          open={openEditSettingsModal}
          onClose={() => {
            setOpenEditSettingsModal(false);
          }}
          onClick={() => setOpenConfirmChangesModal(true)}
          loading={loading}
          title={t('Editar Usuário')}
          confirmationButtonText={t('SALVAR')}
          textDeclineButton={t('CANCELAR')}
          shortModal={!healthcareProfessional}
          buttonRight={healthcareProfessional}
          styleButtons={{ justifyContent: !healthcareProfessional ? 'center' : 'flex-end' }}
          childrenContent={returnEditUser()}
          style={{ width: healthcareProfessional ? '80%' : '30%' }}
        />
      }

      {isSm ?
        <ModalCustomBottom
          open={openDeleteUserModal}
          onClose={() => setOpenDeleteUserModal(false)}
          onClick={() => setOpenConfirmDeactivateUserModal(true)}
          loading={loading}
          title={t('Desativar Usuário')}
          confirmationButtonText={t('SALVAR')}
          textDeclineButton={t('CANCELAR')}
          showIcon={false}
          buttonClose
          childrenContent={returnDeactivateUser()}
          buttonsEnabled
          styleButtons={{ justifyContent: 'center' }}
        /> :
        <ModalCustom
          open={openDeleteUserModal}
          onClose={() => setOpenDeleteUserModal(false)}
          onClick={() => setOpenConfirmDeactivateUserModal(true)}
          loading={loading}
          title={t('Desativar Usuário')}
          confirmationButtonText={t('SALVAR')}
          textDeclineButton={t('CANCELAR')}
          buttonRight
          twoButton={false}
          childrenContent={returnDeactivateUser()}
        />
      }

      <ModalCustomBottom
        open={openOptionsModal}
        onClose={() => setOpenOptionsModal(false)}
        title={t('Detalhes do usuário')}
        showIcon={false}
        buttonClose
        childrenContent={
          <S.ContainerDetailsUser>
            <div className='boxInfo'>
              <h1>{t('ID')}</h1>
              <h2>{itemSelected.Id}</h2>
            </div>

            <div className='boxInfo'>
              <h1>{t('Nome')}</h1>
              <h2>{itemSelected.Name}</h2>
            </div>

            <div className='boxInfo'>
              <h1>{t('E-mail')}</h1>
              <h2>{itemSelected.Email}</h2>
            </div>

            <div className='boxInfo'>
              <h1>{t('Data de Cadastro')}</h1>
              <h2>{DataMaskFormtData(itemSelected.CreatedAt)}</h2>
            </div>

            <div className='boxInfo'>
              <h1>{t('Função')}</h1>
              <h2>{renderTextFunction(itemSelected.Role)}</h2>
            </div>

            <div className='boxButtons'>
              <ButtonMaterial
                style={{ borderColor: theme.colors.seventh, color: theme.colors.seventh, width: '100%' }}
                variant="outlined"
                label={t('ATRIBUIR FUNÇÃO')}
                icon={<Attribution style={{ color: theme.colors.seventh, fontSize: '12px', marginRight: '8px' }} />}
                onClick={() => handleModalEditRole(itemSelected.Id)}
              />
              <div className='twoButtons'>
                <ButtonMaterial
                  style={{ borderColor: theme.colors.secundary, color: theme.colors.secundary, width: '100%', marginRight: '16px' }}
                  variant="outlined"
                  label={t('EDITAR')}
                  icon={<Edit style={{ color: theme.colors.secundary, fontSize: '12px', marginRight: '8px' }} />}
                  onClick={() => handleModalEditUser(itemSelected.Id)}
                />

                <ButtonMaterial
                  style={{ borderColor: theme.colors.graphicRed, color: theme.colors.graphicRed, width: '100%' }}
                  variant="outlined"
                  label={itemSelected.Enabled ? t('BLOQUEAR') : t('BLOQUEADO')}
                  icon={<Block style={{ color: theme.colors.graphicRed, fontSize: '12px', marginRight: '8px' }} />}
                  disable={!itemSelected.Enabled}
                  onClick={() => {
                    setOpenOptionsModal(false)
                    setOpenDeleteUserModal(true)
                  }}
                />
              </div>
            </div>
          </S.ContainerDetailsUser>
        }
      />
    </S.Table>
  )

}

export default TableSettings
