import styled from 'styled-components'

export const ContainerHide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  .iconPassword {
        width: 24px;
        height: 24px;
  }
`