import Modal from '@mui/material/Modal';
import { IModalCancelCustomProps } from './types';
import * as S from './styles';
import { Close } from '@mui/icons-material';
import { theme } from '../../styles/theme';
import ButtonMaterial from '../Button';

export default function ModalCustom(props: Readonly<IModalCancelCustomProps>) {
  const {
    largeTitle = false,
    style,
    styleLine,
    redButtonLeft,
    redButtonRight,
    icon,
    iconHeader,
    open,
    onClose,
    onCloseBottom,
    closeBottom,
    onClick,
    title,
    description,
    confirmationButtonText,
    textDeclineButton,
    loading = false,
    childrenContent,
    buttonsEnabled = true,
    twoButton = true,
    closeBottomTop = true,
    shortModal = false,
    buttonRight = true,
    styleButtons,
    showPreviousButton = false,
    onPreviousClick,
    stepIndicators,
    step = 1
  } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <S.ContainerModal style={style} active={typeof description === 'string' || shortModal}>
        <S.Line style={styleLine} active={largeTitle}>
          {iconHeader}
          <h5>{title}</h5>
        </S.Line>

        {closeBottomTop && (
          <button className="buttonClose" onClick={onClose}>
            <Close className="icon" />
          </button>
        )}

        {description && <h4>{description}</h4>}

        {childrenContent}

        {buttonsEnabled && (
          <S.ButtonContainer style={styleButtons} step={step}>
            {showPreviousButton && (
              <div className="leftButtons">
                <ButtonMaterial
                  style={{ color: theme.colorsBase.white }}
                  variant="contained"
                  label="ANTERIOR"
                  onClick={onPreviousClick}
                />
              </div>
            )}
            <div className="stepIndicators">{stepIndicators}</div>
            <div className="actionButtons">
              {twoButton && (
                <>
                  {onCloseBottom && closeBottom ? (
                    <ButtonMaterial
                      style={{
                        borderColor: redButtonLeft ? theme.colors.graphicRed : theme.colors.secundary,
                        color: redButtonLeft ? theme.colorsBase.red : theme.colors.secundary,
                      }}
                      variant="outlined"
                      label={textDeclineButton}
                      onClick={() => onCloseBottom()}
                      disabled={loading}
                    />
                  ) : (
                    <ButtonMaterial
                      style={{
                        borderColor: redButtonLeft ? theme.colors.graphicRed : theme.colors.secundary,
                        color: redButtonLeft ? theme.colorsBase.red : theme.colors.secundary,
                      }}
                      variant="outlined"
                      label={textDeclineButton}
                      onClick={() => onClose()}
                      disabled={loading}
                    />
                  )}
                </>
              )}
              {buttonRight && (
                <ButtonMaterial
                  style={{
                    backgroundColor: redButtonRight ? theme.colors.graphicRed : theme.colors.seventh,
                    marginLeft: twoButton ? '24px' : '0px',
                  }}
                  variant="contained"
                  label={confirmationButtonText}
                  onClick={onClick}
                  loading={loading}
                  disabled={loading}
                  icon={icon}
                />
              )}
            </div>
          </S.ButtonContainer>
        )}
      </S.ContainerModal>
    </Modal>
  );
}
