import styled from 'styled-components'
import { responsiveFont } from '../../../utils/UtilsGlobal'

export const ContainerBackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  padding: 24px;

    &:hover {
      opacity: 0.8;
    }

    .iconBackButton {
      color: ${(props) => props.theme.colorsBase.white};
      width: 24px;
      height: 24px;
      background-color: ${(props) => props.theme.colors.seventh};
      border-radius: 50%;
    }

    h5 {
      margin-left: 8px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  

  @media (max-width: 680px) {
    padding: 16px;
  }
`

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 0.5px;
  background-color: ${(props) => props.theme.colors.ten};
  margin: 16px 0 48px 0;

  h5 {
    line-height: 32px;
    letter-spacing: 0.18px;
    text-align: left;
    font-size: ${responsiveFont(24)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    background-color:  #F9F9F9;
    padding: 0px 8px;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(20)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(28)};
    }
  }
`

export const ContainerContent = styled.div`
  padding: 0 40px;
  width: 100%;
`