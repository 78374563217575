import React, { useEffect, useState } from 'react';
import * as S from './styles'
import { getNotifications, postNotificationRead, } from '../../services/Notifications';
import HeaderAchievements from '../../components/HeaderAchievements';
import CardNotification from '../../components/CardNotification';
import ButtonMaterial from '../../components/Button'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ToastNotification from '../../components/ToastNotification';
import ViewError from '../../components/ViewError';
import { INotification } from '../../services/Notifications/types';
import { theme } from '../../styles/theme'
import { Check, History } from '@mui/icons-material';

const Notification: React.FC = () => {
  const { t } = useTranslation();
  const [larguraDaTela, setLarguraDaTela] = useState(window.innerWidth);
  const navigate = useNavigate()
  const [notificationList, setNotificationList] = useState<INotification[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setLarguraDaTela(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    retrieveNotifications();
    resetCountDown();
  }, []);

  const [time, setTime] = useState(0);
  /* eslint-disable @typescript-eslint/no-explicit-any */
  let countdownTimeOut: any;

  useEffect(() => {
    if (time > 0) {
      countdownTimeOut = setTimeout(() => {
        setTime((state) => state - 1);
      }, 1000);
    } else if (time <= 0) {
      retrieveNotifications();
      resetCountDown();
    }
  }, [time]);

  const resetCountDown = () => {
    clearTimeout(countdownTimeOut);
    setTime(100);
  };

  const retrieveNotifications = async () => {
    setLoading(true)
    try {
      const bodyDataUser = {
        PageNumber: 1,
        PageSize: 9999,
        Visualizadas: 'false',
      };
      const response = await getNotifications(bodyDataUser);
      if (response.Success) {
        setNotificationList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar as notificações, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar as notificações, tente novamente.')
      });
    }
    setLoading(false)
  };

  const notificationRead = async (id?: string) => {
    try {
      const idList: string[] = [];

      if (id) {
        idList.push(id)
      } else {
        notificationList.forEach((item) => {
          idList.push(item.Id)
        })
      }
      idList.map(async (item) => {
        const response = await postNotificationRead(item);
        if (response.Success) {
          retrieveNotifications();
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Houve um erro ao visualizar as notificações, tente novamente.'),
            errorMessage: response.Message,
            errors: response.Errors
          });
        }
      })
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao visualizar as notificações, tente novamente.')
      });
    }
  };

  return (
    <S.Container>
      <HeaderAchievements
        title={t('Notificações')}
        onClick={() => navigate(-1)}
      />

      <S.ContainerContent>
        <S.ContainerTable>
          {notificationList.length > 0 ? (
            <>
              {notificationList.map((item) => (
                <CardNotification key={item.Id} item={item} onClickClose={() => notificationRead(item.Id)} />
              ))}

              <ButtonMaterial
                style={{ borderColor: theme.colors.secundary, marginTop: larguraDaTela > 800 ? "40px" : "24px", minWidth: larguraDaTela > 800 ? "40%" : "100%" }}
                variant="outlined"
                label={t('MARCAR TUDO COMO LIDO')}
                icon={<Check style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.secundary }} />}
                onClick={() => notificationRead()}
                disabled={loading}
              />
            </>
          ) : (
            <ViewError message={t('Nenhuma notificação encontrada!')} />
          )}

          <S.ContainerBottom>
            <ButtonMaterial
              style={{ borderColor: theme.colors.secundary, minWidth: larguraDaTela > 800 ? "10%" : "100%" }}
              variant="outlined"
              label={t('HISTÓRICO')}
              icon={<History style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.secundary }} />}
              onClick={() => navigate('/historico_notificacoes')}
              disabled={loading}
            />
          </S.ContainerBottom>
        </S.ContainerTable>
      </S.ContainerContent>
    </S.Container>
  )
}

export default Notification
