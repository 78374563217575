import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const ContainerDataAssessment = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
 // margin-top: 56px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  
  .divContentText {
    width: 50%;
    height: 100%
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .boxTextLeft {
      h3 {
        line-height: 14px;
        letter-spacing: 0.18px;
        text-align: left;
        font-size: ${responsiveFont(20)};
        color: ${(props) => props.theme.colorsBase.black3};
        font-weight: 400;
        font-family: 'Barlow', Regular;
    
        @media (max-width: 680px) {
          font-size: ${responsiveFont(16)};
        }
    
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(22)};
        }
      }
  
      h5 {
        margin-top: 8px;
        line-height: 14px;
        letter-spacing: 0.18px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colorsBase.black3};
        font-weight: 400;
        font-family: 'Barlow', Regular;
    
        @media (max-width: 680px) {
          font-size: ${responsiveFont(10)};
          margin-top: 4px;
        }
    
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }
   
  }

  .divContentAssessment {
    width: 50%;
    height: 100%
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 40px;

    h5 {
      margin-bottom: 8px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.fourth};
      font-weight: 400;
      font-family: 'Barlow', Regular;
  
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
        margin-top: 4px;
        margin-bottom: 4px;
      }
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
  }


  @media (max-width: 680px) {
    padding: 8px 16px 16px 16px;
    margin-top: 0px;
    flex-direction: column;
    align-items: flex-start;

    .divContentText {
      width: 100%;
      padding-right: 0px;
      align-items: flex-start;
    }

    .divContentAssessment {
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      padding: 0px;
    }
  }
`