import React from 'react';
import { Button, Card, CardContent, CardMedia, Paper, Stack, StackProps, Typography } from '@mui/material';
import { Person, KeyboardArrowRight, CalendarMonthOutlined } from '@mui/icons-material';
import { WithTranslationProps, withTranslation } from 'react-i18next';
import { format } from 'date-fns';

import LanguageFlag from '../../../../components/LanguageFlag';
import { IChallengeWaitingCurarionDashboard } from '../../../../services/Dashboard/CurationsDashboard/types';
import { Link } from 'react-router-dom';

interface IProps extends StackProps, WithTranslationProps {
  challenges: IChallengeWaitingCurarionDashboard
}

const AwatingTriageCard: React.FC<IProps> = ({ challenges, i18n, ...props }) => {
  return (
    <Paper component={Stack} {...props} justifyContent="space-between" p="16px">
      <Typography fontSize="20px" fontWeight="400" color='#201E1E'>{i18n?.t("Desafios aguardando Revisão")}: {challenges.WaitingCurationCount}</Typography>

      <Stack spacing={1}>

        {challenges.Items?.slice(0, 2).map(({ AuthorName, Title, DateTime, CoverImageUrl }) => (
          <Card sx={{ display: 'flex' }}>
            <CardMedia
              component="img"
              sx={{ width: 68 }}
              image={CoverImageUrl}
              alt={Title}
            />

            <CardContent sx={{ width: "100%", p: 1, ":last-child": { pb: 1 } }}>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontSize="14px" fontWeight="400" color="rgba(0, 0, 0, 0.74)">
                  {Title}
                </Typography>
                <LanguageFlag Idioma={1} fillSpace={false} />
              </Stack>


              <Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Person className="i" fontSize='small' />
                  <Typography variant="body2" color="text.secondary">
                    {AuthorName || "N/A"}
                  </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <CalendarMonthOutlined className="i" fontSize='small' />
                  <Typography variant="body2" color="text.secondary">
                    {format(new Date(DateTime), "dd/MM/yyyy")}
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>

      <Stack direction="row" justifyContent="center">
        <Link to="/inspection">
          <Button
            variant="text"
            endIcon={<KeyboardArrowRight />}
            sx={{ color: "#1A1266" }}
          >
            {i18n?.t("Ver todos")}
          </Button>
        </Link>
      </Stack>
    </Paper>
  );
}

export default withTranslation()(AwatingTriageCard);
