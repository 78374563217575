export type RuleNames = 'length' | 'specialChar' | 'number' | 'capital' | 'lowerCase' | 'match' | 'equalNumber'

export class RegistrationIdentification {
  name = '';
  language = '';
  difficultyLevel = '';
  category = '';
  tags = '';
  mediaType = '';
}

export class RegistrationIdentificationError {
  name = '';
  language = '';
  difficultyLevel = '';
  category = '';
  tags = '';
  mediaType = '';
}

export class RegistrationAdditionalInformation {
  duration = '';
  source = '';
  sourceNotes = '';
  award = '';
}

export class RegistrationAdditionalInformationError {
  duration = '';
  source = '';
  sourceNotes = '';
  award = '';
}

export class RegistrationQuiz {
  questionType = '1';
  question = '';
  response1 = '';
  response2 = '';
  response3 = '';
  response4 = '';
  response5 = '';
  trueAnswer = '';
}

export class RegistrationQuizError {
  questionType = '';
  question = '';
  response1 = '';
  response2 = '';
  response3 = '';
  response4 = '';
  response5 = '';
  trueAnswer = '';
}