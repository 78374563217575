import React from 'react';
import { UseMutationResult } from '@tanstack/react-query';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import ModalCustom from '../../../components/ModalCustom';
import { IPostAcceptModerationCommand } from '../../../services/Moderation/types';

interface IProps {
  open: boolean;
  onClose: () => void;
  mutation: UseMutationResult<unknown, unknown, Omit<IPostAcceptModerationCommand, "DesafioId">, unknown>
}

const ApproveChallengeModal: React.FC<IProps> = ({ open, onClose, mutation }) => {
  const { t } = useTranslation()

  const { control, handleSubmit } = useForm<Omit<IPostAcceptModerationCommand, 'DesafioId'>>({
    defaultValues: { AceiteDivulgacao: false }
  })

  return (
    <ModalCustom
      open={open}
      onClick={handleSubmit((data) => mutation.mutateAsync(data, { onSettled: onClose }))}
      onClose={onClose}
      buttonsEnabled={true}
      title={t("Aprovar desafio")}
      childrenContent={(
        <Controller
          control={control}
          name='AceiteDivulgacao'
          render={({ field: { value, ...field } }) => (
            <FormControlLabel
              control={<Checkbox checked={value} {...field} />}
              label="Autorizo a aparição do meu nome como moderador resposável pelo desafio."
            />
          )}
        />
      )}
      loading={mutation.isLoading}
      confirmationButtonText={t("ENVIAR")}
      textDeclineButton={t("CANCELAR")}
    />
  )
}

export default ApproveChallengeModal;
