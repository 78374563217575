import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 16px; 

  @media (max-width: 680px) {
    padding: 0 0 16px 0;
  }
`

export const ContainerChallenge = styled.div` 
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;

  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 8px 16px 8px;
    width: 100%;
  }
  
   @media (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 2500px) {
    grid-template-columns: repeat(5, 1fr);
  }
`