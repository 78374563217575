import React, { useEffect, useState } from 'react';
import * as S from './styles'
import { WorkspacePremium } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ToastNotification from '../../components/ToastNotification';
import ViewLoading from '../../components/ViewLoading';
import { getCertificates } from '../../services/Challenges';
import { ICertificates } from '../../services/Challenges/types';
import HeaderAchievements from '../../components/HeaderAchievements';
import ViewError from '../../components/ViewError';
import CardCertificates from '../../components/CardCertificates';

const MyCertificates: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<ICertificates[]>([])

  useEffect(() => {
    retrieveCertificates();
  }, []);

  const retrieveCertificates = async () => {
    setLoading(true)
    try {
      const response = await getCertificates();
      if (response.Success) {
        setData(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };


  return (
    <S.Container>
      <HeaderAchievements
        title={t('Meus Certificados')}
        onClick={() => navigate(-1)}
        icon={<WorkspacePremium className="i" />}
      />

      <S.ContainerContent>
        <S.ContainerPoints>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <ViewLoading />
            </div>
          ) : (
            <>
              {data.length > 0 ? (
                <>
                  {data.map((item) => (
                    <CardCertificates key={item.Id} item={item} />
                  ))}
                </>
              ) : <ViewError message={t('Nenhum certificado encontrado!')} />}
            </>
          )}
        </S.ContainerPoints>
      </S.ContainerContent>
    </S.Container>
  )
}

export default MyCertificates
