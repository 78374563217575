import { useMutation } from "@tanstack/react-query";

import { sendRequest } from "../../RequestService";

import { IChallengesCurationDisapproveCommand, IChallengesCurationDisapproveCommandResponse } from "./types";

const defaultRoot = "challenges-curations/disapprovals"

/**
 * Reprovar desafio
 * @returns 
 */
export const useChalengeCurationDisapproveMutation = () => useMutation({
  mutationFn: async (command: IChallengesCurationDisapproveCommand) => {
    const url = `${defaultRoot}/${command.id}`

    return await sendRequest<IChallengesCurationDisapproveCommandResponse>(url, 'POST', command.reason)
  }
})
