import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToastNotification from '../../../components/ToastNotification';
import ViewError from '../../../components/ViewError';
import { useIsSm } from '../../../hooks/useIsSm';
import { Pagination } from '@mui/material';
import SearchBar from '../../../components/SearchBar';
import { ICardSpecialProgram } from './types';
import ChallengeItemDesktop from '../../../components/ChallengeItemDesktop';
import { getSpecialProgram } from '../../../services/SpecialProgram';
import { ISpecialProgram } from '../../../pages/SpecialProgramManagement/ScreenUnderConstruction/types';
import * as S from '../../../pages/SpecialProgramManagement/ScreenUnderConstruction/styles';
import { Pages } from '../../../pages/ViewChallenge/styles';
import ModalSpecialProgram from '../../../pages/SpecialProgramManagement/ScreenUnderConstruction/ModalSpecialProgram';

interface IProps {
  update: boolean;
}

const ScreenTriageSpecialProgram: React.FC<IProps> = ({ update }) => {
  const { t } = useTranslation();
  const isSm = useIsSm();
  const [value, setValue] = useState('');
  const [specialProgramList, setSpecialProgramList] = useState<ICardSpecialProgram[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [larguraDaTela, setLarguraDaTela] = useState(window.innerWidth);

  useEffect(() => {
    retrieveSpecialProgram(1, true);
  }, [update, isSm]);

  const onEnterSearch = (event: { key: string }) => {
    if (event.key === 'Enter') {
      retrieveSpecialProgram(1, true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setLarguraDaTela(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = async () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (scrollY + windowHeight >= documentHeight - 100) {
        if ((currentPage <= (totalPages || 0)) && isSm) {
          await retrieveSpecialProgram(currentPage + 1, false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, totalPages, isSm]);

  const numberElements = () => {
    if (larguraDaTela > 800 && larguraDaTela < 1600) {
      return 3;
    } else if (larguraDaTela > 1600 && larguraDaTela < 2000) {
      return 4;
    } else if (larguraDaTela > 2000) {
      return 5;
    }
    return 3;
  };

  function convertSpecialProgram(program: ISpecialProgram): ICardSpecialProgram {
    return {
      Id: program?.Id,
      Title: program?.Title,
      CoverImageUrl: program?.Image,
      DateTime: '',
      Language: program.Language.toString(),
    };
  }

  const retrieveSpecialProgram = async (page: number = 1, clear: boolean = false) => {
    try {
      if (clear) {
        setSpecialProgramList([]);
      }
      const bodyData = {
        PageNumber: page,
        PageSize: isSm ? 64 : numberElements(),
        Name: value,
        Type: 3,
        GetChallenges: true
      };
      const response = await getSpecialProgram(bodyData);
      if (response.Success) {
        const responseAPI: ISpecialProgram[] = response.Data
        const mapperSpecialPrograms = responseAPI.map(convertSpecialProgram)

        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }
        if (clear) {
          setSpecialProgramList(mapperSpecialPrograms);
        } else if (response.Data.length > 1) {
          const novaArray = specialProgramList.concat(mapperSpecialPrograms);
          setSpecialProgramList(novaArray);
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os programas, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os programas, tente novamente.')
      });
    }
  };


  return (
    <S.Container style={{ marginTop: '8px' }}>
      <SearchBar
        value={value}
        setValue={(e) => setValue(e)}
        onEnterSearch={(e) => onEnterSearch(e)}
        placeholder={t('Busque o programa especial')}
        filterButton={false}
        style={{ padding: 0, width: isSm ? '100%' : '80%', marginRight: 'auto' }}
      />

      {specialProgramList.length > 0 ? (
        <>
          <S.ContainerChallenge>
            {specialProgramList.map((item) => (
              <ChallengeItemDesktop
                key={item.Id}
                item={item}
                onClick={() => {
                  setOpen(!open);
                  setId(item.Id ?? '')
                }}
                typeChallenge={10}
                showId
                labelButton="GERENCIAR PROGRAMA"
              />
            ))}
          </S.ContainerChallenge>

          {!isSm && (
            <Pages>
              <Pagination
                showFirstButton
                showLastButton
                count={totalPages}
                page={currentPage}
                color="primary"
                onChange={(e, p) => {
                  e.preventDefault();
                  e.stopPropagation();
                  retrieveSpecialProgram(p, true);
                }}
              />
            </Pages>
          )}
        </>
      ) : (
        <ViewError message={t('Nenhum programa encontrado!')} />
      )}

      <ModalSpecialProgram open={open} setOpen={setOpen} id={id} update={() => retrieveSpecialProgram(1, true)} triage={true} />
    </S.Container>
  );
};

export default ScreenTriageSpecialProgram;
