import React, { useState } from 'react';

import { useIsSm } from '../../hooks/useIsSm';
import TriageSpecialProgramView from './view';

const TriageSpecialProgram: React.FC = () => {
  const [tab] = useState(0)
  const isSm = useIsSm()

  return (
    <TriageSpecialProgramView
      tab={tab}
      variant={isSm ? 'scrollable' : 'standard'}
    />
  );
}

export default TriageSpecialProgram;