import React, { useEffect, useState } from 'react';
import { Star, } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ToastNotification from '../../components/ToastNotification';
import ViewLoading from '../../components/ViewLoading';
import { getMyPoints, } from '../../services/Challenges';
import { IMyPoints } from '../../services/Challenges/types';
import CardAchievements from '../../components/CardAchievements';
import HeaderAchievements from '../../components/HeaderAchievements';
import { Container, ContainerContent, ContainerPoints } from './styles';

const MyPoints: React.FC = () => {
  const [data, setData] = useState<IMyPoints>();
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    retrievePoints();
  }, []);

  const retrievePoints = async () => {
    setLoading(true)
    try {
      const response = await getMyPoints();
      if (response.Success) {
        setData(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };


  return (
    <Container>
      <HeaderAchievements
        title={t('Meus Pontos')}
        onClick={() => navigate(-1)}
        icon={<Star className="i" />}
      />

      <ContainerContent>
        <ContainerPoints>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <ViewLoading />
            </div>
          ) : (
            <>
              <CardAchievements title={t('Pontos Ganhos')} description={t('Total de pontos ganhos ao completar desafios:')} value={data?.PointsDonated} />
              <CardAchievements title={t('Pontos Recebidos')} description={t('Total de pontos recebidos por outros participantes:')} value={data?.PointsDonated} />
              <CardAchievements title={t('Pontos Doados')} description={t('Total de pontos doados para contatos:')} value={data?.PointsEarned} />
              <CardAchievements title={t('Meu Saldo Atual')} description={t('Total de pontos atual:')} value={data?.PointsEarned} />
            </>
          )}
        </ContainerPoints>
      </ContainerContent>
    </Container>
  )
}

export default MyPoints
