import React from 'react';

import { useCurationsDashboard } from '../../services/Dashboard/CurationsDashboard';

import InspectionDashboardView from './view';

const InspectionDashboard: React.FC = () => {
  const query = useCurationsDashboard();

  return <InspectionDashboardView query={query} />;
}

export default InspectionDashboard;
