import React, { useEffect, useState } from 'react';

import * as S from './styles'
import { useTranslation } from 'react-i18next';
import ToastNotification from '../../components/ToastNotification';
import ViewLoading from '../../components/ViewLoading';
import ViewError from '../../components/ViewError';
import { Pagination } from '@mui/material'
import TableSettings from '../../components/TableSettings';
import { getSettings } from '../../services/Settings';
import { ISettings } from '../../components/TableSettings/types';

interface IProps {
  hidden: boolean
}

const Home: React.FC<IProps> = ({ hidden }) => {
  const { t } = useTranslation();
  const [parametersList, setParametersList] = useState<ISettings[]>([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState<number | undefined>(0)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    retrieveParameters(1, true);
  }, []);

  const retrieveParameters = async (page: number = 1, clear: boolean = false) => {
    setLoading(true)
    try {
      if (clear) {
        setParametersList([]);
      }
      const bodyData = {
        PageNumber: page,
        PageSize: 8
      };
      const response = await getSettings(bodyData);
      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }
        setParametersList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os parãmetros, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os parãmetros, tente novamente.')
      });
    }
    setLoading(false)
  };

  return (
    <S.Container style={{ display: hidden ? 'none' : 'flex' }}>
      <S.ContainerTable>
        {loading ? (
          <ViewLoading />
        ) : (
          <>
            {parametersList.length > 0 ? (
              <>
                <TableSettings listUsers={parametersList} updateList={() => retrieveParameters(1, true)} />
                <S.Pages>
                  <Pagination
                    showFirstButton
                    showLastButton
                    count={totalPages}
                    page={currentPage}
                    color="primary"
                    onChange={(e, p) => {
                      e.preventDefault()
                      e.stopPropagation()
                      retrieveParameters(p)
                    }}
                  />
                </S.Pages>
              </>
            ) : (
              <ViewError message={t('Nenhum parâmetro encontrado')} />
            )}
          </>
        )}
      </S.ContainerTable>
    </S.Container>
  )
}

export default Home
