import React, { useState } from 'react';
import { ITableUsers } from './types'
import * as S from './styles'
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import { useIsSm } from '../../hooks/useIsSm';
import ReactAudioPlayer from 'react-audio-player';
import { Done } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AnswerQuestions, ContainerQuestionCount, ModalBottomQuiz, ButtonQuestion } from '../../pages/ViewChallenge/styles';

const ViewQuiz: React.FC<ITableUsers> = (props: ITableUsers) => {
  const { questionContent } = props
  const isSm = useIsSm()
  const { t } = useTranslation();
  const [currentQuestion, setCurrentQuestion] = useState(0)

  return (
    <>
      {questionContent.length > 0 ? <>
        <AnswerQuestions>
          <div className='divContentText'>
            <div className='divNumber'>
              <h3>{currentQuestion + 1}</h3>
            </div>

            <h5>{t(questionContent[currentQuestion]?.Question || '')}</h5>
          </div>

          {questionContent[currentQuestion]?.AudioUrl && (
            <ReactAudioPlayer
              src={questionContent[currentQuestion]?.AudioUrl}
              controls
              style={{ marginTop: isSm ? '8px' : '16px', width: isSm ? '100%' : '90%' }}
            />
          )}

          <div className='divContent'>
            {questionContent[currentQuestion]?.Alternatives?.map((item, index) => (
              <div key={item.Id} className='boxAlternative' style={{ backgroundColor: item.RightAnswer ? theme.colorsBase.green4 : 'transparent', border: item.RightAnswer ? `solid 1px ${theme.colorsBase.green3}` : 'solid 1px transparent' }}>
                <h3>{index + 1}. </h3>
                <h5>{item.Answer}</h5>
                {item.RightAnswer && <Done className="iconDone" />}
              </div>
            ))}
          </div>
        </AnswerQuestions>

        {isSm && <ContainerQuestionCount>
          {questionContent.map((item, index) => (
            <ButtonQuestion key={item.Id} active={currentQuestion === index}>
              <h5 className='textbutton'>{index + 1}</h5>
            </ButtonQuestion>
          ))}
        </ContainerQuestionCount>}

        <ModalBottomQuiz>
          <ButtonMaterial
            style={{ marginRight: isSm ? '16px' : '0', width: isSm ? '100%' : 'auto', border: `solid 1px ${currentQuestion <= 0 ? theme.colorsBase.gray3 : theme.colors.seventh}`, color: currentQuestion <= 0 ? theme.colorsBase.gray3 : theme.colors.seventh }}
            variant="outlined"
            color='info'
            label={t("PERGUNTA ATERIOR")}
            onClick={() => setCurrentQuestion(currentQuestion - 1)}
            disable={currentQuestion <= 0}
          />

          {!isSm && <ContainerQuestionCount>
            {questionContent.map((item, index) => (
              <ButtonQuestion key={item.Id} active={currentQuestion === index}>
                <h5 className='textbutton'>{index + 1}</h5>
              </ButtonQuestion>
            ))}
          </ContainerQuestionCount>}


          <ButtonMaterial
            style={{ padding: 'auto', width: isSm ? '100%' : 'auto', border: `solid 1px ${questionContent.length !== (currentQuestion + 1) ? theme.colors.seventh : theme.colorsBase.gray3}`, color: questionContent.length !== (currentQuestion + 1) ? theme.colors.seventh : theme.colorsBase.gray3 }}
            variant="outlined"
            color='info'
            label={t("PRÓXIMA PERGUNTA")}
            onClick={() => setCurrentQuestion(currentQuestion + 1)}
            disable={questionContent.length === (currentQuestion + 1)}
          />
        </ModalBottomQuiz>
      </> : <S.EmptyQuiz><h1 className='textEmptyQuiz'>{t("Perguntas não cadastradas")}</h1></S.EmptyQuiz>}
    </>
  )
}

export default ViewQuiz