import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  width: 100% !important;
  background-color: #F9F9F9;
`

export const Body = styled.main<{ signed: boolean }>`
  @media (max-width: 800px) {
    margin-left: 0px;
  }

  width: ${(props) => (props.signed ? '100%' : '100% !important')};
  margin-left: ${props => props.signed ? '60px' : '0px'}
`
