import { sendRequest } from "../../../services/RequestService";
import {  IChalengeScreeningQueryResponse } from "./types";
import { IBodyGET } from "../../../services/Challenges/types";

const defaultRoot = "challenges-management/all"

export const getChallengesAll = async (body: IBodyGET) => {
  let url = `${defaultRoot}`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Title=${body.Nome}`;
  url += `&OrderByProperty=-Id`;
  return await sendRequest<IChalengeScreeningQueryResponse[], IBodyGET>(url, 'GET')
}