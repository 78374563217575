import React, { useState } from 'react';

import * as S from './styles'
import { useTranslation } from 'react-i18next';
import ButtonMaterial from '../../../components/Button';
import { theme } from '../../../styles/theme';
import { useIsSm } from '../../../hooks/useIsSm';
import { Add } from '@mui/icons-material';
import ScreenUnderConstruction from '../ScreenUnderConstruction';
import ModalCreateSpecialProgram from '../ModalCreateSpecialProgram';
import ButtonCreateMobileSpecialProgram from '../../../components/ButtonCreateMobileSpecialProgram';

interface IProps {
  tab: number
}

const ScreenSpecialProgramManagement: React.FC<IProps> = ({ tab }) => {
  const { t } = useTranslation();
  const isSm = useIsSm()
  const [open, setOpen] = useState(false)

  return (
    <S.Container>
      <S.ContainerTable>
        {!isSm ?
          <div className='containerButton'>
            <ButtonMaterial
              style={{ borderColor: theme.colors.seventh, color: theme.colorsBase.white, backgroundColor: theme.colors.seventh, minWidth: "none", borderRadius: '50px  8px 8px 50px' }}
              variant="outlined"
              label={t('Criar novo Programa ESPECIAL')}
              icon={<Add style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.white }} />}
              onClick={() => setOpen(!open)}
            />
          </div>
          : <ButtonCreateMobileSpecialProgram onClick={() => setOpen(!open)} />}

        <ScreenUnderConstruction update tab={tab} />

        <ModalCreateSpecialProgram
          open={open}
          setOpen={setOpen}
        />
      </S.ContainerTable>
    </S.Container>
  )
}

export default ScreenSpecialProgramManagement