import React, { useState, useEffect } from 'react';

import Footer from '../../components/Footer'
import { theme } from '../../styles/theme';
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import HeaderAuthentication from '../../components/HeaderAuthentication';
import ButtonMaterial from '../../components/Button';
import ToastNotification from '../../components/ToastNotification';
import { useAuth } from '../../contexts/Auth';
import { postSendEmailConfirmation } from '../../services/User';
import AuthCode from '../../components/InputCode';
import * as S from './styles'
import { removeSpaces } from '../../utils/UtilsGlobal';

const EmailConfirmation: React.FC = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const { emailConfirmation, email } = useAuth();
  const [time, setTime] = useState(0);
  const [hasFinished, setHasFinished] = useState(false);
  const [loadingValidationCode, setLoadingValidationCode] = useState(false);
  const [validationCode, setValidationCode] = useState('');

  /* eslint-disable @typescript-eslint/no-explicit-any */
  let countdownTimeOut: any;

  useEffect(() => {
    if (token === 'login') {
      handleSendEmail();
    } else {
      clearTimeout(countdownTimeOut);
      setHasFinished(false);
      setTime(0.5 * 60);
    }
  }, []);

  useEffect(() => {
    if (time > 0) {
      countdownTimeOut = setTimeout(() => {
        setTime((state) => state - 1);
      }, 1000);
    } else if (time === 0) {
      setHasFinished(true);
    }
  }, [time]);

  const resetCountDown = () => {
    handleSendEmail();
    clearTimeout(countdownTimeOut);
    setHasFinished(false);
    setTime(0.5 * 60);
  };

  const handleSendEmail = async () => {
    setLoadingValidationCode(true);
    try {
      const bodyData = {
        Email: removeSpaces(email),
        TipoEmail: 2
      };
      const response = await postSendEmailConfirmation(bodyData);
      if (response.Success) {
        clearTimeout(countdownTimeOut);
        setHasFinished(false);
        setTime(0.5 * 60);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao enviar o e-mail, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao enviar o e-mail, tente novamente.')
      });
    }
    setLoadingValidationCode(false);
  };

  const handleValidationCode = async () => {
    setLoadingValidationCode(true);
    try {
      if (validationCode.length !== 7) {
        setLoadingValidationCode(false);
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('O código informado está inválido.')
        });
        return;
      }

      await emailConfirmation(validationCode, removeSpaces(email), (token !== 'login' ? token : ''));
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao confirmar o código, tente novamente.')
      });
    }
    setLoadingValidationCode(false);
  };

  return (
    <S.Container>
      <HeaderAuthentication title={t('Verificação de E-mail') ?? ''} />

      <S.ContainerContent>
        <div className='containerRecoverPassword'>
          <h3>{t('Insira abaixo o Código de Verificação que você recebeu em seu e-mail para concluir.')}</h3>
          <h1>{t('Verifique a caixa de spam e lixo eletrônico!')}</h1>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <AuthCode onChange={(e: string) => setValidationCode(e)} />
          </div>

          <ButtonMaterial
            style={{ borderColor: theme.colors.secundary, width: '100%', marginTop: '100px' }}
            variant="outlined"
            label={`${t("REENVIAR ")} ${time <= 0 ? '' : `(${time})`}`}
            onClick={resetCountDown}
            disable={!hasFinished}
          />

          <ButtonMaterial
            style={{ backgroundColor: theme.colors.seventh, width: '100%', marginTop: '16px' }}
            variant="contained"
            label={t("CONFIRMAR")}
            onClick={handleValidationCode}
            loading={loadingValidationCode}
          />
        </div>
      </S.ContainerContent>

      <div style={{ display: 'flex', width: '100%', flex: 1, alignItems: 'flex-end' }}>
        <Footer />
      </div>
    </S.Container>
  )
}

export default EmailConfirmation
