import React, { useEffect, useState } from 'react';
import * as S from './styles'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CardLink from './card';
import HeaderAchievements from '../../components/HeaderAchievements';
import { getExtract, getMyPoints, } from '../../services/Challenges';
import { IExtract, IMyPoints } from '../../services/Challenges/types';
import ToastNotification from '../../components/ToastNotification';
import ViewLoading from '../../components/ViewLoading';
import CardAchievements from '../../components/CardAchievements';
import { theme } from '../../styles/theme';
import { AccountBalanceWallet, EmojiEvents } from '@mui/icons-material';
import { useIsSm } from '../../hooks/useIsSm';
import ModalCustom from '../../components/ModalCustom';
import { DataMaskFormtData } from '../../utils/Mask';
import { Pagination } from '@mui/material'
import ViewError from '../../components/ViewError';
import { changeDateLocal } from '../../utils/UtilsGlobal';

const MyAchievements: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IMyPoints>();
  const isSm = useIsSm()
  const [openModal, setOpenModal] = useState(false)
  const [totalPages, setTotalPages] = useState<number | undefined>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [extractList, setExtractList] = useState<IExtract[]>([])
  const { token } = useParams();

  useEffect(() => {
    retrievePoints();
    retrieveExtract(1, true);

    if (token === 'extract') {
      setOpenModal(true)
    }
  }, []);

  const retrievePoints = async () => {
    setLoading(true)
    try {
      const response = await getMyPoints();
      if (response.Success) {
        setData(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  const retrieveExtract = async (page: number = 1, clear: boolean = false) => {
    setLoading(true)
    try {
      if (clear) {
        setExtractList([]);
      }
      const bodyData = {
        PageNumber: page,
        PageSize: 6
      };
      const response = await getExtract(bodyData);
      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }
        setExtractList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar o extrato, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar o extrato, tente novamente.')
      });
    }
    setLoading(false)
  };

  return (
    <S.Container>
      <HeaderAchievements
        title={t('Minhas Conquistas')}
        description={t('Nessa página é possível encontrar todos os itens e pontuações que você conquistou completando desafios e/ou programas especiais.') ?? ''}
        onClick={() => navigate(-1)}
        icon={!isSm ? <EmojiEvents className="i" style={{ color: theme.colorsBase.gray3 }} /> : null}
      />

      <S.ContainerContent>
        <S.ContainerPoints>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <ViewLoading />
            </div>
          ) : (
            <>
              <S.ContainerData>
                <CardAchievements onClick={() => setOpenModal(true)} extractButton title={t('Meu Saldo Atual')} description={t('Total de pontos atual:')} value={data?.TotalBalance} style={{ marginBottom: isSm ? '6px' : 'none' }} />

                {isSm ? (
                  <>
                    <div className='boxDubleCard'>
                      <CardAchievements smallerSize style={{ marginRight: '6px', padding: '12px 4px', marginBottom: 0 }} title={t('Pontos Ganhos')} description={t('Total de pontos ganhos ao completar desafios:')} value={data?.PointsEarned} />
                      <CardAchievements smallerSize style={{ padding: '12px 4px', marginBottom: 0 }} title={t('Pontos Recebidos')} description={t('Total de pontos recebidos por outros participantes:')} value={data?.PointsReceived} />
                    </div>

                    <div className='boxDubleCard'>
                      <CardAchievements smallerSize style={{ marginRight: '6px', padding: '12px 4px', marginBottom: 0 }} title={t('Pontos Doados')} description={t('Total de pontos doados para contatos:')} value={data?.PointsDonated} />
                      <CardAchievements smallerSize style={{ padding: '12px 4px', marginBottom: 0 }} title={t('Pontos Trocados')} description={t('Total de pontos já trocados por produtos:')} value={data?.PointsExchanged} />
                    </div>
                  </>
                ) : (
                  <>
                    <CardAchievements title={t('Pontos Ganhos')} description={t('Total de pontos ganhos ao completar desafios:')} value={data?.PointsEarned} />
                    <CardAchievements title={t('Pontos Recebidos')} description={t('Total de pontos recebidos por outros participantes:')} value={data?.PointsReceived} />
                    <CardAchievements title={t('Pontos Doados')} description={t('Total de pontos doados para contatos:')} value={data?.PointsDonated} />
                    <CardAchievements title={t('Pontos Trocados')} description={t('Total de pontos já trocados por produtos:')} value={data?.PointsExchanged} />
                  </>
                )}
              </S.ContainerData>

              <S.ContainerAchivements>
                <CardLink type={1} onClick={() => navigate('/produtos')} />
                <CardLink type={2} onClick={() => navigate('/contatos')} />
                <CardLink type={3} onClick={() => navigate('/premios_especiais')} />
                <CardLink type={4} onClick={() => navigate('/em_desenvolvimento')} />
              </S.ContainerAchivements>
            </>
          )}
        </S.ContainerPoints>
      </S.ContainerContent>


      <ModalCustom
        open={openModal}
        onClose={() => setOpenModal(false)}
        onClick={() => { }}
        shortModal
        childrenContent={
          <S.ModalExtract>
            <div className='topModal'>
              <h1 className='title'>{t('Total de pontos atual:')}</h1>
              <h1 className='value'>{data?.TotalBalance}</h1>
            </div>

            {extractList.length > 0 ? (
              <>
                <S.ListExtract>
                  {extractList?.map((item) => (
                    <div key={item.Id} className='itemList'>
                      {item.TypeWalletMovement === 1 && <p className='text'>{DataMaskFormtData(item?.CreatedAt)} {changeDateLocal(item?.CreatedAt.substring(11, 16))} | <b>+{item.QtyPoints} {t('pontos')}</b> {t('conquistados no desafio')}: <b>{item.ChallengeName}</b></p>}
                      {item.TypeWalletMovement === 3 && <p className='text'>{DataMaskFormtData(item?.CreatedAt)} {changeDateLocal(item?.CreatedAt.substring(11, 16))} | <b>+{item.QtyPoints} {t('pontos')}</b> {t('recebidos do contato')}: <b>{item.ContactRelationName}</b></p>}
                      {item.TypeWalletMovement === 4 && <p className='text'>{DataMaskFormtData(item?.CreatedAt)} {changeDateLocal(item?.CreatedAt.substring(11, 16))} | <b>+{item.QtyPoints} {t('pontos')}</b> {t('ganhos por conquistas')}</p>}
                      {item.TypeWalletMovement === 5 && <p className='text' style={{ color: theme.colors.graphicRed }}>{DataMaskFormtData(item?.CreatedAt)} {changeDateLocal(item?.CreatedAt.substring(11, 16))} | <b style={{ color: theme.colors.graphicRed }}>-{item.QtyPoints} {t('pontos')}</b> {t('doados para contato')}: <b style={{ color: theme.colors.graphicRed }}>{item.ContactRelationName}</b></p>}
                      {item.TypeWalletMovement === 6 && <p className='text' style={{ color: theme.colors.graphicRed }}>{DataMaskFormtData(item?.CreatedAt)} {changeDateLocal(item?.CreatedAt.substring(11, 16))} | <b style={{ color: theme.colors.graphicRed }}>-{item.QtyPoints} {t('pontos')}</b> {t('trocados por produto')}: <b style={{ color: theme.colors.graphicRed }}>{item.GiftName ?? item.ProductName}</b></p>}
                      {item.TypeWalletMovement === 7 && <p className='text'>{DataMaskFormtData(item?.CreatedAt)} {changeDateLocal(item?.CreatedAt.substring(11, 16))} | <b>+{item.QtyPoints} {t('pontos')}</b> {t('ganhos por um convite aceito')}</p>}
                      {item.TypeWalletMovement === 8 && <p className='text'>{DataMaskFormtData(item?.CreatedAt)} {changeDateLocal(item?.CreatedAt.substring(11, 16))} | <b>+{item.QtyPoints} {t('pontos')}</b> {t('ganhos por publicar um novo desafio')}</p>}
                    </div>
                  ))}
                </S.ListExtract>

                <S.Pages>
                  <Pagination
                    showFirstButton
                    showLastButton
                    count={totalPages}
                    page={currentPage}
                    color="primary"
                    onChange={(e, p) => {
                      e.preventDefault()
                      e.stopPropagation()
                      retrieveExtract(p)
                    }}
                  />
                </S.Pages>
              </>
            ) : (
              <ViewError message={t('Extrato vazio!')} />
            )}
          </S.ModalExtract>
        }
        loading={loading}
        title={t('Extrato de Pontos')}
        buttonsEnabled={false}
        iconHeader={<AccountBalanceWallet style={{ fontSize: '24px', color: theme.colorsBase.gray3, backgroundColor: theme.colorsBase.white }} />}
      />
    </S.Container>
  )
}

export default MyAchievements
