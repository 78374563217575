import React, { useEffect, useState, ChangeEvent, useRef } from 'react';

import * as S from './styles'
import { Add, Done, Edit, Lock, ModeEdit, Rule, Search, UploadFile, } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import ToastNotification from '../../components/ToastNotification';
import ViewLoading from '../../components/ViewLoading';
import ModalCustom from '../../components/ModalCustom';
import { getChallengeDetailsActive, getChallengeDetailsRejected, getSearchChallengeQuiz, putChallenge, } from '../../services/Challenges';
import Input from '../../components/Input';
import { RegistrationIdentification, RegistrationIdentificationError, } from './types';
import { MenuItem, Select, Tooltip, InputAdornment, TextField, Radio, FormControlLabel, } from '@mui/material'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import offImage from '../../assets/images/imgErro.png'
import { registerChallengeValidation } from './validations';
import ModalChallengeContent from '../../components/ModalChallengeContent';
import { RegistrationQuiz, RegistrationQuizError } from '../ViewChallenge/types';
import { IArrayQuestions, IQuizAlternatives } from '../../services/Challenges/types';
import { makeStyles } from "@material-ui/core/styles";
import ModalRegistrationAndQuizEditing from '../../components/ModalRegistrationAndQuizEditing';
import { useIsSm } from '../../hooks/useIsSm';
import HeaderAchievements from '../../components/HeaderAchievements';
import { uploadFiles } from '../../services/Users';
import ViewQuiz from '../../components/ViewQuiz';
import ImageAdjustment from '../../components/ImageAdjustment';
import CardQuizResume from '../ViewChallenge/CardQuizResume';
import { responsiveFont } from '../../utils/UtilsGlobal';
import QuestionsQuiz from './questionsQuiz';

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiFilledInput-root": {
      background: theme.colorsBase.gray7
    }
  }
}));

const EditAndViewChallenge: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { token, token2 } = useParams();
  const [loading, setLoading] = useState(false)
  const [registration, setRegistration] = useState<RegistrationIdentification>(new RegistrationIdentification());
  const [registrationError, setRegistrationError] = useState<RegistrationIdentificationError>(new RegistrationIdentificationError());
  const [loadingEdit, setLoadingEdit] = useState(false)
  const [openSaveChangeModal, setOpenSaveChangeModal] = useState(false)

  const [imageSet, setImageSet] = useState<File>();
  const [image, setImage] = useState<string>(offImage);
  const [imageError, setImageError] = useState<string>('');
  const [mediaSet, setMediaSet] = useState<File>();
  const [media, setMedia] = useState<string>(offImage);
  const [extensionMedia, setExtensionMedia] = useState<string>('');
  const [mediaError, setMediaError] = useState<string>('');

  const [modalContent, setModalContent] = useState(false);

  const [registrationQuiz, setRegistrationQuiz] = useState<RegistrationQuiz>(new RegistrationQuiz());
  const [registrationQuizError, setRegistrationQuizError] = useState<RegistrationQuizError>(new RegistrationQuizError());
  const [openQuizModal, setOpenQuizModal] = useState(false)
  const [status, setStatus] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false)
  const [audioSet, setAudioSet] = useState<File>();
  const [openConfirmClosingModal, setOpenConfirmClosingModal] = useState(false)
  const [numberOfAlternatives, setNumberOfAlternatives] = useState(3);
  const [openInactivationRequiredModal, setOpenInactivationRequiredModal] = useState(false)

  const [activeChallenge, setActiveChallenge] = useState(false);
  const [listQuestions, setListQuestions] = useState<IArrayQuestions[]>([])
  const [questionSelected, setQuestionSelected] = useState(-1);
  const [audio, setAudio] = useState<string>('');
  const isSm = useIsSm()
  const [openModalViewQuiz, setOpenModalViewQuiz] = useState(false)

  const [openModalImageAdjustment, setOpenModalImageAdjustment] = useState(false)
  const [openModalEditQuiz, setOpenModalEditQuiz] = useState(false)

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const dispararCliqueInput = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };


  const validationQuiz1 = (arraytotalResponse: string[]) => {
    try {
      if (registrationQuiz.question.length <= 0
        || registrationQuiz.response1.length <= 0
        || registrationQuiz.response2.length <= 0
        || registrationQuiz.response3.length <= 0
        || String(arraytotalResponse[Number(registrationQuiz.trueAnswer) - 1]).length <= 0
      ) {
        setRegistrationQuizError({
          questionType: '',
          question: registrationQuiz.question.length <= 0 ? 'Campo obrigatório' : '',
          response1: registrationQuiz.response1.length <= 0 ? 'Campo obrigatório' : '',
          response2: registrationQuiz.response2.length <= 0 ? 'Campo obrigatório' : '',
          response3: registrationQuiz.response3.length <= 0 ? 'Campo obrigatório' : '',
          response4: '',
          response5: '',
          trueAnswer: String(arraytotalResponse[Number(registrationQuiz.trueAnswer) - 1]).length <= 0 ? 'Resposta não preenchida' : ''
        });
        setLoadingRegister(false);
        return true;
      }
    } catch (ex) {
      console.log(ex)
    }
  };

  const validationQuiz2 = () => {
    try {
      if (registrationQuiz.question.length <= 0) {
        setRegistrationQuizError({
          questionType: '',
          question: registrationQuiz.question.length <= 0 ? 'Campo obrigatório' : '',
          response1: '',
          response2: '',
          response3: '',
          response4: '',
          response5: '',
          trueAnswer: '',
        });
        setLoadingRegister(false);
        return true;
      }
    } catch (ex) {
      console.log(ex)
    }
  };

  const validationQuiz = (arraytotalResponse: string[]) => {
    try {
      if (registrationQuiz.questionType === '1') {
        if (registrationQuiz.trueAnswer === '') {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Selecione a Resposta Correta')
          });
          setLoadingRegister(false);
          return true;
        }

        if (validationQuiz1(arraytotalResponse)) return true;
      } else if (validationQuiz2()) return true;
    } catch (ex) {
      console.log(ex)
    }
  };

  const handleRegistrationQuiz = async (finish?: boolean) => {
    setLoadingRegister(true);
    try {
      const arraytotalResponse = [registrationQuiz.response1, registrationQuiz.response2, registrationQuiz.response3, registrationQuiz.response4, registrationQuiz.response5];

      if (validationQuiz(arraytotalResponse)) return;

      let urlFile: string = ''

      if (audioSet) {
        const data = new FormData();
        data.append('file', audioSet as File);
        const responseAudio = await uploadFiles(data, 4);
        if (responseAudio.Success) {
          urlFile = responseAudio.Data.FileUrl
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Houve um erro ao salvar o audio, tente novamente.'),
            errorMessage: responseAudio.Message,
            errors: responseAudio.Errors
          });
          setLoadingRegister(false);
          return;
        }
      }

      let arrayResponse: IQuizAlternatives[] = [];

      if (registrationQuiz.questionType === '1') {
        arraytotalResponse.map((item, index) => {
          if (item) {
            arrayResponse.push({
              "Answer": String(item),
              "RightAnswer": registrationQuiz.trueAnswer === String(index + 1) ? true : false
            })
          }
        })
      } else {
        arrayResponse = [
          {
            "Answer": "verdadeiro",
            "RightAnswer": status
          },
          {
            "Answer": "falso",
            "RightAnswer": !status
          }
        ]
      }


      if (questionSelected >= 0) {
        const listQuestionsTemp: IArrayQuestions[] = listQuestions;

        listQuestionsTemp.splice(questionSelected, 0, {
          QuestionType: registrationQuiz.questionType,
          Question: registrationQuiz.question,
          AudioUrl: urlFile ?? audio,
          Alternatives: arrayResponse
        })

        listQuestionsTemp.splice(questionSelected + 1, 1)
        setListQuestions(listQuestionsTemp)
      } else {
        const listQuestionsTemp: IArrayQuestions[] = listQuestions;

        listQuestionsTemp.push({
          QuestionType: registrationQuiz.questionType,
          Question: registrationQuiz.question,
          AudioUrl: urlFile ?? audio,
          Alternatives: arrayResponse
        })

        setListQuestions(listQuestionsTemp)
      }

      setAudio("")
      setAudioSet(undefined)
      setRegistrationQuiz({
        questionType: '1',
        question: '',
        response1: '',
        response2: '',
        response3: '',
        response4: '',
        response5: '',
        trueAnswer: ''
      })
      setNumberOfAlternatives(3)
      setQuestionSelected(-1)
      if (finish) {
        setOpenQuizModal(false)
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao cadastrar o quiz, tente novamente.')
      });
    }
    setLoadingRegister(false);
  };

  const searchChallengeQuiz = async () => {
    setLoading(true)
    try {
      const response = await getSearchChallengeQuiz(token ?? '');
      if (response.Success) {
        setListQuestions(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  useEffect(() => {
    retrieveChallenge();
    searchChallengeQuiz();
  }, []);

  const handleChangeMedia = (e: ChangeEvent<HTMLInputElement>) => {
    setMediaError('')
    const selectedMedia = e.target.files?.[0];
    if (selectedMedia) {
      setMediaSet(selectedMedia)
      setMedia(URL.createObjectURL(selectedMedia))
      setExtensionMedia(selectedMedia.type)
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setImageError('')
    setOpenModalImageAdjustment(true)
    const selectedImage = e.target.files?.[0];
    if (selectedImage) {
      setImageSet(selectedImage)
      setImage(URL.createObjectURL(selectedImage));
    }
  };

  const handleChangeModal = (e: File) => {
    setImageError('')
    setOpenModalImageAdjustment(false)
    if (e) {
      setImageSet(e)
      setImage(URL.createObjectURL(e));
    }
  };

  const handleChangeModalClose = () => {
    setImageError('')
    setOpenModalImageAdjustment(false)
  };

  const retrieveChallenge = async () => {
    if (token2 === "1") {
      challengeDetailsActive();
    } else {
      challengeDetailsRejected();
    }
  };

  const challengeDetailsActive = async () => {
    setLoading(true)
    try {
      const response = await getChallengeDetailsActive(token ?? '');
      if (response.Success) {
        setRegistration({
          name: response.Data.Title,
          language: response.Data.Language,
          difficultyLevel: response.Data.DifficultyLevel,
          category: response.Data.Category ?? '',
          tags: response.Data.Tags ?? [],
          mediaType: String(response.Data.ContentType),
          duration: String(response.Data.EstimatedTime),
          source: response.Data.ContentSourceUrl ?? '',
          sourceNotes: response.Data.ContentSourceDetails ?? ''
        })
        setImage(response.Data.CoverImageUrl);
        setMedia(response.Data.ContentFileUrl ?? '');
        setExtensionMedia(response.Data.ContentFileExtension ?? "")
        setActiveChallenge(true)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  const challengeDetailsRejected = async () => {
    setLoading(true)
    try {
      const response = await getChallengeDetailsRejected(token ?? '');
      if (response.Success) {
        setRegistration({
          name: response.Data.Title,
          language: response.Data.Language,
          difficultyLevel: response.Data.DifficultyLevel ?? '',
          category: response.Data.Category ?? '',
          tags: response.Data.Tags ?? [],
          mediaType: String(response.Data.ContentType),
          duration: String(response.Data.EstimatedTime),
          source: response.Data.ContentSourceUrl ?? '',
          sourceNotes: response.Data.ContentSourceDetails ?? ''
        })
        setImage(response.Data.CoverImageUrl);
        setMedia(response.Data.ContentFileUrl ?? '');
        setExtensionMedia(response.Data.ContentFileExtension ?? "")
        setActiveChallenge(false)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  const handleEdit = async () => {
    setLoadingEdit(true);
    try {
      let countError = 0;

      const validation = await registerChallengeValidation(registration);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError(validation as RegistrationIdentificationError);
        setLoadingEdit(false);
        return;
      }

      let imageSelected = ''
      if (imageSet) {
        const data = new FormData();
        data.append('file', imageSet as File);
        const responseImage = await uploadFiles(data, 2);
        if (responseImage.Success) {
          imageSelected = responseImage.Data.FileUrl;
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Houve um erro ao salvar a imagem, tente novamente.'),
            errorMessage: responseImage.Message,
            errors: responseImage.Errors
          });
          setLoadingRegister(false);
          return;
        }
      }
      let mediaSelected = ''
      if (mediaSet) {
        const dataMedia = new FormData();
        dataMedia.append('file', mediaSet as File);
        const responseMidia = await uploadFiles(dataMedia, Number(registration.mediaType));
        if (responseMidia.Success) {
          mediaSelected = responseMidia.Data.FileUrl;
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Houve um erro ao salvar a mídia, tente novamente.'),
            errorMessage: responseMidia.Message,
            errors: responseMidia.Errors
          });
          setLoadingRegister(false);
          return;
        }
      }

      const arrayResponse: IArrayQuestions[] = [];
      listQuestions.map((item) => {
        arrayResponse.push({
          QuestionType: item.QuestionType,
          Question: item.Question,
          AudioUrl: item.AudioUrl,
          QuizAlternatives: item.Alternatives
        })
      })

      const bodyData = {
        Title: registration.name,
        Language: registration.language,
        ContentSourceUrl: registration.source,
        ContentSourceDetails: registration.sourceNotes,
        CoverImageUrl: imageSelected || image,
        ContentFileUrl: mediaSelected || media,
        ContentType: registration.mediaType,
        Questions: arrayResponse.length > 0 ? arrayResponse : [],
        Tags: registration.tags || []
      };

      const response = await putChallenge(bodyData, token);
      if (response.Success) {
        navigate('/desafios')
        setOpenSaveChangeModal(false)
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Desafio editado com sucesso.')
        });
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao editar o desafio, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao editar o desafio, tente novamente.')
      });
    }
    setLoadingEdit(false);
  };

  const renderExtension = (type: number) => ({
    1: "video/*",
    2: "image/*",
    4: "audio/*",
  }[type]);

  const deleteQuestionEdit = async (question: number) => {
    if (question >= 0) {
      const newArray = [...listQuestions];
      newArray.splice(question, 1);
      setListQuestions(newArray)
    } else {
      setListQuestions([])
    }
  };

  const contentViewTotalQuiz = () => {
    return (
      <S.ContainerViewTotalQuiz>
        {isSm && <S.LineTitleModal><h5>{t('Editar Perguntas')}</h5></S.LineTitleModal>}

        <h2 className='titleContainerViewTotalQuiz'>{t('Perguntas')}</h2>

        <div className='containerQuiz'>
          <div className='listQuiz'>
            {listQuestions.length > 0 ? (
              <>
                {listQuestions.map((item, index) => (
                  <CardQuizResume key={item.Id} item={item} index={index} onClickDelete={() => deleteQuestionEdit(index)} edit={true} />
                ))}
              </>
            ) : <h2 className='textNotFountQuiz'>{t('Você optou por não adicionar quiz, a equipe da curar saúde poderá adicionar por você. Caso deseje adicionar, clique em "Criar Quiz".')}</h2>}
          </div>

          <ButtonMaterial
            style={{ border: `dashed 1px ${theme.colors.seventh}`, color: theme.colors.seventh, marginTop: '24px' }}
            variant="outlined"
            icon={<Edit style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.seventh }} />}
            label={listQuestions.length > 0 ? t('Editar Perguntas') : t('Criar Perguntas')}
            onClick={() => setOpenQuizModal(true)}
          />
        </div>
      </S.ContainerViewTotalQuiz>
    )
  };

  const cancelModalViewTotalQuiz = async () => {
    setOpenModalEditQuiz(false)
    searchChallengeQuiz();
  };

  const contentSelectRightC = (bool: boolean) => {
    return (
      <InputAdornment
        position="end"
      >
        <FormControlLabel style={{ width: '100%', display: 'flex', margin: '0px' }}
          checked={status === bool}
          onClick={() => setStatus(bool)}
          control={<Radio />}
          label={<h1 style={{ textAlign: 'center', fontSize: responsiveFont(10), fontWeight: '500' }}>Alternativa <br /> correta</h1>}
        />
      </InputAdornment>
    )
  };

  const contentSelectRight = (numb: number) => {
    return (
      <InputAdornment
        position="end"
      >
        <FormControlLabel style={{ width: '100%', display: 'flex', margin: '0px' }}
          checked={registrationQuiz.trueAnswer === String(numb)}
          onClick={() => setRegistrationQuiz({ ...registrationQuiz, trueAnswer: String(numb) })}
          control={<Radio />}
          label={<h1 style={{ textAlign: 'center', fontSize: responsiveFont(10), fontWeight: '500' }}>Alternativa <br /> correta</h1>}
        />
      </InputAdornment>
    )
  };

  return (
    <S.Container>
      <HeaderAchievements
        title={t('Editar Desafio')}
        icon={isSm ? <Rule className="i" style={{ color: theme.colors.secundary }} /> : null}
        buttonBack={!isSm}
        marginBottom={isSm ? 24 : 0}
      />

      <S.ContainerContent>
        <S.ContainerTable>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <ViewLoading />
            </div>
          ) : (
            <>
              {(openModalEditQuiz && isSm) ? (
                <S.ContainerTotal>
                  {contentViewTotalQuiz()}
                </S.ContainerTotal>
              ) : (
                <S.ContainerTotal>
                  <S.BoxTotal active>
                    <S.CoverStep>
                      <h5 className='title'>{t('Imagem de Capa')}</h5>

                      <S.BackgroundImageProfile htmlFor="selecao-arquivo">
                        <img style={{ aspectRatio: '16/9' }} src={image} alt='' />

                        <div className='iconBackground'>
                          <UploadFile className="iconCam" />
                        </div>

                        <S.Input id="selecao-arquivo" onChange={handleChange} type="file" accept="image/*" ref={inputFileRef} />
                      </S.BackgroundImageProfile>
                      <h4 style={{ color: 'red', marginBottom: '8px' }}>{imageError}</h4>
                    </S.CoverStep>

                    <S.MediaStage>
                      <S.InputSelect>
                        <div className="input">
                          <FormControl sx={{ m: 1, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">{t('Tipo de Conteúdo')}</InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              variant="standard"
                              style={{ display: 'flex', width: '100%' }}
                              value={registration.mediaType}
                              error={registrationError.mediaType ? true : false}
                              onClick={() => setRegistrationError({ ...registrationError, mediaType: '' })}
                              onChange={(e) => {
                                setRegistration({ ...registration, mediaType: String(e.target.value) })
                                setExtensionMedia('')
                                setMediaSet(undefined)
                                setMedia(offImage)
                              }}
                            >
                              <MenuItem value={'1'}>{t('Vídeo')}</MenuItem>
                              <MenuItem value={'2'}>{t('Imagem')}</MenuItem>
                              <MenuItem value={'4'}>{t('Audio')}</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </S.InputSelect>

                      <S.BoxMedia htmlFor="selecao-media">
                        {mediaSet?.name ? (
                          <div className='iconBackground'>
                            <Done className="iconCam" color="info" />
                            <h5 className='textAdd'>{mediaSet?.name}</h5>
                          </div>
                        ) : (
                          <div className='iconBackground'>
                            <ModeEdit className="iconCam" />
                            <h5 className='textAdd'>{t('Editar Conteúdo')}</h5>
                          </div>
                        )}

                        <S.InputMedia id="selecao-media" onChange={handleChangeMedia} type="file" accept={renderExtension(Number(registration.mediaType))} />
                      </S.BoxMedia>
                      {mediaError && <h4 style={{ color: 'red', marginBottom: '8px' }}>{mediaError}</h4>}

                      {media &&
                        <ButtonMaterial
                          style={{ borderColor: theme.colors.secundary }}
                          variant="outlined"
                          label={t('ver conteúdo')}
                          icon={<Search style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.secundary }} />}
                          onClick={() => setModalContent(true)}
                        />
                      }
                    </S.MediaStage>

                    {isSm &&
                      <S.ContainerButtonQuiz>
                        <div className='boxQuizButtons'>
                          <ButtonMaterial
                            style={{ borderColor: theme.colors.secundary, marginTop: '8px' }}
                            variant="outlined"
                            label={t('ver perguntas')}
                            icon={<Search style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.secundary }} />}
                            onClick={() => setOpenModalViewQuiz(true)}
                          />

                          <ButtonMaterial
                            style={{ border: `dashed 1px ${theme.colors.secundary}`, color: theme.colorsBase.black, width: '100%' }}
                            variant="outlined"
                            icon={<Edit style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.black }} />}
                            label={t('Editar Perguntas')}
                            onClick={() => setOpenModalEditQuiz(true)}
                          />
                        </div>
                      </S.ContainerButtonQuiz>
                    }
                  </S.BoxTotal>

                  <S.BoxTotal >
                    <S.ContainerColumnRow>
                      <div style={{ marginRight: isSm ? '0px' : '12px', width: '100%' }}>
                        <Input
                          label={t('Título')}
                          type="name"
                          value={registration.name}
                          onChange={(e) => setRegistration({ ...registration, name: e.target.value })}
                          size="small"
                          fullWidth
                          error={registrationError.name ? true : false}
                          helperText={registrationError.name}
                          onClick={() => setRegistrationError({ ...registrationError, name: '' })}
                          variant="filled"
                          style={{ width: '100%' }}
                        />

                        <S.InputSelect>
                          <div className="input">
                            <FormControl sx={{ m: 1, width: '100%', marginTop: '24px' }}>
                              <InputLabel id="demo-simple-select-helper-label">{t('Idioma')}</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                variant="standard"
                                style={{ display: 'flex', width: '100%' }}
                                value={registration.language}
                                error={registrationError.language ? true : false}
                                onClick={() => setRegistrationError({ ...registrationError, language: '' })}
                                onChange={(e) => {
                                  setRegistration({ ...registration, language: String(e.target.value) })
                                }}
                              >
                                <MenuItem value={'1'}>{t('Português')}</MenuItem>
                                <MenuItem value={'2'}>{t('Inglês')}</MenuItem>
                                <MenuItem value={'3'}>{t('Espanhol')}</MenuItem>
                                <MenuItem value={'4'}>{t('Francês')}</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </S.InputSelect>

                        {(!isSm && activeChallenge) && <>
                          <TextField
                            type='text'
                            label={t('Categoria')}
                            size="small"
                            disabled={true}
                            value={registration.category}
                            error={registrationError.category.length > 0}
                            sx={{
                              marginTop: 2,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                >
                                  <Lock fill={theme.colors.black} />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            variant="filled"
                            style={{ marginTop: '8px', marginBottom: '12px' }}
                            className={classes.root}
                          />

                          <TextField
                            type='text'
                            label={t('Tempo de Duração')}
                            size="small"
                            disabled={true}
                            value={registration.duration}
                            error={registrationError.duration.length > 0}
                            sx={{
                              marginTop: 2,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                >
                                  <Lock fill={theme.colors.black} />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            variant="filled"
                            style={{ marginTop: '8px', marginBottom: '12px' }}
                            className={classes.root}
                          />
                        </>}
                      </div>

                      <div style={{ marginLeft: isSm ? '0px' : '12px', width: '100%' }}>
                        <Input
                          label={t('Fonte')}
                          type="source"
                          value={registration.source}
                          onChange={(e) => setRegistration({ ...registration, source: e.target.value })}
                          size="small"
                          fullWidth
                          error={registrationError.source.length > 0}
                          helperText={registrationError.source}
                          onClick={() => setRegistrationError({ ...registrationError, source: '' })}
                          variant="filled"
                          style={{ width: '100%', marginBottom: '24px' }}
                        />

                        <Input
                          label={t('Observações da Fonte')}
                          type="sourceNotes"
                          value={registration.sourceNotes}
                          onChange={(e) => setRegistration({ ...registration, sourceNotes: e.target.value })}
                          size="small"
                          fullWidth
                          error={registrationError.sourceNotes ? true : false}
                          helperText={registrationError.sourceNotes}
                          onClick={() => setRegistrationError({ ...registrationError, sourceNotes: '' })}
                          variant="filled"
                          style={{ width: '100%', marginBottom: '8px' }}
                        />

                        {activeChallenge && <>
                          <TextField
                            type='text'
                            label={t('Tags')}
                            size="small"
                            disabled={true}
                            value={registration.tags}
                            sx={{
                              marginTop: 2,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                >
                                  <Lock fill={theme.colors.black} />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            variant="filled"
                            style={{ marginTop: '8px', marginBottom: '12px' }}
                            className={classes.root}
                          />

                          <TextField
                            type='text'
                            label={t('Dificuldade')}
                            size="small"
                            disabled={true}
                            value={registration.difficultyLevel}
                            error={registrationError.difficultyLevel.length > 0}
                            sx={{
                              marginTop: 2,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                >
                                  <Lock fill={theme.colors.black} />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            variant="filled"
                            style={{ marginTop: '8px', marginBottom: '12px' }}
                            className={classes.root}
                          />
                        </>}

                        {(isSm && activeChallenge) && <>
                          <TextField
                            type='text'
                            label={t('Categoria')}
                            size="small"
                            disabled={true}
                            value={registration.category}
                            error={registrationError.category.length > 0}
                            sx={{
                              marginTop: 2,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                >
                                  <Lock fill={theme.colors.black} />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            variant="filled"
                            style={{ marginTop: '8px', marginBottom: '12px' }}
                            className={classes.root}
                          />

                          <TextField
                            type='text'
                            label={t('Tempo de Duração')}
                            size="small"
                            disabled={true}
                            value={registration.duration}
                            error={registrationError.duration.length > 0}
                            sx={{
                              marginTop: 2,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                >
                                  <Lock fill={theme.colors.black} />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            variant="filled"
                            style={{ marginTop: '8px', marginBottom: '12px' }}
                            className={classes.root}
                          />
                        </>}
                      </div>
                    </S.ContainerColumnRow>

                    {!isSm &&
                      <S.ContainerButtonQuiz>
                        <h3>{t('Perguntas')}</h3>

                        <div className='boxQuizButtons'>
                          <ButtonMaterial
                            style={{ borderColor: theme.colors.secundary, marginRight: '12px' }}
                            variant="outlined"
                            label={t('ver quiz')}
                            icon={<Search style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.secundary }} />}
                            onClick={() => setOpenModalViewQuiz(true)}
                          />

                          <ButtonMaterial
                            style={{ border: `dashed 1px ${theme.colors.secundary}`, color: theme.colorsBase.black }}
                            variant="outlined"
                            icon={<Edit style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.black }} />}
                            label={t('Editar Perguntas')}
                            onClick={() => setOpenModalEditQuiz(true)}
                          />
                        </div>
                      </S.ContainerButtonQuiz>
                    }
                  </S.BoxTotal>
                </S.ContainerTotal>
              )}

              <S.BottomContainer>
                <div className='divBottom'>
                  <ButtonMaterial
                    style={{
                      border: `solid 1px ${theme.colors.secundary}`,
                      marginRight: '24px',
                      width: isSm ? '100%' : 'none',
                      marginTop: isSm ? '16px' : '0px'
                    }}
                    variant="outlined"
                    color='primary'
                    label={t('CANCELAR')}
                    onClick={() => { (openModalEditQuiz && isSm) ? cancelModalViewTotalQuiz() : setOpenConfirmClosingModal(true) }}
                  />

                  <ButtonMaterial
                    style={{
                      border: `solid 1px ${theme.colors.seventh}`,
                      width: isSm ? '100%' : 'none',
                    }}
                    variant="contained"
                    color='info'
                    label={(openModalEditQuiz && isSm) ? t('SALVAR') : t('ENVIAR PARA REVISÃO')}
                    onClick={() => { (openModalEditQuiz && isSm) ? setOpenModalEditQuiz(false) : setOpenSaveChangeModal(true) }}
                    loading={loadingEdit}
                  />
                </div>
              </S.BottomContainer>
            </>
          )}
        </S.ContainerTable>
      </S.ContainerContent>

      <ModalChallengeContent
        setModalContent={() => setModalContent(false)}
        modalContent={modalContent}
        media={media}
        mediaType={Number(registration.mediaType)}
        extension={extensionMedia}
      />

      <ModalCustom
        open={openConfirmClosingModal}
        onClose={() => setOpenConfirmClosingModal(false)}
        onClick={() => navigate('/desafios')}
        loading={loading}
        title={t('Cancelar Edição')}
        description={t('Deseja cancelar a edição do desafio? Todas as alterações não serão salvas.')}
        confirmationButtonText={t('CONFIRMAR')}
        textDeclineButton={t('CANCELAR')}
      />

      <ModalChallengeContent
        setModalContent={() => setModalContent(false)}
        modalContent={modalContent}
        media={media}
        mediaType={Number(registration.mediaType)}
        extension={extensionMedia}
      />

      <ModalCustom
        open={openSaveChangeModal}
        onClose={() => setOpenSaveChangeModal(false)}
        onClick={handleEdit}
        loading={loading}
        title={t('Salvar Alterações')}
        description={t('Você deseja salvar as edições feitas no desafio? A nova versão estará disponível na plataforma somente após aceita pela equipe de revisão.')}
        confirmationButtonText={t('CONFIRMAR')}
        textDeclineButton={t('CANCELAR')}
      />

      <ModalRegistrationAndQuizEditing
        open={openQuizModal}
        onClose={() => {
          setQuestionSelected(-1)
          setRegistrationQuiz({
            questionType: '1',
            question: '',
            response1: '',
            response2: '',
            response3: '',
            response4: '',
            response5: '',
            trueAnswer: ''
          })
          setOpenQuizModal(false)
          setNumberOfAlternatives(3)
          setAudioSet(undefined)
          setAudio("")
        }}
        onClick={() => handleRegistrationQuiz()}
        onCloseBottom={() => handleRegistrationQuiz(true)}
        childrenContent={
          <>
            <S.ContainerQuestionCount>
              {!loading && listQuestions?.map((item, index) => (
                <S.ButtonQuestion active={questionSelected === index} key={item.Id} onClick={() => {
                  setQuestionSelected(index)
                  const findA = listQuestions[index]?.Alternatives ?? [];

                  let findRightAnswer = 0;
                  listQuestions[index]?.Alternatives?.map((item, index) => {
                    if (item.RightAnswer === true) {
                      findRightAnswer = index + 1;
                    }
                  })

                  setRegistrationQuiz({
                    questionType: String(item.QuestionType),
                    question: item.Question,
                    response1: findA[0]?.Answer || "",
                    response2: findA[1]?.Answer || "",
                    response3: findA[2]?.Answer || "",
                    response4: findA[3]?.Answer || "",
                    response5: findA[4]?.Answer || "",
                    trueAnswer: String(findRightAnswer)
                  })
                  setNumberOfAlternatives(findA.length || 3)
                  setAudio(item.AudioUrl)
                }}>
                  <h5 className='textbutton'>{item && index + 1}</h5>
                </S.ButtonQuestion>
              ))}
              <Tooltip title={t('Adicionar Pergunta')}>
                <S.ButtonQuestion active={true} onClick={() => {
                  setQuestionSelected(-1)
                  setRegistrationQuiz({
                    questionType: '1',
                    question: '',
                    response1: '',
                    response2: '',
                    response3: '',
                    response4: '',
                    response5: '',
                    trueAnswer: ''
                  })
                  setNumberOfAlternatives(3)
                  setAudioSet(undefined)
                  setAudio("")
                }}>
                  <Add className="i" />
                </S.ButtonQuestion>
              </Tooltip>
            </S.ContainerQuestionCount>

            <QuestionsQuiz
              registrationQuiz={registrationQuiz}
              setRegistrationQuiz={setRegistrationQuiz}
              setRegistrationQuizError={setRegistrationQuizError}
              registrationQuizError={registrationQuizError}
              numberOfAlternatives={numberOfAlternatives}
              setNumberOfAlternatives={setNumberOfAlternatives}
              contentSelectRightC={contentSelectRightC}
              contentSelectRight={contentSelectRight}
              buttonAudio={
               <></>
              }
            />
          </>
        }
        loading={loadingRegister}
        title={t('Perguntas')}
        confirmationButtonText={t('SALVAR')}
        textDeclineButton={t('CANCELAR')}
      />

      <ModalCustom
        open={openModalViewQuiz}
        onClose={() => setOpenModalViewQuiz(false)}
        onClick={() => { }}
        buttonsEnabled={false}
        style={{ padding: isSm ? '8px' : '24px' }}
        styleLine={{ backgroundColor: 'transparent' }}
        childrenContent={
          <ViewQuiz questionContent={listQuestions} />
        }
        title={t('Perguntas')}
        confirmationButtonText={t('Pesquisar')}
        textDeclineButton={t('CANCELAR')}
      />

      <ModalCustom
        open={openInactivationRequiredModal}
        onClose={() => setOpenInactivationRequiredModal(false)}
        onClick={() => setOpenInactivationRequiredModal(false)}
        title={t('Editar desafio')}
        description={t('Para editar este desafio, é preciso que ele esteja desativado para garantir a integridade do desafio a todos os participantes. Desative Para Prosseguir!')}
        confirmationButtonText={t('CONFIRMAR')}
        textDeclineButton={t('CANCELAR')}
        buttonsEnabled={false}
        childrenContent={
          <ButtonMaterial
            style={{ borderColor: theme.colors.secundary }}
            variant="outlined"
            label={t('VOLTAR')}
            onClick={() => setOpenInactivationRequiredModal(false)}
          />
        }
      />

      <ModalCustom
        open={openModalImageAdjustment}
        onClose={handleChangeModalClose}
        onClick={() => { }}
        childrenContent={<>{imageSet && <ImageAdjustment onClickAdd={dispararCliqueInput} onClickSave={handleChangeModal} imageSet={imageSet} />}</>}
        title=''
        buttonsEnabled={false}
        styleLine={{ backgroundColor: 'transparent', marginBottom: '24px' }}
        style={{ backgroundColor: theme.colorsBase.white2 }}
      />

      {!isSm &&
        <ModalCustom
          open={openModalEditQuiz}
          onClose={cancelModalViewTotalQuiz}
          onClick={() => setOpenModalEditQuiz(false)}
          childrenContent={contentViewTotalQuiz()}
          title={t('Editar Perguntas')}
          buttonsEnabled
          style={{ width: '80%', }}
          textDeclineButton={t('CANCELAR')}
          confirmationButtonText={t('SALVAR')}
        />
      }
    </S.Container>
  )
}

export default EditAndViewChallenge
