import { useQuery } from "@tanstack/react-query";

import { sendRequest } from "../../RequestService";
import { toRecordString } from "../../../utils";
import { ErrCodes } from "../../types";
import { IChallengeDashboardQuery, IChallengeDashboardQueryResponse } from "./types";

const defaultRoot = "challenge-dashboards"

export const useChallengeDashboard = (params: IChallengeDashboardQuery) => useQuery({
  queryKey: ["useChallengeDashboard", params],
  queryFn: async () => {
    const query = new URLSearchParams(toRecordString(params)).toString()

    const url = `${defaultRoot}?${query}`

    const response = await sendRequest<IChallengeDashboardQueryResponse>(url, 'GET')
    if (!response.Success) throw new Error(response.ErrorDetails)

    return response
  },
  meta: { errCode: ErrCodes.CHALLENGE_DESHBOARD_FETCH_FAILED }
})
