import { useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { sendRequest } from "../../../services/RequestService";

import { IChalengeDetailsQuery, IChalengeDetailsQueryResponse } from "./types";

const defaultRoot = "challenges-management"

export const useInvalidateChalengeDetails = () => {
  const queryClient = useQueryClient()

  return useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ["useChalengeDetails"],
      exact: false
    });
  }, [queryClient])
}

export const useChalengeDetails = (params: IChalengeDetailsQuery) => useQuery({
  queryKey: ["useChalengeDetails", params],
  queryFn: async () => {
    const url = `${defaultRoot}/${params.Id}/details`

    const response = await sendRequest<IChalengeDetailsQueryResponse>(url, 'GET')

    if (!response.Success) {
      throw new Error(response.Message)
    }

    return response
  }
})
