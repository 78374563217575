import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: ${(props) => props.theme.colorsBase.white};
`

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  border: none;
  border-radius: 8px;
  padding: 16px 16.5px;
  margin-top: 8px;
  margin-bottom: 24px;

  .containerRecoverPassword {
    width: 70%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    h3 {
      line-height: 24px;
      text-align: center;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }
   
    h1 {
      margin-bottom: 40px;
      line-height: 24px;
      text-align: center;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  }

  @media (max-width: 680px) {
    width: 100%;
    border: none;
    border-radius: 0px;
    margin-top: 0px; 
    padding: 0px 16.5px 16px 16.5px;

    .containerRecoverPassword {
      width: 100%;

      .belowTheInput {
        margin-bottom: 92px;
      }
    }
  }

  @media (min-width: 681px) {
    width: 80%;
  }

  @media (min-width: 900px) {
    width: 60%;
  }

  @media (min-width: 1200px) {
    width: 50%;
  }
`

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // border: solid 0.5px ${(props) => props.theme.colors.ten};
  height: 0.5px;
  background-color: ${(props) => props.theme.colors.ten};
  margin: 24px 0;

  h5 {
    line-height: 16px;
    letter-spacing: 0.1px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    background-color: ${(props) => props.theme.colorsBase.white};
    padding: 8px;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(14)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }
`

export const SocialLoginButtonFacebook = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 0.5px ${(props) => props.theme.colors.eleven};
  background-color: #0D47A1;
  border-radius: 4px;
  height: 40px;
  margin-right: 8px;
  padding-left: 8px;
  padding-right: 8px;

  @media (max-width: 680px) {
    margin-bottom: 16px;
    margin-right: 0px;
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }

  img {
    width: 20px;
    object-fit: cover;
    margin-right: 8px;
  }

  h5 {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.twelve};
    font-weight: 500;
    font-family: 'Barlow', Regular;
    opacity: 0.8;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(12)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(12)};
    }
  }
`

export const SocialLoginButtonGoogle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: solid 0.5px ${(props) => props.theme.colors.eleven};
  border-radius: 4px;
  height: 40px;
  margin-left: 8px;
  
  &:hover {
    opacity: 0.8;
  }

  img {
    width: 20px;
    object-fit: cover;
    margin-right: 8px;
  }

  h5 {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-family: 'Barlow', Regular;
    opacity: 0.8;

    @media (max-width: 680px) {
      font-size: ${responsiveFont(12)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(12)};
    }
  }
`

export const ContainerImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: none;
  position:relative;

  .imageLeft {
    width: 23%;
    object-fit: cover;
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
`