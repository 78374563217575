import styled from 'styled-components'
import { responsiveFont } from '../../../utils/UtilsGlobal'

export const Table = styled.div`
  width: 100%;
  min-height: 380px;
  overflow: auto;

  &::-webkit-scrollbar {
    border-radius: 8px;
    width: 4px;
    background: ${(props) => props.theme.colors.sixteen};
    }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.sixteen};
    border-radius: 8px;
  }
  ::-webkit-scrollbar:horizontal { 
    height: 4px;
  }

  .i {
    font-size: 22px;
  }

  table {
    width: 100%;
    border: none;
    border-collapse: collapse;

    tr:nth-child(2n) {
      background: ${(props) => props.theme.colors.seventeen};
    }
    tr {
      height: 40px;
      width: 150px;
    }
    td {
     padding-right: 16px;
     max-width: 300px;
     white-space: nowrap;
     text-overflow: ellipsis;
     overflow: hidden;
     font-size: ${responsiveFont(14)};
     color: ${(props) => props.theme.colorsBase.black3};
    }
    .header {
      height: 40px;
      margin-bottom: 40px;
      text-align: left;
      border-bottom: solid 2px ${(props) => props.theme.colors.secundary};
    }

    .body-item{
      height: 60px;
    }

    .itemBlocked {
      color: red;
    }

    .iconBlue {
      color: ${(props) => props.theme.colors.seventh};
      font-size: 24px;
    }
  }

  @media (max-width: 680px) {
    width: none;
    margin: 0px;
    padding: 0px 8px;

    table {
      td {
        max-width: 100px;
        font-size: ${responsiveFont(12)};
       }

     
    }
  }

`

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 80%;

  button {
    border: none;
    background: none;

    width: auto;
    height: auto;
  }

  .i {
    color: ${(props) => props.theme.colors.friday};
    font-size: 24px;

    &:hover {
     opacity: 0.8;
    }

  }

  .iconBlocked {
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
  }

  .iconDisable {
    opacity: 0.5;
    color: rgba(0, 0, 0, 0.7);
    font-size: 24px;
  }

  .iconDisableBlocked {
    opacity: 0.5;
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
  }

  @media (max-width: 680px) {
    .i {
      color: ${(props) => props.theme.colors.fourth};
    }
  }
`

export const ContainerEditCategories = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .InputContainer {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h2 {
      margin-left: 12px;
      line-height: 24px;
      letter-spacing: 0.15px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.black};
      opacity: 0.6;
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(14)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    h1 {
      margin-left: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(10)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  }

  .switchDivContainer {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h3 {
      margin-left: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(10)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

    .switchDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      h5 {
        line-height: 20px;
        letter-spacing: 0.15px;
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colors.black};
        opacity: 0.6;
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }
    }
  }

  .switchDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    h5 {
      line-height: 20px;
      letter-spacing: 0.15px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.black};
      opacity: 0.6;
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }
  }
`

export const ContainerPostUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 16px;
`

export const LineHeader = styled.div<{ register?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  border-bottom: solid 0.5px ${(props) => props.theme.colors.ten};
  margin-bottom: 24px;

  @media (max-width: 680px) {
    width: 100%;
    margin-bottom: ${(props) => (props.register ? '8px' : ' 48px')};
  }
`

export const ButtonHeader = styled.button<{ active?: boolean }>`
  width: 40%;
  border: none;
  border-bottom: solid 2px ${(props) => (props.active ? props.theme.colors.seventh : 'transparent')};
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }
  
    h5 {
      text-align: center;
      font-size: ${responsiveFont(12)};
      color: ${(props) => (props.active ? props.theme.colors.seventh : props.theme.colors.friday)};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      padding: 16px 8px 16px 8px;
  
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(12)};
      }
    }

  @media (max-width: 680px) {
    width: 50%;
  }
`

export const ContainerModalUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 250px;
`

export const BoxSelectPermission = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  h5 {
    line-height: 20px;
    letter-spacing: 0.15px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black};
    opacity: 0.6;
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(12)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }
`

export const ContainerSelectPermission = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const BoxChip = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`;

export const ContainerChip = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin-top: 16px;
`;

export const ItemModal = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 24px;
  padding: 0px 16px;
  border: none;
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }
  
  .icon {
    color: ${(props) => props.theme.colors.friday};
    width: 24px;
    height: 24px;
  }

  h5 {
    margin-left: 8px;
    line-height: 18px;
    letter-spacing: 0.25px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.friday};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }
`

export const ContainerDetailsUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .boxInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0px 4px 8px 4px;

    h1 {
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.fourth};
      opacity: 0.6;
      font-weight: 400;
      font-family: 'Barlow', Regular;
    }

    h2 {
      margin-top: 4px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
    }
  }
  
  .boxButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 40px;
    width: 100%;

    .twoButtons{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-top: 16px;
    }
  }
 
`

export const ContainerDeactivateUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .boxDeactivateUser {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 24px;

    h5 {
      line-height: 20px;
      text-align: center;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
  
    b {
      line-height: 20px;
      text-align: center;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 700;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
  }
`

export const ContainerEditUser = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 24px;

  .boxLeftB {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .switchDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      h5 {
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colorsBase.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }
    
    .boxInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0px 4px 8px 12px;
  
      h1 {
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colors.fourth};
        opacity: 0.6;
        font-weight: 400;
        font-family: 'Barlow', Regular;
      }
  
      h2 {
        margin-top: 4px;
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colorsBase.black3};
        font-weight: 400;
        font-family: 'Barlow', Regular;
      }
    }
  }

  .boxRightB {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 80%;
    margin-left: auto;
  }

  @media (max-width: 680px) {
    flex-direction: column;

    .boxRightB {
      width: 100%;
      margin-left: 0px;
      margin-top: 12px;
    }
  }
`

export const ContainerProfile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items:flex-start;
  width: 100%;

  .box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  @media (max-width: 680px) {
    flex-direction: column;
  }
`

export const TextCount = styled.div`
display: flex;
align-items: left;
justify-content: flex-start;
width: 100%;
height: 0.5px;
margin-top: 16px;
margin-left: 16px;

h5 {
  display: flex;
  align-self: flex-start;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
  font-size: ${responsiveFont(12)};
  color: ${(props) => props.theme.colors.black};
  font-weight: 400;
  font-family: 'Barlow', Medium;

  @media (max-width: 680px) {
    font-size: ${responsiveFont(10)};
  }

  @media (min-width: 1600px) {
    font-size: ${responsiveFont(14)};
  }
}
`

export const HeaderInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  flex-direction: row;
  margin-bottom: 8px;

  .iconProfile {
    color: ${(props) => props.theme.colors.seventh};
    width: 24px;
    height: 24px;
    align-self: center;
  }

  h3 {
    margin-left: 8px;
    line-height: 18px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
   
  }
`


export const ContainerEditRole = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

    .boxInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0px 4px 8px 12px;
  
      h1 {
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colors.fourth};
        opacity: 0.6;
        font-weight: 400;
        font-family: 'Barlow', Regular;
      }
  
      h2 {
        margin-top: 4px;
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colorsBase.black3};
        font-weight: 400;
        font-family: 'Barlow', Regular;
      }
    }
  
    .boxLineSeparator {
      display: flex;
      height: 0.5px;
      background-color: ${(props) => props.theme.colorsBase.gray8};
      margin: 16px 0px;
      width: 100%;
    }
    
    .boxMessage {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      background-color: ${(props) => props.theme.colorsBase.gray3};
      width: 100%;
      margin-top: 16px;
      border-radius: 4px;
      padding: 4px;

      .iconMessage {
        color: ${(props) => props.theme.colors.friday};
        font-size: 16px;
        margin-right: 4px;
      }

      h5 {
        text-align: center;
        font-size: ${responsiveFont(10)};
        color: ${(props) => props.theme.colors.friday};
        font-weight: 400;
        font-family: 'Barlow', Regular;
      }
    
      b {
        line-height: 20px;
        text-align: center;
        font-size: ${responsiveFont(10)};
        color: ${(props) => props.theme.colors.friday};
        font-weight: 700;
        font-family: 'Barlow', Regular;
      }
    }

  @media (max-width: 680px) {
   
  }
`