import React from 'react';

import * as S from './styles'
import { AddBox, } from '@mui/icons-material';
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import Input from '../../components/Input';
import { ContainerBottom, ContainerResponse, ContainerHeader} from '../ViewChallenge/styles';
import { useTranslation } from 'react-i18next';
import { RegistrationQuiz, RegistrationQuizError } from '../ViewChallenge/types';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useIsSm } from '../../hooks/useIsSm';


interface IProps {
  registrationQuiz: RegistrationQuiz
  setRegistrationQuiz: (e: RegistrationQuiz) => void;
  setRegistrationQuizError: (e: RegistrationQuizError) => void;
  registrationQuizError: RegistrationQuizError
  numberOfAlternatives: number
  setNumberOfAlternatives: (e: number) => void;
  contentSelectRightC: (e: boolean) => React.ReactNode;
  contentSelectRight: (e: number) => React.ReactNode;
   /* eslint-disable @typescript-eslint/no-explicit-any */
  buttonAudio?: any;
}

const QuestionsQuiz: React.FC<IProps> = ({buttonAudio, contentSelectRightC, contentSelectRight, numberOfAlternatives, setNumberOfAlternatives, registrationQuiz, setRegistrationQuiz, setRegistrationQuizError, registrationQuizError }) => {
  const { t } = useTranslation();
  const isSm = useIsSm()

  return (
    <>
      {isSm &&
        <TextField
          id="outlined-multiline-static"
          label={t('Pergunta')}
          multiline
          rows={2}
          defaultValue="Default Value"
          variant="outlined"
          value={registrationQuiz.question}
          onChange={(e) => setRegistrationQuiz({ ...registrationQuiz, question: e.target.value })}
          error={registrationQuizError.question.length > 0}
          helperText={t(registrationQuizError.question || "")}
          onClick={() => setRegistrationQuizError({ ...registrationQuizError, question: '' })}
          style={{ width: '100%', display: 'flex', marginTop: '4px' }}
        />
      }

      <ContainerHeader>
        {buttonAudio}

        <FormControl sx={{ m: 1, width: isSm ? '100%' : '230px', marginTop: isSm ? '24px' : '0px', marginLeft: 'auto', marginRight: 'auto'}}>
          <InputLabel id="demo-simple-select-helper-label">{t('Tipo de Pergunta')}</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            variant="standard"
            style={{ display: 'flex', width: '100%' }}
            value={registrationQuiz.questionType}
            onClick={() => setRegistrationQuizError({ ...registrationQuizError, questionType: '' })}
            onChange={(e) => {
              setRegistrationQuiz({ ...registrationQuiz, questionType: String(e.target.value) })
            }}
          >
            <MenuItem value={'1'}>{t('Alternativa')}</MenuItem>
            <MenuItem value={'2'}>{t('Verdadeiro ou Falso')}</MenuItem>
          </Select>
        </FormControl>
      </ContainerHeader>

      {!isSm &&
        <TextField
          id="outlined-multiline-static"
          label={t('Pergunta')}
          multiline
          rows={2}
          defaultValue="Default Value"
          variant="outlined"
          value={registrationQuiz.question}
          onChange={(e) => setRegistrationQuiz({ ...registrationQuiz, question: e.target.value })}
          error={registrationQuizError.question.length > 0}
          helperText={t(registrationQuizError.question || "")}
          onClick={() => setRegistrationQuizError({ ...registrationQuizError, question: '' })}
          style={{ width: '100%', display: 'flex', marginTop: '16px' }}
        />
      }

      {registrationQuiz.questionType === '2' ? (
        <ContainerResponse>
          <S.BoxResponse>
            <h5 className='textNumber'>1.</h5>
            <Input
              label={t('Alternativa')}
              type="pergunta"
              value={t('Verdadeiro')}
              size="small"
              fullWidth
              variant="filled"
              style={{ width: '100%', display: 'flex' }}
              InputProps={{
                endAdornment: (contentSelectRightC(true)),
              }}
              disabled
            />
          </S.BoxResponse>

          <S.BoxResponse>
            <h5 className='textNumber'>2.</h5>
            <Input
              label={t('Alternativa')}
              type="pergunta"
              value={t('Falso')}
              size="small"
              fullWidth
              variant="filled"
              style={{ width: '100%', display: 'flex' }}
              InputProps={{
                endAdornment: (contentSelectRightC(false)),
              }}
              disabled
            />
          </S.BoxResponse>
        </ContainerResponse>
      ) : (
        <ContainerResponse>
          <S.BoxResponse>
            <h5 className='textNumber'>1.</h5>
            <Input
              label={t('Alternativa')}
              type="pergunta"
              value={registrationQuiz.response1}
              onChange={(e) => setRegistrationQuiz({ ...registrationQuiz, response1: e.target.value })}
              size="small"
              fullWidth
              error={registrationQuizError.response1.length > 0}
              onClick={() => setRegistrationQuizError({ ...registrationQuizError, response1: '' })}
              variant="filled"
              style={{ width: '100%', display: 'flex' }}
              InputProps={{
                endAdornment: (contentSelectRight(1)),
              }}
            />
          </S.BoxResponse>

          <S.BoxResponse>
            <h5 className='textNumber'>2.</h5>
            <Input
              label={t('Alternativa')}
              type="pergunta"
              value={registrationQuiz.response2}
              onChange={(e) => setRegistrationQuiz({ ...registrationQuiz, response2: e.target.value })}
              size="small"
              fullWidth
              error={registrationQuizError.response2.length > 0}
              onClick={() => setRegistrationQuizError({ ...registrationQuizError, response2: '' })}
              variant="filled"
              style={{ width: '100%', display: 'flex' }}
              InputProps={{
                endAdornment: (contentSelectRight(2)),
              }}
            />
          </S.BoxResponse>

          <S.BoxResponse>
            <h5 className='textNumber'>3.</h5>
            <Input
              label={t('Alternativa')}
              type="pergunta"
              value={registrationQuiz.response3}
              onChange={(e) => setRegistrationQuiz({ ...registrationQuiz, response3: e.target.value })}
              size="small"
              fullWidth
              error={registrationQuizError.response3.length > 0}
              onClick={() => setRegistrationQuizError({ ...registrationQuizError, response3: '' })}
              variant="filled"
              style={{ width: '100%', display: 'flex' }}
              InputProps={{
                endAdornment: (contentSelectRight(3)),
              }}
            />
          </S.BoxResponse>

          {numberOfAlternatives >= 4 &&
            <S.BoxResponse>
              <h5 className='textNumber'>4.</h5>
              <Input
                label={t('Alternativa')}
                type="pergunta"
                value={registrationQuiz.response4}
                onChange={(e) => setRegistrationQuiz({ ...registrationQuiz, response4: e.target.value })}
                size="small"
                fullWidth
                error={registrationQuizError.response4.length > 0}
                onClick={() => setRegistrationQuizError({ ...registrationQuizError, response4: '' })}
                variant="filled"
                style={{ width: '100%', display: 'flex' }}
                InputProps={{
                  endAdornment: (contentSelectRight(4)),
                }}
              />
            </S.BoxResponse>
          }

          {numberOfAlternatives >= 5 &&
            <S.BoxResponse>
              <h5 className='textNumber'>5.</h5>
              <Input
                label={t('Alternativa')}
                type="pergunta"
                value={registrationQuiz.response5}
                onChange={(e) => setRegistrationQuiz({ ...registrationQuiz, response5: e.target.value })}
                size="small"
                fullWidth
                error={registrationQuizError.response5.length > 0}
                onClick={() => setRegistrationQuizError({ ...registrationQuizError, response5: '' })}
                variant="filled"
                style={{ width: '100%', display: 'flex' }}
                InputProps={{
                  endAdornment: (contentSelectRight(5)),
                }}
              />
            </S.BoxResponse>
          }

          <ContainerBottom>
            <ButtonMaterial
              style={{ border: `solid 1px ${theme.colorsBase.gray3}`, marginTop: '16px', color: theme.colors.friday, textTransform: 'none' }}
              variant="outlined"
              color='info'
              label={t("Adicionar Alternativa")}
              icon={<AddBox style={{ fontSize: '24px', marginRight: '4px', color: theme.colors.friday }} />}
              onClick={() => setNumberOfAlternatives(numberOfAlternatives + 1)}
              disabled={numberOfAlternatives >= 5}
            />

            <div className='divLine' />

          </ContainerBottom>
        </ContainerResponse>
      )}
    </>
  )
}

export default QuestionsQuiz
