import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'
import { theme } from '../../styles/theme';

const funcActive = (active: boolean) => {
  return active ? `solid 1px ${theme.colorsBase.gray8}` : 'none'
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding-top: 56px;

  @media (max-width: 680px) {
    height: 100vh;
    background-color: ${(props) => props.theme.colorsBase.white};
  }
`

export const ContainerContent = styled.div`
padding: 0 40px;
width: 100%;
height: 100%;
@media (max-width: 680px) {
  padding: 0;
  height: auto;
}
`

export const ContainerTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: 16px 24px;
  border-radius: 16px 16px 0px 0px;
  background-color: ${(props) => props.theme.colorsBase.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

 .divs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
 }
  
  @media (max-width: 680px) {
    padding: 16px 0px;
    border-radius: 0px;
    background: transparent;
    box-shadow: none;


 .divs {
  flex-direction: column;
  height: auto;
 }
  }
`

export const LineHeader = styled.div<{ register?: boolean }>`
  display: none;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  min-width: 50%;
  margin-bottom: 24px;
  margin-top: 16px;

  @media (max-width: 680px) {
    display: flex;
    width: 100%;
    margin-bottom: ${(props) => (props.register ? '8px' : ' 48px')};
  }
`

export const ButtonHeader = styled.button<{ active?: boolean }>`
  width: 40%;
  border: none;
  border-bottom: solid 2px ${(props) => (props.active ? props.theme.colors.seventh : 'transparent')};
   background-color: transparent;
  height: 100%;

  &:hover {
    opacity: 0.8;
  }
  
  .icon {
    color: ${(props) => props.theme.colors.seventh};
    width: 24px;
    height: 24px;
    align-self: center;
  }

    h3 {
      line-height: 16px;
      letter-spacing: 1.07px;
      text-align: center;
      font-size: ${responsiveFont(12)};
      color: ${(props) => (props.active ? props.theme.colors.seventh : props.theme.colors.friday)};
      font-weight: 500;
      font-family: 'Barlow', Medium;
      padding: 8px 8px 2px 8px;
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
`

export const BoxImageProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 16px;
  width: 100%;

  .InputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 16px;
    margin-left: 24px;

    h2 {
      line-height: 24px;
      letter-spacing: 0.15px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.black2};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    h1 {
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  }

  @media (max-width: 680px) {
    padding-bottom: 8px;
  }

`

export const BackgroundImageProfile = styled.label`
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 50%;
  position: relative;

   img {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: solid 2px ${(props) => props.theme.colors.secundary};
    z-index: 1;
   }

  .iconBackground {
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.secundary};
    z-index: 1;
    .iconCam {
      color: ${(props) => props.theme.colorsBase.white};
      width: 16px;
      height: 16px;
      align-self: center;
    }
  }

  .noImage {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: solid 2px ${(props) => props.theme.colors.secundary};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colorsBase.white};
    z-index: 1;

    .icon {
      color: ${(props) => props.theme.colorsBase.gray6};
      width: 24px;
      height: 24px;
      align-self: center;
    }
  }
`

export const ContainerProfile = styled.div<{ professional?: boolean, active?: boolean, }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: ${(props) => (props.professional ? '100%' : '40%')};
  //  height: 100%;
  padding: 0px 40px 16px 40px;

  ${({ professional, active }) =>
    professional ?
      `
  border-left:  ${funcActive(active ?? false)}; 
  border-right:  ${funcActive(active ?? false)}; 
  `: `
  border-left:  ${funcActive(active ?? false)}; 
`};

@media (max-width: 680px) {
  padding: 16px 16px 16px 16px;
  width: 100%;
  height: auto;
}

`

export const ContainerRecoverPassword = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  h3 {
    margin-bottom: 8px;
    line-height: 20px;
    text-align: center;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }

  a {
    margin-left: auto;
    margin-top: 2px;
    line-height: 20px;
    text-align: center;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    text-decoration: none;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }

    &:hover {
      opacity: 0.8;
    }
  }
`

export const Input = styled.input`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`

export const TextCount = styled.div`
display: flex;
align-items: left;
justify-content: flex-start;
width: 100%;
height: 0.5px;
margin-top: 16px;
margin-left: 16px;

h5 {
  display: flex;
  align-self: flex-start;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
  font-size: ${responsiveFont(12)};
  color: ${(props) => props.theme.colors.black};
  font-weight: 400;
  font-family: 'Barlow', Medium;

  @media (max-width: 680px) {
    font-size: ${responsiveFont(10)};
  }

  @media (min-width: 1600px) {
    font-size: ${responsiveFont(14)};
  }
}
`

export const ContainerProfessional = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  width: 80%;
  height: 100%;
  margin: 16px 40px 56px 40px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`

export const HeaderInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  flex-direction: row;
  margin-bottom: 16px;

  .icon {
    color: ${(props) => props.theme.colors.seventh};
    width: 24px;
    height: 24px;
    align-self: center;
  }

  h3 {
    margin-left: 8px;
    line-height: 18px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
    display: none;
  }
`



export const CheckBoxWithText = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  flex-direction: row;
  margin-top: 40px;

  button {
    display: flex;
    border: none;
    background: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 12px;

    .i {
      color: ${(props) => props.theme.colors.seventh};
      font-size: 24px;
    }
  }
    h5 {
      line-height: 16px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

 }

  h3 {
    margin-left: 8px;
    line-height: 18px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
}

@media (max-width: 680px) {
  margin-top: 24px;
}
`

export const ContainerBottom = styled.div<{ active?: boolean, }>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 48px 16px 16px 16px;
  width: 100%;

  .left {
    width: 60%;
    display: flex;
    border: none;
    background: none;
    flex-direction: row;
    align-items: center;
    margin-right: 12px;
    justify-content: flex-start;
    
  }

  .right {
    width: 40%;
    display: flex;
    border: none;
    background: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  @media (max-width: 680px) {
    padding: 24px 16px;
    margin-top: 0px;
    flex-direction: column;

    .left {
      width: 100%;
      flex-direction: column;
      margin-right: 0px;
    }

    .right {
      width: 100%;
      flex-direction: column;
    }

  }
`