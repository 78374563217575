import styled from 'styled-components'
import { theme } from '../../styles/theme'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 8px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  position: relative;
  padding: 8px;

  @media (max-width: 680px) {
    width: 100%;
  }

  .containerChip {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
  }
  
  .boxContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;

      .boxUser {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        width: 100%;

        .i {
          font-size: 24px;
          color: ${theme.colors.seventh};
        }
  
        .nameUser {
          margin-left: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          text-align: left;
          font-size: ${responsiveFont(14)};
          color: ${(props) => props.theme.colors.black};
          font-weight: 400;
          font-family: 'Barlow', Regular;
          
          @media (min-width: 1600px) {
            font-size: ${responsiveFont(16)};
          }
        }
      }
  }


`

export const ChipMonochrome = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 12px;
  height: 30px;
  background-color: transparent;
  border-radius: 16px;
  border: solid 1px ${(props) => props.theme.colors.friday};
  margin-left: 8px;

  .i {
    color: ${theme.colors.seventh};
    font-size: 16px;
    margin-right: 8px;
  }

  .titleChip {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.friday};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }
`

export const LinkDownload = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 12px;
  height: 30px;
  background-color: transparent;
  border-radius: 16px;
  border: solid 1px ${(props) => props.theme.colors.seventh};
  text-decoration: none;
  .i {
    color: ${theme.colors.seventh};
    font-size: 16px;
    margin-right: 8px;
  }

  .titleChip {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 500;
    font-family: 'Barlow', Medium;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.seventh};

    .i {
      color: ${theme.colorsBase.white};
    }

    .titleChip {
      color: ${(props) => props.theme.colorsBase.white};
    }
  }
`


export const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const Image = styled.img`
  width: 100%;
  border-radius: 4px;
  object-fit: cover; 
`

export const ButtonViewMore = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  .title {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: right;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.friday};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  &:hover {
      opacity: 0.8;
  }
`


