import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Option = styled.div<{ white?: boolean }>`
  display: flex;
  width: 100%;
  border: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0px;
  box-shadow:  0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 8px 12px 8px 24px;
  border-radius: 4px;

  .boxText {
    display: flex;
    width: 100%;
    height: 40px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 24px;

    .buttonh5 {
      background: none;
      border: none;
      font-weight: 400;
      color: ${(props) => props.theme.colors.black};
      font-size: ${responsiveFont(20)};
      font-family: 'Barlow', Regular;

      max-width: ${`calc(100vw - 400px)`};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }

    .description {
      font-weight: 400;
      margin-top: 4px;
      color: ${(props) => props.theme.colors.black};
      font-size: ${responsiveFont(14)};
      font-family: 'Barlow', Regular;

      max-width: ${`calc(100vw - 400px)`};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(8)};
        max-width: ${`calc(100vw - 110px)`};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .containerTitle {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    .buttonh4 {
      border: none;
      background: none;
      font-weight: 400;
      color: ${(props) => props.theme.colorsBase.black};
      font-size: ${responsiveFont(14)};
      font-family: 'Barlow', Regular;

      max-width: ${`calc(100vw - 195px)`};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }

  .containerRight {
    display: flex;
    height: 40px;
    width: 10%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    margin-left: 12px;

    h1 {
      font-weight: 400;
      margin-top: 4px;
      color: ${(props) => props.theme.colors.friday};
      font-size: ${responsiveFont(12)};
      font-family: 'Barlow', Regular;

      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: 680px) {
        margin-top: 0px;
        font-size: ${responsiveFont(8)};
      }
    }

    .boxIcon {
      width: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
       background-color: transparent;
      border: none;

      .icon {
        width: 16px;
        color: ${(props) => props.theme.colors.friday};
      }
    }

  }

  .flag {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colorsBase.gray7};
    color: ${(props) => props.theme.colorsBase.white};
    padding: 8px;
  }

  @media (max-width: 680px) {
    padding: 8px;

    .containerRight {
      margin-left: 0px;
      // padding-left: 8px;
      height: auto;
      width: auto;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      h1 {
        padding-right: 8px;
      }
    }
  }
`