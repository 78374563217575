import { SvgIcon } from '@mui/material'

export interface ISvgIconProps {
  color?: 'error' | 'disabled' | 'action' | 'inherit' | 'primary' | 'secondary' | undefined
  className?: string
}

export function DatabaseIcon (props: ISvgIconProps) {
  return (
    <SvgIcon color={props.color} className={props.className ? props.className : ''}>
      <g transform="translate(0 -290.65)">
        <path
          d="m11.929 291a11.092 4.2062 0 0 0-11.093 4.2061 11.092 4.2062 0 0 0 11.093 4.2061 11.092 4.2062 0 0 0 11.093-4.2061 11.092 4.2062 0 0 0-11.093-4.2061zm10.29 7.3275a11.092 4.2062 0 0 1-10.348 2.7068 11.092 4.2062 0 0 1-10.292-2.6404 11.092 4.2062 0 0 0-0.74261 1.4993 11.092 4.2062 0 0 0 11.093 4.2061 11.092 4.2062 0 0 0 11.093-4.2061 11.092 4.2062 0 0 0-0.80299-1.5657zm0 5.1701a11.092 4.2062 0 0 1-10.348 2.7068 11.092 4.2062 0 0 1-10.292-2.6424 11.092 4.2062 0 0 0-0.74261 1.5013 11.092 4.2062 0 0 0 11.093 4.2041 11.092 4.2062 0 0 0 11.093-4.2041 11.092 4.2062 0 0 0-0.80299-1.5657zm0 4.5664a11.092 4.2062 0 0 1-10.348 2.7068 11.092 4.2062 0 0 1-10.292-2.6424 11.092 4.2062 0 0 0-0.74261 1.5013 11.092 4.2062 0 0 0 11.093 4.2041 11.092 4.2062 0 0 0 11.093-4.2041 11.092 4.2062 0 0 0-0.80299-1.5657z"
          strokeWidth="1.0583"
        />
      </g>
    </SvgIcon>
  )
}

export function CloseIcon (props: ISvgIconProps) {
  return (
    <SvgIcon color={props.color} className={props.className ? props.className : ''}>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </SvgIcon>
  )
}
