export const responsiveFont = (size: number) => {
  return `${size * 0.0625}rem`;
};

export const validateIfNull = (input?: string, inputOptional = '-') => {
  return input || inputOptional;
};

export const changeDateLocal = (timeString: string) => {
  const currentDate = new Date();
  
  const [hours, minutes] = timeString.split(":").map(Number);
  
  currentDate.setHours(hours);
  currentDate.setMinutes(minutes);

  return String(new Date(currentDate.getTime() - currentDate.getTimezoneOffset()*60*1000)).substring(16, 21);
};

export const removeSpaces = (str: string) => {
  if (!str) return '';
  return str.trim();
};