import Modal from '@mui/material/Modal';
import React, { useState } from 'react';
import * as S from './styles'
import { useTranslation } from 'react-i18next';
import { Registration, RegistrationError, ModalProps } from './types';
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import { useAuth } from '../../contexts/Auth';
import Input from '../../components/Input';
import ToastNotification from '../../components/ToastNotification';
import { signInValidation } from './validations';
import { dateMask } from '../../utils/Mask';
import { Info } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip';

const CompleteRegistration: React.FC<ModalProps> = (props) => {
  const { open } = props;
  const { t } = useTranslation();
  const handleClose = () => { };
  const { completeRegistrationSocialLogin, changeCompleteRegistration } = useAuth();
  const [registration, setRegistration] = useState<Registration>(new Registration());
  const [registrationError, setRegistrationError] = useState<RegistrationError>(new RegistrationError());
  const [loading, setLoading] = useState(false);

  const handleCompleteRegistration = async () => {
    setLoading(true);
    try {
      let countError = 0;

      const validation = await signInValidation(registration);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError(validation as RegistrationError);
        setLoading(false);
        return;
      }

      const bodyData = {
        BornDate: registration.dateOfBirth
      };

      await completeRegistrationSocialLogin(bodyData);
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao completar os dados, tente novamente.')
      });
    }
    setLoading(false);
  };

  const onEnterDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      handleCompleteRegistration();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <S.ContainerModal>
        <h5>{t('Para completar o seu cadastro,')}<br />{t('insira as informações abaixo:')}</h5>

        <Tooltip title={<h3 style={{ fontSize: 10, fontWeight: 'normal' }}>{t('Para quê precisamos dessa informação?')}<br /><br />{t('Nem todos os conteúdos de saúde são adequados para todas as idades. Por favor, insira informações verdadeiras. Seus dados não serão compartilhados.')}</h3>} placement="left-start">
          <Info className="icon" />
        </Tooltip>

        <div>
          <Input
            label={t('Data de Nascimento')}
            value={registration.dateOfBirth}
            onChange={(e) => setRegistration({ ...registration, dateOfBirth: dateMask(e.target.value) })}
            size="small"
            fullWidth
            error={registrationError.dateOfBirth ? true : false}
            helperText={t(registrationError.dateOfBirth)}
            onClick={() => setRegistrationError({ ...registrationError, dateOfBirth: '' })}
            variant="filled"
            style={{ marginTop: '16px' }}
            onKeyPress={onEnterDown}
          />
        </div>

        <div className='button'>
          <ButtonMaterial
            style={{ borderColor: theme.colors.secundary }}
            variant="outlined"
            label={t("CANCELAR")}
            onClick={() => changeCompleteRegistration(false)}
            loading={loading}
          />

          <ButtonMaterial
            style={{ backgroundColor: theme.colors.seventh, marginLeft: '24px' }}
            variant="contained"
            label={t("SALVAR")}
            onClick={handleCompleteRegistration}
            loading={loading}
          />
        </div>
      </S.ContainerModal>
    </Modal>
  )
}

export default CompleteRegistration
