import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 48px 16px;

  .icon_loading {
    color: ${props => props.theme.colors.primary};
    animation: loading 1s infinite;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Message = styled.h1`
  flex: 1;
  margin-bottom: 56px;
  margin-top: 28px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`