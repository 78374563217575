import * as yup from 'yup';
import { onlyDigits } from '../../utils/Mask';

import { verifyClassEmpty } from '../../utils/ClassEmpty';
import { BirthDateValidation, genericValidation } from '../../utils/ValidationGenerics';

import { Login, LoginError, Registration, RegistrationError } from './types';

export const signInValidation = async (object: Login): Promise<true | LoginError> => {
  const schemaRegister = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required()
  });

  const mountError = await genericValidation<Login, LoginError>(schemaRegister, object, new LoginError());

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};

export const registerValidation = async (object: Registration): Promise<true | Login> => {
  const schemaRegister = yup.object().shape({
    password: yup.string().min(8, 'A senha deve ter pelo menos 8 caracteres!').required(),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Senhas diferentes!').required(),
    dateOfBirth: yup.string().required(),
    email: yup.string().email().required(),
    name: yup.string().required(),
  });

  const mountError = await genericValidation<Registration, RegistrationError>(schemaRegister, object, new Login());

  const dateValidation = BirthDateValidation(onlyDigits(object.dateOfBirth));
  if (object.dateOfBirth.length > 0) {
    if (!dateValidation.status) {
      mountError.dateOfBirth = dateValidation.msg;
    }
  }

  const regExpSpecialChar = /[^a-zA-Z0-9]/;
  const regExpNumber = /\d/;
  const regExpCapitalLowerCase = () => {
    let i = 0;
    if (object.password.length === 0) {
      return false;
    }
    while (i < object.password.length) {
      const character = object.password.charAt(i);
      if (character === character.toUpperCase()) {
        // Character is upperCase, numeric, or a symbol
      } else if (character === character.toLowerCase()) {
        return true;
      }
      i++;
    }
    return false;
  };
  const regExpCapitalUpperCase = () => {
    let i = 0;
    if (object.password.length === 0) {
      return false;
    }
    while (i < object.password.length) {
      const character = object.password.charAt(i);
      if (character === character.toLowerCase()) {
        // Character is lowercase, numeric, or a symbol
      } else if (character === character.toUpperCase()) {
        return true;
      }
      i++;
    }
    return false;
  };

  if (!regExpSpecialChar.test(object.password) && mountError.password === '') {
    mountError.password = 'Deve conter pelo menos um caracter especial';
  }

  if (!regExpNumber.test(object.password) && mountError.password === '') {
    mountError.password = 'Deve conter pelo menos um dígito de 0-9';
  }

  if (!regExpCapitalUpperCase() && mountError.password === '') {
    mountError.password = 'Deve conter pelo menos um caracter maiúsculo';
  }

  if (!regExpCapitalLowerCase() && mountError.password === '') {
    mountError.password = 'Deve conter pelo menos um caracter minúsculo';
  }
  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};
