import React from 'react'

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { ThemeProvider as SCThemeProvider } from 'styled-components'

import { MUIThemeFintalk, theme } from './theme'

const ThemeMain: React.FC = ({ children }) => {
  return (
    <MuiThemeProvider theme={MUIThemeFintalk}>
      <SCThemeProvider theme={theme}>
        { children }
      </SCThemeProvider>
    </MuiThemeProvider>
  )
}

export default ThemeMain
