import React from 'react';
import { Close, Delete, Notifications } from '@mui/icons-material';
import * as S from './styles'
import { INotification } from '../../services/Notifications/types';
import { DataMaskFormtData } from '../../utils/Mask';
import { useIsSm } from '../../hooks/useIsSm';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { changeDateLocal } from '../../utils/UtilsGlobal';
interface IProps {
    historic?: boolean
    onClickClose?: () => void;
    item: INotification;
}


const CardNotification: React.FC<IProps> = ({ onClickClose, item, historic = false }) => {
    const isSm = useIsSm()
    const navigate = useNavigate()

    const renderTitle = (type: number) => ({
        1: t("Novo desafio para análise"),
        2: t("Novo desafio para triagem"),
        3: t("Novo desafio para curadoria"),
        4: t("Seu desafio foi aprovado"),
        5: t("Sugestão de edição para desafio"),
        6: t("Seu desafio foi bloqueado!"),
        7: t("Novo desafio para curadoria"),
        8: t("Convite recebido!"),
        9: t("Convite aceito!"),
        10: t("Convite Recusado"),
        11: t("O seu convidado aderiu a plataforma"),
        12: t("Você ganhou pontos!"),
    }[type])

    const renderDescription = (type: number) => {
        if (type === 1) return item.Data?.ChallengeTitle
        if (type === 2) return item.Data?.ChallengeTitle
        if (type === 3) return item.Data?.ChallengeTitle
        if (type === 4) return item.Data?.ChallengeTitle
        if (type === 5) return item.Data?.ChallengeTitle
        if (type === 6) return item.Data?.ChallengeTitle
        if (type === 7) return item.Data?.ChallengeTitle
        if (type === 8) return item.Data?.Name
        if (type === 9) return item.Data?.Name
        if (type === 10) return item.Data?.Name
        if (type === 11) return item.Data?.Name
        if (type === 12) return item.Data?.Name
        return ''
    };

    const renderClick = (type: number) => {
        if (type === 1) return navigate('/desafios')
        if (type === 2) return navigate('/administracao/desafios')
        if (type === 3) return navigate('/inspection')
        if (type === 4) return navigate('/desafios')
        if (type === 5) return navigate('/desafios')
        if (type === 6) return navigate('/desafios')
        if (type === 7) return navigate('/inspection')
        if (type === 8) return navigate('/contatos')
        if (type === 9) return navigate('/contatos')
        if (type === 10) return navigate('/contatos')
        if (type === 11) return navigate('/contatos')
        if (type === 12) return navigate('/minhas_conquistas')
        return ''
    };

    return (
        <S.Option>
            <Notifications className="flag" />
            <div className='boxText'>
                {isSm ?
                    <div className='containerTitle'>
                        <button className='buttonh4' onClick={() => renderClick(item.Type)}>{renderTitle(item.Type)}</button>

                        <div className='containerRight'>
                            <h1>{DataMaskFormtData(item.DateTime)} {changeDateLocal(item?.DateTime.substring(11, 16))}</h1>

                            <button className='boxIcon' onClick={onClickClose}>
                                {historic ? <Delete className="icon" /> : <Close className="icon" />}
                            </button>
                        </div>
                    </div>
                    : <button className='buttonh5' onClick={() => renderClick(item.Type)}>{renderTitle(item.Type)}</button>}

                <div className='description'>{renderDescription(item?.Type)}</div>
            </div>

            {!isSm &&
                <div className='containerRight'>
                    <button className='boxIcon' onClick={onClickClose}>
                        {historic ? <Delete className="icon" /> : <Close className="icon" />}
                    </button>

                    <h1>{DataMaskFormtData(item.DateTime)} {changeDateLocal(item?.DateTime.substring(11, 16))} </h1>
                </div>
            }
        </S.Option>
    )
}

export default CardNotification
