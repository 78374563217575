import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  margin-top: 56px;
  background-color: ${(props) => props.theme.colorsBase.white};
`

export const ContainerLineChallenge = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 4px 24px;
  background-color: ${(props) => props.theme.colorsBase.white};

  .i {
    color: ${(props) => props.theme.colorsBase.gray12};
    width: 24px;
    height: 24px;
  }

  h5 {
    margin-left: 8px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: center;
    font-size: ${responsiveFont(12)};
    color: ${(props) => (props.theme.colors.fourth)};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (max-width: 680px) {
      text-align: left;
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  @media (max-width: 680px) {
    padding: 4px 16px;
    align-items: flex-start;
  }
`

export const ContainerData = styled.div`
  display: flex;
  max-height: 50%
  align-items: flex-start;
  padding: 40px 25% 16px 25%;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 680px) {
    padding: 12px 0px 24px 0px;
  }
`

export const Table = styled.div`
  position: relative;
  height: 350px;
  min-height: 350px;
  overflow: auto;
  margin: 16px 16px;

  z-index: 10;

  &::-webkit-scrollbar {
    border-radius: 8px;
    width: 4px;
    background: ${(props) => props.theme.colors.eleven};
    }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.seventh};
    border-radius: 8px;
  }
  ::-webkit-scrollbar:vertical { 
    height: 4px;
    border-radius: 8px;
  }

  h2 {
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    margin-bottom: 8px;
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Medium;
  }

  @media (min-width: 1600px) {
    h2 {
      line-height: 24px;
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
    padding-right: 4px;
    margin 48px 8px 32px 16px;
  }
`