import { Box, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: "24px",
  paddingLeft: '48px',
  paddingRight: '48px',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateAreas: `
    "totalChallenge challengeImpact"
    "history history"
  `,
  [theme.breakpoints.down(1048)]: {
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `
      "totalChallenge"
      "challengeImpact"
      "history"
    `,
  }
}))
