import { LinearProgress, Stack, Typography } from "@mui/material";

interface ILinearProps {
  name: string;
  value: number;
  color: string;
}

const Linear: React.FC<ILinearProps> = ({ name, value, color }) => (
  <Stack spacing={0.5}>
    <Stack direction="row" justifyContent="space-between">
      <Typography>{name}</Typography>
      <Typography color={color}>{isNaN(value) ? 0 : value}</Typography>
    </Stack>
    <LinearProgress
      variant="determinate"
      value={isNaN(value) ? 0 : value}
      sx={{
        height: "8px",
        borderRadius: '16px',
        backgroundColor: '#DBDADA',
        "span": {
          backgroundColor: color,
        }
      }}
    />
  </Stack>
)

export default Linear;
