/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PageContainer from '../../../components/Layout/PageContainer';
import PaperContainer from '../../../components/Layout/PaperContainer';
import { AntTab, AntTabs } from '../../../components/Tabs';
import { theme } from '../../../styles/theme';
import HeaderAchievements from '../../../components/HeaderAchievements';
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../../hooks/useIsSm';
import { Add, Star } from '@mui/icons-material';
import ToastNotification from '../../../components/ToastNotification';
import { ICardSpecialProgram } from '~/pages/SpecialProgramManagement/ScreenUnderConstruction/types';
import * as S from '../../../components/ChallengeItemDesktop/styles';
import ButtonMaterial from '../../../components/Button';
import ScreenContents from './ScreenSpecialProgramContents';
import ModalInviteParticipants from './ModalInviteParticipants';
import SpecialSummary from './ScreenSpecialProgramContents/SpecialProgramSummaryModal/index';
import { getSpecialProgram, postFinalizeContent, putSpecialProgram } from '../../../services/SpecialProgram';
import Logger from '../../../utils/Logger';
import ModalEditSpecialProgram from './ModalEditSpecialProgram';
import ButtonCreateMobileSpecialProgram from '../../../components/ButtonCreateMobileSpecialProgram';
import SpecialProgramDetailCard from '../../../components/SpecialProgramDetailCard';
import ParticipantsList from '../../../components/ParticipantsList';

interface IProps {
  tab: number;
  setNewTab: (newTab: number) => void;
  variant?: "fullWidth" | "standard" | "scrollable";
}

const OverviewTab: React.FC<{ specialProgram: ICardSpecialProgram, update: () => void }> = ({ specialProgram, update }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  const handleSendInvitations = async (index: number) => {
    try {
      const updatedEmails: string[] = specialProgram.emailList;
      updatedEmails.splice(index, 1);

      const data = new FormData();
      updatedEmails.forEach(e => data.append('SpecialProgram.Emails', e));
      data.append('SpecialProgram.Title', specialProgram.title);
      data.append('SpecialProgram.Description', specialProgram.description);
      data.append('SpecialProgram.AverageRate', specialProgram.averagePerformance);
      data.append('SpecialProgram.Language', specialProgram.language);
      data.append('SpecialProgram.Attempts', specialProgram.attempts);
      data.append('SpecialProgram.Tags', specialProgram?.tags);

      const response = await putSpecialProgram(data, String(specialProgram?.id));
      if (response.Success) {
        update();
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      Logger('ex', 'Houve um erro, tente novamente.');
    }
  };

  const validateContentsActive = () => {
    let resultNegative = false;

    specialProgram?.Challenges?.map((e) => {
      if (e.ChallengeStatus !== 5) {
        resultNegative = true;
      }
    })
    return !resultNegative
  };

  const handleFinalizeContent = async () => {
    try {
      if (validateContentsActive() === false) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Você só pode finalizar a inserção de conteúdos quando todos forem aprovados.')
        });
        return;
      }

      const bodyData = {
        Id: String(specialProgram?.id),
        ContentAdditionCompleted: true
      };
      const response = await postFinalizeContent(bodyData);
      if (response.Success) {
        update();
        navigate('/gerenciamento_de_programas_especiais');
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao Finalizar Inserção do Conteúdo, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      Logger('ex', 'Houve um erro, tente novamente.');
    }
  };

  return (
    <div>
      <S.ContainerSpecial1>
        <SpecialProgramDetailCard
          specialProgram={specialProgram}
          handleEdit={() => setOpen(true)}
          handleFinalizeContent={handleFinalizeContent}
          typePermission={6}
        />

        <ParticipantsList
          specialProgram={specialProgram}
          handleSendInvitations={handleSendInvitations}
          typePermission={6}
        />
      </S.ContainerSpecial1>

      <ModalEditSpecialProgram
        open={open}
        setOpen={setOpen}
        program={specialProgram}
        update={() => update()}
      />
    </div>
  );
};

const SpecialProgramDetailsPage: React.FC<IProps> = ({ setNewTab, tab, variant }) => {
  const { t } = useTranslation();
  const isSm = useIsSm();
  const [loading, setLoading] = useState(false);
  const [specialProgram, setSpecialProgram] = useState<ICardSpecialProgram>();
  const { token } = useParams();
  const location = useLocation();
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (location.state?.specialProgram) {
      setSpecialProgram(location.state.specialProgram);
    } else {
      handleSpecialProgram(token ?? '');
    }
  }, [token, location.state]);

  function getStatusText(status: number): 'approved' | 'rejected' | 'under_review' {
    switch (status) {
      case 5:
        return 'approved';
      case 7:
        return 'rejected';
      case 3:
        return 'under_review';
      default:
        return 'under_review'; // Padrão para qualquer outro valor não mapeado
    }
  }

  const handleSpecialProgramUpdate = (id: string) => {
    handleSpecialProgram(id)
  }

  const handleSpecialProgram = async (id: string) => {
    setLoading(true);
    try {
      const body = {
        Id: id,
        PageNumber: 1,
        PageSize: 1,
        GetChallenges: true
      };
      const response = await getSpecialProgram(body);
      if (response.Success) {
        if (response.Data && response.Data.length > 0) {
          setSpecialProgram({
            language: String(response.Data[0].Language),
            image: response.Data[0].Image,
            averagePerformance: response.Data[0].AverageRate,
            tags: response.Data[0].Tags,
            description: response.Data[0].Description,
            emailList: response.Data[0].EmailList,
            mockData: specialProgram,
            id: response.Data[0].Id,
            title: response.Data[0].Title,
            coverImageUrl: response.Data[0].Image,
            status: getStatusText(response.Data[0].Status),
            Challenges: response.Data[0].Challenges,
            attempts: String(response.Data[0].Attempts),
            DurationTime: response.Data[0].DurationTime,
          });
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('O_programa_especial_não_está_mais_disponível'),
          errorMessage: response.Message,
          errors: response.Errors,
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('O_programa_especial_não_está_mais_disponível'),
      });
    }
    setLoading(false);
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setNewTab(newValue);
  };

  const RenderSpecialProgram = (update: () => void) => {
    if (loading) {
      return <div>Loading...</div>;
    }

    if (specialProgram) {
      if (tab === 0) return <OverviewTab specialProgram={specialProgram} update={update} />
      if (tab === 1) return <ScreenContents update={() => handleSpecialProgram(specialProgram?.id ?? '')} tab={0} id={specialProgram?.id} />
      if (tab === 2) return <SpecialSummary update={false} tab={0} id={specialProgram?.id} />;
    }

    return <div>Nenhum programa especial encontrado.</div>;
  };

  return (
    <PageContainer>
      <HeaderAchievements
        title={specialProgram ? specialProgram.title : t('Programa_Especial')}
        marginBottom={isSm ? 24 : 0}
        buttonBack={true}
        icon={<Star style={{ color: isSm ? theme.colors.secundary : theme.colorsBase.gray3 }} />}
      />

      <PaperContainer>
        <AntTabs value={tab} onChange={handleTabChange} variant={variant}>
          <AntTab label="VISÃO GERAL" sx={{ width: isSm ? (specialProgram?.status === 'approved' ? '33.33vw' : '50vw') : 'none' }} />
          <AntTab label="CONTEÚDOS" sx={{ width: isSm ? (specialProgram?.status === 'approved' ? '33.33vw' : '50vw') : 'none' }} />
          {specialProgram?.status === 'approved' && <AntTab label="RESUMO" sx={{ width: isSm ? '33.33vw' : 'none' }} />}

          {!isSm ? <ButtonMaterial
            icon={<Add style={{ color: theme.colorsBase.white }} />}
            style={{ backgroundColor: theme.colorsBase.blue, position: 'absolute', right: '1px', marginTop: '1rem', marginRight: '1rem' }}
            variant='contained'
            label={t('Convidar_Participantes')}
            onClick={() => setOpen(!open)}
          /> : <ButtonCreateMobileSpecialProgram title={t('CONVIDAR PARTICIPANTES')} onClick={() => setOpen(!open)} />}
        </AntTabs>

        <ModalInviteParticipants
          open={open}
          setOpen={setOpen}
          program={specialProgram}
          update={() => handleSpecialProgram(specialProgram?.id ?? '')}
          programActive={specialProgram?.status === 'approved'}
        />

        {RenderSpecialProgram(() => handleSpecialProgramUpdate(specialProgram?.id ?? ''))}
      </PaperContainer>
    </PageContainer>
  );
};

export default SpecialProgramDetailsPage;
