import { useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { sendRequest } from "../../../../services/RequestService";
import { toRecordString } from "../../../../utils";

import { IChallengeCurationAllQuery, IChallengeCurationAllQueryResponse } from "./types";

const defaultRoot = "challenges-curations/all"

export const useInvalidateChallengeCurationAll = () => {
  const queryClient = useQueryClient()

  return useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ["useChallengeCurationAll"], exact: false })
  }, [queryClient])
}

export const useChallengeCurationAll = (params: IChallengeCurationAllQuery) => useQuery({
  queryKey: ["useChallengeCurationAll", params],
  queryFn: async () => {
    const query = new URLSearchParams(toRecordString(params)).toString()
    const url = `${defaultRoot}?${query}`
    const response =  await sendRequest<IChallengeCurationAllQueryResponse[]>(url, 'GET')

    if (!response.Success) throw new Error(response.ErrorDetails)

    return response;
  }
})
