import React, { useEffect, useState } from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';


import todos from "../../assets/images/about/todos.png"
import criadores from "../../assets/images/about/criadores.png"
import confiavel from "../../assets/images/about/confiavel.png"
import pontos from "../../assets/images/about/pontos.png"
import privacidade from "../../assets/images/about/privacidade.png"

import PageContainer from '../../components/Layout/PageContainer';
import AboutHeader from '../../components/Layout/AboutHeader';
import Footer from '../../components/Footer';
import { withLineBreak } from '../../utils';

import { Title, ContentText, BoxWithImage, SideImage, Title2 } from '../About/styles';
import { Grid } from './styles';
import ToastNotification from '../../components/ToastNotification';
import { getEarlyRegistration, postEarlyRegistration } from '../../services/EarlyRegistration';
import { theme } from '../../styles/theme';
import ButtonMaterial from '../../components/Button';
import { useIsSm } from '../../hooks/useIsSm';

const CompanyChannelView: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const isSm = useIsSm()
  const [alreadyRegistered, setAlreadyRegistered] = useState(false)

  useEffect(() => {
    handlealreadyRegistered()
  }, []);

  const handlealreadyRegistered = async () => {
    setLoading(true);
    try {
      const response = await getEarlyRegistration(1);
      if (response.Success) {
        setAlreadyRegistered(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.')
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false);
  };

  const handleRegisterEmail = async () => {
    setLoading(true);
    try {
      const bodyData = {
        Type: 1
      };

      const response = await postEarlyRegistration(bodyData);
      if (response.Success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Registro realizado com sucesso!')
        });
        setAlreadyRegistered(true)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao se registrar, tente novamente.')
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao se registrar, tente novamente.'),
      });
    }
    setLoading(false);
  };

  return (
    <PageContainer>
      <AboutHeader title="Canal Empresarial" subTitle='(Em Construção)'/>

      <Grid>
        <Box gridArea="geral">
          <Title>{t("Bem-vindo ao Canal Empresarial Curar Saúde!")}</Title>
          <ContentText>{withLineBreak(t("canal_empresarial_sobre"))}</ContentText>
        </Box>

        <Box gridArea="importancia">
          <Title>{t("Por que o Canal Empresarial é importante?")}</Title>
          <ContentText>{withLineBreak(t("canal_empresarial_importancia"))}</ContentText>
        </Box>

        <Box gridArea="neste_canal">
          <Title2 textAlign="center" fontSize="24px" color="#5080FF">{t("O que você encontrará neste Canal?")}</Title2>
        </Box>

    

        <BoxWithImage gridArea="publicacao"  style={{ width: isSm ? '100%' : '50%', marginLeft: 'auto', marginRight: 'auto' }}>
          <SideImage src={todos} />
          <Title>{t("Capacidade de Publicação")}</Title>
          <ContentText>{withLineBreak(t("canal_empresarial_publicacao"))}</ContentText>
        </BoxWithImage>

        <BoxWithImage gridArea="visibilidade">
          <SideImage src={criadores} />
          <Title>{t("Maior Visibilidade e Engajamento")}</Title>
          <ContentText>{withLineBreak(t("canal_empresarial_visibilidade"))}</ContentText>
        </BoxWithImage>

        <BoxWithImage gridArea="planos">
          <SideImage src={confiavel} />
          <Title>{t("Planos Diferenciados")}</Title>
          <ContentText>{withLineBreak(t("canal_empresarial_planos"))}</ContentText>
        </BoxWithImage>

        <Box gridArea="primeiro">
          <Title2 textAlign="center" fontSize="24px" color="#5080FF">{t("Seja o Primeiro a Saber!")}</Title2>
        </Box>

        <BoxWithImage gridArea="registro">
          <SideImage src={pontos} />
          <Title>{t("Registro Antecipado")}</Title>
          <ContentText>{withLineBreak(t("canal_empresarial_registro"))}</ContentText>
        </BoxWithImage>

        <BoxWithImage gridArea="notificacao">
          <SideImage src={privacidade} />
          <Title>{t("Opção de Notificação")}</Title>
          <ContentText>{withLineBreak(t("canal_empresarial_notificacao"))}</ContentText>
        </BoxWithImage>

       
      </Grid>

      <Paper
        component={Stack}
        mx="48px"
        mb="64px"
        p="32px"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ width: isSm ? '100%' : 'none', marginLeft: isSm ? '0px' : 'none', marginTop: '-150px' }}
      >
        {alreadyRegistered ?
          <Typography textAlign="center" style={{ color: theme.colors.seventh }}>{t("Você está registrado! Quando estiver disponível, você será notificado.")}</Typography>
          :
          <Typography textAlign="center" style={{ color: theme.colors.seventh }}>{t("Seja avisado! Basta clicar no botão abaixo.")}</Typography>
        }

        <ButtonMaterial
          style={{ border: `solid 1px ${alreadyRegistered ? 'transparent' : theme.colors.seventh}`, maxWidth: '320px', width: '100%' }}
          variant="contained"
          color='info'
          label={t('Desejo ser notificado')}
          onClick={handleRegisterEmail}
          loading={loading}
          disable={alreadyRegistered}
        />
      </Paper>

      <Footer />
    </PageContainer>
  );
}

export default CompanyChannelView;
