export const getLocalStorage = <T>(key: string): T | null => {
  const getItem = sessionStorage.getItem(key)
  if (!getItem) return null
  try {
    return JSON.parse(getItem)
  } catch (e) {
    return getItem as unknown as T
  }
}

export const setLocalStorage = <T>(key: string, value: T) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value))
    return true
  } catch (e) {
    return false
  }
}

export const cleanLocalStorage = () => {
  try {
    sessionStorage.clear()
    return true
  } catch (e) {
    return false
  }
}

export const removeLocalStorage = (key: string) => {
  try {
    sessionStorage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
}
