import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color:"rgba(0, 0, 0, 0.87)",
    paddingBottom: "9px",
    fontWeight: 400,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 0,
    paddingLeft: "24px",
    height: "40px"
  },
  paddingLeft: "24px",
}));

export const StyledTableRowHead =  styled(TableRow)(() => ({
  borderBottom: "2px solid #1A1266",
}));

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#F9F9F9",
  }
}));
