import { sendRequest } from '../RequestService';
import {
  IGetChallengesModeration,
  IGetChallengesModerationResponse,
  IGetChallengesTriageResponse,
  IPostAcceptModerationCommand,
  IPostAcceptTriageCommand,
  IPostBlockModerationCommand,
  IPostCancelTriageCommand,
  IPostRefuseModerationCommand,
  IPostRejectModerationCommand,
  ISendModeration,
} from './types';

const defaultRoot = 'moderacoes';

export const postSendModeration = async (body: ISendModeration) => {
  const url = `${defaultRoot}/enviar-triagem`
  return await sendRequest<boolean, ISendModeration>(url, 'POST', body)
}

export const getChallengesInModeration = async (params: IGetChallengesModeration) => {
  let url = `${defaultRoot}/desafios-moderacoes`

  url += `?Page=${params?.Page ?? 1}&PageSize=${params?.PageSize ?? 10}`;
  if (params.Id) {
    url += `&Id=${params?.Id}`
  }
  return await sendRequest<IGetChallengesModerationResponse[]>(url, 'GET')
}

export const getChallengesInTriage = async (params: IGetChallengesModeration) => {
  let url = `${defaultRoot}/desafios-triagem`

  url += `?Page=${params?.Page ?? 1}&PageSize=${params?.PageSize ?? 10}`;
  if (params.Id) {
    url += `&Id=${params?.Id}`
  }

  if (params.Status) {
    params.Status.forEach((s) => url += `&Status=${s}`)
  }

  return await sendRequest<IGetChallengesTriageResponse[]>(url, 'GET')
}

export const postAcceptTriagem = async (command: IPostAcceptTriageCommand) => {
  const url = `${defaultRoot}/aprovar-triagem`
  return await sendRequest<void, IPostAcceptTriageCommand>(url, 'POST', command)
}

export const postCancelTriagem = async (command: IPostCancelTriageCommand) => {
  const url = `${defaultRoot}/cancelar-desafio`
  return await sendRequest<void, IPostAcceptTriageCommand>(url, 'POST', command)
}

export const postAcceptModeration = async (command: IPostAcceptModerationCommand) => {
  const url = `${defaultRoot}/aceitar-moderacao`
  return await sendRequest<void, IPostAcceptModerationCommand>(url, 'POST', command)
}

export const postRefuseModeration = async (command: IPostRefuseModerationCommand) => {
  const url = `${defaultRoot}/recusar-moderacao`
  return await sendRequest<void, IPostRefuseModerationCommand>(url, 'POST', command)
}

export const postRejectModeration = async (command: IPostRejectModerationCommand) => {
  const url = `${defaultRoot}/rejeitar-moderacao`
  return await sendRequest<void, IPostRejectModerationCommand>(url, 'POST', command)
}

export const postBlockModeration = async (command: IPostBlockModerationCommand) => {
  const url = `${defaultRoot}/bloquear-desafio`
  return await sendRequest<void, IPostBlockModerationCommand>(url, 'POST', command)
}
