/* eslint-disable */

interface IMaskObj {
    conditional: boolean
    expr1: string
    expr2?: string
}

export const onlyDigits = (input?: string) => {
    if (!input) return '';
    return input.replace(/\D/g, '');
};

const renderTernary = (conditional: boolean, expr1: string, expr2 = '') => {
    return conditional ? expr1 : expr2;
};

const reduceList = (list: IMaskObj[]) => {
    return list.reduce((join: string, obj) => {
        join += renderTernary(obj.conditional, obj.expr1, obj.expr2);
        return join;
    }, '');
};

export const cpfMask = (input?: string) => {
    try {
        const value = onlyDigits(input || '').match(/(\d{1,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
        if (typeof input !== 'string' || !value) throw '';

        const listMask = [
            { conditional: !value[2], expr1: value[1], expr2: `${value[1]}.${value[2]}` },
            { conditional: !!value[3], expr1: `.${value[3]}` },
            { conditional: !!value[4], expr1: `-${value[4]}` }
        ];

        return reduceList(listMask);
    } catch (error) {
        return '';
    }
};

export const dateMask = (input?: string) => {
    try {
        const value = onlyDigits(String(input)).match(/(\d{1,2})(\d{0,2})(\d{0,4})/);
        if (typeof input !== 'string' || !value) throw '';

        const listMask = [
            { conditional: !value[2], expr1: value[1], expr2: `${value[1]}/${value[2]}` },
            { conditional: !!value[3], expr1: `/${value[3]}` }
        ];

        return reduceList(listMask);
    } catch (error) {
        return '';
    }
};

export const dateMaskB = (input?: string) => {
    try {
        const value = onlyDigits(String(input)).match(/(\d{1,2})(\d{0,2})(\d{0,4})/);
        if (typeof input !== 'string' || !value) throw '';

        const listMask = [
            { conditional: !value[2], expr1: value[1], expr2: `${value[1]}/${value[2]}` },
            { conditional: !!value[3], expr1: `/${value[3]}` }
        ];

        return reduceList(listMask);
    } catch (error) {
        return '';
    }
};

export const dateMaskInverter = (input?: string) => {
    try {
        const value = onlyDigits(String(input));
        if (typeof input !== 'string' || !value) throw '';
        const data = value.substring(4, 8) + '-' + value.substring(2, 4) + '-' + value.substring(0, 2);
        return data;
    } catch (error) {
        return '';
    }
};

export const validateIfNull = (input?: string, inputOptional = '-') => {
    return input || inputOptional;
};

export const DataMaskFormtData = (DateReceive: string, hideYear?: boolean) => {
    const DateTest = new Date(DateReceive)
    const day = DateTest.getUTCDate()
    const month = DateTest.getMonth() + 1
    const year = DateTest.getFullYear()

    let dy
    let mont

    if (Number(day) < 10) {
        dy = `0${day.toString()}`
    } else {
        dy = day
    }

    if (Number(month) < 10) {
        mont = `0${month.toString()}`
    } else {
        mont = month
    }

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    let data = ''

    if (hideYear) {
        data = dy + '/' + mont
    } else {
        data = dy + '/' + mont + '/' + year
    }

    return data
}

export const cepMask = (input?: string) => {
    try {
        const value = onlyDigits(input || '').match(/(\d{1,5})(\d{0,3})/);
        if (typeof input !== 'string' || !value) throw '';
        return renderTernary(!value[2], value[1], `${value[1]}-${value[2]}`);
    } catch (error) {
        return '';
    }
};

export const mobilePhoneMask = (input?: string) => {
    try {
        const value = onlyDigits(String(input)).match(/^([0-9]{1,2})([0-9]{0,5})([0-9]{0,4})/);
        if (typeof input !== 'string' || !value) throw '';

        const listMask = listPhoneMask(value);
        return reduceList(listMask);
    } catch (error) {
        return '';
    }
};

const listPhoneMask = (value: RegExpMatchArray) => {
    return [
        { conditional: !value[2], expr1: value[1], expr2: `(${value[1]}) ${value[2]}` },
        { conditional: !!value[3], expr1: `-${value[3]}` }
    ];
};

export const cnpjMask = (input?: string) => {
    try {
        const value = onlyDigits(String(input)).match(/(\d{1,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        if (typeof input !== 'string' || !value) throw '';

        const listMask = [
            { conditional: !value[2], expr1: value[1], expr2: `${value[1]}.${value[2]}` },
            { conditional: !!value[3], expr1: `.${value[3]}` },
            { conditional: !!value[4], expr1: `/${value[4]}` },
            { conditional: !!value[5], expr1: `-${value[5]}` }
        ];

        return reduceList(listMask);
    } catch (error) {
        return '';
    }
};

export const objetosSaoIguais = (objeto1: any, objeto2: any) => {

    if (objeto1 == null && objeto2 == null) return true;

    if (objeto1 == null || objeto2 == null || objeto1.length !== objeto2.length) {
        return false;
    }

    for (let propriedade in objeto1) {

        if (typeof objeto1[propriedade] === 'object' && typeof objeto2[propriedade] === 'object') {
            const saoIguais = objetosSaoIguais(objeto1[propriedade], objeto2[propriedade])
            if (!saoIguais) return false;
            continue
        }

        if (objeto1[propriedade] !== objeto2[propriedade]) {
            return false
        }
    }
    return true;
};