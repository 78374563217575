import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  padding-top: 90px;

  .containerButtonFooter {
    margin-top: auto;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }

  .curvaN {
    display: none;
    width: 100%;
    margin-bottom: 8px;
  }

  @media (max-width: 680px) {
    padding-top: 10px;

    .curvaN {
      display: flex;
    }
  }
`

export const ContainerContent = styled.div`
padding: 0 40px;
width: 100%;

@media (max-width: 680px) {
  padding: 0 24px;
  height: auto;
}
`

export const ContainerBackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  margin: 24px 0 24px 0;

button {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  border: none;
  background: none;
   
  &:hover {
    opacity: 0.8;
  }

  .iconBackButton {
    color: ${(props) => props.theme.colors.secundary};
    width: 24px;
    height: 24px;
  }

  h5 {
    margin-left: 8px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.secundary};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(12)};
    }
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }
}

 
  

  @media (max-width: 680px) {
    margin: 40px 0 24px 0;
  }
`

export const ContainerNewTab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  margin-bottom: 40px;
  width: 100%;

    &:hover {
      opacity: 0.8;
    }

    .icon {
      color: ${(props) => props.theme.colors.secundary};
      width: 24px;
      height: 24px;
    }

    .container {
      margin-left: 8px;
      width: 100%;

      .box {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        align-self: flex-start;
        justify-content: flex-start;

        h1 {
          text-align: left;
          font-size: ${responsiveFont(12)};
          color: ${(props) => props.theme.colors.secundary};
          font-weight: 400;
          font-family: 'Barlow', Regular;
          white-space: nowrap;
        
          @media (min-width: 1600px) {
            font-size: ${responsiveFont(14)};
          }
        }

        h3 {
          margin-left: 2px;
          text-align: left;
          font-size: ${responsiveFont(12)};
          color: ${(props) => props.theme.colors.seventh};
          font-weight: 400;
          font-family: 'Barlow', Regular;
        
          max-width: 60vw;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @media (max-width: 680px) {
            max-width: 60%;
          }

          @media (min-width: 1600px) {
            font-size: ${responsiveFont(14)};
          }
        }
      }

      h4 {
        text-align: left;
        font-size: ${responsiveFont(10)};
        color: ${(props) => props.theme.colors.secundary};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(12)};
        }
      }
    }
    
    @media (max-width: 680px) {
      margin-bottom: 80px;
    }
`

export const ContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  justify-content: flex-start;
  margin-top: 16px;

    h1 {
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }

    h2 {
      margin-top: 4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.friday};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
`