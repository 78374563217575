import React from 'react';

import PageContainer from '../../../components/Layout/PageContainer';
import PaperContainer from '../../../components/Layout/PaperContainer';
import { AntTab, AntTabs } from '../../../components/Tabs';
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../../hooks/useIsSm';
import { theme } from '../../../styles/theme';
import AdmChallengeTriage from './tabs/triage';
import AdmChallengeAll from './tabs/all';
import AdmChallengeCuration from './tabs/curation';
import HeaderAchievements from '../../../components/HeaderAchievements';
import { RuleFolder } from '@mui/icons-material';

interface IProps {
  tab: number;
  setNewTab: (newTab: number) => void;
  variant?: "fullWidth" | "standard" | "scrollable";
}

const AdmChallengeView: React.FC<IProps> = ({ tab, variant, setNewTab }) => {
  const { t } = useTranslation();
  const isSm = useIsSm()

  return (
    <PageContainer>
      <HeaderAchievements
        title={t('Gerenciamento de Desafios')}
        icon={<RuleFolder style={{ color: isSm ? theme.colors.secundary : theme.colorsBase.gray3 }} />}
        marginBottom={isSm ? 24 : 0}
      />

      <PaperContainer>
        <AntTabs value={tab} onChange={(_, newValue) => setNewTab(newValue)} variant={variant}>
          <AntTab label="todos" sx={{ width: isSm ? '33.33vw' : 'none' }} />
          <AntTab label="triagem" sx={{ width: isSm ? '33.33vw' : 'none' }} />
          <AntTab label="curadoria" sx={{ width: isSm ? '33.33vw' : 'none' }} />
        </AntTabs>

        {tab === 0 && <AdmChallengeAll />}
        {tab === 1 && <AdmChallengeTriage />}
        {tab === 2 && <AdmChallengeCuration />}
      </PaperContainer>
    </PageContainer>
  )
};

export default AdmChallengeView;