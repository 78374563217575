import {
  QueryObserverLoadingErrorResult,
  QueryObserverLoadingResult,
  QueryObserverRefetchErrorResult,
  QueryObserverSuccessResult,
  UseQueryResult
} from '@tanstack/react-query';
import ViewError from '../ViewError';
import ViewLoading from '../ViewLoading';

interface IProps<TData = unknown, TError = unknown> {
  query: UseQueryResult<TData, TError>
  onLoading?: React.FC<QueryObserverLoadingResult<TData, TError>>
  onError?:  React.FC<QueryObserverRefetchErrorResult<TData, TError> | QueryObserverLoadingErrorResult<TData, TError>>
  children?: React.FC<QueryObserverSuccessResult<TData, TError>>
}

const QueryContainer = <TData = unknown, TError = unknown>({ query, onError = ViewError, onLoading = ViewLoading, children }: IProps<TData, TError>) => {
  if (query.isError) return onError?.(query) ?? null;
  if (query.isLoading) return onLoading?.(query) ?? null;
  if (query.isSuccess) return children?.(query) ?? null;

  return onLoading?.(query) ?? null;
}

export default QueryContainer;
