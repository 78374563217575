import React from 'react';
import * as S from './styles'
import { CardGiftcard } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HeaderAchievements from '../../components/HeaderAchievements';
import ViewError from '../../components/ViewError';

const MyGiftsAndProducts: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()


  return (
    <S.Container>
      <HeaderAchievements
        title={t('Brindes/Produtos')}
        onClick={() => navigate(-1)}
        icon={<CardGiftcard className="i" />}
      />

      <S.ContainerContent>
        <S.ContainerPoints>
          <ViewError message={t('Nenhum prêmio encontrado!')} />
        </S.ContainerPoints>
      </S.ContainerContent>
    </S.Container>
  )
}

export default MyGiftsAndProducts
