import React from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { QueryStats } from '@mui/icons-material';
import { WithTranslationProps, withTranslation } from 'react-i18next';
import { format } from 'date-fns';

import QueryContainer from '../../../../components/QueryContainer';
import ModalButton from '../../../../components/ModalButton';
import { IChalengeInScreeningQueryResponse } from '../../../../services/ChallengesManagement/InScreenings/types';
import { useInvalidateChalengeDetails } from '../../../../services/ChallengesManagement/Details';
import { useChalengeInCurationDetails } from '../../../../services/ChallengesCuration/queries/InCurationDetails';
import { useChalengeQuiz } from '../../../../services/ChallengesDefinition/Quizzes';
import { useChalengeCurationRejectionMutation } from '../../../../services/ChallengesCuration/Rejection';
import { useChalengeCurationBlockMutation } from '../../../../services/ChallengesCuration/Block';
import { useChalengeCurationDisapproveMutation } from '../../../../services/ChallengesCuration/Disaprove';
import { useChalengeCurationApproveMutation } from '../../../../services/ChallengesCuration/Approve';
import { useInvalidateChallengeCurationAll } from '../../../../services/ChallengesCuration/queries/All';

import TriageItemModal from '../../../../pages/AdministrativeFlow/AdmChallenge/TriageItemModal';

import DisapproveChallengeModal from './DisapproveChallengeModal';
import BlockChallengeModal from './BlockChallengeModal';
import RejectChallengeModal from './RejectChallengeModal';
import ApproveChallengeModal from './ApproveChallengeModal';

import { ItemContainer } from './styles';


interface IProps extends WithTranslationProps {
  item: IChalengeInScreeningQueryResponse,
  onClose: () => void
}

const ChallengeInspectionDetail: React.FC<IProps> = ({ item, i18n, onClose: onCloseModal }) => {
  const invalidateChallengeCurationAll = useInvalidateChallengeCurationAll()
  const invalidateChalengeDetails = useInvalidateChalengeDetails()

  const chalengeCurationRejectionMutation = useChalengeCurationRejectionMutation()
  const chalengeCurationBlockMutation = useChalengeCurationBlockMutation()
  const chalengeCurationDisapproveMutation = useChalengeCurationDisapproveMutation()
  const chalengeCurationApproveMutation = useChalengeCurationApproveMutation()


  const chalengeDetailsQuery = useChalengeInCurationDetails({ Id: item.Id });
  const chalengeQuizQuery = useChalengeQuiz({ Id: item.Id });

  return (
    <Box width="70vw" maxWidth="900px">
      <QueryContainer query={chalengeDetailsQuery}>
        {({ data: { Data: challengeDetail } }) => (
          <QueryContainer query={chalengeQuizQuery}>
            {({ data: { Data: challengeQuizes } }) => (
              <>
                <Box
                  display="flex"
                  width="70vw"
                  maxWidth="900px"
                  gap={2}
                  sx={({ breakpoints }) => ({
                    [breakpoints.down("md")]: {
                      flexDirection: "column"
                    }
                  })}
                >
                  <Box flex={0.6}>
                    <TriageItemModal
                      challengeDetail={challengeDetail}
                      challengeQuizes={challengeQuizes}
                      hasQuiz={challengeQuizes.length > 0}
                    />
                  </Box>

                  <Paper component={Box} flex={0.4} width="100%" p="8px 16px 0 16px">
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <QueryStats sx={{ color: '#DBDADA' }} />
                      <Typography variant='h6' fontWeight="400">{i18n?.t("Status do Desafio")}</Typography>
                    </Stack>
                    <Stack spacing={1} pt={2}>
                      <ItemContainer>
                        <Typography>{i18n?.t("Curador Responsável")}: {challengeDetail.AuthorName || "N/A"}</Typography>
                      </ItemContainer>

                      <ItemContainer>
                        <Typography>{i18n?.t("Data da Submissão")}: {format(new Date(challengeDetail.LastStatus.SubmissionDate), "dd/MM/yyyy")}</Typography>
                      </ItemContainer>

                      <ItemContainer>
                        <Typography>{i18n?.t("Quantidade de reprovações")}: {challengeDetail.LastStatus.RepprovedCount || "0"}</Typography>
                      </ItemContainer>
                    </Stack>
                  </Paper>
                </Box>

                <Stack width="100%" mt={2} direction="row" spacing={2} justifyContent="end">
                  <ModalButton variant='contained' size='small' color='success' buttonChildren={i18n?.t("APROVAR")}>
                    {({ open, onClose }) => (
                      <ApproveChallengeModal
                        open={open}
                        onClose={onClose}
                        onSubmit={({ showCuratorNameInChallenge }) =>
                          chalengeCurationApproveMutation.mutateAsync(
                            { id: item.Id, shareName: showCuratorNameInChallenge },
                            {
                              onSuccess: () => { onClose(); onCloseModal(); },
                              onSettled: () => {
                                invalidateChallengeCurationAll();
                                invalidateChalengeDetails();
                              }
                            }
                          )
                        }
                        loading={false}
                      />
                    )}
                  </ModalButton>

                  <ModalButton variant='outlined' size='small' color='primary' buttonChildren={i18n?.t("REPROVAR")}>
                    {({ open, onClose }) => (
                      <DisapproveChallengeModal
                        open={open}
                        onClose={onClose}
                        onSubmit={({ reason }) =>
                          chalengeCurationDisapproveMutation.mutateAsync(
                            { id: item.Id, reason },
                            {
                              onSuccess: () => { onClose(); onCloseModal(); },
                              onSettled: () => {
                                invalidateChallengeCurationAll();
                                invalidateChalengeDetails();
                              }
                            }
                          )
                        }
                        loading={false}
                      />
                    )}
                  </ModalButton>
                  <ModalButton variant='outlined' size='small' color='error' buttonChildren={i18n?.t("BLOQUEAR")}>
                    {({ open, onClose }) => (
                      <BlockChallengeModal
                        open={open}
                        onClose={onClose}
                        onSubmit={({ reason }) =>
                          chalengeCurationBlockMutation.mutateAsync(
                            { id: item.Id, reason },
                            {
                              onSuccess: () => { onClose(); onCloseModal(); },
                              onSettled: () => {
                                invalidateChallengeCurationAll();
                                invalidateChalengeDetails();
                              }
                            }
                          )
                        }
                        loading={false}
                      />
                    )}
                  </ModalButton>
                  <ModalButton variant='outlined' size='small' color='info' buttonChildren={i18n?.t("não posso moderar")}>
                    {({ open, onClose }) => (
                      <RejectChallengeModal
                        open={open}
                        onClose={onClose}
                        onSubmit={({ reason }) =>
                          chalengeCurationRejectionMutation.mutateAsync(
                            { id: item.Id, reason },
                            {
                              onSuccess: () => { onClose(); onCloseModal(); },
                              onSettled: () => {
                                invalidateChallengeCurationAll();
                                invalidateChalengeDetails();
                              }
                            }
                          )
                        }
                        loading={false}
                      />
                    )}
                  </ModalButton>
                </Stack>
              </>
            )}
          </QueryContainer>
        )}
      </QueryContainer>
    </Box>
  );
}
export default withTranslation()(ChallengeInspectionDetail);
