import styled from 'styled-components'
import { responsiveFont } from '../../../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
`

export const ContainerChallenge = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;

  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 8px 0px 8px;
    width: 100%;
  }
  
  @media (min-width: 1600px) {
    gap: 40px;
  }
`

export const BoxChallengeAdd = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 350px;
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 8px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  position: relative;
  margin: 0px 8px 24px 8px;
  border: none; 
  padding: 32px 0;
  

  &:hover {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  }

  .iconAdd {
    color: ${(props) => props.theme.colorsBase.white};
    font-size: 24px;
    background-color: ${(props) => props.theme.colors.seventh};
  } 

  .textAdd {
    margin-top: 8px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 400;
    font-family: 'Barlow', Regular;
  }

  @media (min-width: 1600px) {
    width: 390px;
  }

  @media (max-width: 680px) {
    height: auto;
    margin: 0px auto 24px auto;
  }
`

