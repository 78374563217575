import React, { useEffect, useState, } from 'react';
import { ButtonProps } from './types'
import { Tooltip } from '@mui/material'
import { Notifications, OpenInNew, Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next';
import { theme } from '../../styles/theme';
import * as S from './styles'
import { getNotifications, postNotificationRead } from '../../services/Notifications';
import ToastNotification from '../../components/ToastNotification';
import { INotification } from '../../services/Notifications/types';
import { useNavigate } from 'react-router-dom';
import ViewLoading from '../../components/ViewLoading';
import Modal from '@mui/material/Modal'
import { useIsSm } from '../../hooks/useIsSm';

const ModalNotifications: React.FC<ButtonProps> = (props) => {
  const { colorWhite, colorPrimary } = props
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [notifications, setNotifications] = useState<INotification[]>([])
  const isSm = useIsSm()

  useEffect(() => {
    retrieveNotifications();
    resetCountDown();
  }, [open]);

  const [quantityOfNotifications, setQuantityOfNotifications] = useState(0)
  const [time, setTime] = useState(0);
  /* eslint-disable @typescript-eslint/no-explicit-any */
  let countdownTimeOut: any;

  useEffect(() => {
    if (time > 0) {
      countdownTimeOut = setTimeout(() => {
        setTime((state) => state - 1);
      }, 1000);
    } else if (time <= 0) {
      updateNotificationCounter();
      retrieveNotifications();
      resetCountDown();
    }
  }, [time]);

  const resetCountDown = () => {
    clearTimeout(countdownTimeOut);
    setTime(100);
  };

  const updateNotificationCounter = async () => {
    try {
      const bodyDataUser = {
        Visualizadas: 'false',
        PageNumber: 1,
        PageSize: 9999,
      };
      const response = await getNotifications(bodyDataUser);
      if (response.Success) {
        setQuantityOfNotifications(response.Data.length)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar as notificações, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar as notificações, tente novamente.')
      });
    }
  };

  const retrieveNotifications = async () => {
    setLoading(true)
    try {
      updateNotificationCounter();

      setNotifications([])
      const bodyDataUser = {
        Visualizadas: 'false',
        PageNumber: 1,
        PageSize: 9999,
      };
      const response = await getNotifications(bodyDataUser);
      if (response.Success) {
        let count = 0;
        const data: INotification[] = [];

        response.Data.forEach((item) => {
          if (count < 3) {
            data.push(item)
          }
          count = count + 1;
        })
        setNotifications(data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar as notificações, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar as notificações, tente novamente.')
      });
    }
    setLoading(false)
  };

  const notificationRead = async (id: string) => {
    try {
      const response = await postNotificationRead(id);
      if (response.Success) {
        retrieveNotifications();
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao visualizar as notificações, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao visualizar as notificações, tente novamente.')
      });
    }
  };

  const selectColorB = () => {
    return colorWhite ? theme.colorsBase.white : theme.colors.black;
  };

  const selectColor = () => {
    return colorPrimary ? theme.colors.secundary : selectColorB();
  };

  const renderTitle = (type: number) => ({
    1: t("Novo desafio para análise"),
    2: t("Novo desafio para triagem"),
    3: t("Novo desafio para curadoria"),
    4: t("Seu desafio foi aprovado"),
    5: t("Sugestão de edição para desafio"),
    6: t("Seu desafio foi bloqueado!"),
    7: t("Novo desafio para curadoria"),
    8: t("Convite recebido!"),
    9: t("Convite aceito!"),
    10: t("Convite Recusado"),
    11: t("O seu convidado aderiu a plataforma"),
    12: t("Você ganhou pontos!"),
  }[type])

  const renderDescription = (item: INotification) => {
    if (item.Type === 1) return item.Data?.ChallengeTitle
    if (item.Type === 2) return item.Data?.ChallengeTitle
    if (item.Type === 3) return item.Data?.ChallengeTitle
    if (item.Type === 4) return item.Data?.ChallengeTitle
    if (item.Type === 5) return item.Data?.ChallengeTitle
    if (item.Type === 6) return item.Data?.ChallengeTitle
    if (item.Type === 7) return item.Data?.ChallengeTitle
    if (item.Type === 8) return item.Data?.Name
    if (item.Type === 9) return item.Data?.Name
    if (item.Type === 10) return item.Data?.Name
    if (item.Type === 11) return item.Data?.Name
    if (item.Type === 12) return item.Data?.Name
    return ''
  };

  const renderClick = (item: INotification) => {
    if (item.Type === 1) return navigate('/desafios')
    if (item.Type === 2) return navigate('/administracao/desafios')
    if (item.Type === 3) return navigate('/inspection')
    if (item.Type === 4) return navigate('/desafios')
    if (item.Type === 5) return navigate('/desafios')
    if (item.Type === 6) return navigate('/desafios')
    if (item.Type === 7) return navigate('/inspection')
    if (item.Type === 8) return navigate('/contatos')
    if (item.Type === 9) return navigate('/contatos')
    if (item.Type === 10) return navigate('/contatos')
    if (item.Type === 11) return navigate('/contatos')
    if (item.Type === 12) return navigate('/minhas_conquistas')
    return ''
  };

  return (
    <S.Container>
      <S.Select white={colorWhite} onClick={() => {
        if (!isSm) {
          setOpen(!open)
          retrieveNotifications();
        } else {
          navigate('/notificacoes');
        }
      }}>
        <Tooltip title={t('Notificações')}>
          <Notifications className="icon" style={{ color: selectColor() }} />
        </Tooltip>

        {quantityOfNotifications > 0 && (
          <S.CircleCount>
            <h1>{quantityOfNotifications > 9 ? '9+' : quantityOfNotifications}</h1>
          </S.CircleCount>
        )}
      </S.Select>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <S.Options white={colorWhite}>
          {loading ? (
            <ViewLoading />
          ) : (
            <>
              {notifications.map((item) => (
                <S.Option key={item.Id} white={colorWhite} onClick={() => { }}>
                  <Notifications className="flag" />
                  <div className='boxText'>
                    <button className='buttonh5' onClick={() => {
                      renderClick(item)
                      setOpen(false)
                    }}>{renderTitle(item.Type)}</button>
                    <h3>{renderDescription(item)}</h3>
                  </div>

                  <button className='boxIcon' onClick={() => notificationRead(item.Id)}>
                    <Close className="icon" />
                  </button>
                </S.Option>
              ))}
              {notifications.length <= 0 && <h1> {t('0 notificações não lida(s)')}</h1>}
            </>
          )}

          <div className='containerViewMore'>
            <button className='buttonViewMore' onClick={() => {
              navigate('/notificacoes')
              setOpen(false)
            }}>
              <h5>{t('Ver todas')}</h5>
              <OpenInNew className="icon" />
            </button>
          </div>
        </S.Options>
      </Modal>

    </S.Container>
  )
}

export default ModalNotifications
