import styled from 'styled-components'
import { responsiveFont } from '../../../utils/UtilsGlobal'

export const ContainerDetails = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 400px; 

  .containerBoxImageProduct {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .boxTextInfoB {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 16px 0px;
      
      .boxTextInfoTopB {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 8px 0px;
      }

      h3 {
        margin-left: 8px;
        text-align: left;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colors.friday};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) { 
          font-size: ${responsiveFont(16)};
        }
      }
    }
  }

  .boxImageProduct {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: solid 1px ${(props) => props.theme.colorsBase.gray3};
    border-radius: 8px;
    padding: 12px 8px;

    .boxTileImage {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;

      .boxIconImage {
        color: ${(props) => props.theme.colorsBase.gray3};
        font-size: 20px;
      }

      h5 {
        margin-left: 8px;
        text-align: left;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colorsBase.black5};
        font-weight: 700;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(16)};
        }
      }
    }

    .boxImageAndText {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    }
  }

  .boxInfoProduct {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px ${(props) => props.theme.colorsBase.gray3};
    border-radius: 4px;
    padding: 16px;
    margin-left: 16px;

    .boxTextInfo {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 8px 0px;
      
      .boxTextInfoTop {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        background: transparent;
        border: none;
      }

      .boxIconInfo {
        color: ${(props) => props.theme.colors.friday};
        font-size: 22px;
        width:  22px;
        height: 22px;
      }

      h3 {
        margin-left: 8px;
        text-align: left;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colors.friday};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) { 
          font-size: ${responsiveFont(16)};
        }
      }

      .score {
        margin-top: auto;
        margin-bottom: 2px;
        margin-left:  4px;
        text-align: left;
        font-size: ${responsiveFont(8)};
        color: ${(props) => props.theme.colors.friday};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) { 
          font-size: ${responsiveFont(10)};
        }
      }

      .textDescription {
        margin-top: 2px;
        margin-left: 32px;
        text-align: left;
        font-size: ${responsiveFont(10)};
        color: ${(props) => props.theme.colors.friday};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) { 
          font-size: ${responsiveFont(12)};
        }
      }
    }
    
  }

  .boxButtonsDetails {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 16px 0px;
  }

  @media (max-width: 680px) {
    flex-direction: column;
    height: 100%;

    .containerBoxImageProduct {
      width: 100%;

      .boxTextInfoB {
        height: 100%;
        display: flex;
        padding: 0px;

        .boxTextInfoTopB {
          margin: 2px 0px;
        }
      }
    }

    .boxInfoProduct {
      width: 100%;
      margin-left: 0px;
      margin-top: 8px;
    }

    .boxImageProduct {
      align-items: center;
      justify-content: center;
    }

 
  }

  @media (min-width: 1600px) {
    height: ${(props) => (props.active ? '500px' : '400px')}; 
  }
`

export const Image = styled.img`
  width: 100%;
  border-radius: 8px;
  object-fit: cover; 

  @media (max-width: 680px) {
    width: 40%;
  }
`