import React, { useEffect, useState } from 'react';
import ToastNotification from '../../../components/ToastNotification';
import ViewLoading from '../../../components/ViewLoading';
import ViewError from '../../../components/ViewError';
import { Pagination } from '@mui/material'
import { useIsSm } from '../../../hooks/useIsSm';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../components/SearchBar';
import { Container, Pages } from '../../../pages/TermsOfUse/ScreenTermsOfUse/styles';
import { getAutoCompleteProducts, getProductsAvailable, getProductsExchanged, postExchangePointsForProducts, } from '../../../services/Product';
import { IProductUser } from '../../../services/Product/types';
import { ContainerTable } from '../../../pages/UserManagement/Users/styles';
import * as S from './styles'
import CardAchievements from '../../../components/CardAchievements';
import { getWallet } from '../../../services/Challenges';
import { useNavigate } from 'react-router-dom';
import CardProduct from '../CardProduct';
import ModalCustomBottom from '../../../components/ModalCustomBottom';
import ModalCustom from '../../../components/ModalCustom';
import { Cached } from '@mui/icons-material';
import { theme } from '../../../styles/theme';
import ViewExchangePointsForProducts from '../ViewExchangePointsForProducts';

interface IProps {
  tab: number
}

const ProductViewAvailable: React.FC<IProps> = ({ tab = 1 }) => {
  const { t } = useTranslation();
  const [usersList, setUsersList] = useState<IProductUser[]>([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState<number | undefined>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [value, setValue] = useState('');
  const isSm = useIsSm()
  const [pointsBalance, setPointsBalance] = useState(0)
  const navigate = useNavigate()
  const [openExchangePointsForProductsModal, setOpenExchangePointsForProductsModal] = useState(false)
  const [loadingExchangePointsForProducts, setLoadingExchangePointsForProducts] = useState(false)
  const [itemSelected, setItemSelected] = useState<IProductUser>()
  const [openCancelModal, setOpenCancelModal] = useState(false)
  const [openSavedModal, setOpenSavedModal] = useState(false)
  const [contentQuantidade, setContentQuantidade] = useState(true)
  const [valueCount, setValueCount] = useState(1)
  const [checkOut, setCheckOut] = useState(false)
  const [autoCompleteList, setAutoCompleteList] = useState<string[]>([])

  useEffect(() => {
    retrievePoints();
    retrieveProductsAvailable(1, true);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > (300 * currentPage)) {
        if ((currentPage <= (totalPages || 0)) && isSm) {
          retrieveProductsAvailable(currentPage + 1, false)
        }
      }
    });
  }, []);

  const retrieveProductsAvailable = async (page: number = 1, clear: boolean = false, search?: string) => {
    setLoading(true)
    try {
      if (clear) {
        setUsersList([]);
      }
      const bodyData = {
        PageNumber: page,
        PageSize: isSm ? 32 : 4,
        Nome: search ?? value
      };

      let response;

      if (tab === 1) {
        response = await getProductsAvailable(bodyData);
      } else {
        response = await getProductsExchanged(bodyData);
      }

      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }
        if (clear) {
          setUsersList(response.Data);
        } else if (response.Data.length > 1) {
          const novaArray = usersList.concat(response.Data);
          setUsersList(novaArray);
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os produtos, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os produtos, tente novamente.')
      });
    }
    setLoading(false)
  };

  const onEnterSearch = (event: { key: string }) => {
    if (event.key === 'Enter') {
      retrieveProductsAvailable(1, true);
      setAutoCompleteList([])
    }
  };

  useEffect(() => {
    if (value.length > 2) {
      getAutoComplete()
    } else {
      setAutoCompleteList([])
    }
  }, [value]);

  const retrievePoints = async () => {
    setLoading(true)
    try {
      const response = await getWallet();
      if (response.Success) {
        setPointsBalance(response.Data.Balance)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
    setLoading(false)
  };

  const handleExchangePointsForProducts = async () => {
    setLoadingExchangePointsForProducts(true);
    try {
      const bodyData = {
        ProductId: itemSelected?.Id ?? '',
        Quantity: valueCount
      };
      const response = await postExchangePointsForProducts(bodyData);
      if (response.Success) {
        retrievePoints();
        retrieveProductsAvailable(1, true);
        clearData()
        setOpenSavedModal(true)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao realizar o pedido, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao realizar o pedido, tente novamente.')
      });
    }
    setLoadingExchangePointsForProducts(false);
  };

  const getAutoComplete = async () => {
    try {
      setAutoCompleteList([])
      const response = await getAutoCompleteProducts(value);
      if (response.Success) {
        if (response.Data[0] !== value) {
          setAutoCompleteList(response.Data)
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.')
      });
    }
  };

  const clearData = () => {
    setOpenCancelModal(false)
    setOpenExchangePointsForProductsModal(false)
    setContentQuantidade(true)
    setValueCount(1)
    setCheckOut(false)
  };

  return (
    <Container>
      <ContainerTable>
        {!isSm && <S.TextTopPage>{tab === 1 ? t('Aqui você encontra os produtos disponíveis, que você pode resgatar utilizando seus pontos!') : t('Aqui você encontra os produtos que você já trocou!')}</S.TextTopPage>}
        <div className='containerButton'>
          <SearchBar
            value={value}
            setValue={(e) => setValue(e)}
            onEnterSearch={onEnterSearch}
            placeholder={t('Busque o produto desejado')}
            filterButton={false}
            style={{ padding: 0, width: '100%' }}
            buttonSearch
            onClearSearch={() => {
              setValue('')
              retrieveProductsAvailable(1, true, '');
            }}
            autoCompleteList={autoCompleteList}
          />

          {!isSm && <CardAchievements style={{ width: '30%', marginTop: '-80px', marginBottom: '24px' }} onClick={() => navigate('/minhas_conquistas/extract')} extractButton title={t('Meu Saldo Atual')} description={t('Total de pontos atual:')} value={String(pointsBalance)} />}
        </div>

        {loading ? (
          <ViewLoading />
        ) : (
          <>
            {usersList.length > 0 ? (
              <>
                <S.ContainerCards>
                  {usersList.map((item) => (
                    <CardProduct
                      key={item.Id}
                      item={item}
                      type={tab}
                      pointsBalance={pointsBalance} onClick={() => {
                        setItemSelected(item)
                        setOpenExchangePointsForProductsModal(true)
                      }}
                    />
                  ))}
                </S.ContainerCards>

                {!isSm && <Pages>
                  <Pagination
                    showFirstButton
                    showLastButton
                    count={totalPages}
                    page={currentPage}
                    color="primary"
                    onChange={(e, p) => {
                      e.preventDefault()
                      e.stopPropagation()
                      retrieveProductsAvailable(p, true)
                    }}
                  />
                </Pages>}
              </>
            ) : (
              <ViewError message={tab === 1 ? t('Nenhum produto disponível') : t('Nenhum produto trocado')} />
            )}
          </>
        )}
      </ContainerTable>

      {isSm ?
        <ModalCustomBottom
          open={openExchangePointsForProductsModal}
          onClose={() => setOpenCancelModal(true)}
          onClick={handleExchangePointsForProducts}
          loading={loadingExchangePointsForProducts}
          title={t('Trocar Pontos por Produto')}
          showIcon
          buttonClose
          buttonsEnabled={false}
          icon={<Cached style={{ fontSize: '24px', color: theme.colorsBase.gray3, backgroundColor: theme.colorsBase.white }} />}
          childrenContent={<ViewExchangePointsForProducts
            item={itemSelected}
            onClick={handleExchangePointsForProducts}
            onClickCancel={() => setOpenCancelModal(true)}
            pointsBalance={pointsBalance}
            maximumQuantityOfProducts={itemSelected?.LimitPerUser}
            setContentQuantidade={setContentQuantidade}
            contentQuantidade={contentQuantidade}
            setValue={setValueCount}
            value={valueCount}
            setCheckOut={setCheckOut}
            checkOut={checkOut}
          />}
        />
        :
        <ModalCustom
          open={openExchangePointsForProductsModal}
          onClose={() => setOpenCancelModal(true)}
          onClick={handleExchangePointsForProducts}
          loading={loadingExchangePointsForProducts}
          title={t('Trocar Pontos por Produto')}
          buttonsEnabled={false}
          largeTitle
          iconHeader={<Cached style={{ fontSize: '24px', color: theme.colorsBase.gray3, backgroundColor: theme.colorsBase.white }} />}
          styleLine={{ backgroundColor: 'transparent', marginBottom: '24px' }}
          childrenContent={<ViewExchangePointsForProducts
            item={itemSelected}
            onClick={handleExchangePointsForProducts}
            onClickCancel={() => setOpenCancelModal(true)}
            pointsBalance={pointsBalance}
            maximumQuantityOfProducts={itemSelected?.LimitPerUser}
            setContentQuantidade={setContentQuantidade}
            contentQuantidade={contentQuantidade}
            setValue={setValueCount}
            value={valueCount}
            setCheckOut={setCheckOut}
            checkOut={checkOut}
          />}
        />
      }

      {isSm ?
        <ModalCustomBottom
          open={openCancelModal}
          onClose={() => setOpenCancelModal(false)}
          onClick={() => clearData()}
          loading={loadingExchangePointsForProducts}
          title={t('Cancelar Troca')}
          confirmationButtonText={t('CANCELAR')}
          textDeclineButton={t('VOLTAR')}
          showIcon={false}
          buttonClose
          buttonsEnabled
          styleButtons={{ justifyContent: 'center' }}
          redButtonRight
          description={t('Deseja cancelar a troca de pontos? Nenhum ponto será gasto, e nenhuma informação da troca será salva.')}
        />
        :
        <ModalCustom
          open={openCancelModal}
          onClose={() => setOpenCancelModal(false)}
          onClick={() => clearData()}
          loading={loadingExchangePointsForProducts}
          title={t('Cancelar Troca')}
          styleButtons={{ justifyContent: 'center' }}
          confirmationButtonText={t('CANCELAR')}
          textDeclineButton={t('VOLTAR')}
          redButtonRight
          shortModal
          description={t('Deseja cancelar a troca de pontos? Nenhum ponto será gasto, e nenhuma informação da troca será salva.')}
        />
      }

      {isSm ?
        <ModalCustomBottom
          open={openSavedModal}
          onClose={() => setOpenSavedModal(false)}
          onClick={() => setOpenSavedModal(false)}
          loading={loadingExchangePointsForProducts}
          title={t('Troca Confirmada')}
          textDeclineButton={t('VOLTAR')}
          showIcon={false}
          buttonClose
          buttonsEnabled
          buttonRight={false}
          styleButtons={{ justifyContent: 'center' }}
          description={t('Pontos trocados! A equipe da Curar Saúde entrará em contato através do seu e-mail de cadastro para mais instruções e informações. Fique atento à sua caixa de entrada!')}
        />
        :
        <ModalCustom
          open={openSavedModal}
          onClose={() => setOpenSavedModal(false)}
          onClick={() => setOpenSavedModal(false)}
          loading={loadingExchangePointsForProducts}
          title={t('Troca Confirmada')}
          styleButtons={{ justifyContent: 'center' }}
          textDeclineButton={t('VOLTAR')}
          buttonRight={false}
          shortModal
          description={t('Pontos trocados! A equipe da Curar Saúde entrará em contato através do seu e-mail de cadastro para mais instruções e informações. Fique atento à sua caixa de entrada!')}
        />
      }
    </Container >
  )
}

export default ProductViewAvailable
