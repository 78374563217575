import { useQuery } from "@tanstack/react-query";

import { sendRequest } from "../../../RequestService";
import { IChallengeCurationInCurationDetailQuery, IChallengeCurationInCurationDetailQueryResponse } from "./types";

const defaultRoot = "challenges-curations"

export const useChalengeInCurationDetails = (params: IChallengeCurationInCurationDetailQuery) => useQuery({
  queryKey: ["useChalengeInCurationDetails", params],
  queryFn: async () => {
    const url = `${defaultRoot}/in-curations/${params.Id}/details`

    const response = await sendRequest<IChallengeCurationInCurationDetailQueryResponse>(url, 'GET')

    if (!response.Success) {
      throw new Error(response.Message)
    }

    return response
  }
})
