import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: -48px;
  margin-bottom: 24px;

  @media (max-width: 680px) {
    margin-top: -8px;
    margin-bottom: 36px;
  }
`

export const ContainerBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  margin-bottom: -24px;
`

export const ContainerQuestionCount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  align-self: center;
  bottom: 30px;
  width: 100%;
  z-index: -1;

  @media (max-width: 680px) {
    z-index: 1;
    bottom: 0px;
    position: relative;
    margin-bottom: 0px;
    // margin-top: 40px;
  }
`

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;

  h2 {
    line-height: 16px;
    text-align: center;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black2};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(10)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  h1 {
    text-align: center;
    font-size: ${responsiveFont(34)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    margin-bottom: 16px;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(20)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(36)};
    }
  }

  .boxContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  @media (max-width: 680px) {
    margin-bottom: 0px;

    .boxContent {
      margin-top: 16px;
      margin-bottom: 0px;
      padding: 0px 24px;
      height: 100%;
    }
  }
`

export const CardCarroselA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px 5%;
  
  .imgCard {
    width: 40%;
    object-fit: cover;
  }

  h2 {
    line-height: 18px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.black2};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(12)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  h1 {
    text-align: center;
    font-size: ${responsiveFont(20)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    margin-bottom: 16px;
    
    @media (max-width: 680px) {
      font-size: ${responsiveFont(18)};
    }

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(22)};
    }
  }

  @media (max-width: 680px) {
    width: 90%;
    height: 100%;
    margin: 0px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    padding: 24px 16px;
    border-radius: 16px;
  }
`

export const CardCarroselB = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px 5%;
  
  .imgCardB {
    width: 100%;
    object-fit: cover;
  }

  h2 {
    line-height: 12px;
    text-align: center;
    font-size: ${responsiveFont(10)};
    color: ${(props) => props.theme.colors.black2};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(12)};
    }
  }

  h1 {
    width: 100%;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    margin-bottom: 16px;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
    width: 90%;
    height: 100%;
    margin: 0px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    padding: 24px 16px;
    border-radius: 16px;
  }
`