import React, { useState } from 'react'

import { Edit } from '@mui/icons-material'
import { Tooltip, Switch } from '@mui/material'
import { emptyItem, ISettings, ITableSettings } from './types'
import { useTranslation } from 'react-i18next'
import ModalCustom from '../ModalCustom'
import ToastNotification from '../ToastNotification'
import { editSettings } from '../../services/Settings'
import Input from '../../components/Input';
import { Icons, Table } from '../../pages/UserManagement/TableUsers/styles'
import * as S from './styles'

const TableSettings: React.FC<ITableSettings> = (props: ITableSettings) => {
  const { t } = useTranslation();
  const { listUsers, updateList } = props
  const [itemSelected, setItemSelected] = useState<ISettings>(emptyItem)
  const [loading, setLoading] = useState(false)
  const [openEditSettingsModal, setOpenEditSettingsModal] = useState(false)
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [status, setStatus] = useState(false);

  const editParameter = async () => {
    setLoading(true)
    try {
      if (itemSelected.DataType !== 2 && name.length < 1) {
        setNameError(t('Campo obrigatório') || "Campo obrigatório")
        setLoading(false);
        return;
      }

      let value: string | number | boolean;

      if (itemSelected.DataType === 1) {
        value = name;
      } else if (itemSelected.DataType === 2) {
        value = status;
      } else {
        value = Number(name);
      }

      const response = await editSettings(value, itemSelected.Id);
      if (response.Success) {
        updateList();
        setOpenEditSettingsModal(false)
        setName('')
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Parâmetro editado com sucesso!')
        });
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao editar o parâmetro, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao editar o parâmetro, tente novamente.')
      });
    }
    setLoading(false)
  };

  const renderTable = () => {
    return (
      <Table style={{ marginTop: '8px' }}>
        <table>
          <tr className="header">
            <td style={{ paddingLeft: 16 }}>{t('Parâmetro')}</td>
            <td>{t('Valor')}</td>
            <td>{t('Ações')}</td>
          </tr>
          {listUsers.map((item) => (
            <tr key={item?.Id}>
              <td style={{ paddingLeft: 16 }}>{item?.Name}</td>
              <td>{item?.Value}</td>
              <td style={{ width: '20px' }}>
                <Icons>
                  <button onClick={() => {
                    setItemSelected(item)
                    setOpenEditSettingsModal(true)
                    if (item.DataType === 2) {
                      setStatus(item.Value === 'true' ? true : false)
                    } else {
                      setName(item.Value)
                    }
                  }
                  }>
                    <Tooltip title={t('Editar')}>
                      <Edit className="i" />
                    </Tooltip>
                  </button>
                </Icons>
              </td>
            </tr>
          ))}
        </table>

        <ModalCustom
          open={openEditSettingsModal}
          onClose={() => setOpenEditSettingsModal(false)}
          onClick={editParameter}
          childrenContent={
            <S.ContainerEditCategories>
              <div className='InputContainer'>
                <h1>{t('Parâmetro')}</h1>
                <h2>{itemSelected?.Name}</h2>
              </div>

              {itemSelected.DataType === 2 ? (
                <div className='switchDiv'>
                  <Switch
                    checked={status}
                    onChange={(event) => setStatus(event.target.checked)}
                    color='info'
                  />
                  <h5>{status ? t('Sim') : t('Não')}</h5>
                </div>
              ) : (
                <Input
                  label={t('Valor')}
                  type={itemSelected.DataType === 3 ? 'number' : 'value'}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  size="small"
                  fullWidth
                  error={nameError ? true : false}
                  helperText={nameError}
                  onClick={() => setNameError('')}
                  variant="filled"
                  style={{ width: '100%', marginBottom: '16px' }}
                />
              )}
            </S.ContainerEditCategories>
          }
          loading={loading}
          title={t('Editar Parâmetro')}
          confirmationButtonText={t('SALVAR')}
          textDeclineButton={t('CANCELAR')}
        />
      </Table>
    )
  }

  return renderTable()
}

export default TableSettings
