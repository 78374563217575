import React, { useEffect } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';

import ModalCustom from '../../../../components/ModalCustom';

interface DisapproveChallenge {
  reason: string
}

interface IProps {
  open: boolean;
  onClose: () => void;
  onSubmit: SubmitHandler<DisapproveChallenge>;
  loading?: boolean;
}

const DisapproveChallengeModal: React.FC<IProps> = ({ open, loading, onClose, onSubmit }) => {
  const { t } = useTranslation()

  const { register, handleSubmit, reset } = useForm<DisapproveChallenge>({
    defaultValues: { reason: "" }
  });

  useEffect(() => reset(), [open])

  return (
    <ModalCustom
      open={open}
      onClick={handleSubmit((data) => { onSubmit(data); onClose(); })}
      onClose={onClose}
      buttonsEnabled={true}
      title={t("Rejeitar desafio")}
      childrenContent={(
        <Stack width="100%" spacing={2}>
          <Stack>
            <Typography variant='h6'>{t("Motivo da rejeição")}</Typography>
            <Typography variant='caption'>
              {t("Insira o local onde foram identificados motivos para a reprovação do desafio, e descreva o motivo e sugestões de alterações.")}
            </Typography>
          </Stack>

          <TextField
            multiline
            fullWidth
            label={t('Motivo')}
            size='small'
            rows={6}
            {...register('reason')}
          />
        </Stack>
      )}
      loading={loading}
      confirmationButtonText={t("ENVIAR")}
      textDeclineButton={t("CANCELAR")}
    />
  )
}

export default DisapproveChallengeModal;
