import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { postAcceptModeration, postBlockModeration, postRefuseModeration, postRejectModeration } from '../../services/Moderation';
import { useChallengesInModeration } from '../../hooks/moderation/useChallengesInModeration';
import {
  IPostAcceptModerationCommand,
  IPostBlockModerationCommand,
  IPostRefuseModerationCommand,
  IPostRejectModerationCommand
} from '../../services/Moderation/types';
import ToastNotification from '../../components/ToastNotification';

import ChallengeInspectionView from './view';

const ChallengeInspection: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams();
  const challengeId = id ? parseInt(id, 10) : undefined

  const baseQuery = useChallengesInModeration({ Id: challengeId })
  const query = useQuery(
    {
      queryKey: [baseQuery.data],
      queryFn: async () => {
        const item = baseQuery.data?.Data.at(0);
        if (!item) throw new Error();
        return item;
      },
      enabled: !!baseQuery.data
    }
  )

  const approveMutation = useMutation({
    mutationFn: async (data: Omit<IPostAcceptModerationCommand, "DesafioId">) => {
      if (!challengeId) throw new Error();
      const response = await postAcceptModeration({ DesafioId: challengeId, ...data })

      if (!response.Success) throw new Error();

      return response;

    },
    onSuccess: () => navigate(-1),
    onError: () => ToastNotification({
      id: 'error',
      type: 'error',
      message: t('Houve um erro ao aprovar o desafio, tente novamente.')
    })
  })

  const rejectMutation = useMutation({
    mutationFn: async (data: Omit<IPostBlockModerationCommand, "DesafioId">) => {
      if (!challengeId) throw new Error();
      const response = await postBlockModeration({ DesafioId: challengeId, ...data })

      if (!response.Success) throw new Error();

      return response;
    },
    onSuccess: () => navigate(-1),
    onError: () => ToastNotification({
      id: 'error',
      type: 'error',
      message: t('Houve um erro ao reprovar o desafio, tente novamente.')
    })
  })

  const requestChangesMutation = useMutation({
    mutationFn: async (data: Omit<IPostRejectModerationCommand, "DesafioId">) => {
      if (!challengeId) throw new Error();
      const response = await postRejectModeration({ DesafioId: challengeId, ...data })

      if (!response.Success) throw new Error();

      return response;
    },
    onSuccess: () => navigate(-1),
    onError: () => ToastNotification({
      id: 'error',
      type: 'error',
      message: t('Houve um erro ao sugerir mudanças no desafio, tente novamente.')
    })
  })

  const cantModerateMutation = useMutation({
    mutationFn: async (data: Omit<IPostRefuseModerationCommand, "DesafioId">) => {
      if (!challengeId) throw new Error();
      const response = await postRefuseModeration({ DesafioId: challengeId, ...data })

      if (!response.Success) throw new Error();

      return response;
    },
    onSuccess: () => navigate(-1),
    onError: () => ToastNotification({
      id: 'error',
      type: 'error',
      message: t('Houve um erro ao informar que não pode moderar o desafio, tente novamente.')
    })
  })

  return (
    <ChallengeInspectionView
      query={query}
      approveMutation={approveMutation}
      rejectChallengeMutation={rejectMutation}
      requestChangesChallengeMutation={requestChangesMutation}
      cantModerateMutation={cantModerateMutation}
    />
  );
}

export default ChallengeInspection;
