import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const ContainerChallengeDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;

  @media (max-width: 680px) {
    flex-direction: column;
  }
`

export const BoxGraphic = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0px 8px;
  border-left: solid 1px ${(props) => props.theme.colors.ten};

  @media (max-width: 680px) {
    border: none;
    padding: 0px;
  }

  .titleGraphic {
    line-height: 18px;
    letter-spacing: 0.1px;
    text-align: center;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(17)};
    }
  }

  .message1 {
    margin-top: 8px;
    line-height: 18px;
    letter-spacing: 0.1px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  .message2 {
    margin-top: 4px;
    line-height: 14px;
    letter-spacing: 0.1px;
    text-align: center;
    font-size: ${responsiveFont(10)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(12)};
    }
  }

  .boxGraphic {
    margin-top: 32px;
    width: 60%;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .percentageGraph {
      line-height: 24px;
      letter-spacing: 0.1px;
      text-align: center;
      font-size: ${responsiveFont(20)};
      font-weight: 400;
      font-family: 'Barlow', Regular;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(22)};
      }
    }

    .boxtRightAnswer {
      margin-top: 4px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;

      .iconB {
        color: ${(props) => props.theme.colors.black};
        font-size: 16px;
      }

      .circle {
        border-radius: 50%;
        width: 14px;
        height: 14px;
      }

      .titleRightAnswer {
        margin-left: 4px;
        line-height: 18px;
        letter-spacing: 0.1px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }

  }
`

export const BoxDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0px 8px 8px 8px;
  
  .titleField {
    line-height: 14px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.fourth};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .textField {
    margin-top: 4px;
    line-height: 18px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }

  .containerContent {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;
  }
`

export const BoxContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  h5 {
    font-weight: 400;
    letter-spacing: 0.71px;
    margin-left: 4px;
    color: ${(props) => props.theme.colors.friday};
    font-size: ${responsiveFont(12)};
    font-family: 'Barlow', Regular;
  }                       

  .i {
    width: 16px;
    object-fit: cover;
    color: ${(props) => props.theme.colorsBase.black};
  }
`

export const ButtonBottom = styled.button<{ green?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin 0 auto;
  margin-top: 24px;
  border: none;
  background: transparent;

  h5 {
    font-weight: 400;
    margin-left: 8px;
    margin-right: 8px;
    color: ${(props) => (props.green ? props.theme.colorsBase.green2 : props.theme.colors.seventh)}; 
    font-size: ${responsiveFont(12)};
    font-family: 'Barlow', Regular;
  }                       

  .iLeft {
    width: 16px;
    object-fit: cover;
    color: ${(props) => (props.green ? props.theme.colorsBase.green2 : props.theme.colors.seventh)}; 
  }

  .iRight {
    width: 12px;
    object-fit: cover;
    color: ${(props) => (props.green ? props.theme.colorsBase.green2 : props.theme.colors.seventh)}; 
  }
`