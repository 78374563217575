import { useMutation } from "@tanstack/react-query";

import { sendRequest } from "../../RequestService";

import { IChallengesCurationBlockCommand, IChallengesCurationBlockCommandResponse } from "./types";

const defaultRoot = "challenges-curations/blocked"

/**
 * Bloquear desafio
 * @returns 
 */
export const useChalengeCurationBlockMutation = () => useMutation({
  mutationFn: async (command: IChallengesCurationBlockCommand) => {
    const url = `${defaultRoot}/${command.id}`

    return await sendRequest<IChallengesCurationBlockCommandResponse>(url, 'POST', command.reason)
  }
})
