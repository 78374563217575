import React, { useEffect, useState, } from 'react';
import * as S from './styles'
import { Person, EmojiEvents, School, KeyboardArrowDown, KeyboardArrowUp, Cancel, CheckCircle, BarChart, Stars, CardGiftcard, OpenInNew, ArrowForward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ToastNotification from '../../components/ToastNotification';
import ViewLoading from '../../components/ViewLoading';
import { IChallengeExecution } from '../../services/Challenges/types';
import { postStartExecution } from '../../services/Challenges';
import { useAuth } from '../../contexts/Auth';
import BoxAssessment from '../../components/BoxAssessment';
import trophy from '../../assets/images/trophy.png'
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import ChallengeHeader from '../../components/ChallengeHeader';
import { useIsSm } from '../../hooks/useIsSm';

const ChallengeConcluded: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { token, token2 } = useParams();
  const [challengeConcluded, setChallengeConcluded] = useState<IChallengeExecution>()
  const [loading, setLoading] = useState(false)
  const [contentQuestions, setContentQuestions] = useState(false)
  const { challenge, listContents, setListContents } = useAuth();
  const isSm = useIsSm()

  useEffect(() => {
    startExecution();
  }, []);

  const startExecution = async () => {
    setLoading(true)
    try {
      const response = await postStartExecution(token ?? '');
      if (response.Success) {
        setChallengeConcluded(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os dados, tente novamente.'),
      });
    }
    setLoading(false)
  };

  const handleValidateNextContent = () => {
    if (listContents[0]) {
      return true;
    } else {
      return false;
    }
  };


  const handleNextContent = () => {
    navigate(`/desafio/${listContents[0]}/PE`);
    setListContents(listContents.slice(1))
  };

  return (
    <S.Container>
      <ChallengeHeader
        title={String(token2 === 'PE' ? t('Você concluiu o Conteúdo!') : t('Você concluiu o desafio!'))}
        buttonNavigate={() => navigate('/')}
        mediaAvaliacoes={challenge?.MediaAvaliacoes}
        qtdAvaliacoes={challenge?.QtdAvaliacoes}
        id={challenge?.Id}
        sponsor={true}
      />

      {loading ? (
        <ViewLoading />
      ) : (
        <S.ContainerData>
          <S.ImageConcluded src={trophy} />

          <S.BoxChallengePending>
            <div className='boxImage'>
              <S.Image src={challenge?.CoverImageUrl} />
            </div>

            <div className='boxContent'>
              <div className='containerTop'>
                <div className='containerTitle'>
                  <h1 className='title'>{challenge?.Title}</h1>
                </div>

                <div className='boxUser'>
                  <Person className="i" />
                  <h1 className='nameUser'>{challenge?.AuthorName}</h1>
                </div>
              </div>
            </div>
          </S.BoxChallengePending>

          <S.BoxPointsEarned>
            {token2 !== 'PE' && <>

              {(challengeConcluded?.SpecialAwardName && Number(challengeConcluded?.ProductCount) > 0) ? (
                <div className='boxContent' style={{ marginBottom: '24px', alignItems: 'flex-start' }}>
                  <EmojiEvents className="i" />
                  <h1 className='titleContent'>{t('Prêmio Especial: ')}</h1>

                  <div className='boxContentLeft'>
                    <CardGiftcard style={{ fontSize: '16px', color: theme.colors.seventh, }} />

                    <div className="boxContentLeftText">
                      <h1 className='titleContent' style={{ fontWeight: '600' }} >{challengeConcluded?.SpecialAwardName}</h1>

                      <button className='boxContentLeftLink' onClick={() => navigate('/premios_especiais')}>
                        <h1 className='titleContent' style={{ color: theme.colors.seventh }} >{t('Ver prêmios disponíveis')}</h1>
                        <OpenInNew style={{ fontSize: '16px', color: theme.colors.seventh, marginLeft: '4px' }} />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='boxContent'>
                  <EmojiEvents className="i" />
                  <h1 className='titleContent'>{t('Pontos Ganhos: ')}</h1>
                  <Stars style={{ fontSize: '12px', color: theme.colors.seventh, marginLeft: '4px' }} />
                  <h1 className='titleContent' style={{ fontWeight: '600' }} >{Number(challengeConcluded?.AlternativePoints) > 0 ? challengeConcluded?.AlternativePoints : challengeConcluded?.ScoreAcquired}</h1>
                </div>
              )}
            </>}

            <div className='boxContent'>
              <BarChart className="i" />
              <h1 className='titleContent'>{t('Aproveitamento: ')}</h1>

              <div className='containerProgressBar'>
                <Box sx={{ width: '100%' }}>
                  <LinearProgress variant="determinate" value={challengeConcluded?.PercentageOfCorrectAswers} style={{ borderRadius: '8px' }} color="info" />
                </Box>

                <h1 className='textProgress'>{challengeConcluded?.PercentageOfCorrectAswers}%</h1>
              </div>
            </div>
          </S.BoxPointsEarned>

          {token2 !== 'PE' && <>
            {challengeConcluded?.Questions && (
              <S.ContainerSummary>
                <button className='boxButton' onClick={() => setContentQuestions(!contentQuestions)}>
                  <h1 className='titleContent'>{t('Resumo do Quiz')}</h1>
                  {contentQuestions ? <KeyboardArrowUp className="i" /> : <KeyboardArrowDown className="i" />}
                </button>

                {contentQuestions && (
                  <div className='containerQuestion'>
                    {challengeConcluded?.Questions.map((item) => (
                      <div className='boxQuestion'>
                        <div className='boxTextQuestion'>
                          <h1 className='textQuestion'>{item.Question}</h1>
                          <h3 className='textAlternative'>{t('Sua Resposta: ')}{item.Alternatives.filter((itemX) => itemX?.SelectedAnswer === true)[0]?.Answer}</h3>
                        </div>

                        {item.IsTheQuestionRight ? <CheckCircle className="i" style={{ color: theme.colorsBase.green }} /> : <Cancel className="i" style={{ color: theme.colorsBase.red }} />}
                      </div>
                    ))}
                  </div>
                )}
              </S.ContainerSummary>
            )}
          </>
          }

          {token2 !== 'PE' && <S.MessageRedoChallenge>{t("• Você pode fazer esse desafio novamente em ")}{challengeConcluded?.DaysToTryAgain}{t(" dia(s)")}</S.MessageRedoChallenge>}


          {token2 !== 'PE' ?
            <ButtonMaterial
              style={{ border: `solid 1px ${theme.colors.seventh}`, width: '100%', marginTop: isSm ? '36px' : '18px', }}
              variant="contained"
              color='info'
              label={t("VOLTAR AOS DESAFIOS")}
              icon={<School style={{ fontSize: '24px', marginRight: '8px' }} />}
              onClick={() => navigate(`/home`)}
            />
            :
            <div style={{ display: "flex", flexDirection: isSm ? "column" : "row", width: '100%' }}>
              <ButtonMaterial
                style={{ width: '100%', marginTop: isSm ? '36px' : '18px', }}
                variant="contained"
                color='info'
                label={t("VOLTAR AO PROGRAMA ESPECIAL")}
                icon={<School style={{ fontSize: '24px', marginRight: '8px' }} />}
                onClick={() => handleValidateNextContent() ? navigate(`/programas_especiais/1`) : navigate(`/programas_especiais/2`)}
              />

              {handleValidateNextContent() && <ButtonMaterial
                style={{ backgroundColor: theme.colorsBase.green2, width: '100%', marginTop: isSm ? '16px' : '18px', marginLeft: isSm ? "0px" : '16px' }}
                variant="contained"
                color='info'
                label={t("PRÓXIMO CONTEÚDO")}
                iconRight={<ArrowForward style={{ fontSize: '24px', marginLeft: '8px' }} />}
                onClick={handleNextContent}
              />}
            </div>
          }
        </S.ContainerData>
      )}

      <BoxAssessment id={token} content={token2 === 'PE'} />
    </S.Container>
  )
}

export default ChallengeConcluded
