import React, { useState } from 'react';

import { useIsSm } from '../../hooks/useIsSm';

import SettingsAdmView from './view';

const SettingsAdm: React.FC = () => {
  const [tab, setTab] = useState(0)
  const isSm = useIsSm()

  return (
    <SettingsAdmView
      tab={tab}
      setNewTab={setTab}
      variant={isSm ? 'scrollable' : 'standard'}
    />
  );
}

export default SettingsAdm;