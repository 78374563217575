import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'
import { theme } from '../../styles/theme';

export const ContainerContent = styled.div`
  display: flex;
  padding: 0 40px;
  width: 90vw;
  flex-direction: row;

  @media (max-width: 680px) {
    padding: 0 8px;
    flex-direction: column;
    width: 100%;
  }
`

export const ContainerInfo = styled.div`
display: flex; 
flex-direction: column;
  width: 50%;
  height: 100%;
  padding-right: 20px;
  border-right: solid 1px ${theme.colorsBase.gray8};

  @media (max-width: 680px) {
    width: 100%;
    padding: 0px;
    border-right: none;
    margin-top: 8px;
  }
`

export const BoxCurator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
  margin: 0 0px 24px 0px;

  @media (max-width: 680px) {
    margin: 0 0px 20px 0px;
  }

  .boxContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 16px;

        .title {
          margin-bottom: 4px;
          line-height: 16px;
          letter-spacing: 0.4px;
          text-align: left;
          font-size: ${responsiveFont(16)};
          color: ${(props) => props.theme.colors.secundary};
          font-weight: 400;
          font-family: 'Barlow', Regular;

          @media (min-width: 1600px) {
            font-size: ${responsiveFont(18)};
          }
        }

        .subTitle {
          margin-bottom: 4px;
          line-height: 16px;
          letter-spacing: 0.4px;
          text-align: left;
          font-size: ${responsiveFont(12)};
          color: ${(props) => props.theme.colors.black};
          font-weight: 400;
          font-family: 'Barlow', Regular;
          
          @media (max-width: 680px) {
            font-size: ${responsiveFont(10)};
          }

          @media (min-width: 1600px) {
            font-size: ${responsiveFont(14)};
          }
        }
  }

  .noImage {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    height: 100px;
    object-fit: cover; 
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    @media (max-width: 680px) {
      min-width: 60px;
      height: 60px;
    }

    .icon {
      color: ${(props) => props.theme.colorsBase.gray6};
      width: 24px;
      height: 24px;
      align-self: center;
      position: absolute;
    }
  }
`

export const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover; 
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 680px) {
    width: 60px;
    height: 60px;
  }
`

export const Description = styled.p`
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
  font-size: ${responsiveFont(10)};
  color: ${(props) => props.theme.colors.black};
  font-weight: 400;
  font-family: 'Barlow', Regular;

  @media (min-width: 1600px) {
    font-size: ${responsiveFont(12)};
  }
`

export const ButtonLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width:  100%; 
  margin: 20px 0px 4px 0px;

  @media (max-width: 680px) {
    margin: 20px 0px 4px 0px;
  }

  .icon {
    width: 16px;
    object-fit: cover;
    margin-right: 8px;
    color: ${(props) => props.theme.colors.seventh};
    object-fit: cover;
  }

  .link {
   letter-spacing: 0.4px;
   text-align: left;
   font-size: ${responsiveFont(12)};
   color: ${(props) => props.theme.colors.seventh};
   font-weight: 400;
   font-family: 'Barlow', Regular;
   border: none;
   background: none;
  
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;


   @media (min-width: 1600px) {
    font-size: ${responsiveFont(14)};
  }
  


`

export const ContainerLink = styled.div`
  display: flex;
  width: 45%;
  height: 100%;
  padding-left: 20px;
  flex-direction: row;
  justify-content: space-between;

  .boxLink {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    padding-bottom: 16px;

  }

  .boxCard {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

  }

  @media (max-width: 680px) {
    width: 100%;
    padding-left: 0px;
    flex-direction: column-reverse;

    .boxLink {
      width: 100%;
      padding-bottom: 0px;
      padding-right: 0px;
    }

    .boxCard {
      width: 100%;
      margin-top: 24px;
      margin-bottom: -24px;
    }
  }
`