import { useMutation } from "@tanstack/react-query";

import { sendRequest } from "../../../services/RequestService";

import { IChallengesCurationRejectionCommand, IChallengesCurationRejectionCommandResponse } from "./types";

const defaultRoot = "challenges-curations/rejections"

/**
 * Rejeitar o desafio e passar para outro curador
 * @returns 
 */
export const useChalengeCurationRejectionMutation = () => useMutation({
  mutationFn: async (command: IChallengesCurationRejectionCommand) => {
    const url = `${defaultRoot}/${command.id}`

    return await sendRequest<IChallengesCurationRejectionCommandResponse>(url, 'POST', command.reason)
  }
})
