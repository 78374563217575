import { Paper, styled, PaperProps, Box, useTheme } from '@mui/material';

const PaperContainer = styled((props: PaperProps) => {
  const theme = useTheme()

  return (
    <Paper
      component={Box}
      // width='100%'
      height='100%'
      elevation={theme.breakpoints.up(800) ? 1 : 0}
      {...props}
    />
  )
})(({ theme }) => ({
  flex: 1,
  [theme.breakpoints.down(800)]: {
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: 'unset'
  },
  [theme.breakpoints.up(800)]: {
    backgroundColor: 'white',
    borderRadius: "8px 8px 0 0",
    marginLeft: "40px",
    marginRight: "40px"
  },
}));

export default PaperContainer;
