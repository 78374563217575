import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'


export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  justify-content: flex-start;
  padding: 8px 16px;
  width: 100%;

  h3 {
    line-height: 16px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

    h5 {
      margin-top: 4px;
      line-height: 20px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }
  

  @media (max-width: 680px) {
  }
`