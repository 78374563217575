import iconEN from '../../assets/images/en.png'
import iconES from '../../assets/images/es.png'
import iconFR from '../../assets/images/fr.png'
import iconPT from '../../assets/images/pt.png'


export const data = [
    {
        img: iconEN,
        name: 'EN',
        language: '2'
    },
    {
        img: iconES,
        name: 'ES',
        language: '3'
    },
    {
        img: iconFR,
        name: 'FR',
        language: '4'
    },
    {
        img: iconPT,
        name: 'PT',
        language: '1'
    }
]