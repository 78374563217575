export type RuleNames = 'length' | 'specialChar' | 'number' | 'capital' | 'lowerCase' | 'match' | 'equalNumber'


export class Registration {
  email = '';
  name = '';
}

export class RegistrationError {
  email = '';
  name = '';
}