import React from 'react';
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  StackProps,
  Button
} from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { WithTranslationProps, withTranslation } from 'react-i18next';

import { StyledTableCell, StyledTableRow, StyledTableRowHead } from '../../../../components/Table';
import { IChallengeCurationDashboardHistoryResponse } from '../../../../services/Dashboard/CurationsDashboard/types';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

interface IProps extends StackProps, WithTranslationProps {
  history: IChallengeCurationDashboardHistoryResponse[] | null
}

const InspectionHistoryCard: React.FC<IProps> = ({ history, i18n, ...props }) => (
  <Paper component={Stack} spacing={2} pb={2} {...props}>
    <Typography fontSize="20px" fontWeight="400" color='#201E1E' p={2}>{i18n?.t("Histórico de Revisão")}</Typography>

    <TableContainer component={Box}>
      <Table>
        <TableHead>
          <StyledTableRowHead>
            <StyledTableCell>{i18n?.t("Desafio Associado")}</StyledTableCell>
            <StyledTableCell>{i18n?.t("Data e hora de Moderação")}</StyledTableCell>
            <StyledTableCell>{i18n?.t("Ação de Moderação")}</StyledTableCell>
            <StyledTableCell>{i18n?.t("Motivo")}</StyledTableCell>
          </StyledTableRowHead>
        </TableHead>
        <TableBody>
          {history?.map(({ Action, DateTime, Reason, Title }) => (
            <StyledTableRow key={`${Action}-${DateTime}-${Reason}-${Title}`}>
              <StyledTableCell component="th">{Title}</StyledTableCell>
              <StyledTableCell>{format(new Date(DateTime), "dd/MM/yyyy hh:mm")}</StyledTableCell>
              <StyledTableCell>{Action}</StyledTableCell>
              <StyledTableCell>{Reason}</StyledTableCell>
            </StyledTableRow>
          ))}
          {Array.from({ length: 4 - (history?.length ?? 0) }).map((_, i) => i).map((i) => (
            <StyledTableRow key={`table-empty-item-${i}`}>
              <StyledTableCell component="th" />
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell />
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <Stack direction="row" justifyContent="center">
      <Link to="/inspection">
        <Button
          variant="text"
          endIcon={<KeyboardArrowRight />}
          sx={{ color: "#1A1266" }}
        >
          {i18n?.t("Ver todos")}
        </Button>
      </Link>
    </Stack>
  </Paper>
);

export default withTranslation()(InspectionHistoryCard);
