import React, { useEffect, useState, } from 'react';

import * as S from './styles'
import { useTranslation } from 'react-i18next';
import ToastNotification from '../../components/ToastNotification';
import ViewError from '../../components/ViewError';
import { MenuItem, Select, Tooltip } from '@mui/material'
import ModalCustom from '../../components/ModalCustom';
import { IChallenge } from '../../services/Challenges/types';
import { getChallenges } from '../../services/Challenges';
import FormControl from '@mui/material/FormControl';
import { theme } from '../../styles/theme'
import InputLabel from '@mui/material/InputLabel';
import { ICategories } from '../../components/TableCategory/types';
import { getCategories } from '../../services/Categories';
import Loading from '../../components/Loading';
import { ArrowForwardIos, Diamond, FilterList, KeyboardArrowLeft, KeyboardArrowRight, Rule } from '@mui/icons-material';
import InputSearch from '../../components/InputSearch';
import HeaderMobile from '../../components/HeaderMobile'
import { useNavigate } from 'react-router-dom';
import { useIsSm } from '../../hooks/useIsSm';
import ChallengeItemDesktop from '../../components/ChallengeItemDesktop';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ButtonCreateDesktopChallenge from '../../components/ButtonCreateDesktopChallenge';
import ButtonCreateMobileChallenge from '../../components/ButtonCreateMobileChallenge';
import ModalCustomBottom from '../../components/ModalCustomBottom';
import { FirstAccessCarousel } from '../../components/FirstAccessCarousel';


const Home: React.FC = () => {
  const { t } = useTranslation();
  const [challengesList, setChallengesList] = useState<IChallenge[]>([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState<number | undefined>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [category, setCategory] = useState('');
  const [categoriesList, setCategoriesList] = useState<ICategories[]>([])
  const [homeSearch, setHomeSearch] = useState('');
  const [homeFilter, setHomeFilter] = useState(false);
  const navigate = useNavigate()
  const isSm = useIsSm()
  const [loadingSpecials, setLoadingSpecials] = useState(false)
  const [totalPagesSpecials, setTotalPagesSpecials] = useState<number | undefined>(0)
  const [currentPageSpecials, setCurrentPageSpecials] = useState(1)
  const [challengesListSpecials, setChallengesListSpecials] = useState<IChallenge[]>([])
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    retrieveChallenges(1, true);
    retrieveChallengesSpecials(1, true);
    retrieveCategories();
    setOpenModal(false)
  }, []);

  useEffect(() => {
    retrieveChallenges(1, true, homeSearch);
    retrieveChallengesSpecials(1, true, homeSearch);
  }, [homeSearch]);

  const retrieveCategories = async () => {
    setLoading(true)
    try {
      const bodyData = {
        PageNumber: 1,
        PageSize: 9999
      };
      const response = await getCategories(bodyData);
      if (response.Success) {
        setCategoriesList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar as categorias, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar as categorias, tente novamente.')
      });
    }
    setLoading(false)
  };

  const retrieveChallengesSpecials = async (page: number = 1, clear: boolean = false, search?: string) => {
    setLoadingSpecials(true)
    try {
      if (clear) {
        setChallengesListSpecials([]);
      }
      const bodyData = {
        PageNumber: page,
        PageSize: 64,
        Nome: search,
        CategoriaId: category,
        HasSpecialAward: 'true'
      };
      const response = await getChallenges(bodyData);
      if (response.Success) {
        setCurrentPageSpecials(page);
        if (page === 1 && response.TotalPages) {
          setTotalPagesSpecials(response.TotalPages);
        }

        if (clear) {
          setChallengesListSpecials(response.Data);
        } else if (response.Data.length > 1) {
          const novaArray = challengesList.concat(response.Data);
          setChallengesListSpecials(novaArray);
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os desafios, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os desafios, tente novamente.')
      });
    }
    setLoadingSpecials(false)
  };

  const retrieveChallenges = async (page: number = 1, clear: boolean = false, search?: string) => {
    if (clear) { setLoading(true) }
    try {
      if (clear) {
        setChallengesList([]);
      }
      const bodyData = {
        PageNumber: page,
        PageSize: 64,
        Nome: search,
        CategoriaId: category,
        HasSpecialAward: 'false'
      };
      const response = await getChallenges(bodyData);
      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }

        if (clear) {
          setChallengesList(response.Data);
        } else if (response.Data.length > 1) {
          const novaArray = challengesList.concat(response.Data);
          setChallengesList(novaArray);
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os desafios, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os desafios, tente novamente.')
      });
    }
    setLoading(false)
  };

  const responsive = {
    0: { items: 1.1 },
    234: { items: 1.1 },
    370: { items: 1.2 },
    400: { items: 1.3 },
    700: { items: 2.1 },
    800: { items: 2.3 },
    1024: { items: 3.3 },
    1900: { items: 3.7 },
    2024: { items: 4.3 },
  };

  const slideNextSpecials = () => {
    if (currentPageSpecials < (totalPagesSpecials || 0)) {
      retrieveChallengesSpecials(currentPageSpecials + 1, false, homeSearch)
    }
  };

  const slideNext = () => {
    if (currentPage < (totalPages || 0)) {
      retrieveChallenges(currentPage + 1, false, homeSearch)
    }
  };

  interface PrevButtonProps {
    isDisabled: boolean;
  }

  const renderPrevButton: React.FC<PrevButtonProps> = ({ isDisabled }) => {
    return (
      <button className="btn-prevB">
        <KeyboardArrowLeft className="ibtn-prevB" style={{ color: !isDisabled ? theme.colors.seventh : theme.colorsBase.gray3 }} />
      </button>
    )
  };

  const renderNextButton: React.FC<PrevButtonProps> = ({ isDisabled }) => {
    return (
      <div className="btn-nextB" style={{ top: "20px", height: "100%", width: "10%", backgroundImage: "linear-gradient(265.8deg, #FFFFFF 31.36%, rgba(255, 255, 255, 0) 96.39%)" }}>
        <button style={{ border: "none", background: "none", marginRight: "-100px" }} onClick={slideNext} >
          <KeyboardArrowRight className="ibtn-nextB" style={{ color: !isDisabled ? theme.colors.seventh : theme.colorsBase.gray3 }} />
        </button>
      </div>
    );
  };

  const renderNextButtonSpecials: React.FC<PrevButtonProps> = ({ isDisabled }) => {
    return (
      <div className="btn-nextB" style={{ top: "20px", height: "100%", width: "10%", backgroundImage: "linear-gradient(265.8deg, #FFFFFF 31.36%, rgba(255, 255, 255, 0) 96.39%)" }}>
        <button style={{ border: "none", background: "none", marginRight: "-100px" }} onClick={slideNextSpecials} >
          <KeyboardArrowRight className="ibtn-nextB" style={{ color: !isDisabled ? theme.colors.seventh : theme.colorsBase.gray3 }} />
        </button>
      </div>
    );
  };

  return (
    <S.Container>
      <S.ContainerContent>
        <S.ContainerTitle>
          <S.Line>
            <h5>{t('Home')}</h5>
          </S.Line>

          <div className='containerSubTitle'>
            <h3>{t('Seja bem-vindo(a) à Página Inicial! Aqui você encontra Desafios Especiais e Desafios disponíveis para você participar!')}</h3>

            {!isSm ?
              <ButtonCreateDesktopChallenge />
              :
              <ButtonCreateMobileChallenge />
            }
          </div>
        </S.ContainerTitle>

        {!isSm ? (
          <S.ContainerSearch>
            <InputSearch
              type='search'
              placeholder={t('O que deseja procurar?') ?? "O que deseja procurar?"}
              value={homeSearch}
              onChange={(e) => setHomeSearch(e.target.value)}
              variant="outlined"
              style={{ width: '100%' }}
            />

            <Tooltip placement='right' title={t('Filtros')}>
              <S.ButtonStyled onClick={() => setHomeFilter(!homeFilter)}>
                <FilterList className='icon' />
              </S.ButtonStyled>
            </Tooltip>
          </S.ContainerSearch>
        ) : (
          <HeaderMobile setSearch={setHomeSearch} updateFilter={() => setHomeFilter(!homeFilter)} />
        )}

        <S.LineSeparator>
          <div className='boxSeparator'>
            <Diamond className="iconSeparator" />
            <h5>{t('Desafios Especiais')}</h5>
            {!isSm && <h6>{t('Participe dos Desafios Especiais! Grandes prêmios te esperam. Topa o desafio?')}</h6>}
          </div>
        </S.LineSeparator>


        {challengesListSpecials.length > 0 ? (
          <>
            <S.ContainerChallenge>
              <AliceCarousel
                mouseTracking
                responsive={responsive}
                disableDotsControls
                onSlideChanged={slideNextSpecials}
                renderPrevButton={renderPrevButton}
                renderNextButton={renderNextButtonSpecials}
                disableButtonsControls={isSm}
                items={challengesListSpecials.map((item) => (
                  <ChallengeItemDesktop playVideo={item?.ContentType === 1} key={item.Id} style={{ margin: '0px 16px 8px 16px' }} onClick={() => {
                    navigate(`/desafio/${item.Id}`)
                  }}
                    item={item}
                    typeChallenge={1}
                  />
                ))}
              />
            </S.ContainerChallenge>

            {loadingSpecials && <Loading />}
          </>
        ) : loadingSpecials ? <Loading /> : <ViewError message={t('Nenhum desafio especial encontrado!')} />}


        <S.SeparatorButton>
          <button className='buttonSeparator' onClick={() => navigate(`/desafios_especiais`)}>
            <h5>{t('Ver todos os Desafios Especiais')}</h5>
            <ArrowForwardIos className="i" />
          </button>
        </S.SeparatorButton>


        <S.LineSeparator>
          <div className='boxSeparator'>
            <Rule className="iconSeparator" />
            <h5>{t('Desafios Disponíveis')}</h5>
            {!isSm && <h6>{t('Vai ficar de fora? Cada desafio te dá mais pontos. Bora acumular!')}</h6>}
          </div>
        </S.LineSeparator>


        {challengesList.length > 0 ? (
          <>
            <S.ContainerChallenge>
              <AliceCarousel
                mouseTracking
                responsive={responsive}
                disableDotsControls
                onSlideChanged={slideNext}
                renderPrevButton={renderPrevButton}
                renderNextButton={renderNextButton}
                disableButtonsControls={isSm}
                items={challengesList.map((item) => (
                  <ChallengeItemDesktop playVideo={item?.ContentType === 1} key={item.Id} style={{ margin: '0px 16px 8px 16px' }} onClick={() => {
                    navigate(`/desafio/${item.Id}`)
                  }}
                    item={item}
                    typeChallenge={1}
                  />
                ))}
              />
            </S.ContainerChallenge>

            {loading && <Loading />}
          </>
        ) : loading ? <Loading /> : <ViewError message={t('Nenhum desafio encontrado!')} />}


        <S.SeparatorButton>
          <button className='buttonSeparator' onClick={() => navigate(`/desafios_disponiveis`)}>
            <h5>{t('Ver todos os Desafios Disponíveis')}</h5>
            <ArrowForwardIos className="i" />
          </button>
        </S.SeparatorButton>
      </S.ContainerContent>

      <ModalCustom
        open={homeFilter}
        onClose={() => setHomeFilter(false)}
        onClick={() => {
          retrieveChallenges(1, true, homeSearch)
          retrieveChallengesSpecials(1, true, homeSearch)
          setHomeFilter(false)
        }}
        onCloseBottom={() => {
          setCategory('')
          retrieveChallenges(1, true, homeSearch)
          retrieveChallengesSpecials(1, true, homeSearch)
          setHomeFilter(false)
        }}
        closeBottom={true}
        childrenContent={
          <S.Item>
            <S.Inputs>
              <div className="input">
                <FormControl sx={{ display: 'flex', m: 1, width: '100%' }}>
                  <InputLabel id="demo-simple-select-helper-label">{t('Categoria')}</InputLabel>
                  <Select
                    MenuProps={{
                      MenuListProps: {
                        sx: { maxHeight: '200px', maxWidth: '100%', overflowY: 'auto' }
                      }
                    }}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    variant="standard"
                    style={{ display: 'flex', width: '100%' }}
                    value={category}
                    onChange={(e) => {
                      setCategory(String(e.target.value))
                    }}
                  >
                    {categoriesList?.map((item) => (
                      <MenuItem key={item.Id} value={item.Id}>{item.Name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </S.Inputs>
          </S.Item>
        }
        loading={loading}
        title={t('Refine sua pesquisa')}
        confirmationButtonText={t('Pesquisar')}
        textDeclineButton={t('LIMPAR')}
      />

      {!isSm ? (
        <ModalCustom
          open={openModal}
          onClose={() => setOpenModal(false)}
          onClick={() => { }}
          title={t('Primeiro Acesso')}
          confirmationButtonText={t('ENVIAR')}
          textDeclineButton={t('CANCELAR')}
          buttonsEnabled={false}
          style={{ width: "80%" }}
          childrenContent={<FirstAccessCarousel onCancel={() => setOpenModal(false)} />}
        />
      ) : (
        <ModalCustomBottom
          open={openModal}
          onClose={() => setOpenModal(false)}
          title={t('Primeiro Acesso')}
          showIcon={false}
          buttonsEnabled={false}
          buttonClose={true}
          childrenContent={<FirstAccessCarousel onCancel={() => setOpenModal(false)} />}
        />
      )}
    </S.Container>
  )
}

export default Home
