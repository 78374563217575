import styled from 'styled-components'
import { responsiveFont } from '../../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  flex: 1;
  padding: 0 80px;
  gap: 25px;

  @media (max-width: 680px) {
    flex-direction: column;
    padding: 0;
    gap: 0;
  }
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  min-width: 50%;
  height: auto;
  flex: 1;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  margin: 15px 0 90px 0;
  padding: 0px 16px 16px 16px;

  .title {
    padding: 12px 24px 12px 12px;
    width: 100%;
    text-align: start;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
  }

  .titleDetails {
    width: 100%;
    text-align: center;
    font-size: ${responsiveFont(24)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    margin-top: -48px;
  }

  .boxSubTitle {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    margin-top: 8px;
  }

  .subTitleDetails {
    text-align: center;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
  }


  @media (max-width: 680px) {
    margin: 0;
    width: 100%;
    box-shadow: none;
    background-color: none;
    border-radius: 0px;

    .titleDetails {
      margin-top: 0px;
    }

    .subTitleDetails {
      text-align: left;
    }
  }
`;

export const ContainerChallenge = styled.div`
  display: flex;
  height: 100%;
  margin: 8px 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 14px;
  align-self: center;

  @media (max-width: 680px) {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin: 8px 0px;
    align-items: center;
    padding: 0px 8px;
    gap: 16px;
  }
`

export const ContainerCertificate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin: -24px 0 16px 0;
  padding: 16px 24px;

  .imgCertificate {
    width: 42px;
    object-fit: cover;
    margin-right: 16px;
  }

  .titleCertificate {
    width: 100%;
    text-align: start;
    font-size: ${responsiveFont(14)};
    color: black;
    font-weight: 400;
    font-family: 'Barlow', Regular;
  }

  .boxDescription {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 0px;
  }

  @media (max-width: 680px) {
    flex-direction: column;

    .boxDescription {
      margin-bottom: 8px;
    }
  }
`;

