import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'


export const Select = styled.button<{ white?: boolean }>`
position: relative;
  display: flex;
  padding: 8px 10px;
  border: none;
  flex-direction: row;
  align-items: center;
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }

  h1 {
    font-weight: 500;
    letter-spacing: 1.25px;
    margin: 0px 8px;
    color: ${(props) => (props.white ? props.theme.colorsBase.white : props.theme.colors.black)};
    font-size: ${responsiveFont(14)};
    font-family: 'Barlow', Medium;
  }
`

export const CircleCount = styled.div`
  display: flex;
  position: absolute;
  top: 8px;
  right: 1px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.seventh};
  width: 18px;
  height: 18px;
  border-radius: 50%;

  &:hover {
    opacity: 0.8;
  }

  h1 {
    font-weight: 500;
    color: ${(props) => props.theme.colorsBase.white};
    font-size: ${responsiveFont(12)};
    font-family: 'Barlow', Medium;
  }
`

export const Options = styled.div<{ white?: boolean }>`
  display: flex;
  position: absolute;
  width: 330px;
  right: 16px;
  top: ${(props) => (props.white ? '66px' : '56px')};
  align-items: center;
  justify-content: flex-start;
  padding: 0px 12px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colorsBase.white};
  flex-direction: column;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  max-height: 250px;
  overflow: auto;

  @media (min-width: 1600px) {
    width: 500px;
    max-height: 350px;
  }

  &::-webkit-scrollbar {
    border-radius: 8px;
    width: 4px;
    background: transparent;
    }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.eleven};
    border-radius: 16px;
  }
  ::-webkit-scrollbar:horizontal { 
    height: 4px;
  }

  .containerViewMore {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 0px;
    border-top: solid 1px ${(props) => props.theme.colors.friday};
    margin-top: 8px;
  }

  .buttonViewMore {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;

    h5 {
      font-weight: 400;
      color: ${(props) => props.theme.colors.seventh};
      font-size: ${responsiveFont(14)};
      font-family: 'Barlow', Regular;
    }

    .icon {
      width: 16px;
      object-fit: cover;
      margin-left: 8px;
      color: ${(props) => props.theme.colors.seventh};
    }
  }

  h1 {
    font-weight: 400;
    color: ${(props) => props.theme.colors.black};
    font-size: ${responsiveFont(14)};
    font-family: 'Barlow', Regular;
    margin: 24px 0px;
  }
`

export const Option = styled.div<{ white?: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  border: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 12px 0px;

  .boxText {
    display: flex;
    width: 100%;
    height: 40px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 12px;

    .buttonh5 {
      background: none;
      border: none;
      font-weight: 400;
      color: ${(props) => props.theme.colorsBase.black};
      font-size: ${responsiveFont(14)};
      font-family: 'Barlow', Regular;

      max-width: 230px;;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }

    h3 {
      font-weight: 400;
      margin-top: 4px;
      color: ${(props) => props.theme.colorsBase.black};
      font-size: ${responsiveFont(8)};
      font-family: 'Barlow', Regular;

      max-width: 230px;;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @media (min-width: 1600px) {
        font-size: ${responsiveFont(10)};
      }
    }
  }

  .boxIcon {
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 4px;
    margin-right: 4px;
    background: none;
    border: none;

    .icon {
      width: 16px;
      color: ${(props) => props.theme.colors.friday};
    }
  }

  .flag {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colorsBase.gray7};
    color: ${(props) => props.theme.colorsBase.white};
    padding: 8px;
  }

  @media (max-width: 680px) {
    width: 80%;
    padding: ${(props) => (props.white ? '8px 0px 8px 0px' : '0px 16px 0px 0px')};
    margin: 0;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
  position: relative;
`