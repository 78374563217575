import React from 'react';
import * as S from './styles'
import { useTranslation } from 'react-i18next';
import { IChallengeAccomplished } from '../../services/Challenges/types';
import { AssignmentTurnedIn, ChevronRight, EmojiEvents, RestartAlt, Schedule, StackedBarChart } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import { theme } from '../../styles/theme';
import { DataMaskFormtData } from '../../utils/Mask';
import { useIsSm } from '../../hooks/useIsSm';
import { useNavigate } from 'react-router-dom';
import ButtonMaterial from '../../components/Button';
interface IProps {
  item?: IChallengeAccomplished
  onClick?: () => void
  type?: number
  content?: boolean
}

const ChallengeDetails: React.FC<IProps> = ({ item, onClick, type, content }) => {
  const { t } = useTranslation();
  const isSm = useIsSm()
  const navigate = useNavigate()

  const value: number = item?.PercentageOfCorrectAswers ?? 0;

  const renderColorGraphic = () => {
    if (value >= 70) return theme.colors.graphicGreen
    if (value < 70 && value > 30) return theme.colors.graphicYellow
    if (value <= 30) return theme.colors.graphicRed
  }

  const renderBackgroundColorGraphic = () => {
    if (value >= 70) return theme.colors.graphicBackgroundGreen
    if (value < 70 && value > 30) return theme.colors.graphicBackgroundYellow
    if (value <= 30) return theme.colors.graphicBackgroundRed
  }

  const renderTextGraphic = () => {
    if (value >= 70) return 'Seu desempenho foi ótimo!'
    if (value < 70 && value > 30) return 'Você pode revisar o conteúdo e melhorar seu desempenho!'
    if (value <= 30) return 'Você pode revisar o conteúdo e melhorar seu desempenho!'
    return ''
  }

  return (
    <>
      <S.ContainerChallengeDetails>
        <S.BoxDetails>
          <h1 className='titleField'>{t('Título')}</h1>
          <h2 className='textField'>{item?.Title}</h2>

          <h1 className='titleField' style={{ marginTop: !isSm ? '32px' : '16px' }}>{content ? t('Autor(a) do Conteúdo') : t('Autor(a) do Desafio')}</h1>
          <h2 className='textField'>{item?.AuthorName}</h2>

          <div className='containerContent'>
            <S.BoxContent>
              <StackedBarChart className="i" />
              <h5>{item?.DifficultyLevel}</h5>
            </S.BoxContent>

            <S.BoxContent style={{ marginLeft: '8px' }}>
              <Schedule className="i" />
              <h5>{item?.EstimatedTime} {t('min')}</h5>
            </S.BoxContent>
          </div>

          <div className='containerContent' style={{ marginTop: !isSm ? '32px' : '16px' }}>
            <div>
              <h1 className='titleField' >{t('Início')}</h1>
              <h2 className='textField'>{DataMaskFormtData(item?.StartedAt ?? "", true)}, {item?.StartedAt?.substring(11, 16)}</h2>
            </div>

            {type === 0 &&
              <div style={{ marginLeft: '24px' }}>
                <h1 className='titleField'>{t('Conclusão')}</h1>
                <h2 className='textField'>{DataMaskFormtData(item?.CompletedAt ?? "", true)}, {item?.CompletedAt?.substring(11, 16)}</h2>
              </div>}
          </div>

          {!content && <>
            <h1 className='titleField' style={{ marginTop: !isSm ? '32px' : '16px' }}>{t('Pontuação adquirida')}</h1>
            <h2 className='textField' style={{ color: theme.colors.seventh }}> {type === 0 ? `${item?.ScoreAcquired} pts` : t('A receber')}</h2>


            {type === 0 &&
              <S.ButtonBottom onClick={() => navigate('/minhas_conquistas')}>
                <EmojiEvents className="iLeft" />
                <h5>{t('Visualizar Conquistas')}</h5>
                <ChevronRight className="iRight" />
              </S.ButtonBottom>
            }
          </>}
        </S.BoxDetails>

        <S.BoxGraphic>
          <h1 className='titleGraphic'>{type === 0 ? t('Desempenho') : t('Progresso')}</h1>

          {type === 0 ? (
            <>
              <div className='boxGraphic'>
                <Box sx={{ position: "relative", display: "inline-flex", backgroundColor: renderBackgroundColorGraphic, borderRadius: '50%', marginBottom: '8px', marginTop: '-12px' }}>
                  <CircularProgress
                    variant="determinate"
                    size={100}
                    thickness={3.5}
                    value={item?.PercentageOfCorrectAswers}
                    sx={{ borderRadius: '50%', color: renderColorGraphic }}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h5 className='percentageGraph' style={{ color: renderColorGraphic() }}>{`${item?.PercentageOfCorrectAswers}%`}</h5>
                  </Box>
                </Box>

                <div className='boxtRightAnswer'>
                  <div className="circle" style={{ backgroundColor: renderColorGraphic() }} />
                  <h1 className='titleRightAnswer'>{t('Respostas corretas')}</h1>
                </div>

                <div className='boxtRightAnswer'>
                  <AssignmentTurnedIn className="iconB" />
                  <h1 className='titleRightAnswer'>{item?.NumberOfCorrectAnswers}/{item?.NumberOfQuestions}</h1>
                </div>
              </div>

              <h1 className='message1'>{value >= 70 ? t('Parabéns!') : ''} {t('Você acertou')} {item?.NumberOfCorrectAnswers} {t('de')} {item?.NumberOfQuestions} {t('questões!')}</h1>
              <h1 className='message2'>{t(renderTextGraphic())}</h1>
            </>
          ) : (
            <>
              <div className='boxGraphic'>
                <Box sx={{ position: "relative", display: "inline-flex", backgroundColor: theme.colors.seventh2, borderRadius: '50%', marginBottom: '8px', marginTop: '-12px' }}>
                  <CircularProgress
                    variant="determinate"
                    size={100}
                    thickness={3.5}
                    value={item?.PercentageCompleted}
                    sx={{ borderRadius: '50%', color: theme.colors.seventh }}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h5 className='percentageGraph' style={{ color: theme.colors.seventh }}>{`${item?.PercentageCompleted}%`}</h5>
                  </Box>
                </Box>

                <div className='boxtRightAnswer'>
                  <div className="circle" style={{ backgroundColor: theme.colors.seventh }} />
                  <h1 className='titleRightAnswer'>{t('Média de Aproveitamento do desafio')}</h1>
                </div>
              </div>

              <h1 className='message1'>{t('Você já aproveitou')} {item?.PercentageCompleted} {t('do desafio!')}</h1>
              <h1 className='message2'>{t('Retome para finalizar o desafio!')}</h1>
            </>
          )}

          {!content && <>
            {type === 0 &&
              <S.ButtonBottom onClick={onClick} green>
                <RestartAlt className="iLeft" />
                <h5>{t('Participar Novamente')}</h5>
                <ChevronRight className="iRight" />
              </S.ButtonBottom>
            }
          </>}
        </S.BoxGraphic>
      </S.ContainerChallengeDetails>

      {type === 1 &&
        <ButtonMaterial
          style={{ backgroundColor: theme.colors.seventh, margin: isSm ? '24px auto 0px auto' : '0px' }}
          variant="contained"
          label={t('RETOMAR DESAFIO')}
          iconRight={<ChevronRight style={{ fontSize: '24px', marginLeft: '8px', color: theme.colorsBase.white }} />}
          onClick={onClick}
        />
      }
    </>
  )
}

export default ChallengeDetails
