import React from 'react';
import { Container, Grid } from '@mui/material';
import { Diamond, FolderSpecial, Gavel, ManageAccounts, RuleFolder, Settings } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PageContainer from '../../../components/Layout/PageContainer';
import Item from './Item';
import { IItem } from './types';
import { useIsSm } from '../../../hooks/useIsSm';
import HeaderAchievements from '../../../components/HeaderAchievements';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';


const items: IItem[] = [
  {
    title: t("Gerenciamento de Desafios"),
    icon: RuleFolder,
    href: "/administracao/desafios"
  },
  {
    title: t("Configurações"),
    icon: Settings,
    href: "/configuracao"
  },
  {
    title: t("Termos de Uso"),
    icon: Gavel,
    href: "/gerenciamento_de_termos"
  },
  {
    title: t("Gerenciamento de Usuários"),
    icon: ManageAccounts,
    href: "/gerenciamento_de_usuarios"
  },
  {
    title: t("Gerenciamento de Produtos"),
    icon: Diamond,
    href: "/gerenciamento_de_produtos"
  },
  {
    title: t("Gerenciamento de Programas Especiais"),
    icon: FolderSpecial,
    href: "/triagem_de_programas_especiais"
  }
]

const AdministrativeFunctions: React.FC = () => {
  const navigate = useNavigate()
  const isSm = useIsSm()
  const { t } = useTranslation();


  return (
    <PageContainer>
      <HeaderAchievements
        title={t('Funções Administrativas')}
        marginBottom={isSm ? 24 : 0}
      />

      <Container maxWidth="lg">
        <Grid container spacing={2} columns={isSm ? 1 : 2}>
          {items.map(({ title, icon, href }) => (
            <Item
              key={title}
              title={title}
              icon={icon}
              onClick={() => navigate(href)}
            />)
          )}
        </Grid>
      </Container>
    </PageContainer>
  )
}

export default AdministrativeFunctions;
