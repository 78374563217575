import * as yup from 'yup';
import { verifyClassEmpty } from '../../utils/ClassEmpty';
import { genericValidation, } from '../../utils/ValidationGenerics';
import { RegistrationIdentification, RegistrationIdentificationError } from './types';


export const registerChallengeValidation = async (object: RegistrationIdentification): Promise<true | RegistrationIdentification> => {
  const schemaRegister = yup.object().shape({
    name: yup.string().required(),
    language: yup.string().required(),
    mediaType: yup.string().required()
  });

  const mountError = await genericValidation<RegistrationIdentification, RegistrationIdentificationError>(schemaRegister, object, new RegistrationIdentification());

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};
