import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 400px;
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 8px;
  position: relative;
  padding: 12px 8px 8px 12px;
  margin: 0 0px 24px 0px;
  box-shadow: 0px 4px 4px 0px ${(props) => props.theme.colorsBase.black6};

  @media (max-width: 680px) {
    width: ${`calc(100vw - 16px)`};
    margin: 0px;
  }

  .boxContent {
    width: 100%;
    height: 104px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 12px;

    @media (max-width: 680px) {
      max-width: ${`calc(100vw - 145px)`};
    }

    .boxText {
      display: flex;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    
    .boxButtons {
      display: flex;
       width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: row;
      padding: 4px 0;

      .Buttons {
        display: flex;
         width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: row;
      }

    }

        .title {
          margin-bottom: 4px;
          line-height: 16px;
          text-align: left;
          font-size: ${responsiveFont(16)};
          color: ${(props) => props.theme.colors.secundary};
          font-weight: 400;
          font-family: 'Barlow', Regular;

          width: 250px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @media (max-width: 680px) {
            max-width: ${`calc(100vw - 145px)`};
          }
        }

        .subTitle {
          margin-bottom: 4px;
          line-height: 16px;
          text-align: left;
          font-size: ${responsiveFont(12)};
          color: ${(props) => props.theme.colorsBase.black};
          font-weight: 400;
          font-family: 'Barlow', Regular;

          width: 250px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          
          @media (max-width: 680px) {
            max-width: ${`calc(100vw - 145px)`};
          }
        }

        .data {
          line-height: 16px;
          text-align: left;
          font-size: ${responsiveFont(12)};
          color: ${(props) => props.theme.colorsBase.black};
          font-weight: 400;
          font-family: 'Barlow', Regular;

          width: 250px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          
          @media (max-width: 680px) {
            max-width: ${`calc(100vw - 145px)`};
          }
        }

       
  }
`

export const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover; 
  border-radius: 50%;
`