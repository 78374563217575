import { useQuery } from "@tanstack/react-query";

import { IBaseResponse } from "../../../services/RequestService/types";
import { sendRequest } from "../../RequestService";
import { ErrCodes } from "../../types";
import { ICurationsDashboardQueryResponse } from "./types";

const defaultRoot = "challenge-dashboards/curations"

export const useCurationsDashboard = () => useQuery({
  queryKey: ["useCurationsDashboard"],
  queryFn: async (): Promise<IBaseResponse<ICurationsDashboardQueryResponse>> => {

    const response = await sendRequest<ICurationsDashboardQueryResponse>(defaultRoot, 'GET')

    if (!response.Success) {
      if (response.status === 404) {
        return {
          Data: {
            CurationCounters: {
              ApprovedCount: 0,
              CurationCount: 0,
              PercentageApproved: 0,
              PercentageUnapproved: 0,
              UnapprovedCount: 0
            },
            History: [],
            WaitingCuration: {
              Items: [],
              WaitingCurationCount: 0
            }
          },
          Message: ''
        }
      }

      throw new Error(response.ErrorDetails)
    }

    return response

  },
  meta: { errCode: ErrCodes.CHALLENGE_DESHBOARD_FETCH_FAILED }
});
