import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const ContaineInput = styled.div`
  display: flex;
  position: relative;
  width: 270px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`

export const ContentInput = styled.input<{ empty?: boolean }>`
  display: flex;
  width: 25px;
  height: 45px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: none;
  border-bottom: solid 1px ${(props) => props.theme.colorsBase.black3};
  margin: ${(props) => (props.empty ? '0px 0px 0px 24px' : '0px 4px')};

  line-height: 24px;
  letter-spacing: 0.18px;
  text-align: center;
  font-size: ${responsiveFont(16)};
  color: ${(props) => props.theme.colors.friday};
  font-weight: 400;
  font-family: 'Barlow', Medium;
  text-transform: uppercase;
`

export const ContentInputEmpty = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  left: 74px;
  width: 25px;
  height: 45px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: none;

  h2 {
    line-height: 24px;
    letter-spacing: 0.18px;
    text-align: center;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colors.friday};
    font-weight: 400;
    font-family: 'Barlow', Medium;
  }
`
