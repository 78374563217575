import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  left: 50%;
  position: absolute;
  width: 520px;
  height: 330px;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.colorsBase.white};
  border-radius: 4px;
  box-shadow: 24;
  padding: 24px;

  .icon {
    color: ${(props) => props.theme.colorsBase.black};
    width: 20px;
    height: 20px;
    top: 24px;
    right: 24px;
    position: absolute;
  }

  .buttonClose {
    display: flex;
    position: absolute;
    top: 8px;
    right: 8px;
    align-items: center;
    flex-direction: row;
    border: none;
    background-color: transparent;
  
    &:hover {
      opacity: 0.8;
    }

    .icon {
      color: ${(props) => props.theme.colorsBase.black};
      width: 16px;
      height: 16px;
    }
  }
 
  h5 {
    line-height: 24px;
    letter-spacing: 0.14px;
    text-align: center;
    font-size: ${responsiveFont(16)};
    margin-top: 8px;
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Medium;
  }

  .button {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
  }

  @media (min-width: 1600px) {
    width: 500px;
    height: 400px;

    h5 {
      line-height: 24px;
      font-size: ${responsiveFont(20)};
    }
  }

  @media (max-width: 680px) {
    width: 90%;
    height: 300px;
  }
`
