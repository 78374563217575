import { Add } from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ButtonMaterial from '../Button'
import { theme } from '../../styles/theme'
import { useIsSm } from '../../hooks/useIsSm'

const ButtonCreateDesktopChallenge: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const isSm = useIsSm()

  return (
    <ButtonMaterial
      style={{ borderColor: theme.colors.seventh, color: theme.colorsBase.white, backgroundColor: theme.colors.seventh, minWidth: !isSm ? "none" : "100%", borderRadius: '50px  8px 8px 50px', marginTop: '16px' }}
      variant="outlined"
      label={t('Criar novo desafio')}
      icon={<Add style={{ fontSize: '24px', marginRight: '8px', color: theme.colorsBase.white }} />}
      onClick={() => navigate('/desafios/criar')}
    />
  )
}

export default ButtonCreateDesktopChallenge
