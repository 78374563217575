import { IPostTermsOfUse, IResponseTermsOfUse, IResponseTermsOfUseMultiLanguages } from './types';
import { sendRequest } from '../RequestService';
import { IPageInfo } from '../RequestService/types';

const defaultRoot = 'terms-of-uses';

export const getTermsOfUse = async () => {
  const url = `${defaultRoot}`
  return await sendRequest<IResponseTermsOfUse, IPageInfo>(url, 'GET')
}

export const postCreateTermsOfUse = async (body: IPostTermsOfUse) => {
  const url = `${defaultRoot}`
  return await sendRequest<boolean, IPostTermsOfUse>(url, 'POST', body)
}

export const getTermsOfUseAdmin = async (body: IPageInfo) => {
  let url = `${defaultRoot}/multi-languages`
  url += `?Page=${body?.Page}&PageSize=${body?.PageSize}`;
  if (body?.CreatedAt) url += `&OrderByProperty=-CreatedAt`;
  return await sendRequest<IResponseTermsOfUseMultiLanguages[], IPageInfo>(url, 'GET')
}