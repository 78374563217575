import { subDays } from "date-fns"
import { useMemo } from "react"


export const useStartEndDate = (DAYS_AMOUNT = 15, startDate?: number | Date) => {
  return useMemo(() => {
    const today = startDate ? new Date(startDate) : new Date()
    return {
      today,
      end: subDays(today, DAYS_AMOUNT)
    }
  }, [])
}