import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer'
import { theme } from '../../styles/theme';
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import HeaderAuthentication from '../../components/HeaderAuthentication';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Login, LoginError } from './types';
import ButtonMaterial from '../../components/Button';
import Input from '../../components/Input';
import ToastNotification from '../../components/ToastNotification';
import { emailSendingValidation, resetPasswordValidation } from './validations';
import { postConfirmCode, postSendEmail, putRedefinePassword } from '../../services/User';
import AuthCode from '../../components/InputCode';
import { InputAdornment } from '@mui/material'
import * as S from './styles'
import logo from '../../assets/images/Frame52.png'
import { useIsSm } from '../../hooks/useIsSm';
import SocialLogin from '../../components/SocialLogin';
import { ContainerSocialLoginButton } from '../loginAndRegister/styles';
import { removeSpaces } from '../../utils/UtilsGlobal';

const RecoverPassword: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const { token } = useParams();
  const [recoverPassword, setRecoverPassword] = useState<Login>(new Login());
  const [recoverPasswordError, setRecoverPasswordError] = useState<LoginError>(new LoginError());
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [time, setTime] = useState(0);
  const [hasFinished, setHasFinished] = useState(false);
  const [loadingValidationCode, setLoadingValidationCode] = useState(false);
  const [validationCode, setValidationCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isSm = useIsSm()

  /* eslint-disable @typescript-eslint/no-explicit-any */
  let countdownTimeOut: any;

  useEffect(() => {
    if (token) {
      setRecoverPassword({
        ...recoverPassword,
        email: token
      })
      setEmailSent(true);
    }
  }, []);

  useEffect(() => {
    if (emailSent) {
      if (time > 0) {
        countdownTimeOut = setTimeout(() => {
          setTime((state) => state - 1);
        }, 1000);
      } else if (time === 0) {
        setHasFinished(true);
      }
    }
  }, [time, emailSent]);

  const resetCountDown = () => {
    handleSendEmail();
    clearTimeout(countdownTimeOut);
    setHasFinished(false);
    setTime(0.5 * 60);
  };

  const handleSendEmail = async () => {
    setLoading(true);
    try {
      let countError = 0;

      const validation = await emailSendingValidation(recoverPassword);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRecoverPasswordError(validation as LoginError);
        setLoading(false);
        return;
      }

      const bodyData = {
        Email: recoverPassword.email,
        TipoEmail: 1
      };
      const response = await postSendEmail(bodyData);
      if (response.Success) {
        setEmailSent(true);

        clearTimeout(countdownTimeOut);
        setHasFinished(false);
        setTime(0.5 * 60);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao enviar o e-mail, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao enviar o e-mail, tente novamente.')
      });
    }
    setLoading(false);
  };

  const handleValidationCode = async () => {
    setLoadingValidationCode(true);
    try {
      if (validationCode.length !== 7) {
        setLoadingValidationCode(false);
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('O código informado está inválido.')
        });
        return;
      }

      const bodyData = {
        Codigo: validationCode
      };
      const response = await postConfirmCode(bodyData);
      if (response.Success) {
        setRecoverPassword({
          ...recoverPassword,
          codigo: response.Data.Codigo,
          hash: response.Data.Hash || ''
        })
        setCodeSent(true)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao confirmar o código, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao confirmar o código, tente novamente.')
      });
    }
    setLoadingValidationCode(false);
  };

  const handleChangePassword = async () => {
    setLoading(true);
    try {
      let countError = 0;

      const validation = await resetPasswordValidation(recoverPassword);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRecoverPasswordError(validation as LoginError);
        setLoading(false);
        return;
      }

      const bodyData = {
        Email: recoverPassword.email,
        Senha: recoverPassword.password,
        ConfirmacaoSenha: recoverPassword.confirmPassword,
        Codigo: recoverPassword.codigo,
        Hash: recoverPassword.hash
      };
      const response = await putRedefinePassword(bodyData);
      if (response.Success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Senha redefinida com sucesso!')
        });
        navigate('/login');
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao redefinir a senha, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao redefinir a senha, tente novamente.')
      });
    }
    setLoading(false);
  };

  const onEnterDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      if (emailSent) {
        if (codeSent) {
          handleChangePassword();
        } else {
          handleValidationCode();
        }
      } else {
        handleSendEmail();
      }
    }
  };

  const renderConfirmationEmail = () => {
    return (
      <div className='containerRecoverPassword'>
        <h3>{t('Insira abaixo o e-mail cadastrado')}<br />{t('e enviaremos um código de verificação.')}</h3>

        <Input
          label={t('E-mail')}
          type="fantasyName"
          value={recoverPassword.email}
          onChange={(e) => setRecoverPassword({ ...recoverPassword, email: removeSpaces(e.target.value) })}
          size="small"
          fullWidth
          error={recoverPasswordError.email ? true : false}
          helperText={recoverPasswordError.email}
          onClick={() => setRecoverPasswordError({ ...recoverPasswordError, email: '' })}
          variant="filled"
          style={{ marginTop: '32px' }}
          onKeyPress={onEnterDown}
        />

        <ButtonMaterial
          style={{ backgroundColor: theme.colors.seventh, width: '100%', marginTop: '140px' }}
          variant="contained"
          label={t("CONFIRMAR")}
          onClick={handleSendEmail}
          loading={loading}
        />
      </div>
    );
  };

  const renderCheckCode = () => {
    return (
      <div className='containerRecoverPassword'>
        <h3>{t('Insira abaixo o Código de Verificação que')}<br />{t('você recebeu em seu e-mail para concluir.')}</h3>
        <h1>{t('Verifique a caixa de spam e lixo eletrônico!')}</h1>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <AuthCode onChange={(e: string) => setValidationCode(e)} />
        </div>

        <ButtonMaterial
          style={{ borderColor: theme.colors.secundary, width: '100%', marginTop: '100px' }}
          variant="outlined"
          label={`${t("REENVIAR ")} ${time <= 0 ? '' : `(${time})`}`}
          onClick={resetCountDown}
          disable={!hasFinished}
        />

        <ButtonMaterial
          style={{ backgroundColor: theme.colors.seventh, width: '100%', marginTop: '16px' }}
          variant="contained"
          label={t("CONFIRMAR")}
          onClick={handleValidationCode}
          loading={loadingValidationCode}
        />
      </div >
    );
  };

  const renderChangePassword = () => {
    return (
      <div className='containerRecoverPassword'>
        <h3>{t('Por favor, insira abaixo sua nova senha,')}<br />{t('ela deve conter no mínimo 8 caracteres, incluindo pelo menos uma letra maiúscula, uma letra minúscula, um número e um símbolo.')}</h3>

        <Input
          type={showPassword ? 'text' : 'password'}
          label={t('Senha')}
          size="small"
          value={recoverPassword.password}
          onChange={(e) => setRecoverPassword({ ...recoverPassword, password: e.target.value })}
          error={recoverPasswordError.password ? true : false}
          helperText={t(recoverPasswordError.password)}
          onClick={() => setRecoverPasswordError({ ...recoverPasswordError, password: '' })}
          sx={{
            marginTop: 2,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => setShowPassword((showPassword) => !showPassword)}
              >
                {showPassword ? (
                  <Visibility fill={theme.colors.nine} />
                ) : (
                  <VisibilityOff fill={theme.colors.nine} />
                )}
              </InputAdornment>
            ),
          }}
          fullWidth
          style={{ marginTop: '16px' }}
          variant="filled"
          onKeyPress={onEnterDown}
        />

        <Input
          type={showConfirmPassword ? 'text' : 'password'}
          label={t('Confirme a Senha')}
          size="small"
          value={recoverPassword.confirmPassword}
          onChange={(e) => setRecoverPassword({ ...recoverPassword, confirmPassword: e.target.value })}
          error={recoverPasswordError.confirmPassword ? true : false}
          helperText={t(recoverPasswordError.confirmPassword)}
          onClick={() => setRecoverPasswordError({ ...recoverPasswordError, confirmPassword: '' })}
          sx={{
            marginTop: 2,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <Visibility fill={theme.colors.nine} />
                ) : (
                  <VisibilityOff fill={theme.colors.nine} />
                )}
              </InputAdornment>
            ),
          }}
          fullWidth
          style={{ marginTop: '16px' }}
          variant="filled"
          onKeyPress={onEnterDown}
        />

        <ButtonMaterial
          style={{ backgroundColor: theme.colors.seventh, width: '100%', marginTop: '100px' }}
          variant="contained"
          label={t("REDEFINIR")}
          onClick={handleChangePassword}
          loading={loading}
        />
      </div>
    );
  };

  const renderBody = () => {
    return emailSent ? (codeSent ? renderChangePassword() : renderCheckCode()) : renderConfirmationEmail();
  };


  return (
    <S.Container>
      <HeaderAuthentication recoverPassword={true} />

      <S.ContainerImage>
        <S.ContainerContent>
          <>
            {renderBody()}

            <S.Line>
              <h5>{t('Ou')}</h5>
            </S.Line>

            <ContainerSocialLoginButton>
              <SocialLogin />
            </ContainerSocialLoginButton>
          </>
        </S.ContainerContent>
        {!isSm && <img src={logo} className="imageLeft" alt='' />}
      </S.ContainerImage>

      <div style={{ display: 'flex', flex: 1, alignItems: 'flex-end', width: '100%' }}>
        <Footer />
      </div>
    </S.Container>
  )
}

export default RecoverPassword
