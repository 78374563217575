import { useQuery } from "@tanstack/react-query";

import { sendRequest } from '../RequestService';
import { IPageInfo } from '../RequestService/types';
import { ICategories, ICategoryPost } from '../../components/TableCategory/types';
import { ICategory, ICategoryQueryResponse } from './types';

const defaultRoot = 'categories';

export const useCategories = () => useQuery({
  queryKey: ["useCategories"],
  queryFn: async () => await sendRequest<ICategoryQueryResponse[]>(defaultRoot, 'GET')
});

export const getCategories = async (body?: IPageInfo) => {
  let url = `${defaultRoot}`
  if (body?.Nome) url += `&Nome=${body.Nome}`;
  return await sendRequest<ICategories[], IPageInfo>(url, 'GET')
}

export const getCategoriesAdmin = async (body?: IPageInfo) => {
  let url = `${defaultRoot}/in-all-languages`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Name=${body.Nome}`;
  return await sendRequest<ICategory[], IPageInfo>(url, 'GET')
}

export const disableCategory = async (id: string) => {
  const url = `${defaultRoot}/disabled/${id}`
  return await sendRequest<boolean, boolean>(url, 'PATCH')
}

export const enableCategory = async (id: string) => {
  const url = `${defaultRoot}/enabled/${id}`
  return await sendRequest<boolean, boolean>(url, 'PATCH')
}

export const postCategories = async (body: ICategoryPost) => {
  const url = `${defaultRoot}`
  return await sendRequest<boolean, ICategoryPost>(url, 'POST', body)
}

export const editCategories = async (body: ICategoryPost, id: string) => {
  const url = `${defaultRoot}/${id}`
  return await sendRequest<boolean, ICategoryPost>(url, 'PUT', body)
}
