import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { sendRequest } from "../../../services/RequestService";
import { IChalengeInCurationsQueryResponse } from "./types";
import { IBodyGET } from "../../../services/Challenges/types";

const defaultRoot = "challenges-management/in-curations"

export const useInvalidateChallengesInCurations = () => {
  const queryClient = useQueryClient()

  return useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ["useChallengesInCurations"],
      exact: false
    });
  }, [queryClient])
}

export const getChallengesCurations = async (body: IBodyGET) => {
  let url = `${defaultRoot}`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Title=${body.Nome}`;
  return await sendRequest<IChalengeInCurationsQueryResponse[], IBodyGET>(url, 'GET')
}