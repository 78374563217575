import React, { useEffect, useState } from 'react';
import * as S from '../../ModalCreateSpecialProgram/styles';
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../../../hooks/useIsSm';
import ModalCustom from '../../../../components/ModalCustom';
import ButtonMaterial from '../../../../components/Button';
import { theme } from '../../../../styles/theme';
import { RegistrationSpecialProgram, RegistrationSpecialProgramError } from '../../ModalCreateSpecialProgram/types';
import SendingInvitations from '../../ModalCreateSpecialProgram/SendingInvitations';
import Logger from '../../../../utils/Logger';
import { emailValidation } from '../../ModalCreateSpecialProgram/validations';
import { ICardSpecialProgram } from '../../ScreenUnderConstruction/types';
import ToastNotification from '../../../../components/ToastNotification';
import { postProgramInvite, putSpecialProgram } from '../../../../services/SpecialProgram';
import ModalCustomBottom from '../../../../components/ModalCustomBottom';

interface IProps {
  open: boolean;
  setOpen: (e: boolean) => void;
  program?: ICardSpecialProgram
  update: () => void;
  programActive?: boolean;
}

const ModalInviteParticipants: React.FC<IProps> = ({ open, setOpen, program, update, programActive }) => {
  const { t } = useTranslation();
  const isSm = useIsSm();
  const [registration, setRegistration] = useState<RegistrationSpecialProgram>(new RegistrationSpecialProgram());
  const [registrationError, setRegistrationError] = useState<RegistrationSpecialProgramError>(new RegistrationSpecialProgramError());
  const [email, setEmail] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setEmail(program?.emailList)
    }
  }, [open]);

  const handleAddEmail = async () => {
    try {
      let countError = 0;
      const validation = await emailValidation(registration);
      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError({ ...registrationError, email: "Por favor, insira um e-mail no formato correto 'usuario@dominio.com.'" });
        return;
      }
      setRegistration({ ...registration, email: '' });
      setEmail([...email, registration.email]);
    } catch (ex) {
      Logger('ex', 'Houve um erro, tente novamente.');
    }
  };

  const handleAddListEmail = (listEmail: string[]) => {
    setEmail([...email, ...listEmail]);
  };

  const handleDeleteEmail = (index: number) => {
    const updatedEmails = [...email];
    updatedEmails.splice(index, 1);
    setEmail(updatedEmails);
  };

  const handleCancel = () => {
    setRegistration(new RegistrationSpecialProgram());
    setRegistrationError(new RegistrationSpecialProgramError());
    setEmail([]);
    setOpen(false);
  };

  const handleSendInvitations = async () => {
    setLoading(true);
    try {
      const data = new FormData();
      email.forEach(e => data.append('SpecialProgram.Emails', e));
      data.append('SpecialProgram.Title', program?.title ?? '');
      data.append('SpecialProgram.Description', program?.description ?? '');
      data.append('SpecialProgram.AverageRate', program?.averagePerformance ?? '');
      data.append('SpecialProgram.Language', program?.language ?? '');
      data.append('SpecialProgram.Attempts', program?.attempts ?? '');
      data.append('SpecialProgram.Tags', program?.tags ?? '');

      let response;

      if (programActive) {
        response = await postProgramInvite(String(program?.id), email);
      } else {
        response = await putSpecialProgram(data, String(program?.id));
      }
      if (response.Success) {
        update();
        setOpen(false);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao cadastrar os convites, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      Logger('ex', 'Houve um erro, tente novamente.');
    }
    setLoading(false);
  };

  const returnContent = () => {
    return (
      <S.ContainerRegister>
        <SendingInvitations
          email={email}
          handleDeleteEmail={handleDeleteEmail}
          handleAddEmail={handleAddEmail}
          registration={registration}
          setRegistration={setRegistration}
          registrationError={registrationError}
          setRegistrationError={setRegistrationError}
          setListEmails={(e) => handleAddListEmail(e)}
          noDelete={program?.status === 'approved'}
        />
        <div className='containerButtonsRegister'>
          <ButtonMaterial
            style={{ borderColor: theme.colors.secundary, color: theme.colors.secundary, width: isSm ? '100%' : 'auto' }}
            variant='outlined'
            label={t('CANCELAR')}
            onClick={handleCancel}
          />
          <ButtonMaterial
            style={{ backgroundColor: theme.colorsBase.green2, marginLeft: '24px', width: isSm ? '100%' : 'auto' }}
            variant='contained'
            label={t('SALVAR')}
            onClick={handleSendInvitations}
            loading={loading}
          />
        </div>
      </S.ContainerRegister>
    )
  }

  return (
    <>
      {isSm ? (
        <ModalCustomBottom
          open={open}
          onClose={handleCancel}
          onClick={() => setOpen(true)}
          title={t('Convidar Mais Participantes')}
          showIcon={false}
          buttonsEnabled={false}
          confirmationButtonText={t('ENVIAR CONVITES')}
          textDeclineButton={t('CANCELAR')}
          childrenContent={returnContent()}
          styleButtons={{ justifyContent: 'center' }}
          buttonClose
        />
      ) : (
        <ModalCustom
          open={open}
          onClose={handleCancel}
          onClick={() => setOpen(true)}
          title={t('Convidar Participantes')}
          buttonsEnabled={false}
          confirmationButtonText={t('ENVIAR CONVITES')}
          textDeclineButton={t('CANCELAR')}
          childrenContent={returnContent()}
          style={{ height: 'auto' }}
        />
      )}
    </>
  );
};

export default ModalInviteParticipants;
