import React, { useState, useEffect } from 'react';
import { useIsSm } from '../../hooks/useIsSm';
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useAuth } from '../../contexts/Auth';
import { SocialLoginButtonFacebook } from '../../pages/loginAndRegister/styles';
import ToastNotification from '../../components/ToastNotification';
import iconFacebook from '../../assets/images/iconFacebook.png'
import { useTranslation } from 'react-i18next';

const SocialLogin: React.FC = () => {
    const isSm = useIsSm()
    const { socialLogin } = useAuth();
    const { t } = useTranslation();
    const [larguraDaTela, setLarguraDaTela] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setLarguraDaTela(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <FacebookLogin
                appId={process.env.REACT_APP_APPID_FACEBOOK ?? ""}
                /* eslint-disable @typescript-eslint/no-explicit-any */
                onSuccess={(response: any) => {
                    socialLogin(response?.accessToken ?? "", 1);
                }}
                /* eslint-disable @typescript-eslint/no-explicit-any */
                render={({ onClick }: any) => (
                    <SocialLoginButtonFacebook onClick={onClick}>
                        <img src={iconFacebook} alt='' />
                        <h5>{t('Entrar com Facebook')}</h5>
                        <div></div>
                    </SocialLoginButtonFacebook>
                )}
            />

            <GoogleLogin
                onSuccess={credentialResponse => {
                    socialLogin(credentialResponse?.credential ?? "", 2);
                }}
                onError={() => {
                    ToastNotification({
                        id: 'error',
                        type: 'error',
                        message: t('Houve um erro ao realizar o login, tente novamente.')
                    });
                }}
                text="continue_with"
                size="large"
                width={isSm ? String(larguraDaTela - 33) : 'none'}
            />
        </>
    )
}

export default SocialLogin
