import { IGetPaginated } from "../../../services/types";

export type IChalengeScreeningQuery = IGetPaginated & {
  Id?: number;
  Title?: string;
  AuthorEmail?: string;
  Status?: EChallengeStatus;
}

export enum EChallengeStatus {
  InEditing = 1,
  GuardiansReview = 2,
  InScreening = 3,
  InCuration = 4,
  Active = 5,
  Blocked = 6,
}

export type IChalengeScreeningQueryResponse = {
  Id: number;
  Title: string;
  CoverImageUrl: string;
  AuthorName: string;
  Status: EChallengeStatus;
  DateTime: string;
  SpecialProgramContent?: boolean
}
