import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const ContainerBack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  padding: 8px 24px 16px 24px;
  width: 100%;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: flex-start;
    width: 100%;
  }

    .buttonB {
      display: flex;
      
      align-items: center;
      align-self: flex-start;
      justify-content: flex-start;
      background-color: transparent;
      border: none;

      &:hover {
        opacity: 0.8;
      }

      .iconBackButton {
        color: ${(props) => props.theme.colorsBase.white};
        width: 24px;
        height: 24px;
        padding: 4px;
        background-color: ${(props) => props.theme.colors.seventh};
        border-radius: 50%;
      }
  
      h5 {
        margin-left: 8px;
        line-height: 16px;
        letter-spacing: 0.4px;
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colorsBase.black3};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(14)};
        }
      }
    }

  @media (max-width: 680px) {
    padding: 16px 16px 0px 16px;

    .buttonB {
      .iconBackButton {
        width: 16px;
        height: 16px;
        padding: 2px;
      }
    }
  }
`

export const LineTitle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  margin-left: 24px;

  @media (max-width: 680px) {
    margin-left: 8px;
  }

  h3 {
    text-align: left;
    font-size: ${responsiveFont(24)};
    color: ${(props) => props.theme.colorsBase.black3};
    background-color: ${(props) => props.theme.colorsBase.white};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    padding: 0px 8px;
    width: calc(100vw - 480px);

    @media (max-width: 680px) {
      width: 100%;
      font-size: ${responsiveFont(16)};
    }

    @media (min-width: 1600px) {
      width: calc(100vw - 530px);
      font-size: ${responsiveFont(28)};
    }
  }
`

export const ContainerBottomBottons = styled.span<{ active?: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  right: 24px;
  top:  ${(props) => (props.active ? '130px' : '120px')}; 
  left: 90px;

  .left {
  }

  .right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    align-self: flex-start;
    height: 100%;
    width: 100%;
  }

  @media (max-width: 680px) {
    position: static;
    right: none;
    top: none;
    margin-top: 6px;
    width: 100%;

    .right {
      width: 30%;
    }

    .left {
      margin-top: 16px;
    }
  }
`

export const BottonToShare = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  background: none;

  &:hover {
    opacity: 0.8;
}

  .i {
    width: 12px;
    object-fit: cover;
    color: ${(props) => props.theme.colors.seventh};
  }

  @media (max-width: 680px) {
    .i {
      width: 24px;
    }

    h3 {
      display: none;
    }
  }

  h3 {
    margin-left: 8px;
    text-align: left;
    font-size: ${responsiveFont(10)};
    color: ${(props) => props.theme.colors.seventh};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(12)};
    }
  }
`

export const BottonReport = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 24px;
  border: solid 1px ${(props) => props.theme.colors.graphicRed};
  background: none;
  border-radius: 4px;
  padding: 0px 8px;

  &:hover {
    opacity: 0.8;
}

  .i {
    width: 12px;
    object-fit: cover;
    color: ${(props) => props.theme.colors.graphicRed};
  }

  @media (max-width: 680px) {
    margin-left: 10px;
    padding: 1px;

    .i {
      width: 24px;
    }

    h3 {
      display: none;
    }
  }

  h3 {
    margin-left: 8px;
    text-align: left;
    font-size: ${responsiveFont(10)};
    color: ${(props) => props.theme.colors.graphicRed};
    font-weight: 400;
    font-family: 'Barlow', Regular;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(12)};
    }
  }
`





















export const Options = styled.div<{ mobile?: boolean }>`
  display: flex;
  position: absolute;
  right: 160px;
  top: 160px;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colorsBase.white};
  flex-direction: column;
  box-shadow: 0px 4px 4px 0px ${(props) => props.theme.colorsBase.black6};
  width: 260px;
  margin-bottom: none;

  @media (min-width: 1600px) {
    width: 320px;
  }

  h3 {
    width: 100%;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colorsBase.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    margin-bottom: 8px;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
    position: relative;
    right: 0px;
    top: 0px;
    box-shadow: none;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 0px;
    padding: 0px 12px 0px 12px;
  }
`

export const ContainerButtonsSocial = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  .buttonSocial {
    background: none;
    border: none;
    padding: 0px 4px;

    &:hover {
      opacity: 0.8;
    }

    .iconSocial {
      width: 24px;
      height: 24px;
      color: ${(props) => props.theme.colors.seventh};
    }
  }

  @media (max-width: 680px) {
    margin-bottom: 32px;

    .buttonSocial {
      padding: 0px 6px;

      .iconSocial {
        width: 24px;
        height: 24px;
      }
    }
  }
`

export const ContainerLink = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
  border: solid 1px ${(props) => props.theme.colorsBase.black};
  border-radius: 2px;

  .boxLink {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-right: 8px;

    .iconLink {
      margin-right: 4px;
      width: 16px;
      height: 16px;
      color: ${(props) => props.theme.colorsBase.black};
    }

    h4 {
      text-align: left;
      font-size: ${responsiveFont(10)};
      color: ${(props) => props.theme.colorsBase.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;

      max-width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: 680px) {
        max-width: calc(100vw - 160px);
        font-size: ${responsiveFont(12)};
      }
  
      @media (min-width: 1600px) {
        max-width: 210px;
        font-size: ${responsiveFont(12)};
      }
    }
  }

  .buttonLink {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    border: none;
    background-color: ${(props) => props.theme.colors.seventh};
    border-radius: 2px;

    &:hover {
      opacity: 0.8;
    }

    h5 {
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colorsBase.white};
      font-weight: 400;
      font-family: 'Barlow', Regular;
  
      @media (max-width: 680px) {
        font-size: ${responsiveFont(14)};
      }
  
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  }

  @media (max-width: 680px) {
    
    .boxLink {
      max-height: 24px;
      
      .iconLink {
        margin-right: 6px;
        width: 24px;
        height: 24px;
      }
    }
  }
`