import styled from 'styled-components'
import { responsiveFont } from '../../../utils/UtilsGlobal'


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 25%; 

    @media (max-width: 680px) {
      width: 100%; 
      margin: 8px 0px;
    }
`

export const Image = styled.img`
  width: 100%;
  border-radius: 8px  8px  0px  0px;
  object-fit: cover; 
`

export const ContainerImage = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;

    .boxInfoTop {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      z-index: 50;
      position: absolute;
      top: 8px;
      left: 12px;
    }

    .boxInfoBottom {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      z-index: 50;
      position: absolute;
      bottom: 8px;
      left: 0px;
      margin: 0 12px;
      width: auto;
    }
`

export const ContainerSponsor = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-color: ${(props) => props.theme.colorsBase.white};
    padding: 0px 6px;
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.colors.seventh};
    width: 100%;

    .iconSponsor {
      color: ${(props) => props.theme.colors.black4};
      width: 16px;
    }

    .textSponsor {
      margin-left: 4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black4};
      font-weight: 400;
      font-family: 'Barlow', Medium;
    
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }
`

export const ContainerQuantityInStock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: ${(props) => props.theme.colorsBase.white};
    padding: 0px 6px;
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.colorsBase.black};

    .iconStock {
      color: ${(props) => props.theme.colorsBase.black};
      width: 16px;
    }

    .textStock {
      margin-left: 4px;
      text-align: center;
      font-size: ${responsiveFont(10)};
      color: ${(props) => props.theme.colorsBase.black};
      font-weight: 400;
      font-family: 'Barlow', Medium;
    
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(12)};
      }
    }
`

export const ContainerContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 4px 8px 8px 8px;
    width: 100%;

    .nameProduct {
      margin-left: 4px;
      margin-bottom: 4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colorsBase.black5};
      font-weight: 700;
      font-family: 'Barlow', Medium;
    
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }

    .boxInfo {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 4px;
      margin-left: 4px;

      .iconInfo {
        color: ${(props) => props.theme.colors.friday};
        width: 16px;
      }
  
      .textInfo {
        margin-left: 4px;
        text-align: center;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colors.friday};
        font-weight: 400;
        font-family: 'Barlow', Medium;
      
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(16)};
        }
      }

      .textInfoBig {
        margin-left: 4px;
        text-align: center;
        font-size: ${responsiveFont(20)};
        color: ${(props) => props.theme.colors.friday};
        font-weight: 400;
        font-family: 'Barlow', Medium;
      
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(22)};
        }
      }
    }
`