import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 24px 0px 0px 0px;
  
  .boxRight {
    margin-top: auto;
    margin-bottom: 4px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .buttonModalB {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: none;
      background: transparent;;

      .iconB {
        color: ${(props) => props.theme.colorsBase.gray13};
        font-size: 24px;
      }
    }
  }

  .boxTop {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 8px;
    padding-left: 16px;
  }
  
  .noElementsSelected {
    margin-bottom: 4px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colorsBase.gray13};
    font-weight: 400;
    font-family: 'Barlow', Regular;
  }

  .label {
    margin-bottom: 4px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(13)};
    color: ${(props) => props.theme.colorsBase.gray13};
    font-weight: 400;
    font-family: 'Barlow', Regular;
  }

  .boxSelected {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .containerTop {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: solid 1px ${(props) => (props.active ? props.theme.colorsBase.red : props.theme.colorsBase.gray13)}; 
  }


  .containerOptions {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 0;
    border-radius: 0px 0px 8px 8px;
    border: solid 1px ${(props) => props.theme.colorsBase.gray3};

    overflow:auto; 
    max-height: 180px;
    &::-webkit-scrollbar {
      width: 8px;
      background: ${(props) => props.theme.colorsBase.white};
      }
    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colorsBase.gray5};
      border-radius: 8px;
    }
    ::-webkit-scrollbar:horizontal { 
      height: 4px;
    }

        .boxOptions {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          padding-left: 16px;

          .text {
            text-align: left;
            font-size: ${responsiveFont(14)};
            color: ${(props) => props.theme.colors.fourth};
            font-weight: 400;
            font-family: 'Barlow', Regular;
          }
        }
  }

`


export const ButtonClose = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items:center;
  background-color: transparent;
  border: solid 1px ${(props) => props.theme.colorsBase.gray13};
  border-radius: 4px;
  position: relative;
  padding: 4px;
  margin: 2px;

  .i {
    color: ${(props) => props.theme.colorsBase.gray13};
    font-size: 20px;
    padding-left: 4px;
  }

  .title {
    text-align: left;
    font-size: ${responsiveFont(10)};
    color: ${(props) => props.theme.colorsBase.gray13};
    font-weight: 400;
    font-family: 'Barlow', Regular;
  }

  &:hover {
    opacity: 0.8;
  }

`

export const ButtonAdd = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: transparent;
  border: solid 1px ${(props) => (props.active ? props.theme.colors.secundary : props.theme.colorsBase.gray14)}; 
  border-radius: 4px;
  position: relative;
  padding: 4px;
  margin: 4px;

  .title {
    text-align: left;
    font-size: ${responsiveFont(10)};
    color: ${(props) => (props.active ? props.theme.colors.secundary : props.theme.colorsBase.gray14)}; 
    font-weight: 400;
    font-family: 'Barlow', Regular;
  }

  &:hover {
    opacity: 0.8;
  }

`