import { sendRequest } from '../RequestService';
import { IUsers } from '../../pages/UserManagement/TableUsers/types';
import { IGETUser, IPostUser, IPutUser, IPutPermissionUser, IPostCategoryUser, IPostLanguageUser, IDeleteCategory, IDeleteLanguageUser, IUserData, IUserPostEndereco, IUserProfessionalPost, IChangePassword, IUserPatch, IResponseFile, IUserProfessional } from './types';
import { IPais } from '../Locality/types';
import { ICurators } from '../../pages/MyHealers/types';

const defaultRoot = 'usuarios';

export const getUsers = async (body: IGETUser) => {
  let url = `user-accounts/all`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Name=${body.Nome}`;
  if (body?.TiposUsuarios) url += `&Role=${body.TiposUsuarios}`;
  return await sendRequest<IUsers[], IGETUser>(url, 'GET')
}

export const deleteUsers = async (reason: string, id?: string) => {
  const url = `user-accounts/lockouts/${id}`
  return await sendRequest<boolean, string>(url, 'PATCH', reason)
}

export const postUser = async (body: IPostUser) => {
  const url = `${defaultRoot}/inserir-usuario-painel-admin`
  return await sendRequest<boolean, IPostUser>(url, 'POST', body)
}

export const putPermissionUser = async (body: IPutPermissionUser) => {
  const url = `${defaultRoot}/permissoes`
  return await sendRequest<boolean, IPutPermissionUser>(url, 'PUT', body)
}

export const postCategoryUser = async (body: IPostCategoryUser) => {
  const url = `moderacoes/moderador-categoria`
  return await sendRequest<boolean, IPostCategoryUser>(url, 'POST', body)
}

export const editUser = async (body: IPutUser) => {
  const url = `${defaultRoot}/editar-usuario-superior`
  return await sendRequest<boolean, IPutUser>(url, 'PUT', body)
}

export const deleteCategory = async (body: IDeleteCategory) => {
  const url = `moderacoes/moderador-categoria`
  return await sendRequest<boolean, IDeleteCategory>(url, 'DELETE', body)
}

export const postLanguageUser = async (body: IPostLanguageUser) => {
  const url = `moderacoes/moderador-idioma`
  return await sendRequest<boolean, IPostLanguageUser>(url, 'POST', body)
}

export const deleteLanguageUser = async (body: IDeleteLanguageUser) => {
  const url = `moderacoes/moderador-idioma`
  return await sendRequest<boolean, IDeleteLanguageUser>(url, 'DELETE', body)
}

export const getUser = async () => {
  const url = `user-accounts`
  return await sendRequest<IUserData, IGETUser>(url, 'GET')
}

export const putUser = async (data: IUserPatch) => {
  const url = `user-accounts`
  return await sendRequest<boolean, IUserPatch>(url, 'PATCH', data)
}

export const postAddressUser = async (body: IUserPostEndereco) => {
  const url = `user-accounts/addresses`
  return await sendRequest<boolean, IUserPostEndereco>(url, 'POST', body)
}

export const putAddressUser = async (body: IUserPostEndereco) => {
  const url = `user-accounts/addresses`
  return await sendRequest<boolean, IUserPostEndereco>(url, 'PUT', body)
}

export const getAdvice = async () => {
  const url = `regulatpry-boards`
  return await sendRequest<IPais[], boolean>(url, 'GET')
}

export const postProfessionalUser = async (body: IUserProfessionalPost) => {
  const url = `user-accounts/professional-data`
  return await sendRequest<boolean, IUserProfessionalPost>(url, 'POST', body)
}

export const putProfessionalUser = async (body: IUserProfessionalPost) => {
  const url = `${defaultRoot}/dados-usuarios-profissionais`
  return await sendRequest<boolean, IUserProfessionalPost>(url, 'PUT', body)
}

export const postCheckPassword = async (body: string) => {
  const url = `user-accounts/password/checks`
  return await sendRequest<string, string>(url, 'POST', body)
}

export const postChangePassword = async (body: IChangePassword) => {
  const url = `user-accounts/password/changes`
  return await sendRequest<string, IChangePassword>(url, 'PATCH', body)
}

export const deleteUserProfile = async (body: string) => {
  const url = `user-accounts`
  return await sendRequest<boolean, string>(url, 'DELETE', body, true)
}

export const getCurators = async (body: IGETUser) => {
  let url = `our-curators`
  url += `?Page=${body?.PageNumber}&PageSize=${body?.PageSize}`;
  if (body?.Nome) url += `&Name=${body.Nome}`;
  if (body?.Conselho) url += `&RegulatoryBoardId=${body.Conselho}`;
  return await sendRequest<ICurators[], IGETUser>(url, 'GET')
}

export const getCuratorsInformation = async (body: string) => {
  const url = `our-curators/information/${body}`
  return await sendRequest<ICurators, string>(url, 'GET')
}

export const uploadFiles = async (data: FormData, type?: number) => {
  const url = `files?type=${type}`
  return await sendRequest<IResponseFile, FormData>(url, 'POST', data)
}

export const postProfessionalUserAdmin = async (body: IUserProfessionalPost, id?: string) => {
  const url = `user-accounts/professional-data/${id}`
  return await sendRequest<boolean, IUserProfessionalPost>(url, 'PUT', body)
}

export const getUserAdmin = async (id?: string) => {
  const url = `user-accounts/professional-data/${id}`
  return await sendRequest<IUserProfessional, IGETUser>(url, 'GET')
}

export const putRoleAdmin = async (role: string, id?: string) => {
  const url = `user-accounts/roles/${id}`
  return await sendRequest<boolean, string>(url, 'PATCH', role)
}