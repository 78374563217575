import { IResponseTermsOfUseMultiLanguages } from "../../../services/TermsOfUse/types"



export const emptyItem: IResponseTermsOfUseMultiLanguages = {
  ValuePtBr: "",
  ValueEn: "",
  ValueEs: "",
  ValueFr: "",
  Version: "",
  CreatedAt: ""
}
export interface ITableUsers {
  listUsers: IResponseTermsOfUseMultiLanguages[]
  updateList: () => void
  currentVersion?: string
}