import styled from 'styled-components'
import { responsiveFont } from '../../../utils/UtilsGlobal'

export const TextTopPage = styled.h5`
    width: 100%;
    margin-top: 4px;
    margin-left: 48px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colorsBase.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) { 
      font-size: ${responsiveFont(14)};
    }
`

export const ContainerCards = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items:  flex-start;
width: 100%;
height: 100%;

@media (max-width: 680px) {
  flex-direction: column;
  padding: 0 16px;
}
`