import styled from 'styled-components'
import { responsiveFont } from '../../../../utils/UtilsGlobal'


export const ContainerRegister = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;

  .boxSelect {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 16px;

    h1 {
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colorsBase.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      margin-left: 8px;
    }

    .switchDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      h5 {
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colorsBase.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
      }
    }
  }
  
  .containerInputsRegister {
    width: 100%;
    height: 100%;
    display: flex;
    align-self: flex-start;
    flex-direction: row;
    margin-bottom: 24px;
  }

  .boxImageRegister {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    padding: 0px 8px 12px 8px;

      h5 {
        margin-bottom: 4px;
        text-align: center;
        font-size: ${responsiveFont(14)};
        color: ${(props) => props.theme.colorsBase.black5};
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(16)};
        }
      }
  }

  .boxInputsRegister {
    width: 100%;
    height: 100%;
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    margin-left: 24px;
  }

  .containerButtonsRegister {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }

  @media (max-width: 680px) {
    .containerInputsRegister {
      align-self: flex-start;
      flex-direction: column;
      margin-bottom: 24px;
    }

    .boxImageRegister {
      padding: 0px;
      align-items: center;
    }

    .boxInputsRegister {
      margin-left: 0px;
    }
  }
`

export const Image = styled.img`
  width: 100%;
  border-radius: 4px;
  object-fit: cover; 

  @media (max-width: 680px) {
    width: 40%;
  }
`

export const InputMedia = styled.input`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`

export const ButtonImageEdit = styled.label`
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  border: solid 1px ${(props) => props.theme.colors.secundary};
  height: 40px;
  border-radius: 4px;
  margin-top: 8px;
  cursor: pointer;

  .textButtonEdit {
    margin-right: 8px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.secundary};
    font-weight: 400;
    font-family: 'Barlow', Medium;
  
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
    width: auto;
    align-self: flex-start;
    margin: 0 auto 0 auto;
  }
`