import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const ContainerTotal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .boxImage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
  }

  .bottomButtons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 24px;
  }

  .imageOptions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
  }

  .buttonOptions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: none;
    background: none;
    margin: 0 8px;

    .iOptions {
      color: ${(props) => props.theme.colors.black4};
      width: 24px;
      height: 24px;
    }

    .textOptions {
      margin-left: 4px;
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colors.black4};
      font-weight: 400;
      font-family: 'Barlow', Medium;
    }
  }

  @media (max-width: 680px) {
    .boxImage {
      width: 100%;
    }
  }
`

export const Image = styled.img`
  width: 80%;
  object-fit: cover; 
`
