import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'


export const ContainerEditCategories = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 60%;

  .InputContainer {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h2 {
      text-align: left;
      font-size: ${responsiveFont(14)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(16)};
      }
    }

    h1 {
      margin-bottom: 4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.fourth};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
  }

  .switchDivContainer {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h3 {
      margin-left: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(10)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }

    .switchDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      h5 {
        line-height: 20px;
        letter-spacing: 0.15px;
        text-align: left;
        font-size: ${responsiveFont(16)};
        color: ${(props) => props.theme.colors.black};
        opacity: 0.6;
        font-weight: 400;
        font-family: 'Barlow', Regular;
        
        @media (max-width: 680px) {
          font-size: ${responsiveFont(12)};
        }
        
        @media (min-width: 1600px) {
          font-size: ${responsiveFont(18)};
        }
      }
    }
  }

  .switchDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    h5 {
      line-height: 20px;
      letter-spacing: 0.15px;
      text-align: left;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colors.black};
      opacity: 0.6;
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(12)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(18)};
      }
    }
  }

  @media (max-width: 680px) {
    width: 100%;
  }
`
