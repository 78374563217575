import React, { useState } from 'react';
import { ButtonProps } from './types'
import { Language, ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { useTranslation } from 'react-i18next';
import { theme } from '../../styles/theme';
import * as S from './styles'
import { data } from './information'

const SelectLanguageEditUser: React.FC<ButtonProps> = (props) => {
  const { colorWhite, language, languageUser } = props
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <S.Container>
      <S.Select onClick={() => setOpen(!open)} white={colorWhite}>
        <Language className="icon" style={{ color: colorWhite ? theme.colorsBase.white : theme.colors.black }} />
        <h1>{t(data.find((state) => state.language === String(language))?.name ?? '')}</h1>
        {open ? <ArrowDropUp className="icon" style={{ color: colorWhite ? theme.colorsBase.white : theme.colors.black }} /> : <ArrowDropDown className="icon" style={{ color: colorWhite ? theme.colorsBase.white : theme.colors.black }} />}
      </S.Select>

      {open &&
        <S.Options white={colorWhite}>
          {data.map((item) => (
            <S.Option white={colorWhite} key={item.language} onClick={() => {
              languageUser(item.language)
              setOpen(false)
            }}>
              <img src={item.img} alt="" className="flag" />
              <h5>{t(item.name || '')}</h5>
            </S.Option>
          ))}
        </S.Options>
      }
    </S.Container>
  )
}

export default SelectLanguageEditUser
