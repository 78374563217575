import React, { useEffect, useState } from 'react';

import * as S from './styles'
import { useTranslation } from 'react-i18next';
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import TableCategory from '../../components/TableCategory';
import { getCategoriesAdmin, postCategories } from '../../services/Categories';
import ToastNotification from '../../components/ToastNotification';
import ViewLoading from '../../components/ViewLoading';
import ViewError from '../../components/ViewError';
import { Pagination } from '@mui/material'
import ModalCustom from '../../components/ModalCustom';
import Input from '../../components/Input';
import { ICategory } from '../../services/Categories/types';
import { ContainerEditCategories } from '../../components/TableCategory/styles';
import { Registration, RegistrationError } from '../../components/TableCategory/types';
import { registerValidation } from '../../components/TableCategory/validations';
interface IProps {
  hidden: boolean
}

const Home: React.FC<IProps> = ({ hidden }) => {
  const { t } = useTranslation();
  const [categoriesList, setCategoriesList] = useState<ICategory[]>([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState<number | undefined>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [openRegisterCategoriesModal, setOpenRegisterCategoriesModal] = useState(false)
  const [loadingRegisterCategory, setLoadingRegisterCategory] = useState(false)
  const [registration, setRegistration] = useState<Registration>(new Registration());
  const [registrationError, setRegistrationError] = useState<RegistrationError>(new RegistrationError());

  useEffect(() => {
    retrieveCategories(1, true);
  }, []);

  const registerCategory = async () => {
    setLoadingRegisterCategory(true)
    try {
      let countError = 0;

      const validation = await registerValidation(registration);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError(validation as RegistrationError);
        setLoadingRegisterCategory(false);
        return;
      }

      const bodyData = {
        ValuePtBr: registration.ValuePtBr,
        ValueEn: registration.ValueEn,
        ValueEs: registration.ValueEs,
        ValueFr: registration.ValueFr
      };
      const response = await postCategories(bodyData);
      if (response.Success) {
        retrieveCategories(1, true)
        setOpenRegisterCategoriesModal(false)
        setRegistration({
          ValuePtBr: '',
          ValueEn: '',
          ValueEs: '',
          ValueFr: '',
        })
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Categoria cadastrada com sucesso!')
        });
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao cadastrar a categoria, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao cadastrar a categoria, tente novamente.')
      });
    }
    setLoadingRegisterCategory(false)
  };

  const retrieveCategories = async (page: number = 1, clear: boolean = false) => {
    setLoading(true)
    try {
      if (clear) {
        setCategoriesList([]);
      }
      const bodyData = {
        PageNumber: page,
        PageSize: 8
      };
      const response = await getCategoriesAdmin(bodyData);
      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }
        setCategoriesList(response.Data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar as categorias, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar as categorias, tente novamente.')
      });
    }
    setLoading(false)
  };

  return (
    <S.Container style={{ display: hidden ? 'none' : 'flex' }}>
      <S.ContainerTable>
        <div className='containerButton'>
          <ButtonMaterial
            style={{ borderColor: theme.colors.secundary, width: '100%' }}
            variant="outlined"
            label={t('NOVA CATEGORIA')}
            onClick={() => setOpenRegisterCategoriesModal(true)}
          />
        </div>

        {loading ? (
          <ViewLoading />
        ) : (
          <>
            {categoriesList.length > 0 ? (
              <>
                <TableCategory listUsers={categoriesList} updateList={() => retrieveCategories(1, true)} />
                <S.Pages>
                  <Pagination
                    showFirstButton
                    showLastButton
                    count={totalPages}
                    page={currentPage}
                    color="primary"
                    onChange={(e, p) => {
                      e.preventDefault()
                      e.stopPropagation()
                      retrieveCategories(p)
                    }}
                  />
                </S.Pages>
              </>
            ) : (
              <ViewError message={t('Nenhuma categoria encontrada')} />
            )}
          </>
        )}
      </S.ContainerTable>

      <ModalCustom
        open={openRegisterCategoriesModal}
        onClose={() => setOpenRegisterCategoriesModal(false)}
        onClick={registerCategory}
        childrenContent={
          <ContainerEditCategories>
            <Input
              label={t('Nome da categoria (Português)')}
              type="name"
              value={registration.ValuePtBr}
              onChange={(e) => setRegistration({ ...registration, ValuePtBr: e.target.value })}
              size="small"
              fullWidth
              error={registrationError.ValuePtBr ? true : false}
              helperText={registrationError.ValuePtBr}
              onClick={() => setRegistrationError({ ...registrationError, ValuePtBr: '' })}
              variant="filled"
              style={{ marginBottom: '8px' }}
            />

            <Input
              label={t('Nome da categoria (Inglês)')}
              type="name"
              value={registration.ValueEn}
              onChange={(e) => setRegistration({ ...registration, ValueEn: e.target.value })}
              size="small"
              fullWidth
              error={registrationError.ValueEn ? true : false}
              helperText={registrationError.ValueEn}
              onClick={() => setRegistrationError({ ...registrationError, ValueEn: '' })}
              variant="filled"
              style={{ margin: '8px 0px' }}
            />

            <Input
              label={t('Nome da categoria (Espanhol)')}
              type="name"
              value={registration.ValueEs}
              onChange={(e) => setRegistration({ ...registration, ValueEs: e.target.value })}
              size="small"
              fullWidth
              error={registrationError.ValueEs ? true : false}
              helperText={registrationError.ValueEs}
              onClick={() => setRegistrationError({ ...registrationError, ValueEs: '' })}
              variant="filled"
              style={{ margin: '8px 0px' }}
            />

            <Input
              label={t('Nome da categoria (Françês)')}
              type="name"
              value={registration.ValueFr}
              onChange={(e) => setRegistration({ ...registration, ValueFr: e.target.value })}
              size="small"
              fullWidth
              error={registrationError.ValueFr ? true : false}
              helperText={registrationError.ValueFr}
              onClick={() => setRegistrationError({ ...registrationError, ValueFr: '' })}
              variant="filled"
              style={{ marginTop: '8px' }}
            />
          </ContainerEditCategories>
        }
        loading={loadingRegisterCategory}
        title={t('Nova Categoria')}
        confirmationButtonText={t('SALVAR')}
        textDeclineButton={t('CANCELAR')}
      />
    </S.Container>
  )
}

export default Home
