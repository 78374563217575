import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'


export const Select = styled.button<{ white?: boolean, mobile?: boolean }>`
  position: relative;
  display: flex;
  padding: ${(props) => (props.mobile ? '8px 0px' : '8px 10px')};
  border: none;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  margin-left: 8px;

  &:hover {
    opacity: 0.8;
  }

   div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 50%;
    background-color: ${(props) => (props.white ? props.theme.colorsBase.white : props.theme.colors.secundary)};
   }

  .iconUser {
    width: 20px;
    object-fit: cover;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
  position: relative;
`

const funcOptions = (active: boolean) => {
  return active ? '66px' : '56px'
};

export const Options = styled.div<{ white?: boolean, mobile?: boolean }>`
  display: flex;
  position:  ${(props) => (props.mobile ? 'relative' : 'absolute')};
  right:  ${(props) => (props.mobile ? 'none' : '16px')};
  top: ${(props) => (props.mobile ? 'none' : funcOptions(props.white ?? false))};
  align-items: center;
  justify-content: flex-start;
  padding: 12px 12px 0px 12px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colorsBase.white};
  flex-direction: column;
  box-shadow:  ${(props) => (props.mobile ? 'none' : '2px 2px 5px rgba(0, 0, 0, 0.3)')};
  width: ${(props) => (props.mobile ? '100%' : 'none')};
  margin-bottom:  ${(props) => (props.mobile ? '16px' : 'none')};

  .containerViewMore {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: ${(props) => (props.mobile ? 'flex-start' : 'center')};
    padding: ${(props) => (props.mobile ? '16px 12px' : '8px 0px')};
    border-top: solid 1px ${(props) => props.mobile ? props.theme.colorsBase.gray3 : props.theme.colors.friday};
    margin-top: 8px;
  }

  .buttonViewMore {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;

    h5 {
      font-weight: 400;
      color: ${(props) => props.theme.colors.secundary};
      font-size: ${responsiveFont(14)};
      font-family: 'Barlow', Regular;
    }

    .icon {
      width: 24px;
      object-fit: cover;
      margin-right: 8px;
      color: ${(props) => props.theme.colors.secundary};
    }
  }

  h1 {
    font-weight: 400;
    color: ${(props) => props.theme.colors.black};
    font-size: ${responsiveFont(14)};
    font-family: 'Barlow', Regular;
    margin: 24px 0px;
  }
`

export const Option = styled.div<{ white?: boolean }>`
  display: flex;
  width: 100%;
  border: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0px;
  padding: 0px 12px;
  cursor: pointer;

  h5 {
      font-weight: 400;
      color: ${(props) => props.theme.colors.secundary};
      font-size: ${responsiveFont(14)};
      font-family: 'Barlow', Regular;
  }

  .flag {
    margin-right: 16px;
    width: 24px;
    height: 24px;
    color: ${(props) => props.theme.colors.secundary};
  }
`
