import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToastNotification from '../../../../../components/ToastNotification';
import ViewError from '../../../../../components/ViewError';
import { useIsSm } from '../../../../../hooks/useIsSm';
import { Pagination } from '@mui/material';
import { Pages } from '../../../../../pages/ViewChallenge/styles';
import ButtonMaterial from '../../../../../components/Button';
import { theme } from '../../../../../styles/theme';
import DownloadIcon from '@mui/icons-material/Download';
import { getDownloadReport, getReport } from '../../../../../services/SpecialProgram';
import TableReport from './TableReport';
import { IReport } from './TableReport/types';
import { ContainerTableReportMobile } from './TableReport/styles';

interface IProps {
  update: boolean;
  tab: number;
  id: string;
}

const SpecialSummary: React.FC<IProps> = ({ update, tab, id }) => {
  const { t } = useTranslation();
  const isSm = useIsSm();
  const [reportList, setReportList] = useState<IReport[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    retrieveSpecialProgram(1, true);
  }, [update, tab]);

  useEffect(() => {
    const handleScroll = async () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (scrollY + windowHeight >= documentHeight - 100) {
        if ((currentPage <= (totalPages || 0)) && isSm) {
          await retrieveSpecialProgram(currentPage + 1, false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, totalPages, isSm]);

  const retrieveSpecialProgram = async (page: number = 1, clear: boolean = false) => {
    try {
      if (clear) {
        setReportList([]);
      }
      const bodyData = {
        PageNumber: isSm ? 1 : page,
        PageSize: isSm ? (64 * page) : 8,
        Id: id
      };
      const response = await getReport(bodyData);
      if (response.Success) {
        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }

        setReportList(response.Data);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar o relatório, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors,
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar o relatório, tente novamente.'),
      });
    }
  };

  const handleDownloadReport = async () => {
    try {
      await getDownloadReport(id);
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar o relatório, tente novamente.'),
      });
    }
  };


  return (
    <div>
      {reportList.length > 0 ? (
        <>
          <ContainerTableReportMobile>
            <TableReport listReport={reportList} updateList={() => retrieveSpecialProgram(1, true)} />
          </ContainerTableReportMobile>

          {!isSm && (
            <Pages>
              <Pagination
                showFirstButton
                showLastButton
                count={totalPages}
                page={currentPage}
                color="primary"
                onChange={(e, p) => {
                  e.preventDefault();
                  e.stopPropagation();
                  retrieveSpecialProgram(p);
                }}
              />
            </Pages>
          )}

          <div style={{ marginBottom: "1rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <ButtonMaterial
              icon={<DownloadIcon style={{ color: theme.colors.seventh, marginRight: "8px" }} />}
              style={{ borderColor: theme.colors.seventh, color: theme.colors.seventh }}
              variant="outlined"
              onClick={handleDownloadReport}
              label={t('BAIXAR RESUMO DE DESEMPENHO')} />
          </div>
        </>
      ) : (
        <ViewError message="Nenhum dado encontrado" />
      )}
    </div>
  );

};

export default SpecialSummary;
