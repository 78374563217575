import React from 'react';
import { theme } from '../../styles/theme';
import { IParticipantsList } from './types';
import { useTranslation } from 'react-i18next';
import * as S from '../ChallengeItemDesktop/styles';
import { ContainerListEmails } from '../../pages/SpecialProgramManagement/ModalCreateSpecialProgram/styles';
import { Add, Delete } from '@mui/icons-material';
import { useIsSm } from '../../hooks/useIsSm';

const ParticipantsList: React.FC<IParticipantsList> = ({ handleSendInvitations, specialProgram, typePermission }) => {
  const { t } = useTranslation();
  const isSm = useIsSm();

  const validateHeight = () => {
    if (typePermission === 5) return '440px';
    if (typePermission === 6) return '550px';
    if (typePermission === 4) return '400px';
    return '100px';
  };

  return (
    <S.ContainerSpeciaLParticipants style={{ height: isSm ? '200px' : validateHeight() }}>
      <S.ContainerParticipants>
        <ContainerListEmails details={true}>
          <h1 className='title'>{t('Participantes')}</h1>

          <div className='containerList'>
            <div className='containerListColumn'>
              {specialProgram.emailList?.map((item: string, i: number) => (
                <div className='itemList' key={item}>
                  <h1 className='textItemList'>{item}</h1>

                  {specialProgram?.status !== "approved" && typePermission !== 4 && <button className='deleteItemList' onClick={() => handleSendInvitations(i)}>
                    <Delete style={{ fontSize: '24px', color: theme.colorsBase.red2 }} />
                  </button>}
                </div>
              ))}

              {specialProgram.emailList?.length < 1 &&
                <div className='boxNoResults'>
                  <Add className="iconNoResults" />
                  <h3>{t('Você ainda não convidou ninguém.')}</h3>
                  <h3>{t('Convide participantes inserindo o e-mail.')}</h3>
                </div>
              }
            </div>
          </div>
        </ContainerListEmails>
      </S.ContainerParticipants>
    </S.ContainerSpeciaLParticipants>
  );
};

export default ParticipantsList;
