export type RuleNames = 'length' | 'specialChar' | 'number' | 'capital' | 'lowerCase' | 'match' | 'equalNumber'

export class Registration {
  nome = '';
  pontos = '';
}

export class RegistrationError {
  nome = '';
  pontos = '';
}
