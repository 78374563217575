import { useMutation } from "@tanstack/react-query";

import { sendRequest } from "../../../services/RequestService";

import { IChalengeManagementDisapprovalCommand, IChalengeManagementDisapprovalCommandResponse } from "./types";

const defaultRoot = "challenges-management/disapprovals"

export const useChalengeManagementDisapprovalMutation = () => useMutation({
  mutationFn: async (command: IChalengeManagementDisapprovalCommand) => {
    const url = `${defaultRoot}/${command.id}`

    return await sendRequest<IChalengeManagementDisapprovalCommandResponse>(url, 'POST', command.reason)
  }
})
