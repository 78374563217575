import styled from 'styled-components/macro'

export const Container = styled.div`
  position: fixed;
  z-index: 100;
  margin-top: 56px;
  
  @media (max-width: 680px) {
    margin-top: 48px;
  }

  #header {
    z-index: 10;
    overflow: auto !important;
    border-radius: 0 16px 16px 0;
  }

  .menuLeft {
    position: relative;
    bottom: 40px;

    @media (max-width: 680px) {
      bottom: 0px;
    }
  }

  .pro-sidebar .pro-menu a {
    color: white !important;
    font-weight: 400;
    font-size: 14px;
  }

  #header .pro-sidebar {
    height: 100%;
    z-index: 1;
    overflow: auto !important;
  }

  #header .closemenu {
    color: white;
    position: absolute;
    left: 28px;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
    top: 16px;
    cursor: pointer;
    background-color: transparent;
      border: none;
  }

  #header .pro-sidebar {
    width: 100%;
    min-width: 100%;
  }

  #header .pro-sidebar.collapsed {
    width: 60px;
    min-width: 60px;
  }

  #header .pro-sidebar-inner {
    background-color: ${props => props.theme.colors.secundary};
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
    height: 100vh;
  }
  #header .inner-submenu-arrows {
    margin-top: 32px;

    @media (max-width: 680px) {
      margin-bottom: 32px;
    }
  }

  #header .pro-sidebar-inner .pro-sidebar-layout::-webkit-scrollbar {
    width: 0;
  }

  #header .pro-sidebar-inner .pro-sidebar-layout ul {
    // padding: 0 8px;
  }

  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: white;
    margin: 10px 0px;
  }

  #header .pro-sidebar-inner .pro-sidebar-layout {
    position: relative;
    right: 12px;
  }

  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: #5080FF33;
    border-radius: 50%;
  }

  #header
    .pro-sidebar-inner
    .pro-sidebar-layout
    ul
    .pro-inner-item
    .pro-icon-wrapper
    .pro-item-content {
    color: #000;
  }

  #header .logo {
    padding: 20px;
  }

  #header .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: ${props => props.theme.colors.secundary}; 
    border-radius: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: transparent;
    color: ${props => props.theme.colorsBase.white};
    line-height: 0;
    
  }
  
  #header .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    color: ${props => props.theme.colorsBase.white};
  }

  #header .pro-sidebar .pro-menu .pro-sub-menu   .pro-menu-item   {
    background-color: ${props => props.theme.colors.secundary};
    padding: 0px;
  }

  #header .pro-sidebar .pro-menu .pro-sub-menu .react-slidedown pro-inner-list-item .popper-inner {
    background-color: ${props => props.theme.colors.secundary};
  }

  #header .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
    background-color: ${props => props.theme.colors.secundary};
  }
  
`
