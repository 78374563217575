import { ArrowUpward } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import * as S from './styles'

const ScrollToTopButton: React.FC = () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 300) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    });
  }, []);

  const buttonTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  };

  return (
    <>
      {visible && (
        <S.ButtonScrollToTop onClick={buttonTop}>
          <ArrowUpward className="icon" />
        </S.ButtonScrollToTop>
      )}
    </>
  );
}

export default ScrollToTopButton
