import styled from 'styled-components'
import { responsiveFont } from '../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding-top: 56px;
  padding-bottom: 76px;
  background-color: ${(props) => props.theme.colorsBase.white};
`

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 0 40px;
  width: 100%;
  height: 100%;
  
  .buttonFilter {
    display: flex;
    align-self: flex-start;
    padding: 8px 16px 8px 16px;
    flex-direction: column;
    width: 100%;
  }

  @media (max-width: 680px) {
    padding: 0px;
  }
`

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 32px 0 32px 0;


  @media (max-width: 680px) {
    margin: 0px;
    padding: 0px;
  }

  h3 {
    margin-top: 20px;
    letter-spacing: 0.18px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    align-self: flex-start;

    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .containerSubTitle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  }
`


export const BackButton = styled.button`
  display: flex; 
  align-items: center;
  flex-direction: row;
  z-index: 13;
  border: none;
  background-color: transparent;
  margin-left: -16px;

  &:hover {
    opacity: 0.8;
  }
  
  .icon {
    color: ${(props) => props.theme.colorsBase.white};
    width: 20px;
    height: 20px;
    background-color: ${(props) => props.theme.colors.seventh};
    border-radius: 50%;
  }

  h1 {
    margin-left: 8px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: center;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-family: 'Barlow', Regular;


    @media (min-width: 1600px) {
      line-height: 24px;
      font-size: ${responsiveFont(16)};
    }
  }
`

export const ContainerSearch = styled.div`
  width: 50%;
  z-index: 100;
  position: fixed;
  top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
`

export const ButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none; 
  padding: 8px;
  background-color: ${(props) => props.theme.colors.primary};
  margin-left: 8px;
  cursor: pointer;

  .icon {
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;

  width: 60%;

  padding: 8px 0;

  p {
    font-weight: 500;
  }

  @media (max-width: 680px) {
    width: 100%;
    margin-top: -12px;
  }
`

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 0px 12px;

  > div {
    display: flex;
    align-self: flex-start;

    padding: 8px 0;

    input {
      width: 22px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .input {
    width: 100%;

    input {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }

    select {
      width: 100%;
      height: 32px;

      margin-top: 15px;
      margin-left: 28px;

      padding: 15px 8px;

      font-size: 18px;

      border: none;
      border-bottom: solid 2px rgba(0, 0, 0, 0.4);
    }
  }
`

export const ButtonScrollToTop = styled.a`
  position: fixed;
  right: 24px;
  bottom: 24px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 90;
  background-color:  ${(props) => props.theme.colors.seventh};
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
  
  .icon {
    position: absolute;
    top: 8px;
    left: 8px;
    color: ${(props) => props.theme.colorsBase.white};
    width: 24px;
    height: 24px;
  }
`

export const ContainerChallenge = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;

  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 8px 78px 8px;
    width: 100%;
  }
  
   @media (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 2500px) {
    grid-template-columns: repeat(5, 1fr);
  }
`