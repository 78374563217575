import Modal from '@mui/material/Modal';
import React, { useState, useEffect } from 'react';
import * as S from './styles'
import { useTranslation } from 'react-i18next';
import { ModalProps } from './types';
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import { useAuth } from '../../contexts/Auth';
import ToastNotification from '../../components/ToastNotification';
import { Close } from '@mui/icons-material';
import { getTermsOfUse } from '../../services/TermsOfUse';
import { withLineBreak } from '../../utils';

const TermsOfUse: React.FC<ModalProps> = (props) => {
  const { open, register, onClick } = props;
  const { t } = useTranslation();
  const handleClose = () => { };
  const { completeTermsOfUse, refuseCompleteTermsOfUse } = useAuth();
  const [loading, setLoading] = useState(false);
  const [termsOfUseId, setTermsOfUseId] = useState('');
  const [terms, setTerms] = useState('');

  const handleCompleteTermsOfUse = async () => {
    setLoading(true);
    try {
      await completeTermsOfUse(termsOfUseId);
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao aceitar os termos de uso, tente novamente.')
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    retrieveTermsOfUse();
  }, [open]);

  const retrieveTermsOfUse = async () => {
    try {
      const response = await getTermsOfUse();
      if (response.Success) {
        setTermsOfUseId(response.Data.Id)
        setTerms(response.Data.Text)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os termos, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os termos, tente novamente.')
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <S.ContainerModal>
        <h5>{t('Termos de Uso')}</h5>

        {register && (
          <button className='buttonClose' onClick={onClick}>
            <Close className="icon" />
          </button>
        )}

        <S.Table>
          <p>{withLineBreak(terms)}</p>
        </S.Table>

        <S.TableContainer register={register} />

        {!register && (
          <div className='button'>
            <ButtonMaterial
              style={{ borderColor: theme.colors.secundary }}
              variant="outlined"
              label={t("CANCELAR")}
              onClick={() => refuseCompleteTermsOfUse()}
              disabled={loading}
            />

            <ButtonMaterial
              style={{ backgroundColor: theme.colors.seventh, marginLeft: '24px' }}
              variant="contained"
              label={t("CONFIRMAR")}
              onClick={handleCompleteTermsOfUse}
              loading={loading}
              disabled={loading}
            />
          </div>
        )}

      </S.ContainerModal>
    </Modal>
  )
}

export default TermsOfUse
