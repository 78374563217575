import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderAuthentication from '../../components/HeaderAuthentication';
import ToastNotification from '../../components/ToastNotification';
import * as S from './styles'
import { useIsSm } from '../../hooks/useIsSm';
import { getTermsOfUse } from '../../services/TermsOfUse';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth'
import ViewLoading from '../../components/ViewLoading';
import { privacyPolicy } from '../LandingPage/information';
import { withLineBreak } from '../../utils';

const TermsAndPolicy: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState('');
  const isSm = useIsSm()
  const { token } = useParams();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (token === 'termos') {
      retrieveTermsOfUse();
    } else {
      setTerms(privacyPolicy ?? '')
    }
  }, []);

  const retrieveTermsOfUse = async () => {
    setLoading(true)
    try {
      const response = await getTermsOfUse();
      if (response.Success) {
        setTerms(response.Data.Text)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os termos, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os termos, tente novamente.')
      });
    }
    setLoading(false)
  };

  return (
    <S.Container>
      <HeaderAuthentication hideHeader={isAuthenticated} terms hideCurve={isSm} />

      <S.ContainerContent>
        <h1>{token === 'termos' ? t('Termos de Uso') : t('POLÍTICA INTERNA DE PRIVACIDADE E PROTEÇÃO DE DADOS ')}</h1>

        {loading ? (
          <ViewLoading />
        ) : (
          <p>{withLineBreak(terms)}</p>
        )}
      </S.ContainerContent>

      <h5>{t('Copyright ©')} {new Date().getFullYear()} {t('- Todos direitos reservados.')}</h5>
    </S.Container >
  )
}

export default TermsAndPolicy
