import React, { PropsWithChildren } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ContainerBackButton, ContainerContent, Line } from './styles';

interface IProps {
  title: string
  goBackTo?: number
}

const BackPageHeader: React.FC<PropsWithChildren<IProps>> = ({ title, goBackTo = -1 }) => {
  const navigate = useNavigate()
  const { t } = useTranslation();

  return (
    <>
      <ContainerBackButton
        onClick={() => navigate(goBackTo)}
      >
        <ArrowBack className="iconBackButton" />
        <h5>{t('Voltar')}</h5>
      </ContainerBackButton>

      <ContainerContent>
        <Line>
          <h5>{title}</h5>
        </Line>
      </ContainerContent>
    </>
  )
}

export default BackPageHeader;