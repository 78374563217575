export type RuleNames = 'length' | 'specialChar' | 'number' | 'capital' | 'lowerCase' | 'match' | 'equalNumber'

export class Registration {
  name = '';
  email = '';
  dateOfBirth = '';
  cpf = '';
  registrationDate = '';
  sexoId = '';
  language = '';
}

export class RegistrationError {
  name = '';
  email = '';
  dateOfBirth = '';
  cpf = '';
  registrationDate = '';
  sexoId = '';
  language = '';
}

export class RegistrationAddress {
  Logradouro = '';
  Numero = '';
  Complemento = '';
  Bairro = '';
  Cep = '';
  CidadeId = '';
  EstadoId = '';
}

export class RegistrationAddressError {
  Logradouro = '';
  Numero = '';
  Complemento = '';
  Bairro = '';
  Cep = '';
  CidadeId = '';
  EstadoId = '';
}

export class RegistrationProfessional {
  SobreAutor = '';
  ConselhoProfissional = '';
  NumeroConselho = '';
  UfConselhoId = '';
  LinkReferencia = '';
  LinkFacebook = '';
  LinkTwitter = '';
  LinkInstagram = '';
  RQE = '';
}

export class RegistrationProfessionalError {
  SobreAutor = '';
  ConselhoProfissional = '';
  NumeroConselho = '';
  UfConselhoId = '';
  LinkReferencia = '';
  LinkFacebook = '';
  LinkTwitter = '';
  LinkInstagram = '';
  RQE = '';
}

export class ChangePassword {
  email = '';
  password = '';
  confirmPassword = '';
  codigo = '';
  hash = '';
}

export class ChangePasswordError {
  email = '';
  password = '';
  confirmPassword = '';
  codigo = '';
  hash = '';
}