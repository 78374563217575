import React from 'react';
import { useAuth } from '../../contexts/Auth';

import * as S from './styles'
import { Rule, MenuBook, ClosedCaption, } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import ReactAudioPlayer from 'react-audio-player';
import BoxAssessment from '../../components/BoxAssessment';
import ChallengeHeader from '../../components/ChallengeHeader';
import { ContainerContent, Image } from '../AnswerQuestions/styles';
import { deviceType } from 'react-device-detect';
import { useIsSm } from '../../hooks/useIsSm';

const ChallengeContent: React.FC = () => {
  const { challenge } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { token, token2 } = useParams();
  const isSm = useIsSm()


  return (
    <S.Container>
      <ChallengeHeader
        title={challenge?.Title}
        buttonNavigate={() => navigate('/home')}
        mediaAvaliacoes={challenge?.AverageRating}
        qtdAvaliacoes={String(challenge?.RatingReviewCount)}
        id={challenge?.Id}
      />

      <S.ContainerLineChallenge>
        <MenuBook className="i" />
        <h5>{t('Tenha certeza de analisar o conteúdo com cuidado antes de responder ao quiz!')}</h5>
      </S.ContainerLineChallenge>

      {deviceType === 'mobile' &&
        <S.ContainerLineChallenge>
          <ClosedCaption className="i" />
          <h5>{t('Caso precise de legendas, não se esqueça de ativar a função de acessibilidade no seu celular!')}</h5>
        </S.ContainerLineChallenge>
      }

      <ContainerContent>
        <S.ContainerData>
          <>
            {challenge?.ContentType === 1 && (
              <video style={{ aspectRatio: !isSm ? '16/9' : 'none' }}
                id="my-player"
                controls
                controlsList="nodownload"
                autoPlay
                data-setup='{}'>
                <source src={challenge.ContentFileUrl}></source>
              </video>
            )}

            {challenge?.ContentType === 2 && (
              <Image style={{ aspectRatio: '16/9' }} src={challenge.ContentFileUrl} />
            )}

            {challenge?.ContentType === 4 && (
              <ReactAudioPlayer
                src={challenge.ContentFileUrl}
                autoPlay
                controls
                style={{ margin: '48px 0px', width: '100%', padding: '0px 48px' }}
              />
            )}
          </>

          <ButtonMaterial
            style={{ border: `solid 1px ${theme.colors.seventh}`, width: '100%', marginTop: '16px' }}
            variant="contained"
            color='info'
            label={t("RESPONDER QUIZ")}
            icon={<Rule style={{ fontSize: '24px', marginRight: '8px' }} />}
            onClick={() => navigate(`/desafio/perguntas/${token}/${token2 === 'PE' ? 'PE' : ''}`)}
          />
        </S.ContainerData>
      </ContainerContent>

      <BoxAssessment id={challenge?.Id} content={token2 === 'PE'} />
    </S.Container>
  )
}

export default ChallengeContent
